import { SHOULD_PRESERVE_SCROLL_POSITION, CLEAR_PRESERVE_SCROLL_POSITION } from '../types';

/**
 * Reducer for resetting scroll position.
 *
 * @param {object} state current state
 * @param {string} action.type the type of the action
 *
 * @returns {object} the new state
 */
const scrollPositionResetReducer = (state = false, action) => {
  switch (action.type) {
    case SHOULD_PRESERVE_SCROLL_POSITION:
      return true;
    case CLEAR_PRESERVE_SCROLL_POSITION:
      return false;
    default:
  }
  return state;
};

export default scrollPositionResetReducer;
