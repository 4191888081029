import React from 'react';
import styles from '../headerBuilder.module.scss';
import ColoredIcon from '../../../components/ColoredIcon/ColoredIcon';
import Branding from '../../../config/branding';
import AbstractHeaderBuilder from '../abstract-header-builder';

export default class ViewBuffHeaderBuilder extends AbstractHeaderBuilder {
  getTextLogo() {
    return (
      <ColoredIcon
        src={Branding.LOGO_TEXT}
        color={this.getLogoColor()}
        className={styles.logoText}
        style={{ height: '26px' }}
        alt={`${Branding.SITE_NAME} logo`}
      />
    );
  }

  calculateBackgroundColor(scrollY, windowHeight) {
    if (this.onHomePage()) {
      /**
       * When at the top of the page (ie scrollY is 0), the startAlpha will be used.
       * As the approaches the endScroll, it will gradually approach the endAlpha
       */
      const startAlpha = 0.5;
      const endAlpha = 0.8;
      const endScroll = 400;
      // This will always be 0 -> 1
      const endWeight = Math.min(scrollY / endScroll, 1);
      const startWeight = 1 - endWeight;
      const alpha = startWeight * startAlpha + endWeight * endAlpha;
      return `rgba(0, 0, 0, ${alpha})`;
    }
    return 'rgba(255,255,255,0.9)';
  }

  getSearchBarComponent() {
    const SearchBar = this.SearchBarComponentType;
    return (
      <div className="header_search">
        <div className="search_wrapper">
          <SearchBar
            cities
            random_performs_action
            venues
            include_icon_labels
            include_random
            include_nearme
            display_behind
            full_width_m
          />
        </div>
      </div>
    );
  }
}
