import { boolProp } from '../../../../../shared-react/src/util/props';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import VBTextInput from '../../../../../shared-react/src/components/VBTextInput/VBTextInput';
import useDropdown from '../../../../../shared-react/src/hooks/dropdown';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import { getPlaceholderName } from '../../../../../shared-react/src/util/location';
import { getLocationLinkSearch } from '../../../../../shared-react/src/util/location';
import { storeSingle } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useResizeObserver from 'use-resize-observer/polyfilled';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { updateLocation } from '../../../../../shared-react/src/store/actions/globalLocationActions';
import IconSearch from './IconSearch/IconSearch';
import IconRandom from './IconRandom/IconRandom';
import IconNearMe from './IconNearMe/IconNearMe';
import ButtonExplore from './ButtonExplore/ButtonExplore';
import ButtonClear from './ButtonClear/ButtonClear';
import styles from './SearchBar.module.scss';
import Autofill from './Autofill/Autofill';
import APIConfig from '../../../../../shared-react/src/config/api';
import useClosestCity from '../../../../../shared-react/src/hooks/closest-city';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';

// TODO: this really needs to be refactored
// there is a lot of state that is useless

/**
 * Auto-filling search bar used on homepage, trending, and headers
 *
 * @param {string}  include         Describes what buttons/icons to include. Ex: "srn" (order doesn't matter)
 *                                  Possible icons:
 *                                    's' - Search hourglass icon button
 *                                    'r' - Random city icon button
 *                                    'n' - Near me icon button
 *                                    'e' - Explore button
 * @param {boolean} initToRandom    Makes the placeholder initialized with a random city. Note: Even if this property is true, if there
 *                                  is a placeholder in Redux store, then that placeholder will be used instead of loading a new random one.
 * @param {boolean} randomPerformsAction  If true, the random button will jump to random page. If false, random only
 *                                          changes the placeholder text but will not go to different page. Default is false.
 * @param {boolean} cities          Whether or not to include cities in search
 * @param {boolean} venues          Whether or not to include venues in search
 * @param {number}  maxLength       Maximum length of text that can be entered
 * @param {boolean} displayBelow    If true, the autofill will display below the search bar instead of behind it.
 * @param {integer} limit           Amount of results to limit to.
 * @param {string}  resultBehavior  Specifies what autofill results should do when clicked.
 *                                  Possible values:
 *                                      "link":     Clicking a result jumps to it's page. (default)
 *                                      "set_text": Clicking a result just sets the search bar text and passes selection to onSelect callback
 * @param {func}    onSelect        A function that runs when an item is selected from the dropdown. The selected item is passed.
 * @param {string}  defaultPlaceholder If defined, this placeholder will appear in the search bar instead of whatever's stored globally
 * @param {boolean} skipPlaceholderSelectionOnBlur If true, then placeholder value will not be selected automatically on blur
 * @param {boolean} condensed       Makes the autofill results appear more condensed and no venue/city icons
 * @param {boolean} border          Whether the dropdown should have a border behind it
 * @param {boolean} separateButtons Makes the City and Near icons appear separately to the right of the search bar (unless width is small)
 * @param {boolean} disabled        Whether the dropdown is disabled.
 * @param {string}  className       Additional class names
 * @param {string}  label           Label above the text box
 * @param {bool}    required        "required" values passed down to the text box
 * @param {bool}    shadow          Whether or not the autofill should have a shadow
 * @param {bool}    fullWidth       Whether or not the results should take up the full screen width (this is based on
 *                                  the left position of the search bar on the screen, if left is 20px, 20px will be
 *                                  left on the right as well).
 * @param {fun} onNearMeClick       called when the near me button is clicked
 * @param {fun} onRandomClick       called when the random button is clicked
 * @param {fun} onExploreClick      called when the explore button is clicked
 */
const SearchBar = (props) => {
  const {
    onNearMeClick,
    onRandomClick,
    onExploreClick,
    height,
    maxWidth,
    exploreText,
    skipPlaceholderSelectionOnBlur,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { vbRequest } = useVbContext();

  // TODO: all disabled does right now is disable the input.
  const disabled = boolProp(props.disabled);
  const [showIcons, setShowIcons] = useState(true); // If true, show icons (random, near me)
  // This must be stored in a hook since it may need to be loaded through alternate means (ie. redux)
  const [initialPlaceholder, setInitialPlaceholder] = useState(props.defaultPlaceholder ?? null);
  const [placeholder, setPlaceholder] = useState(initialPlaceholder || null);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [searchBarStyle, setSearchBarStyle] = useState({});
  const [abbreviate, setAbbreviate] = useState(false);
  const { location, loading } = useSelector((state) => state.globalLocation);
  const [nextRandomCity, setNextRandomCity] = useState(null);
  const [locationInitialized, setLocationInitialized] = useState(false);

  const nearestCity = useClosestCity();

  // Check what icons should be included in the search bar
  const include = useMemo(() => {
    return {
      explore: props.include?.includes('e'),
      search: props.include?.includes('s'),
      random: props.include?.includes('r'),
      nearme: props.include?.includes('n'),
    };
  }, [props.include]);

  // Padding when icons are showing
  const [paddingDefault, setPaddingDefault] = useState(0);
  // Padding when typing
  const paddingTyping = 42;

  const searchBarEl = useRef(null);
  const textInputEl = useRef(null);

  if (!props.cities && !props.venues) {
    console.error("This search bar will not show any results. Give it 'cities' or 'venues' tags.");
  }

  // Gets information from an endpoint (reusable)
  const callRoute = useCallback(
    (endpoint, callback) => {
      vbRequest(`${APIConfig.NAMESPACE.SEARCH}/${endpoint}`)
        .then((data) => {
          callback(data);
        })
        .catch((err) => console.error(err));
    },
    [vbRequest]
  );

  // Sends an updated placeholder to the parent of this component.
  const passPlaceholder = (newPlaceholder) => {
    if (props.onSelect) {
      props.onSelect(newPlaceholder);
    }
  };

  const clearText = () => {
    setSearchBarValue('');
    setShowIcons(true);
  };

  // Perform the action based on the action passed in args.
  const searchBarAction = useCallback(
    (result, nearMe) => {
      if (result && props.resultBehavior === 'link') {
        // Add clicked result to redux so that it can be loaded very fast on the target page.
        if (result?.isVenue && result?.venue) {
          dispatch(storeSingle(ContentTypes.venue.type, result.venue));
        } else if (result?.location) {
          dispatch(storeSingle(ContentTypes.location.type, result.location));
        }

        history.push(getLocationLinkSearch(history.location, result, nearMe));
      }
    },
    [dispatch, history, props]
  );

  let autofillDropdown = null; // define so it can be referenced in setAndPerform

  // Sets the placeholder, initial placeholder, performs the action. Also clears input and closes autofill
  const setAndPerform = useCallback(
    (p, nearMe) => {
      setPlaceholder(p);
      setInitialPlaceholder(p);
      searchBarAction(p, nearMe);
      clearText();
      autofillDropdown.close();
    },
    [autofillDropdown, searchBarAction]
  );

  // Handle when the user clicks an item from the autofill dropdown
  const handleSelectAutofillItem = (item) => {
    setAndPerform(item, false);
    setSearchBarValue(item.name);
    passPlaceholder(item);
  };

  const [topResult, setTopResult] = useState(null);

  // Prepare autofill dropdown
  const [autofillProps, setAutofillProps] = useState({
    searchText: null,
    elementWidth: 0,
    onSelect: handleSelectAutofillItem,
    getTopResult: (data) => setTopResult(data),
    cities: props.cities,
    venues: props.venues,
    fullWidth: props.fullWidth,
    inputHeight: `${height}px`,
  });

  useEffect(() => {
    setAutofillProps((prev) => ({ ...prev, onSelect: handleSelectAutofillItem }));
    // I really don't like doing this but the autofillProps aren't updating properly when onSelect changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onSelect]);

  const autofillContent = <Autofill {...autofillProps} />;

  autofillDropdown = useDropdown({
    dropdown: autofillContent,
    dropdownClassName: styles.autofillDropdown,
    trigger: null,
    triggerClassName: styles.autofill_trigger,
  });

  // Handle when the user clicks off of the search bar input
  const handleBlur = useCallback(
    (e) => {
      if (e?.relatedTarget?.className?.startsWith('Autofill')) return;

      if (!skipPlaceholderSelectionOnBlur && props.resultBehavior === 'set_text') {
        setSearchBarValue(placeholder ? getPlaceholderName(placeholder) : '');
        passPlaceholder(placeholder);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeholder, passPlaceholder]
  );

  // Initial
  useEffect(() => {
    setShowIcons(true); // Initialize icons
  }, []);

  useEffect(() => {
    // Load initial holder if defined
    if (initialPlaceholder) {
      setPlaceholder(initialPlaceholder);
    }
  }, [initialPlaceholder]);

  // Try loading from redux store. If nothing is in there, then load new random location.
  useEffect(() => {
    if (!props.cities) return;
    if (loading) return;
    if (locationInitialized) return;

    if (props.initToNearest) {
      const p = nearestCity.city;
      if (!p?.id) return;
      setInitialPlaceholder(p);
      setPlaceholder(p);
      dispatch(storeSingle(ContentTypes.location.type, p));
      setLocationInitialized(true);
    } else if (props.initToRandom) {
      // No placeholder in global. Load a new one and store it
      callRoute('random-city', (result) => {
        const p = result.result; // This is the only part of the result we need
        setInitialPlaceholder(p);
        setPlaceholder(p);
        dispatch(storeSingle(ContentTypes.location.type, p));
        setLocationInitialized(true);
      });
    } else if (location?.id && !props.defaultPlaceholder) {
      // Display placeholder value from global location in search bar
      setInitialPlaceholder(location);
      setLocationInitialized(true);
    }
  }, [
    callRoute,
    dispatch,
    loading,
    location,
    locationInitialized,
    nearestCity,
    props.cities,
    props.defaultPlaceholder,
    props.initToNearest,
    props.initToRandom,
  ]);

  // When the global location changes, update the placeholder.
  useEffect(() => {
    if (!props.cities || props.defaultPlaceholder) {
      return;
    }
    setInitialPlaceholder(location);
    setPlaceholder(location);
  }, [location, props.cities, props.defaultPlaceholder]);

  useEffect(() => {
    // Preload random city
    callRoute('random-city', (result) => {
      setNextRandomCity(result.result);
      dispatch(storeSingle(ContentTypes.location.type, result.result));
    });
  }, [callRoute, dispatch]);

  // User clicks the 'near me' icon
  const { isLoading: nearMeLoading, requestUserLocation } = useClosestCity();

  const handleClickNearMe = useCallback(async () => {
    const closestCity = await requestUserLocation();

    if (closestCity) {
      setAndPerform(closestCity, true);

      history.push({
        search: `?${QueryString.stringify({ n: 1 })}`,
      });

      onNearMeClick?.(closestCity);
    }
  }, [history, onNearMeClick, requestUserLocation, setAndPerform]);

  // User clicks the 'random' icon
  const handleClickRandom = useCallback(() => {
    if (props.randomPerformsAction) {
      // Set and go to page
      setAndPerform(nextRandomCity, false);
      callRoute('random-city', (result) => {
        if (result) {
          const newCity = result.result;
          setNextRandomCity(newCity);
          dispatch(storeSingle(ContentTypes.location.type, newCity));
        }
      });
    } else {
      // Only set, don't change page
      setPlaceholder(nextRandomCity);
      if (!props.randomPerformsAction) {
        dispatch(storeSingle(ContentTypes.location.type, nextRandomCity));
        dispatch(updateLocation(nextRandomCity));
      }
      callRoute('random-city', (result) => {
        setNextRandomCity(result.result);
        dispatch(storeSingle(ContentTypes.location.type, result.result));
      });
    }

    onRandomClick?.(nextRandomCity);
  }, [callRoute, dispatch, nextRandomCity, onRandomClick, props, setAndPerform]);

  // Used for 'search' icon
  const handleClickSearch = useCallback(() => {
    searchBarAction(placeholder, false);
  }, [placeholder, searchBarAction]);

  // User clicks 'Explore' button
  const handleClickExplore = useCallback(() => {
    handleClickSearch();
    onExploreClick?.();
  }, [handleClickSearch, onExploreClick]);

  // Clears the input text
  const clearInput = (e) => {
    setPlaceholder(initialPlaceholder);
    passPlaceholder(initialPlaceholder);
    clearText();
    textInputEl?.current?.focus();
  };

  const setWidths = useCallback(() => {
    // Decide whether or not to use abbreviated name. Assume average of 8px per letter (seems to work well)
    const name = getPlaceholderName(placeholder);
    if (name && searchBarEl?.current) {
      const newAbb = searchBarEl.current.offsetWidth - paddingDefault < name.length * 8;
      if (newAbb !== abbreviate) {
        setAbbreviate(newAbb);
      }
    }

    // Update width of autofill dropdown to match width of search bar
    setAutofillProps((prev) => ({
      ...prev,
      elementWidth: searchBarEl.current.offsetWidth,
    }));
  }, [abbreviate, paddingDefault, placeholder]);

  useEffect(() => {
    setWidths(); // might need to update based on length of placeholder
  }, [placeholder, paddingDefault, setWidths]);

  useResizeObserver({
    ref: searchBarEl,
    onResize: ({ width }) => {
      // not mounted
      if (!width) return;
      setWidths();
    },
  });

  // Autofill functionality:
  const handleInput = (e) => {
    const newValue = e.target.value;
    setSearchBarValue(newValue); // set hook value

    // Hide/show trinkets.
    setShowIcons(newValue.length === 0); // Show if no text entered

    // Only get results if the length is at least 3.
    if (newValue.length >= 3) {
      // Display autofill results
      if (!autofillDropdown.isOpen) {
        autofillDropdown.open();
      }

      // Pass search string
      setAutofillProps({
        ...props,
        ...autofillProps,
        elementWidth: searchBarEl.current.offsetWidth,
      });
    } else if (autofillDropdown.isOpen) {
      // Hide autofill results
      autofillDropdown.close();
    }
  };
  // Send input to autofill dropdown
  useEffect(() => {
    setAutofillProps((prev) => ({ ...prev, searchText: searchBarValue }));
  }, [searchBarValue]);

  useEffect(() => {
    setPaddingDefault(
      5 +
        (include.explore ? (exploreText.length / 3) * height : 0) +
        (include.search ? height : 0) +
        (include.random ? height : 0) +
        (include.nearme ? height : 0)
    );
  }, [include, height, exploreText]);

  useEffect(() => {
    if (showIcons) {
      setSearchBarStyle({
        paddingRight: `${paddingDefault}px`,
      });
    } else {
      setSearchBarStyle({
        paddingRight: `${paddingTyping}px`,
      });
    }
  }, [showIcons, paddingDefault, paddingTyping]);

  const nearMeInnerContent = useMemo(() => {
    if (!include.nearme) {
      return null;
    }

    return nearMeLoading ? (
      <div className={styles.spinnerWrapperInner}>
        <VBSpinner />
      </div>
    ) : (
      <IconNearMe color="blue" onClick={handleClickNearMe} />
    );
  }, [handleClickNearMe, include, nearMeLoading]);

  const innerIconsContent = useMemo(() => {
    return (
      <>
        {include.search && <IconSearch onClick={handleClickSearch} />}
        {include.random && <IconRandom color="red" onClick={handleClickRandom} />}
        {include.nearme && nearMeInnerContent}
        {include.explore && (
          <ButtonExplore onClick={handleClickExplore} height={`${height}px`} exploreText={exploreText} />
        )}
      </>
    );
  }, [exploreText, handleClickExplore, handleClickRandom, handleClickSearch, height, include, nearMeInnerContent]);

  const clearButton = <ButtonClear onClick={clearInput} />;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // go to first result
      if (!topResult) return;
      handleSelectAutofillItem(topResult);
      clearText();
    }
  };

  return (
    <>
      <div
        role="search"
        className={mergeClassNames(props.className, styles.searchWrapper, props.condensed ? styles.condensed : null)}
        style={{ maxWidth: `${maxWidth}px` }}
      >
        <div className={styles.searchBar} ref={searchBarEl} style={{ maxHeight: `${height}px` }}>
          <VBTextInput
            type="text"
            className={mergeClassNames(styles.searchInput, props.border ? styles.searchInputBordered : null)}
            autoComplete="nope"
            placeholder={placeholder?.name ? placeholder.name : getPlaceholderName(placeholder, abbreviate)}
            maxLength={props.maxLength}
            onClick={handleInput}
            value={searchBarValue}
            onChange={handleInput}
            onBlur={handleBlur}
            style={searchBarStyle}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            label={props.label}
            required={props.required}
            inputRef={textInputEl}
            height={`${height}px`}
          />
          <span
            className={mergeClassNames(styles.iconsRight, !include.explore ? styles.extraPadding : null)}
            style={{ height: `${height}px`, marginTop: `CALC(0px - ${height}px)` }}
          >
            {showIcons ? innerIconsContent : clearButton}
          </span>
          {autofillDropdown.dropdown}
        </div>
      </div>
    </>
  );
};

SearchBar.propTypes = {
  include: PropTypes.string,
  initToRandom: PropTypes.bool,
  randomPerformsAction: PropTypes.bool,
  cities: PropTypes.bool,
  venues: PropTypes.bool,
  maxLength: PropTypes.number,
  displayBelow: PropTypes.bool,
  limit: PropTypes.number,
  resultBehavior: PropTypes.string,
  onSelect: PropTypes.func,
  defaultPlaceholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  condensed: PropTypes.bool,
  border: PropTypes.bool,
  separateButtons: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  shadow: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onNearMeClick: PropTypes.func,
  onRandomClick: PropTypes.func,
  onExploreClick: PropTypes.func,
  height: PropTypes.number,
  maxWidth: PropTypes.number,
  initToNearest: PropTypes.bool,
  exploreText: PropTypes.string,
};

SearchBar.defaultProps = {
  include: '',
  initToRandom: false,
  randomPerformsAction: true,
  cities: false,
  venues: false,
  maxLength: 80,
  displayBelow: false,
  limit: 5,
  resultBehavior: 'link',
  onSelect: null,
  defaultPlaceholder: undefined,
  condensed: false,
  border: false,
  separateButtons: false,
  disabled: false,
  className: undefined,
  label: undefined,
  shadow: false,
  required: false,
  height: 48,
  maxWidth: 400,
  exploreText: 'Explore',
};

export default SearchBar;
