import React from 'react';
import PropTypes from 'prop-types';
import styles from './VBFeedbackText.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** valid will make text green, invalid will make text red. */
  type: PropTypes.oneOf(['valid', 'invalid', null]),
  /** The message to display. */
  message: PropTypes.string,
  /** another means of putting a feedback message. */
  children: PropTypes.node,
  className: PropTypes.string,
};
const defaultProps = {
  type: null,
  message: null,
  className: '',
  children: null,
};

/**
 * For displaying feedback messages to the user.
 */
function VBFeedbackText({ type, message, children, className }) {
  const theMessage = children || message;
  return <div className={mergeClassNames(styles[type], className)}>{type && theMessage}</div>;
}

VBFeedbackText.propTypes = propTypes;
VBFeedbackText.defaultProps = defaultProps;

export default VBFeedbackText;
