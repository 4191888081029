import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import VBButton from '../../VBButton/VBButton';
import styles from './SwitchButton.module.scss';

/**
 * Sizes a switch can take.
 */
export const SIZES = ['tny', 'sm', 'med', 'lg'];

const propTypes = {
  style: PropTypes.object,
  size: PropTypes.oneOf(SIZES),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.string,
  onClickOption: PropTypes.func.isRequired,
  onWidthChange: PropTypes.func.isRequired,
};

/**
 * Individual options in a switch.
 */
const SwitchButton = ({ size, name, value, selected, style, onClickOption, onWidthChange }) => {
  const ref = useRef(null);
  const { width = 0 } = useResizeObserver({ ref });

  // Update the width in the parent.
  useEffect(() => {
    onWidthChange(ref.current.clientWidth);
  }, [onWidthChange, width]);

  return (
    <VBButton
      key={name}
      size={size}
      className={styles.option}
      onClick={() => onClickOption()}
      type={selected === value ? 'full' : 'border'}
      style={style}
      buttonRef={ref}
      noFocusStyle={selected === value}
    >
      {name}
    </VBButton>
  );
};

export default SwitchButton;

SwitchButton.propTypes = propTypes;
SwitchButton.defaultProps = {
  style: undefined,
  size: 'med',
  selected: undefined,
};
