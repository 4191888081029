import { useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';
import { mergeClassNames } from '../../../../../../shared-react/src/util/props';
import VBButton from '../../../../../../shared-react/src/components/VBButton/VBButton';
import VBNWaySwitch from '../../../../../../shared-react/src/components/VBNWaySwitch/VBNWaySwitch';
import FilterSelector from '../../../../../../shared-react/src/components/FilterSelector/FilterSelector';
import AgesSelector from '../../../../../../shared-react/src/components/AgesSelector/AgesSelector';
import {
  getAgeFilterLabel,
  getPriceFilterLabel,
  getRadiusFilterLabel,
} from '../../../../../../shared-react/src/util/explore';
import RadiusBubbleSelector from '../../../../../../shared-react/src/components/RadiusBubbleSelector/RadiusBubbleSelector';
import { COMMON_DISTANCE_RANGES } from '../../../../../../shared-react/src/config/explore';
import SubcategorySelector from '../../SubcategorySelector/SubcategorySelector';
import BubbleSelector from '../../../../../../shared-react/src/components/BubbleSelector/BubbleSelector';
import { getPlaceholderName } from '../../../../../../shared-react/src/util/location';
import SearchBar from '../../SearchBar/SearchBar';

import styles from './CityFilter.module.scss';

const VenueFilterOption = {
  RANDOM: 'random',
  POPULAR: 'popular',
};

const CityFilterOption = {
  RANDOM: 'random',
  ONLY: 'only',
};

const VenueFilterOptionLabel = {
  [VenueFilterOption.RANDOM]: 'Random',
  [VenueFilterOption.POPULAR]: 'Popular',
};

const CityFilterOptionLabel = {
  [CityFilterOption.RANDOM]: 'Random',
  [CityFilterOption.ONLY]: 'Only',
};

const VENUE_OPTIONS = [VenueFilterOption.RANDOM, VenueFilterOption.POPULAR];
const SWITCH_OPTIONS = [CityFilterOption.RANDOM, CityFilterOption.ONLY];

const CityFilter = ({ defaultValues, venue, location, fullPage, hideCancel, isShown, onCancel, onSave }) => {
  const [locationDetails, setLocationDetails] = useState(location);
  const [values, setValues] = useState(defaultValues);

  const nWaySwitchOptions = SWITCH_OPTIONS.map((option) => ({ name: CityFilterOptionLabel[option], value: option }));
  const venueOptions = VENUE_OPTIONS.map((option) => ({ name: VenueFilterOptionLabel[option], value: option }));

  const handleSelectCity = (searchItem) => {
    if (searchItem) {
      setValues((values) => ({ ...values, locationId: searchItem.id }));
      setLocationDetails(searchItem.location);
    }
  };

  const canSaveChanges = !isEqual(values, defaultValues);

  const handleSaveValues = useCallback(() => {
    const result = {};

    const location =
      values.locationId !== defaultValues.locationId ? locationDetails : values.randomCity ? venue?.city : null;

    if (location) {
      result.locationDetails = location;
    }

    if (values.randomVenue !== defaultValues.randomVenue) {
      result.randomVenue = values.randomVenue;
    }

    if (values.randomCity !== defaultValues.randomCity) {
      result.randomCity = values.randomCity;
    }

    if (values.randomState !== defaultValues.randomState) {
      result.randomState = values.randomState;
    }

    if (!isEqual(values.ages, defaultValues.ages)) {
      result.ages = values.ages;
    }

    if (!isEqual(values.radius, defaultValues.radius)) {
      result.radius = values.radius;
    }

    if (values.subcategory !== defaultValues.subcategory) {
      result.subcategory = values.subcategory;
    }

    if (values.lowRisk !== defaultValues.lowRisk) {
      result.lowRisk = values.lowRisk;
    }

    if (values.isFree !== defaultValues.isFree) {
      result.isFree = values.isFree;
    }

    onSave(result);
  }, [values, defaultValues, venue, onSave, locationDetails]);

  return (
    <div className={mergeClassNames(styles.filter, isShown && styles.shown, fullPage && styles.fullPage)}>
      <div className={styles.header}>
        <SearchBar
          resultBehavior="set_text"
          defaultPlaceholder={
            locationDetails
              ? {
                  name: getPlaceholderName(locationDetails, true),
                  location: locationDetails,
                  isVenue: false,
                }
              : null
          }
          onRandomClick={handleSelectCity}
          onNearMeClick={handleSelectCity}
          onSelect={handleSelectCity}
          className={styles.searchBar}
          include="rn"
          skipPlaceholderSelectionOnBlur
          cities
          shadow
        />
      </div>
      <div className={styles.body}>
        <div className={styles.switchContainer}>
          Venue
          <VBNWaySwitch
            options={venueOptions}
            selected={values.randomVenue ? VenueFilterOption.RANDOM : VenueFilterOption.POPULAR}
            onChange={(value) =>
              setValues((values) => ({ ...values, randomVenue: value === VenueFilterOption.RANDOM }))
            }
          />
          City
          <VBNWaySwitch
            options={nWaySwitchOptions}
            selected={values.randomCity ? CityFilterOption.RANDOM : CityFilterOption.ONLY}
            onChange={(value) => setValues((values) => ({ ...values, randomCity: value === CityFilterOption.RANDOM }))}
          />
          State
          <VBNWaySwitch
            options={nWaySwitchOptions}
            selected={values.randomState ? CityFilterOption.RANDOM : CityFilterOption.ONLY}
            onChange={(value) => setValues((values) => ({ ...values, randomState: value === CityFilterOption.RANDOM }))}
          />
        </div>
        <FilterSelector
          filters={[
            {
              name: 'Ages',
              filterItem: (
                <AgesSelector
                  ages={values.ages}
                  setAges={(ages) => setValues((values) => ({ ...values, ages }))}
                  hideLabel
                  isVertical
                  leftAlign
                />
              ),
              getValue: () => getAgeFilterLabel(values.ages),
            },
            {
              name: 'Radius',
              filterItem: (
                <RadiusBubbleSelector
                  radius={values.radius}
                  setRadius={(radius) => {
                    setValues((values) => ({ ...values, radius }));
                  }}
                  ranges={COMMON_DISTANCE_RANGES}
                  hideLabel
                  isVertical
                />
              ),
              getValue: () => getRadiusFilterLabel(values.radius, COMMON_DISTANCE_RANGES),
            },
            {
              name: 'Type',
              filterItem: (
                <SubcategorySelector
                  subcategory={values.subcategory}
                  setSubcategory={(subcategory) => setValues((values) => ({ ...values, subcategory }))}
                  lowRisk={values.lowRisk}
                  setLowRisk={useCallback((lowRisk) => setValues((values) => ({ ...values, lowRisk })), [])}
                />
              ),
              getValue: () => 'Type',
            },
            {
              name: 'Price',
              filterItem: (
                <BubbleSelector
                  options={[
                    { label: 'All', value: false },
                    { label: 'Free', value: true },
                  ]}
                  value={values.isFree}
                  onChange={(isFree) => setValues((values) => ({ ...values, isFree }))}
                />
              ),
              getValue: () => getPriceFilterLabel(values.isFree),
            },
          ]}
          tabName="activities"
          buttonSize="sm"
          className={styles.filters}
        />
      </div>
      <div className={styles.actions}>
        {!fullPage && !hideCancel ? <VBButton type="empty" size="med" content="Cancel" onClick={onCancel} /> : null}
        <VBButton
          className={styles.save}
          type="full"
          size="med"
          content="Save"
          color="red"
          disabled={!canSaveChanges}
          noFocusStyle
          onClick={handleSaveValues}
        />
      </div>
    </div>
  );
};

export default CityFilter;
