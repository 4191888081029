import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';
import styles from './StackedAvatars.module.scss';
import { boolProp } from '../../util/props';
import { PropTypesVBBool } from '../../util/types';

/**
 * Horizontally stacked avatars. This is used in the venue card.
 *
 * @param {string} props.className  additional classes
 * @param {object[]} props.users    an array of user listing info. See vb_social_get_listing_info
 * @param {boolean} props.showExtra whether or not to show extra users, ie (+10)
 * @param {number} props.maxUsers   the max number of users to show
 * @param {number} props.total      the total users. When this is supplied, this will be used to calculate the extra
 */
const StackedAvatars = (props) => {
  const { className, users, showExtra, maxUsers, total, ...other } = props;
  const realTotal = typeof total === 'undefined' ? users.length : total;
  const realMaxUsers = typeof maxUsers === 'undefined' ? users.length : maxUsers;
  return (
    <div className={[className, styles.users, 'd-flex'].join(' ')} {...other}>
      <div className="icons d-flex flex-row-reverse align-items-center justify-content-end">
        {users.slice(0, realMaxUsers).map((user) => (
          <Avatar
            key={user.id}
            src={user.avatar}
            userId={user.id}
            href={user.permalink}
            className={styles.innerAvatar}
          />
        ))}
      </div>
      {boolProp(showExtra, true) && realTotal > realMaxUsers ? (
        <div className={styles.plus}> +{realTotal - realMaxUsers} </div>
      ) : null}
    </div>
  );
};

StackedAvatars.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  showExtra: PropTypesVBBool,
  maxUsers: PropTypes.number,
  total: PropTypes.number,
};
StackedAvatars.defaultProps = {
  total: undefined,
  maxUsers: undefined,
};

export default StackedAvatars;
