import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { addParams } from '../util/urls';

/**
 * Returns a function for going to a page with city slugs while already on a page with city slugs.
 *
 * @param {string} currentSlugs.countrySlug
 * @param {string} currentSlugs.regionSlug
 * @param {string} currentSlugs.citySlug
 *
 * @returns {(newSlugs: {countrySlug: string, regionSlug: string, citySlug: string}, extraParam?: object): void}
 */
const useGotoCityFromSlugLocation = (currentSlugs) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const currentCountrySlug = currentSlugs?.countrySlug;
  const currentRegionSlug = currentSlugs?.regionSlug;
  const currentCitySlug = currentSlugs?.citySlug;

  return useCallback(
    (newSlugs, extraParams = {}, mode = 'push') => {
      const newCountrySlug = newSlugs?.countrySlug;
      const newRegionSlug = newSlugs?.regionSlug;
      const newCitySlug = newSlugs?.citySlug;

      if (!currentCountrySlug || !currentRegionSlug || !currentCitySlug) throw new Error('invalid new location');
      if (!newCountrySlug || !newRegionSlug || !newCitySlug) throw new Error('invalid old location');

      const oldPath = `${currentCountrySlug}/${currentRegionSlug}/${currentCitySlug}`;
      const newPath = `${newCountrySlug}/${newRegionSlug}/${newCitySlug}`;
      history[mode](addParams(pathname.replace(oldPath, newPath) + search, extraParams));
    },
    [currentCitySlug, currentCountrySlug, currentRegionSlug, history, pathname, search]
  );
};

export default useGotoCityFromSlugLocation;
