import QueryString from 'query-string';
import APIConfig from '../config/api';
import { trimSlashes } from './string';
import { hours } from './time';
import { getJsonFromStorage, saveJsonInStorage } from './objects';
import { isTesting } from './env';
import { removeParams } from './urls';

/**
 * Get location from IP.
 */
export const getLocationTelize = () => {
  if (isTesting())
    Promise.resolve({
      ip: '75.60.246.12',
      continent_code: 'NA',
      country: 'United States',
      country_code: 'US',
      country_code3: 'USA',
      region: 'North Carolina',
      region_code: 'NC',
      city: 'Raleigh',
      postal_code: '27609',
      latitude: 35.8536,
      longitude: -78.6262,
      timezone: 'America/New_York',
      offset: -14400,
      asn: 7018,
      organization: 'ATT-INTERNET4',
    });

  return fetch('https://telize.viewbuff.com/location').then((response) => response.json());
};

/**
 * Checks user's permission to retrieve geolocation without prompting
 *
 * @returns Promise<PermissionStatus>
 */
export const checkGeolocationPermission = () => {
  return navigator.permissions.query({ name: 'geolocation' });
};

/**
 * Get user geolocation using browser API
 *
 * @returns Promise<GeolocationPosition>
 */
export const requestBrowserPosition = async () => {
  try {
    return await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject(null);
      }

      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  } catch {
    return null;
  }
};

/**
 * Gets the user's IP. This is cached for up to an hour in session storage.
 * @returns Promise<string>
 */
export const getIp = () => {
  return new Promise((resolve, reject) => {
    const ipCached = getJsonFromStorage('ip', sessionStorage);
    if (ipCached) {
      resolve(ipCached);
    } else {
      localStorage.removeItem('ip');
      getLocationTelize()
        .then(({ ip }) => {
          saveJsonInStorage('ip', ip, sessionStorage, Date.now() + hours(1));
          resolve(ip);
        })
        .catch(reject);
    }
  });
};

/**
 * Gets placeholder name from nav info.
 *
 * @param {object} navInfo the nav info object. See VB_Location, CTX_NAV_INFO
 * @param {boolean} short whether to use the short version, defaults to false
 *
 * @returns {string} the name to put in the placeholder, or null if navInfo is invalid
 */
export function getPlaceholderName(navInfo, short = false) {
  if (!navInfo) return null;
  if (navInfo?.name) return navInfo.name;
  if (navInfo?.isVenue) {
    return navInfo.name; // For venues, can't shorten name
  }
  if (!navInfo?.city || !navInfo?.region || !navInfo?.regionShort) {
    return null;
  }
  if (short) {
    return `${navInfo.city}, ${navInfo.regionShort}`;
  }
  return `${navInfo.city}, ${navInfo.region}`;
}

/**
 * Takes the user to a city page.
 *
 * @param {string} city.link link to the city page
 */
export const getSlugsFromLocationLink = (link) => {
  const split = trimSlashes(link).split('/');
  return {
    country: split[1],
    region: split[2],
    city: split[3],
  };
};

/**
 * Checks whether a link to a location page refers to the same place as some location slugs
 * @param {string} link the link
 * @param {string} country country slug
 * @param {string} region region slug
 * @param {string} city city slug
 */
export const linkEqualsCity = (link, country, region, city) => {
  const slugs = getSlugsFromLocationLink(link);
  return slugs.country === country && slugs.region === region && slugs.city === city;
};

/**
 * Given an autofill result, generate the link that the user should be taken to.
 *
 * @param {object} location window.location or useLocation()
 * @param {object} result result object from the autofill query.
 * @param {bool} nearMe whether this should be a near me link
 *
 * @returns {string} the link
 */
export const getLocationLinkSearch = (location, result, nearMe) => {
  if (result.isVenue) {
    // Venue
    return result.searchLink ?? result.link;
  }
  // City
  // If no location object passed, just assume search page.
  if (!location) {
    return result.link;
  }
  // Check the first part of the url path to determine what page we are on.
  const routeStart = location.pathname.split('/')[1];
  // Get base of new page based off what page we are currently on. Typically want to stay on the same page type.
  let curBase = null;
  switch (routeStart) {
    case 'location':
      curBase = '/location';
      break;
    case 'trending':
      curBase = '/trending/location';
      break;
    case 'videos':
      curBase = '/videos';
      break;
    case 'activity':
      curBase = '/activity';
      break;
    default:
      curBase = null;
  }
  const newBase = curBase ?? '/location';

  // Note: this isn't always the category. Could also be a subpage like Experts
  let category = null;
  // If we are on a page that can have a category then search for it.
  if (curBase !== null) {
    const regexBase = curBase.replaceAll('/', '\\/');
    const regex = `^${regexBase}\\/[^/]+\\/[^/]+\\/[^/]+(\\/[a-zA-Z]+)?.*`;
    // Get the category from the url if there is one.
    const categoryMatches = location.pathname.match(new RegExp(regex));
    // Category matched in the url
    if (categoryMatches?.length === 2) {
      // eslint-disable-next-line prefer-destructuring
      category = categoryMatches[1];
    }
  }

  const { countrySlug, regionSlug, citySlug } = result.location || result;
  const link = `${newBase}/${countrySlug}/${regionSlug}/${citySlug}`;

  const { n, ...prevParams } = QueryString.parse(location?.search);
  const newParams = prevParams;
  if (nearMe) {
    Object.assign(newParams, { n: 1 });
  }
  const relativeUrl = `${link}${category || ''}${
    Object.keys(newParams).length ? `?${QueryString.stringify(newParams)}` : ''
  }`;

  return removeParams(relativeUrl, ['rank', 'exploreLink', 'exploreLinkText', 'exploreContext', 'exploreFilter']);
};

/**
 * Check if location slugs refer to the default location.
 *
 * @param {string} countrySlug
 * @param {string} regionSlug
 * @param {string} citySlug
 *
 * @returns {bool}
 */
export const isDefaultLocation = (countrySlug, regionSlug, citySlug) => {
  if (countrySlug !== 'country') return false;
  if (regionSlug !== 'region') return false;
  if (citySlug !== 'city') return false;

  return true;
};

/**
 * Get a random city that is not the one passed in to this function.
 * @param {string} country slug for country. ex. "us"
 * @param {string} region slug for region. ex. "nc"
 * @param {string} city slug for city. ex. "raleigh"
 * @return {Promise} Resolves a VB_Location with context CTX_NAV_INFO.
 */
export function getRandomCity(vbRequest, country, region, city) {
  // In theory, getting a random city could cause an infinitely long loop. In practice, it won't. In the future, this
  // route could accept an "except" parameter.
  return vbRequest(`${APIConfig.NAMESPACE.SEARCH}/random-city`).then(({ result }) => {
    const { countrySlug, regionSlug, citySlug } = result;
    if (countrySlug === country && regionSlug === region && citySlug === city) {
      return getRandomCity();
    }
    return result;
  });
}

export const getLocationURL = (location) => {
  return `${location.countrySlug}/${location.regionSlug}/${location.citySlug}`;
};

export const getProfileLocation = (user) => {
  if (user.locationDetails) {
    return [user.locationDetails.city, user.locationDetails.regionShort].join(', ');
  }

  return user.location;
};

export const getClosestCity = async (vbRequest, latitude, longitude) => {
  const coordsToCities = getJsonFromStorage('coordsToCities', localStorage, {});
  const coordsKey = `${latitude.toFixed(2)},${longitude.toFixed(2)}`;

  const cityByCoordsKey = coordsToCities[coordsKey];

  const promise = vbRequest(`${APIConfig.NAMESPACE.SEARCH}/closest-city`, {
    params: { latitude, longitude },
  });

  if (cityByCoordsKey) {
    promise.then((city) => {
      saveJsonInStorage('coordsToCities', { ...coordsToCities, [coordsKey]: city });
    });

    return cityByCoordsKey;
  }

  return await promise;
};

export const getUserCoords = async (requestBrowserPositionIfNotGranted = false) => {
  const { state } = await checkGeolocationPermission();

  const showRequestBrowserPosition = state === 'granted' || (requestBrowserPositionIfNotGranted && state === 'prompt');

  if (showRequestBrowserPosition) {
    const position = await requestBrowserPosition();

    if (position) {
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        retriever: 'browser',
      };
    }
  }

  try {
    const coords = await getLocationTelize();

    return {
      latitude: coords.latitude,
      longitude: coords.longitude,
      retriever: 'telize',
    };
  } catch {
    return {
      latitude: 40.73061,
      longitude: -73.935242,
      retriever: 'telize',
    };
  }
};
