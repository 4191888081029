import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearPreserveScrollPosition } from '../../store/actions/scrollPositionPreserveActions';

/**
 * Handles resetting the scroll position when changing pages.
 */
const ScrollResetHandler = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const shouldPreserveScrollPosition = useSelector((state) => state.scrollPositionPreserve);

  useEffect(() => {
    if (!history) return null;

    const removeListener = history.listen((l, action) => {
      if (!shouldPreserveScrollPosition && (action === 'PUSH' || action === 'REPLACE')) {
        window.scrollTo(0, 0);
      }
      if (shouldPreserveScrollPosition) dispatch(clearPreserveScrollPosition());
    });
    return () => {
      removeListener();
    };
  }, [dispatch, history, shouldPreserveScrollPosition]);

  return null;
};
export default ScrollResetHandler;
