import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MaxWidthContainer from '../../../../../../../shared-react/src/components/MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../../../../../../../shared-react/src/components/VBHeading/VBHeading';
import FormFAQ from '../../../../../../../shared-react/src/components/FormFAQ/FormFAQ';
import APIConfig from '../../../../../../../shared-react/src/config/api';
import { invalidateList } from '../../../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../../../shared-react/src/config/content-types';
import useVbContext from '../../../../../../../shared-react/src/hooks/vb-context';
import { vbRequest } from '../../../../../util/api';
import Question from '../Question/Question';

import styles from './AnswerForm.module.scss';

const propTypes = {
  locationDetails: PropTypes.object,
  selectedQuestion: PropTypes.object.isRequired,
  setSelectedQuestion: PropTypes.func.isRequired,
};

const AnswerForm = ({ locationDetails, selectedQuestion, setSelectedQuestion }) => {
  const dispatch = useDispatch();

  const { errorToast } = useVbContext();

  const goBack = useCallback(() => {
    setSelectedQuestion(null);
  }, [setSelectedQuestion]);

  const handleSubmit = useCallback(
    (values) => {
      if (locationDetails) {
        vbRequest(`${APIConfig.NAMESPACE.LOCATION}/question/answer`, {
          method: 'POST',
          body: {
            questionId: selectedQuestion.id,
            answer: values.answer,
          },
        })
          .then(() => {
            dispatch(
              invalidateList(
                ContentTypes.locationQuestion.type,
                (ctx) => ctx === `location-question:${locationDetails?.id}`
              )
            );

            dispatch(
              invalidateList(ContentTypes.faq.type, (ctx) => ctx === `faq:location-${locationDetails?.id}`)
            );

            goBack();
          })
          .catch((error) => errorToast(error.message, 'Error'));
      }
    },
    [dispatch, errorToast, goBack, locationDetails, selectedQuestion]
  );

  return (
    <MaxWidthContainer className={styles.answerForm}>
      <VBHeading className={styles.heading} size="sm">
        Answer Question
      </VBHeading>
      <Question question={selectedQuestion} />
      <FormFAQ defaultValues={{ question: selectedQuestion.question }} onSubmit={handleSubmit} onBack={goBack} />
    </MaxWidthContainer>
  );
};

AnswerForm.propTypes = propTypes;

export default AnswerForm;
