import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import styles from './VBScrollContainer.module.scss';

const propTypes = {
  /** whether to scroll along the x axis (true) or y axis (false) */
  x: PropTypes.bool,

  /** additional class names */
  className: PropTypes.string,

  /** children */
  children: PropTypes.node,
};

const defaultProps = {
  x: false,
  className: undefined,
  children: undefined,
};

/**
 * Applies class which styleizes the scrollbars the VB way.
 */
const VBScrollContainer = React.forwardRef(({ x, className, children, ...other }, ref) => {
  return (
    <div className={mergeClassNames(className, styles.container, x ? styles.x : styles.y)} ref={ref} {...other}>
      {children}
    </div>
  );
});

export default VBScrollContainer;

VBScrollContainer.propTypes = propTypes;
VBScrollContainer.defaultProps = defaultProps;
