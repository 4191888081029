import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../../../shared-react/src/components/Avatar/Avatar';
import { mergeClassNames } from '../../../../../../shared-react/src/util/props';
import OnClickLink from '../../../../../../shared-react/src/components/OnClickLink/OnClickLink';
import { PropTypesVBBool } from '../../../../../../shared-react/src/util/types';
import { PropTypesVBUser } from '../../../../../../shared-react/src/util/types';
import VBLink from '../../../../../../shared-react/src/components/VBLink/VBLink';
import { useOpenAuthPopup } from '../../../../hooks/open-auth-popup';
import ImageCheckmarkCircle from '../../../../assets/images/icons/checkmark-circle.svg';
import useCurrentUser from '../../../../hooks/current-user';

import styles from './PostMeta.module.scss';

/**
 * Displays the meta information that is specific to posts:
 * User, content, and comments.
 *
 * @param {string} content caption the user attaches to the image they upload
 * @param {string} venue venue that connected the post
 * @param {object} user user that uploaded the post
 * @param {string} user.username poster's username
 * @param {string} user.avatar full URL of the poster's avatar
 * @param {int} user.id poster's user ID
 * @param {string} user.permalink relative URL of the poster's profile page
 * @param {int} comments the number of comments a post has
 * @param {string} ago the time the post was made ago as a human readable string
 * @param {boolean} isFollowing if clientize is enabled and a user is provided/logged in, this will be whether or
 *                                     not the user is following the poster
 * @param {boolean} hideComments whether or not to hide the comments block
 * @param {boolean} showVenueMetaInsteadUser whether or not to show venue details or user
 * @param {Function()} handleOpenComments the function to execute when the comments button is clicked. Can be null
 */
const PostMeta = ({
  content,
  venue,
  user,
  comments,
  ago,
  postId,
  isFollowing,
  hideComments,
  showVenueMetaInsteadUser,
  handleOpenComments: onOpenComments,
}) => {
  const openAuthPopup = useOpenAuthPopup();
  const { user: currentUser } = useCurrentUser();

  const handleOpenComments = onOpenComments ?? (() => {});

  return (
    <>
      <div className={styles.postMeta}>
        <VBLink
          className={mergeClassNames('d-flex align-items-baseline', styles.details)}
          to={showVenueMetaInsteadUser ? venue.permalink : user.permalink}
        >
          {!showVenueMetaInsteadUser && <Avatar src={user.avatar} userId={user.id} />}
          <p className={mergeClassNames('my-auto', showVenueMetaInsteadUser && styles.venueTitle)}>
            {showVenueMetaInsteadUser ? venue.name : user.username}
          </p>
          {!showVenueMetaInsteadUser && isFollowing ? (
            <img
              src={ImageCheckmarkCircle}
              style={{ minWidth: '20px', maxWidth: '20px', marginLeft: '8px' }}
              alt="Following"
            />
          ) : null}
        </VBLink>
      </div>
      <p className={mergeClassNames('my-2', styles.caption)}>
        {content}
        <span className={mergeClassNames('ml-1', styles.ago)}> ({ago})</span>
      </p>
      {!hideComments && (
        <OnClickLink
          className={styles.comments}
          vb-post-comments-button={postId}
          onClick={currentUser ? handleOpenComments : () => openAuthPopup()}
        >
          {comments} {comments === 1 ? 'Comment' : 'Comments'}
        </OnClickLink>
      )}
      {/* {firstComment ? (
        <div className={Styles.topComment}>
          <a href={firstComment.user.permalink}>
            <Avatar src={firstComment.user.avatar} size="2.25rem" />
          </a>
          <div className={Styles.topCommentRight}>
            <a href={firstComment.user.permalink} className={Styles.topCommentName}>
              {firstComment.user.fullName}
            </a>
            <p className={Styles.topCommentContent}>{firstComment.content}</p>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

PostMeta.propTypes = {
  postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  content: PropTypes.string.isRequired,
  user: PropTypesVBUser.isRequired,
  comments: PropTypes.number.isRequired,
  ago: PropTypes.string.isRequired,
  isFollowing: PropTypesVBBool.isRequired,
  handleOpenComments: PropTypes.func,
  hideComments: PropTypes.bool,
  showVenueMetaInsteadUser: PropTypes.bool,
};

PostMeta.defaultProps = {
  handleOpenComments: null,
  hideComments: false,
  showVenueMetaInsteadUser: false,
};

export default PostMeta;
