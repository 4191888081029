import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from '../../util/objects';
import SITES from '../../../config-json/sites.json';
import { getSitesArray } from '../../util/sites';
import VBCheckbox from '../VBCheckbox/VBCheckbox';

const propTypes = {
  /** valid site options, as bits. See the site field in the database. Defaults to all sites */
  options: PropTypes.number,

  /** value, as bits. See the site field in the database */
  value: PropTypes.number,

  /** onChange, called with the new value */
  onChange: PropTypes.func,
};

/**
 * A set of checkboxes to select multiple sites (VB, TF, etc). Right now, there is minimal styling since the component
 * is only used for contributors. Deals with site data in bit form, like the site field for venues in the database.
 */
const SitesSelector = ({ options, value: parentValue, onChange: parentHandleChange }) => {
  const [value, setValue] = useState(parentValue ?? 0);

  useEffect(() => {
    if (!isUndefined(parentValue)) setValue(parentValue);
  }, [parentValue]);

  const handleChange = (newValue) => {
    if (parentHandleChange) parentHandleChange(newValue);
    setValue(newValue);
  };

  return (
    <div>
      {getSitesArray().map((siteName) => {
        const siteId = SITES[siteName];
        if (!isUndefined(options) && (options & (1 << siteId)) === 0) return null;

        return (
          <div key={siteId}>
            {siteName + ': '}
            <VBCheckbox
              checked={value & (1 << siteId)}
              onChange={(checked) => (value & (1 << siteId)) !== checked && handleChange(value ^ (1 << siteId))}
            />
          </div>
        );
      })}
    </div>
  );
};

SitesSelector.propTypes = propTypes;

export default SitesSelector;
