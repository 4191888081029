import React from 'react';
import PropTypes from 'prop-types';
import styles from './VBHeading.module.scss';
import { mergeClassNames } from '../../util/props';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { PropTypesRefProp } from '../../util/types';

const propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  className: PropTypes.string,
  color: PropTypes.oneOf(['black', 'blue', 'white']),
  noMargin: PropTypes.bool,
  /** Element type to render the heading as. You can pass the string if it's a html
   * standard component like h1, div, etc. If it's a custom component, pass that component type.
   * Defaults to h2 */
  as: PropTypes.elementType,
  children: PropTypes.node,
  theRef: PropTypesRefProp,
  /** Will text-align center the heading */
  center: PropTypes.bool,
};
const defaultProps = {
  size: 'md',
  className: '',
  color: 'black',
  noMargin: false,
  as: 'h2',
  children: undefined,
  theRef: undefined,
  center: false,
};

/**
 * Should be used for most headings in ViewBuff. The main reason for a custom component
 * is to make it quicker to make headings and not having to manually set the headings
 * to our custom black, with custom sizing in the CSS.
 */
function VBHeading({ theRef, center, children, className, size, color, noMargin, as: As, ...other }) {
  const { ltSm, ltMd, ltLg } = useVBBreakpoint();
  const screenClass = (ltSm && 'screenSm') || (ltMd && 'screenMd') || (ltLg && 'screenLg') || 'screenXl';
  return (
    <As
      ref={theRef}
      className={mergeClassNames(
        className,
        styles.heading,
        styles[size],
        styles[color],
        styles[screenClass],
        center ? styles.center : '',
        noMargin ? styles.noMargin : ''
      )}
      {...other}
    >
      {children}
    </As>
  );
}

VBHeading.propTypes = propTypes;
VBHeading.defaultProps = defaultProps;

export default VBHeading;
