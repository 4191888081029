import React, { lazy, Suspense } from 'react';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';

const PostComments = lazy(() => import('../PostCard/PopupPostComments/PostComments/PostComments'));

const PostCommentsWrapper = (postCommentsProps) => (
  <Suspense fallback={<VBSpinner center />}>
    <PostComments {...postCommentsProps} />
  </Suspense>
);

export default PostCommentsWrapper;
