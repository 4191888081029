import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import VBButton from '../VBButton/VBButton';
import styles from './FilterSelector.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** array of objects for each filter. Each object should have the following structure:
   * {
   * name: string for filter name,
   * filterItem: selector component that will drop down when the button is clicked
   * }
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      filterItem: PropTypes.element.isRequired,
      getValue: PropTypes.func.isRequired,
    })
  ).isRequired,

  /** Whether or not the list of filters should scroll. Should not be used in combination with 'isVertical' prop */
  scroll: PropTypes.bool,

  /** Name of the tab. Required in order to know when the tab changes. */
  tabName: PropTypes.string.isRequired,

  /** Whether or not the filters should be displayed vertically. Should not be used in combination with 'scroll' prop */
  isVertical: PropTypes.bool,

  /** Button size */
  buttonSize: PropTypes.oneOf(['tny', 'xs', 'sm', 'med', 'lg']),
};

const defaultProps = {
  scroll: false,
  isVertical: false,
  buttonSize: 'med',
};

/**
 * Bubbles of filters which drop down when the corresponding button is clicked.
 *
 * @param {object} props
 */
const FilterSelector = ({ filters, scroll, tabName, isVertical, buttonSize, className }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);

  // Not sure why, but switching between tabs doesn't clear selectedFilter, so
  // this will clear it when the filters change.
  useEffect(() => {
    setSelectedFilter(null);
  }, [setSelectedFilter, tabName]);

  /**
   * Toggles whether or not a filter is shown
   *
   * @param {number} idx the filter index in the row
   */
  const toggleFilter = (idx) => {
    if (selectedFilter === idx) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(idx);
    }
  };

  let buttons = filters.map((filter, idx) => (
    <Fragment key={idx}>
      <div className={mergeClassNames(styles.filterButtonContainer, isVertical ? styles.vertical : null)}>
        <p
          className={mergeClassNames(styles.filterName, isVertical ? styles.vertical : null)}
          onClick={() => {
            toggleFilter(idx);
          }}
        >
          {isVertical ? `${filter.name}  •  ${filter.getValue()} ${selectedFilter === idx ? '▼' : '►'}` : filter.name}
        </p>
        {!isVertical ? (
          <VBButton
            className={mergeClassNames(styles.button, isVertical ? styles.vertical : null)}
            content={filter.getValue()}
            onClick={() => {
              toggleFilter(idx);
            }}
            size={buttonSize}
            type={selectedFilter === idx ? 'full' : 'border'}
          />
        ) : null}
      </div>
      {isVertical ? (
        <div
          key={filter.name}
          className={mergeClassNames(
            styles.filterOptions,
            selectedFilter === idx ? styles.shown : styles.hidden,
            isVertical ? styles.vertical : null
          )}
        >
          {filter.filterItem}
        </div>
      ) : null}
    </Fragment>
  ));

  return (
    <>
      <div
        className={mergeClassNames(
          styles.filterContainer,
          scroll ? styles.scroll : undefined,
          isVertical ? styles.vertical : null,
          className
        )}
      >
        {/* Filter category buttons, for example: [Ages, Radius, Outdoor] */}
        {buttons}
      </div>
      {!isVertical ? (
        <div className={styles.filterBottom}>
          {/* Second section with filter options, for example: [1-8 yrs, 8-12 yrs, 12+ yrs] */}
          {filters.map((filter, idx) => (
            <div
              key={filter.name}
              className={mergeClassNames(styles.filterOptions, selectedFilter === idx ? styles.shown : styles.hidden)}
            >
              {filter.filterItem}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default FilterSelector;
FilterSelector.propTypes = propTypes;
FilterSelector.defaultProps = defaultProps;
