import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ExploreCard, {
  ExploreCardSkeletonPart,
} from '../../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import usePopup from '../../../../../../shared-react/src/hooks/popup';
import PopupDeleteConfirm from '../../../../../../shared-react/src/components/PopupDeleteConfirm/PopupDeleteConfirm';
import { removeItem } from '../../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import APIConfig from '../../../../../../shared-react/src/config/api';
import PopupUpsertFestival from '../../PopupUpsertFestival/PopupUpsertFestival';

const propTypes = {
  item: PropTypes.object.isRequired,
  noMargin: PropTypes.bool,
  isLoading: PropTypes.bool,
  permalink: PropTypes.string,
  locationDetails: PropTypes.object.isRequired,
  fullDescription: PropTypes.bool,
};

const RenderedFestival = ({ item, noMargin, locationDetails, isLoading, permalink, fullDescription }) => {
  const dispatch = useDispatch();
  const { vbRequest, raiseToast } = useVbContext();

  const handleDeleteFestival = useCallback(
    (confirmed) => {
      if (!confirmed) {
        return;
      }

      dispatch(removeItem(ContentTypes.festival.type, item.id));

      vbRequest(`${APIConfig.NAMESPACE.FESTIVAL}/${item.id}`, { method: 'DELETE' })
        .then((response) => {
          if (response.success) {
            raiseToast('Successfully deleted festival.', 'Deleted', 'info');
          } else {
            raiseToast('There was an error deleting this festival.', 'Error', 'warning');
            console.error(response?.success);
          }
        })
        .catch((err) => {
          raiseToast('There was an error deleting this festival.', 'Error', 'warning');
          console.error(err?.message);
        });
    },
    [dispatch, item, raiseToast, vbRequest]
  );

  const { modal: deleteConfirmModal, open: openDeleteConfirmModal } = usePopup({
    child: <PopupDeleteConfirm contentName="this festival" onResolve={handleDeleteFestival} />,
  });

  const { modal: upsertFestivalModal, open: openUpsertFestivalModal } = usePopup({
    child: (
      <PopupUpsertFestival
        locationDetails={locationDetails}
        festivalId={item.id}
        defaultValues={{
          name: item.name,
          description: item.description,
          locationId: item.locationId,
          date: item.date,
          onlyMonth: item.onlyMonth,
          tbd: !item.date,
          externalLink: item.externalLink,
        }}
      />
    ),
  });

  const content = useMemo(() => {
    const actualPermalink = permalink ?? item.blogPostPermalink;

    return (
      <ExploreCard
        elementId={item.slug}
        name={item.name}
        description={item.description}
        descriptionLink={actualPermalink}
        date={item.displayDate}
        permalink={actualPermalink}
        // outlink={item.externalLink}
        onDelete={openDeleteConfirmModal}
        onEdit={openUpsertFestivalModal}
        isLoading={isLoading}
        skeletonParts={[
          ExploreCardSkeletonPart.TITLE,
          ExploreCardSkeletonPart.DATE,
          ExploreCardSkeletonPart.DESCRIPTION,
        ]}
        numberOfLines={fullDescription ? null : 3}
        hideReadMore
        hideImage
      />
    );
  }, [fullDescription, isLoading, item, openDeleteConfirmModal, openUpsertFestivalModal, permalink]);

  return (
    <>
      {noMargin ? content : <div style={{ marginBottom: '1.5rem' }}>{content}</div>}

      {deleteConfirmModal}
      {upsertFestivalModal}
    </>
  );
};

RenderedFestival.propTypes = propTypes;

export default RenderedFestival;
