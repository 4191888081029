import { Suspense, lazy } from 'react';
import VBSpinner from '../../../../../../shared-react/src/components/VBSpinner/VBSpinner';

const FAQComments = lazy(() => import('./FAQComments/FAQComments'));

const FAQCommentsWrapper = (props) => (
  <Suspense fallback={<VBSpinner center />}>
    <FAQComments {...props} />
  </Suspense>
);

export default FAQCommentsWrapper;
