import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import YTPlayer from 'yt-player';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons/faForward';
import { generateTestAttributes, mergeClassNames } from '../../../util/props';
import YtThumbnail from './YtThumbnail/YtThumbnail';
import VBButton from '../../VBButton/VBButton';
import VBLink from '../../VBLink/VBLink';
import IconMute from '../../../../assets/images/icons/videos/mute.webp';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './YoutubeEmbedVideo.module.scss';

const propTypes = {
  /** ID of the youtube video. You can get this from the URL */
  videoId: PropTypes.string,

  /** URL of the image to render as the thumbnail */
  image: PropTypes.string,

  /** if provided (true or false) this will control video autoplay */
  isSelected: PropTypes.bool,

  permalink: PropTypes.string,

  permalinkAriaLabel: PropTypes.string,

  isLoading: PropTypes.bool,
};

/**
 * A component that renders an embedded YouTube video.
 */
const YoutubeEmbedVideo = ({ videoId, image, isSelected, permalink, permalinkAriaLabel, isLoading }) => {
  const ref = useRef();
  const playerMountId = useMemo(() => `v${uuid()}`, []);

  const [player, setPlayer] = useState(null);
  const [isEnded, setIsEnded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const autoplay = typeof isSelected !== 'undefined';

  useEffect(() => {
    if (autoplay && player && isPlayerReady && !isEnded && !isLoading) {
      if (isSelected) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [autoplay, isEnded, isSelected, player, isPlayerReady, isLoading]);

  const fastForward = () => {
    if (player) {
      player.seek(player.getCurrentTime() + 15);
    }
  };

  useEffect(() => {
    const el = ref.current;

    const observer = new IntersectionObserver(
      (entry) => {
        if (entry.length > 0) {
          if (entry[0].isIntersecting && !isInView) {
            setIsInView(true);
          }

          // if (!entry[0].isIntersecting && isInView) {
          //   setIsInView(false);
          // }
        }
      },
      {
        root: null,
        rootMargin: '100% 0% 100% 0%',
        threshold: 0,
      }
    );
    if (el) {
      observer.observe(el);
      return () => observer.disconnect(el);
    }
  }, [isInView]);

  // Hacky remount.
  useEffect(() => {
    setIsInView(false);
  }, [videoId]);

  useEffect(() => {
    let newPlayer = player;

    if (isInView && !newPlayer && !isLoading) {
      newPlayer = new YTPlayer(`#${playerMountId}`, {
        origin: '*',
        autoplay: autoplay,
        playsInline: true,
        // controls: false,
        modestBranding: false,
        related: false,
      });

      newPlayer.load(videoId);

      let initialized = false;

      newPlayer.on('unstarted', () => {
        if (initialized) {
          return;
        }

        initialized = true;

        if (autoplay) {
          newPlayer.play(); // Note, this will be stopped in a previous useEffect. This way, the video can start to buffer.
        }
      });
    }

    if (!isInView && newPlayer) {
      newPlayer.destroy();
      newPlayer = null;
      setIsPlayerReady(false);
    }

    if (newPlayer !== player) {
      setPlayer(newPlayer);
    }

    if (newPlayer) {
      let isMostRecentHandle = true;

      newPlayer.on('timeupdate', () => {
        if (!isMostRecentHandle) return;

        if (!isPlayerReady && newPlayer.getProgress() > 0) {
          setIsPlayerReady(true);
        }

        const state = newPlayer.getState();
        const shouldBeEnded = state === 'ended';

        if (isEnded && !shouldBeEnded) {
          setIsEnded(false);
        } else if (!isEnded && shouldBeEnded) {
          setIsEnded(true);
        }
      });

      return () => {
        isMostRecentHandle = false;
      };
    }
  }, [isEnded, isInView, player, playerMountId, videoId, isPlayerReady, autoplay, isLoading]);

  useEffect(() => {
    if (!player) {
      return;
    }

    // const isPlaying = player.getState() === 'playing';

    // if (isPlaying) player.pause();

    if (player.isMuted() && !isMuted) {
      player.unMute();
    }

    if (!player.isMuted() && isMuted) {
      player.mute();
    }

    // if (isPlaying) setTimeout(() => player.play(), 0);
  }, [isMuted, player]);

  // const handleToggleMute = () => {
  //   setIsMuted((m) => !m);
  // };

  return (
    <div className={styles.container} ref={ref}>
      {!isLoading && (
        <div id={playerMountId} className={styles.video} {...generateTestAttributes({ id: `yt-player-${videoId}` })} />
      )}
      {permalink ? (
        <VBLink className={styles.clickCapture} to={permalink} aria-label={permalinkAriaLabel} noStyle />
      ) : null}
      {isLoading ? (
        <Skeleton containerClassName={styles.loaderContainer} className={styles.loader} />
      ) : !isInView ? (
        <YtThumbnail image={image} />
      ) : !isEnded ? (
        <>
          {isMuted ? (
            <div className={mergeClassNames(styles.unmute, isMobile && styles.isMobile)}>
              <img src={IconMute} width="20" height="20" alt="Mute" />
            </div>
          ) : null}
          <div className={mergeClassNames(styles.floatingButton, styles.fastForward)}>
            <VBButton circle type="full" size="lg" aria-label="Forward" className={styles.inner} onClick={fastForward}>
              <FontAwesomeIcon icon={faForward} />
            </VBButton>
          </div>
          {/* <div className={mergeClassNames(styles.floatingButton, styles.toggleMute)}>
            <VBButton circle type="full" size="lg" className={styles.inner} onClick={handleToggleMute}>
              <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
            </VBButton>
          </div> */}
        </>
      ) : null}
    </div>
  );
};

YoutubeEmbedVideo.propTypes = propTypes;

export default YoutubeEmbedVideo;
