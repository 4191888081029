import React from 'react';
import PropTypes from 'prop-types';
import styles from './MapEventCard.module.scss';
import ProgressiveImage from '../ProgressiveImage/ProgressiveImage';
import NoContent from '../NoContent/NoContent';
import VBLink from '../VBLink/VBLink';
import OnClickLink from '../OnClickLink/OnClickLink';
import useVBBreakpoint from '../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** event to render */
  event: PropTypes.object.isRequired,

  /** callback for when the next button is clicked, or leave undefined for no next button to render */
  onClickNext: PropTypes.func,

  /** callback for when the previous button is clicked, or leave undefined for no previous button to render */
  onClickPrevious: PropTypes.func,

  /**
   * whether or not small style should be used. The current use case for this is when the card is rendered while the
   * map is not fullscreened.
   */
  isSmall: PropTypes.bool,
};

/**
 * A venue card on the map. Wraps VenueCard. Used by buildRenderItemVenue.
 */
const MapEventCard = ({ event, onClickPrevious, onClickNext }) => {
  const { lteXs: mobileStyle, lte1280: tabletStyle } = useVBBreakpoint();

  return (
    <div
      className={mergeClassNames(
        styles.container,
        tabletStyle ? styles.tabletStyle : null,
        mobileStyle ? styles.mobileStyle : null
      )}
    >
      <div className={styles.left}>
        {event.externalImageUrl ? (
          <img src={event.externalImageUrl} alt={event.name} className={styles.image} />
        ) : (
          <ProgressiveImage
            fullSrc={event.featuredImageUrlFull}
            smallSrc={event.featuredImageUrlSmall}
            imageClassName={styles.image}
            alt={event.name}
          />
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.name}>{event.name}</div>
        <div className={styles.description}>
          {event.description?.length ? event.description : <NoContent typePlural="event description" noCenter />}
        </div>
        <div className={styles.bottom}>
          <div className={styles.readMore}>
            <VBLink to={event.permalink ?? event.outlink} newTab={!!event.outlink}>
              Read More
            </VBLink>
          </div>
          <div className={styles.pagination}>
            <div className={styles.previous}>
              <OnClickLink onClick={onClickPrevious}>Prev</OnClickLink>
            </div>
            <div className={styles.next}>
              <OnClickLink onClick={onClickNext}>Next</OnClickLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapEventCard;

MapEventCard.propTypes = propTypes;
