import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'query-string';
import Cookies from 'js-cookie';

/**
 * Handles the referral query param.
 */
const ReferralHandler = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = QueryString.parse(search.slice(1));
    const { r: token, ...otherParams } = params;
    if (token) {
      Cookies.set('vb-refer-token', token, { expires: 7 });
      history.push(`${pathname}?${QueryString.stringify(otherParams)}`);
    }
  }, [history, pathname, search]);

  return null;
};
export default ReferralHandler;
