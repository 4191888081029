export const PageExploreSummarySection = {
  SUMMARY: 'summary',
  ARTICLES: 'articles',
  EVENTS: 'events',
  FESTIVALS: 'festivals',
  CHAT: 'chat',
  VIDEOS: 'videos',
  TRIVIA: 'trivia',
  FAQ: 'FAQ',
  ITINERARIES: 'itineraries',
  ACTIVITIES: 'activities',
  NEWSLETTERS: "newsletters",
  DAY_TRIPS: 'dayTrips',
  PLAYGROUNDS: 'playgrounds',
};
