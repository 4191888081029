import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { isImageGenerated } from '../../util/venue';
// import YoutubeEmbedVideo from '../VideoCard/YoutubeEmbedVideo/YoutubeEmbedVideo';
import MarkdownText, { getMarkdownLink, injectMatchesInText } from '../MarkdownText/MarkdownText';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './ItineraryDay.module.scss';

const propTypes = {
  video: PropTypes.object,
  description: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.object),
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
  injectImages: PropTypes.bool,
};

const MAX_IMAGES_IN_ROW = 3;

const ItineraryDay = ({ video, description, matches, injectImages, isLoading }) => {
  const text = useMemo(() => {
    if (!matches?.length) {
      return description;
    }

    let result = injectMatchesInText(
      description,
      matches.map((match) => ({ match: match.match, permalink: match.venue.permalink }))
    );

    if (injectImages) {
      const injectedVenues = new Set();

      const [introduction, ...list] = result.split('\n');

      let content = list.join('\n');

      for (const match of matches) {
        if (isImageGenerated(match.venue.imageAlt) && !injectedVenues.has(match.venue.id)) {
          const markdownLink = getMarkdownLink(match.match, match.venue.permalink);

          content = content.replace(
            markdownLink,
            (replacement) =>
              `<img src="${match.venue.featuredImage}" alt="${match.venue.imageAlt}" class="${styles.venueImage}" /> ${replacement}`
          );

          injectedVenues.add(match.venue.id);
        }
      }

      result = `${introduction}\n${content}`;

      const rowImages = [];

      for (const match of matches) {
        const isDuplicate = rowImages.some((image) => image.src === match.venue.featuredImage);

        if (isImageGenerated(match.venue.imageAlt) && !isDuplicate) {
          rowImages.push({ src: match.venue.featuredImage, alt: match.venue.imageAlt });
        }

        if (rowImages.length === MAX_IMAGES_IN_ROW) {
          break;
        }
      }

      if (rowImages.length) {
        const row = `<div class="${styles.imageRow}">${rowImages
          .map((image) => `<img src="${image.src}" alt="${image.alt}" />`)
          .join('\n')}</div>`;

        result = result.replace('\n', '\n'.concat(row).concat('\n\n'));
      }
    }

    return result;
  }, [description, injectImages, matches]);

  return (
    <div className={styles.itineraryDay}>
      {/* {Boolean(isLoading || video) && (
        <div className={styles.video}>
          <YoutubeEmbedVideo
            key={video?.id}
            videoId={video?.id}
            image={video?.thumbnail}
            isSelected={isSelected}
            isLoading={isLoading}
          />
        </div>
      )} */}
      {isLoading ? <Skeleton count={4} /> : <MarkdownText text={text} html />}
    </div>
  );
};

ItineraryDay.propTypes = propTypes;

export default ItineraryDay;
