import { useMemo } from 'react';
import PropTypes from 'prop-types';
import FAQ from '../../FAQ/FAQ';

const propTypes = {
  item: PropTypes.object.isRequired,
  locationDetails: PropTypes.object,
  noMargin: PropTypes.bool,
};

const defaultProps = {
  noMargin: false,
};

const RenderedFAQ = ({ item, locationDetails, noMargin, isLoading }) => {
  const content = useMemo(
    () => <FAQ faq={item} locationDetails={locationDetails} isLoading={isLoading} />,
    [isLoading, item, locationDetails]
  );

  return noMargin ? content : <div style={{ marginBottom: '1.5rem' }}>{content}</div>;
};

RenderedFAQ.propTypes = propTypes;
RenderedFAQ.defaultProps = defaultProps;

export default RenderedFAQ;
