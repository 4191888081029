import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import VBLink from '../../../../../shared-react/src/components/VBLink/VBLink';
import OnClickLink from '../../../../../shared-react/src/components/OnClickLink/OnClickLink';
import ColoredIcon from '../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { VBReactContext } from '../../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import { MOBILE_FOOTER_HEIGHT } from '../../../../../shared-react/src/config/mobile-footer';
import { useHasUnreadActivity } from '../../../../../shared-react/src/hooks/unread-activity';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import useCurrentUser from '../../../hooks/current-user';
import { useMakePostPopup } from '../../../hooks/make-post-popup';
import Home from '../../../../../shared-react/assets/images/mobile-footer/home.svg';
import Search from '../../../assets/images/mobile-footer/search-search.svg';
import Add from '../../../assets/images/mobile-footer/plus-circle.svg';
import Notifications from '../../../assets/images/mobile-footer/notifications.svg';
import Profile from '../../../assets/images/mobile-footer/profile.svg';

import styles from './MobileFooter.module.scss';

/**
 * Displays the mobile navigation footer.
 * Includes links to Video, Search, Notifications, Profile, and an Add post button.
 */
const MobileFooter = () => {
  const { user: currentUser } = useCurrentUser();
  const routeLocation = useLocation();
  const postPopup = useMakePostPopup();

  const hasUnreadActivity = useHasUnreadActivity();
  const { vbBlue, vbBlack } = useContext(VBReactContext);

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const isVideos = /^\/location\/.+\/.+\/.+\/videos.*/.test(routeLocation?.pathname);

  const { pathname } = useLocation();

  const isFooterHidden = ['/daily-travel-contest', '/zuzu-go', '/trips', '/embed/'].some((item) => pathname.indexOf(item) !== -1);

  if (isFooterHidden) {
    return null;
  }

  return currentUser ? (
    <>
      {postPopup.modal}
      <div className={styles.padContentBottom} style={{ height: `${MOBILE_FOOTER_HEIGHT}px` }} />
      <div
        className={mergeClassNames('fixed-bottom', 'justify-content-around', 'align-items-center', styles.footer)}
        style={{ height: `${MOBILE_FOOTER_HEIGHT}px` }}
      >
        <VBLink to="/" onClick={() => sendAnalyticsEvent('vb_mobile_footer_home', 'vb_mobile_footer')}>
          <ColoredIcon
            src={Home}
            color={routeLocation?.pathname.length <= 1 ? vbBlue : vbBlack}
            height="20"
            width="20"
            alt="Homepage"
          />
        </VBLink>
        <VBLink to="/location" onClick={() => sendAnalyticsEvent('vb_mobile_footer_explore', 'vb_mobile_footer')}>
          <ColoredIcon
            src={Search}
            color={!isVideos && /^\/location.*/.test(routeLocation?.pathname) ? vbBlue : vbBlack}
            height="20"
            width="20"
            alt="Search page"
          />
        </VBLink>
        <OnClickLink
          onClick={() => {
            sendAnalyticsEvent('vb_mobile_footer_notifications', 'vb_mobile_footer');
            postPopup.open();
          }}
        >
          <ColoredIcon
            color={vbBlue}
            src={Add}
            height="50"
            width="50"
            alt="Add post"
            style={{ width: '50px', margin: '-15px' }}
          />
        </OnClickLink>
        <VBLink
          to="/notifications"
          onClick={() => sendAnalyticsEvent('vb_mobile_footer_notifications', 'vb_mobile_footer')}
        >
          <ColoredIcon
            src={Notifications}
            color={/^\/notifications.*/.test(routeLocation?.pathname) ? vbBlue : vbBlack}
            height="20"
            width="20"
            alt="Notifications page"
          />
          <div className={hasUnreadActivity ? styles.unreadNotifications : ''} />
        </VBLink>
        <VBLink to="/user" onClick={() => sendAnalyticsEvent('vb_mobile_footer_profile', 'vb_mobile_footer')}>
          <ColoredIcon
            src={Profile}
            color={/^\/user.*/.test(routeLocation?.pathname) ? vbBlue : vbBlack}
            height="20"
            width="20"
            alt="Profile page"
          />
        </VBLink>
      </div>
    </>
  ) : (
    <></>
  );
};

export default MobileFooter;
