import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import APIConfig from '../../config/api';
import styles from './PopupReportBug.module.scss';
import VBHeading from '../VBHeading/VBHeading';
import VBTextArea from '../VBTextArea/VBTextArea';
import VBSpinnerButton from '../VBSpinnerButton/VBSpinnerButton';
import VBAlert from '../VBAlert/VBAlert';
import VBRadioGroup from '../VBRadioGroup/VBRadioGroup';
import VBRadioButton from '../VBRadioGroup/VBRadioButton/VBRadioButton';
import ContentLengths from '../../../../shared-react/config-json/content-lengths.json';

const propTypes = {};
const defaultProps = {};

/**
 * The popup prompting the user to log in or sign up.
 */
const PopupReportBug = () => {
  // Input.
  const [content, setContent] = useState('');

  // Whether a submission is loading.
  const [loading, setLoading] = useState(false);

  // Error text.
  const [error, setError] = useState();

  // Whether or not the report has been sent.
  const [done, setDone] = useState();

  // Whether the user is reporting a bug or leaving feedback
  const [type, setType] = useState('bug');

  const { vbRequest } = useContext(VBReactContext);

  /**
   * Whether or not the form can be submitted.
   * @returns {bool}
   */
  const canSubmit = () => content.trim().length;

  /**
   * Handle submission of the form.
   *
   * @param {React.SyntheticEvent} e
   * @returns
   */
  const handleSubmit = (e) => {
    if (!canSubmit()) return;
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setError(null);

    vbRequest(`${APIConfig.NAMESPACE.CONTACT}/feedback`, {
      method: 'POST',
      body: {
        type,
        content,
        url: window.location.href,
      },
    })
      .then(() => setDone(true))
      .catch((err) => setError('Unable to send feedback. Please try again later.'))
      .finally(() => setLoading(false));
  };

  const formContent = (
    <>
      {error && <VBAlert content={error} variant="danger" />}
      <VBHeading>Report a Bug or Leave Feedback</VBHeading>
      {type === 'bug' ? (
        <p>
          Describe the bug that you are experiencing. Please be as detailed as possible. This will help us resolve the
          issue.
        </p>
      ) : (
        <p>Let us know about changes or features you&apos;d like to see. Please be as detailed as possible.</p>
      )}
      <Form onSubmit={handleSubmit}>
        <div className={styles.checkboxContainer}>
          <VBRadioGroup selected={type} onChange={(val) => setType(val)}>
            <VBRadioButton value="bug" label="Report a bug" />
            <VBRadioButton value="feedback" onChange={() => setType('feedback')} label="Leave feedback" />
          </VBRadioGroup>
        </div>
        <VBTextArea
          label={type === 'bug' ? 'Report Message' : 'Feedback Message'}
          maxLength={ContentLengths.FEEDBACK_CONTENT}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          showMaxLength
          required
        />
        <div className={styles.buttonContainer}>
          <VBSpinnerButton
            size="med"
            type={canSubmit() ? 'full' : 'empty'}
            content="Submit"
            submit
            isSpinning={loading}
          />
        </div>
      </Form>
    </>
  );

  const doneContent = (
    <>
      <VBHeading>Feedback Successful</VBHeading>
      <p>Thank you for {type === 'bug' ? 'reporting a bug' : 'leaving feedback'}!</p>
    </>
  );

  return <div className={styles.container}>{done ? doneContent : formContent}</div>;
};

PopupReportBug.WIDTH = 'med';
PopupReportBug.propTypes = propTypes;
PopupReportBug.defaultProps = defaultProps;

export default PopupReportBug;
