import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import VBPopup from '../components/VBPopup/VBPopup';

/**
 * @typedef UsePopupReturn
 *
 * @param {ReactElement} modal the element to render
 * @param {boolean} isOpen whether the popup is open
 * @param {(): void} open opens the popup
 * @param {(): void} close closes the popup
 */

const PopupContext = createContext();

/**
 * Hook to include popups in your components.
 *
 * @param {ReactElement | string} args.child the child of the popup
 * @param {bool} args.initOpen If the popup should be initially open.
 * @param {(): void} args.onOpen optional callback for when the popup opens
 * @param {(): void} args.onClose optional callback for when the popup closes
   @param {string} size PropTypes.oneOf(['sm', 'md', 'lg', 'xl']), This is a bootstrap modal prop.
   @param {bool} hideFakeScrollbar whether or not to hide the fake scrollbar
 *
 * @returns UsePopupReturn
 */
export const usePopup = ({ child, onOpen, onClose, initOpen, hideFakeScrollbar, ...other }) => {
  const [isOpen, setIsOpen] = useState(!!initOpen);
  const popupRef = useRef();

  const handleOpen = useCallback(() => {
    setIsOpen(true);

    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const modal = (
    <PopupContext.Provider value={{ close: handleClose, ref: popupRef }}>
      <VBPopup
        component={child}
        isOpen={isOpen}
        onRequestClose={handleClose}
        hideFakeScrollbar={hideFakeScrollbar}
        popupRef={popupRef}
        {...other}
      />
    </PopupContext.Provider>
  );

  return { modal, isOpen, open: handleOpen, close: handleClose };
};

export default usePopup;

export const useClosePopup = () => {
  const ctx = useContext(PopupContext);
  return ctx.close;
};

export const usePopupRef = () => {
  const ctx = useContext(PopupContext);
  return ctx.ref;
};
