import React from 'react';
import { PropTypesVBVenueVideo } from '../../../util/types';
import { getDisplayedCategory } from '../../../util/venue';
import VenueMeta from '../../VenueMeta/VenueMeta';
import GemDropdown from '../../GemDropdown/GemDropdown';
import { getBadge } from '../../../config/badges';
import useSaveIconButtons from '../../../hooks/save-icon-buttons';
import VBLink from '../../VBLink/VBLink';
import IconButton from '../../IconButton/IconButton';
import DeleteIcon from '../../../../assets/images/icons/forms/delete-trash.svg';
import ColoredIcon from '../../ColoredIcon/ColoredIcon';
import useVbContext from '../../../hooks/vb-context';
import { usePopup } from '../../../hooks/popup';
import PopupDeleteConfirm from '../../PopupDeleteConfirm/PopupDeleteConfirm';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../../util/props';
import useDeleteVideo from '../../../hooks/delete-video';

import styles from './VenueSnippet.module.scss';

const propTypes = {
  video: PropTypesVBVenueVideo.isRequired,
};
const defaultProps = {};

/**
 * In the VenueVideoCard component, this component is used to display the venue below the video title.
 */
function VenueSnippet({ video }) {
  const { venue } = video;
  const actionButtons = useSaveIconButtons(venue);
  const { vbBlack, currentUser } = useVbContext();

  const deleteVideo = useDeleteVideo();

  const handlePopup = (confirmed) => {
    if (!confirmed) return;
    deleteVideo(video.id, video.venue?.id);
  };

  const { open, modal } = usePopup({
    child: <PopupDeleteConfirm contentName="this video" onResolve={handlePopup} />,
  });

  if (currentUser?.hasProductCapability) {
    actionButtons.push(
      <IconButton
        key="deleteVideo"
        text="Delete"
        onClick={open}
        children={<ColoredIcon src={DeleteIcon} color={vbBlack} />}
      />
    );
  }

  const { lteXs: mobileStyle } = useVBBreakpoint();

  const badge = getBadge(venue.overallRating);
  return (
    <>
      {/* <hr className={styles.line} /> */}
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.top}>
            <VBLink to={venue.permalink} className={styles.title}>
              {venue.name}
            </VBLink>
            {badge && <GemDropdown gemBadge={badge} />}
          </div>
          <div className={styles.bottom}>
            <VenueMeta
              distance={parseInt(venue.distance, 10)}
              direction={venue.direction}
              coords={[venue.latitude, venue.longitude]}
              users={venue.latestUsers ? venue.latestUsers.users : null}
              category={getDisplayedCategory(venue)}
              // nearMe={nearMe}
              overallRating={venue.overallRating}
              venue={venue}
            />
          </div>
        </div>
        <div className={mergeClassNames(styles.right, mobileStyle ? styles.mobile : null)}>{actionButtons}</div>
      </div>
      {modal}
    </>
  );
}

VenueSnippet.propTypes = propTypes;
VenueSnippet.defaultProps = defaultProps;

export default VenueSnippet;
