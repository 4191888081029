import {
  errorToast as sharedErrorToast,
  pointsToast as sharedPointsToast,
  raiseToast as sharedRaiseToast,
} from '../../../shared-react/src/util/toast';
import Branding from '../../../shared-react/src/config/branding';

/**
 * Wraps the toast functions in the shared library with the branding config
 * of tripfool and then exports these functions.
 */
export const raiseToast = (...args) => sharedRaiseToast(Branding, ...args);
export const pointsToast = (...args) => sharedPointsToast(Branding, ...args);
export const errorToast = (...args) => sharedErrorToast(Branding, ...args);
