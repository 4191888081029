import { CLEAR_TOKEN } from '../../../../shared-react/src/store/types';
import { SET_TOKEN } from '../../../../shared-react/src/store/types';

/**
 * Reducer for the auth token field in the store.
 *
 * @param {string} state the current auth token
 * @param {string} action.type the type of the action. Currently only 'setToken' is valid
 * @param {string} action.payload.token the bearer token
 *
 * @returns {string} the new auth token
 */
const authTokenReducer = (state = null, action) => {
  switch (action.type) {
    case SET_TOKEN:
      const token = action.payload.token;
      return token;
    case CLEAR_TOKEN:
      return null;
    default:
  }
  return state;
};

export default authTokenReducer;
