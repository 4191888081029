import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const GameHangmanGeneralRedirect = () => {
  const locationId = useSelector((state) => state.globalLocation?.location?.id);

  return <Redirect to={`/games/hangman/${locationId}`} />;
};

export default GameHangmanGeneralRedirect;
