/**
 * Configuration for the search page.
 */

import { CURRENT_SEASON } from '../components/Master/PageExplore/PopupItinerary/config';

/**
 * default values in the filter on the explore page
 */
export const FilterDefaults = {
  ages: [0, 1, 2],
  radius: [0, 1],
  nearMe: false,
  lowRisk: false,
  subcategory: 'all',
  price: false,
};

export const EventsFilterDefaults = {
  timePeriod: 'all',
  isOnlyZzEvents: false,
};

export const FestivalsFilterDefaults = {
  sort: 'date',
};

export const ItinerariesFilterDefaults = {
  season: CURRENT_SEASON,
};

export const SubcategoryLabels = {
  all: 'All',
  parks_nature: 'Parks and Nature',
  beaches: 'Beaches',
  museums: 'Museums',
  zoos: 'Zoos',
  theme_parks: 'Theme Parks',
  hikes: 'Hikes',
  outdoor: 'Outdoor',
};
