import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { generateTestAttributes, mergeClassNames } from '../../../util/props';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import { ExploreEllipsisPropType } from '../../../util/types';
import styles from './Tabs.module.scss';
import VBTab from '../../VBTab/VBTab';
import MaxWidthContainer from '../../MaxWidthContainer/MaxWidthContainer';
import TopEllipsis from '../TopEllipsis/TopEllipsis';
import { trimSlashes } from '../../../util/string';
import { useHeaderHeight } from '../../../util/size';
import { useHideHeaderManually } from '../../../hooks/header-hide';

const propTypes = {
  /** base URL of the current section */
  basePath: PropTypes.string,

  /** subsections, like Activities, Experts, and Itineraries. These will be included in tabs at teh top of the page */
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      /** value, used for matching and comparison as well as routing. The first subsection will be matched to the
       *  section's baseUrl */
      value: PropTypes.string,

      /** pretty name of the subsection */
      name: PropTypes.string,
    })
  ).isRequired,

  /** whether the tabs should be visible or hidden */
  visible: PropTypes.bool.isRequired,

  /** duration of the show/hide animation for sticky content in seconds */
  hideStickiesAnimationDuration: PropTypes.number.isRequired,

  /** array of items to put in the ellipsis, or null/empty for no ellipsis. If the resolution is mobile and there are
   *  ellipsis items, showAboutSection must be true
   */
  ellipsis: ExploreEllipsisPropType,

  /** node to render above the content, or beside the filter if there are no subsections */
  extraFilterContent: PropTypes.node,

  /** TODO: doc */
  filter: PropTypes.object,

  /** whether to prevent scroll to top if a tab is active or not */
  preventScrollToTop: PropTypes.bool,
};
const defaultProps = {
  setHeaderYOffset: undefined,
  ellipsis: undefined,
  extraFilterContent: undefined,
  filter: undefined,
  preventScrollToTop: false,
};

const mobileScrollPadding = 20; // Pixels to pad mobile tab scrolling

/**
 * Tabs at the top of the Explore page. Each tab represents a subsection. The filter is included in the left. If the
 * resolution is not mobile, an ellipsis can be rendered on the right side.
 *
 * @param {object} props
 */
const Tabs = ({
  basePath,
  subsections,
  visible,
  hideStickiesAnimationDuration,
  ellipsis,
  extraFilterContent,
  filter,
  preventScrollToTop,
}) => {
  const headerHeight = useHeaderHeight();
  const [transparency, setTransparency] = useState(true);
  const scrollDivRef = useRef();

  const { lteXs: mobileStyle, lt1280: tabletStyle } = useVBBreakpoint();

  const { search } = useLocation();

  const { isVisible: isHeaderVisible } = useSelector((state) => state.header);

  useHideHeaderManually(visible || !tabletStyle);

  const tabStyle = {
    position: 'sticky',
    top: `${headerHeight}px`,
    left: '0',
    right: '0',
  };

  const getScrollConstraint = () => {
    if (!scrollDivRef?.current) return 0;
    // Find how wide the scroll div's content is versus how much content is actually displaying (due to overflow hidden)
    const constraint = scrollDivRef.current.scrollWidth - scrollDivRef.current.offsetWidth;
    if (constraint < 0) return 0; // Useless if negative
    return constraint;
  };

  const scrollConstraint = getScrollConstraint();
  const dragConstraints = { left: -scrollConstraint - mobileScrollPadding, right: 0 };
  const isScrollable = scrollConstraint !== 0 && mobileStyle;

  const getTabProps = useCallback(
    ({ id, name, value, isActive, outlink, to, onClick }, idx) => {
      const hasOutlink = Boolean(outlink);

      if (hasOutlink) {
        return {
          to: outlink,
          content: name,
          key: outlink,
          id,
          isActive,
          onClick,
        };
      }

      const hasValue = Boolean(value);

      if (hasValue) {
        const nextRoute = `/${trimSlashes(basePath)}/${value}${search}`;

        return {
          to: nextRoute,
          content: name,
          key: value,
          exact: true,
          id,
          isActive,
          onClick,
        };
      }

      return {
        content: name,
        key: idx,
        id,
        to,
        isActive,
        onClick,
      };
    },
    [basePath, search]
  );

  useEffect(() => {
    if (!isScrollable) {
      setTransparency(true); // Reset
    }
  }, [isScrollable]);

  const handleDrag = (e, info) => {
    // If user is scrolled all the way to the right, then turn off the transparency gradient
    setTransparency(info?.offset?.x >= dragConstraints.left + mobileScrollPadding);
  };

  return (
    <div
      className={mergeClassNames(
        styles.container,
        tabletStyle ? styles.tablet : null,
        !subsections.length ? styles.noTabs : null
      )}
      style={{
        ...tabStyle,
        transitionDuration: `${hideStickiesAnimationDuration}s`,
        transform: `translateY(${isHeaderVisible ? 0 : `${-headerHeight}px`})`,
      }}
    >
      <MaxWidthContainer className={styles.tabsWrapper}>
        <div
          className={!mobileStyle ? styles.filterContainerDesktop : null}
          {...generateTestAttributes({ id: 'vb-explore-filter-mobile' })}
        >
          {filter}
        </div>
        {subsections.length ? (
          <div
            className={mergeClassNames(
              styles.scrollDivContainer
              // transparency && isScrollable ? styles.transparencyGradient : null
            )}
          >
            <div className={styles.scrollDiv} ref={scrollDivRef} onScroll={handleDrag}>
              {subsections.map((subSection, idx) => {
                const tabProps = getTabProps(subSection, idx);

                return <VBTab preventScrollToTop={preventScrollToTop} {...tabProps} />;
              })}
            </div>
          </div>
        ) : (
          extraFilterContent
        )}
        {ellipsis?.length && !mobileStyle ? <TopEllipsis ellipsis={ellipsis} /> : null}
      </MaxWidthContainer>
    </div>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
