import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import MaxWidthContainer from '../../../../../../shared-react/src/components/MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../../../../../../shared-react/src/components/VBHeading/VBHeading';
import VBField from '../../../../../../shared-react/src/components/VBForm/VBInput/VBField';
import VBTextArea from '../../../../../../shared-react/src/components/VBTextArea/VBTextArea';
import { invalidateList } from '../../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import VBButton from '../../../../../../shared-react/src/components/VBButton/VBButton';
import { useClosePopup } from '../../../../../../shared-react/src/hooks/popup';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { vbRequest } from '../../../../util/api';

import styles from './PopupUpdateArticle.module.scss';

const propTypes = {
  article: PropTypes.object.isRequired,
  locationDetails: PropTypes.object.isRequired,
};

const PopupUpdateArticle = ({ article, locationDetails }) => {
  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: article,
  });

  const closePopup = useClosePopup();

  const { isValid, isSubmitted } = formState;

  const onSubmit = useCallback(
    (values) => {
      vbRequest(`${APIConfig.NAMESPACE.ARTICLE}/${article.id}`, { method: 'PUT', body: values }).then(() => {
        dispatch(invalidateList(ContentTypes.articlePost.type, (ctx) => ctx === `article-post:${article.postSlug}`));

        dispatch(
          invalidateList(
            ContentTypes.venueWithArticles.type,
            (ctx) => ctx === `venues-with-articles:${locationDetails.id}`
          )
        );
      });

      closePopup();
    },
    [article, closePopup, dispatch, locationDetails]
  );

  return (
    <MaxWidthContainer>
      <VBHeading size="sm">Edit Article</VBHeading>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <VBField name="heading" prettyLabel="Title" rules={{ required: true }} control={control} />
        <Controller
          name="content"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <VBTextArea label="Content" value={value} onChange={onChange} grow />
          )}
        />
        <div className={styles.buttons}>
          <VBButton
            type={isValid ? 'full' : 'empty'}
            disabled={!isValid || isSubmitted}
            size="med"
            content="Save"
            submit
          />
          <VBButton type="border" size="med" content="Cancel" onClick={closePopup} />
        </div>
      </form>
    </MaxWidthContainer>
  );
};

PopupUpdateArticle.propTypes = propTypes;

export default PopupUpdateArticle;
