export const HeaderMenuItem = {
  HOME: 'home',
  POST: 'post',
  EXPLORE: 'explore',
  ACTIVITY: 'activity',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGN_UP: 'signUp',
  NOTIFICATIONS: 'notifications',
  MY_PROFILE: 'myProfile',
  BLOG: 'blog',
  GAME_HANGMAN: 'gameHangman',
  SETTINGS: 'settings',
  ADMIN_SETTINGS: 'adminSettings',
};
