import { useCallback, useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import PropTypes from 'prop-types';
import VBTextInput from '../../../../../../shared-react/src/components/VBTextInput/VBTextInput';
import useDropdown from '../../../../../../shared-react/src/hooks/dropdown';
import DummyUsersDropdownList from '../../../../../../shared-react/src/components/DummyUsersDropdownList/DummyUsersDropdownList';
import useCurrentUser from '../../../../hooks/current-user';

import styles from './Author.module.scss';

const propTypes = {
  author: PropTypes.object,
  onSelect: PropTypes.func,
};

const Author = ({ author, onSelect }) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const { user: currentUser } = useCurrentUser();

  const [inputValue, setInputValue] = useState(author.username);
  const [isFilterMode, setIsFilterMode] = useState(false);

  const { width = 0 } = useResizeObserver({ ref: containerRef });

  const inputHeight = '48px';

  const { dropdown, open } = useDropdown({
    dropdown: (
      <DummyUsersDropdownList
        filter={inputValue}
        isFilterMode={isFilterMode}
        elementWidth={width}
        inputHeight={inputHeight}
        onSelect={onSelect}
        border
        shadow
      />
    ),
    dropdownClassName: styles.dropdownList,
  });

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (value !== undefined) {
        setInputValue(value);
        setIsFilterMode(true);
      }

      if (value === '') {
        setIsFilterMode(false);
      }

      open();
    },
    [open]
  );

  useEffect(() => {
    if (author) {
      setInputValue(author.username);
      setIsFilterMode(false);
    }
  }, [author]);

  return (
    <div className={styles.author} ref={containerRef}>
      <VBTextInput
        type="text"
        autoComplete="nope"
        height={inputHeight}
        className={styles.searchInput}
        value={inputValue}
        placeholder={currentUser.username}
        inputRef={inputRef}
        onChange={handleChange}
        onClick={open}
      />
      {dropdown}
    </div>
  );
};

Author.propTypes = propTypes;

export default Author;
