import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

const propTypes = {
  // Component to render when the route matches
  component: PropTypes.elementType.isRequired,
  // Extra path url to insert after the match and before the location arguments.
  // (this was added for the trending url which is kind of weird and we may want to change sometime).
  afterPath: PropTypes.string,
};
const defaultProps = {
  afterPath: '',
};

/**
 * The search/top 25 page. Must be supplied directly to a route.
 */
function RouteWithGlobalLocation({ component, afterPath }) {
  const { location } = useSelector((state) => state.globalLocation);
  const match = useRouteMatch();
  const base = match.path + afterPath;

  return (
    <Switch>
      <Route path={`${base}/:country/:region/:city`} component={component} />
      {location ? (
        <Redirect to={`${base}/${location?.countrySlug}/${location?.regionSlug}/${location?.citySlug}`} />
      ) : null}
    </Switch>
  );
}

RouteWithGlobalLocation.propTypes = propTypes;
RouteWithGlobalLocation.defaultProps = defaultProps;

export default RouteWithGlobalLocation;
