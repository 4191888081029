import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /** number of days in the itinerary */
  days: PropTypes.number,

  /** tripscore of the itinerary */
  tripscore: PropTypes.number,
};

const defaultProps = {
  days: undefined,
  tripscore: undefined,
};

/**
 * Display of days and tripscore used by both itinerary card and trip card.
 * @param {object} props
 */
const ItineraryTripInfo = ({ days, tripscore }) => {
  const hasDays = typeof days !== 'undefined';
  const hasTripscore = typeof tripscore !== 'undefined';
  const tripvalue = tripscore < 0 ? 0 : tripscore;
  return (
    <div>
      {hasDays ? `${days}d` : null}
      {hasDays && hasTripscore ? ' | ' : null}
      {hasTripscore ? `TripScore: ${tripvalue.toFixed(0)}` : null}
    </div>
  );
};

export default ItineraryTripInfo;
ItineraryTripInfo.propTypes = propTypes;
ItineraryTripInfo.defaultProps = defaultProps;
