import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import VBLink from '../../VBLink/VBLink';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';
import { useTopImageMaxRatio } from '../../../hooks/top-image-max-ratio';
import { mergeClassNames } from '../../../util/props';

import styles from './CardImage.module.scss';

import 'react-loading-skeleton/dist/skeleton.css';

const propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string,
  isOutlink: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAvatar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
};

const CardImage = ({ src, alt, title, width, height, link, isOutlink, isLoading, isAvatar, fullWidth, onClick }) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();
  const imageRatio = useTopImageMaxRatio();

  const classNames = mergeClassNames(
    isAvatar ? styles.avatar : styles.image,
    fullWidth && mobileStyle ? styles.noBorderRadius : null
  );

  if (isLoading) {
    return (
      <div
        className={mergeClassNames(classNames, styles.loaderContainer, fullWidth ? styles.fullWidth : null)}
        style={{ paddingBottom: fullWidth ? `${100 / imageRatio}%` : undefined }}
      >
        <Skeleton containerClassName={styles.loader} height="100%" />
      </div>
    );
  }

  const image = (
    <img
      src={src}
      alt={alt}
      title={title}
      width={width}
      height={height}
      className={mergeClassNames(classNames, onClick ? styles.pointer : null)}
      role={onClick ? 'button' : 'img'}
      onClick={onClick}
    />
  );

  return link ? (
    <VBLink to={link} newTab={isOutlink}>
      {image}
    </VBLink>
  ) : (
    image
  );
};

CardImage.propTypes = propTypes;

export default CardImage;
