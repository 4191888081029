import React from 'react';
import PropTypes from 'prop-types';
import VideoCard from '../../../../../../../shared-react/src/components/VideoCard/VideoCard';
import { PropTypesVBPost, PropTypesVBVenueVideo } from '../../../../../../../shared-react/src/util/types';
import RenderedPost from '../RenderedPost/RenderedPost';

const propTypes = {
  /** item to render */
  item: PropTypes.oneOf([PropTypesVBPost, PropTypesVBVenueVideo]).isRequired,

  /** contextual data unique to each content factory */
  contextual: PropTypes.any,

  /** Whether to use an h3 for the itinerary title (for SEO purposes, does not affect style) */
  titleH3Seo: PropTypes.bool,

  isSelected: PropTypes.bool,
};

const defaultProps = {
  contextual: undefined,
  titleH3Seo: false,
  isSelected: false,
};

/**
 * Post or Video rendered by PageExplore.
 *
 * @param {object} props
 */
const RenderedPostOrVideo = ({ item, contextual, titleH3Seo, isSelected }) => {
  if (item.post) {
    return <RenderedPost item={item.post} contextual={contextual} titleH3Seo={titleH3Seo} />;
  } else {
    return (
      <div style={{ minHeight: '280px' }}>
        <VideoCard video={item.video} disablePadding="ltSm" titleH3Seo={titleH3Seo} isSelected={isSelected} />
      </div>
    );
  }
};

export default RenderedPostOrVideo;

RenderedPostOrVideo.propTypes = propTypes;
RenderedPostOrVideo.defaultProps = defaultProps;
