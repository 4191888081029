import React, { useState, useRef, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import VBSpinner from '../VBSpinner/VBSpinner';
import VBButton from '../VBButton/VBButton';
import styles from './VBPhotoUpload.module.scss';
import ImageUpload from '../../../assets/images/icons/forms/upload.svg';
import ImageCancel from '../../../assets/images/icons/popups/cancel-circle.svg';
import OnClickLink from '../OnClickLink/OnClickLink';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import { getCroppedImg } from '../../util/images';
import useVbContext from '../../hooks/vb-context';

const CropWrapper = lazy(() => import('./CropWrapper/CropWrapper'));

/**
 * Photo upload input.
 * IMPORTANT: You likely want to us the UploadPhotoSelector component for a wrapper
 *            that will actually upload the image and return the media id.
 *
 * @param {function}    onUpload            Callback function, runs after user uploads a valid photo. Returns the file.
 * @param {bool}        allowMultiple       Whether or not it should allow multiple photos to be uploaded.
 * @param {boolean}     disabled            Whether or not the selector is disabled.
 * @param {boolean}     showPreview         Whether or not to show image preview after selecting a file (true by default, not working with 'textarea' theme)
 * @param {boolean}     short               When enabled, the previews area will only be shown if an image is selected
 * @param {boolean}     showCancel          whether or not to show the option to void the image selection (not working with 'textarea' theme)
 * @param {boolean}     loading             whether to show the image as loading
 * @param {string}      src                 initial image to render, as a URL or data string
 * @param {string}      theme               Name of component theme. Can be either 'basic', 'subdued' or 'textarea'
 * @param {string}      containerClassName  Class name for the container
 */
const VBPhotoUpload = ({
  short,
  showCancel,
  onUpload,
  disabled,
  showPreview,
  allowMultiple,
  loading: isUploadLoading,
  src,
  allowCrop,
  theme,
  containerClassName,
  onCropParamsChange: handleCropParamsChange,
  onIsCroppingChange: handleIsCroppingChange,
  ...other
}) => {
  const [isCropping, setIsCropping] = useState(false);
  const [cropParams, setCropParams] = useState();
  const [isCropLoading, setIsCropLoading] = useState(false);

  const cropRef = useRef();

  // Tracks if user is dragging a file over the file upload
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [imageName, setImageName] = useState('');

  const [imageOriginal, setImageOriginal] = useState(src ?? '');
  const [imageOriginalDim, setImageOriginalDim] = useState(null);
  const [imagePreview, setImagePreview] = useState(src ?? '');

  const [lastSrc, setLastSrc] = useState(src);

  // Solves this issue: // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
  const dragCount = useRef(0);

  useEffect(() => {
    if (src !== lastSrc) {
      setLastSrc(src);
      if (src) {
        setImageOriginal(src);
        setImagePreview(src);
        const img = new Image();
        img.onload = () => {
          setImageOriginalDim({
            width: img.width,
            height: img.height,
          });
          setLoading(false);
        };
        img.src = src;
      } else {
        setImageOriginal('');
        setImagePreview('');
        setImageOriginalDim(null);
      }
    }
  }, [imageOriginal, lastSrc, src]);

  const canUse = !disabled && !loading && !isUploadLoading;

  const { brandingConfig, vbBlue } = useVbContext();
  const {
    ICONS: { UPLOAD_PENCIL: ImageEditPencil, UPLOAD_CROP: ImageCrop },
  } = brandingConfig;

  const fileInput = useRef(null);

  // When the user drops a file into the file upload
  const handleDrag = (e) => {
    if (!canUse) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  // When the user drops a file into the file upload
  const handleDragIn = (e) => {
    if (!canUse) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    dragCount.current += 1;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);

      // Clear error message
      setErrorMessage('');
    }
  };

  // When the user drops a file into the file upload
  const handleDragOut = (e) => {
    if (!canUse) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    dragCount.current -= 1;

    if (dragCount.current > 0) {
      return;
    }

    setDragging(false);
  };

  const displayPreview = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const { result } = reader;
      setImagePreview(result);
      setImageOriginal(result);
      const img = new Image();
      img.onload = () => {
        setImageOriginalDim({
          width: img.width,
          height: img.height,
        });
        setLoading(false);
      };
      img.src = result;
    };
    reader.readAsDataURL(file);
    setLoading(true);
  };

  const processFiles = useCallback(
    (files) => {
      if (files && files.length > 0) {
        if (files.length > 1) {
          setErrorMessage('Too many files uploaded');
          return;
        }

        if (!files[0].type.includes('image')) {
          setErrorMessage('File must be an image');
          return;
        }

        onUpload?.(files);

        dragCount.current = 0;

        const [image] = files;

        // Save image name
        setImageName(image.name);

        // Load image into element to preview
        displayPreview(image);
      } else {
        // No files uploaded
        setErrorMessage('No files uploaded');
      }
    },
    [onUpload]
  );

  // When the user drops a file into the file upload
  const handleDrop = (e) => {
    if (!canUse) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    // User dropped file

    // This handles drag/drop from firefox.
    for (const item of Object.values(e.dataTransfer.items)) {
      if (item.type === 'application/x-moz-file-promise-url') {
        item.getAsString((s) =>
          (async () => {
            const response = await fetch(s);
            const imageBlob = await response.blob();
            const imageFile = new File(
              [imageBlob],
              `image.${imageBlob.type.substring(imageBlob.type.indexOf('/') + 1)}`,
              {
                type: imageBlob.type,
              }
            );
            setDragging(false);
            processFiles([imageFile]);
            e.dataTransfer.clearData();
          })()
        );
      }
    }

    // This handles drag/drop from edge/chrome
    if (e.dataTransfer.files.length) {
      setDragging(false);
      processFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleClick = () => {
    if (!canUse) return;
    fileInput.current.click();
  };

  /**
   * Handle click of the cancel button.
   * @param {React.SyntheticEvent} e
   */
  const handleCancel = useCallback(
    (e) => {
      e.stopPropagation();
      fileInput.current.value = '';
      setImagePreview('');
      onUpload?.(null);
    },
    [onUpload]
  );

  const errorMessageElement = useMemo(() => {
    return <div className={styles.error}>{errorMessage}</div>;
  }, [errorMessage]);

  const textDefaultContent = useMemo(() => {
    switch (theme) {
      case 'basic': {
        return (
          <>
            <div className={mergeClassNames(styles.big, styles.blue)}>Take Photo</div>
            <div>
              Or <span className={mergeClassNames(styles.browse, styles.blue)}>Browse</span> to
            </div>
            <div>Choose {allowMultiple ? 'Files' : 'a File'}</div>
            {errorMessage.length > 0 ? errorMessageElement : null}
          </>
        );
      }
      case 'subdued': {
        return (
          <>
            <div className={mergeClassNames(styles.big)}>+</div>
            <div>Add photos or media</div>
            {errorMessage.length > 0 ? errorMessageElement : null}
          </>
        );
      }
      case 'textarea': {
        return <div>Add Photo</div>;
      }
      default: {
        return null;
      }
    }
  }, [allowMultiple, errorMessage.length, errorMessageElement, theme]);

  const textDraggingContent = useMemo(() => {
    switch (theme) {
      case 'basic': {
        return (
          <>
            <div className={mergeClassNames(styles.big, styles.blue)}>Drop file</div>
            <div className={mergeClassNames(styles.big, styles.blue)}>to upload</div>
          </>
        );
      }
      case 'subdued': {
        return (
          <>
            <div className={mergeClassNames(styles.big)}>+</div>
            <div>Drop file to upload</div>
          </>
        );
      }
      case 'textarea': {
        return <div>Drop file to upload</div>;
      }
      default: {
        return null;
      }
    }
  }, [theme]);

  const handleCropClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setIsCropping(true);
  };

  const uploadContent = useMemo(() => {
    const hasImagePreview = Boolean(imagePreview.length);

    if (!hasImagePreview) {
      return <div className={styles.innerText}>{dragging ? textDraggingContent : textDefaultContent}</div>;
    }

    if (theme === 'textarea') {
      return <div className={styles.innerText}>{imageName}</div>;
    }

    return (
      <div className={styles.imagePreview}>
        {canUse && (
          <div className={styles.topIconContainer}>
            <img src={ImageEditPencil} className={styles.icon} alt="Edit" />
            {!!allowCrop && (
              <OnClickLink noStyle onClick={handleCropClick}>
                <img src={ImageCrop} className={styles.icon} alt="Edit" />
              </OnClickLink>
            )}
          </div>
        )}
        {showPreview && (
          <img
            src={imagePreview}
            className={mergeClassNames(styles.file, isUploadLoading ? styles.loading : null)}
            alt="Preview"
          />
        )}
        {showCancel && !disabled && !isUploadLoading && (
          <OnClickLink onClick={handleCancel}>
            <img src={ImageCancel} className={mergeClassNames(styles.icon, styles.cancel)} alt="Cancel" />
          </OnClickLink>
        )}
        {isUploadLoading && (
          <div className={styles.uploadLoading}>
            <VBSpinner />
          </div>
        )}
      </div>
    );
  }, [
    ImageCrop,
    ImageEditPencil,
    allowCrop,
    canUse,
    disabled,
    dragging,
    handleCancel,
    imageName,
    imagePreview,
    isUploadLoading,
    showCancel,
    showPreview,
    textDefaultContent,
    textDraggingContent,
    theme,
  ]);

  const inputContent = useMemo(
    () => (
      <input
        type="file"
        accept="image/*"
        ref={fileInput}
        onChange={() => processFiles(fileInput.current.files)}
        className={styles.hiddenInput}
        {...other}
      />
    ),
    [other, processFiles]
  );

  let cropScale;
  if (cropRef?.current) {
    const rect = cropRef.current.getBoundingClientRect();
    cropScale = rect.width / imageOriginalDim.width;
  }

  const commitCrop = () => {
    setIsCropLoading(true);
    getCroppedImg(imageOriginal, cropParams, cropScale).then((imageSrc) => {
      setImagePreview(imageSrc);
      setIsCropping(false);
      setIsCropLoading(false);
    });
  };

  // Data binding for parent.
  useEffect(() => {
    if (handleIsCroppingChange) handleIsCroppingChange(isCropping);
  }, [handleIsCroppingChange, isCropping]);

  useEffect(() => {
    if (cropScale && cropParams && handleCropParamsChange) {
      handleCropParamsChange({
        x: cropParams.x / cropScale,
        y: cropParams.y / cropScale,
        width: cropParams.width / cropScale,
        height: cropParams.height / cropScale,
      });
    }
  }, [handleCropParamsChange, cropParams, cropScale]);

  if (short && !imagePreview.length) {
    return (
      <>
        {inputContent}
        <OnClickLink className={styles.short} onClick={handleClick}>
          <ColoredIcon src={ImageUpload} color={vbBlue} />
          <span className={styles.text}>Upload Media</span>
        </OnClickLink>
      </>
    );
  }

  return (
    <>
      {inputContent}
      {isCropping ? (
        <div className={styles.cropContainer}>
          {isCropLoading ? (
            <VBSpinner />
          ) : (
            <>
              <div ref={cropRef}>
                <Suspense fallback={<VBSpinner center />}>
                  <CropWrapper src={imageOriginal} crop={cropParams} onChange={setCropParams} />
                </Suspense>
              </div>
              <div className={styles.buttons}>
                <VBButton type="full" size="med" content="Save" onClick={commitCrop} />
                <VBButton type="border" size="med" content="Cancel" onClick={() => setIsCropping(false)} />
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={mergeClassNames(
            styles.container,
            imagePreview.length === 0 && styles.bordered,
            theme === 'subdued' && styles.subdued,
            theme === 'textarea' && styles.textarea,
            containerClassName
          )}
          onClick={handleClick}
          onDragOver={handleDrag}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDrop={handleDrop}
          role="tab"
          tabIndex="0"
        >
          {loading ? <VBSpinner /> : uploadContent}
        </div>
      )}
    </>
  );
};

VBPhotoUpload.propTypes = {
  allowCrop: PropTypes.bool,
  onUpload: PropTypes.func,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  showPreview: PropTypes.bool,
  short: PropTypes.bool,
  showCancel: PropTypes.bool,
  loading: PropTypes.bool,
  src: PropTypes.string,
  onCropParamsChange: PropTypes.func,
  onIsCroppingChange: PropTypes.func,
  theme: PropTypes.oneOf(['basic', 'subdued', 'textarea']),
  containerClassName: PropTypes.string,
};

VBPhotoUpload.defaultProps = {
  onUpload: undefined,
  allowMultiple: false,
  disabled: false,
  short: false,
  showPreview: true,
  showCancel: false,
  loading: false,
  src: undefined,
  allowCrop: false,
  onCropParamsChange: undefined,
  onIsCroppingChange: undefined,
  theme: 'basic',
  containerClassName: undefined,
};

export default VBPhotoUpload;
