import { useEffect, useState } from 'react';
import { useContentFactory } from '../../../../../../../shared-react/src/hooks/content-factory';

const useExploreContent = (contentFactoryProps) => {
  const { content, loading, refreshing, ...rest } = useContentFactory(contentFactoryProps);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!loading && !refreshing) {
      setData(content);
    }
  }, [content, loading, refreshing]);

  return {
    data,
    isLoading: !data,
    ...rest,
  };
};

export default useExploreContent;
