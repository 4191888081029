/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import VBTextInput from '../VBTextInput/VBTextInput';
import styles from './Share.module.scss';
import SocialShareButton from './SocialShareButton/SocialShareButton';
import ImageCopyClipboard from '../../../assets/images/icons/glyphs/copy-clipboard.svg';
import { isMobile } from '../../util/platforms';
import VBSpinner from '../VBSpinner/VBSpinner';
import ImageEmail from '../../../assets/images/icons/social/email.svg';
import ImageFacebook from '../../../assets/images/icons/social/facebook.svg';
// import ImageMessenger from '../../../assets/images/icons/social/messenger.svg';
// import ImagePinterest from '../../../assets/images/icons/social/pinterest.svg';
import ImageTwitter from '../../../assets/images/icons/social/twitter.svg';
import ImageSms from '../../../assets/images/icons/social/sms.svg';

const propTypes = {
  /** whether or not to include the link in a text field below. Will use permalink prop */
  includeLink: PropTypes.bool,

  /** given the platform as an argument, return either the share link or a promise which resolved the share link */
  getPermalink: PropTypes.func.isRequired,

  /** hashtag (without the pound sign) to use for the share */
  hashtag: PropTypes.string.isRequired,

  /** subject of the share (currently only used for email) */
  subject: PropTypes.string.isRequired,

  /** text content of the share */
  description: PropTypes.string.isRequired,

  /** additional class names */
  className: PropTypes.string,
};

const defaultProps = {
  includeLink: false,
  className: undefined,
};

/**
 * Buttons to share content, and optionally a textbox to copy a link.
 *
 * @param {object} props
 */
const Share = ({ className, includeLink, getPermalink, hashtag, subject, description }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [permalink, setPermalink] = useState(null);
  const [isPermalinkLoading, setIsPermalinkLoading] = useState(false);

  useEffect(() => {
    setIsPermalinkLoading(true);
    Promise.resolve(getPermalink('direct'))
      .then((link) => {
        setPermalink(link);
      })
      .finally(() => setIsPermalinkLoading(false));
  }, [getPermalink]);

  // Elements
  const elShareLinkContainer = useRef(null);
  const elShareLink = useRef(null);

  const platforms = [
    { image: ImageFacebook, name: 'facebook', prettyName: 'Facebook' },
    { image: ImageTwitter, name: 'twitter', prettyName: 'Twitter' },
    { image: ImageEmail, name: 'email', prettyName: 'Email' },
    // { image: ImageMessenger, name: 'facebook-messenger', prettyName: 'Messenger' },
    // { image: ImagePinterest, name: 'pinterest', prettyName: 'Pinterest' },
  ].concat(isMobile() ? [{ image: ImageSms, name: 'sms', prettyName: 'SMS' }] : []);

  // Copy the permalink to the user's clipboard
  const copyLink = () => {
    // get the native dom text element
    const el = elShareLink.current;
    el.select();
    el.setSelectionRange(0, el.value.length);
    document.execCommand('copy'); // Force copy

    setShowTooltip(true);

    // Hide tooltip after 2 seconds
    window.setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <>
      <div className={mergeClassNames(className, styles.container)}>
        <div className={styles.shareButtons}>
          {platforms.map((p) => (
            <SocialShareButton
              platform={p.name}
              icon={p.image}
              displayText={p.prettyName}
              hashtag={hashtag}
              subject={subject}
              description={description}
              getPermalink={getPermalink}
              flexBasis={`${100 / platforms.length}%`}
              key={p.name}
            />
          ))}
        </div>
        {includeLink && (
          <div
            ref={elShareLinkContainer}
            className={mergeClassNames(styles.linkContainer, showTooltip && styles.showTooltip)}
            data-tooltip="Copied"
          >
            <VBTextInput
              inputRef={elShareLink}
              className={styles.shareLink}
              value={permalink ?? ''}
              readOnly
              onClick={() => copyLink()}
              style={{ backgroundColor: 'white' }}
            />
            {isPermalinkLoading ? (
              <VBSpinner className={styles.inputSpinner} />
            ) : (
              <span className={styles.iconRight} onClick={() => copyLink()} role="tab" tabIndex="0">
                <div
                  className={mergeClassNames('bar_icon pr-3', styles.copyIcon)}
                  onClick={() => copyLink()}
                  role="tab"
                  tabIndex="0"
                >
                  <ColoredIcon src={ImageCopyClipboard} color="#ccc" />
                </div>
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Share;

Share.propTypes = propTypes;
Share.defaultProps = defaultProps;
