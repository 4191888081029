import React from 'react';
import PropTypes from 'prop-types';
import VBInputLabel from '../../VBInputLabel/VBInputLabel';
import VBButton from '../../VBButton/VBButton';
import styles from './DropdownFilterActivities.module.scss';
import RadiusSelector from '../RadiusSelector/RadiusSelector';
import { mergeClassNames } from '../../../util/props';
import VBRadioGroup from '../../VBRadioGroup/VBRadioGroup';
import VBRadioButton from '../../VBRadioGroup/VBRadioButton/VBRadioButton';

const propTypes = {
  /** stringified array of radiuses, ie '[0, 1]', '[2]'. See DISTANCE_RANGES in config/explore.jsx */
  radius: PropTypes.string.isRequired,

  /** setter for radius */
  setRadius: PropTypes.func.isRequired,

  /** whether or not to filter out high risk covid venues */
  lowRisk: PropTypes.bool,

  /** setter for low risk */
  setLowRisk: PropTypes.func,

  /** whether or not there should be horizontal padding on the outer div */
  noPadding: PropTypes.bool,

  /** Whether only free venues should be displayed */
  isFree: PropTypes.bool,

  /** Setter for free venues */
  setIsFree: PropTypes.func,
};

const defaultProps = {
  lowRisk: undefined,
  setLowRisk: undefined,
  isFree: undefined,
  setIsFree: undefined,
  noPadding: false,
};

/**
 * Dropdown to use to filter venues. Includes radius and virus risk filters.
 *
 * @param {object} props
 */
const DropdownFilterActivities = ({ radius, setRadius, lowRisk, setLowRisk, noPadding, isFree, setIsFree }) => {
  return (
    <div className={mergeClassNames(styles.dropdown, !noPadding ? styles.padded : null)}>
      <div className={styles.inputContainer}>
        <VBInputLabel content="Radius" noMargin />
        <RadiusSelector radius={radius} setRadius={setRadius} />
      </div>
      {typeof lowRisk !== 'undefined' && typeof setLowRisk !== 'undefined' ? (
        <VBButton
          type={lowRisk ? 'full' : 'border'}
          size="med"
          content="Outdoor"
          className={styles.outdoor}
          onClick={() => setLowRisk(!lowRisk)}
        />
      ) : null}
      {typeof isFree !== 'undefined' && typeof setIsFree !== 'undefined' ? (
        <div className={styles.inputContainer}>
          <VBInputLabel content="Price" noMargin />
          <VBRadioGroup selected={isFree} onChange={setIsFree}>
            <VBRadioButton value={false} label="All" />
            <VBRadioButton value={true} label="Free" />
          </VBRadioGroup>
        </div>
      ) : null}
    </div>
  );
};
export default DropdownFilterActivities;

DropdownFilterActivities.propTypes = propTypes;
DropdownFilterActivities.defaultProps = defaultProps;
