import { usePopup } from '../../../shared-react/src/hooks/popup';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openAuthPopup } from '../store/actions/authPopupOpenActions';
/**
 * Hook to use the login/signup popup.
 *
 * @returns {(type: string): void} function to open the popup
 */
export const useOpenAuthPopup = () => {
  const dispatch = useDispatch();
  return useCallback((type = 'signUp') => dispatch(openAuthPopup(type)), [dispatch]);
};
export default usePopup;
