/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import { getCSSFilters } from '../../util/colors';
import Styles from './ColoredIcon.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** CSS string representing the color of the icon */
  color: PropTypes.string,

  /** style to put on the image */
  style: PropTypes.object,

  /** component to render as */
  as: PropTypes.func,

  /** additional filters to add to the image */
  additionalFilters: PropTypes.string,

  /** size to give to the icon */
  size: PropTypes.oneOf(['tny', 'xs', 'sm', 'med', 'lg']),
  /** Will put a circle background behind the image. Accepts any color string 'white' or some hex value.
   * Note: You may want to use the style.padding to give more space between icon and bubble.
   */
  bubbleBackground: PropTypes.string,
  /** If you change the color this will be the transition time for changing it. Ex. ".5s"
   * Note: the transition can look kind of weird. For example I was seeing purple when going between black and blue.
   */
  colorTransitionTime: PropTypes.string,
};

const defaultProps = {
  color: undefined,
  style: undefined,
  as: undefined,
  additionalFilters: undefined,
  size: null,
  bubbleBackground: undefined,
  colorTransitionTime: null,
};

/**
 * Given a white icon, rendered a colored version. Has the same props as <img />, along with color.
 *
 * @param {object} props
 */
const ColoredIcon = ({
  color,
  style,
  additionalFilters,
  as,
  size,
  bubbleBackground,
  colorTransitionTime,
  ...other
}) => {
  const appliedStyle = {
    ...style,
  };

  if (typeof color !== 'undefined')
    Object.assign(appliedStyle, { filter: getCSSFilters(color) + (additionalFilters ? ` ${additionalFilters}` : '') });

  if (colorTransitionTime) {
    Object.assign(appliedStyle, { transition: `filter ${colorTransitionTime}` });
  }

  if (as) {
    const As = as;
    return <As style={appliedStyle} {...other} />;
  }
  const sizeClass = size ? Styles[size] : '';
  const icon = <img className={sizeClass} style={appliedStyle} alt="" {...other} />;
  if (bubbleBackground) {
    // Unable to just put a background color on the image because of the "filter" css attribute which messes with the background color.
    // So, we have to put the background color on a div.
    return (
      <div className={mergeClassNames(Styles.bubble, sizeClass)} style={{ backgroundColor: bubbleBackground }}>
        {icon}
      </div>
    );
  }
  return icon;
};
export default ColoredIcon;

ColoredIcon.propTypes = propTypes;
ColoredIcon.defaultProps = defaultProps;
