import { CURRENT_USER_ERROR } from '../../../../shared-react/src/store/types';
import { CURRENT_USER_LOADING } from '../../../../shared-react/src/store/types';
import { CURRENT_USER_RECEIVED } from '../../../../shared-react/src/store/types';
/**
 * Action dispatchers dealing with the current user field of the store.
 */

import { vbRequest } from '../../util/api';
/**
 * Action dispatcher to set the current user.
 *
 * @param {object} user the user listing object
 *
 * @returns the action dispatcher for setting the current user
 */
export const requestCurrentUser = () => async (dispatch, getState) => {
  const { authToken } = getState();

  if (!authToken) {
    return dispatch({
      type: CURRENT_USER_RECEIVED,
      payload: { user: null },
    });
  }

  dispatch({
    type: CURRENT_USER_LOADING,
  });
  try {
    const { user } = await vbRequest('vb/v1/social/whoami');
    return dispatch({
      type: CURRENT_USER_RECEIVED,
      payload: { user },
    });
  } catch (error) {
    return dispatch({
      type: CURRENT_USER_ERROR,
      payload: { error },
    });
  }
};
