import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../../../util/props';
import { isCrawler } from '../../../../util/env';
import OnClickLink from '../../../OnClickLink/OnClickLink';
import MarkdownText from '../../../MarkdownText/MarkdownText';
import useVBBreakpoint from '../../../../hooks/vb-breakpoint';

import styles from './Description.module.scss';

const propTypes = {
  description: PropTypes.string.isRequired,
  matches: PropTypes.array.isRequired,
};

const Description = ({ description, matches }) => {
  const [dimensions, setDimensions] = useState(null);
  const [showMore, setShowMore] = useState(isCrawler());

  const { lteXs: mobileStyle } = useVBBreakpoint();

  const toggleShowMore = () => {
    setShowMore((value) => !value);
  };

  const handleClickText = useCallback(() => {
    if (mobileStyle && !showMore) {
      setShowMore(true);
    }
  }, [mobileStyle, showMore]);

  const hideShowMoreButton =
    mobileStyle || isCrawler() || (!showMore && dimensions?.scrollHeight <= dimensions?.clientHeight);

  const content = useMemo(() => {
    return (
      <div
        className={mergeClassNames(styles.content, !showMore && styles.truncated)}
        ref={(element) => {
          if (element) {
            setDimensions({ clientHeight: element.clientHeight, scrollHeight: element.scrollHeight });
          }
        }}
        onClick={handleClickText}
      >
        <MarkdownText text={description} matches={matches} />
      </div>
    );
  }, [description, handleClickText, matches, showMore]);

  return (
    <div className={styles.description}>
      {content}
      {!hideShowMoreButton && (
        <OnClickLink className={styles.more} onClick={toggleShowMore}>
          {showMore ? 'Show Less' : 'Show More'}
        </OnClickLink>
      )}
    </div>
  );
};

Description.propTypes = propTypes;

export default Description;
