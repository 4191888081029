import { ContentTypes } from '../config/content-types';
import { MOST_POPULAR_DETAILS_COUNT } from '../config/itineraries';
import { addParams } from './urls';

/**
 * Formats a number of hours for display in an itinerary. Will limit to 2 decimal places.
 *
 * @param {number} x
 * @returns {string}
 */
export const formatHours = (x) => {
  const floorX = Math.floor(x);
  const fracX = x - floorX;
  if (!fracX) return floorX.toString();
  let decimalPart = Math.floor(fracX * 100);
  if (decimalPart === 0) {
    return floorX.toString();
  }
  decimalPart = decimalPart.toString().match(/[^0]+/)[0];
  return `${floorX.toString()}.${decimalPart}`;
};

/**
 * Get the most popular activities from a list, sorted by rating descending.
 *
 * @param {object[]} activities
 *
 * @returns {object[]} the most popular activities
 */
export const getMostPopular = (activities, explorePage = true) =>
  [...activities]
    .map((prev, index) => ({ ...prev, index }))
    .sort((a, b) => b.venue?.overallRating - a.venue?.overallRating)
    .slice(0, explorePage ? MOST_POPULAR_DETAILS_COUNT : MOST_POPULAR_DETAILS_COUNT);

/**
 * Gets a list of activities from an itinerary
 * @param {array[]} days the days array in the itinerary
 * @returns {object[]} list of activities
 */
export const getAllActivities = (days) =>
  days?.reduce(
    (acc, { activities, dayIndex }) => acc.concat(activities.map((activity) => ({ ...activity, dayIndex }))),
    []
  );

/**
 * Gets an array of location objects consumable by the map from an array of activities.
 * This takes into account normal activities and custom activities.
 * @param {object[]} activities the activities to get locations from
 * @returns {object[]} list of locations consumable by map.
 */
export const getMapLocations = (activities, renderVenue) =>
  activities.map(({ venue, customActivity }, idx) => {
    // First try to use venue.
    let item = venue;
    let { type } = ContentTypes.venue;
    // It's a custom activity if no venue.
    if (!item) {
      // If a custom activity doesn't have a location, don't show on map but still skip a number on the map.
      if (!customActivity.latitude || !customActivity.longitude) return null;
      // If a custom activity has a location, show that on map. Also show thumbnail and name in card if selected.
      item = customActivity;
      item.featuredImage = customActivity.thumbnail;
      type = ContentTypes.customActivity.type;
    }
    return {
      ...item,
      renderCard: (handleClickNext, handleClickPrevious, isFullscreen) =>
        renderVenue({ item }, idx, handleClickNext, handleClickPrevious, isFullscreen),
    };
  });

/**
 * Creates a new itinerary of a given day length from an existing itinerary. This day length should be less than or
 * equal to that of the original itinerary.
 *
 * @param {objet} itinerary
 * @param {number} days
 *
 * @returns {object} the new itinerary
 */
export const truncateItinerary = (itinerary, days) => {
  if (days >= itinerary.days.length) return { ...itinerary };
  return {
    ...itinerary,
    days: itinerary.days.slice(0, days),
    permalink: addParams(itinerary.permalink, { days }),
    maxDays: itinerary.days.length,
  };
};

/**
 * Gets time, tripscore, distance, activities, and days for an itinerary. Itinerary should have activities with
 * tripscore and distance fields, and activities should have duration
 *
 * @param {object} itinerary
 *
 * @returns {object} object containing the above fields for the itinerary
 */
export const getItineraryMeta = (itinerary) =>
  itinerary.days.reduce(
    (dayAcc, dayCur) => {
      const data = dayCur.activities.reduce(
        (actAcc, { tripscore, distance, duration }) => ({
          time: actAcc.time + duration,
          tripscore: actAcc.tripscore + tripscore,
          distance: actAcc.distance + distance,
          activities: actAcc.activities + 1,
        }),
        {
          time: 0,
          tripscore: 0,
          distance: 0,
          activities: 0,
        }
      );
      return {
        time: dayAcc.time + data.time,
        tripscore: dayAcc.tripscore + data.tripscore,
        distance: dayAcc.distance + data.distance,
        activities: dayAcc.activities + data.activities,
        days: dayAcc.days + 1,
      };
    },
    {
      time: 0,
      tripscore: 0,
      distance: 0,
      activities: 0,
      days: 0,
    }
  );

/**
 * Given an activity index and days, get the correct day index.
 *
 * @param {number} activity activity index
 * @param {object[]} days the days, see PropTypesVBItineraryDay
 *
 * @return {number} the day index
 */
export const getDayIndex = (activity, days) => {
  let sumActivities = 0;
  for (let i = 0; i < days.length; i++) {
    const { activities } = days[i];
    sumActivities += activities.length;
    if (sumActivities > activity) {
      return i;
    }
  }
  return days.length - 1;
};

/**
 * Strips custom activities from a day.
 * @param {object} day VB day object
 *
 * @returns {object} the new day
 */
export const stripCustomActivities = (day) => {
  if (day.activities)
    return {
      ...day,
      activities: day.activities.filter((activity) => activity.venue),
    };
  return day.filter((activity) => activity.venue);
};

/**
 * Gets a featured image URL from an itinerary. Works with custom activities.
 *
 * @param {object} itnr VB itinerary object
 *
 * @returns {string} URL of the first featured image or custom activity thumbnail, or null for no images
 */
export const getFeaturedImageUrl = (itnr) => {
  if (!itnr?.days) return null;
  for (let i = 0; i < itnr.days.length; i++) {
    const hasImg = itnr.days[i].activities.find(
      (act) =>
        (act?.venue?.featuredImage && !act?.venue?.featuredImage.includes('Placeholder')) ||
        act?.customActivity?.thumbnail
    );
    if (hasImg) {
      return hasImg.customActivity?.thumbnail || hasImg.venue?.featuredImage;
    }
  }
  return null;
};

/**
 *
 * @param {object} itinerary an itinerary object
 * @param {int} days the number of days for the itinerary. itinerary.days.length is used as a fallback.
 * @returns the name of the itinerary with the proper number of days in front if it's dynamic length
 */
export const createItineraryName = (itinerary, days) => {
  const itineraryDays = days ?? itinerary.days.length;
  return itinerary.isDynamicLength
    ? // eslint-disable-next-line eqeqeq
      `${itineraryDays} Day${itineraryDays == 1 ? '' : 's'} in ${itinerary.name}`
    : itinerary.name;
};
