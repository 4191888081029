import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VBButton from '../../../../../../shared-react/src/components/VBButton/VBButton';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import useSendAnalyticsEvent from '../../../../../../shared-react/src/hooks/send-analytics-event';
import useDropdown from '../../../../../../shared-react/src/hooks/dropdown';
import DropdownRSVP from './DropdownRSVP/DropdownRSVP';
import useEventToggleRsvp from '../../../../hooks/event-toggle-rsvp';

const propTypes = {
  /** event to RSVP for. Should be a ZZ event */
  event: PropTypes.object.isRequired,

  redirectOnRsvp: PropTypes.string,
};

/**
 * Button to RSVP for an event.
 */
const RSVPButton = ({ event, redirectOnRsvp }) => {
  const [shouldSendEvent, setShouldSendEvent] = useState(true);

  const { openAuthPopup, currentUser } = useVbContext();
  const { isLoading, toggleRsvp } = useEventToggleRsvp();

  const userCountStr = `(${event.signUpCount}/${event.capacity})`;
  const isEventFull = event.signUpCount >= event.capacity;

  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { dropdown, open: openRsvp } = useDropdown({
    dropdown: <DropdownRSVP event={event} redirectOnRsvp={redirectOnRsvp} />,
  });

  const handleRsvpClick = () => {
    if (isLoading) return;
    if (!currentUser) {
      openAuthPopup();
      return;
    }
    if (!event.isUserSignedUp && shouldSendEvent) {
      sendAnalyticsEvent('vb_events_rsvp', 'vb_events');
      setShouldSendEvent(true);
    }
    if (!event.userSignUpCount) {
      if (!isEventFull) openRsvp();
    } else {
      toggleRsvp(event, 0);
    }
  };

  let buttonVariant = 'border';
  let buttonText = 'RSVP';
  if (currentUser) {
    if (event.userSignUpCount) {
      buttonText = `${event.userSignUpCount} RSVP'd ${userCountStr}`;
      buttonVariant = 'full';
    } else if (!isEventFull) {
      buttonText = `RSVP ${userCountStr}`;
    } else {
      buttonText = 'Event is Full';
      buttonVariant = 'empty';
    }
  }

  return (
    <>
      {dropdown}
      <VBButton onClick={handleRsvpClick} size="med" type={buttonVariant}>
        {buttonText}
      </VBButton>
    </>
  );
};

export default RSVPButton;
RSVPButton.propTypes = propTypes;
