import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import APIConfig from '../../config/api';
import styles from './UploadPhotoSelector.module.scss';
import useVbContext from '../../hooks/vb-context';
import VBPhotoUpload from '../VBPhotoUpload/VBPhotoUpload';
import { uppercaseFirst } from '../../util/string';

const propTypes = {
  /** title of content to use when uploading to WP */
  name: PropTypes.string,

  /** callback for when the image has been uploaded, {data, mediaCollectionId} */
  onUpload: PropTypes.func,

  /** callback for when the upload process has begun */
  onUploadStart: PropTypes.func,

  /** initial image to render, as a URL or data string */
  src: PropTypes.string,
};

const defaultProps = {
  name: undefined,
  onUpload: undefined,
  onUploadStart: undefined,
  src: undefined,
};

/**
 * Wrapper of VBPhotoUpload that will actually upload the image, and call onUpload with the resulting media collection
 * ID.
 *
 * @param {object} props
 */
const UploadPhotoSelector = ({ name, onUpload, onUploadStart, src: parentSrc, ...other }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [renderingUploader, setRenderingUploader] = useState(true);
  const [src, setSrc] = useState(parentSrc);

  const { vbRequest } = useVbContext();

  useEffect(() => {
    setSrc(parentSrc);
  }, [parentSrc]);

  // Remount the photo uploader if there was an error.
  useEffect(() => {
    if (!renderingUploader) setRenderingUploader(true);
  }, [renderingUploader]);
  useEffect(() => {
    if (error) setRenderingUploader(false);
  }, [error]);

  /**
   * Request to update the media.
   *
   * @returns {Promise}
   */
  const handleUpload = (files) => {
    if (!files || !files.length) {
      onUpload(null);
      return null;
    }
    if (onUploadStart) onUploadStart();
    setLoading(true);
    setError(null);

    const image = files[0];
    const submitData = new FormData();
    submitData.append('file', image);
    submitData.append('title', name ?? '');

    return vbRequest(`${APIConfig.NAMESPACE.MEDIA}/add`, {
      method: 'POST',
      contentType: false,
      body: submitData,
    })
      .then((data) => {
        if (onUpload) {
          const reader = new FileReader();
          reader.onload = () => {
            setLoading(false);
            const { result } = reader;
            onUpload({ mediaCollectionId: data.collection_id, src: result });
            setSrc(result);
          };
          reader.readAsDataURL(image);
        }
      })
      .catch((err) => setError(`${uppercaseFirst(err?.message ?? 'unknown error')}.`))
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.container}>
      {renderingUploader ? <VBPhotoUpload onUpload={handleUpload} loading={loading} src={src} {...other} /> : null}
      <div className={styles.error}>{error}</div>
    </div>
  );
};

export default UploadPhotoSelector;

UploadPhotoSelector.propTypes = propTypes;
UploadPhotoSelector.defaultProps = defaultProps;
