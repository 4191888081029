import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExploreCard, {
  ExploreCardSkeletonPart,
} from '../../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import APIConfig from '../../../../../../shared-react/src/config/api';

const propTypes = {
  item: PropTypes.object,
  isLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  fullPageLink: PropTypes.string,
};

const RenderedNewsletter = ({ item, isLoading, noMargin, fullPageLink }) => {
  const history = useHistory();

  const handleClick = () => {
    if (fullPageLink) {
      history.push(fullPageLink);
    } else {
      window.open(`${APIConfig.BASE + APIConfig.NAMESPACE.NEWSLETTER}/content/${item.id}?type=${item.type}`, '_blank');
    }
  };

  const content = (
    <ExploreCard
      description={item.preview}
      fullImage={item.image}
      numberOfLines={null}
      skeletonParts={[ExploreCardSkeletonPart.FULL_IMAGE, ExploreCardSkeletonPart.DESCRIPTION]}
      isLoading={isLoading}
      onClick={handleClick}
      markdown
    />
  );

  return noMargin ? content : <div style={{ marginBottom: '1.5rem' }}>{content}</div>;
};

RenderedNewsletter.propTypes = propTypes;

export default RenderedNewsletter;
