import { READ_NOTIFICATIONS, HAS_UNREAD_NOTIFICATION } from '../types';

export const readNotifications = () => {
  return (dispatch) =>
    dispatch({
      type: READ_NOTIFICATIONS,
    });
};

export const hasUnreadNotification = (hasUnreadActivity) => {
  return (dispatch) =>
    dispatch({
      type: HAS_UNREAD_NOTIFICATION,
      payload: { hasUnreadActivity },
    });
};
