import stringify from 'json-stable-stringify';
import stringifyPruned from 'json-prune';
import { isTesting } from './env';

/**
 * Given an object with options an aliases, like {dogs: ['pups', 'hounds'], cats: ['kitties'], rats: []}, get the
 * key that matches with the parameter. For example, if I had 'rats' as the parameter the return would be 'rats'. If
 * I had 'pups' it would be 'dogs'.
 *
 * @param {string} param the parameter
 * @param {object} options the options object, as described above
 *
 * @returns {string} the matching key, or null if no matches were found
 */
export const matchParamWithAlias = (param, options) =>
  Object.keys(options).reduce((acc, k) => {
    if (acc === null) {
      if (k === param || options[k].includes(param)) return k;
    }
    return acc;
  }, null);

const BOOL_ALIASES = { true: ['t', '1'], false: ['f', '0'] };

/**
 * Given a prop value, convert it to a boolean.
 * @param {any} prop the prop value
 * @param {boolean} defaultVal if prop is undefined, what should the default value be
 * @returns {boolean} the result
 */
export const boolProp = (prop, defaultVal) => {
  if (typeof prop === 'undefined') {
    return defaultVal;
  }
  if (typeof prop === 'string') {
    return matchParamWithAlias(prop, BOOL_ALIASES) === 'true';
  }
  if (typeof prop === 'number') {
    return !!prop;
  }
  return prop;
};

/**
 * Merge multiple className attributes. These can be null or undefined or false.
 * @param {string} a the first className to merge
 * @param {string} b the second className to merge
 * @param {string} c ...
 */
export const mergeClassNames = (...args) =>
  Array.from(args).reduce((acc, cur) => (acc ? acc.split(' ') : []).concat(cur ? cur.split(' ') : []).join(' '));

/**
 * Stringify JSON in a consistent manner. Result can be used in a React dependency array.
 *
 * @param {any} x
 * @returns {string}
 */
export const serializeProp = (x, depth = Infinity) => {
  if (typeof x === 'undefined') return undefined;
  return stringifyPruned(x, depth);
};

/**
 * Parses result of serializeProp.
 *
 * @param {string} x
 * @returns {any}
 */
export const unserializeProp = (x) => (typeof x === 'undefined' ? undefined : JSON.parse(x));

/**
 * Given key value pairs, map to [attributeName, value] pairs where the attribute name is `data-testX`. X is the
 * original key, lower cased, letters only.
 *
 * @param {object} testData key value pairs
 * @returns {object} attribute value pairs
 */
export const generateTestAttributes = (testData) => {
  if (!isTesting()) return {};
  const newTestData = {};
  Object.entries(testData ?? {}).forEach(([key, value]) => {
    newTestData['data-test' + key.toLocaleLowerCase().replace(/\W/g, '')] = value;
  });
  return newTestData;
};

export const combineCallbacks =
  (...callbacks) =>
  (...args) =>
    callbacks.map((callback) => (callback ? callback(...args) : null));
