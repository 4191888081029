import { useContentFactory } from '../../../../../../shared-react/src/hooks/content-factory';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../../shared-react/src/config/api';
import React from 'react';
import qs from 'query-string';
import HomepageBottom from '../../../../../../shared-react/src/components/HomepageBottom/HomepageBottom';
import useClosestCity from '../../../../../../shared-react/src/hooks/closest-city';
import useRandomCity from '../../../../../../shared-react/src/hooks/random-city';
import testimonialImageOne from '../../../../assets/images/testimonials/testimonial-1.webp';
import testimonialImageTwo from '../../../../assets/images/testimonials/testimonial-2.webp';
import testimonialImageThree from '../../../../assets/images/testimonials/testimonial-3.webp';
import testimonialImageFour from '../../../../assets/images/testimonials/testimonial-4.webp';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import RenderedVenueHomepage from '../RenderedVenueHomepage/RenderedVenueHomepage';

/**
 * Activities feed below the top section on the ZZ homepage.
 */
const HomepageBottomZZ = () => {
  const { city: closestCity, isLoading: isLoadingClosestCity, requestUserLocation } = useClosestCity();
  const { city: randomLocation } = useRandomCity();

  const {
    brandingConfig: { SITE_NAME },
  } = useVbContext();

  const testimonials = [
    {
      img: testimonialImageOne,
      desc: `“I use ${SITE_NAME} to connect with other moms in my area. It’s great to see their posts and keep up with what they’re doing.”`,
      name: 'Melanie S.',
    },
    {
      img: testimonialImageTwo,
      desc: `“My family trips always start with ${SITE_NAME}. We love using ${SITE_NAME} to get information about activities and plan which ones to go to.”`,
      name: 'Sophie C.',
    },
    {
      img: testimonialImageThree,
      desc: `“I take my grandkids to ${SITE_NAME} places all the time. I check reviews and pictures to see which places they will enjoy the most.”`,
      name: 'Donna M.',
    },
    {
      img: testimonialImageFour,
      desc: `“We have found some of our favorite activities through ${SITE_NAME}. I check it often to see what other families in my area are doing.”`,
      name: 'Ashley T.',
    },
  ];

  const activitiesCf = useContentFactory({
    context: `top25:${closestCity?.countrySlug}/${closestCity?.regionSlug}/${closestCity?.citySlug}`,
    type: ContentTypes.venue.type,
    getQueryAddress: (offset) => {
      return `${APIConfig.NAMESPACE.SEARCH}/location/${closestCity?.countrySlug}/${closestCity?.regionSlug}/${
        closestCity?.citySlug
      }/venues?${qs.stringify({
        offset,
        distanceRanges: JSON.stringify([0, 1]),
        ages: JSON.stringify([0]),
        category: 'activities',
      })}`;
    },
    getItems: (response) =>
      response.results.map((x) => ({ item: x.venue, contextual: { distance: x.distance, direction: x.direction } })),
    disabled: !closestCity?.id,
  });

  // Content factory for the top cities and top venues in those cities
  const citiesCf = useContentFactory({
    context: 'top-cities',
    type: ContentTypes.topCity.type,
    getQueryAddress: () => `${APIConfig.NAMESPACE.SEARCH}/top-city-venues`,
    getItems: (response) => {
      return response.results.map((city) => ({ item: city }));
    },
  });

  return (
    <HomepageBottom
      contentFactory={activitiesCf}
      citiesFactory={citiesCf}
      renderAs={RenderedVenueHomepage}
      requestUserLocation={requestUserLocation}
      isLoadingClosestCity={isLoadingClosestCity}
      randomCityLink={randomLocation?.searchLink ?? randomLocation?.link}
      topText={
        closestCity?.city && closestCity?.region
          ? `Kids activities in ${closestCity.city}, ${closestCity.region}:`
          : null
      }
      testimonials={testimonials}
    />
  );
};

export default HomepageBottomZZ;
