import { USER_LOCATION_LOADING, USER_LOCATION_LOADING_FINISHED, SET_USER_CITY, SET_USER_COORDS } from '../types';

/**
 * Reducer for userLocation.
 */

const initialState = {
  isLoadingCoords: false,
  isLoadingCity: false,
  retriever: null,
  latitude: null,
  longitude: null,
  city: null,
};

const userLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOCATION_LOADING:
      return {
        ...state,
        isLoadingCoords: true,
        isLoadingCity: true,
      };

    case USER_LOCATION_LOADING_FINISHED:
      return {
        ...state,
        isLoadingCoords: false,
        isLoadingCity: false,
      };

    case SET_USER_CITY:
      return {
        ...state,
        isLoadingCity: false,
        city: action.payload.city,
        retriever: action.payload.retriever,
      };

    case SET_USER_COORDS:
      return {
        ...state,
        isLoadingCoords: false,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };

    default:
  }
  return state;
};

export default userLocationReducer;
