import { useSelector } from 'react-redux';

/**
 * Hook to use the current user.
 */
const useCurrentUser = () => {
  const { isLoading, user, error, initialized } = useSelector(({ currentUser }) => currentUser);
  return {
    isLoading: isLoading || !initialized,
    error,
    user,
    isInitialized: initialized,
  };
};

export default useCurrentUser;
