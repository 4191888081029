import React from 'react';
import styles from './Dot.module.scss';

/**
 * A dot, used in meta components.
 */
const Dot = ({ ...props }) => (
  <div className={styles.dotWrapper} {...props}>
    <div className={styles.dot} />
  </div>
);

export default Dot;
