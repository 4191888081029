import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonExplore.module.scss';
/**
 * Icon button for jumping to random city
 * @param props.onClick   The function to run on click
 */
const ButtonExplore = ({ onClick, height, exploreText }) => {
  return (
    <>
      <span onClick={onClick} tabIndex="0" role="button" className={styles.wrapper}>
        <div
          className={styles.explore}
          style={{
            borderRadius: `0px CALC(${height} / 2) CALC(${height} / 2) 0px`,
            height: `${height}`,
            padding: `0 CALC(${height} / 3)`,
            fontSize: `CALC(${height} / 4)`,
          }}
        >
          {exploreText}
        </div>
      </span>
    </>
  );
};

ButtonExplore.propTypes = {
  onClick: PropTypes.func,
  big: PropTypes.bool,
  height: PropTypes.string.isRequired,
};

ButtonExplore.defaultProps = {
  onClick: undefined,
};

export default ButtonExplore;
