import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import Styles from './VBNWaySwitch.module.scss';
import SwitchButton, { SIZES } from './VBNWaySwitch/SwitchButton';

const propTypes = {
  /** The size to give to the buttons */
  size: PropTypes.oneOf(SIZES),

  /** The options to include. Each must have a unique name. Ex:
   * [
   *    {
   *      name: "Trips",
   *      value: "trips"
   *    },
   *    {
   *      name: "Venues",
   *      value: "venues",
   *    },
   *  ]
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,

  /** The value that is selected (should match a value from one of the options) */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Callback function that runs when the selected value is changed.
   * The new selection's 'value' will be passed.
   */
  onChange: PropTypes.func,

  /** A CSS string of the transition time between states */
  transition: PropTypes.string,

  /** A className to give to this component */
  className: PropTypes.string,

  /**  */
  fixedWidth: PropTypes.bool,
};

const defaultProps = {
  size: undefined,
  onChange: undefined,
  transition: undefined,
  className: undefined,
  selected: undefined,
  fixedWidth: false,
};

/**
 * A selector made up of rounded buttons. Only one can be selected at a time.
 */
const VBNWaySwitch = (props) => {
  const { options, size, onChange, selected, transition, className, fixedWidth } = props;

  const [minWidth, setMinWidth] = useState(0);

  useEffect(() => {
    options.forEach((element) => {
      if ((!element?.name && element?.name !== 0) || (!element?.value && element?.value !== 0)) {
        console.error('Must provide a name and value for each item on an N-way switch.');
      }
    });
  }, [options]);

  // Reset min width on window size change. Technically, we should do this whenever the switch itself changes sizes,
  // but that could be triggered by resizing a part of it so best to just leave it like this.
  useEffect(() => {
    const onResize = () => setMinWidth(0);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /**
   * Handle selection of an option. Change the value.
   */
  const handleClickOption = (element) => {
    if (onChange) onChange(element.value);
  };

  /**
   * Handle a change in the width of an element.
   */
  const handleWidthChange = useCallback(
    (width) => {
      if (width > minWidth) setMinWidth(width);
    },
    [minWidth]
  );

  const style = {};
  if (!fixedWidth) {
    Object.assign(style, { minWidth: `${minWidth}px` });
  } else {
    Object.assign(style, { width: `${100 / options.length}%` });
  }
  if (transition) style.transition = transition;

  return (
    <div className={mergeClassNames(className, Styles.selector)}>
      {options.map((el) => {
        return (
          <SwitchButton
            name={el.name}
            value={el.value}
            size={size}
            onClickOption={() => handleClickOption(el)}
            onWidthChange={handleWidthChange}
            selected={selected}
            style={style}
            key={el.name}
          />
        );
      })}
    </div>
  );
};

VBNWaySwitch.propTypes = propTypes;
VBNWaySwitch.defaultProps = defaultProps;

export default VBNWaySwitch;
