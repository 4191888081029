import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../Avatar/Avatar';
import { timeAgo } from '../../../../../util/time';
import { PropTypesVBCommentReply } from '../../../../../util/types';
import styles from './Reply.module.scss';

const propTypes = {
  /** the PropTypesVBCommentReply to render */
  reply: PropTypesVBCommentReply.isRequired,

  /** function to generate a report icon. Takes the reply as an argument, should return a node */
  report: PropTypes.func,
};

const defaultProps = {
  report: undefined,
};

/**
 * Responsible for rendering a single reply within a Replies component.
 *
 * @param {object} props
 */
const Reply = ({ reply, report }) => {
  return (
    <div className={styles.single}>
      <div>
        <a href={reply.user.permalink}>
          <Avatar src={reply.user.avatar} />
        </a>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.name}>
          <a href={reply.user.permalink}>{reply.user.fullName}</a>
        </div>
        <div className={styles.content}>{reply.content}</div>
        <div className={styles.contentBottom}>
          {report ? <div className={styles.reportContainer}>{report(reply)}</div> : null}
          <span className={styles.timePosted}>{timeAgo(reply.time)}</span>
        </div>
      </div>
    </div>
  );
};

export default Reply;

Reply.propTypes = propTypes;
Reply.defaultProps = defaultProps;
