import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { shouldPreserveScrollPosition } from '../store/actions/scrollPositionPreserveActions';
import { addParams } from '../util/urls';
import qs from 'query-string';

// TODO: handle state if needed.
const updateLocation = (oldLocation, newLocation) => {
  if (!oldLocation || oldLocation === newLocation) return newLocation;

  const oldUrl = new URL(oldLocation, oldLocation[0] === '/' ? window.location : undefined);
  const newUrl = new URL(newLocation, newLocation[0] === '/' ? window.location : undefined);

  if (oldUrl.pathname !== newUrl.pathname) return newLocation;
  return addParams(newUrl.pathname, { ...qs.parse(oldUrl.search), ...qs.parse(newUrl.search) });
};

/**
 * Returns a function to perform a history method while maintaining the scroll position.
 *
 * @returns {(location, state): void} function similar to history.push
 */
const usePushPreserveScrollPosition = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const locations = useRef();
  useEffect(() => {
    locations.current = null;
  }, []);

  return useCallback(
    (location, state, method = 'push', additionalParams = {}) => {
      dispatch(shouldPreserveScrollPosition());
      locations.current = updateLocation(locations.current, addParams(location, additionalParams));
      setTimeout(() => {
        if (locations.current) history[method](locations.current, state);
        locations.current = null;
      }, 0);
    },
    [dispatch, history]
  );
};

export default usePushPreserveScrollPosition;
