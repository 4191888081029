import { ExploreSubsection } from '../../config/explore';

export const EXPLORE_SECTIONS = [
  // ExploreSubsection.EVENTS,
  ExploreSubsection.ITINERARIES,
  ExploreSubsection.ACTIVITIES,
  ExploreSubsection.DAY_TRIPS,
  ExploreSubsection.PLAYGROUNDS,
];

export const ExploreSectionLabel = {
  [ExploreSubsection.EVENTS]: 'Kids Events',
  [ExploreSubsection.ITINERARIES]: 'Itineraries',
  [ExploreSubsection.ACTIVITIES]: 'Kids Activities',
  [ExploreSubsection.DAY_TRIPS]: 'Day Trips',
  [ExploreSubsection.PLAYGROUNDS]: 'Playgrounds',
};
