import React, { useEffect, useState, useContext, lazy, Suspense } from 'react';
import VBSpinner from '../VBSpinner/VBSpinner';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import APIConfig from '../../config/api';

const UserVenueForm = lazy(() => import('./UserVenueForm/UserVenueForm'));

const PopupUserAddEditVenue = ({ venueId }) => {
  const [venue, setVenue] = useState();
  const { vbRequest } = useContext(VBReactContext);

  useEffect(() => {
    if (venueId) {
      vbRequest(`${APIConfig.NAMESPACE.VENUE}/${venueId}/details`).then((data) => {
        setVenue(data.venue);
      });
    } else {
      setVenue({
        activityMinTime: 0,
        activityKidsRatio: 0,
        activityMaxTime: 0,
        description: '',
        descriptionSource: '',
        isHike: false,
        hikeDifficulty: 1,
        hikeDistance: 0,
        hikePaved: false,
        isTour: false,
        kidsAgeGroups: 0,
        season: 0,
        address: '',
        timeOfDay: 0,
        name: '',
        isVirusLowRisk: false,
        twitterUser: '',
        ticketPrice: null,
        ticketLink: null,
        website: '',
      });
    }
  }, [vbRequest, venueId]);

  if (!venue) {
    return <VBSpinner />;
  }

  return (
    <Suspense fallback={<VBSpinner center />}>
      <UserVenueForm
        venue={venue}
        title={venueId ? 'Submit edits for review!' : 'Submit a new venue for review!'}
        subtitle={
          venueId
            ? 'Your edits will be sent to our team to be reviewed and possibly added for this venue!'
            : 'Your new venue will be sent to our team to be reviewed and possibly added to the site!'
        }
      />
    </Suspense>
  );
};

export default PopupUserAddEditVenue;
