import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import VBLink from '../VBLink/VBLink';
import styles from './Card.module.scss';
import Meta from '../Meta/Meta';
import { boolProp, generateTestAttributes, mergeClassNames } from '../../util/props';
import { PropTypesVBBool } from '../../util/types';
import { VBLightboxContext } from '../VBCardLightbox/VBCardLightbox';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import PencilSVG from '../../../assets/images/icons/forms/pencil.svg';
import MonoPencil from '../../../assets/images/icons/forms/mono-edit-pencil.svg';
import OnClickLink from '../OnClickLink/OnClickLink';
import ProgressiveImage from '../ProgressiveImage/ProgressiveImage';
import IconButton from '../IconButton/IconButton';
import useVbContext from '../../hooks/vb-context';
import useVBBreakpoint from '../../hooks/vb-breakpoint';

import 'react-loading-skeleton/dist/skeleton.css';

/**
 * A card that can be used to display a post or venue.
 * Leave any props blank to exclude that part of the card.
 * OPTIONALLY: if you have a VBCardLightbox as an ancestor of the card, it will
 * be treated as a part of the lightbox and you can fullscreen the image in the card.
 *
 * @param {string} headerText   The text to display above the image
 * @param {string} image        The image source to use
 * @param {string} imageSmall   The thumbnail image source to use
 * @param {string} imageAlt     The alternative text for image
 * @param {boolean} imageDimensions Width and height attributes for img tag
 * @param {boolean} imageAspectRatio The aspect ratio for image
 * @param {number} ranking      Ranking number to display to the left of the title
 * @param {string} title        The title to use on the card (displayed under the image)
 * @param {string} permalink    Link that is used for title and image
 * @param {number} distance     The distance to display next to the title
 * @param {string} direction     The direction to display next to the title
 * @param {number} days         The number of days of the trip
 * @param {number} tripscore    The tripscore of the trip
 * @param {string|array} coords The coordinates of the location. For example, "32.7807, -79.7984" or: [32.7807, -79.7984]. Used for google maps link
 * @param {element} meta            Specialized meta information to display. (For example, VenueMeta).
 * @param {element} imageOverlay    Elements to display over the image (For example, a 'Like' button)
 * @param {array} actionButtons The buttons to display on the bottom right of the card. Each item in the array should be JSX
 * @param {string} className       additional class names
 * @param {string} titleSize    'sm', 'med' or 'lg'
 * @param {node} badge    badge to show next to the title
 *
 * --- Boolean Settings: ---        Default values:
 * @param {boolean} bordered        = False   Should this card have a visible border?
 * @param {boolean} squareBorders   = False   Should the borders of the card be square (instead of rounded)?
 * @param {boolean} disablePadding  = False   Removes padding from the perimeter of the card
 * @param {boolean} extraPadding    = False   Adds additional padding to the perimeter of the card
 * @param {boolean} horizontal      = False   Makes the whole card layout horizontal (image, meta, buttons)
 * @param {boolean} forceVerticalButtons = False force vertical action buttons
 * @param {boolean} covidLowRisk    = False   Whether to show the covid low risk logo
 * @param {boolean} replaceMeta     = False   If provided, this will override the default meta component with the meta you pass in
 * @param {boolean} metaMaxWidthContainer = False Wrap meta in a MaxWidthContainer
 * @param {boolean} disableImgLink  = False   Removes the link from the card's image
 * @param {boolean} includeImageBorder = False Whether or not to put a vbBlue border around the image
 * @param {boolean} hideImage = False whether or not to hide the image
 * @param {boolean} noTopPadding = False whether or not to have no top padding
 * @param {boolean} noBottomPadding = False whether or not to have no bottom padding
 * @param {boolean} isLoading = False whether or not the card is loading
 * @param {boolean} noReferrerImage = False whether to set referrer-policy as no-referrer or not (used for google images)
 */
const Card = ({ className, ...props }) => {
  const {
    onClickImage,
    image,
    imageSmall,
    imageAlt,
    imageDimensions,
    imageAspectRatio,
    replaceMeta,
    permalink,
    imageOverlay,
    meta,
    title,
    style,
    metaMaxWidthContainer,
    disableImgLink,
    ranking,
    onEdit,
    editButtonStyle,
    includeImageBorder,
    hideImage,
    cardMetaStyle,
    testData,
    aboveTitle,
    noTopPadding,
    noBottomPadding,
    extraBottomPadding,
    isVideo,
    bare,
    disableMetaLink,
    isLoading,
    noReferrerImage,
  } = props;

  const bordered = boolProp(props.bordered);
  const squareBorders = boolProp(props.squareBorders);
  const disablePadding = boolProp(props.disablePadding);
  const horizontal = boolProp(props.horizontal);
  const extraPadding = boolProp(props.extraPadding);

  const { lteXs } = useVBBreakpoint();

  const { vbBlue } = useContext(VBReactContext);

  const imageIsString = typeof image === 'string';

  const lightboxContext = useContext(VBLightboxContext);

  const [imageLoaded, setImageLoaded] = React.useState(!imageIsString);

  // Only can trigger when a VBCardLightbox is in its ancestry.
  const fullscreenClick = useCallback(
    (e) => {
      e.stopPropagation();
      lightboxContext.setImage(image);
    },
    [image, lightboxContext]
  );

  const innerImage = useMemo(() => {
    const style = { aspectRatio: String(imageAspectRatio) };

    const containerClassName = mergeClassNames(
      styles.imgContainer,
      includeImageBorder && imageLoaded ? styles.border : null,
      !lteXs ? styles.borderRadius : null,
      !imageLoaded ? styles.noMargin : null
    );

    if (!imageIsString) {
      return <div className={mergeClassNames(containerClassName, styles.img)}>{image}</div>;
    }

    if (imageSmall) {
      return (
        <ProgressiveImage
          smallSrc={imageSmall}
          fullSrc={image}
          alt={imageAlt ?? title}
          imageStyle={style}
          imageClassName={styles.img}
          containerClassName={containerClassName}
          dimensions={imageDimensions}
          noReferrer={noReferrerImage}
          onSmallImageLoad={() => setImageLoaded(true)}
          noImmediateLoadFull
        />
      );
    }

    return (
      <img
        src={image}
        alt={imageAlt ?? title}
        width={imageDimensions?.width}
        height={imageDimensions?.height}
        style={{
          ...style,
          position: imageLoaded ? undefined : 'absolute',
          visibility: imageLoaded ? 'visible' : 'hidden',
        }}
        className={mergeClassNames(containerClassName, styles.img)}
        onLoad={() => setImageLoaded(true)}
        referrerPolicy={noReferrerImage ? 'no-referrer' : undefined}
      />
    );
  }, [
    image,
    imageAlt,
    imageAspectRatio,
    imageDimensions,
    imageIsString,
    imageLoaded,
    imageSmall,
    includeImageBorder,
    lteXs,
    noReferrerImage,
    title,
  ]);

  const { vbBlack } = useVbContext();

  const editButtonContent = useMemo(() => {
    if (hideImage) {
      return (
        // Edit button to display next to other action buttons if there's no image on the playgrounds tab
        <IconButton onClick={onEdit} text={'Edit'} fontSize="1rem">
          <ColoredIcon src={MonoPencil} color={vbBlack} alt="edit venue" />
        </IconButton>
      );
    }

    return (
      // We assume if metaMaxWidthContainer prop is true that we are on the details page (which currently has a header with fixed positioning.)
      <OnClickLink
        onClick={onEdit}
        className={mergeClassNames(
          styles.editButton,
          imageOverlay && styles.higher,
          metaMaxWidthContainer && styles.venueDetails
        )}
        style={editButtonStyle}
      >
        <ColoredIcon
          src={PencilSVG}
          color="white"
          bubbleBackground={vbBlue}
          alt="edit venue"
          style={{ padding: '8px' }}
          height="34"
          width="34"
        />
      </OnClickLink>
    );
  }, [editButtonStyle, hideImage, imageOverlay, metaMaxWidthContainer, onEdit, vbBlack, vbBlue]);

  const imageContent = useMemo(() => {
    if (hideImage) {
      return null;
    }

    if (isLoading && imageDimensions) {
      return (
        <div
          className={mergeClassNames(
            styles.imgContainer,
            styles.skeletonContainer,
            !lteXs ? styles.borderRadius : null
          )}
          style={{
            paddingBottom: `${(imageDimensions.height / imageDimensions.width) * 100}%`,
          }}
        >
          <Skeleton height="100%" containerClassName={styles.skeleton} />
        </div>
      );
    }

    if (!image) {
      return null;
    }

    return (
      <div onClick={onClickImage} className={styles.imageContainer}>
        {permalink && !disableImgLink ? (
          <VBLink to={permalink} className={styles.imageWrap}>
            {innerImage}
          </VBLink>
        ) : (
          <div className={styles.imageWrap}>{innerImage}</div>
        )}
        {onEdit && editButtonContent}
        {typeof ranking === 'number' && !metaMaxWidthContainer && imageLoaded ? (
          <div className={styles.ranking}>{ranking}</div>
        ) : null}
        {imageOverlay && <div className={styles.imageOverlay}>{imageOverlay}</div>}
        {lightboxContext && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={fullscreenClick} className={styles.fullscreenOverlay}>
            <div className={styles.fullscreenText}>Click for fullscreen</div>
          </div>
        )}
      </div>
    );
  }, [
    disableImgLink,
    editButtonContent,
    fullscreenClick,
    hideImage,
    image,
    imageDimensions,
    imageLoaded,
    imageOverlay,
    innerImage,
    isLoading,
    lightboxContext,
    lteXs,
    metaMaxWidthContainer,
    onClickImage,
    onEdit,
    permalink,
    ranking,
  ]);

  const metaContent = replaceMeta ? (
    meta
  ) : (
    <Meta
      {...props}
      style={cardMetaStyle}
      padded={bordered || disablePadding || horizontal}
      // Pass the edit button if there is no image shown and the user can edit
      editButton={hideImage && onEdit ? editButtonContent : undefined}
      permalink={disableMetaLink ? null : permalink}
    />
  );

  return (
    <div
      className={mergeClassNames(
        className,
        styles.card,
        bordered ? styles.cardBordered : null,
        squareBorders ? null : styles.cardRounded,
        disablePadding ? null : styles.cardPadded,
        extraPadding ? styles.cardExtraPadded : null,
        extraBottomPadding ? styles.cardExtraBottomPadded : null,
        horizontal ? styles.horizontal : null,
        noTopPadding ? styles.noTopPadding : null,
        noBottomPadding ? styles.noBottomPadding : null
      )}
      style={style}
      {...generateTestAttributes(testData)}
    >
      {!isVideo || bare ? null : (
        <span className={mergeClassNames(styles.titleVideo, lteXs ? styles.mobile : null)}>
          {isLoading ? <Skeleton /> : title}
        </span>
      )}
      {!hideImage && imageContent}
      {!bare && aboveTitle}
      {!bare && (metaMaxWidthContainer ? <MaxWidthContainer>{metaContent}</MaxWidthContainer> : metaContent)}
    </div>
  );
};

/* eslint-disable react/require-default-props */
Card.propTypes = {
  style: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imageSmall: PropTypes.string,
  imageAlt: PropTypes.string,
  imageAspectRatio: PropTypes.number,
  ranking: PropTypes.number,
  title: PropTypes.string,
  permalink: PropTypes.string,
  meta: PropTypes.element,
  imageOverlay: PropTypes.element,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  bordered: PropTypesVBBool,
  squareBorders: PropTypesVBBool,
  disablePadding: PropTypesVBBool,
  extraPadding: PropTypesVBBool,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  coords: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  horizontal: PropTypesVBBool,
  forceVerticalButtons: PropTypesVBBool,
  covidLowRisk: PropTypesVBBool,
  className: PropTypes.string,
  days: PropTypes.number,
  tripscore: PropTypes.number,
  replaceMeta: PropTypes.bool,
  metaMaxWidthContainer: PropTypes.bool,
  direction: PropTypes.string,
  titleSize: PropTypes.oneOf(['sm', 'med', 'lg']),
  disableImgLink: PropTypes.bool,
  disableMetaLink: PropTypes.bool,
  badge: PropTypes.node,
  onEdit: PropTypes.func,
  editButtonStyle: PropTypes.object,
  includeImageBorder: PropTypes.bool,
  hideImage: PropTypes.bool,
  /** extra style to put on the card Meta */
  cardMetaStyle: PropTypes.object,
  /** test data to add to the container */
  testData: PropTypes.object,
  /** node to put above the title. */
  aboveTitle: PropTypes.node,
  isLoading: PropTypes.bool,
  noReferrerImage: PropTypes.bool,
};

Card.defaultProps = {
  onClickImage: () => {},
};

export default Card;
