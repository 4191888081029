/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './VBField.module.scss';
import VBTextInput from '../../VBTextInput/VBTextInput';

const propTypes = {
  // The label the user sees in the UI. Defaults to name
  prettyLabel: PropTypes.string,
  // Name used for the form value underhood with react-hook-form. Also used as label if no prettyLabel is provided.
  name: PropTypes.string.isRequired,
  // react-hook-form's control object.
  control: PropTypes.object.isRequired,
  /**
   * react-hook-form's rules object.
   * Note: since we are using the controller, rules like valueAs* do not work. (see the fine print of their documentation.)
   *  */
  rules: PropTypes.object,
  /**
   * React-hook-forms will validate and detect errors, you can specify error messages for each type of error that
   * can happen with the input. This is a map of error type to error message. Key is error type, value is error message.
   */
  errorMessages: PropTypes.object,
};
const defaultProps = {
  prettyLabel: undefined,
  rules: {},
  errorMessages: {},
};

/**
 * This is a wrapper around VBTextInput that uses the react-hook-form library to handle form validation.
 */
function VBField({ prettyLabel, name, control, rules, errorMessages, ...other }) {
  if (rules?.valueAsNumber) {
    throw new Error('Using valueAs* will not work and cause weird behavior.');
  }
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <VBTextInput
            label={prettyLabel ?? name}
            onChange={onChange}
            // inputRef={ref}
            value={value}
            onBlur={onBlur}
            feedback={
              error && {
                type: 'invalid',
                message: (errorMessages && errorMessages[error.type]) ?? error.message ?? 'This field is invalid.',
              }
            }
            {...other}
          />
        )}
      />
    </>
  );
}

VBField.propTypes = propTypes;
VBField.defaultProps = defaultProps;

export default VBField;
