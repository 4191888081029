import React, { Suspense, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import { PropTypesVBVenueVideo } from '../../util/types';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import VenueSnippet from './VenueSnippet/VenueSnippet';
import YoutubeEmbedVideo from './YoutubeEmbedVideo/YoutubeEmbedVideo';
import APIConfig from '../../config/api';
import useContentSingle from '../../hooks/content-single';
import useVbContext from '../../hooks/vb-context';
import { ContentTypes } from '../../config/content-types';
import VBSpinner from '../VBSpinner/VBSpinner';
import RateVideoMeta from './RateVideoMeta/RateVideoMeta';
import WithHtmlDecodedProp from './WithHtmlDecodedProp/WithHtmlDecodedProp';

const propTypes = {
  /** the buttons on the right side of the venue snippet **/
  venueButtons: PropTypes.arrayOf(PropTypes.node),

  /** video object from the server */
  video: PropTypesVBVenueVideo,

  /** disablePadding whether padding should be disabled on the card.
   *  Optionally can be a string representing a breakpoint e.g. 'lteSm' */
  disablePadding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /** if provided (true or false) this will control video autoplay */
  isSelected: PropTypes.bool,

  withRatingActions: PropTypes.bool,
  isLoading: PropTypes.bool,
};
const defaultProps = {
  venueButtons: [],
  disablePadding: false,
  withRatingActions: false,
  isLoading: false,
};

/**
 * A card representing a video that is attached to a venue or location. This will also display a snippet
 * of the venue that the video is attached to.
 */
const VideoCard = ({
  video,
  venueButtons,
  disablePadding: propDisablePadding,
  isSelected,
  withRatingActions,
  isLoading,
  ...other
}) => {
  const vbBreakpoints = useVBBreakpoint();
  const { currentUser } = useVbContext();

  const venueId = video?.venue?.id;

  useContentSingle({
    type: ContentTypes.venue.type,
    getQueryAddress: useCallback(() => `${APIConfig.NAMESPACE.VENUE}/user-data/${venueId}`, [venueId]),
    id: venueId,
    disabled: !currentUser || !venueId,
  });

  const disablePadding = useMemo(() => {
    if (typeof propDisablePadding === 'string') {
      return vbBreakpoints[propDisablePadding];
    }

    return propDisablePadding;
  }, [propDisablePadding, vbBreakpoints]);

  const meta = useMemo(() => {
    if (venueId && currentUser?.hasProductCapability) {
      return <VenueSnippet key={video.id} video={video} />;
    }

    if (withRatingActions) {
      return <RateVideoMeta video={video} isLoading={isLoading} />;
    }

    return null;
  }, [currentUser?.hasProductCapability, isLoading, venueId, video, withRatingActions]);

  return (
    <Suspense fallback={<VBSpinner center />}>
      <WithHtmlDecodedProp
        component={Card}
        decode={video?.title}
        decodeName="title"
        image={
          <YoutubeEmbedVideo
            videoId={video?.id}
            image={video?.thumbnail}
            isSelected={isSelected}
            isLoading={isLoading}
          />
        }
        titleSize="sm"
        disablePadding={disablePadding}
        squareBorders={disablePadding}
        meta={meta}
        isLoading={isLoading}
        isVideo
        {...other}
      />
    </Suspense>
  );
};

VideoCard.propTypes = propTypes;
VideoCard.defaultProps = defaultProps;

export default VideoCard;
