import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

/**
 * Safari will break when trying to convert a date string in from xx-xx-xxxx. Instead
 * it needs it in the form xx/xx/xxxx. This will convert a string to a date object.
 * https://github.com/iamkun/dayjs/issues/254#issuecomment-415278024
 * @param {string} date represents the date in the format xx/xx/xxxx with timestamp or xx-xx-xxxx with timestamp.
 * @param {boolean} isInputUTC if true, the date will be treated as the utc timezone UTC timezone.
 * @return {Date} the date object representing the date.
 */
export const safeDateConvert = (date, isInputUTC) => {
  let day = dayjs(date);
  if (isInputUTC) {
    day = day.utc(true);
  }
  return day;
};

/**
 * Get the time a certain date was ago.
 *
 * @param {number} time as a Unix timestamp (in seconds)
 * @param {bool} ago If it should include "ago" at the end of the time string.
 * @returns {string} the time ago string
 */
export const timeAgo = (time, ago = true) => dayjs(time * 1000).fromNow(true) + (ago ? ' ago' : '');

/**
 * Pretty display for how many weeks ago the given date is.
 * @param {Date} date The date you want to compare from the current date.
 */
export function weeksAgo(date) {
  const weeks = dayjs().diff(date, 'week');
  return (weeks === 0 && 'This week') || (weeks === 1 && 'Last week') || `${weeks} weeks ago`;
}

/**
 * Pretty display for how many days ago the given date is. It's based off how many midnights you
 * would pass to get to the previous date.
 * @param {Date} date The date you want to compare from the current date.
 */
export function daysAgo(date) {
  const midnight = dayjs().set('hour', 24).set('minute', 0).set('second', 0);
  const days = dayjs(midnight).diff(date, 'day');
  return (days === 0 && 'Today') || (days === 1 && 'Yesterday') || `${days} days ago`;
}

/**
 * This is good for comparing dates (if you want to know if one comes before or after).
 * It would convert the the Date into M/D/YYYY format based on local timezone. Ex. 12-09
 * @param {boolean} isUTC if true, the date will be treated as the utc timezone UTC timezone.
 */
export const monthDay = (time, isUTC = true) => safeDateConvert(time, isUTC).format('l');

/**
 * Get the string representation of a date.
 * @param {number | string} date as a Unix timestamp (in seconds) or string
 * @returns {string} the string representation of the string
 */
export const getDateString = (date) => {
  const timePosted = new Date(typeof date === 'string' ? date : date * 1000);
  const dateYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(timePosted);
  const dateMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(timePosted);
  const dateDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(timePosted);
  return `${dateDay} ${dateMonth}, ${dateYear}`;
};

/**
 * Gives a string representing an hour (optionally with minutes also)
 * @param {number} seconds The number of seconds to calculate this hour from
 * @param {bool} includeMinutes Whether or not to include minutes in the result. Default=true
 * @param {array} period Period to include. Defaults to ["am", "pm"]. Set to null to exclude.
 * @returns {string} a string representing the hour timestamp
 */
export const getHourString = (seconds, includeMinutes, period) => {
  let per = period !== undefined ? period : ['am', 'pm'];
  if (per === null) {
    per = ['', ''];
  }
  const inclMin = includeMinutes || includeMinutes === undefined;

  const secondsToHours = (sec) => {
    return sec / 60 / 60;
  };

  const militaryToStandard = (military) => {
    const convert = military % 12;
    return convert === 0 ? 12 : convert;
  };

  const hours = secondsToHours(seconds);
  const standardTime = militaryToStandard(hours);
  const standardRounded = Math.floor(standardTime);
  const showPeriod = hours < 12 ? per[0] : per[1];
  const minutes = Math.round((standardTime - standardRounded) * 60);
  const showMinutes = inclMin ? `:${minutes < 10 ? '0' : ''}${minutes}` : '';

  return `${standardRounded === 0 ? 12 : standardRounded}${showMinutes}${showPeriod}`;
};

/**
 * Given a time in seconds, return formatted as h:mm A.
 *
 * @param {number} time time in seconds since midnight
 *
 * @returns {string}
 */
export const getTimeString = (time) => {
  return dayjs(time * 1000)
    .utc(false)
    .format('h:mm A');
};

/**
 * Given a time in seconds, returns an object with the hour, minute and seconds.
 * @param {number} time time duration in seconds
 * @return {object} {hour, minute, second}
 */
export const secondsToTimeObject = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;
  return {
    hours,
    minutes,
    secondsLeft,
  };
};

/**
 * Given a time object, returns the total number of seconds.
 * @param {object} timeObject {hour, minute, second}
 * @return {number} total number of seconds
 */
export const timeObjectToSeconds = (timeObject) => {
  return timeObject.hours * 3600 + timeObject.minutes * 60 + timeObject.secondsLeft;
};

/**
 * Number of seconds as a duration in seconds or ms
 * @param {number} s
 * @param {boolean} asS whether to return the results in seconds or ms
 * @returns number
 */
export const seconds = (s, asS = false) => s * (asS ? 1 : 1000);

/**
 * Number of minutes as a duration in seconds or ms
 * @param {number} m
 * @param {boolean} asS whether to return the results in seconds or ms
 * @returns number
 */
export const minutes = (m, asS = false) => seconds(m * 60, asS);

/**
 * Number of hours as a duration in seconds or ms
 * @param {number} s
 * @param {boolean} asS whether to return the results in seconds or ms
 * @returns number
 */
export const hours = (h, asS = false) => minutes(h * 60, asS);

/**
 * Number of days as a duration in seconds or ms
 * @param {number} s
 * @param {boolean} asS whether to return the results in seconds or ms
 * @returns number
 */
export const days = (d, asS = false) => hours(d * 24, asS);
