import React from 'react';
import PropTypes from 'prop-types';
import { useDropdown } from '../../../hooks/dropdown';
import { mergeClassNames } from '../../../util/props';
import styles from './Header.module.scss';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import { injectComponent } from '../../../util/string';
import OnClickLink from '../../OnClickLink/OnClickLink';
import TopEllipsis from '../TopEllipsis/TopEllipsis';
import { ExploreEllipsisPropType } from '../../../util/types';

// Content to replace with the actual filter.
const FILTER_STR = '%FILTER%';
const HIDDEN_GEMS_STR = '%HIDDEN_GEMS%';

const propTypes = {
  /** content to render at the top of the page. Include "%FILTER%" in the string where you want the dropdown to be
   *  placed */
  header: PropTypes.string.isRequired,

  /** text to show in the filter trigger in the tabs */
  filterText: PropTypes.string.isRequired,

  /** content in the filter dropdown */
  dropdown: PropTypes.node,

  hiddenGemsRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),

  /** whether or not the about column is being shown */
  hasAboutColumn: PropTypes.bool,

  /** array of items to put in the ellipsis */
  ellipsis: ExploreEllipsisPropType,
};
const defaultProps = {
  hiddenGemsRef: undefined,
  hasAboutColumn: false,
  ellipsis: undefined,
};

/**
 * The header used at the top of the Explore page.
 *
 * @param {object} props
 */
const Header = ({
  header: headerText,
  filterText,
  dropdown: dropdownInner,
  hiddenGemsRef,
  hasAboutColumn,
  ellipsis,
}) => {
  const { lteXs: mobileStyle, lt1280: tabletStyle } = useVBBreakpoint();

  const trigger = <>{filterText}</>;

  const { dropdown: headerDropdown } = useDropdown({
    dropdown: dropdownInner,
    triggerClassName: styles.trigger,
    trigger,
    inline: true,
    testData: { id: 'vb-explore-filter-header' },
  });

  const hiddenGemsLink = (
    <OnClickLink
      onClick={() => {
        if (!hiddenGemsRef?.current) return;
        window.scrollTo({
          top: hiddenGemsRef?.current.offsetTop + hiddenGemsRef?.current.offsetHeight,
          behavior: 'smooth',
        });
      }}
      className={styles.hiddenGems}
      noHoverStyle
    >
      Hidden Gems
    </OnClickLink>
  );

  // Replace the placeholder text with the actual dropdown and hidden gem link
  const header = injectComponent(headerText, [
    { find: FILTER_STR, replace: headerDropdown },
    { find: HIDDEN_GEMS_STR, replace: hiddenGemsLink },
  ]);

  const showEllipsis = !tabletStyle && !hasAboutColumn && ellipsis?.length;

  return (
    <>
      <h1
        className={mergeClassNames(
          styles.header,
          mobileStyle ? styles.mobile : null,
          showEllipsis ? styles.padded : null
        )}
      >
        {header}
      </h1>
      {showEllipsis ? <TopEllipsis ellipsis={ellipsis} className={styles.ellipsis} /> : null}
    </>
  );
};
export default Header;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
