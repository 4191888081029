import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './VBTextInput.module.scss';
import { mergeClassNames } from '../../util/props';
import VBButton from '../VBButton/VBButton';
import VBInputLabel from '../VBInputLabel/VBInputLabel';
import VBCharacterLimit from '../VBCharacterLimit/VBCharacterLimit';
import VBFeedbackText from '../VBFeedbackText/VBFeedbackText';
import { PropTypesRefProp } from '../../util/types';

const propTypes = {
  /** the input type, see https://react-bootstrap.github.io/components/forms/ */
  type: PropTypes.string,

  /** additional classNames for Form.Control */
  className: PropTypes.string,

  /** additional classNames for Input Group */
  groupClassName: PropTypes.string,

  /** height of the input as a CSS string */
  height: PropTypes.string,

  /** additional style */
  style: PropTypes.object,

  /** a ref to give to the input element */
  inputRef: PropTypesRefProp,

  /** (optional) text to put on a button in the right side of the input */
  buttonText: PropTypes.string,

  /** (optional) action that button performs */
  buttonAction: PropTypes.func,

  /** {type, error} will display a message underneath the input as an error or success (invalid or valid)
   *    type: type of feedback. Either "valid" or "invalid"
   *    message: the message to display under input
   */
  feedback: PropTypes.shape({ type: PropTypes.oneOf(['valid', 'invalid']).isRequired, message: PropTypes.string }),

  /** string label to display above the input */
  label: PropTypes.string,

  /** whether or not the field is required */
  required: PropTypes.bool,

  /** max length of the field */
  maxLength: PropTypes.number,

  /** whether to show the max length on the right side of the textbox */
  showMaxLength: PropTypes.bool,

  /** input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** onChange callback passed down to input */
  onChange: PropTypes.func,

  /** input classNames for the input container */
  containerClassName: PropTypes.string,

  /** id of the input */
  id: PropTypes.string,

  disabled: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  style: {},
  buttonText: '',
  buttonAction: null,
  feedback: null,
  className: '',
  groupClassName: '',
  label: undefined,
  height: undefined,
  inputRef: undefined,
  required: false,
  maxLength: undefined,
  showMaxLength: false,
  value: '',
  onChange: undefined,
  containerClassName: undefined,
  id: undefined,
  disabled: undefined,
};

/**
 * A stylized text input. For more props, see https://react-bootstrap.github.io/components/forms/.
 *
 * @param {object} props
 */
const VBTextInput = ({
  type,
  className,
  groupClassName,
  height,
  style,
  inputRef,
  buttonText,
  buttonAction,
  feedback,
  label,
  required,
  maxLength,
  showMaxLength,
  value: parentValue,
  onChange,
  containerClassName,
  id,
  ...other
}) => {
  if (showMaxLength && typeof maxLength === 'undefined')
    throw new Error('maxLength is required in VBTextArea when showMaxLength is enabled');

  // Maintain value locally, bind it one way to the value prop.
  const [value, setValue] = useState(parentValue);
  useEffect(() => setValue(parentValue), [parentValue]);

  /**
   * Sets the value in state and calls the parent callback if it is defined.
   *
   * @param {React.SyntheticEvent} e
   */
  const onChangeWrapper = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const heightStyle = {};
  if (height) {
    heightStyle.height = height;
    heightStyle.borderRadius = `CALC(${height} / 2)`;
  }

  const inputElement = (
    <div className={containerClassName}>
      <div className={mergeClassNames(styles.inputContainer)}>
        {label ? <VBInputLabel labelFor={id} content={label} required={required} /> : null}
        <Form.Control
          id={id}
          type={type}
          style={{ ...heightStyle, ...style }}
          className={mergeClassNames(styles.input, className, buttonText ? styles.hasButton : null)}
          ref={inputRef}
          required={required}
          onChange={onChangeWrapper}
          value={value}
          maxLength={maxLength}
          data-hj-allow
          {...other}
        />
        {showMaxLength ? <VBCharacterLimit remaining={maxLength - value.length} /> : null}
      </div>
      {feedback?.type && <VBFeedbackText type={feedback.type}>{feedback.message}</VBFeedbackText>}
    </div>
  );

  // Doesn't need to be in a group if it is not grouped with anything.
  // I was having problems using validation when it was nested inside of a group.
  if (!buttonText) {
    return inputElement;
  }

  return (
    <>
      <InputGroup className={groupClassName} hasValidation>
        {inputElement}
        {buttonText && (
          <InputGroup.Text className={styles.inputGroupText}>
            <VBButton className={styles.button} style={{ height }} onClick={buttonAction}>
              {buttonText}
            </VBButton>
          </InputGroup.Text>
        )}
      </InputGroup>
    </>
  );
};

export default VBTextInput;

VBTextInput.propTypes = propTypes;

VBTextInput.defaultProps = defaultProps;
