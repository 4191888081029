import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import ImageCheck from '../../../assets/images/icons/check.svg';
import styles from './ShareList.module.scss';
import APIConfig from '../../config/api';
import useVbContext from '../../hooks/vb-context';
import { useContentFactory } from '../../hooks/content-factory';
import { ContentTypes } from '../../config/content-types';
import UserListPopup from '../UserListPopup/UserListPopup';
import VBButton from '../VBButton/VBButton';
import ColoredIcon from '../ColoredIcon/ColoredIcon';

const propTypes = {
  /** the ID of the content that is being shared */
  contentId: PropTypes.number.isRequired,

  /** the type of the content that is being shared */
  contentType: PropTypes.oneOf(['venue', 'itinerary']).isRequired,
};

const ROUTES = { venue: 'venues', itinerary: 'itineraries' };

/**
 * A list of the user's friends that they can then share venues with.
 */
const ShareList = ({ contentId, contentType }) => {
  const { currentUser, vbRequest, pointsToast } = useVbContext();

  // Array of user IDs that have been shared with.
  const [sharedWith, setSharedWith] = useState([]);

  // Setup the content factory.
  const contentFactory = useContentFactory({
    getQueryAddress: (offset) => {
      const params = { sort_by: 'recent_share', offset };
      return `${APIConfig.NAMESPACE.SOCIAL}/relationships/query/${currentUser.id}/friends?${QueryString.stringify(
        params
      )}`;
    },
    nextOffset: (offset, items, data) => data.next_offset,
    context: 'friends',
    type: ContentTypes.user.type,
  });

  /**
   * Shares with a user.
   * @param {number} userId
   * @returns {Promise<void>}
   */
  const shareContent = (userId) => {
    setSharedWith((prev) => [...prev, userId]);
    return new Promise((resolve, reject) => {
      const params = { user_id: userId };
      vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/${ROUTES[contentType]}/${contentId}/share`, { method: 'POST', params })
        .then((data) => {
          pointsToast(data.expert_points, 'sharing with a friend');
          resolve();
        })
        .catch(reject);
    });
  };

  return (
    <>
      <div className={styles.title}>Share with Friends</div>
      <UserListPopup
        contentFactory={contentFactory}
        buildActionArea={(user) => {
          const shared = sharedWith.includes(user.id);
          return (
            <div>
              {shared ? (
                <VBButton size="med" type="full">
                  <ColoredIcon src={ImageCheck} color="white" style={{ marginRight: '0.5rem' }} /> Sent
                </VBButton>
              ) : (
                <VBButton size="med" type="border" onClick={() => shareContent(user.id)} content="Send" />
              )}
            </div>
          );
        }}
      />
    </>
  );
};
export default ShareList;
ShareList.propTypes = propTypes;

ShareList.NO_OVERFLOW = true;
