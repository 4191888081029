import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Star from './Star/Star';
import { getRandomInt } from '../../../util/number';

import styles from './RatingSummary.module.scss';

const propTypes = {
  venueId: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
};

const RatingSummary = ({ venueId, reviewsCount }) => {
  const rating = useMemo(() => getRandomInt(5, 10), []);

  const handleClick = () => {
    const element = document.getElementById(`${venueId}_reviews`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.ratingSummary} onClick={handleClick}>
      <div className={styles.stars}>
        <Star value={10} />
        <Star value={10} />
        <Star value={10} />
        <Star value={10} />
        <Star value={rating} />
      </div>
      <div className={styles.text}>
        {reviewsCount} {reviewsCount === 1 ? 'review' : 'reviews'}
      </div>
    </div>
  );
};

RatingSummary.propTypes = propTypes;

export default RatingSummary;
