import { useCallback, useEffect, useState } from 'react';
import { getRandomCity } from '../util/location';
import useVbContext from './vb-context';

/**
 * Gets a random city, avoiding a given country, region, city combo.
 *
 * @param {string} avoidCountry
 * @param {string} avoidRegion
 * @param {string} avoidCity
 */
const useRandomCity = (refreshCount, avoidCountry, avoidRegion, avoidCity) => {
  const { vbRequest, errorToast } = useVbContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshes, setRefreshes] = useState(0);
  const [city, setCity] = useState(null);

  useEffect(() => {
    // TODO: Check that the cause of the hook being fired is the changes to the avoid args. If so, exit out of the hook
    // early if the existing city does not conflict with the avoid city.

    setIsLoading(true);
    getRandomCity(vbRequest, avoidCountry, avoidRegion, avoidCity)
      .then((data) => {
        setCity(data);
      })
      .catch((err) => {
        errorToast(err.message, 'Random City');
      })
      .finally(() => setIsLoading(false));
  }, [refreshCount, errorToast, vbRequest, refreshes, avoidCountry, avoidRegion, avoidCity]);

  const refresh = useCallback(() => setRefreshes((prev) => prev + 1), []);

  return { city, isLoading, refresh };
};

export default useRandomCity;
