/* eslint-disable react/require-default-props */

import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import styles from './VBTab.module.scss';
import { mergeClassNames } from '../../util/props';
import OnClickLink from '../OnClickLink/OnClickLink';
import { trimSlashes } from '../../util/string';
import usePushPreserveScrollPosition from '../../hooks/push-preserve-scroll-position';
import useVbContext from '../../hooks/vb-context';

const propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  content: PropTypes.node,
  className: PropTypes.string,
  activeClass: PropTypes.string,
  isActive: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClick: PropTypes.func,
  shouldResetScroll: PropTypes.bool,
  linkStyle: PropTypes.bool,

  /** whether to force using react router links */
  forceReactRouterLink: PropTypes.bool,

  /** whether to prevent scroll to top if a tab is active or not */
  preventScrollToTop: PropTypes.bool,
};

/**
 *
 * A single tab. This is similar to a link, but is has a concept of active/non-active.
 *
 * @param {ReactElement} props.content optionally specify the content of the link here, or do it in children and leave
 *                                     this undefined
 * @param {string} props.to the path to link to when the tab is clicked
 * @param {string} props.className additional classes to put on the link
 * @param {string} props.activeClass additional classes to put on the link when it is active
 * @param {(match: object, location: object): bool} props.isActive filter used to determining whether or not the tab
 *                                                                 should be active. Params match and location will be
 *                                                                 undefined if to is not provided
 * @param {string} props.onClick the function to execute when the component is clicked.
 *
 * @todo support tabs that do not rely on react router
 */
const VBTab = ({
  to,
  content,
  children,
  className,
  activeClass,
  onClick,
  isActive: parentIsActive,
  shouldResetScroll,
  linkStyle,
  forceReactRouterLinks,
  preventScrollToTop,
  ...other
}) => {
  const { routerLinks } = useVbContext();
  const location = useLocation();
  const match = useRouteMatch();

  // Custom isActive that ignores state. I don't think there is any tab on the site that includes state, so this is
  // fine.
  const defaultIsActive = () =>
    to && trimSlashes(location.pathname) === trimSlashes(to.slice(0, `${to}?`.indexOf('?')));

  let isActive;
  if (typeof parentIsActive === 'boolean') {
    isActive = parentIsActive;
  } else if (typeof parentIsActive === 'function') {
    isActive = parentIsActive(match, location);
  } else {
    isActive = defaultIsActive();
  }

  const history = useHistory();

  const pushKeepScroll = usePushPreserveScrollPosition();

  const handleClickLink = (e) => {
    if (isActive && !preventScrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (onClick) {
      onClick();
    }

    if (!to) return;

    const isLocal = to[0] === '/';
    if (!routerLinks && !forceReactRouterLinks) {
      // If we're not using react router, go to page directly
      if (isLocal) {
        window.location.href = to;
      } else {
        window.open(to, '_blank');
      }
    }

    if (isLocal) {
      if (!shouldResetScroll) {
        pushKeepScroll(to);
      } else {
        history.push(to);
      }
    } else {
      window.open(to, '_blank');
    }
  };

  return (
    <OnClickLink
      onClick={handleClickLink}
      className={mergeClassNames(
        styles.tab,
        linkStyle ? styles.linkStyle : null,
        isActive ? mergeClassNames(styles.active, activeClass) : null,
        className
      )}
      {...other}
    >
      {content || children}
    </OnClickLink>
  );
};

VBTab.propTypes = propTypes;

export default VBTab;
