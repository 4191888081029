import React, { createContext, isValidElement, useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import VBDropdown from '../components/VBDropdown/VBDropdown';
import VBDropdownTrigger from '../components/VBDropdownTrigger/VBDropdownTrigger';
import { boolProp } from '../util/props';
import useTestData from './test-data';

export const DropdownContext = createContext();

/**
 * @typedef UseDropdownReturn
 *
 * @param {ReactElement} dropdown the element to render
 * @param {(): void)} open opens the dropdown
 * @param {(): void} close closes the dropdown
 * @param {boolean} isOpen whether or not the dropdown is open
 */

/**
 * Hook for working with VBDropdown and VBDropdownTrigger.
 *
 * @param {ReactElement} args.dropdown the content to put inside the dropdown
 * @param {string} args.dropdownClassName optional addition classNames for the dropdown
 * @param {ReactElement} args.trigger the content to put inside the trigger
 * @param {string} args.triggerClassName optional addition classNames for the trigger
 * @param {boolean} args.hover whether this dropdown is triggered by hover rather than click
 * @param {boolean} args.alignRight whether to align dropdown to right instead of left
 *
 * @returns {UseDropdownReturn}
 */
export const useDropdown = (args = {}) => {
  const { dropdown, trigger, dropdownClassName, triggerClassName, alignRight, inline, testData } = args;
  const hover = boolProp(args.hover);
  const { ref } = useTestData(testData);

  const [show, setShow] = useState(false);

  /**
   * This is a hacky bandaid for ViewBuff. On VB when a dropdown is open, it will move the
   * footer down off the screen (and other divs with position fixed) and sometimes will overflow
   * the page horizontally. This only happens in the mobile renderer. Upon editing a css value
   * after opening the dropdown, everything fixes itself. This makes me think it could be a deep bug
   * in the browser. This does not happen on TripFool though, which is interesting.
   *
   * So what this does is when the dropdown is opened, it will update a css property after the render.
   * The footer will flicker a bit, but it will not overflow the page and we won't have an invisible footer.
   */
  const [applyBandaid, setApplyBandaid] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (show) {
      timeoutId = setTimeout(() => setApplyBandaid(true), 0);
      return () => clearTimeout(timeoutId);
    } else setApplyBandaid(false);
  }, [show]);

  const bandaidStyle = {
    // I just picked an arbitrary css property, this can be any property and it will work.
    justifyContent: applyBandaid ? 'center' : undefined,
  };

  const onToggle = () => {
    if (hover) {
      return;
    }
    setShow(!show);
  };

  const handleMouseEnter = () => {
    if (hover && !show) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (hover && show) {
      setShow(false);
    }
  };

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  const getNodeText = (node) => {
    if (typeof node === 'string') {
      return node;
    }

    if (isValidElement(node)) {
      return getNodeText(node.props.children);
    }

    if (Array.isArray(node)) {
      return node
        .map((item) => getNodeText(item))
        .filter(Boolean)
        .join(' ')
        .trim();
    }

    return null;
  };

  const content = (
    <DropdownContext.Provider value={{ close, isOpen: show }}>
      <Dropdown
        onToggle={onToggle}
        show={show}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ display: inline ? 'inline-block' : 'block' }}
        {...(alignRight ? { align: 'end' } : {})}
        ref={ref}
      >
        <Dropdown.Toggle
          role="button"
          aria-label={getNodeText(trigger) ?? 'Trigger dropdown'}
          as={VBDropdownTrigger}
          className={triggerClassName}
        >
          {trigger}
        </Dropdown.Toggle>
        <VBDropdown style={{ width: 'max-content', ...bandaidStyle }} className={dropdownClassName}>
          {dropdown}
        </VBDropdown>
      </Dropdown>
    </DropdownContext.Provider>
  );
  return { dropdown: content, open, close, isOpen: show };
};
export default useDropdown;

export const useCloseDropdown = () => {
  const ctx = useContext(DropdownContext);
  return ctx.close;
};
