import React from 'react';
import PropTypes from 'prop-types';
import ImageUpload from '../../../../assets/images/admin/upload.svg';
import useVbContext from '../../../hooks/vb-context';
import PopupUploadFeaturedImage from './PopupUploadFeaturedImage/PopupUploadFeaturedImage';
import IconButton from '../../IconButton/IconButton';
import ColoredIcon from '../../ColoredIcon/ColoredIcon';
import { usePopup } from '../../../hooks/popup';

const propTypes = {
  /** ID of the venue to upload featured image for */
  venueId: PropTypes.number.isRequired,

  /** name of the venue to upload featured image for, used to build search query */
  venueName: PropTypes.string.isRequired,

  /** city that the venue is in, used to build search query */
  cityName: PropTypes.string.isRequired,
};

/**
 * Icon button for admin uploading a new featured image for a venue.
 */
const IconUploadFeaturedImage = ({ venueId, venueName, cityName }) => {
  const { vbBlack } = useVbContext();

  const { modal, open: openPopup } = usePopup({ child: <PopupUploadFeaturedImage venueId={venueId} /> });

  const handleClick = () => {
    openPopup();
    window.open(
      `https://www.google.com/search?tbm=isch&q=${encodeURIComponent(
        `${venueName}${cityName ? `, ${cityName}` : ``}`
      )}`,
      '_blank'
    );
  };

  return (
    <>
      <IconButton onClick={handleClick} text="[Upl. Feat.]" fontSize="1rem">
        <ColoredIcon src={ImageUpload} color={vbBlack} height="22" width="25" />
      </IconButton>
      {modal}
    </>
  );
};

IconUploadFeaturedImage.propTypes = propTypes;

export default IconUploadFeaturedImage;
