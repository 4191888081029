import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styles from './HomepageBottom.module.scss';
import { mergeClassNames } from '../../util/props';
import useBottomWatcher from '../../hooks/bottom-watcher';
import VBSpinner from '../VBSpinner/VBSpinner';
import VBButton from '../VBButton/VBButton';
import VBLink from '../VBLink/VBLink';
import VBHeading from '../VBHeading/VBHeading';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import AnimateFromBottom from '../AnimateFromBottom/AnimateFromBottom';
import useWindowScroll from '../../hooks/window-scroll';
import Testimonial from './Testimonial/Testimonial';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import City from './City/City';
import VBSpinnerButton from '../VBSpinnerButton/VBSpinnerButton';

const propTypes = {
  contentFactory: PropTypes.object.isRequired,
  renderAs: PropTypes.func.isRequired,
  doubleWidth: PropTypes.bool,
  closestCityLink: PropTypes.string,
  requestUserLocation: PropTypes.func,
  randomCityLink: PropTypes.string,
  isLoadingClosestCity: PropTypes.bool,
  topText: PropTypes.string,
  renderAsProps: PropTypes.object,
  testimonials: PropTypes.array,
  /** The content factory for the top cities at the bottom of the page */
  citiesFactory: PropTypes.object,
};

const defaultProps = {
  closestCityLink: undefined,
  randomCityLink: undefined,
  topText: undefined,
  renderAsProps: undefined,
  testimonials: undefined,
  citiesFactory: undefined,
};

const MAX_ITEMS = 25;
// Maximum number of items to display if testimonials are included
const MAX_ITEMS_TESTIMONIALS = 8;

const HomepageBottom = ({
  contentFactory,
  renderAs: RenderAs,
  renderAsProps,
  doubleWidth,
  randomCityLink,
  topText,
  testimonials,
  citiesFactory,
  requestUserLocation,
  isLoadingClosestCity,
}) => {
  const { content, loading, refreshing, noMore, loadMore } = contentFactory;

  const bottomRef = useRef();
  const containerRef = useRef();

  const [stickiesVisible, setStickiesVisible] = useState();

  const history = useHistory();

  const { lteXs: mobileStyle, lteMd: tabletStyle } = useVBBreakpoint();

  useWindowScroll(() => {
    setStickiesVisible(
      containerRef?.current && containerRef.current.getBoundingClientRect().top < window.innerHeight / 2
    );
  }, [containerRef]);

  const handleExploreNearMe = useCallback(async () => {
    const closestCity = await requestUserLocation();

    if (closestCity) {
      history.push(closestCity.searchLink);
    }
  }, [history, requestUserLocation]);

  // Load more items when the user is at the bottom of the page.
  const handleScrollBottom = useCallback(() => {
    if (
      !noMore &&
      loadMore &&
      !loading &&
      ((content.length < MAX_ITEMS && !testimonials) || (content.length < MAX_ITEMS_TESTIMONIALS && testimonials))
    )
      loadMore();
  }, [loadMore, noMore, loading, content.length, testimonials]);

  useBottomWatcher(bottomRef, handleScrollBottom);

  return (
    <div className={mergeClassNames(styles.container, mobileStyle && styles.mobile)} ref={containerRef}>
      {topText ? (
        <MaxWidthContainer padded>
          <VBHeading className={styles.top} size="sm">
            {topText}
          </VBHeading>
        </MaxWidthContainer>
      ) : null}
      <div className={mergeClassNames(styles.list, doubleWidth ? styles.double : null)}>
        {content.slice(0, testimonials ? MAX_ITEMS_TESTIMONIALS : MAX_ITEMS).map((data) => (
          <div className={styles.item} key={data.item.id}>
            <RenderAs {...data} {...renderAsProps} />
          </div>
        ))}
        {loading && !refreshing && <VBSpinner center />}
      </div>
      {citiesFactory ? (
        // If a cities factory was provided, render the Top Cities section
        <>
          <MaxWidthContainer padded>
            <VBHeading className={styles.top} size="md">
              Top Cities
            </VBHeading>
          </MaxWidthContainer>
          <div className={mergeClassNames(styles.list, !tabletStyle ? styles.double : null)}>
            {tabletStyle
              ? // If window is tablet-sized or smaller, don't use a double wide list
                citiesFactory.content.map((obj) => {
                  return <City city={obj.item} key={obj.key} />;
                })
              : citiesFactory.content.map((obj, idx) => {
                  // Desktop size window - display two cities side by side
                  return idx % 2 === 0 ? (
                    <div className={styles.doubleCities} key={obj.key}>
                      <City city={obj.item} />
                      {citiesFactory.content[idx + 1] ? <City city={citiesFactory.content[idx + 1].item} /> : null}
                    </div>
                  ) : null;
                })}
          </div>
          {/* Display a spinner if the cities are loading */}
          {citiesFactory.loading && !citiesFactory.refreshing && <VBSpinner center />}
        </>
      ) : null}
      {testimonials ? (
        <MaxWidthContainer padded>
          <VBHeading className={styles.top} size="md">
            Testimonials
          </VBHeading>
          <div className={styles.testimonialGrid}>
            {testimonials.map((testimonial, idx) => (
              <Testimonial img={testimonial.img} description={testimonial.desc} name={testimonial.name} key={idx} />
            ))}
          </div>
        </MaxWidthContainer>
      ) : null}

      <div ref={bottomRef} className={styles.bottom}>
        <AnimateFromBottom bottomDistance="4.5rem" bottomDistanceMobile="1rem" isVisible={stickiesVisible}>
          <div className={styles.bottomButtons}>
            <VBSpinnerButton
              type="full"
              size="med"
              content="Explore Near Me"
              isSpinning={isLoadingClosestCity}
              onClick={handleExploreNearMe}
            />
            {Boolean(randomCityLink) && (
              <VBLink noStyle to={randomCityLink}>
                <VBButton type="full" size="med" content="New City" />
              </VBLink>
            )}
          </div>
        </AnimateFromBottom>
      </div>
    </div>
  );
};

HomepageBottom.propTypes = propTypes;
HomepageBottom.defaultProps = defaultProps;

export default HomepageBottom;
