import SITES from '../../config-json/sites.json';

export const VB_SITE_VIEWBUFF = SITES[SITES.VIEWBUFF];
export const VB_SITE_ITINERARIES = SITES[SITES.ITINERARIES];
export const VB_SITE_KIDS = SITES[SITES.KIDS];

export const SITE_INFO = {
  [SITES[SITES.VIEWBUFF]]: {
    /** The rating field on the venue object returned from server. */
    RATING_FIELD: 'viewbuffRating',
    /** Threshold values for gold, silver and bronze badges for each site */
    THRESHOLDS: [6863, 6253, 5816],
    LIVE_SITE: 'https://viewbuff.com',
    STAGING_SITE: 'https://staging.viewbuff.com',
    DEV_SITE: 'http://localhost:8080',
    PRETTY_NAME: 'ViewBuff',
  },
  [SITES[SITES.ITINERARIES]]: {
    RATING_FIELD: 'activityOverallRating',
    THRESHOLDS: [3205, 1227, 600],
    LIVE_SITE: 'https://tripfool.com',
    STAGING_SITE: 'https://staging.tripfool.com',
    DEV_SITE: 'http://localhost:3000',
    PRETTY_NAME: 'TripFool',
  },
  [SITES[SITES.KIDS]]: {
    RATING_FIELD: 'kidsOverallRating',
    THRESHOLDS: [3205, 1227, 600],
    LIVE_SITE: 'https://zuzuforkids.com',
    STAGING_SITE: 'https://staging.zuzuforkids.com',
    DEV_SITE: 'http://localhost:3000',
    PRETTY_NAME: 'ZuzuForKids',
  },
};
