import { useCallback, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { stringify } from 'query-string';
import { useWindowSize } from '@react-hook/window-size';
import TriviaQuestion from '../../../../../../../../shared-react/src/components/TriviaQuestion/TriviaQuestion';
import OnClickLink from '../../../../../../../../shared-react/src/components/OnClickLink/OnClickLink';
import MarkdownText from '../../../../../../../../shared-react/src/components/MarkdownText/MarkdownText';
import VBButton from '../../../../../../../../shared-react/src/components/VBButton/VBButton';
import useContentSingle from '../../../../../../../../shared-react/src/hooks/content-single';
import APIConfig from '../../../../../../../../shared-react/src/config/api';
import { ContentTypes } from '../../../../../../../../shared-react/src/config/content-types';
import VenueCard from '../../../../../../../../shared-react/src/components/VenueCard/VenueCard';
import useVBBreakpoint from '../../../../../../../../shared-react/src/hooks/vb-breakpoint';
import { mergeClassNames } from '../../../../../../../../shared-react/src/util/props';
import { TriviaResultMessage, TriviaStatus } from './config';
import { getTriviaStatus } from './utils';

import styles from './TriviaSet.module.scss';

const TriviaSet = ({
  setId,
  questions,
  randomCities,
  currentLocation,
  onNextTriviaSet,
  onSelectRandomCity,
  onSetMastered,
}) => {
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [width, height] = useWindowSize();
  const shownVideos = useRef(new Set());

  const { lteSm, lteXs: mobileStyle } = useVBBreakpoint();

  const question = questions[currentQuestionIdx];

  const hasAnswer = answers[currentQuestionIdx] !== undefined;
  const hasRandomCities = Boolean(randomCities.length);

  const correctAnswersCount = answers
    .map((answer, idx) => answer === questions[idx].correctOptionIdx)
    .filter(Boolean).length;

  const remainingQuestionsCount = questions.length - answers.length;

  const status = getTriviaStatus(correctAnswersCount, questions.length === answers.length);

  const isSetFinished = status !== TriviaStatus.NOT_FINISHED;

  const showConfetti = [TriviaStatus.GREAT_JOB, TriviaStatus.MASTERED].includes(status);

  const handleSelectAnswer = useCallback((answer) => {
    setAnswers((answers) => [...answers, answer]);
  }, []);

  const handleNextQuestion = useCallback(() => {
    setCurrentQuestionIdx((currentQuestionIdx) => currentQuestionIdx + 1);
  }, []);

  const handleNextTriviaSet = useCallback(() => {
    setAnswers([]);
    setCurrentQuestionIdx(0);

    onNextTriviaSet(setId);
  }, [onNextTriviaSet, setId]);

  const { content } = useContentSingle({
    type: ContentTypes.triviaVenue.type,
    id: question.id,
    disabled: !question?.matches?.length,
    getQueryAddress: useCallback(() => {
      const queryParams = {
        shownVideoIds: Array.from(shownVideos.current),
      };

      return `${APIConfig.NAMESPACE.TRIVIA}/${question.id}/venue?${stringify(queryParams, { arrayFormat: 'bracket' })}`;
    }, [question]),
    getContent: useCallback(
      (content) => {
        if (content.venue?.featuredVideo?.id) {
          shownVideos.current.add(content.venue.featuredVideo.id);
        }

        return { id: question.id, venue: content.venue };
      },
      [question]
    ),
  });

  const venue = content?.venue;

  useEffect(() => {
    if (status === TriviaStatus.MASTERED) {
      onSetMastered(setId);
    }
  }, [onSetMastered, setId, status]);

  return (
    <div>
      <TriviaQuestion
        question={question}
        answer={answers[currentQuestionIdx]}
        disabled={hasAnswer}
        onSelectAnswer={handleSelectAnswer}
      />
      {hasAnswer && (
        <>
          <div className={styles.content}>
            {!isSetFinished && (
              <span>
                {correctAnswersCount}/{answers.length} Correct! ({remainingQuestionsCount}{' '}
                {remainingQuestionsCount === 1 ? 'question' : 'questions'} remaining)
              </span>
            )}
            {Boolean(question.explanation) && (
              <MarkdownText text={`Explanation: ${question.explanation}`} matches={question.matches} />
            )}
            {isSetFinished ? (
              <span className={styles.status}>{TriviaResultMessage[status]}</span>
            ) : (
              <OnClickLink
                className={mergeClassNames(styles.next, lteSm && styles.mobile)}
                onClick={handleNextQuestion}
              >
                Next
              </OnClickLink>
            )}
          </div>
          {Boolean(venue) && (
            <div className={styles.venue}>
              <VenueCard venue={venue} showDescription showVideoIfAvailable={false} disablePadding={mobileStyle} />
            </div>
          )}
        </>
      )}
      {isSetFinished && (
        <div className={styles.bottomButtons}>
          <VBButton
            type="border"
            size="med"
            content={`${currentLocation.city} Play Again`}
            className={styles.nextTrivia}
            onClick={handleNextTriviaSet}
            noFocusStyle
          />
          {hasRandomCities && (
            <>
              <div>- or play -</div>
              <div className={styles.closestCities}>
                {randomCities.map(({ item }) => {
                  const label = `${item.city}, ${item.regionSlug.toUpperCase()}`;

                  return (
                    <VBButton
                      key={item.key}
                      type="border"
                      color="red"
                      size="med"
                      content={label}
                      onClick={() => onSelectRandomCity(item)}
                      noFocusStyle
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={500}
          gravity={0.125}
          recycle={false}
          className={styles.confetti}
        />
      )}
    </div>
  );
};

export default TriviaSet;
