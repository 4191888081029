import React, { useContext } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import styles from './VBLink.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** URL to link to. Addressed on the site should begin with a forward slash */
  to: PropTypes.string.isRequired,

  /** children */
  children: PropTypes.node,

  /** whether to open the link in a new tab */
  newTab: PropTypes.bool,

  /** whether to remove the default link style (excluding cursor: pointer) */
  noStyle: PropTypes.bool,

  /** additional class names */
  className: PropTypes.string,

  /** whether to force using react router links */
  forceReactRouterLink: PropTypes.bool,

  /** whether to force using html a tags */
  forceHtmlLink: PropTypes.bool,
};

const defaultProps = {
  children: null,
  newTab: false,
  noStyle: false,
  className: undefined,
  forceReactRouterLink: false,
  forceHtmlLink: false,
};

/**
 * Returns either a link using React Router or a link using the default HTML5 link tag.
 *
 * @param {string} props.to the address to link to
 */
const VBLink = ({ to, newTab, children, noStyle, className, forceReactRouterLink, forceHtmlLink, ...other }) => {
  const { routerLinks } = useContext(VBReactContext);

  let classNameProps = null;
  if (noStyle || className) {
    classNameProps = { className: mergeClassNames(noStyle ? styles.noStyle : null, className, styles.link) };
  }

  if (forceHtmlLink || (!forceReactRouterLink && (!routerLinks || to[0] !== '/' || newTab))) {
    const extra = {};
    if (newTab)
      Object.assign(extra, {
        target: '_blank',
        // https://pointjupiter.com/what-noopener-noreferrer-nofollow-explained/
        rel: 'noopener noreferrer',
      });
    return (
      <a href={to} {...classNameProps} {...extra} {...other}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={to} {...classNameProps} {...other}>
      {children}
    </ReactRouterLink>
  );
};
export default VBLink;

VBLink.propTypes = propTypes;
VBLink.defaultProps = defaultProps;
