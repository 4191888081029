import { getKey } from '../../../../../../util/objects';
import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  REMOVE_ITEM,
  REMOVE_FROM,
  ADD_TO,
  ITEMS_REFRESHING,
  ITEMS_REFRESHED,
  ITEMS_FAILED,
} from '../../../../../types';

/**
 * Reducer for contentFactoryData.items[context].filters[filter].
 *
 * @param {object} state the current filter array
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx.
 *
 * @returns {object} the new filters array
 */
const filtersReducer = (
  state = { items: [], offset: 0, noMore: false, isLoading: false, isRefreshing: false, error: null },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ITEMS_FAILED: {
      const { error } = payload;
      return { ...state, isLoading: false, isRefreshing: false, error };
    }
    case ITEMS_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case ITEMS_REFRESHING:
      return { ...state, isRefreshing: true, error: null };
    case ITEMS_REFRESHED: {
      const { items, offset, noMore } = payload;
      const contentType = payload.type;
      const newItems = items.map(({ item, contextual }) => {
        // TODO: get key based on content type
        const key = getKey(item);
        return { item: key, contextual };
      });
      return { ...state, offset, noMore, isRefreshing: false, items: newItems };
    }
    case ITEMS_RECEIVED: {
      const { items, offset, noMore } = payload;
      const contentType = payload.type;
      const newItems = [...state.items];
      items.forEach(({ item, contextual }) => {
        // TODO: get key based on content type
        const key = getKey(item);
        if (!newItems.find((x) => x.item === key)) {
          newItems.push({ item: key, contextual });
        }
      });
      return { ...state, offset, noMore, isLoading: false, items: newItems };
    }
    case ADD_TO: {
      const { content, contextual, filter, index, context } = payload;
      const parsedFilter = JSON.parse(filter);
      const newItems = [...state.items];
      const ind = index ? index(context, parsedFilter, newItems) : newItems.length;
      const contentType = payload.type;
      // TODO: get key based on content type
      const key = getKey(content);
      newItems.splice(ind, 0, { item: key, contextual: contextual?.(context, parsedFilter) });
      return { ...state, offset: state.offset + 1, items: newItems };
    }

    case REMOVE_FROM:
    case REMOVE_ITEM: {
      const { id } = payload;
      const newItems = state.items.filter((x) => x.item !== id);
      return { ...state, items: newItems, offset: state.offset + newItems.length - state.items.length };
    }
    default:
  }
  return state;
};

export default filtersReducer;
