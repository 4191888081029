import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './GemDropdown.module.scss';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import { useDropdown } from '../../hooks/dropdown';

const propTypes = {
  /** the gem to render (gold, silver, bronze) */
  gemBadge: PropTypes.oneOf(['gold', 'silver', 'bronze']),
};

const defaultProps = {
  gemBadge: undefined,
};

/**
 * The gem badge on venue cards.
 */
const GemDropdown = ({ gemBadge, ...other }) => {
  const { brandingConfig } = useContext(VBReactContext);

  // Create badge
  const gems = brandingConfig.BADGES[gemBadge].node;
  const label = brandingConfig.BADGES[gemBadge].tooltip;

  return (
    <>
      {gems}
      <label className={styles.badgeLabel}>{label}</label>
    </>
  );
};

export default GemDropdown;
GemDropdown.propTypes = propTypes;
GemDropdown.defaultProps = defaultProps;
