import useBreakpoint from 'use-breakpoint';
import Breakpoints from '../../config-json/breakpoints.json';
import { uppercaseFirst } from '../util/string';

const BREAKPOINTS = [
  { name: 'xs', value: Breakpoints.XS },
  { name: 'sm', value: Breakpoints.SM },
  { name: 'md', value: Breakpoints.MD },
  { name: 'lg', value: Breakpoints.LG },
  { name: '1280', value: Breakpoints['1280'] },
  { name: 'xl', value: Breakpoints.XL },
  { name: 'xxl', value: Breakpoints.XXL },
];

const CONDITIONS = [
  { name: 'lt', compare: (b, x) => b < x },
  { name: 'gt', compare: (b, x) => b > x },
  { name: 'lte', compare: (b, x) => b <= x },
  { name: 'gte', compare: (b, x) => b >= x },
];

/**
 * Preconfigured breakpoints hook. Use Bootstrap-breakpoint-mixin-like syntax, ie {lteMd, gtXs} = useVBBreakpoint().
 * @returns {object}
 */
export const useVBBreakpoint = () => {
  const { breakpoint } = useBreakpoint(Breakpoints.VALUES);

  const obj = {};
  BREAKPOINTS.forEach((b) => {
    const upperName = uppercaseFirst(b.name);
    CONDITIONS.forEach((c) => {
      obj[c.name + upperName] = c.compare(breakpoint, b.value);
    });
  });
  return obj;
};

export default useVBBreakpoint;
