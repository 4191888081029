import { useCallback, useEffect } from 'react';

/**
 * Hook for listening for window scroll.
 *
 * @param {function} cb the callback
 * @param {any[]} deps the callback dependencies
 * @param {bool} callOnAddListener whether to call the function when the listener is added
 */
const useWindowScroll = (cb, deps, callOnAddListener = false, isListening = true) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(cb, deps);

  useEffect(() => {
    if (isListening) {
      window.addEventListener('scroll', callback);

      if (callOnAddListener) {
        callback();
      }

      return () => window.removeEventListener('scroll', callback);
    }
  }, [callback, callOnAddListener, isListening]);
};

export default useWindowScroll;
