import ItineraryDay from '../../../../../../../shared-react/src/components/ItineraryDay/ItineraryDay';
import { ItinerarySeasonLabel } from '../../PopupItinerary/config';

import styles from './RenderedItinerary.module.scss';

const RenderedItinerary = ({ item }) => {
  return (
    <div className={styles.itinerary}>
      <h2 className={styles.heading}>{ItinerarySeasonLabel[item.season]} Itinerary</h2>
      <div className={styles.days}>
        {item.days.map((day) => {
          return (
            <div key={day.id}>
              <ItineraryDay
                video={day.video}
                description={day.description}
                matches={day.matches}
                injectImages
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RenderedItinerary;
