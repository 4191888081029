import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import APIConfig from '../config/api';
import { uppercaseFirst } from '../util/string';
import { removeItem } from '../store/actions/apiActions';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';

/**
 * Hook to delete posts
 *
 * @returns {(type: string, id: int): void} function to report an item
 */
export const useDeletePost = () => {
  const { vbRequest, raiseToast } = useContext(VBReactContext);
  const dispatch = useDispatch();
  const del = useCallback(
    (type, postId, userId) => {
      dispatch(removeItem(type, postId));
      return new Promise(() => {
        vbRequest(`${APIConfig.NAMESPACE.POST}/${postId}`, { method: 'DELETE' })
          .then((response) => {
            if (response.success) {
              raiseToast(`${uppercaseFirst(type)} successfully deleted.`, 'Deleted', 'info');
            } else {
              raiseToast(`There was an error deleting this ${type}.`, 'Error', 'warning');
              console.error(response?.success);
            }
          })
          .catch((err) => {
            raiseToast(`There was an error deleting this ${type}.`, 'Error', 'warning');
            console.error(err?.message);
          });
      });
    },
    [dispatch, raiseToast, vbRequest]
  );

  return del;
};
export default useDeletePost;
