/**
 * Actions to deal with resetting the scroll on location change.
 */

import { SHOULD_PRESERVE_SCROLL_POSITION, CLEAR_PRESERVE_SCROLL_POSITION } from '../types';

/**
 * Dispatch this before changing the location via React Router to preserve the scroll position when changing pages.
 */
export const shouldPreserveScrollPosition = () => {
  return (dispatch) =>
    dispatch({
      type: SHOULD_PRESERVE_SCROLL_POSITION,
    });
};

/**
 * Resets preservation.
 */
export const clearPreserveScrollPosition = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_PRESERVE_SCROLL_POSITION,
    });
};
