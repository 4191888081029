import PropTypes from 'prop-types';
import ExploreCard from '../../../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import { timeAgo } from '../../../../../../../shared-react/src/util/time';

import styles from './Question.module.scss';

const propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onAnswer: PropTypes.func,
};

const Question = ({ question, onDelete, onAnswer }) => {
  return (
    <ExploreCard
      description={question.question}
      avatar={question.user.avatar}
      author={question.user.username}
      date={timeAgo(question.time, true)}
      onDelete={onDelete}
      additionalActions={
        Boolean(onAnswer) && (
          <div className={styles.answer} onClick={onAnswer}>
            Answer
          </div>
        )
      }
    />
  );
};

Question.propTypes = propTypes;

export default Question;
