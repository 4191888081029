import { useContentFactory } from '../../../../../../shared-react/src/hooks/content-factory';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../../shared-react/src/config/api';
import React, { useMemo, useState } from 'react';
import QueryString from 'query-string';
import HomepageBottom from '../../../../../../shared-react/src/components/HomepageBottom/HomepageBottom';
import RenderedItinerary from '../../PageExplore/PageDetails/RenderedItinerary/RenderedItinerary';
import useClosestCity from '../../../../../../shared-react/src/hooks/closest-city';
import useRandomCity from '../../../../../../shared-react/src/hooks/random-city';

/**
 * Itineraries feed below the top section on the TF homepage.
 */
const HomepageBottomTF = () => {
  const { city: location } = useClosestCity();
  const { city: randomLocation } = useRandomCity();

  const [isMostPopular, setIsMostPopular] = useState(false);

  const itinerariesCf = useContentFactory({
    context: `top-itineraries:${location?.id}`,
    type: ContentTypes.itinerary.type,
    getQueryAddress: (offset) => {
      const qp = QueryString.stringify(
        { locationId: location?.id, offset, radius: [0, 1] },
        { arrayFormat: 'bracket' }
      );
      return `${APIConfig.NAMESPACE.ITINERARIES}/search?${qp}`;
    },
    getItems: ({ itineraries }) => itineraries.map((data) => ({ item: data.itinerary, contextual: data.days })),
    disabled: !location?.id,
  });

  return (
    <HomepageBottom
      contentFactory={itinerariesCf}
      renderAs={RenderedItinerary}
      renderAsProps={useMemo(() => ({ mostPopular: isMostPopular, setMostPopular: setIsMostPopular }), [isMostPopular])}
      closestCityLink={location?.searchLink ?? location?.link}
      randomCityLink={randomLocation?.searchLink ?? randomLocation?.link}
      topText={location?.city && location?.region ? `Itineraries in ${location.city}, ${location.region}:` : null}
      doubleWidth
    />
  );
};

export default HomepageBottomTF;
