/**
 * Configuration relating to the different content types that the server can send the client.
 */

// import { PropTypesVBPlaceDetails, PropTypesVBUser, PropTypesVBVenue } from '../util/types';

export const ContentTypes = {
  venue: {
    type: 'venue',
    // propType: PropTypesVBVenue,
    // getSingleAddress: null,
    references: [
      {
        path: ['latestUsers', 'users'],
        arrayOf: true,
        type: 'user',
      },
      {
        path: ['description'],
        arrayOf: false,
        type: 'venueDescription',
      },
      {
        path: ['userReview'],
        arrayOf: false,
        type: 'review',
      },
      {
        path: ['city'],
        type: 'location',
      },
      {
        path: ['featuredVideo'],
        arrayOf: false,
        type: 'venueVideo',
      },
    ],
  },
  venueDescription: {
    type: 'venueDescription',
    references: [
      {
        path: ['user'],
        arrayOf: false,
        type: 'user',
      },
      {
        path: ['venue'],
        arrayOf: false,
        type: 'venue',
      },
    ],
  },
  location: {
    type: 'location',
    // propType: PropTypesVBPlaceDetails,
    // getSingleAddress: null,
    references: [
      {
        path: ['latestUsers', 'users'],
        arrayOf: true,
        type: 'user',
      },
    ],
  },
  user: {
    type: 'user',
    // propType: PropTypesVBUser,
    // getSingleAddress: null,
  },
  notification: {
    type: 'notification',
  },
  postOrVideo: {
    type: 'postOrVideo',
    references: [
      {
        path: ['post'],
        arrayOf: false,
        type: 'post',
      },
      {
        path: ['video'],
        arrayOf: false,
        type: 'venueVideo',
      },
    ],
  },
  post: {
    type: 'post',
    references: [
      {
        path: ['user'],
        arrayOf: false,
        type: 'user',
      },
      {
        path: ['venue'],
        arrayOf: false,
        type: 'venue',
      },
      {
        path: ['firstComment'],
        arrayOf: false,
        type: 'comment',
      },
    ],
  },
  review: {
    type: 'review',
  },
  snippetCategory: {
    type: 'snippetCategory',
    references: [
      {
        path: ['previews'],
        arrayOf: true,
        type: 'snippet',
      },
    ],
  },
  snippet: {
    type: 'snippet',
  },
  reviewOrSnippet: {
    type: 'reviewOrSnippet',
    references: [
      {
        path: ['review'],
        arrayOf: false,
        type: 'review',
      },
      {
        path: ['snippet'],
        arrayOf: false,
        type: 'snippet',
      },
    ],
  },
  reviewReply: {
    type: 'reviewReply',
  },
  comment: {
    type: 'comment',
  },
  commentReply: {
    type: 'commentReply',
  },
  followFeedItem: {
    type: 'followFeedItem',
    references: [
      {
        path: ['users'],
        arrayOf: true,
        type: 'user',
      },
    ],
  },
  itinerary: {
    type: 'itinerary',
    references: [
      {
        path: ['days'],
        arrayOf: true,
        type: 'itineraryDay',
      },
      {
        path: ['userReview'],
        arrayOf: false,
        type: 'review',
      },
    ],
  },
  itineraryDay: {
    type: 'itineraryDay',
    references: [
      {
        path: ['activities'],
        arrayOf: true,
        type: 'itineraryActivity',
      },
    ],
  },
  itineraryActivity: {
    type: 'itineraryActivity',
    references: [
      {
        path: ['venue'],
        arrayOf: false,
        type: 'venue',
      },
      {
        path: ['customActivity'],
        arrayOf: false,
        type: 'customActivity',
      },
    ],
  },
  trip: {
    type: 'trip',
    references: [
      {
        path: ['itinerary'],
        arrayOf: false,
        type: 'itinerary',
      },
    ],
  },
  tripPost: {
    type: 'tripPost',
    references: [
      {
        path: ['post'],
        arrayOf: false,
        type: 'post',
      },
    ],
  },
  proposedActivity: {
    type: 'proposedActivity',
    references: [
      {
        path: ['user'],
        arrayOf: false,
        type: 'user',
      },
      {
        path: ['venue'],
        arrayOf: false,
        type: 'venue',
      },
    ],
  },
  customActivity: {
    type: 'customActivity',
  },
  venueVideo: {
    type: 'venueVideo',
    references: [
      {
        path: ['venue'],
        arrayOf: false,
        type: 'venue',
      },
    ],
  },
  explorePageTotal: {
    type: 'explorePageTotal',
  },
  collection: {
    type: 'collection',
  },
  listAndCollection: {
    type: 'listAndCollection',
    references: [
      {
        path: ['collection'],
        arrayOf: false,
        type: 'collection',
      },
    ],
  },
  tweet: {
    type: 'tweet',
  },
  blogPost: {
    type: 'blogPost',
    references: [
      {
        path: ['user'],
        arrayOf: false,
        type: 'user',
      },
    ],
  },
  topCity: {
    type: 'topCity',
    references: [
      {
        path: ['venues'],
        arrayOf: true,
        type: 'venue',
      },
    ],
  },
  event: {
    type: 'event',
    references: [
      {
        path: ['users'],
        arrayOf: true,
        type: 'user',
      },
    ],
  },
  venueQA: {
    type: 'venueQA',
  },
  festival: {
    type: 'festival',
  },
  trivia: {
    type: 'trivia',
  },
  triviaVenue: {
    type: 'triviaVenue',
  },
  triviaQuiz: {
    type: 'triviaQuiz',
  },
  randomCities: {
    type: 'randomCities',
  },
  randomCityWithTrivia: {
    type: 'randomCityWithTrivia',
  },
  locationQuestion: {
    type: 'locationQuestion',
  },
  closestCities: {
    type: 'closestCities',
  },
  locationVideo: {
    type: 'locationVideo',
  },
  userFilters: {
    type: 'userFilters',
  },
  userVenues: {
    type: 'userVenues',
  },
  exploreArticles: {
    type: 'exploreArticles',
  },
  venueWithArticles: {
    type: 'venueWithArticles',
  },
  venueArticles: {
    type: 'venueArticles',
    references: [
      {
        path: ['articles'],
        arrayOf: true,
        type: 'article',
      },
    ],
  },
  articlePost: {
    type: 'articlePost',
    references: [
      {
        path: ['articles'],
        arrayOf: true,
        type: 'article',
      },
    ],
  },
  faq: {
    type: 'faq',
  },
  fact: {
    type: 'fact',
  },
  newsletter: {
    type: 'newsletter',
  },
  article: {
    type: 'article',
  },
};
