import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/actions/authTokenActions';
import { setAuthToken } from '../util/api';

const useSetToken = () => {
  const dispatch = useDispatch();
  return useCallback(
    (token) => {
      dispatch(setToken(token));
      setAuthToken(token);
    },
    [dispatch]
  );
};

export default useSetToken;
