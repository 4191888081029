import { Fragment, useMemo } from 'react';
import { STAR_SEGMENTS_COUNT, STAR_SEGMENT_WIDTH } from './config';

const Star = ({ value }) => {
  const gradientId = `grad_${value};`;

  const gradient = useMemo(() => {
    return (
      <linearGradient id={gradientId}>
        {[...Array(STAR_SEGMENTS_COUNT)].map((_, segment) => {
          const isSegmentFilled = segment <= value - 1;
          const color = isSegmentFilled ? '#ffffff' : 'transparent';

          return (
            <Fragment key={`${value}_${segment}`}>
              <stop offset={`${segment * STAR_SEGMENT_WIDTH}%`} stopColor={color}></stop>
              <stop offset={`${(segment + 1) * STAR_SEGMENT_WIDTH}%`} stopColor={color}></stop>
            </Fragment>
          );
        })}
      </linearGradient>
    );
  }, [gradientId, value]);

  return (
    <svg height="1.15rem" width="1.15rem" viewBox="0 0 24 24">
      <defs>{gradient}</defs>
      <path
        fill={`url(#${gradientId})`}
        stroke="#ffffff"
        strokeWidth="1.5"
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      ></path>
    </svg>
  );
};

export default Star;
