/* eslint-disable no-undef */
/**
 * API related configuration.
 */

let base = 'http://localhost:8080/wp-json/';
try {
  if (VB_API_BASE) base = VB_API_BASE;
} catch (err) {
  // do nothing
}

const APIConfig = {
  BASE: base,
  NAMESPACE: {
    AUTH: 'vb/v1/auth',
    BUCKET: 'vb/v1/bucket',
    COMMENT: 'vb/v1/comment',
    CONTACT: 'vb/v1/contact',
    CONTENT_REVIEW: 'vb/v1/content-review',
    EXPERT: 'vb/v1/expert',
    EZPULL: 'vb/v1/ezpull',
    ITINERARIES: 'vb/v1/itineraries',
    ITINERARY: 'vb/v1/itinerary',
    MEDIA: 'vb/v1/media',
    NOTIFICATIONS: 'vb/v1/notifications',
    POST: 'vb/v1/post',
    PROCESSING: 'vb/v1/processing',
    REPORT: 'vb/v1/report',
    REVIEW: 'vb/v1/review',
    SEARCH: 'vb/v1/search',
    SNIPPET: 'vb/v1/snippet',
    SOCIAL: 'vb/v1/social',
    STAGING: 'vb/v1/staging',
    SECURITY: 'vb/v1/security',
    SLOGIN: 'vb/v1/slogin',
    SEO: 'vb/v1/seo',
    VENUE: 'vb/v1/venue',
    VIDEO: 'vb/v1/venue-video',
    BLOG: 'vb/v1/blog',
    EVENTS: 'vb/v1/events',
    MARKETING: 'vb/v1/marketing',
    GAMES: 'vb/v1/games',
    LOCATION: 'vb/v1/location',
    ARTICLE: 'vb/v1/article',
    FESTIVAL: 'vb/v1/festival',
    DUMMY_USER: 'vb/v1/dummy-user',
    USER: 'vb/v1/user',
    FAQ: 'vb/v1/faq',
    TRIVIA: 'vb/v1/trivia',
    NEWSLETTER: 'vb/v1/newsletter',
  },
};
export default APIConfig;
