import React from 'react';
import PropTypes from 'prop-types';
import styles from './PriceBadge.module.scss';

const propTypes = {
  /** venue price field value */
  price: PropTypes.number,
};

/**
 * Shows the price of a venue within the venue card.
 */
const PriceBadge = ({ price }) => {
  let value;
  if (typeof price !== 'number' || isNaN(price)) {
    value = null;
  } else if (price === 0) {
    value = 'Free';
  } else if (price < 5) {
    value = '$';
  } else if (price < 12) {
    value = '$$';
  } else if (price < 22) {
    value = '$$$';
  } else {
    value = '$$$$';
  }

  return value ? <span className={styles.badge}>{value}</span> : null;
};

export default PriceBadge;
PriceBadge.propTypes = propTypes;
