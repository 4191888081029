import PropTypes from 'prop-types';
import VideoCard from '../../../../../../shared-react/src/components/VideoCard/VideoCard';

const propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  isSelected: false,
  isLoading: false,
};

const RenderedVideo = ({ item, isSelected, isLoading, ...props }) => {
  return <VideoCard video={item} isSelected={isSelected} isLoading={isLoading} withRatingActions {...props} />;
};

RenderedVideo.propTypes = propTypes;
RenderedVideo.defaultProps = defaultProps;

export default RenderedVideo;
