import { lazy, Suspense } from 'react';
import VBSpinner from '../../../../../../shared-react/src/components/VBSpinner/VBSpinner';

const ArticleComments = lazy(() => import('./ArticleComments/ArticleComments'));

const ArticleCommentsWrapper = (props) => {
  return (
    <Suspense fallback={<VBSpinner center />}>
      <ArticleComments {...props} />
    </Suspense>
  );
};

export default ArticleCommentsWrapper;
