/* eslint-disable react/no-multi-comp */
import React from 'react';
import MapEventCard from '../components/MapEventCard/MapEventCard';
import MapVenueCard from '../components/MapVenueCard/MapVenueCard';
import { mapDefaultCenter } from '../config/map';
import { isUndefined } from './objects';

/**
 * Find geometric center (not average) of coordinates
 * @param {array} locations locations array, see shared-react/src/components/Map/Map.jsx
 * @returns {object} {latitude, longitude}, the center
 */
export const findCenter = (locations) => {
  if (!locations?.length) return mapDefaultCenter;

  let minLat;
  let minLon;
  let maxLat;
  let maxLon;

  locations.forEach((location) => {
    if (isUndefined(location?.latitude) || location.latitude === null) return;
    if (isUndefined(location?.longitude) || location.longitude === null) return;
    const { latitude: lat, longitude: lon } = location;

    if (isUndefined(minLat) || lat < minLat) {
      minLat = lat;
    }
    if (isUndefined(maxLat) || lat > maxLat) {
      maxLat = lat;
    }
    if (isUndefined(minLon) || lon < minLon) {
      minLon = lon;
    }
    if (isUndefined(maxLon) || lon > maxLon) {
      maxLon = lon;
    }
  });

  return {
    latitude: (minLat + maxLat) / 2,
    longitude: (minLon + maxLon) / 2,
  };
};

/**
 * Builds a renderCard function for a venue (although you will still have to wrap this in your own function to pass in
 * entry and optionally trueIndex). Used in conjunction with Map.
 *
 * @param {bool} noRank
 * @param {bool} shouldShowCardInNonFullscreen
 * @returns {function}
 */
export const buildRenderItemVenue = (noRank = false, shouldShowCardInNonFullscreen = false) => {
  return (entry, trueIndex, handleClickNext, handleClickPrevious, isFullscreen) => {
    if (!isFullscreen && !shouldShowCardInNonFullscreen) return null;

    return (
      <MapVenueCard
        venue={entry.item}
        rank={noRank || (!trueIndex && trueIndex !== 0) ? undefined : trueIndex + 1}
        direction={entry.contextual?.direction}
        distance={entry.contextual?.distance}
        onClickNext={isFullscreen && handleClickNext}
        onClickPrevious={isFullscreen && handleClickPrevious}
        isSmall={shouldShowCardInNonFullscreen && !isFullscreen}
      />
    );
  };
};

/**
 * Builds a renderCard function for an event (although you will still have to wrap this in your own function to pass in
 * entry and optionally trueIndex). Used in conjunction with Map.
 *
 * @param {bool} shouldShowCardInNonFullscreen
 * @returns {function}
 */
export const buildRenderItemEvent = (shouldShowCardInNonFullscreen = false) => {
  return (entry, trueIndex, handleClickNext, handleClickPrevious, isFullscreen) => {
    if (!isFullscreen && !shouldShowCardInNonFullscreen) return null;

    return (
      <MapEventCard
        event={entry.item}
        onClickNext={isFullscreen && handleClickNext}
        onClickPrevious={isFullscreen && handleClickPrevious}
      />
    );
  };
};
