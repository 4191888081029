/* eslint-disable react/require-default-props */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { mergeClassNames } from '../../util/props';
import styles from './ProgressiveImage.module.scss';
import { PropTypesRefProp } from '../../util/types';
import useLazyLoad from '../../hooks/lazy-load';
import { progressiveImageLoaded } from '../../store/actions/progressiveImagesActions';
import useWindowScroll from '../../hooks/window-scroll';

const propTypes = {
  /** src of the full image */
  fullSrc: PropTypes.string.isRequired,

  /** src of the small image */
  smallSrc: PropTypes.string.isRequired,

  /** alt on the image */
  alt: PropTypes.string.isRequired,

  /** additional class names for the image (these will be applied to both the small and full images) */
  imageClassName: PropTypes.string,

  /** additional class names for the small image */
  smallImageClassName: PropTypes.string,

  /** additional class names for the full image */
  fullImageClassName: PropTypes.string,

  /** additional class names for the container div */
  containerClassName: PropTypes.string,

  /** style for the image (these will be applied to both the small and full images) */
  imageStyle: PropTypes.object,

  /** style for the small image */
  smallImageStyle: PropTypes.object,

  /** style for the full image */
  fullImageStyle: PropTypes.object,

  /** style for the container div */
  containerStyle: PropTypes.object,

  /** whether to wait until the component is in view before loading the full image */
  noImmediateLoadFull: PropTypes.bool,

  /** whether or not to disable loading the full image. This is useful for when the image is in the viewport, but is
   *  not being displayed
   */
  isLoadFullImageDisabled: PropTypes.bool,

  /** callback for when the small image has loaded */
  onSmallImageLoad: PropTypes.func,

  /** callback for when the full image has loaded */
  onFullImageLoad: PropTypes.func,

  /** ref for the container div */
  containerRef: PropTypesRefProp,

  /** whether to set referrer-policy as no-referrer or not (used for google images) */
  noReferrer: PropTypes.bool,

  /** whether or not to load image lazily */
  lazy: PropTypes.bool,

  dimensions: PropTypes.object,
};

/**
 * Switch between a blurred, low res image and a higher res image as each load in.
 * Example (and a source of inspiration): https://frontend-digest.com/progressively-loading-images-in-react-107cb075417a
 */
const ProgressiveImage = ({
  fullSrc,
  smallSrc,
  alt,
  imageClassName,
  fullImageClassName,
  smallImageClassName,
  containerClassName,
  imageStyle,
  fullImageStyle,
  smallImageStyle,
  containerStyle,
  noImmediateLoadFull,
  isLoadFullImageDisabled,
  onSmallImageLoad,
  onFullImageLoad,
  containerRef,
  noReferrer,
  lazy,
  dimensions,
}) => {
  const smallImageRef = useRef();
  const fullImageRef = useRef();
  const dispatch = useDispatch();

  const [smallImageLoaded, setSmallImageLoaded] = useState(false);
  const [fullImageLoaded, setFullImageLoaded] = useState(false);
  const [smallImageLoadFired, setSmallImageLoadFired] = useState(false);
  const [fullImageLoadFired, setFullImageLoadFired] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);

  const otherImages = useSelector((state) => state.progressiveImages);
  const isFullImageInCache = otherImages.indexOf(fullSrc) >= 0;

  // On changes to src, reset state.
  useEffect(() => {
    setSmallImageLoaded(false);
    setFullImageLoaded(false);
    setSmallImageLoadFired(false);
    setFullImageLoadFired(false);
    setShowFullImage(false);
  }, [smallSrc, fullSrc]);

  // Fire small image load callback.
  useEffect(() => {
    if (smallImageLoaded && !smallImageLoadFired && onSmallImageLoad) {
      onSmallImageLoad();
      setSmallImageLoadFired(true);
    }
  }, [onSmallImageLoad, smallImageLoadFired, smallImageLoaded]);

  // Fire full image load callback, track that it has been cached.
  useEffect(() => {
    if (fullImageLoaded && !fullImageLoadFired) {
      if (onFullImageLoad) {
        onFullImageLoad();
        setFullImageLoadFired(true);
      }

      dispatch(progressiveImageLoaded(fullSrc));
    }
  }, [fullImageLoadFired, fullImageLoaded, onFullImageLoad, dispatch, fullSrc]);


  const smallImageAppliedStyle = { ...smallImageStyle, ...imageStyle };
  const fullImageAppliedStyle = { ...fullImageStyle, ...imageStyle };

  const shouldRenderFullImage = useLazyLoad(smallImageRef);

  const ImageComponent = lazy ? LazyLoadImage : 'img';

  // Determine whether or not the full image should be rendered.
  useEffect(() => {
    if (!smallImageLoaded || isLoadFullImageDisabled) return;

    if (!noImmediateLoadFull || shouldRenderFullImage || isFullImageInCache) {
      setShowFullImage(true);
    }
  }, [shouldRenderFullImage, isLoadFullImageDisabled, noImmediateLoadFull, smallImageLoaded, isFullImageInCache]);

  useEffect(() => {
    if (isFullImageInCache) {
      setSmallImageLoaded(true);
      setFullImageLoaded(true);
    }
  }, [isFullImageInCache]);

  useWindowScroll(() => {
    setShowFullImage(true);
  }, []);

  const showSmallImage = !fullImageLoaded;

  return (
    <div className={mergeClassNames(styles.container, containerClassName)} style={containerStyle} ref={containerRef}>
      <ImageComponent
        ref={smallImageRef}
        src={smallSrc}
        alt={alt}
        width={dimensions?.width}
        height={dimensions?.height}
        className={mergeClassNames(
          styles.image,
          styles.small,
          showSmallImage ? styles.visible : null,
          imageClassName,
          smallImageClassName
        )}
        style={smallImageAppliedStyle}
        referrerPolicy={noReferrer ? 'no-referrer' : undefined}
        onLoad={() => setSmallImageLoaded(true)}
      />
      {showFullImage || isFullImageInCache ? (
        <ImageComponent
          ref={fullImageRef}
          src={fullSrc}
          alt={alt}
          width={dimensions?.width}
          height={dimensions?.height}
          className={mergeClassNames(
            styles.image,
            styles.full,
            showSmallImage ? null : styles.visible,
            isFullImageInCache && !showFullImage ? styles.noTransition : null,
            imageClassName,
            fullImageClassName
          )}
          style={fullImageAppliedStyle}
          referrerPolicy={noReferrer ? 'no-referrer' : undefined}
          onLoad={() => setFullImageLoaded(true)}
        />
      ) : null}
    </div>
  );
};

export default ProgressiveImage;

ProgressiveImage.propTypes = propTypes;
