import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import VBButton from '../../VBButton/VBButton';
import VBNWaySwitch from '../../VBNWaySwitch/VBNWaySwitch';
import { DEFAULT_EXPLORE_SUBSECTION } from '../../../config/explore';
import styles from './SideNavigation.module.scss';

const propTypes = {
  /** base URL of the current section */
  basePath: PropTypes.string.isRequired,

  /** subsections, like Activities, Experts, and Itineraries. These will be included in tabs at teh top of the page */
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      /** value, used for matching and comparison as well as routing. The first subsection will be matched to the
       *  section's baseUrl */
      value: PropTypes.string,

      /** pretty name of the subsection */
      name: PropTypes.string,
    })
  ).isRequired,

  /** content in the filter dropdown */
  dropdown: PropTypes.node,

  /** node to render above the content, or beside the filter if there are no subsections */
  extraFilterContent: PropTypes.node,

  /** current subsection value */
  subsection: PropTypes.string,

  bottomSwitch: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    selected: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
  }),
};
const defaultProps = {
  extraFilterContent: undefined,
  subsection: undefined,
};

/**
 * Navigation panel content on the left side of the screen on the Explore page.
 */
const SideNavigation = ({ subsections, extraFilterContent, basePath, dropdown, subsection, bottomSwitch }) => {
  const { search } = useLocation();
  const history = useHistory();

  const getButtonProps = useCallback(
    ({ name, value, total, outlink, onClick }, idx) => {
      const hasOutlink = Boolean(outlink);

      if (hasOutlink) {
        return {
          type: 'border',
          content: name,
          key: outlink,
          onClick: () => {
            onClick?.();

            if (outlink.indexOf('/') === 0) {
              window.location = outlink;
            } else {
              window.open(outlink, '_blank');
            }
          },
        };
      }

      const hasValue = Boolean(value);

      if (hasValue) {
        const nextRoute = basePath + `/${value}` + search;

        return {
          type: subsection === value ? 'full' : 'border',
          content: name + (typeof total !== 'undefined' ? ` (${total})` : ''),
          key: value,
          onClick: () => {
            onClick?.();
            history.push(nextRoute);
          },
        };
      }

      return {
        type: 'border',
        content: name,
        key: idx,
        onClick: () => {
          onClick?.();
        },
      };
    },
    [subsection, basePath, search, history]
  );

  return (
    <>
      {bottomSwitch ? (
        <div className={styles.sections}>
          <div>
            <VBNWaySwitch
              options={bottomSwitch.options}
              selected={bottomSwitch.selected}
              onChange={bottomSwitch.handleChange}
              className={styles.switch}
              fixedWidth
            />
          </div>
        </div>
      ) : null}
      <div className={styles.subsections}>
        {subsections.map((subSection, idx) => {
          const buttonProps = getButtonProps(subSection, idx);

          const showFilters = subSection.value === subsection && Boolean(dropdown || extraFilterContent);

          return (
            <>
              <VBButton {...buttonProps} className={styles.fullWidthButton} />
              {showFilters ? (
                <div className={styles.filters}>
                  <div>{dropdown}</div>
                  <div>{extraFilterContent}</div>
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    </>
  );
};

export default SideNavigation;

SideNavigation.propTypes = propTypes;
SideNavigation.defaultProps = defaultProps;
