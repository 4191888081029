import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import KidsImage from '../../../../assets/images/homepage/kids-jumping.webp';
import styles from './Sidebar.module.scss';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../../util/props';
import { VBReactContext } from '../../VBReactProvider/VBReactProvider';

const propTypes = {
  // Which image should be displayed in the sidebar. benefits is the list of benefits, splash is the login splash image.
  imageType: PropTypes.oneOf(['benefits', 'splash']),
};

const defaultProps = {
  imageType: 'benefits',
};

/**
 * The sidebar in the Sign Up popup.
 */
const Sidebar = ({ imageType }) => {
  const { brandingConfig } = useContext(VBReactContext);
  const { SIGNUP_BENEFITS_IMAGES } = brandingConfig;
  const { gtLg, lteLg } = useVBBreakpoint();

  // Change the global modal content className to avoid a weird size the modal goes to when the screen is large.
  // I couldn't figure out a better way to responsively lay out the sign up with this size.
  useEffect(() => {
    const modalContentNode = document.getElementsByClassName('modal-content')[0];
    if (imageType === 'benefits' && modalContentNode) {
      if (lteLg) {
        modalContentNode.style.maxWidth = '500px';
      } else {
        modalContentNode.style.maxWidth = '950px';
      }
    }
    return () => {
      if (modalContentNode) {
        modalContentNode.style.maxWidth = 'none';
      }
    };
  }, [imageType, lteLg]);

  let background = null;
  switch (imageType) {
    case 'benefits':
      background = SIGNUP_BENEFITS_IMAGES[gtLg ? 0 : 1];
      break;
    case 'splash':
      // Note: just using the ZZ image for now
      // background = isKids() ? KidsImage : SplashImage;
      background = KidsImage;
      break;
    default:
      throw new Error('Invalid image type');
  }

  return (
    <div
      className={mergeClassNames(
        styles.sidebar,
        gtLg ? styles.desktop : undefined,
        imageType === 'splash' ? styles.splash : undefined,
        imageType === 'benefits' ? styles.benefits : undefined
      )}
      style={{ backgroundImage: `url(${background})` }}
    />
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
