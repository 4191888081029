import {
  SET_HEADER_HEIGHT,
  SET_HEADER_VISIBILITY,
  SET_HEADER_VISIBILITY_MODE,
  SET_HEADER_HAMBURGER_VISIBILITY,
  TOGGLE_HEADER_HAMBURGER_VISIBILITY,
  SET_HEADER_HAMBURGER_TRANSITIONING,
} from '../types';

/**
 * Sets the height of the header
 *
 * @param {number} height
 */

export const setHeaderHeight = (height) => {
  return (dispatch) =>
    dispatch({
      type: SET_HEADER_HEIGHT,
      payload: height,
    });
};

/**
 * Sets the visibility of the header
 *
 * @param {bool} isVisible
 */

export const setHeaderVisibility = (isVisible) => {
  return (dispatch) =>
    dispatch({
      type: SET_HEADER_VISIBILITY,
      payload: isVisible,
    });
};

/**
 * Sets the visibility mode of the header
 *
 * @param {HeaderVisibilityMode} mode
 */

export const setHeaderVisibilityMode = (mode) => {
  return (dispatch) =>
    dispatch({
      type: SET_HEADER_VISIBILITY_MODE,
      payload: mode,
    });
};

/**
 *
 * Sets the visibility of the hamburger
 *
 * @param {bool} isOpen
 */

export const setHamburgerVisibility = (isOpen) => {
  return (dispatch) =>
    dispatch({
      type: SET_HEADER_HAMBURGER_VISIBILITY,
      payload: isOpen,
    });
};

/**
 *
 * Toggles the state of the hamburger
 *
 */

export const toggleHamburgerVisibility = () => {
  return (dispatch) =>
    dispatch({
      type: TOGGLE_HEADER_HAMBURGER_VISIBILITY,
    });
};

/**
 *
 * Sets the transition state of the hamburger
 *
 * @param {bool} isTransitioning
 */

export const setHamburgerTransitioning = (isTransitioning) => {
  return (dispatch) =>
    dispatch({
      type: SET_HEADER_HAMBURGER_TRANSITIONING,
      payload: isTransitioning,
    });
};
