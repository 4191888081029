import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styles from './SignIn.module.scss';
import { VBReactContext } from '../../VBReactProvider/VBReactProvider';
import APIConfig from '../../../config/api';
import { RECAPTCHA_PUBLIC_KEY, TEST_RECAPTCHA_TOKEN } from '../../../config/security';
import { mergeClassNames } from '../../../util/props';
import { PopupAuthPage } from '../../../util/types';
import VBButton from '../../VBButton/VBButton';
import VBTextInput from '../../VBTextInput/VBTextInput';
import OnClickLink from '../../OnClickLink/OnClickLink';
import Dot from '../../Dot/Dot';
import VBSpinner from '../../VBSpinner/VBSpinner';
import Sidebar from '../Sidebar/Sidebar';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import { isTesting } from '../../../util/env';
import GoogleLoginBadge from '../../GoogleLoginBadge/GoogleLoginBadge';
import RecaptchWrapper from '../../RecaptchaWrapper/RecaptchaWrapper';

const propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  googleLogin: PropTypes.func,
  /** ({username, password}) => Promise | value */
  handleLogin: PropTypes.func.isRequired,
  // For setting the VBAlert at the top of the modal.
  setNotice: PropTypes.func.isRequired,
  sendActivationEmail: PropTypes.func.isRequired,
};

const defaultProps = {
  googleLogin: undefined,
};

/**
 * The first page of the popup prompting the user to log in.
 *
 * @param {function} props.setCurrentPage changes the current page of the PopupAuth
 * @param {function} props.googleLogin redirects to google social login
 */
const SignIn = ({ setNotice, setCurrentPage, googleLogin, handleLogin, sendActivationEmail }) => {
  const captchaRef = useRef();
  const inputRef = useRef();

  // Input control.
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  // Captcha state
  const [captchaResult, setCaptchaResult] = useState();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [lastFailedLogin, setLastFailedLogin] = useState('');

  const [loading, setLoading] = useState(false);

  const { gteXl } = useVBBreakpoint();

  const { vbRequest } = useContext(VBReactContext);

  useEffect(() => inputRef.current && inputRef.current.focus(), [emailInput]);

  /**
   * Checks if the user can submit the login request.
   *
   * @returns bool
   */
  const canSubmit = () => {
    if (!emailInput.length) return false;
    if (!passwordInput.length) return false;
    if (showCaptcha && !captchaResult && emailInput === lastFailedLogin && !isTesting()) return false;
    if (loading) return false;

    return true;
  };

  /**
   * Handle click on the submit button. This will log the user in.
   *
   * @param {SyntheticEvent} e the event
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!canSubmit()) return false;

    setLoading(true);

    const requestLogin = () => {
      const body = {
        username: emailInput,
        password: passwordInput,
      };
      return Promise.resolve(handleLogin(body, { captcha: isTesting() ? TEST_RECAPTCHA_TOKEN : captchaResult }))
        .then(() => {
          setNotice({ message: 'Success! Logging you in now!', variant: 'success' });
          setLoading(false);
        })
        .catch((err) => {
          setLastFailedLogin(emailInput);

          // Display a custom error message depending on the error code returned.
          // Remove the prepended "[jwt_auth] " from the error code if it exists (itineraries).
          const code = err.code.replace(/^\[jwt_auth\] /, '');
          let message;
          switch (code) {
            case 'bp_account_not_activated':
              message = (
                <>
                  This account has not been activated. Please check your email for the activation link.
                  <br />
                  <OnClickLink onClick={() => sendActivationEmail(emailInput)}>Click here</OnClickLink> to resend.
                </>
              );
              break;
            case 'invalid_email':
              message = `Invalid email address ${emailInput}.`;
              break;
            case 'invalid_username':
              message = `Invalid username ${emailInput}.`;
              break;
            case 'incorrect_password':
              message = `The password you entered for ${emailInput} is incorrect.`;
              break;
            case 'invalid_captcha':
              message = `The CAPTCHA was invalid. Please try again`;
              break;
            case 'invalid_user':
              message = `Invalid user ${emailInput}.`;
              break;
            case 'vb-slogin-3rd-party':
              message = err.message;
              break;
            default:
              message = 'An error occurred while trying to log you in.';
          }
          setNotice({ message, variant: 'danger' });
          setLoading(false);
        });
    };

    if (!showCaptcha || emailInput !== lastFailedLogin) {
      vbRequest(`${APIConfig.NAMESPACE.SECURITY}/captcha-login`, { params: { username: emailInput } }).then(
        ({ captcha }) => {
          if (!captcha) {
            requestLogin();
          } else {
            setLastFailedLogin(emailInput);
            setShowCaptcha(true);
            setLoading(false);
          }
        }
      );
    } else {
      requestLogin();
      if (captchaRef.current) captchaRef.current.reset();
    }

    return false;
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className={styles.container} style={{ flexShrink: gteXl ? 0 : 1 }}>
          <h1>Sign In</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="loginEmail">
              <VBTextInput
                inputRef={inputRef}
                type="text"
                placeholder="Username / Email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                disabled={loading}
                required
              />
            </Form.Group>
            <Form.Group controlId="loginPassword">
              <VBTextInput
                type="password"
                placeholder="Password"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
                disabled={loading}
                required
              />
            </Form.Group>
            {showCaptcha && emailInput === lastFailedLogin && (
              <div className={styles.captchaContainer}>
                <div className={styles.captchaText}>
                  There have been several failed attempts to login to you account recently. Please complete this
                  CAPTCHA.
                </div>
                <RecaptchWrapper
                  sitekey={RECAPTCHA_PUBLIC_KEY}
                  onChange={(x) => setCaptchaResult(x)}
                  ref={captchaRef}
                />
              </div>
            )}
            {loading ? (
              <div
                className={mergeClassNames(
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  styles.spinnerContainer
                )}
              >
                <VBSpinner />
              </div>
            ) : (
              <VBButton size="med" submit>
                Sign In
              </VBButton>
            )}
          </Form>
          <div className={mergeClassNames('d-flex', 'align-items-center', 'text-nowrap', !gteXl && 'flex-wrap')}>
            <OnClickLink content="Forgot Password?" onClick={() => setCurrentPage(PopupAuthPage.FORGOT_PASSWORD)} />
            <Dot />
            <OnClickLink content="Don't Have an Account?" onClick={() => setCurrentPage(PopupAuthPage.SIGN_UP)} />
            <Dot />
            <GoogleLoginBadge loginLink={googleLogin} />
          </div>
        </div>
        {gteXl ? <Sidebar imageType="splash" /> : undefined}
      </div>
    </>
  );
};

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;

export default SignIn;
