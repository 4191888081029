import { SET_LOCATION, START_LOADING } from '../types';

/**
 * Reducer to use on the globalLocation attribute of the store.
 *
 * @param {object} state.location the location object
 * @param {boolean} state.loading whether a new location is loading or not
 * @param {string} action the action that the reducer should take
 */
const globalLocationReducer = (state = { location: null, loading: false }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { location: state.location, loading: true };
    case SET_LOCATION:
      return { loading: false, location: action.payload.location };
    default:
  }
  return state;
};

export default globalLocationReducer;
