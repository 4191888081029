import Avatar from '../../../../../../../shared-react/src/components/Avatar/Avatar';
import VBSpinner from '../../../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import useVbContext from '../../../../../../../shared-react/src/hooks/vb-context';

import styles from './BeenThere.module.scss';

const propTypes = {};

const BeenThere = ({ beenThereCount, totalCount, isLoading }) => {
  const { currentUser } = useVbContext();
  if (isLoading) return <VBSpinner />;
  return (
    <div className={styles.container}>
      <Avatar size="1.8rem" src={currentUser.avatar} className={styles.avatar} />
      <span className={styles.beenThere}>Been There</span>
      <span className={styles.amount}>
        {beenThereCount} / {totalCount}
      </span>
    </div>
  );
};

export default BeenThere;

BeenThere.propTypes = propTypes;
