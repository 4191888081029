import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  REMOVE_ITEM,
  ITEMS_INVALIDATE,
  REMOVE_FROM,
  ADD_TO,
  ITEMS_REFRESHING,
  ITEMS_REFRESHED,
  ITEMS_FAILED,
} from '../../../../types';
import filterReducer from './filters/filterReducer';

/**
 * Reducer for contentFactoryData.items[context].filters.
 *
 * @param {object} state the current filters object
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx.
 *
 * @returns {object} the new filters object
 */
const filtersReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEMS_RECEIVED:
    case ITEMS_REQUESTED:
    case ITEMS_REFRESHING:
    case ITEMS_FAILED:
    case ITEMS_REFRESHED: {
      const { filter } = action.payload;
      const key = JSON.stringify(filter);
      return { ...state, [key]: filterReducer(state[key], action) };
    }
    case REMOVE_ITEM: {
      return Object.keys(state).reduce(
        (acc, cur) => Object.assign(acc, { [cur]: filterReducer(state[cur], action) }),
        {}
      );
    }
    case ADD_TO: {
      const { add, context } = action.payload;
      return Object.keys(state).reduce((acc, filter) => {
        if (add(context, JSON.parse(filter))) {
          return Object.assign(acc, {
            [filter]: filterReducer(state[filter], { ...action, payload: { ...action.payload, filter } }),
          });
        }
        return Object.assign(acc, { [filter]: state[filter] });
      }, {});
    }
    case REMOVE_FROM: {
      const { remove, context } = action.payload;
      return Object.keys(state).reduce((acc, filter) => {
        if (remove(context, JSON.parse(filter))) {
          return Object.assign(acc, { [filter]: filterReducer(state[filter], action) });
        }
        return Object.assign(acc, { [filter]: state[filter] });
      }, {});
    }
    case ITEMS_INVALIDATE: {
      const { invalidate, context } = action.payload;
      return Object.keys(state).reduce((acc, filter) => {
        if (!invalidate(context, JSON.parse(filter))) {
          return Object.assign(acc, { [filter]: state[filter] });
        }
        return acc;
      }, {});
    }
    default:
  }

  return state;
};

export default filtersReducer;
