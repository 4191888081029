import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useVbContext from '../../../hooks/vb-context';
import { updateItem } from '../../../store/actions/apiActions';
import { ContentTypes } from '../../../config/content-types';
import APIConfig from '../../../config/api';

const useRateVideo = () => {
  const { vbRequest, currentUser } = useVbContext();
  const dispatch = useDispatch();

  const rate = useCallback(
    (videoId, rating) => {
      if (!currentUser) {
        return;
      }

      dispatch(updateItem(videoId, ContentTypes.venueVideo.type, { rating }));
      dispatch(updateItem(videoId, ContentTypes.locationVideo.type, { rating }));

      vbRequest(`${APIConfig.NAMESPACE.VIDEO}/${videoId}/rate`, {
        method: 'POST',
        params: {
          rating,
        },
      })
        .then((response) => {
          dispatch(updateItem(response.videoId, ContentTypes.venueVideo.type, { rating: response.rating }));
          dispatch(updateItem(response.videoId, ContentTypes.locationVideo.type, { rating: response.rating }));
        })
        .catch((err) => {
          console.error(err.message ?? err);
        });
    },
    [currentUser, dispatch, vbRequest]
  );

  return rate;
};

export default useRateVideo;
