import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { mapEllipsisArray } from '../../../util/explore';
import { ExploreEllipsisPropType } from '../../../util/types';
import styles from './TopEllipsis.module.scss';
import EllipsisDropdown from '../../EllipsisDropdown/EllipsisDropdown';
import { VBReactContext } from '../../VBReactProvider/VBReactProvider';
import { mergeClassNames } from '../../../util/props';

const propTypes = {
  /** array of items to put in the ellipsis */
  ellipsis: ExploreEllipsisPropType,
  className: PropTypes.string,
};
const defaultProps = {
  ellipsis: undefined,
};

/**
 * The ellipsis on the top of the explore page.
 * @returns
 */
const TopEllipsis = ({ ellipsis, className }) => {
  const { vbBlack } = useContext(VBReactContext);

  if (!ellipsis) {
    return null;
  }

  return (
    <div className={mergeClassNames(styles.ellipsisContainer, className)}>
      <EllipsisDropdown items={mapEllipsisArray(ellipsis)} className={styles.ellipsis} color={vbBlack} closeOnSelect />
    </div>
  );
};

export default TopEllipsis;

TopEllipsis.propTypes = propTypes;
TopEllipsis.defaultProps = defaultProps;
