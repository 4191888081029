import PropTypes from 'prop-types';
import ItineraryDay from '../../../../../../../shared-react/src/components/ItineraryDay/ItineraryDay';

const propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const RenderedItineraryDay = ({ item, isSelected, isLoading }) => {
  return (
    <ItineraryDay
      video={item.video}
      description={item.description}
      matches={item.matches}
      isSelected={isSelected}
      isLoading={isLoading}
    />
  );
};

RenderedItineraryDay.propTypes = propTypes;

export default RenderedItineraryDay;
