import { usePopup } from '../../../shared-react/src/hooks/popup';
import React from 'react';
import MakePostPopupInner from '../components/Master/PopupMakePost/PopupMakePost';
/**
 * This hook handles the PopupMakePost component so that the parent doesn't have to worry about handling the "cancel"
 * button for the popup.
 *
 * @return UsePopupReturn
 */
export const useMakePostPopup = () => {
  return usePopup({ child: <MakePostPopupInner /> });
};

export default useMakePostPopup;
