import { stringify } from 'query-string';
import APIConfig from '../../../../../../shared-react/src/config/api';

export const getVenuesQueryAddress = (offset, filter) => {
  const params = {
    offset,
    size: 3,
    distanceRanges: filter.radius,
    category: filter.category,
    ages: filter.ages,
    subcategory: filter.subcategory,
    nearMe: filter.nearMe,
    isFree: filter.isFree,
  };

  return `${APIConfig.NAMESPACE.SEARCH}/location/${filter.country}/${filter.region}/${filter.city}/venues?${stringify(
    params
  )}`;
};

export const getVenuesItems = ({ results }) =>
  results.map(({ venue, distance, direction }) => ({ item: venue, contextual: { distance, direction } }));
