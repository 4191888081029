import {
  SET_HEADER_HEIGHT,
  SET_HEADER_VISIBILITY,
  SET_HEADER_VISIBILITY_MODE,
  SET_HEADER_HAMBURGER_VISIBILITY,
  TOGGLE_HEADER_HAMBURGER_VISIBILITY,
  SET_HEADER_HAMBURGER_TRANSITIONING,
} from '../types';

export const HeaderVisibilityMode = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

const initialState = {
  isVisible: true,
  height: 0,
  mode: HeaderVisibilityMode.AUTO,
  isHamburgerOpen: false,
  isHamburgerTransitioning: false,
};

/**
 * Reducer for header
 *
 * @param {object} state
 * @param {string} action.type
 * @param {object} action.payload
 *
 * @returns {object} the new state
 */

const headerReducer = (state = initialState, action) => {
  const { payload, type: actionType } = action;

  switch (actionType) {
    case SET_HEADER_HEIGHT: {
      return {
        ...state,
        height: payload,
      };
    }
    case SET_HEADER_VISIBILITY: {
      return {
        ...state,
        isVisible: state.isHamburgerOpen || state.isHamburgerTransitioning || payload,
      };
    }
    case SET_HEADER_VISIBILITY_MODE: {
      return {
        ...state,
        mode: payload,
      };
    }
    case SET_HEADER_HAMBURGER_VISIBILITY: {
      return {
        ...state,
        isHamburgerOpen: payload,
      };
    }
    case TOGGLE_HEADER_HAMBURGER_VISIBILITY: {
      return {
        ...state,
        isHamburgerOpen: !state.isHamburgerOpen,
      };
    }
    case SET_HEADER_HAMBURGER_TRANSITIONING: {
      return {
        ...state,
        isHamburgerTransitioning: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default headerReducer;
