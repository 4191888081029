import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import VBField from '../VBForm/VBInput/VBField';
import VBTextArea from '../VBTextArea/VBTextArea';
import VBButton from '../VBButton/VBButton';

import styles from './FormFAQ.module.scss';

const propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
};

const FormFAQ = ({ defaultValues, onSubmit, onBack, onCancel }) => {
  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: defaultValues?.question ?? '',
      answer: defaultValues?.answer ?? '',
    },
  });

  const { isValid, isSubmitted } = formState;

  return (
    <form className={styles.formFAQ} onSubmit={handleSubmit(onSubmit)}>
      <VBField name="question" prettyLabel="Question" rules={{ required: true }} control={control} />
      <Controller
        name="answer"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <VBTextArea label="Answer" maxLength={500} value={value} onChange={onChange} showMaxLength />
        )}
      />
      <div className={styles.actions}>
        {Boolean(onBack) && <VBButton type="border" size="med" content="Back" onClick={onBack} />}
        <VBButton
          type={isValid ? 'full' : 'empty'}
          disabled={!isValid || isSubmitted}
          size="med"
          content="Save"
          submit
        />
        {Boolean(onCancel) && <VBButton type="border" size="med" content="Cancel" onClick={onCancel} />}
      </div>
    </form>
  );
};

FormFAQ.propTypes = propTypes;

export default FormFAQ;
