import IconButton from '../../../../../shared-react/src/components/IconButton/IconButton';
import ColoredIcon from '../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { VBReactContext } from '../../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import { updateItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ImageSave from '../../../assets/images/icons/save/save.svg';
import ImageSaveActive from '../../../assets/images/icons/save/save-active.svg';
import ImageSaveBold from '../../../assets/images/icons/save/save-bold.svg';
import { useIsLoggedIn } from '../../../hooks/auth';
import { vbRequest } from '../../../util/api';
import APIConfig from '../../../../../shared-react/src/config/api';
import { useOpenAuthPopup } from '../../../hooks/open-auth-popup';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import { VENUE_BUTTONS_SHADOW_VALUE } from '../../../../../shared-react/src/config/venue-buttons';
/**
 * Icon button for saving a venue
 * @param props.venueId the ID of the venue to save
 * @param props.saved whether the venue has been saved
 * @param props.hideText whether or not to hide the text at the bottom
 * @param props.color the color to give to the icon
 * @param props.colorFilled the color to give the filled icon
 * @param props.shadow whether or not icon has shadow
 * @param props.bold whether or not icon is bold
 */
const IconSaveVenue = (props) => {
  const { venueId, saved, hideText, color, colorFilled, shadow, bold } = props;
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const dispatch = useDispatch();
  const loggedIn = useIsLoggedIn();
  const openAuthPopup = useOpenAuthPopup();

  const { vbBlack, vbBlue, pointsToast } = useContext(VBReactContext);

  const handleClick = () => {
    if (!saved) sendAnalyticsEvent('vb_icon_button_save_venue_click', 'vb_icon_button');
    if (!loggedIn) {
      openAuthPopup();
    } else {
      dispatch(updateItem(venueId, ContentTypes.venue.type, { saved: !saved }));
      vbRequest(`${APIConfig.NAMESPACE.VENUE}/${venueId}/save`, {
        params: { is_save: !saved },
        method: 'POST',
      })
        .then((response) => {
          if (response.expert_points > 0) pointsToast(response.expert_points, `saving a venue`);
        })
        .catch((err) => {
          console.error(err.message ?? err);
        });
    }
  };

  const buttonColor = !saved ? color || vbBlack : colorFilled || vbBlue;
  return (
    <>
      <IconButton
        onClick={handleClick}
        text={hideText ? null : 'Save'}
        fontSize="1rem"
        textShadow={shadow ? VENUE_BUTTONS_SHADOW_VALUE : null}
        subtitleColor={color}
      >
        <ColoredIcon
          src={saved ? ImageSaveActive : bold ? ImageSaveBold : ImageSave}
          color={buttonColor}
          height="25"
          width="25"
          additionalFilters={shadow ? `drop-shadow(${VENUE_BUTTONS_SHADOW_VALUE})` : null}
        />
      </IconButton>
    </>
  );
};

IconSaveVenue.propTypes = {
  saved: PropTypes.bool,
  venueId: PropTypes.number.isRequired,
  hideText: PropTypes.bool,
  color: PropTypes.string,
  colorFilled: PropTypes.string,
};

IconSaveVenue.defaultProps = {
  saved: false,
  hideText: false,
  color: null,
  colorFilled: null,
};

export default IconSaveVenue;
