import apiReducer from '../../../shared-react/src/store/reducers/apiReducer';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authTokenReducer from './reducers/authTokenReducer';
import currentUserReducer from './reducers/currentUserReducer';
import pageItemIdReducer from '../../../shared-react/src/store/reducers/pageItemIdReducer';
import authPopupOpenReducer from './reducers/authPopupOpenReducer';
import headerReducer from '../../../shared-react/src/store/reducers/headerReducer';
import progressiveImageReducer from '../../../shared-react/src/store/reducers/progressiveImagesReducer';
import scrollPositionPreserveReducer from '../../../shared-react/src/store/reducers/scrollPositionPreserveReducer';
import userLocationReducer from '../../../shared-react/src/store/reducers/userLocationReducer';
import globalLocationReducer from '../../../shared-react/src/store/reducers/globalLocationReducer';
import notificationsReducer from '../../../shared-react/src/store/reducers/notificationReducer';
import LocalStore from 'store';
import { LOCAL_AUTH_TOKEN_KEY } from '../config/auth';
import { isDevelopment } from '../../../shared-react/src/util/env';
import preloadSeedReducer from './reducers/preloadSeed';

/**
 * Configures the Redux store.
 *
 * @returns {object} the store and persister
 */
export const configureStore = () => {
  // Combine all of the reducers.
  const reducers = combineReducers({
    authToken: authTokenReducer,
    currentUser: currentUserReducer,
    globalLocation: globalLocationReducer,
    pageItemId: pageItemIdReducer,
    api: apiReducer,
    toastr: toastrReducer,
    authPopupOpen: authPopupOpenReducer,
    header: headerReducer,
    progressiveImages: progressiveImageReducer,
    scrollPositionPreserve: scrollPositionPreserveReducer,
    userLocation: userLocationReducer,
    notifications: notificationsReducer,
    preloadSeed: preloadSeedReducer,
  });

  const ROOT_PERSIST_KEY = 'root';

  const rootPersistConfig = {
    key: ROOT_PERSIST_KEY,
    storage,
    whitelist: ['authToken', 'globalLocation'],
  };

  const serverToken = window._pmgCurrentUser?.token ?? null;
  const persistedDataStr = localStorage.getItem(`persist:${ROOT_PERSIST_KEY}`);

  if (persistedDataStr) {
    const persistedData = JSON.parse(persistedDataStr);
    const lastSiteVersion = localStorage.getItem('siteVersionStore');

    if (lastSiteVersion !== VB_VERSION) {
      // TODO: clear data here
      // delete persistedData['authToken'];
      // delete persistedData['globalLocation'];
      localStorage.setItem('siteVersionStore', VB_VERSION);
    }

    if (!isDevelopment()) {
      // Sync local token with server token.
      if (persistedData.authToken) {
        const persistedToken = JSON.parse(persistedData.authToken);

        if (serverToken && persistedToken !== serverToken) {
          persistedData.authToken = JSON.stringify(serverToken);
        }
      }

      localStorage.setItem(`persist:${ROOT_PERSIST_KEY}`, JSON.stringify(persistedData));
    }
  }

  if (!isDevelopment()) {
    // There is probably a better place to do this, but this works. We just synched some token state, lets synch some
    // more
    const localToken = LocalStore.get(LOCAL_AUTH_TOKEN_KEY) ?? null;

    if (localToken !== serverToken) {
      if (localToken && !serverToken) {
        LocalStore.remove(LOCAL_AUTH_TOKEN_KEY);
      } else {
        LocalStore.set(LOCAL_AUTH_TOKEN_KEY, serverToken);
      }
    }
  }

  // Configure the fields in the store that should persist (be stored in local store).
  const persistedReducer = persistReducer(rootPersistConfig, reducers);

  // TODO: Don't integrate with dev tools in production.
  // Return the store.
  const composeEnhancers =
    (isDevelopment() ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 }) : null) ||
    compose;
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore();
