import React, { useState, createContext, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import VBSpinner from '../VBSpinner/VBSpinner';

const VBLightboxContext = createContext();

const propTypes = {
  // All images, this is needed for switching between card images.
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const defaultProps = {};

const LightboxWrapper = lazy(() => import('./LightboxWrapper/LightboxWrapper'));

/**
 * When you wrap this around an array of cards and pass the card images into its props, this
 * will make all the cards fullscreenable. All cards nested inside will be clickable to fullscreen
 * via context values being sent down.
 */
function VBCardLightbox({ images, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const setImage = (img) => {
    setPhotoIndex(images.findIndex((s) => s === img));
    setIsOpen(true);
  };

  return (
    <VBLightboxContext.Provider
      value={{
        setImage,
      }}
    >
      {children}
      {isOpen && (
        <Suspense fallback={<VBSpinner center />}>
          <LightboxWrapper
            reactModalStyle={{ overlay: { zIndex: 10000 } }}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            animationDisabled
          />
        </Suspense>
      )}
    </VBLightboxContext.Provider>
  );
}

VBCardLightbox.propTypes = propTypes;
VBCardLightbox.defaultProps = defaultProps;

export { VBLightboxContext };
export default VBCardLightbox;
