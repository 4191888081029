import { useMemo } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import VBLink from '../../VBLink/VBLink';
import { mergeClassNames } from '../../../util/props';
import MarkdownText from '../../MarkdownText/MarkdownText';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './CardTitle.module.scss';

const propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.object),
  isOutlink: PropTypes.bool,
  isLoading: PropTypes.bool,
  doNotHighlightLink: PropTypes.bool,
  className: PropTypes.string,
};

const CardTitle = ({ title, link, matches, isOutlink, isLoading, doNotHighlightLink, className }) => {
  const history = useHistory();

  const content = useMemo(() => {
    if (isLoading) {
      return <Skeleton width="65%" />;
    }

    if (link && !doNotHighlightLink) {
      return (
        <VBLink to={link} newTab={isOutlink} aria-label={title}>
          {title}
        </VBLink>
      );
    }

    if (matches?.length) {
      return <MarkdownText text={title} matches={matches} />;
    }

    const clickHandler = link ? () => history.push(link) : undefined;

    return (
      <span
        role={link ? 'link' : undefined}
        tabIndex={link ? 0 : undefined}
        className={link ? styles.link : undefined}
        onClick={clickHandler}
      >
        {title}
      </span>
    );
  }, [doNotHighlightLink, history, isLoading, isOutlink, link, matches, title]);

  return <div className={mergeClassNames(styles.title, className)}>{content}</div>;
};

CardTitle.propTypes = propTypes;

export default CardTitle;
