import React from 'react';
import styles from '../headerBuilder.module.scss';
import ColoredIcon from '../../../components/ColoredIcon/ColoredIcon';
import Branding from '../../../config/branding';
import AbstractHeaderBuilder from '../abstract-header-builder';

export default class ZuzuHeaderBuilder extends AbstractHeaderBuilder {
  getTextLogo() {
    return (
      <ColoredIcon
        src={Branding.LOGO_TEXT}
        color={this.getThemeColor('blue')}
        className={styles.logoText}
        style={{ marginTop: '3px', height: '37px' }}
        height="37"
        width="116.5"
        alt={`${Branding.SITE_NAME} logo`}
      />
    );
  }

  getLogoColor() {
    return undefined;
  }

  calculateBackgroundColor(scrollY, windowHeight) {
    if (this.onHomePage()) {
      return 'rgba(255, 255, 255, 0.7)';
    }
    return 'rgba(255, 255, 255, 0.9)';
  }

  hasWhiteLinks() {
    return false;
  }

  hasBottomBorder() {
    return true;
  }

  getSearchBarComponent(sendAnalyticsEvent) {
    const SearchBar = this.SearchBarComponentType;

    const includeVenuesInSearchResult = this.hasVenuesInSearchResult();
    const onSelectClick = this.getSearchBarSelectItemHandler();
    const onRandomClick = this.getSearchBarRandomClickHandler(sendAnalyticsEvent);
    const onNearMeClick = this.getSearchBarNearMeClickHandler(sendAnalyticsEvent);
    const onExploreClick = this.getSearchBarExploreClickHandler();
    const resultBehavior = this.getSearchBarResultBehavior();

    return (
      <SearchBar
        include="rne"
        venues={includeVenuesInSearchResult}
        cities
        border
        separateButtons
        randomPerformsAction
        fullWidth={false}
        exploreText="Go"
        resultBehavior={resultBehavior}
        onSelect={onSelectClick}
        onRandomClick={onRandomClick}
        onNearMeClick={onNearMeClick}
        onExploreClick={onExploreClick}
      />
    );
  }
}
