import IconButton from '../../../../../../shared-react/src/components/IconButton/IconButton';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Icon button for clearing the search bar
 * @param props.onClick   The function to run on click
 */
const ButtonClear = ({ onClick }) => {
  return (
    <>
      <IconButton onClick={onClick} width="25px" fontSize="20px">
        <>&#10005;</>
      </IconButton>
    </>
  );
};

ButtonClear.propTypes = {
  onClick: PropTypes.func,
};

ButtonClear.defaultProps = {
  onClick: undefined,
};

export default ButtonClear;
