import { PropTypesVBUser } from '../../../shared-react/src/util/types';
import { PropTypesVBVenue } from '../../../shared-react/src/util/types';
import PropTypes from 'prop-types';
/**
 * Prop type for a value parsed with boolProp.
 */
export const PropTypesVBBool = PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]);

/**
 * Nav info for a location
 */
export const PropTypesVBNavInfo = PropTypes.shape({
  id: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  searchLink: PropTypes.string.isRequired,
  trendingLink: PropTypes.string.isRequired,
  citySlug: PropTypes.string.isRequired,
  regionSlug: PropTypes.string.isRequired,
  countrySlug: PropTypes.string.isRequired,
  regionShort: PropTypes.string.isRequired,
  countryShort: PropTypes.string.isRequired,
}).isRequired;

/**
 * Prop type for collection
 */
export const PropTypesVBCollection = PropTypes.shape({
  display_order: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
});

/**
 * Category
 */
export const PropTypesVBCategory = PropTypes.oneOf([null, 'activities', 'restaurants', 'hotels', 'bars', 'tours']);

/**
 * Prop types for bucket list status.
 */
export const PropTypesVBBucketStatus = PropTypes.shape({
  collections: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  list: PropTypes.number.isRequired,
});

/**
 * User listing info
 */
export const PropTypesVBUserListing = PropTypes.shape({
  avatar: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  permalink: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
});

/**
 * User post.
 */
export const PropTypesVBPost = PropTypes.shape({
  content: PropTypes.string,
  time: PropTypes.number,
  image: PropTypes.string,
  likes: PropTypes.number,
  userLiked: PropTypes.bool,
  comments: PropTypes.number,
  // In the future, we should have separate proptypes for each context. Neither of our VB_Venue Proptypes here really
  // represent the venue field in here well
  venue: PropTypes.object,
  user: PropTypesVBUserListing,
});

/**
 * Represents an activity in an itinerary.
 */
export const PropTypesVBItineraryActivity = PropTypes.shape({
  venue: PropTypesVBVenue,
  venueId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

/**
 * Represents a day in an itinerary.
 */
export const PropTypesVBItineraryDay = PropTypes.shape({
  activities: PropTypes.arrayOf(PropTypesVBItineraryActivity.isRequired),
  dayIndex: PropTypes.number,
  itineraryId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
});

/**
 * Represents an itinerary.
 */
export const PropTypesVBItinerary = PropTypes.shape({
  id: PropTypes.number,
  time: PropTypes.number,
  days: PropTypes.arrayOf(PropTypesVBItineraryDay.isRequired),
  name: PropTypes.string,
});

/**
 * A trip.
 */
export const PropTypesVBTrip = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  itineraryId: PropTypes.number,
  itinerary: PropTypesVBItinerary,
  mediaCollectionId: PropTypes.number,
  thumbnail: PropTypes.string,
  userId: PropTypes.number,
  user: PropTypesVBUser,
  date: PropTypes.number,
  permalink: PropTypes.string,
});

export const PropTypesVBProposedActivity = PropTypes.shape({
  id: PropTypes.number.isRequired,
  venue: PropTypesVBVenue,
  user: PropTypesVBUser,
  time: PropTypes.number,
  ratings: PropTypes.shape({
    '-2': PropTypes.number,
    '-1': PropTypes.number,
    '0': PropTypes.number,
    '1': PropTypes.number,
    '2': PropTypes.number,
  }),
  userRating: PropTypes.number,
});
