import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import { boolProp, mergeClassNames } from '../../util/props';
import VBSpinner from '../VBSpinner/VBSpinner';
import OnClickLink from '../OnClickLink/OnClickLink';
import { useCloseDropdown } from '../../hooks/dropdown';

import styles from './DropdownList.module.scss';

const propTypes = {
  elementWidth: PropTypes.oneOf([PropTypes.exact('auto'), PropTypes.number]),
  inputHeight: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  ItemRenderer: PropTypes.func,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  displayBelow: PropTypes.bool,
  shadow: PropTypes.bool,
  border: PropTypes.bool,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
};

const DropdownList = ({
  elementWidth,
  inputHeight,
  options,
  onSelect,
  ItemRenderer,
  isLoading,
  fullWidth,
  displayBelow,
  shadow,
  border,
  containerClassName,
  itemClassName,
}) => {
  const [currentWidth, setCurrentWidth] = useState(0);
  const [containerStyle, setContainerStyle] = useState({});
  const close = useCloseDropdown();

  const containerRef = useRef();

  const { width: actualWidth } = useResizeObserver({ ref: containerRef });

  const listContent = useMemo(() => {
    return options.map((item, idx) => {
      return ItemRenderer ? (
        <ItemRenderer key={item?.id ?? item?.value ?? idx} {...item} />
      ) : (
        <OnClickLink
          key={item.value}
          className={mergeClassNames(styles.item, itemClassName)}
          onClick={() => {
            onSelect?.(item);
            close();
          }}
        >
          <div className={styles.label}>{item.label}</div>
        </OnClickLink>
      );
    });
  }, [ItemRenderer, close, itemClassName, onSelect, options]);

  useEffect(() => {
    let newWidth = null;

    if (fullWidth) {
      if (actualWidth !== undefined) {
        const { left } = containerRef.current.getBoundingClientRect();
        newWidth = window.innerWidth - left * 2;
      }
    } else {
      if (elementWidth !== undefined && elementWidth !== currentWidth) {
        newWidth = elementWidth;
        setCurrentWidth(newWidth);
      }
    }

    if (newWidth) {
      setContainerStyle({
        minWidth: `${newWidth}px`,
        maxWidth: `${newWidth}px`,
      });
    }
  }, [actualWidth, currentWidth, elementWidth, fullWidth]);

  return (
    <div
      className={mergeClassNames(styles.container, shadow && styles.shadow, containerClassName)}
      style={{
        ...containerStyle,
        paddingTop: displayBelow ? '0px' : inputHeight,
        marginTop: displayBelow ? '0px' : `CALC(2px - ${inputHeight})`,
        ...(boolProp(border) ? { border: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '0' } : {}),
      }}
      ref={containerRef}
    >
      <div className={styles.box}>
        {Boolean(isLoading) && (
          <div className={styles.loading}>
            <VBSpinner />
          </div>
        )}
        {!isLoading && !options.length && <div className={styles.listEmpty}>No results found...</div>}
        {!isLoading && listContent}
      </div>
    </div>
  );
};

DropdownList.propTypes = propTypes;

export default DropdownList;
