import React, { useMemo, useState } from 'react';
import styles from './City.module.scss';
import MaxWidthContainer from '../../MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../../VBHeading/VBHeading';
import VBCarousel from '../../CarouselWrapper/CarouselWrapper';
import Meta from '../../Meta/Meta';
import VenueMeta from '../../VenueMeta/VenueMeta';
import useSaveIconButtons from '../../../hooks/save-icon-buttons';
import IconShare from '../../IconShare/IconShare';
import { getBadge } from '../../../config/badges';
import GemDropdown from '../../GemDropdown/GemDropdown';
import VBLink from '../../VBLink/VBLink';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../../util/props';
import PropTypes from 'prop-types';
import ProgressiveImage from '../../ProgressiveImage/ProgressiveImage';

const propTypes = {
  /** City containing a list of venues */
  city: PropTypes.object.isRequired,
};

const City = ({ city }) => {
  // The index of the image showing in the carousel
  const [selectedIdx, setSelectedIdx] = useState(0);

  // Honestly this is just to make it easier to reference the selected venue venue
  const selectedVenue = useMemo(() => (city.venues ? city.venues[selectedIdx] : null), [city.venues, selectedIdx]);

  // Badge for the selected venue, if any
  const badge = useMemo(() => getBadge(selectedVenue.overallRating), [selectedVenue.overallRating]);

  const { lteXs, lteMd } = useVBBreakpoint();

  const saveButtons = useSaveIconButtons(selectedVenue);

  // In practice this will never happen.
  if (!city.venues?.length) return null;

  return (
    <MaxWidthContainer padded={!lteXs} className={mergeClassNames(styles.city, lteMd ? null : styles.desktop)}>
      {/* Heading for city which links to city explore page */}
      <VBLink
        to={`location/${city.country_slug}/${city.region_slug}/${city.city_slug}`}
        aria-label={`${city.city}, ${city.region}`}
      >
        <VBHeading size={lteXs ? 'xs' : 'sm'} center>
          {`${city.city}, ${city.region}`}
        </VBHeading>
      </VBLink>
      {/* Carousel of featured images for each venue */}
      <VBCarousel
        children={city.venues.map((venue) => (
          <VBLink to={venue.permalink} key={venue.id} aria-label={venue.name}>
            <ProgressiveImage
              containerClassName={mergeClassNames(styles.carouselImageContainer, lteXs ? styles.mobile : null)}
              imageClassName={mergeClassNames(styles.carouselImage, lteXs ? styles.mobile : styles.desktop)}
              fullSrc={venue.featuredImage}
              smallSrc={venue.featuredImageSmall}
              alt={venue.name}
              lazy
            />
          </VBLink>
        ))}
        showArrows
        showStatus
        selectedItem={selectedIdx}
        onChange={(idx) => setSelectedIdx(idx)}
      />
      {/* Meta for the currently selected venue */}
      <Meta
        title={selectedVenue.name}
        permalink={selectedVenue.permalink}
        coords={[selectedVenue.latitude, selectedVenue.longitude]}
        actionButtons={[
          ...saveButtons,
          <IconShare
            type="View"
            permalink={window.location.host + selectedVenue.permalink}
            name={selectedVenue.name}
            venueId={selectedVenue.id}
          />,
        ]}
        badge={badge ? <GemDropdown gemBadge={badge} /> : null}
        covidLowRisk={selectedVenue.isVirusLowRisk}
        isMetaAlignedTop
        padded={lteXs}
        meta={
          <VenueMeta
            venue={selectedVenue}
            coords={[selectedVenue.latitude, selectedVenue.longitude]}
            desc={selectedVenue.description?.content}
            category={selectedVenue.subcategory}
          />
        }
      />
    </MaxWidthContainer>
  );
};

City.propTypes = propTypes;
export default City;
