export const TriviaStatus = {
  NOT_FINISHED: 'notFinished',
  TRY_AGAIN: 'tryAgain',
  GOOD_JOB: 'goodJob',
  GREAT_JOB: 'greatJob',
  MASTERED: 'mastered',
};

export const TriviaResultMessage = {
  [TriviaStatus.TRY_AGAIN]: 'Try Again!',
  [TriviaStatus.GOOD_JOB]: 'Good Job!',
  [TriviaStatus.GREAT_JOB]: 'Great Job!',
  [TriviaStatus.MASTERED]: 'Congratulations, you’re a Zuzu Travel Pro!',
};
