import { matchPath } from 'react-router';

/**
 * Class for managing the pages of the site. The idea that this module would handle
 * the abstraction of pages and keep track of things like URLs and what components generate
 * these pages.
 */
export default class Pages {
  static HOME = 'home';
  static SEARCH = 'search';
  static TRENDING = 'trending';
  static TRIP_DETAILS = 'tripDetails';
  static PROFILE = 'profile';
  static PROFILE_REVIEWS = 'profileReviews';
  static HALL_OF_FAME = 'hallOfFame';
  static EXPLORE_SUMMARY = 'summary';
  static PLAYGROUNDS = 'playgrounds';
  static ACTIVITIES = 'activities';
  static EVENTS = 'events';
  static ARTICLES = 'articles';
  static ARTICLE_POST = 'articlePost';
  static FESTIVALS = 'festivals';
  static CHAT = 'chat';
  static VIDEOS = 'videos';
  static TRIVIA = 'trivia';
  static FAQ = 'faq';
  static NEWSLETTERS = 'newsletters';
  static ITINERARIES = 'itineraries';
  static BLOG = 'blog';
  static GAME_HANGMAN = 'hangman';
  static DAY_TRIPS = 'dayTrips';
  static VENUE_DETAILS = 'venueDetails';
  static VENUE_REVIEWS = 'venueReviews';
  static VENUE_VIDEOS = 'venueVideos';
  static ACTIVITY = 'activity';

  static EXPLORE_PAGES = [
    this.TRENDING,
    this.EXPLORE_SUMMARY,
    this.SEARCH,
    this.PLAYGROUNDS,
    this.ITINERARIES,
    this.ARTICLES,
    this.ARTICLE_POST,
    this.ACTIVITIES,
    this.EVENTS,
    this.FESTIVALS,
    this.CHAT,
    this.VIDEOS,
    this.TRIVIA,
    this.FAQ,
    this.DAY_TRIPS,
    this.NEWSLETTERS,
  ];

  static VENUE_DETAILS_PAGES = [this.VENUE_DETAILS, this.VENUE_REVIEWS, this.VENUE_VIDEOS];

  static PAGES_WITH_SEARCH_BAR = [...this.EXPLORE_PAGES, ...this.VENUE_DETAILS_PAGES, this.ACTIVITY, this.GAME_HANGMAN];

  /**
   * TODO: Add more pages. Could make a system to have sub-pages and such.
   */
  static pages = {
    [this.HOME]: {
      path: '/',
      // component: PageHome,
    },
    [this.SEARCH]: {
      path: '/location/:country/:state/:city',
    },
    [this.TRENDING]: {
      path: '/trending/location/:country/:state/:city',
      // component: PageTrending,
      // wrapper: <RouteWithGlobalLocation ...>{children}
    },
    [this.TRIP_DETAILS]: {
      path: '/trips/:tripId',
      // component: PageTripDetails,
    },
    [this.PROFILE]: {
      path: '/user/:username',
    },
    [this.PROFILE_REVIEWS]: {
      path: '/user/:username/reviews',
    },
    [this.HALL_OF_FAME]: {
      path: '/hall-of-fame/:subPage?',
    },
    [this.EXPLORE_SUMMARY]: {
      path: '/location/:country/:state/:city/summary',
    },
    [this.PLAYGROUNDS]: {
      path: '/location/:country/:state/:city/playgrounds',
    },
    [this.EVENTS]: {
      path: '/location/:country/:state/:city/events',
    },
    [this.ARTICLES]: {
      path: '/location/:country/:state/:city/articles',
    },
    [this.ARTICLE_POST]: {
      path: '/location/:country/:state/:city/articles/post/:slug'
    },
    [this.FESTIVALS]: {
      path: '/location/:country/:state/:city/festivals',
    },
    [this.CHAT]: {
      path: '/location/:country/:state/:city/chat',
    },
    [this.ITINERARIES]: {
      path: '/location/:country/:state/:city/itineraries',
    },
    [this.ACTIVITIES]: {
      path: '/location/:country/:state/:city/activities',
    },
    [this.VIDEOS]: {
      path: '/location/:country/:state/:city/videos',
    },
    [this.TRIVIA]: {
      path: '/location/:country/:state/:city/trivia',
    },
    [this.FAQ]: {
      path: '/location/:country/:state/:city/faq',
    },
    [this.NEWSLETTERS]: {
      path: "/location/:country/:state/:city/newsletters",
    },
    [this.DAY_TRIPS]: {
      path: '/location/:country/:state/:city/daytrips',
    },
    [this.BLOG]: {
      path: '/blog',
    },
    [this.VENUE_DETAILS]: {
      path: '/places/:country/:state/:city/:name',
    },
    [this.VENUE_REVIEWS]: {
      path: '/places/:country/:state/:city/:name/reviews',
    },
    [this.VENUE_VIDEOS]: {
      path: '/places/:country/:state/:city/:name/videos',
    },
    [this.GAME_HANGMAN]: {
      path: '/games/hangman/:locationId',
    },
    [this.ACTIVITY]: {
      path: '/activity/:country/:state/:city',
    },
  };

  // TODO: Eventually auto generate routes in TripFool Master
  /** Returns react component with router props */
  generatePages() {
    throw new Error('not implemented');
  }

  /**
   * Returns true if on the page
   * @param {string} pageName - The name of the page that corresponds to the static constants.
   * @param {string} pathname - pathname to test against (Defaults to window.location.pathname)
   */
  static isOnPage(pageName, pathname) {
    const path = pathname ?? location.pathname;

    const match = matchPath(path, {
      path: this.pages[pageName].path,
      isExact: false,
    });
    return !!match?.isExact;
  }

  /**
   * Returns true if on any of the pages in the array
   * @param {string[]} pageNames
   * @param {string} pathname
   */
  static isOnAnyOfPages(pageNames, pathname) {
    if (!Array.isArray(pageNames)) {
      throw new Error('pageNames must be an array');
    }
    return pageNames.some((pageName) => this.isOnPage(pageName, pathname));
  }
}
