import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmojiSelector.module.scss';
import OnClickLink from '../OnClickLink/OnClickLink';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import { mergeClassNames } from '../../util/props';
import useVbContext from '../../hooks/vb-context';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const propTypes = {
  /* if you want to colorize the emoji image. */
  color: PropTypes.string,

  /* size of the emojis in pixels. */
  size: PropTypes.number,

  /* the index of the emoji to have selected. Null or undefined if none selected. */
  selected: PropTypes.number,

  /* called when the user selects an emoji. Passes in emoji index in array. */
  onSelect: PropTypes.func.isRequired,

  /** additional class names */
  className: PropTypes.string,
};
const defaultProps = {
  selected: undefined,
  color: undefined,
  size: 35,
  className: undefined,
};

/**
 * Rating Emoji Selector. Let's the user select a rating.
 */
const EmojiSelector = ({ selected, onSelect, color, size, className }) => {
  const { brandingConfig } = useVbContext();

  return (
    <div className={mergeClassNames(styles.container, className)}>
      {brandingConfig.RATING_EMOJIS.map((emoji, index) => {
        const imgProps = {
          src: emoji,
          className: styles.emoji,
          styles: { width: size },
          alt: `Rate ${index + 1}`,
        };
        return (
          <OverlayTrigger
            key={index}
            overlay={<Tooltip>I had a {brandingConfig.RATING_EMOJI_LABELS[index]} experience here.</Tooltip>}
          >
            <span className={styles.vStack}>
              <OnClickLink
                role="button"
                aria-label={`rating-${brandingConfig.RATING_EMOJI_LABELS[index]}`}
                onClick={() => onSelect(index)}
                className={mergeClassNames(styles.emojiContainer, selected === index && styles.activeEmoji)}
              >
                {color ? (
                  <ColoredIcon color={color} {...imgProps} />
                ) : (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img {...imgProps} />
                )}

                {selected === index && <hr style={{ top: size + 5, width: size }} className={styles.underline} />}
              </OnClickLink>
              <div style={{ opacity: 0.5 }}>{brandingConfig.RATING_EMOJI_LABELS[index]}</div>
            </span>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

EmojiSelector.propTypes = propTypes;
EmojiSelector.defaultProps = defaultProps;

export default EmojiSelector;
