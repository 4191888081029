import IconHamburger from './IconHamburger/IconHamburger';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderView.module.scss';
import { mergeClassNames } from '../../../util/props';
import MaxWidthContainer from '../../MaxWidthContainer/MaxWidthContainer';
import { isMobile } from '../../../util/platforms';
import { useDispatch, useSelector } from 'react-redux';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';
import useVisibleOnUpscroll from '../../../hooks/visible-on-upscroll';
import useWindowScroll from '../../../hooks/window-scroll';
import useResizeObserver from 'use-resize-observer/polyfilled';
import Avatar from '../../Avatar/Avatar';
import { useDropdown } from '../../../hooks/dropdown';
import VBDropdownItem from '../../VBDropdownItem/VBDropdownItem';
import {
  setHeaderHeight,
  setHeaderVisibility,
  setHamburgerVisibility,
  toggleHamburgerVisibility,
} from '../../../store/actions/headerActions';
import { HeaderVisibilityMode } from '../../../store/reducers/headerReducer';
import VBLink from '../../VBLink/VBLink';
import useVbContext from '../../../hooks/vb-context';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { isViewbuff } from '../../../util/sites';
import VBSpinner from '../../VBSpinner/VBSpinner';

const PropTypesVBTabsAndLinks = PropTypes.shape({
  tabs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, node: PropTypes.node })),
  bubbleDropdownLinks: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, node: PropTypes.node })),
  hamburgerLinks: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, node: PropTypes.node })),
  hamburgerDividers: PropTypes.arrayOf(PropTypes.string),
});

const PropTypesVBLogos = PropTypes.shape({
  iconNode: PropTypes.node,
  siteNameNode: PropTypes.node,
});

const propTypes = {
  tabsAndLinks: PropTypesVBTabsAndLinks.isRequired,
  bubbleImgSrc: PropTypes.string,
  logos: PropTypesVBLogos,
  searchBar: PropTypes.node,
  fixedPositioning: PropTypes.bool,
  hamburgerColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  blurBackground: PropTypes.bool,
  whiteLinks: PropTypes.bool,
  bottomBorder: PropTypes.bool,

  /** whether or not the user is loading */
  isUserLoading: PropTypes.bool,
};

const defaultProps = {
  fixedPositioning: false,
};

function HeaderView({
  whiteLinks,
  tabsAndLinks,
  bubbleImgSrc,
  logos,
  searchBar,
  fixedPositioning,
  hamburgerColor,
  backgroundColor,
  blurBackground,
  bottomBorder,
  isUserLoading,
}) {
  const { vbGrayLight, vbWhite } = useVbContext();
  const { gteLg, ltMd, gteMd, lt1280: tabletStyle } = useVBBreakpoint();

  const dispatch = useDispatch();

  const ref = useKeepGlobalHeaderHeightUpdated(dispatch);

  const bubbleDropdown = useBubbleDropdown(bubbleImgSrc, tabsAndLinks.bubbleDropdownLinks);
  const hasBubbleDropdown = !!bubbleImgSrc;

  const tabsAndBubble = [
    tabsAndLinks.tabs.map((tab) => tab.node),
    hasBubbleDropdown && <React.Fragment key="bubbleDropdown">{bubbleDropdown}</React.Fragment>,
  ];

  const { isVisible, height } = useSelector((state) => state.header);

  useHideHeader(dispatch, Boolean(tabletStyle));

  useCloseHamburgerOnSizeChange(gteLg, dispatch);
  useCloseHamburgerOnScrollDown(dispatch);

  const handleClickHamburger = () => {
    dispatch(toggleHamburgerVisibility());
  };

  const hamburgerContentClicked = () => {
    dispatch(setHamburgerVisibility(false));
  };

  const hamburgerIcon = <IconHamburger onClick={handleClickHamburger} color={whiteLinks ? vbWhite : hamburgerColor} />;

  const hamburgerMenu = !gteLg && (
    <HamburgerMenu
      links={tabsAndLinks.hamburgerLinks}
      linksWithDividerUnder={tabsAndLinks.hamburgerDividers}
      whiteLinks={whiteLinks}
      onContentClick={hamburgerContentClicked}
    />
  );

  const logosLink = (gteMd || !searchBar) && (
    <VBLink className={styles.left} to="/" style={ltMd ? { padding: 0 } : {}}>
      {logos.iconNode}
      {logos.siteNameNode}
    </VBLink>
  );

  const rightSide = gteLg ? tabsAndBubble : hamburgerIcon;

  const isVb = isViewbuff();

  useEffect(() => {
    if (isVb) {
      Object.assign(document.getElementById('vb-header').style, {
        position: fixedPositioning ? 'fixed' : 'sticky',
        zIndex: 1000,
      });
    }
  }, [fixedPositioning, isVb]);

  return (
    <div
      role="heading"
      aria-level={1}
      className={mergeClassNames(
        styles.header,
        !isVb ? styles.noParentPosition : null,
        fixedPositioning ? styles.fixed : null,
        isMobile() ? styles.mobile : null,
        blurBackground && styles.blurBackground
      )}
      id="header"
      style={{
        borderBottom: bottomBorder && `1px solid ${vbGrayLight}`,
        background: backgroundColor,
        transitionDuration: '0.5s',
        transform: `translateY(${isVisible ? 0 : -height}px)`,
      }}
      ref={ref}
    >
      <MaxWidthContainer className={styles.inner}>
        {logosLink}
        {searchBar}
        <div className={styles.right}>
          {!isUserLoading ? (
            <div className={mergeClassNames(styles.tabs, whiteLinks && styles.white, 'd-flex align-items-center')}>
              {rightSide}
            </div>
          ) : (
            <div className={styles.spinnerContainer}>
              <VBSpinner />
            </div>
          )}
        </div>
      </MaxWidthContainer>
      {hamburgerMenu}
    </div>
  );
}

HeaderView.propTypes = propTypes;
HeaderView.defaultProps = defaultProps;

export default HeaderView;

function useCloseHamburgerOnSizeChange(gteLg, dispatch) {
  useEffect(() => {
    if (gteLg) {
      dispatch(setHamburgerVisibility(false));
    }
  }, [gteLg, dispatch]);
}

function useCloseHamburgerOnScrollDown(dispatch) {
  const { isHamburgerOpen, isHamburgerTransitioning } = useSelector((state) => state.header);

  const lastScrollPosition = useRef(window.scroll);

  const isListening = isHamburgerOpen && !isHamburgerTransitioning;

  useWindowScroll(
    () => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > lastScrollPosition.current) {
        dispatch(setHamburgerVisibility(false));
      }

      lastScrollPosition.current = currentScrollPosition;
    },
    [dispatch],
    false,
    isListening
  );

  useEffect(() => {
    if (isListening) {
      lastScrollPosition.current = window.scroll;
    }
  }, [isListening]);
}

function useKeepGlobalHeaderHeightUpdated(dispatch) {
  const ref = useRef();
  const { height } = useResizeObserver({ ref });

  useEffect(() => {
    if (typeof height !== 'undefined') {
      dispatch(setHeaderHeight(ref.current.getBoundingClientRect().height));
    }
  }, [height, dispatch]);

  return ref;
}

function useBubbleDropdown(bubbleImgSrc, bubbleDropdownLinks) {
  const bubbleDropdownTrigger = <Avatar aria-label="Profile Bubble" src={bubbleImgSrc} />;
  const bubbleDropdownContent = (
    <>
      {bubbleDropdownLinks.map(({ name, node }) => (
        <VBDropdownItem key={name} className={styles.bubbleDropdownItem}>
          {node}
        </VBDropdownItem>
      ))}
    </>
  );
  const { dropdown: bubbleDropdown } = useDropdown({
    dropdown: bubbleDropdownContent,
    dropdownClassName: styles.profileDropdown,
    trigger: bubbleDropdownTrigger,
    triggerClassName: null,
  });
  return bubbleDropdown;
}

function useHideHeader(dispatch, isTabletStyle) {
  const { mode, isHamburgerTransitioning } = useSelector((state) => state.header);

  const isVisibleInAutoMode = useVisibleOnUpscroll();

  useEffect(() => {
    const isAutoMode = mode === HeaderVisibilityMode.AUTO;

    if (isAutoMode && isTabletStyle) {
      dispatch(setHeaderVisibility(isVisibleInAutoMode));
    }
  }, [dispatch, isTabletStyle, isHamburgerTransitioning, isVisibleInAutoMode, mode]);
}
