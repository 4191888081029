import Color from 'color';
import React from 'react';
import GemIcon from '../../assets/images/header/vb-logo-gem.svg';
import ZuzuLogoText from '../../assets/images/vb-branding/zuzu/zuzu-text-logo.svg';
import DinoLogo from '../../assets/images/vb-branding/zuzu/logo.webp';
import DinoSad from '../../assets/images/vb-branding/zuzu/sad-dino.webp';
import DinoHappy from '../../assets/images/vb-branding/zuzu/happy-dino.webp';
import DinoSuperHappy from '../../assets/images/vb-branding/zuzu/super-happy-dino.webp';
// import CityImage from '../../assets/images/homepage/background.png';
import KidsImage from '../../assets/images/homepage/kids-jumping.webp';
// import TripBusImage from '../../assets/images/homepage/tripfool-bus.jpg';
// import TripTrailImage from '../../assets/images/homepage/tripfool-trail.jpg';
// import TripBridgeImage from '../../assets/images/homepage/tripfool-bridge.jpg';
// import TripBeachImage from '../../assets/images/homepage/tripfool-beach.jpg';
import FaceSad from '../../assets/images/icons/faces/sad-face.svg';
import FaceHappy from '../../assets/images/icons/faces/happy-face.svg';
import FaceSuperHappy from '../../assets/images/icons/faces/super-happy-face.svg';
import FaceSadBorder from '../../assets/images/icons/faces/sad-face-border.svg';
import FaceHappyBorder from '../../assets/images/icons/faces/happy-face-border.svg';
import FaceSuperHappyBorder from '../../assets/images/icons/faces/super-happy-face-border.svg';
import VBMapMarker from '../../assets/images/icons/map/map-marker/vb.svg';
import VBMapMarkerSelected from '../../assets/images/icons/map/map-marker-selected/vb.svg';
import TFMapMarker from '../../assets/images/icons/map/map-marker/tf.svg';
import TFMapMarkerSelected from '../../assets/images/icons/map/map-marker-selected/tf.svg';
import ZZMapMarker from '../../assets/images/icons/map/map-marker/zz.svg';
import ZZMapMarkerSelected from '../../assets/images/icons/map/map-marker-selected/zz.svg';
import ImageGemGoldFilled from '../../assets/images/icons/badges/gem-gold-filled.svg';
import ImageGemGold from '../../assets/images/icons/badges/gem-gold.svg';
import ImageGemSilver from '../../assets/images/icons/badges/gem-silver.svg';
import ImageGemBronze from '../../assets/images/icons/badges/gem-bronze.svg';
import ImageMedalGold from '../../assets/images/icons/badges/medal-gold.svg';
import ImageMedalSilver from '../../assets/images/icons/badges/medal-silver.svg';
import ImageMedalBronze from '../../assets/images/icons/badges/medal-bronze.svg';
import ImageMedalIcon from '../../assets/images/vb-branding/tripfool/badge-solid.svg';
import ImageMedalIconBlue from '../../assets/images/vb-branding/tripfool/badge-blue.svg';
import { getSiteName } from '../util/sites';
import SITES from '../../config-json/sites.json';
// import VBBenefitsFull from '../../assets/images/auth/vb-signup-full.png';
// import VBBenefitsSmall from '../../assets/images/auth/vb-signup-short.png';
// import TFBenefitsFull from '../../assets/images/auth/tf-signup-full.png';
// import TFBenefitsSmall from '../../assets/images/auth/tf-signup-short.png';
import ZZBenefitsFull from '../../assets/images/auth/zz-signup-full.webp';
import ZZBenefitsSmall from '../../assets/images/auth/zz-signup-short.webp';
import ImageVBEditPencil from '../../assets/images/icons/forms/edit-pencil/vb.svg';
import ImageTFEditPencil from '../../assets/images/icons/forms/edit-pencil/tf.svg';
import ImageZZEditPencil from '../../assets/images/icons/forms/edit-pencil/zz.svg';
import ImageVBCrop from '../../assets/images/icons/forms/crop/vb.svg';
import ImageTFCrop from '../../assets/images/icons/forms/crop/tf.svg';
import ImageZZCrop from '../../assets/images/icons/forms/crop/zz.svg';
import ImageVBArrowFullRight from '../../assets/images/icons/glyphs/arrows/right-filled/vb.svg';
import ImageTFArrowFullRight from '../../assets/images/icons/glyphs/arrows/right-filled/tf.svg';
import ImageZZArrowFullRight from '../../assets/images/icons/glyphs/arrows/right-filled/zz.svg';
import VBTextLogo from '../../assets/images/site-logos/vb-text-logo.svg';
import TripfoolLogoText from '../../assets/images/site-logos/tf-text-logo.svg';
import BasicBranding from '../../config-json/branding.json';
// import ViewBuffAboutOne from '../../assets/images/about/viewbuff-about-1.jpg';
// import ViewBuffAboutTwo from '../../assets/images/about/viewbuff-about-2.jpg';
// import TripfoolAboutOne from '../../assets/images/about/tripfool-about-1.jpg';
// import TripfoolAboutTwo from '../../assets/images/about/tripfool-about-2.jpeg';
import ZuzuAboutOne from '../../assets/images/about/zuzu-about-1.webp';
import ZuzuAboutTwo from '../../assets/images/about/zuzu-about-2.webp';
import VBLink from '../components/VBLink/VBLink';
import ColoredIcon from '../components/ColoredIcon/ColoredIcon';

const vbBlue = BasicBranding.VIEWBUFF.THEME_COLOR;
const vbBlack = '#3B3B3B';
const vbGreen = '#21BB21';
const vbRed = '#E24380';
const vbYellow = '#CEC700';
const vbWhite = '#FFFFFF';
const vbGrayDark = '#818191';
const vbGrayMid = '#AAABBB';
const vbGrayLight = '#EEEEF1';

/**
 * Get the branding config for a site
 * @param {string} site - the site name to get the branding config for
 *
 */
export const getBranding = (site) => {
  /**
   * Branding information for the site.
   * Site colors are handled in colors.jsx
   */
  const Branding = {
    // Note: Using ZZ conf here
    HOMEPAGE_IMAGES: [KidsImage],
    CSS_VARS: {
      'color': {
        // In essence, blue is the primary.
        'blue': [vbBlue, { highlight: new Color(vbBlue).lighten(0.05).hex() }],
        'black': vbBlack,
        'green': [vbGreen, { highlight: new Color(vbGreen).lighten(0.05).hex() }],
        'red': [vbRed, { highlight: new Color(vbRed).lighten(0.05).hex() }],
        'yellow': [vbYellow, { highlight: new Color(vbYellow).lighten(0.05).hex() }],
        'white': vbWhite,
        'grey-light': vbGrayLight,
        'grey-mid': vbGrayMid,
        'grey-dark': vbGrayDark,
      },
      'max-width': '1300px',
    },
    RATING_EMOJIS: [FaceSad, FaceHappy, FaceSuperHappy],
    RATING_EMOJIS_BORDER: [FaceSadBorder, FaceHappyBorder, FaceSuperHappyBorder],
    RATING_EMOJI_LABELS: ['Bad', 'Good', 'Great'],
    SOCIAL: {
      INSTAGRAM: 'https://www.instagram.com/viewbuff',
    },
    COMPANY: 'Potter Media Group LLC',
  };

  if (site === SITES[SITES.VIEWBUFF]) {
    Object.assign(Branding, BasicBranding.VIEWBUFF, {
      // Note: Using ZZ conf here
      HOMEPAGE_IMAGES: [KidsImage],
      // Note: just use the ZZ ones for now
      SIGNUP_BENEFITS_IMAGES: [ZZBenefitsFull, ZZBenefitsSmall],
      LOGO_ICON: GemIcon,
      LOGO_TEXT: VBTextLogo,
      ICONS: {
        // FAVICON: ImgFavicon,
        APPLE_TOUCH: GemIcon,
        UPLOAD_PENCIL: ImageVBEditPencil,
        UPLOAD_CROP: ImageVBCrop,
        RIGHT_ARROW_FILLED: ImageVBArrowFullRight,
        MAP_MARKER: VBMapMarker,
        MAP_MARKER_SELECTED: VBMapMarkerSelected,
      },
      // The message to display in Venue meta if this appears on another site.
      CROSS_LINK_TEXT: 'Great View',
      // Icon to display in Venue meta if this appears on another site.
      CROSS_LINK_ICON: ImageGemGoldFilled,
      HASHTAG: 'viewbuff',
      SITE_URL: 'viewbuff.com',
      BADGES: {
        title: 'View Quality',
        gold: {
          node: <img alt="Gold Badge" src={ImageGemGold} />,
          tooltip: 'VB Gold Badge (Highest)',
        },
        silver: {
          node: <img alt="Silver Badge" src={ImageGemSilver} />,
          tooltip: 'VB Silver Badge',
        },
        bronze: {
          node: <img alt="Bronze Badge" src={ImageGemBronze} />,
          tooltip: 'VB Bronze Badge',
        },
      },
    });
    Branding.SHARE_DESCRIPTION = `Check out ${Branding.SITE_NAME} to see the best views in the US and Canada and right at your fingertips!`;
    Branding.SHARE_SUBJECT = `Check out ${Branding.SITE_NAME}!`;
    Branding.WHAT = `${Branding.SITE_NAME} strives to help you find the best views anywhere in the US. We show you the best scenery near you, including restaurants, hotels, and activities. We also provide a social environment for view buffs to share their favorite views and connect with each other.`;
    Branding.HOW = `${Branding.SITE_NAME} uses unique technology to pinpoint great scenery all across the US. We make sure to include all the best reviews, pictures, and information for each location. This way, you can quickly find your next amazing view.`;
    Branding.WHO = `${Branding.SITE_NAME} is made by view buffs, for view buffs. Our team is based in North Carolina, but our reach extends all over the US. It took several years to develop ${Branding.SITE_NAME}, and we’re very proud of how far it has come. We hope you enjoy ${Branding.SITE_NAME} as much as we do!`;
    Branding.CONNECT = (
      <p>
        If you’d like to connect with us, check out our{' '}
        <VBLink to="https://www.instagram.com/viewbuff/" newTab>
          Instagram
        </VBLink>{' '}
        and{' '}
        <VBLink to="https://www.facebook.com/profile.php?id=100076354782651" newTab>
          Facebook
        </VBLink>{' '}
        pages.
      </p>
    );
    // Note: just use the zz ones for now
    Branding.ABOUT_ONE = ZuzuAboutOne;
    Branding.ABOUT_TWO = ZuzuAboutTwo;
    Branding.ALT_ONE = 'Woman overlooking city';
    Branding.ALT_TWO = 'Road through the mountains';
  }

  if (site === SITES[SITES.ITINERARIES]) {
    Object.assign(Branding, BasicBranding.ITINERARIES, {
      // Note: Using ZZ conf here
      HOMEPAGE_IMAGES: [KidsImage],
      // Note: just use the ZZ ones for now
      SIGNUP_BENEFITS_IMAGES: [ZZBenefitsFull, ZZBenefitsSmall],
      LOGO_ICON: ImageMedalIcon,
      LOGO_TEXT: TripfoolLogoText,
      ICONS: {
        FAVICON: ImageMedalIconBlue,
        APPLE_TOUCH: ImageMedalIconBlue,
        MAP_MARKER: TFMapMarker,
        MAP_MARKER_SELECTED: TFMapMarkerSelected,
        UPLOAD_PENCIL: ImageTFEditPencil,
        UPLOAD_CROP: ImageTFCrop,
        RIGHT_ARROW_FILLED: ImageTFArrowFullRight,
      },
      // The message to display in Venue meta if this appears on another site.
      CROSS_LINK_TEXT: 'Top Ranked',
      // Icon to display in Venue meta if this appears on another site.
      CROSS_LINK_ICON: ImageMedalIcon,
      HASHTAG: 'tripfool',
      SITE_URL: 'tripfool.com',
      BADGES: {
        title: 'Top Ranked',
        gold: {
          node: <img alt="Gold Medal" src={ImageMedalGold} />,
          tooltip: 'TF Gold Medal (Highest)',
        },
        silver: {
          node: <img alt="Silver Medal" src={ImageMedalSilver} />,
          tooltip: 'TF Silver Medal',
        },
        bronze: {
          node: <img alt="Bronze Medal" src={ImageMedalBronze} />,
          tooltip: 'TF Bronze Medal',
        },
      },
      SITE_ABOUT:
        'TripFool is a free travel platform for the inspired traveler, where you can discover a unique itinerary for your next adventure! Plan your next trip today!',
    });
    Branding.SHARE_DESCRIPTION = `Check out ${Branding.SITE_NAME} to see the best itineraries in the US and Canada and right at your fingertips!`;
    Branding.SHARE_SUBJECT = `Check out ${Branding.SITE_NAME}!`;
    Branding.CSS_VARS.color.blue = [
      Branding.THEME_COLOR,
      { highlight: new Color(Branding.THEME_COLOR).lighten(0.05).hex() },
    ];
    Branding.WHAT = `${Branding.SITE_NAME} provides itineraries for adventures all over the US. We have thousands of great activities and destinations. These itineraries can be customized to create the perfect trip. We also create a social environment in which travelers can share their adventures and connect with each other.`;
    Branding.HOW = `${Branding.SITE_NAME} uses unique technology to find highly rated activities all across the US. We then compile those activities into itineraries based on distance and ratings. We make sure to include the best information, ratings, and pictures of each activity. This way, you can enjoy your trip without the stress of planning what to do.`;
    Branding.WHO = `${Branding.SITE_NAME} is made by explorers, for explorers. Our team is based in North Carolina, but our reach extends all over the US. It took several years to develop ${Branding.SITE_NAME}, and we are proud of how far it has come. We hope you enjoy ${Branding.SITE_NAME} as much as we do!`;
    Branding.CONNECT = (
      <p>
        If you’d like to connect with us, check out our{' '}
        <VBLink to="https://www.facebook.com/TripFool-100396799269483" newTab>
          Facebook
        </VBLink>{' '}
        and{' '}
        <VBLink to="https://www.instagram.com/trip.fool/" newTab>
          Instagram
        </VBLink>{' '}
        pages.
      </p>
    );
    // Note: just use the zz ones for now
    Branding.ABOUT_ONE = ZuzuAboutOne;
    Branding.ABOUT_TWO = ZuzuAboutTwo;
    Branding.ALT_ONE = 'A view of a city from an airplane window';
    Branding.ALT_TWO = 'A view of the ocean from a pool';
  }

  const zuzuMedal = (
    <ColoredIcon
      alt="Zuzu Medal"
      color={BasicBranding.KIDS.THEME_COLOR}
      src={ImageMedalIcon}
      style={{ margin: '0px 2px' }}
      height="24"
      width="16"
    />
  );
  if (site === SITES[SITES.KIDS]) {
    Object.assign(Branding, BasicBranding.KIDS, {
      HOMEPAGE_IMAGES: [KidsImage],
      SIGNUP_BENEFITS_IMAGES: [ZZBenefitsFull, ZZBenefitsSmall],
      LOGO_ICON: DinoLogo,
      // FIXME: placeholder
      LOGO_TEXT: ZuzuLogoText,
      ICONS: {
        FAVICON: DinoLogo,
        APPLE_TOUCH: DinoLogo,
        MAP_MARKER: ZZMapMarker,
        MAP_MARKER_SELECTED: ZZMapMarkerSelected,
        UPLOAD_PENCIL: ImageZZEditPencil,
        UPLOAD_CROP: ImageZZCrop,
        RIGHT_ARROW_FILLED: ImageZZArrowFullRight,
      },
      // The message to display in Venue meta if this appears on another site.
      CROSS_LINK_TEXT: 'Kid Friendly',
      // Icon to display in Venue meta if this appears on another site.
      CROSS_LINK_ICON: DinoLogo,
      RATING_EMOJIS: [DinoSad, DinoHappy, DinoSuperHappy],
      RATING_EMOJIS_BORDER: [DinoSad, DinoHappy, DinoSuperHappy],
      SITE_NAME: 'ZuzuForKids',
      SITE_NAME_ABV: 'Zuzu',
      HASHTAG: 'zuzuforkids',
      SITE_URL: 'zuzuforkids.com',
      BADGES: {
        title: "Kid's Award",
        gold: {
          node: zuzuMedal,
          tooltip: 'Top 1%',
        },
        silver: {
          node: zuzuMedal,
          tooltip: 'Top 5%',
        },
        bronze: {
          node: zuzuMedal,
          tooltip: 'Top 10%',
        },
      },
      SITE_ABOUT:
        'Zuzu is a free travel platform for superstar parents, where you can quickly and easily find the best kids activities near you! Join the adventure today!',
    });
    Branding.SHARE_DESCRIPTION = `Congratulations! You’ve been invited to join your friends and the ZuzuForKids.com community (yes, it’s Free) to find all the best family friendly activities in your state. Never miss out again!`;
    Branding.SHARE_SUBJECT = `ZuzuForKids.com - Take your family fun to another level!`;
    Branding.CSS_VARS.color.blue = [
      Branding.THEME_COLOR,
      { highlight: new Color(Branding.THEME_COLOR).lighten(0.05).hex() },
    ];
    Branding.WHAT = `${Branding.SITE_NAME} strives to help parents easily find the best things to do with their children. We offer insights into the top kids activities in areas all over the US. We provide a safe place for parents to share their adventures and connect with each other.`;
    Branding.HOW = `${Branding.SITE_NAME} uses unique technology to find highly rated kids activities anywhere in the US. We make sure to include the best reviews, pictures, and information about each activity. This way, you can enjoy time with your children, and leave the stress of researching and planning activities to us.`;
    Branding.WHO = `${Branding.SITE_NAME} is made by parents, for parents. Our team is primarily based out of North Carolina, but our reach extends all over the country. It took several years to develop ${Branding.SITE_NAME}, and we are proud of how far it has come. We hope you enjoy ${Branding.SITE_NAME} as much as we do!`;
    Branding.CONNECT = (
      <p>
        If you’d like to connect with us, check out our{' '}
        <VBLink to="https://www.facebook.com/ZuzuForKids" newTab>
          Facebook
        </VBLink>{' '}
        and{' '}
        <VBLink to="https://www.instagram.com/zuzuforkids/" newTab>
          Instagram
        </VBLink>{' '}
        pages. We also have Facebook groups for some cities, you can check those out{' '}
        <VBLink to="https://www.facebook.com/ZuzuForKids/groups">here</VBLink>.
      </p>
    );
    Branding.ABOUT_ONE = ZuzuAboutOne;
    Branding.ABOUT_TWO = ZuzuAboutTwo;
    Branding.ALT_ONE = 'Children playing with tennis rackets and plastic balls';
    Branding.ALT_TWO = 'A child smiling on a playground';
  }
  return Branding;
};

const Branding = getBranding(getSiteName());

export default Branding;
