import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import Styles from './VBCheckbox.module.scss';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import OnClickLink from '../OnClickLink/OnClickLink';
import ImageMark from '../../../assets/images/icons/forms/checkbox-mark.svg';

/**
 * A checkbox.
 *
 * @param {boolean} props.checked whether or not the checkbox has been checked
 * @param {(checked: boolean) => void} props.onChange callback when the checkbox value has changed
 * @param {boolean} props.disabled whether or not the checkbox should be disabled
 * @param {string}  props.className a class to give to the element
 * @param {string}  props.label label to show next to the text box
 */
const VBCheckbox = ({ checked: parentChecked, onChange: handleChange, disabled, className, label }) => {
  // Maintain state so the checkbox is not completely dependant on 2 way data binding.
  const [checked, setChecked] = useState(parentChecked);

  useEffect(() => {
    setChecked(parentChecked);
  }, [parentChecked]);

  /**
   * Handle on onchange event.
   *
   * @param {SyntheticEvent}  x   the event
   */
  const onChange = (x) => {
    setChecked(x.target.checked);
    if (handleChange) handleChange(x.target.checked);
  };

  /**
   * Handle clicks on the checkbox.
   */
  const handleClick = () => {
    if (disabled) {
      return;
    }
    setChecked(!checked);
    if (handleChange) handleChange(!checked);
  };

  return (
    <>
      <OnClickLink onClick={handleClick} noStyle>
        <span
          className={mergeClassNames(
            Styles.checkbox,
            className,
            checked ? Styles.selected : null,
            disabled ? Styles.disabled : null
          )}
        >
          <input
            type="checkbox"
            style={{ display: 'none' }}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <ColoredIcon className={Styles.boxMark} src={ImageMark} color={disabled ? 'black' : 'white'} />
        </span>
        {label && <span className={Styles.label}>{label}</span>}
      </OnClickLink>
    </>
  );
};

VBCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.node,
};

VBCheckbox.defaultProps = {
  className: null,
  onChange: null,
  checked: false,
  disabled: false,
  label: undefined,
};

export default VBCheckbox;
