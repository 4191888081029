import IconButton from '../../../../../../shared-react/src/components/IconButton/IconButton';
import ColoredIcon from '../../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { VBReactContext } from '../../../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ImageBasic from '../../../../../../shared-react/assets/images/icons/search/search-near.svg';
import ImageLarge from '../../../../assets/images/icons/buttons/search-near-large.svg';
/**
 * Icon button for viewing venues "near me"
 * @param props.filled    Makes the icon a filled-in circle with no text.
 * @param props.color     Color of the icon. If props.filled is set, then this is ignored and the color is automatically white.
 * @param props.onClick   The function to run on click.
 */
const IconNearMe = (props) => {
  const { filled, onClick } = props;

  const { getThemeColor } = useContext(VBReactContext);

  let { color } = props;
  color = getThemeColor(color);
  if (filled) {
    color = 'white';
  }

  return (
    <>
      <IconButton onClick={onClick} text="Nearby" {...props}>
        <ColoredIcon src={color === 'white' ? ImageLarge : ImageBasic} color={color} height="24" width="24" alt="" />
      </IconButton>
    </>
  );
};

IconNearMe.propTypes = {
  filled: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconNearMe.defaultProps = {
  color: 'blue',
  filled: false,
  onClick: undefined,
};

export default IconNearMe;
