/* eslint-disable react/no-multi-comp */
import React from 'react';
import VBLink from '../../components/VBLink/VBLink';
import styles from '../../components/Header/Header.module.scss';
import OnClickLink from '../../components/OnClickLink/OnClickLink';
import { mergeClassNames } from '../../util/props';
import { getLiveSiteUrl, getSitePrettyName, getSiteUrlByCurrentEnv, getStagingSiteUrl } from '../../util/sites';
import useVbContext from '../../hooks/vb-context';
import { VB_SITE_VIEWBUFF } from '../../config/sites';

export const SettingsLink = () => {
  return (
    <VBLink className={mergeClassNames(styles.noUnderline, styles.bubbleLink)} to="/edit/user">
      Settings
    </VBLink>
  );
};

export const LogoutLink = () => {
  const { logout } = useVbContext();
  return (
    <OnClickLink className={mergeClassNames(styles.noUnderline, styles.bubbleLink)} onClick={logout}>
      Logout
    </OnClickLink>
  );
};

export const OtherSiteLink = ({ name }) => {
  return (
    <VBLink className={mergeClassNames(styles.noUnderline, styles.bubbleLink)} to={getLiveSiteUrl(name)} newTab>
      {getSitePrettyName(name)}
    </VBLink>
  );
};

export const AdminSettings = () => {
  let domain = getSiteUrlByCurrentEnv(VB_SITE_VIEWBUFF);
  const url = `${domain}/wp-admin`;
  return (
    <VBLink className={styles.noUnderline} to={url}>
      Admin Settings
    </VBLink>
  );
};
