/**
 * Actions used by the API and content factory.
 */

import { requestItems, requestOne } from '../../util/api';
import { getKey } from '../../util/objects';
import {
  ADD_TO,
  ITEMS_CLEAR,
  ITEMS_INVALIDATE,
  ITEMS_RECEIVED,
  ITEMS_REFRESHED,
  ITEMS_REFRESHING,
  ITEMS_REQUESTED,
  REMOVE_FROM,
  REMOVE_ITEM,
  SINGLE_FAILED,
  ITEMS_FAILED,
  SINGLE_RECEIVED,
  SINGLE_REQUESTED,
  SINGLE_STORE,
  UPDATE_ITEM,
  ADD_ITEM,
} from '../types';

/**
 * Requests items. This is used by the content factory. When the response is received, the items and content will be
 * stored in Redux store.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {string} context the context, ie "top25:us/nc/raleigh"
 * @param {string} filter the filter to load, ie {distance: 0, nearMe: false}
 * @param {string} type the type of content, ie "venue"
 * @param {RequestItemsArgs} args the arguments to use when requesting content
 * @param {number} startOffset the offset to request from
 * @param {number} remaining the items left to load. Defaults to a single request
 */
export const requestMultiple =
  (vbRequest, context, filter, type, args = {}, startOffset, remaining) =>
  async (dispatch) => {
    dispatch({
      type: ITEMS_REQUESTED,
      payload: { context, filter },
    });
    try {
      const { items, offset, noMore } = await requestItems(vbRequest, startOffset ?? 0, filter, remaining, args);
      return dispatch({
        type: ITEMS_RECEIVED,
        payload: { context, filter, type, items, offset, noMore },
      });
    } catch (err) {
      console.error(err);
      return dispatch({
        type: ITEMS_FAILED,
        payload: { address: args.getQueryAddress(startOffset, filter), error: err, context, filter },
      });
    }
  };

/**
 * Refresh items. This is used by the content factory. When the response is received, the items and content will be
 * stored in Redux store.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {string} context the context, ie "top25:us/nc/raleigh"
 * @param {string} filter the filter to load, ie {distance: 0, nearMe: false}
 * @param {string} type the type of content, ie "venue"
 * @param {RequestItemsArgs} args the arguments to use when requesting content
 * @param {number} total the items left to load
 */
export const refreshContent =
  (vbRequest, context, filter, type, args = {}, total) =>
  async (dispatch) => {
    dispatch({
      type: ITEMS_REFRESHING,
      payload: { context, filter },
    });
    try {
      const { items, offset, noMore } = await requestItems(vbRequest, 0, filter, total, args);
      return dispatch({
        type: ITEMS_REFRESHED,
        payload: { context, filter, type, items, offset, noMore },
      });
    } catch (err) {
      console.error(err);
      return dispatch({
        type: ITEMS_FAILED,
        payload: { address: args.getQueryAddress(0, filter), error: err, context, filter },
      });
    }
  };

/**
 * Requests a single item, which will be put in redux store.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {string} type the type of content, ie "venue"
 * @param {RequestOneArgs} args the arguments to use when requesting content
 */
export const requestSingle = (vbRequest, type, args) => async (dispatch) => {
  const { id } = args;
  if (id) {
    dispatch({
      type: SINGLE_REQUESTED,
      payload: { type, id },
    });
  }

  try {
    const content = await requestOne(vbRequest, type, args);
    return dispatch({
      type: SINGLE_RECEIVED,
      payload: { type, content },
    });
  } catch (err) {
    return dispatch({
      type: SINGLE_FAILED,
      payload: { address: args.getQueryAddress(id), error: err, type, id },
    });
  }
};

/**
 * Like requestSingle, but you provide the content instead of the API.
 *
 * @param {string} type the type of content, ie "venue"
 * @param {object} content the content to store
 */
export const storeSingle = (type, content) => {
  return (dispatch) =>
    dispatch({
      type: SINGLE_STORE,
      payload: { type, content },
    });
};

/**
 * Updates an item.
 *
 * @param {any} id the ID of the item
 * @param {string} type the type of content, ie "venue"
 * @param {object | Function} content the fields to update
 */
export const updateItem = (id, type, content) => {
  return (dispatch) =>
    dispatch({
      type: UPDATE_ITEM,
      payload: { id, type, content },
    });
};

/**
 * Adds an item. Adds to the content, not any particular list.
 *
 * @param {string} type the type of content, ie "venue"
 * @param {object} content the fields to add
 * @param {any} id the ID of the item
 */
export const addItem = (type, content, id) => {
  return (dispatch) =>
    dispatch({
      type: ADD_ITEM,
      payload: { id: id ?? getKey(content), type, content },
    });
};

/**
 * Adds an item if it does not exist, updates it if it does.
 *
 * @param {string} type the type of content, ie "venue"
 * @param {object} content the fields to add
 * @param {any} id the ID of the item
 */
export const upsertItem = (type, content, id) => {
  return (dispatch, getState) => {
    if (!getState().api.content[type][id]) {
      dispatch({
        type: ADD_ITEM,
        payload: { id: id ?? getKey(content), type, content },
      });
    } else {
      dispatch({
        type: UPDATE_ITEM,
        payload: { id, type, content },
      });
    }
  };
};

/**
 * Reloads a context. This means throwing away all filters, then reloading content in the filter specified until a
 * goal count is reached.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {string} context the context, ie "top25:us/nc/raleigh"
 * @param {string} loadFilter the filter to load, ie {distance: 0, nearMe: false}
 * @param {string} type the type of content, ie "venue"
 * @param {RequestItemsArgs} args the arguments to use when requesting content
 * @param {number} amount the target amount of items to load
 */
export const reloadContext = (vbRequest, context, loadFilter, type, args, amount) => async (dispatch) => {
  const filter = loadFilter;
  dispatch({
    type: ITEMS_CLEAR,
    payload: { context },
  });
  dispatch({
    type: ITEMS_REQUESTED,
    payload: { context, filter },
  });
  try {
    const { items, offset, noMore } = await requestItems(vbRequest, 0, filter, amount, args);
    return dispatch({
      type: ITEMS_RECEIVED,
      payload: { context, filter, type, items, offset, noMore },
    });
  } catch (err) {
    console.error(err);
    return dispatch({
      type: ITEMS_FAILED,
      payload: { address: args.getQueryAddress(0, filter), error: err, context, filter: loadFilter },
    });
  }
};

/**
 * Stores an item.
 *
 * @param {string} type the type of content, ie "venue"
 * @param {object} content the item
 */
export const storeItem = (type, content) => {
  return (dispatch) =>
    dispatch({
      type: SINGLE_STORE,
      payload: { type, content },
    });
};

/**
 * Removes an item from all lists.
 *
 * @param {string} type the type of content, ie "venue"
 * @param {any} id the item ID
 */
export const removeItem = (type, id) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_ITEM,
      payload: { type, id },
    });
};

/**
 * Clears content from a list.
 * @param {string} type the content type
 * @param {(context, filter): bool} invalidate function which determines whether or not to clear the list
 */
export const invalidateList = (type, invalidate) => {
  return (dispatch) =>
    dispatch({
      type: ITEMS_INVALIDATE,
      payload: { type, invalidate },
    });
};

/**
 * Remove an item from certain lists.
 * @param {string} type the content type
 * @param {any} id the ID of the item
 * @param {(context, filter): bool} remove function which determines whether or not to remove from the list
 */
export const removeFrom = (type, id, remove) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_FROM,
      payload: { type, remove, id },
    });
};

/**
 * Add an item to certain lists.
 * @param {string} type the content type
 * @param {any} content the content to add
 * @param {(context: string, filter: any): bool} add function which determines whether or not to add to the list
 * @param {(context: string, filter: any, items: object[])} index function to get the index to add to in the list
 * @param {(context: string, filter: any)} contextual contextual data
 *
 */
export const addTo = (type, content, add, index, contextual) => {
  return (dispatch) =>
    dispatch({
      type: ADD_TO,
      payload: { type, content, add, index, contextual },
    });
};
