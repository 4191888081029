/**
 * Util functions for distance
 */

/**
 * Calculates the distance in miles between two latitude-longitude pairs using the haversine formula.
 * @param {number} lat1 the latitude of the first location
 * @param {number} lon1 the longitude of the first location
 * @param {number} lat2 the latitude of the second location
 * @param {number} lon2 the longitude of the second location
 * @returns {number} the distance between the two given locations, in miles.
 */
export const haversine = (lat1, lon1, lat2, lon2) => {
  // Conversion factor between km and mi
  const CONVERSION_FACTOR = 0.62137119;

  // distance between latitudes
  // and longitudes
  let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
  let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

  // convert to radians
  lat1 = (lat1 * Math.PI) / 180.0;
  lat2 = (lat2 * Math.PI) / 180.0;

  // apply formula
  let a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  let rad = 6371;
  let c = 2 * Math.asin(Math.sqrt(a));
  let dist = rad * c * CONVERSION_FACTOR;
  return dist;
};

/**
 * Builds a string for a distance in miles in a nice format. If the distance is at least one mile,
 * it is truncated to an integer value. If the distance is less than one mile but greater than 0.1 miles,
 * it is changed to 0.1 miles. If the distance is less than 0.1 miles, it is truncated to 1 decimal place.
 * The new distance value then has ' mi' appended to the end of it.
 * @param {number} distance the distance to build a string for
 * @returns {string} the distance, formatted as indicated above
 */
export const buildDistanceString = (distance) => {
  let str = '';
  if (distance < 1) {
    str += Math.min(distance, 0.1).toFixed(1);
  } else {
    str += distance.toFixed(0);
  }
  str += ' mi';
  return str;
};
