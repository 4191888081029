/**
 * Currently used in HTTP requests so that we can get more data in the catch
 * of a request.
 */
export class VBError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}
