import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VBButton from '../../VBButton/VBButton';
import { useClosePopup } from '../../../hooks/popup';
import VBHeading from '../../VBHeading/VBHeading';
import useVbContext from '../../../hooks/vb-context';
import { isIOS, isSafari } from 'react-device-detect';
import ImageIOSAdd from '../../../../assets/images/icons/ios/add.webp';
import ImageIOSShare from '../../../../assets/images/icons/ios/share.webp';
import ColoredIcon from '../../ColoredIcon/ColoredIcon';
import useSendAnalyticsEvent from '../../../hooks/send-analytics-event';
import { LOCAL_STORAGE_REPROMPT_TIME_KEY, NEVER_REPROMPT_VALUE } from '../InstallPWA';

import styles from './PWAPopup.module.scss';

const propTypes = {
  /** event from beforeinstallprompt listener **/
  deferredPrompt: PropTypes.object,
};

/**
 * PWA install prompt. Used by InstallPWA.
 */
const PWAPopup = ({ deferredPrompt }) => {
  // Current page of the popup.
  const [page, setPage] = useState('prompt');

  const { brandingConfig } = useVbContext();

  const closePopup = useClosePopup();

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const neverReprompt = () => {
    localStorage.setItem(LOCAL_STORAGE_REPROMPT_TIME_KEY, NEVER_REPROMPT_VALUE);
  };

  /**
   * Handle user selecting "Maybe Later". Sets the reprompt time for 1 week in the future.
   */
  const handleMaybeLater = () => {
    sendAnalyticsEvent('vb_pwa_prompt_outcome_no', 'vb_pwa');
    closePopup();
  };

  /**
   * Handle clicking the "Install" button. Will change the page and possibly add a browser prompt depending on the
   * device and browser.
   */
  const handleAcceptPrompt = () => {
    sendAnalyticsEvent('vb_pwa_prompt_outcome_yes', 'vb_pwa');

    if (isIOS && isSafari) {
      setPage('install-ios');
      sendAnalyticsEvent('vb_pwa_show_prompt_instructions_ios', 'vb_pwa');
      neverReprompt();
    } else {
      deferredPrompt.prompt();

      deferredPrompt.userChoice
        .then(({ outcome }) => {
          sendAnalyticsEvent('vb_pwa_install_outcome_' + (outcome ? 'yes' : 'no'), 'vb_pwa');

          if (outcome) {
            neverReprompt();
          }
        })
        .catch(() => {
          sendAnalyticsEvent('vb_pwa_install_outcome_no', 'vb_pwa');
        })
        .finally(() => {
          closePopup();
        });
    }
  };

  let content;
  switch (page) {
    case 'prompt':
      // Initial page.
      content = (
        <>
          <div>Skip the app store. Install the free {brandingConfig.SITE_NAME} app now!</div>
          <br />
          <div className={styles.bottom}>
            <VBButton content="Install App" onClick={handleAcceptPrompt} size="med" type="full" />
            <VBButton content="Maybe Later" onClick={handleMaybeLater} size="med" type="border" />
          </div>
        </>
      );
      break;
    case 'install-ios':
      // Installation instructions for iOS.
      content = (
        <>
          <ol>
            <li>
              Tap the Share {<ColoredIcon src={ImageIOSShare} className={styles.iosIcon} alt="Share" />} icon at the
              bottom of the screen.
            </li>
            <li>
              Select the Add to Home Screen {<ColoredIcon src={ImageIOSAdd} className={styles.iosIcon} alt="Add" />}{' '}
              option. You may need to scroll down to find this option.
            </li>
          </ol>
          <br />
          <div className={styles.bottom}>
            <VBButton content="Done" onClick={closePopup} size="med" type="full" />
          </div>
        </>
      );
      break;
    case 'install-general':
      // General installation instructions.
      content = (
        <>
          <div>Please follow the instructions to install {brandingConfig.SITE_NAME} on your device.</div>
        </>
      );
      break;
    // We could add more devices/platforms here in the future.
    default:
  }

  return (
    <div className={styles.container}>
      <VBHeading size="xs">Install App</VBHeading>
      {content}
    </div>
  );
};

export default PWAPopup;

PWAPopup.propTypes = propTypes;
