import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import IconButton from '../../../../../shared-react/src/components/IconButton/IconButton';
import IconShare from '../../../../../shared-react/src/components/IconShare/IconShare';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import ColoredIcon from '../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import ImagePencil from '../../../../../shared-react/assets/images/icons/forms/mono-edit-pencil.svg';
import ImageDelete from '../../../../../shared-react/assets/images/icons/forms/delete-trash.svg';
import usePopup, { useOpenAuthPopup } from '../../../hooks/open-auth-popup';
import LikeButton from '../PostCard/LikeButton/LikeButton';
import PostCommentsWrapper from '../PostCommentsWrapper/PostCommentsWrapper';
import FAQCommentsWrapper from './FAQCommentsWrapper/FAQCommentsWrapper';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import ArticleCommentsWrapper from './ArticleCommentsWrapper/ArticleCommentsWrapper';

import styles from './SocialActions.module.scss';

const propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.oneOf(['post', 'FAQ', 'article']).isRequired,
  liked: PropTypes.bool,
  commentsTotal: PropTypes.number,
  shareType: PropTypes.string.isRequired,
  shareName: PropTypes.string,
  username: PropTypes.string,
  userLink: PropTypes.string,
  additionalActions: PropTypes.node,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

const SocialActions = ({
  entityId,
  entityType,
  liked,
  commentsTotal,
  shareType,
  shareName,
  username,
  userLink,
  additionalActions,
  className,
  onEdit,
  onDelete,
}) => {
  const { currentUser } = useVbContext();
  const openAuthPopup = useOpenAuthPopup();

  const { modal, open } = usePopup({
    child:
      entityType === 'post' ? (
        <PostCommentsWrapper postId={entityId} commentsTotal={commentsTotal} />
      ) : entityType === 'article' ? (
        <ArticleCommentsWrapper articleId={entityId} commentsTotal={commentsTotal} />
      ) : (
        <FAQCommentsWrapper faqId={entityId} commentsTotal={commentsTotal} />
      ),
    size: 'lg',
    initOpen: false,
  });

  const handleOpenComments = useCallback(() => {
    if (currentUser) {
      open();
    } else {
      openAuthPopup();
    }
  }, [currentUser, open, openAuthPopup]);

  const additionalActionsContent = useMemo(() => {
    if (additionalActions) {
      return additionalActions;
    }

    if ((!onEdit && !onDelete) || !currentUser?.hasProductCapability) {
      return null;
    }

    return (
      <>
        {onEdit ? (
          <IconButton onClick={onEdit}>
            <ColoredIcon src={ImagePencil} className={styles.pencil} />
          </IconButton>
        ) : null}
        {onDelete ? (
          <IconButton onClick={onDelete}>
            <ColoredIcon src={ImageDelete} className={styles.trash} />
          </IconButton>
        ) : null}
      </>
    );
  }, [additionalActions, currentUser?.hasProductCapability, onDelete, onEdit]);

  return (
    <div className={mergeClassNames(styles.socialActions, className)}>
      <div className={styles.buttons}>
        <LikeButton
          entityId={entityId}
          entityType={entityType}
          liked={liked}
          containerClassName={styles.likeButton}
          iconClassName={styles.icon}
          withLabel
        />
        <IconButton text={commentsTotal || null} textPosition="right" onClick={handleOpenComments}>
          <FontAwesomeIcon icon={faComment} className={styles.icon} />
        </IconButton>
        <IconShare
          type={shareType}
          name={shareName}
          username={username}
          userLink={userLink}
          shareParams={{ with: '24px' }}
          postId={entityId}
          faqId={entityId}
          articleId={entityId}
          hideText
        />
        {additionalActionsContent}
      </div>
      {modal}
    </div>
  );
};

SocialActions.propTypes = propTypes;

export default SocialActions;
