import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { isUndefined } from '../util/objects';

/**
 * A common functionality for headers and footers we have is when the user scrolls the page down, we want it to disappear.
 * And if the user scrolls back up, it should reappear. This function will monitor a scrollable container.
 * If the user scroll down more than delta pixels, this will return false, meaning you should make the element hidden.
 * If the user scrolls up more than delta pixels, this will return true, meaning you should make the element visible.
 * @param {React.Ref} scrollContainer - The element to check the scrolling of. Will default to window.
 * @param {number} delta - How many pixels of scrolling down or up to trigger a flip in visibility.
 * @param {number} offset - How many pixels the user must be scrolled down before the content can be hidden
 * @param {number} hiddenOffset - How many pixels the user must be scrolled down before the content can be visible
 * @return {bool} - Whether the content should be visible or hidden
 */
function useVisibleOnUpscroll({ scrollContainer = window, offset = 0, hiddenOffset = 0, delta = 20 } = {}) {
  const isWindow = scrollContainer === window;

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState((isWindow ? window.scrollY : scrollContainer.current?.scrollTop) || 0);

  useEffect(() => {
    const listenEl = isWindow ? document : scrollContainer.current;
    const observeEl = isWindow ? document.body : scrollContainer.current;

    const handleScroll = () => {
      const y = isWindow ? window.scrollY : scrollContainer.current?.scrollTop;
      if (typeof y !== 'undefined') {
        if (y < offset) {
          setIsVisible(true);
          return;
        }
        if (y < hiddenOffset) {
          setIsVisible(false);
          return;
        }

        if (Math.abs(lastScrollY - y) <= delta) return;

        if (y > lastScrollY) {
          // Scrolling down
          setIsVisible(false);
        } else {
          // Scrolling up
          setIsVisible(true);
        }
        setLastScrollY(y);
      }
    };

    listenEl.addEventListener('scroll', handleScroll);
    const observer = new ResizeObserver(handleScroll);
    observer.observe(observeEl);
    return () => {
      listenEl.removeEventListener('scroll', handleScroll);
      observer.unobserve(observeEl);
    };
  }, [delta, hiddenOffset, isWindow, lastScrollY, offset, scrollContainer]);

  return isVisible;
}

export default useVisibleOnUpscroll;
