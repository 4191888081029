import React from 'react';
import PropTypes from 'prop-types';
import VBButton from '../VBButton/VBButton';
import { AGE_GROUPS } from '../../config/explore';
import { mergeClassNames } from '../../util/props';
import styles from './AgesSelector.module.scss';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  /** array of non repeating indices in AGE_GROUPS */
  ages: PropTypes.array.isRequired,

  /** setter for ages */
  setAges: PropTypes.func.isRequired,

  /** whether or not to hide the label */
  hideLabel: PropTypes.bool,

  /** whether or not the content should be displayed vertically */
  isVertical: PropTypes.bool,

  /** whether the filters should be left aligned with no width 100% when vertical. Has no effect if fullWidth is true */
  leftAlign: PropTypes.bool,

  /** whether or not the filters should take up the full width of the container */
  fullWidth: PropTypes.bool,
};

const defaultProps = {
  hideLabel: false,
  isVertical: false,
  leftAlign: false,
  fullWidth: false,
};

/**
 * Select ages in AGE_GROUPS. Used on kids site.
 *
 * @param {object} props
 */
const AgesSelector = ({ ages, setAges, hideLabel, isVertical, leftAlign, fullWidth, analyticsLabel }) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  /**
   * Toggles whether or not an age group is selected.
   *
   * @param {number} age the age group index, see AGE_GROUPS
   */
  const toggleAgeGroup = (age) => {
    const ind = ages.indexOf(age);
    const newAges = [...ages];
    if (ind < 0) {
      newAges.push(age);
      newAges.sort();
      setAges(newAges);
    } else if (ages.length > 1) {
      newAges.splice(ind, 1);
      newAges.sort();
      setAges(newAges);
    }
  };

  let label = null;
  if (!hideLabel) {
    label = <span className={styles.ages}>Ages: </span>;
  }

  return (
    <div
      className={mergeClassNames(
        styles.ageContainer,
        isVertical ? styles.vertical : null,
        leftAlign ? styles.leftAlign : null
      )}
    >
      {label}
      {AGE_GROUPS.map((val, ind) => (
        <VBButton
          content={val}
          onClick={() => {
            if (analyticsLabel)
              sendAnalyticsEvent(`vb_explore_filter_${analyticsLabel}_${ind}_${ages.includes(ind) ? 0 : 1}`);
            toggleAgeGroup(ind);
          }}
          size="med"
          type={ages.includes(ind) ? 'full' : 'border'}
          key={val}
          className={fullWidth ? styles.fullWidth : null}
        />
      ))}
    </div>
  );
};

export default AgesSelector;
AgesSelector.propTypes = propTypes;
AgesSelector.defaultProps = defaultProps;
