import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import VBField from '../../VBForm/VBInput/VBField';
import useVbContext from '../../../hooks/vb-context';
import { invalidateList } from '../../../store/actions/apiActions';
import { ContentTypes } from '../../../config/content-types';
import APIConfig from '../../../config/api';

import styles from './AskQuestion.module.scss';

const AskQuestion = ({ locationDetails }) => {
  const dispatch = useDispatch();

  const { currentUser, openAuthPopup, raiseToast, errorToast, vbRequest } = useVbContext();

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: '',
    },
  });

  const onSubmit = useCallback(
    ({ question }) => {
      const trimmedQuestion = question.trim();

      if (trimmedQuestion && locationDetails) {
        vbRequest(`${APIConfig.NAMESPACE.LOCATION}/question/ask`, {
          method: 'POST',
          body: { question, locationId: locationDetails.id },
        })
          .then(() => {
            raiseToast('Question has been submitted!', 'Success');

            dispatch(
              invalidateList(
                ContentTypes.locationQuestion.type,
                (ctx) => ctx === `location-question:${locationDetails?.id}`
              )
            );

            setValue('question', '');
          })
          .catch((error) => errorToast(error.message, 'Error'));
      }
    },
    [dispatch, errorToast, locationDetails, raiseToast, setValue, vbRequest]
  );

  const handleClickAskQuestion = useCallback(() => {
    if (!currentUser) {
      openAuthPopup();
    }
  }, [currentUser, openAuthPopup]);

  return (
    <form className={styles.askQuestion} onSubmit={handleSubmit(onSubmit)} onClick={handleClickAskQuestion}>
      <VBField
        name="question"
        prettyLabel=""
        placeholder="Ask a Question..."
        disabled={!currentUser}
        control={control}
        className={styles.input}
      />
    </form>
  );
};

export default AskQuestion;
