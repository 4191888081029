import Comments from '../../../../../shared-react/src/components/Comments/Comments';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import { updateItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { useContentFactory } from '../../../../../shared-react/src/hooks/content-factory';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import APIConfig from '../../../../../shared-react/src/config/api';
import useCurrentUser from '../../../hooks/current-user';
import { vbRequest } from '../../../util/api';
import { errorToast, pointsToast } from '../../../util/toast';
import Report from '../../../../../shared-react/src/components/Report/Report';
import BackIcon from '../../../assets/images/icons/arrows/left-gray.svg';
import TFReplies from './TFReplies/TFReplies';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import Author from './Author/Author';

const propTypes = {
  /** content type of the parent content */
  contentType: PropTypes.string.isRequired,

  /** ID of the parent content */
  contentId: PropTypes.number.isRequired,

  /** the field in the parent object which represents the number of comments */
  commentCountField: PropTypes.string.isRequired,

  /** total number of comments, from the parent */
  commentsTotal: PropTypes.number.isRequired,

  /** the route parameter to use when querying, for example "posts" or "itineraries/days" */
  queryRoute: PropTypes.string.isRequired,

  /** callback for when the back button is blocked. If this is omitted, the back button will not be rendered */
  onBackButtonClick: PropTypes.func,

  /** whether or not to hide the title */
  hideTitle: PropTypes.bool,

  /** max length of a comment */
  maxLength: PropTypes.number,

  /** max length of a reply */
  maxReplyLength: PropTypes.number,
};

const defaultProps = {
  onBackButtonClick: undefined,
  hideTitle: undefined,
  maxLength: undefined,
  maxReplyLength: undefined,
};

/**
 * Wrapper for @vb/vb-react/Comments with a lot of the Itineraries boilerplate in place.
 *
 * @param {object} props
 */
const TFComments = ({
  contentType,
  contentId,
  commentCountField,
  commentsTotal,
  queryRoute,
  onBackButtonClick,
  hideTitle,
  maxLength,
  maxReplyLength,
}) => {
  const dispatch = useDispatch();
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const { user } = useCurrentUser();

  const [author, setAuthor] = useState(user);

  const { brandingConfig } = useVbContext();
  const {
    ICONS: { RIGHT_ARROW_FILLED: AddIcon },
  } = brandingConfig;

  // Content factory hook.
  const {
    content,
    loadMore,
    reload,
    noMore,
    loading: commentsLoading,
  } = useContentFactory({
    getQueryAddress: (offset) => `${APIConfig.NAMESPACE.COMMENT}/${queryRoute}/${contentId}?offset=${offset}`,
    context: `comments:${contentType}-${contentId}`,
    type: ContentTypes.comment.type,
    getItems: ({ comments }) => comments.map((item) => ({ item })),
  });

  /**
   * Creates a new comment.
   *
   * @param {string} commentContent the content of the new comment
   */
  const handleSubmit = async (commentContent) => {
    sendAnalyticsEvent(`vb_comments_${contentType}_create`, `vb_comments_${contentType}`);
    try {
      const response = await vbRequest(`${APIConfig.NAMESPACE.COMMENT}/${queryRoute}/${contentId}`, {
        method: 'POST',
        body: { content: commentContent, author_id: author.id },
      });

      if (user.id === author.id) {
        pointsToast(response.expert_points, 'commenting');
      }

      dispatch(updateItem(contentId, contentType, { [commentCountField]: commentsTotal + 1 }));
      reload(1);
    } catch (err) {
      errorToast(err);
      throw err;
    }
  };

  /**
   * Handle a report of a comment.
   */
  const handleReport = async () => {
    dispatch(updateItem(contentId, contentType, { [commentCountField]: commentsTotal - 1 }));
  };

  /**
   * Handle liking a comment.
   *
   * @param {object} comment
   */
  const handleLike = async (comment) => {
    const isLike = !comment.liked;
    dispatch(
      updateItem(comment.id, ContentTypes.comment.type, {
        likeCount: comment.likeCount + (isLike ? 1 : -1),
        liked: isLike,
      })
    );

    sendAnalyticsEvent(`vb_comments_${contentType}_like`, `vb_comments_${contentType}`);

    try {
      const response = await vbRequest(`${APIConfig.NAMESPACE.COMMENT}/comments/${comment.id}/like`, {
        method: 'POST',
        body: {
          like: isLike ? 1 : 0,
        },
      });
      pointsToast(response.expert_points, 'liking a comment');
    } catch (err) {
      errorToast(err);
      throw err;
    }
  };

  let title;
  if (!hideTitle) {
    title = commentsTotal === 1 ? '1 Comment' : `${commentsTotal} Comments`;
  }

  // Mounting this component again on every render means doing the content factory initial load over and over again.
  // So, memoize it.
  /* eslint-disable react/no-multi-comp */
  /* eslint-disable react/prop-types */
  const replies = useMemo(
    () =>
      ({ commentId, replyCount }) =>
        <TFReplies commentId={commentId} replyCount={replyCount} maxLength={maxReplyLength} />,
    [maxReplyLength]
  );
  /* eslint-enable react/no-multi-comp */
  /* eslint-enable react/prop-types */

  return (
    <Comments
      comments={content.map(({ item }) => item)}
      loading={commentsLoading}
      noMore={noMore}
      title={title}
      maxLength={maxLength}
      onRequestSubmit={handleSubmit}
      report={(comment) => (
        <Report contentType={ContentTypes.comment.type} contentId={comment.id} onReport={handleReport} />
      )}
      onRequestLike={handleLike}
      onRequestLoadMore={loadMore}
      onBackButtonClick={onBackButtonClick}
      replies={replies}
      currentUserId={user?.id}
      addIcon={<img src={AddIcon} alt="Add Comment" />}
      backIcon={<img src={BackIcon} alt="Back" />}
      author={user?.hasProductCapability ? <Author author={author} onSelect={setAuthor} /> : null}
    />
  );
};
export default TFComments;

TFComments.propTypes = propTypes;
TFComments.defaultProps = defaultProps;
