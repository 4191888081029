import React from 'react';
import styles from './Testimonial.module.scss';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../../util/props';

/**
 * A component containing an image and some text used to display testimonials
 * on the bottom of the homepage.
 * @param {image} img The image to display in the testimonial
 * @param {string} description The description to display next to the image
 * @param {string} name The name of the person giving the testimonial
 */
const Testimonial = ({ img, description, name }) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();

  return (
    <div className={mergeClassNames(styles.testimonial, mobileStyle && styles.mobile)}>
      <div className={styles.row}>
        <img
          src={img}
          alt=""
          height="100"
          width="100"
          className={mergeClassNames(styles.image, mobileStyle && styles.mobile)}
        />
        <p className={mergeClassNames(styles.description, mobileStyle && styles.mobile)}>{description}</p>
      </div>
      <div className={styles.row}>
        <p className={mergeClassNames(styles.stars, mobileStyle && styles.mobile)}>
          &#9733;&#9733;&#9733;&#9733;&#9733;
        </p>
        <p className={mergeClassNames(styles.name, mobileStyle && styles.mobile)}>{`- ${name}`}</p>
      </div>
    </div>
  );
};

export default Testimonial;
