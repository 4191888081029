import React from 'react';
import { useLocation } from 'react-router';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import VBLink from '../../../../../shared-react/src/components/VBLink/VBLink';
import { useVBBreakpoint } from '../../../../../shared-react/src/hooks/vb-breakpoint';
import Branding from '../../../../../shared-react/src/config/branding';

import styles from './Footer.module.scss';

/**
 * The footer.
 */
const Footer = () => {
  const { lteSm } = useVBBreakpoint();
  const bulletClassNames = mergeClassNames(styles.bullet, lteSm ? styles.lteSmBreakpoint : undefined);

  const { pathname } = useLocation();

  const isFooterHidden = ['/daily-travel-contest', '/zuzu-go', '/trips', '/embed/'].some((item) => pathname.indexOf(item) !== -1);

  // Temporary: reduce Cumulative Layout Shift on Venue Details page
  const isVenueDetailsPage = pathname.indexOf('/places') !== -1;

  if (isFooterHidden) {
    return null;
  }

  return (
    <>
      <footer
        role="contentinfo"
        style={{ flexGrow: !isVenueDetailsPage ? 1 : undefined }}
        className={mergeClassNames('mx-auto', styles.siteFooter)}
      >
        <div className={styles.footerInner}>
          <hr className={styles.separatorLine} />
          <div
            className={mergeClassNames(
              'd-md-flex',
              'flex-column',
              'flex-lg-row',
              'align-self-center',
              styles.footerCopyright
            )}
          >
            <div>
              <span className={styles.legalCopy}>
                &copy; Copyright {new Date().getFullYear()} {Branding.COMPANY}, Inc. All rights reserved.
              </span>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/terms-and-conditions">
                Terms of Use
              </VBLink>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/privacy-policy">
                Privacy Policy
              </VBLink>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/about">
                About Us
              </VBLink>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/contact">
                Contact
              </VBLink>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/blog">
                Family Travel Blog by ZuzuForKids
              </VBLink>
            </div>
            <div className="d-flex">
              <span className={bulletClassNames}> &bull; </span>
              <VBLink className={styles.link} to="/advertisers">
                Advertisers
              </VBLink>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
