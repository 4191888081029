import { isMobile as rddIsMobile, getUA } from 'react-device-detect';

/**
 * Determines if the user is on a mobile device.
 * @returns {string} the device manufacturer if the device is mobile, null otherwise.
 */
export const isMobile = () => (rddIsMobile ? getUA : null);

/**
 * Checks if the site is being run in its PWA.
 * @returns {bool}
 */
export const isPwa = () => {
  // https://benborgers.com/posts/pwa-detect-installed

  // For iOS
  if (window.navigator.standalone) return true;

  // For Android
  if (window.matchMedia('(display-mode: standalone)').matches) return true;

  // If neither is true, it's not installed
  return false;
};
