import { TriviaStatus } from './config';

export const getTriviaStatus = (correctAnswersCount, isFinished) => {
  if (!isFinished) {
    return TriviaStatus.NOT_FINISHED;
  }

  if (correctAnswersCount <= 2) {
    return TriviaStatus.TRY_AGAIN;
  }

  if (correctAnswersCount === 3) {
    return TriviaStatus.GOOD_JOB;
  }

  if (correctAnswersCount === 4) {
    return TriviaStatus.GREAT_JOB;
  }

  return TriviaStatus.MASTERED;
};
