import { useLocation } from 'react-router';
import Pages from '../classes/pages';

export const useIsOnPage = (pageName) => {
  // This hook should guarantee that whenever the location changes we will get a rerender.
  const location = useLocation();
  return Pages.isOnPage(pageName, location.pathname);
};

export const useIsOnAnyOfPages = (pageNames) => {
  // This hook should guarantee that whenever the location changes we will get a rerender.
  const location = useLocation();
  return Pages.isOnAnyOfPages(pageNames, location.pathname);
};
