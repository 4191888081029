import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import HeaderView from './HeaderView/HeaderView';
import useVbContext from '../../hooks/vb-context';
import getHeaderBuilderClass from '../../classes/header-builder/header-builder';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  /** whether or not the user is loading */
  isUserLoading: PropTypes.bool,
};

function Header({ isUserLoading, ...other }) {
  const {
    getThemeColor,
    currentUser,
    components: { SearchBar },
  } = useVbContext();

  const location = useLocation();
  const history = useHistory();

  const HeaderBuilder = getHeaderBuilderClass();

  const headerBuilder = useMemo(
    () => new HeaderBuilder(currentUser, location.pathname, getThemeColor, SearchBar, history),
    [HeaderBuilder, currentUser, location.pathname, getThemeColor, SearchBar, history]
  );

  const { links: hamburgerLinks, dividers: hamburgerDividers } = headerBuilder.getHamburgerLinksAndDividers();
  const tabsAndLinks = useMemo(
    () => ({
      tabs: headerBuilder.getTabs(),
      bubbleDropdownLinks: headerBuilder.getDropdownLinks(),
      hamburgerLinks,
      hamburgerDividers,
    }),
    [hamburgerDividers, hamburgerLinks, headerBuilder]
  );

  const iconLogo = useMemo(() => headerBuilder.getIconLogo(), [headerBuilder]);
  const textLogo = useMemo(() => headerBuilder.getTextLogo(), [headerBuilder]);
  const logos = {
    iconNode: iconLogo,
    siteNameNode: textLogo,
  };

  const backgroundColor = useCalculateBackgroundColorOnScroll(headerBuilder);
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const isHeaderHidden = ['/daily-travel-contest', '/zuzu-go', '/trips', '/embed/'].some(
    (item) => location.pathname.indexOf(item) !== -1
  );

  if (isHeaderHidden) {
    return null;
  }

  return (
    <HeaderView
      {...other}
      fixedPositioning={headerBuilder.hasFixedPositioning()}
      hamburgerColor={headerBuilder.getHamburgerIconColor()}
      logos={logos}
      searchBar={headerBuilder.getSearchBar(sendAnalyticsEvent)}
      bubbleImgSrc={currentUser?.avatar}
      tabsAndLinks={tabsAndLinks}
      backgroundColor={backgroundColor}
      blurBackground={headerBuilder.getBackgroundBlur()}
      whiteLinks={headerBuilder.hasWhiteLinks()}
      bottomBorder={headerBuilder.hasBottomBorder()}
      isUserLoading={isUserLoading}
    />
  );
}

/**
 * As the user scrolls the header color is recalculated (because some headers have dynamic color dependent on scroll).
 */
function useCalculateBackgroundColorOnScroll(headerBuilder) {
  const calculateColor = useMemo(
    () => (window) => headerBuilder.calculateBackgroundColor(window.scrollY, window.innerHeight),
    [headerBuilder]
  );
  const [homepageBackgroundColor, setHomepageBackgroundColor] = useState(calculateColor(window));
  useEffect(() => {
    const handleScroll = () => {
      setHomepageBackgroundColor(calculateColor(window));
    };
    setHomepageBackgroundColor(calculateColor(window));
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [calculateColor, headerBuilder]);
  return homepageBackgroundColor;
}

Header.propTypes = propTypes;

export default Header;
