import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';

import styles from './ForgotPassword.module.scss';
import APIConfig from '../../../../config/api';
import { mergeClassNames } from '../../../../util/props';
import { VBReactContext } from '../../../VBReactProvider/VBReactProvider';
import { useVBBreakpoint } from '../../../../hooks/vb-breakpoint';
import VBSpinner from '../../../VBSpinner/VBSpinner';
import VBTextInput from '../../../VBTextInput/VBTextInput';
import VBButton from '../../../VBButton/VBButton';
import Sidebar from '../../Sidebar/Sidebar';

const propTypes = { setNotice: PropTypes.func.isRequired };
const defaultProps = {};

/**
 * The forgot password page of the popup.
 */
const ForgotPassword = ({ setNotice }) => {
  // Input control.
  const [emailInput, setEmailInput] = useState('');

  const [loading, setLoading] = useState(false);

  const { vbRequest } = useContext(VBReactContext);
  const { gteXl } = useVBBreakpoint();

  /**
   * Handle click on the submit button. This will send the user an email for a password reset.
   *
   * @param {SyntheticEvent} e the event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    vbRequest(`${APIConfig.NAMESPACE.AUTH}/lost-password`, { method: 'POST', params: { login: emailInput } })
      .then(() => {
        setLoading(false);
        setNotice({ message: 'Check your email for a link to reset your password!', variant: 'success' });
      })
      .catch((err) => {
        setLoading(false);
        setNotice({ message: parseHTML(err.message), variant: 'danger' });
      });
    return false;
  };

  return (
    <>
      <div className="d-flex">
        <div className={styles.container} style={{ flexShrink: gteXl ? '0' : '1' }}>
          <h1>Forgot Password</h1>
          <Form onSubmit={handleSubmit} className={styles.form}>
            <Form.Group controlId="loginEmail">
              <Form.Label>
                Type in your username or email and we’ll send you a link to create a new password.
              </Form.Label>
              <VBTextInput
                type="text"
                placeholder="Username / Email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                required
              />
            </Form.Group>
            {loading ? (
              <div
                className={mergeClassNames(
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  styles.spinnerContainer
                )}
              >
                <VBSpinner />
              </div>
            ) : (
              <VBButton size="med" submit>
                Send Email
              </VBButton>
            )}
          </Form>
        </div>
        {gteXl ? <Sidebar imageType="splash" /> : undefined}
      </div>
    </>
  );
};

ForgotPassword.propTypes = propTypes;
ForgotPassword.defaultProps = defaultProps;

export default ForgotPassword;
