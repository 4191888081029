import { useEffect } from 'react';

/**
 * Hook for listening for window resize.
 *
 * @param {function} cb the callback
 * @param {any[]} deps the callback dependencies
 * @param {bool} callOnAddListener whether to call the function when the listener is added
 */
const useWindowResize = (cb, deps = [], callOnAddListener = false) => {
  useEffect(() => {
    window.addEventListener('resize', cb);
    if (callOnAddListener) cb();
    return window.removeEventListener('resize', cb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, callOnAddListener]);
};

export default useWindowResize;
