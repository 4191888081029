import React from 'react';
import PropTypes from 'prop-types';
import styles from './VBCharacterLimit.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** the number of characters remaining */
  remaining: PropTypes.number.isRequired,
};

/**
 * Character limit used in text boxes.
 * @param {object} props
 */
const VBCharacterLimit = ({ remaining }) => (
  <span className={mergeClassNames(styles.container, remaining < 0 ? styles.over : null)}>{remaining}</span>
);
export default VBCharacterLimit;

VBCharacterLimit.propTypes = propTypes;
