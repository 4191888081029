import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MaxWidthContainer from '../../../../../shared-react/src/components/MaxWidthContainer/MaxWidthContainer';
import FormFAQ from '../../../../../shared-react/src/components/FormFAQ/FormFAQ';
import VBHeading from '../../../../../shared-react/src/components/VBHeading/VBHeading';
import APIConfig from '../../../../../shared-react/src/config/api';
import { invalidateList } from '../../../../../shared-react/src/store/actions/apiActions';
import { useClosePopup } from '../../../../../shared-react/src/hooks/popup';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import { vbRequest } from '../../../util/api';

const propTypes = {
  faq: PropTypes.object,
  venue: PropTypes.object,
  locationDetails: PropTypes.object,
};

const PopupUpsertFAQ = ({ faq, venue, locationDetails }) => {
  const dispatch = useDispatch();
  const closePopup = useClosePopup();

  const handleSubmit = useCallback(
    (values) => {
      if (!locationDetails && !venue) {
        return;
      }

      const invalidateListAndClosePopup = () => {
        dispatch(
          invalidateList(
            ContentTypes.faq.type,
            (ctx) => ctx === (locationDetails ? `faq:location-${locationDetails.id}` : `faq:venue-${venue.id}`)
          )
        );

        closePopup();
      };

      if (faq) {
        vbRequest(`${APIConfig.NAMESPACE.FAQ}/${faq.id}`, {
          method: 'PUT',
          body: values,
        }).then(invalidateListAndClosePopup);
      } else {
        vbRequest(`${APIConfig.NAMESPACE.FAQ}`, {
          method: 'POST',
          body: {
            ...values,
            locationId: locationDetails.id,
          },
        }).then(invalidateListAndClosePopup);
      }
    },
    [locationDetails, venue, faq, dispatch, closePopup]
  );

  return (
    <MaxWidthContainer>
      <VBHeading size="sm">{faq ? 'Edit' : 'Add New'} FAQ</VBHeading>
      <FormFAQ defaultValues={faq} onSubmit={handleSubmit} onCancel={closePopup} />
    </MaxWidthContainer>
  );
};

PopupUpsertFAQ.propTypes = propTypes;

export default PopupUpsertFAQ;
