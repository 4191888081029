import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../../../shared-react/src/components/IconButton/IconButton';
import ColoredIcon from '../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { VBReactContext } from '../../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import PopupLeaveReview from '../../../../../shared-react/src/components/PopupLeaveReview/PopupLeaveReview';
import DinoEmpty from '../../../assets/images/icons/save/zuzu-empty.svg';
import DinoEmptyBold from '../../../assets/images/icons/save/zuzu-empty-bold.svg';
import { usePopup } from '../../../../../shared-react/src/hooks/popup';
import { useOpenAuthPopup } from '../../../hooks/open-auth-popup';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import { PropTypesVBVenue } from '../../../../../shared-react/src/util/types';
import { VENUE_BUTTONS_SHADOW_VALUE } from '../../../../../shared-react/src/config/venue-buttons';

const propTypes = {
  /** venue to add to been there */
  venue: PropTypesVBVenue,

  /** whether or not text should be hidden */
  hideText: PropTypes.bool,

  color: PropTypes.string,

  shadow: PropTypes.bool,

  bold: PropTypes.bool,
};

/**
 * Icon button on TF/ZZ to add a venue to the user's been there.
 */
const IconBeenThere = ({ venue, color, shadow, bold, hideText }) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const {
    vbBlack,
    currentUser,
    brandingConfig: { RATING_EMOJIS },
  } = useContext(VBReactContext);

  const openAuthPopup = useOpenAuthPopup();

  const { modal, open: openLeaveReviewPopup } = usePopup({
    child: <PopupLeaveReview venue={venue} />,
    size: 'lg',
    initOpen: false,
  });

  const emojiRating = venue?.userReview?.emojiRating;

  const { iconContent, description } = useMemo(() => {
    switch (emojiRating) {
      case 1: {
        return {
          iconContent: <ColoredIcon src={RATING_EMOJIS[0]} alt="Sad Dino" width="25" height="26" />,
          description: 'Bad',
        };
      }
      case 2: {
        return {
          iconContent: <ColoredIcon src={RATING_EMOJIS[1]} alt="Happy Dino" width="25" height="23" />,
          description: 'Good',
        };
      }
      case 3: {
        return {
          iconContent: <ColoredIcon src={RATING_EMOJIS[2]} alt="Super Happy Dino" width="25" height="23" />,
          description: 'Great',
        };
      }
      default: {
        return {
          iconContent: (
            <ColoredIcon
              additionalFilters={shadow ? `drop-shadow(${VENUE_BUTTONS_SHADOW_VALUE})` : null}
              color={color || vbBlack}
              src={bold ? DinoEmptyBold : DinoEmpty}
              width="25"
              height="26"
              alt="Dino Empty"
            />
          ),
          description: 'Been There',
        };
      }
    }
  }, [RATING_EMOJIS, bold, color, emojiRating, shadow, vbBlack]);

  const handleClick = () => {
    sendAnalyticsEvent('vb_icon_button_been_there_click', 'vb_icon_button');

    if (!currentUser) {
      openAuthPopup();
    } else {
      openLeaveReviewPopup();
    }
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        text={hideText ? null : description}
        fontSize="1rem"
        textShadow={shadow ? VENUE_BUTTONS_SHADOW_VALUE : null}
        subtitleColor={color}
      >
        {iconContent}
      </IconButton>
      {modal}
    </>
  );
};

export default IconBeenThere;

IconBeenThere.propTypes = propTypes;
