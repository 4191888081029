/**
 * Contains utility functions for working the with Viewbuff API.
 */

import QueryString from 'query-string';
import LocalStore from 'store';
import APIConfig from '../../../shared-react/src/config/api';
import { VBError } from '../../../shared-react/src/util/errors';
import { getSite, getSiteUrlByEnv } from '../../../shared-react/src/util/sites';
import { LOCAL_AUTH_TOKEN_KEY } from '../config/auth';
import { clearToken } from '../store/actions/authTokenActions';
import store from '../store/store';

/**
 * Gets the auth token.
 *
 * @returns {string} the auth token
 */
export const getToken = () => {
  return LocalStore.get(LOCAL_AUTH_TOKEN_KEY);
};

/**
 * @typedef VBRequestArgs
 * @param {string} token the bearer token to use for auth
 * @param {object} params query parameters
 * @param {object} body the body, which will be stringified
 * @param {string} method the HTTP method, defaults to GET
 */

/**
 * Performs an HTTP get request to the VB API.
 *
 * @param {string} endpoint the API endpoint to access within the VB namespace
 * @param {VBRequestArgs} args additional, optional arguments
 *
 * @returns {Promise<object>} returns a promise which resolves the parsed JSON
 */
export const vbRequest = (endpoint, args = {}) =>
  new Promise((resolve, reject) => {
    const { params, body, method, contentType, signal } = args;

    let headers = { ...args.headers };

    let contentTypeObj = {};
    if (contentType !== false) {
      contentTypeObj = { 'Content-Type': contentType || 'application/json' };
    }

    let bodyStr = null;
    if (body) {
      headers = { ...headers, ...contentTypeObj };
      bodyStr = JSON.stringify(body);
    }

    const sendBody = contentType === undefined || contentType === 'application/json' ? bodyStr : body;

    const token = getToken();
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    let isError = false;
    const requestParams = { _site: getSite() };
    if (params) {
      Object.assign(requestParams, params);
    }
    return fetch(`${APIConfig.BASE + endpoint}?${QueryString.stringify(requestParams, { arrayFormat: 'bracket' })}`, {
      method: method || 'GET',
      headers,
      signal,
      body: sendBody,
    })
      .then((response) => {
        if (!response.ok) {
          isError = true;
        }
        return response.json();
      })
      .then((data) => {
        if (!isError) {
          return data;
        }
        if (data.code === 'jwt_auth_invalid_token') {
          // If the token was invalid, clear it from local store and redux.
          store.store.dispatch(clearToken());
          // TODO: This whole thing should probably be in a hook so we can just call useClearToken.
          setAuthToken(null);
        }
        throw new VBError(data.message ?? 'unknown error', data.code ?? 'unknown_code');
        // return null;
      })
      .then(resolve)
      .catch(reject);
  });

export const setAuthToken = (token) => {
  fetch(`${getSiteUrlByEnv()}/api/v2/set-auth-cookie`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
  });
};
