import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { scrollVertically } from '../util/scroll';

const useContentAutoScroll = (offset, deps = []) => {
  const { hash } = useLocation();
  const isScrollOccurred = useRef(false);

  useEffect(() => {
    isScrollOccurred.current = false;
  }, [hash]);

  useLayoutEffect(() => {
    const [actualHash] = hash.split('?');

    if (actualHash && !isScrollOccurred.current) {
      const element = document.getElementById(actualHash.slice(1));

      if (element) {
        setTimeout(() => {
          scrollVertically(element, offset);
        }, 250);

        isScrollOccurred.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, offset, ...deps]);
};

export default useContentAutoScroll;
