import { useState } from 'react';
import { useDispatch } from 'react-redux';
import APIConfig from '../../../shared-react/src/config/api';
import { ContentTypes } from '../../../shared-react/src/config/content-types';
import useVbContext from '../../../shared-react/src/hooks/vb-context';
import { updateItem } from '../../../shared-react/src/store/actions/apiActions';

/**
 * Returns a function used to toggle RSVP for an event.
 */
const useEventToggleRsvp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { vbRequest, errorToast, raiseToast } = useVbContext();
  const dispatch = useDispatch();

  const toggleRsvp = (event, count) => {
    if (isLoading) return;
    setIsLoading(true);

    const isRsvp = !event.userSignUpCount;
    const initialSignUpCount = event.signUpCount;
    const initialUserSignUpCount = event.userSignUpCount;
    const newSignUpCount = initialSignUpCount + (isRsvp ? count : -event.userSignUpCount);
    const newUserSignUpCount = isRsvp ? count : null;
    vbRequest(`${APIConfig.NAMESPACE.EVENTS}/${event.id}/rsvp`, { method: 'POST', params: { isRsvp, count } })
      .then(() => {
        // success.
        if (isRsvp) {
          raiseToast(`Successfully RSVP'd ${count} guests`, 'Success!');
        } else {
          raiseToast('Successfully cancelled RSVP', 'Success!');
        }
      })
      .catch((err) => {
        errorToast(err.message);
        // On failure, reset to initial state.
        dispatch(
          updateItem(event.id, ContentTypes.event.type, {
            signUpCount: initialSignUpCount,
            userSignUpCount: initialUserSignUpCount,
          })
        );
      })
      .finally(() => setIsLoading(false));
    dispatch(
      updateItem(event.id, ContentTypes.event.type, {
        signUpCount: newSignUpCount,
        userSignUpCount: newUserSignUpCount,
      })
    );
  };

  return { isLoading, toggleRsvp };
};

export default useEventToggleRsvp;
