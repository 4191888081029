export const getMasteredTriviaSets = () => {
  const masteredTriviaSets = localStorage.getItem('masteredTriviaSets');

  if (masteredTriviaSets) {
    return JSON.parse(masteredTriviaSets);
  }

  return [];
};

export const getInitialFinishedSets = (triviaSets) => {
  const masteredSets = getMasteredTriviaSets();
  const unmasteredSets = triviaSets.filter((set) => !masteredSets.includes(set.id));

  if (!unmasteredSets.length) {
    return [];
  }

  return masteredSets;
};

export const saveMasteredTriviaSet = (id) => {
  const savedMasteredTriviaSets = localStorage.getItem('masteredTriviaSets');
  const masteredTriviaSets = savedMasteredTriviaSets ? JSON.parse(savedMasteredTriviaSets) : [];

  if (!masteredTriviaSets.includes(id)) {
    masteredTriviaSets.push(id);
  }

  localStorage.setItem('masteredTriviaSets', JSON.stringify(masteredTriviaSets));
};
