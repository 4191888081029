import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import EventExternal from '../../../EventExternal/EventExternal';
import EventInternal from '../../../EventInternal/EventInternal';

const propTypes = {
  /** event to render */
  item: PropTypes.object.isRequired,

  /** contextual data */
  contextual: PropTypes.object.isRequired,

  /** the specific explore page link that the user navigated from, if any */
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,

  hideImage: PropTypes.bool,
  noMargin: PropTypes.bool,
  isLoading: PropTypes.bool,
};

/**
 * Event rendered by PageExplore.
 *
 * @param {object} props
 */
const RenderedEvent = ({ item: event, contextual, exploreLink, exploreLinkText, hideImage, noMargin, isLoading }) => {
  const externalEvent = useMemo(() => {
    const content = (
      <EventExternal event={event} distance={contextual.distance} hideImage={hideImage} isLoading={isLoading} />
    );

    if (noMargin) {
      return content;
    }

    return <div style={{ marginBottom: '1.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>{content}</div>;
  }, [contextual, event, hideImage, isLoading, noMargin]);

  if (isLoading || event.isThirdParty) {
    return externalEvent;
  }

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <EventInternal
        event={event}
        distance={contextual.distance}
        heading="h3"
        exploreLink={exploreLink}
        exploreLinkText={exploreLinkText}
      />
    </div>
  );
};

export default RenderedEvent;

RenderedEvent.propTypes = propTypes;
