import React from 'react';
import PropTypes from 'prop-types';
import VBButton from '../../VBButton/VBButton';
import useSendAnalyticsEvent from '../../../hooks/send-analytics-event';

const propTypes = {
  /** the current filter object, can be any key value pairs */
  filter: PropTypes.object.isRequired,

  /** function to set the filter, (filter: object): void */
  setFilter: PropTypes.func.isRequired,

  /** the field in the filter that is being changed */
  property: PropTypes.string.isRequired,

  /** the value to set the field to */
  value: PropTypes.any,

  /** the label for the button */
  label: PropTypes.string.isRequired,

  /** whether the button should be small or medium */
  small: PropTypes.bool,

  /** filter attributes to always include in the new filter, ie {hiddenGems: false} */
  always: PropTypes.object,
};

const defaultProps = {
  value: undefined,
  small: false,
  always: {},
};

/**
 * A single split option button.
 *
 * @param {object} filter
 */
const SimpleSplitOption = (props) => {
  const { filter, setFilter, property, value, label, always, small } = props;

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  // If the property is an array, allow the user to select multiple values.
  if (Array.isArray(filter[property])) {
    const valueInd = filter[property].indexOf(value);
    return (
      <VBButton
        size={small ? 'sm' : 'med'}
        type={valueInd > -1 ? 'full' : 'border'}
        onClick={() => {
          sendAnalyticsEvent(`vb_explore_splits_${property.toLowerCase()}_${value.toLowerCase()}`);
          const newPropValue = [...filter[property]];
          if (valueInd > -1) {
            newPropValue.splice(valueInd, 1);
          } else {
            newPropValue.push(value);
          }
          setFilter({ ...filter, ...always, [property]: newPropValue });
        }}
      >
        {label}
      </VBButton>
    );
  }

  if (filter[property] !== value) {
    return (
      <VBButton
        size={small ? 'sm' : 'med'}
        type="border"
        onClick={() => {
          sendAnalyticsEvent(`vb_explore_splits_${property.toLowerCase()}_${value.toLowerCase()}`);
          setFilter({ ...filter, ...always, [property]: value });
        }}
      >
        {label}
      </VBButton>
    );
  }
  return (
    <VBButton size={small ? 'sm' : 'med'} type="full">
      {label}
    </VBButton>
  );
};
export default SimpleSplitOption;

SimpleSplitOption.propTypes = propTypes;
SimpleSplitOption.defaultProps = defaultProps;
