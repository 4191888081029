import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useVbContext from '../../hooks/vb-context';
import APIConfig from '../../config/api';
import DropdownList from '../DropdownList/DropdownList';

const propTypes = {
  filter: PropTypes.string,
  isFilterMode: PropTypes.bool,
};

const defaultValues = {
  isFilterMode: false,
};

const DummyUsersDropdownList = ({ filter, isFilterMode, onSelect, ...props }) => {
  const { vbRequest, currentUser } = useVbContext();

  const [dummyUsers, setDummyUsers] = useState({ list: [], isLoading: false });

  const users = useMemo(
    () => dummyUsers.list.concat({ id: currentUser.id, username: currentUser.username, avatar: currentUser.avatar }),
    [currentUser, dummyUsers.list]
  );

  const options = useMemo(() => users.map((user) => ({ value: user.id, label: user.username })), [users]);

  const filteredOptions = useMemo(() => {
    if (!filter || !isFilterMode) {
      return options;
    }

    return options.filter((option) => option.label.includes(filter));
  }, [filter, isFilterMode, options]);

  const handleSelect = useCallback(
    (option) => onSelect?.(users.find((user) => user.id === option.value)),
    [onSelect, users]
  );

  useEffect(() => {
    setDummyUsers((value) => ({ ...value, isLoading: true }));

    vbRequest(`${APIConfig.NAMESPACE.DUMMY_USER}/random`).then((response) => {
      setDummyUsers({ list: response.dummyUsers, isLoading: false });
    });
  }, [vbRequest]);

  return <DropdownList options={filteredOptions} isLoading={dummyUsers.isLoading} onSelect={handleSelect} {...props} />;
};

DummyUsersDropdownList.propTypes = propTypes;
DummyUsersDropdownList.defaultValues = defaultValues;

export default DummyUsersDropdownList;
