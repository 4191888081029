import PropTypes from 'prop-types';
import Article from '../../../../../../../shared-react/src/components/Article/Article';

const propTypes = {
  item: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  exploreLinkText: PropTypes.string,
};

const RenderedArticle = ({ item, isLoading, exploreLinkText }) => {
  return <Article item={item} isLoading={isLoading} exploreLinkText={exploreLinkText} hideReadMore />;
};

RenderedArticle.propTypes = propTypes;

export default RenderedArticle;
