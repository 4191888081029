import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import MaxLengths from '../../../config-json/content-lengths.json';
import APIConfig from '../../config/api';
import { mergeClassNames } from '../../util/props';
import VBCheckbox from '../VBCheckbox/VBCheckbox';
import VBPhotoUpload from '../VBPhotoUpload/VBPhotoUpload';
import VBTextArea from '../VBTextArea/VBTextArea';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import { uppercaseFirst } from '../../util/string';
import VBSpinnerButton from '../VBSpinnerButton/VBSpinnerButton';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import VBHeading from '../VBHeading/VBHeading';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import { isKids } from '../../util/sites';
import EmojiSelector from '../EmojiSelector/EmojiSelector';
import Author from './Author/Author';

import styles from './ReviewCreate.module.scss';

const propTypes = {
  /** "venue" or "itinerary" */
  parentType: PropTypes.string.isRequired,

  /** ID of the parent content */
  parentId: PropTypes.number.isRequired,

  /** name of the parent */
  parentName: PropTypes.string.isRequired,

  popupView: PropTypes.bool,

  onReviewCreated: PropTypes.func,
};

const defaultProps = {
  onReviewCreated: undefined,
  popupView: false,
};

/**
 * Component for creating reviews.
 */
const ReviewCreate = ({ parentType, parentId, parentName, popupView, onReviewCreated }) => {
  // The emoji that is currently selected.
  const [emojiSelected, setEmojiSelected] = useState(null);

  // Whether or not the review form is open.
  const [reviewFormOpen, setReviewFormOpen] = useState(popupView);

  // Two way binding for the include by checkmark.
  const [includeBy, setIncludeBy] = useState(true);

  // Currently selected media.
  const [media, setMedia] = useState(null);

  // The content of the textarea.
  const [content, setContent] = useState('');

  // Whether a new review is being made.
  const [loading, setLoading] = useState(false);

  const { lteMd, gteLg } = useVBBreakpoint();

  const { currentUser, vbRequest, raiseToast, pointsToast, openAuthPopup, vbBlue } = useContext(VBReactContext);

  const [author, setAuthor] = useState(currentUser);

  /**
   * Handle change in value of the content textarea.
   * @param {SyntheticEvent} event
   */
  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  /**
   * Handle when a user clicks on the emoji.
   * @param {number} emoji the emoji index
   */
  const handleEmojiClick = (emoji) => {
    if (!currentUser) {
      openAuthPopup();
      return;
    }

    setEmojiSelected(emoji !== emojiSelected ? emoji : null);

    if (!popupView) {
      setReviewFormOpen(emoji !== emojiSelected);
    }
  };

  /**
   * Creates a new review, based on the inputs state.
   */
  const createReview = () => {
    if (loading) {
      return;
    }

    /**
     * Post a piece of media.
     * @returns {Promise<number>} resolves a media collection ID
     */
    const postMediaRequest = () => {
      return new Promise((resolve, reject) => {
        const submitData = new FormData();

        submitData.append('file', media[0]);
        submitData.append('title', parentName);
        submitData.append('caption', content);
        submitData.append('author_id', author.id);

        vbRequest(`${APIConfig.NAMESPACE.MEDIA}/add`, {
          method: 'POST',
          contentType: false,
          body: submitData,
        })
          .then(({ collection_id: collectionId }) => resolve(collectionId))
          .catch(reject);
      });
    };

    /**
     * Post the content of the review.
     * @returns {object} resolves the server response
     */
    const postContentRequest = (collectionId) => {
      return new Promise((resolve, reject) => {
        const postContent = {
          content,
          emoji: emojiSelected + 1,
          author_id: author.id,
        };

        if (collectionId) {
          postContent.media_collection_id = collectionId;
        }

        vbRequest(`${APIConfig.NAMESPACE.REVIEW}/${parentType === 'venue' ? 'venues' : 'itineraries'}/${parentId}`, {
          method: 'POST',
          body: postContent,
        })
          .then((data) => {
            // dispatch(
            //   invalidateList(
            //     ContentTypes.review.type,
            //     (ctx, f) => ctx === `${parentType}-expert-reviews:${parentId}` && f.userId === currentUser.id.toString()
            //   )
            // );

            if (author.id === currentUser?.id) {
              pointsToast(data.expert_points, 'leaving a review');
            }

            resolve(data);
          })
          .catch(reject);
      });
    };

    /**
     * Called when the process of submitting a review has finished, either with a success or error.
     * @param {Error} err
     */
    const finishRequest = (err, data) => {
      if (!err) {
        if (!popupView) {
          setContent('');
          setMedia(null);
          setIncludeBy(true);
          setEmojiSelected(null);
          setReviewFormOpen(false);
        }

        if (onReviewCreated) {
          onReviewCreated({ id: data.reviewId, content, emojiRating: emojiSelected + 1 });
        }

        raiseToast('Successfully posted!', 'Review');
      } else {
        raiseToast(uppercaseFirst(err.message), 'Error', 'warning');
      }
      setLoading(false);
    };

    setLoading(true);

    // Depending on whether or not there is media to upload, either post the media and then the content, or just post
    // the content.
    if (media?.length) {
      postMediaRequest()
        .then((collectionId) => {
          return postContentRequest(collectionId);
        })
        .then((data) => finishRequest(null, { reviewId: data.review_id }))
        .catch((err) => finishRequest(err));
    } else {
      postContentRequest()
        .then((data) => finishRequest(null, { reviewId: data.review_id }))
        .catch((err) => finishRequest(err));
    }
  };

  const submit = (
    <div className={styles.submitContainer}>
      <VBSpinnerButton type="full" size="med" content="Share My Opinion" onClick={createReview} isSpinning={loading} />
    </div>
  );

  return (
    <MaxWidthContainer padded>
      <div className={mergeClassNames(styles.container, lteMd ? styles.lteMdBreakpoint : undefined)}>
        <VBHeading size="sm">Leave a Review</VBHeading>
        <div className={styles.myExperience}>
          My experience was:
          <span style={{ width: '10px', display: 'inline-block' }} />
          <EmojiSelector
            selected={emojiSelected}
            onSelect={handleEmojiClick}
            color={isKids() ? undefined : vbBlue}
            className={styles.emojiSelector}
            size={35}
          />
        </div>
        {reviewFormOpen && currentUser ? (
          <div className={styles.reviewForm}>
            <div className={styles.left}>
              <VBTextArea
                className={styles.content}
                placeholder="In my opinion ..."
                maxLength={MaxLengths.REVIEW}
                minRows={4}
                border="grey"
                grow
                value={content}
                onChange={handleContentChange}
                disabled={loading}
              />
              <div className={styles.includeBy}>
                <VBCheckbox
                  checked={includeBy}
                  onChange={(checked) => setIncludeBy(checked)}
                  className={styles.checkbox}
                  disabled={loading}
                />
                Include: by <Author author={author} onSelect={setAuthor} />
              </div>
              {gteLg ? submit : null}
            </div>
            <div className={styles.right}>
              <VBPhotoUpload onUpload={(file) => setMedia(file)} disabled={loading} theme="subdued" />
            </div>
            {lteMd ? submit : null}
          </div>
        ) : null}
      </div>
    </MaxWidthContainer>
  );
};

ReviewCreate.propTypes = propTypes;
ReviewCreate.defaultProps = defaultProps;

export default ReviewCreate;
