/* eslint-disable react/no-multi-comp */
import React from 'react';
import VBTab from '../../components/VBTab/VBTab';
import useVbContext from '../../hooks/vb-context';
import { mergeClassNames } from '../../util/props';
import styles from '../../components/Header/Header.module.scss';
import Pages from '../pages';
import { useIsOnAnyOfPages, useIsOnPage } from '../../hooks/page';
import OnClickLink from '../../components/OnClickLink/OnClickLink';
import VBButton from '../../components/VBButton/VBButton';
import Branding from '../../config/branding';
import { useHasUnreadActivity } from '../../hooks/unread-activity';
import { matchPath, useLocation } from 'react-router';
import PlusIcon from '../../../assets/images/icons/glyphs/add-post.svg';

export const PostTab = ({ isHamburger }) => {
  const { openMakePostPopup } = useVbContext();
  return (
    <div role="button" className={mergeClassNames(styles.createPost, styles.noUnderline)} onClick={openMakePostPopup}>
      <img alt="" className={styles.createPostIcon} src={PlusIcon} />
      <div>Post</div>
    </div>
  );
};

export const HallOfFameTab = ({ isHamburger }) => {
  const onHallOfFame = useIsOnPage(Pages.HALL_OF_FAME);
  return (
    <VBTab to="/hall-of-fame" isActive={onHallOfFame} linkStyle={isHamburger}>
      Hall of Fame
    </VBTab>
  );
};

export const ExploreTab = ({ isHamburger }) => {
  const onExplore = useIsOnAnyOfPages(Pages.EXPLORE_PAGES);
  return <VBTab to="/location" content="Explore" isActive={onExplore} shouldResetScroll linkStyle={isHamburger} />;
};

export const ActivityTab = ({ isHamburger }) => {
  const onActivity = useIsOnPage(Pages.ACTIVITY);
  return <VBTab to="/activity" content="Activity" isActive={onActivity} shouldResetScroll linkStyle={isHamburger} />;
};

export const HomeTab = ({ isHamburger }) => {
  const onHome = useIsOnPage(Pages.HOME);
  return <VBTab to="/" content="Home" isActive={onHome} shouldResetScroll linkStyle={isHamburger} />;
};
export const VideoTab = ({ isHamburger }) => {
  const onVideo = useIsOnPage(Pages.VIDEOS);
  return <VBTab to="/videos" content="Videos" isActive={onVideo} shouldResetScroll linkStyle={isHamburger} />;
};
export const BlogTab = ({ isHamburger }) => {
  const onBlog = useIsOnPage(Pages.BLOG);
  return <VBTab to="/blog" content="Blog" isActive={onBlog} shouldResetScroll linkStyle={isHamburger} />;
};
export const GameHangmanTab = ({ isHamburger }) => {
  const onHangman = useIsOnPage(Pages.GAME_HANGMAN);
  return <VBTab to="/games/hangman" content="Travel 22" isActive={onHangman} shouldResetScroll linkStyle={isHamburger} />;
};
export const LoginTab = () => {
  const { openAuthPopup } = useVbContext();
  return (
    <OnClickLink
      onClick={() => openAuthPopup('signIn')}
      content="Login"
      className={mergeClassNames(styles.onClick, styles.noUnderline)}
    />
  );
};

export const SignUpTab = ({ isHamburger }) => {
  const { openAuthPopup } = useVbContext();
  // Using button style always because I think it makes the "Join Now" button stand out more in both
  // the header and the hamburger menu.
  const useButtonStyle = true;
  return (
    <OnClickLink className={styles.noUnderline} onClick={() => openAuthPopup()}>
      {isHamburger ? (
        <>Join {Branding.SITE_NAME_ABV}, it&apos;s free</>
      ) : (
        <VBButton size="med" className={styles.noUnderline} style={{ fontSize: '14px', fontWeight: 700 }}>
          Join {Branding.SITE_NAME_ABV}, it&apos;s free
        </VBButton>
      )}
    </OnClickLink>
  );
};

export const NotificationsTab = ({ isHamburger }) => {
  const hasUnreadActivity = useHasUnreadActivity();
  return (
    <VBTab
      to="/notifications"
      content="Notifications"
      className={hasUnreadActivity ? styles.unreadActivity : null}
      shouldResetScroll
      linkStyle={isHamburger}
    />
  );
};

export const MyProfileTab = ({ isHamburger }) => {
  const { currentUser } = useVbContext();
  const location = useLocation();
  const onMyProfile = matchPath(location.pathname, { path: `/user/${currentUser.username}` })?.isExact;
  return <VBTab to="/user" content="My Profile" isActive={onMyProfile} shouldResetScroll linkStyle={isHamburger} />;
};
