import { PopupAuthPage } from '../../../../shared-react/src/util/types';
import { CLOSE_POPUP } from '../../../../shared-react/src/store/types';
import { OPEN_POPUP } from '../../../../shared-react/src/store/types';
/**
 * Action dispatchers dealing with the auth token field of the store.
 */

/**
 * Closes the auth popup.
 *
 * @param {string} token the auth token
 *
 * @returns the action dispatcher for setting the auth token
 */
export const closeAuthPopup = () => {
  return (dispatch) => dispatch({ type: CLOSE_POPUP });
};

/**
 * Action dispatcher to clear the auth token. Use this when the user logs out.
 *
 * @param {PopupAuthPage} type
 *
 * @returns the action dispatcher for clearing the auth token
 */
export const openAuthPopup = (type = PopupAuthPage.SIGN_UP) => {
  return (dispatch) =>
    dispatch({
      type: OPEN_POPUP,
      payload: type,
    });
};
