import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './MapVenueCard.module.scss';
import { PropTypesVBVenue } from '../../util/types';
import VBButton from '../VBButton/VBButton';
import VenueCard from '../VenueCard/VenueCard';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { ContentTypes } from '../../config/content-types';
import useContentSingle from '../../hooks/content-single';
import APIConfig from '../../config/api';

const propTypes = {
  /** venue to render */
  venue: PropTypesVBVenue.isRequired,

  /** callback for when the next button is clicked, or leave undefined for no next button to render */
  onClickNext: PropTypes.func,

  /** callback for when the previous button is clicked, or leave undefined for no previous button to render */
  onClickPrevious: PropTypes.func,

  /**
   * whether or not small style should be used. The current use case for this is when the card is rendered while the
   * map is not fullscreened.
   */
  isSmall: PropTypes.bool,
};

/**
 * A venue card on the map. Wraps VenueCard. Used by buildRenderItemVenue.
 */
const MapVenueCard = ({ venue, onClickPrevious, onClickNext, isSmall: parentIsSmall, ...other }) => {
  const { lteSm } = useVBBreakpoint();
  const isSmall = Boolean(parentIsSmall || lteSm);

  // Get the venue details.
  const { id: venueId, name: venueName } = venue;
  const { countrySlug: venueCountry, regionSlug: venueRegion, citySlug: venueCity } = venue.city;

  const detailsEndpoint = useMemo(() => {
    const hasVenueId = Boolean(venueId);

    if (hasVenueId) {
      return `${APIConfig.NAMESPACE.VENUE}/${venueId}/details`;
    }

    const hasCityDetails = venueCountry && venueRegion && venueCity;

    if (hasCityDetails) {
      return `${APIConfig.NAMESPACE.VENUE}/${venueCountry}/${venueRegion}/${venueCity}/${venueName}/details`;
    }
  }, [venueCity, venueCountry, venueId, venueName, venueRegion]);

  const getQueryAddress = useMemo(() => {
    const hasDetailsEndpoint = Boolean(detailsEndpoint);

    return hasDetailsEndpoint ? () => detailsEndpoint : null;
  }, [detailsEndpoint]);

  const findContent = useCallback(
    (content) => {
      return content.permalink?.includes(`/${venueCountry}/${venueRegion}/${venueCity}/${venueName}`);
    },
    [venueCity, venueCountry, venueName, venueRegion]
  );

  const shouldFetchContent = useCallback((existingContent) => {
    return typeof existingContent?.content?.hasFeaturedImage === 'undefined';
  }, []);

  useContentSingle({
    getQueryAddress,
    shouldFetchContent,
    find: findContent,
    type: ContentTypes.venue.type,
  });

  const buttonSize = parentIsSmall ? 'sm' : 'med';
  let imgHeight;
  if (isSmall) {
    imgHeight = '10rem';
    if (parentIsSmall) {
      imgHeight = '6rem';
    }
  }

  return (
    <div className={styles.container}>
      <VenueCard
        venue={venue}
        horizontal={isSmall}
        lessActionButtons={isSmall}
        imgHeight={imgHeight}
        style={parentIsSmall ? { padding: 0 } : null}
        cardMetaStyle={parentIsSmall ? { padding: '0 0.5rem' } : null}
        className={isSmall ? styles.cardOverride : null}
        {...other}
      />
      {onClickPrevious || onClickNext ? (
        <div className={styles.bottom}>
          {!!onClickPrevious && (
            <VBButton content=" < Previous" size={buttonSize} type="full" onClick={onClickPrevious} />
          )}
          {!!onClickNext && <VBButton content="Next > " size={buttonSize} type="full" onClick={onClickNext} />}
        </div>
      ) : null}
    </div>
  );
};

export default MapVenueCard;

MapVenueCard.propTypes = propTypes;
