import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderVisibility, setHeaderVisibilityMode } from '../store/actions/headerActions';
import { HeaderVisibilityMode } from '../store/reducers/headerReducer';
import useVisibleOnUpscroll from './visible-on-upscroll';

/**
 * Basic hook for hiding the header on scroll with default settings.
 *
 * @param {boolean} disabled if disabled, the header will always be visible
 */
export function useHideHeaderManuallyOnScroll(disabled = false) {
  const isVisible = useVisibleOnUpscroll({});
  useHideHeaderManually(isVisible || disabled);
}

/**
 * Handles the hiding of the header in the manual mode
 *
 * @param {boolean} isVisible whether the header should be made visible or not
 */
export function useHideHeaderManually(isVisible) {
  const { isHamburgerTransitioning } = useSelector((state) => state.header);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderVisibility(isVisible));
  }, [dispatch, isVisible, isHamburgerTransitioning]);

  useEffect(() => {
    dispatch(setHeaderVisibilityMode(HeaderVisibilityMode.MANUAL));

    return () => dispatch(setHeaderVisibilityMode(HeaderVisibilityMode.AUTO));
  }, [dispatch]);
}
