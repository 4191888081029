import PopupAuth from '../../../../../shared-react/src/components/PopupAuth/PopupAuth';
import { PropTypesAuthPopupPages } from '../../../../../shared-react/src/util/types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import QueryString from 'query-string';
import { useHistory } from 'react-router';
import { vbRequest } from '../../../util/api';
import { closeAuthPopup } from '../../../store/actions/authPopupOpenActions';
import useSetToken from '../../../hooks/set-token';

const propTypes = {
  page: PropTypesAuthPopupPages,
};
const defaultProps = {
  page: null,
};

function PopupAuthWrap({ page }) {
  const dispatch = useDispatch();
  const setToken = useSetToken();
  const history = useHistory();

  const handleSocialRegister = ({ token }) => {
    const params = QueryString.parse(window.location.search.slice(1));
    const newParams = { ...params };
    delete newParams.slogin;
    delete newParams.token;
    history.replace(
      window.location.pathname + (Object.keys(newParams).length ? `?${QueryString.stringify(newParams)}` : '')
    );
    setToken(token);
    dispatch(closeAuthPopup());
  };

  const handleLogin = (body, params) => {
    return vbRequest('jwt-auth/v1/token', { method: 'POST', body, params }).then(({ token }) => {
      setToken(token);
      dispatch(closeAuthPopup());
    });
  };

  return <PopupAuth page={page} handleSocialRegister={handleSocialRegister} handleLogin={handleLogin} />;
}

PopupAuthWrap.propTypes = propTypes;
PopupAuthWrap.defaultProps = defaultProps;

export default PopupAuthWrap;
