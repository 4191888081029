import { useContext } from 'react';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';

/**
 * useContext(VBReactContext)
 */
const useVbContext = () => {
  return useContext(VBReactContext);
};

export default useVbContext;
