import { HAS_UNREAD_NOTIFICATION, READ_NOTIFICATIONS } from '../types';

const notificationsReducer = (state = { hasUnreadActivity: false }, action) => {
  const { payload, type: actionType } = action;
  switch (actionType) {
    case HAS_UNREAD_NOTIFICATION:
      return { ...state, hasUnreadActivity: payload.hasUnreadActivity };
    case READ_NOTIFICATIONS:
      return { ...state, hasUnreadActivity: false };
    default:
  }
  return state;
};

export default notificationsReducer;
