import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewCreate from '../ReviewCreate/ReviewCreate';
import { PropTypesVBVenue } from '../../util/types';
import useVbContext from '../../hooks/vb-context';
import { ContentTypes } from '../../config/content-types';
import { addItem, invalidateList, updateItem } from '../../store/actions/apiActions';

const propTypes = {
  venue: PropTypesVBVenue,
  popupView: PropTypes.bool,
  onReviewCreated: PropTypes.func,
};

const defaultProps = {
  popupView: false,
};

const VenueReviewCreate = ({ venue, popupView, onReviewCreated }) => {
  const dispatch = useDispatch();
  const { currentUser } = useVbContext();

  const handleReviewCreated = (data) => {
    dispatch(addItem(ContentTypes.review.type, data, data.reviewId));

    dispatch(
      updateItem(venue.id, ContentTypes.venue.type, {
        reviewsCount: Number(venue.reviewsCount) + 1,
        userReview: data.id,
      })
    );

    dispatch(invalidateList(ContentTypes.review.type, (ctx) => ctx === `venue-reviews:${venue.id}`));
    dispatch(invalidateList(ContentTypes.reviewOrSnippet.type, (ctx) => ctx === `reviews-snippets:${currentUser.id}`));

    onReviewCreated?.();
  };

  return (
    <ReviewCreate
      parentType="venue"
      parentId={venue.id}
      parentName={venue.name}
      onReviewCreated={handleReviewCreated}
      popupView={popupView}
    />
  );
};

VenueReviewCreate.propTypes = propTypes;
VenueReviewCreate.defaultProps = defaultProps;

export default VenueReviewCreate;
