import { getKey, merge } from '../../../../util/content';
import {
  ITEMS_RECEIVED,
  SINGLE_REQUESTED,
  SINGLE_RECEIVED,
  SINGLE_STORE,
  ITEMS_STORE,
  UPDATE_ITEM,
  ADD_ITEM,
  ADD_TO,
  ITEMS_REFRESHED,
  SINGLE_FAILED,
  REMOVE_ITEM,
} from '../../../types';
import { ContentTypes } from '../../../../config/content-types';
import { deepCloneSerializable } from '../../../../util/objects';

/**
 * Reducer for contentFactoryData.content.
 *
 * @param {object} state the current items object
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx
 * @param {string} action.payload.type the type of the content. See content-types.jsx
 *
 * @returns {object} the new items object
 */
const typeReducer = (state = {}, action) => {
  if (!action.payload) return state;
  const { type, payload } = action;

  switch (type) {
    case SINGLE_REQUESTED: {
      const newState = { ...state };
      const { id } = payload;
      newState[id] = { content: newState[id]?.content, loading: true, error: null };
      return newState;
    }
    case SINGLE_FAILED: {
      const newState = { ...state };
      const { id, error } = payload;
      newState[id] = { content: newState[id]?.content, loading: false, error };
      return newState;
    }
    case ADD_ITEM:
    case UPDATE_ITEM: {
      const { id, content } = payload;
      let add = false;
      if (!state[id]) {
        if (type === UPDATE_ITEM) {
          console.warn(`tried to update an item that does not exist, ${payload.type} ${id}`);
          return state;
        } else {
          add = true;
        }
      }
      let newContent;
      if (typeof content !== 'function') {
        newContent = { ...state[id]?.content, ...content };
      } else {
        newContent = content(state[id]?.content);
      }
      if (!add) return { ...state, [id]: { ...state[id], content: newContent } };
      return { ...state, [id]: { content: newContent, loading: false, error: null } };
    }
    case REMOVE_ITEM: {
      const { id } = payload;
      if (!state[id]) {
        console.warn(`tried to remove an item that does not exist, ${payload.type} ${id}`);
        return state;
      }
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case SINGLE_STORE:
    case ITEMS_STORE:
    case SINGLE_RECEIVED:
    case ADD_TO:
    case ITEMS_RECEIVED:
    case ITEMS_REFRESHED: {
      const newState = { ...state };
      let { items } = payload;
      const { addItems, type: itemType } = payload;
      if (type === SINGLE_RECEIVED || type === SINGLE_STORE || type === ADD_TO) {
        items = [{ item: payload.content }];
      }
      items.forEach(({ item }) => {
        const key = getKey(item, itemType);

        const itemCopy = deepCloneSerializable(item);
        const { references } = ContentTypes[itemType];
        if (references) {
          references.forEach(({ path, arrayOf, type: refContentType }) => {
            let ind = 0;
            let cur = itemCopy;
            let prev = null;
            while (cur !== null && cur !== undefined && ind < path.length) {
              prev = cur;
              cur = cur[path[ind++]];
            }
            if (cur) {
              if (arrayOf) {
                if (!Array.isArray(cur)) {
                  throw Error(`reference with arrayOf true is not an array: ${JSON.stringify(cur)}`);
                }
                if (cur.length) {
                  addItems(
                    refContentType,
                    cur.map((x) => ({ item: x }))
                  );
                  prev[path[ind - 1]] = cur.map((x) => {
                    return getKey(x, refContentType);
                  });
                }
              } else {
                addItems(refContentType, [{ item: cur }]);
                prev[path[ind - 1]] = getKey(cur, refContentType);
              }
            }
          });
        }
        if (newState[key]) {
          newState[key] = { content: merge(newState[key].content, itemCopy, itemType), loading: false, error: null };
        } else {
          newState[key] = { content: itemCopy, loading: false, error: null };
        }
      });
      return newState;
    }
    default:
  }

  return state;
};

export default typeReducer;
