import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import styles from './VBAlert.module.scss';

const propTypes = {
  variant: PropTypes.oneOf(['danger', 'success']),
  // Content to be displayed in the notice
  content: PropTypes.node,
  // Content to be displayed in the notice
  children: PropTypes.node,
  // additional classNames
  className: PropTypes.string,
};
const defaultProps = {
  variant: 'success',
  content: undefined,
  children: undefined,
  className: undefined,
};

/**
 * The notice will not display if there is no content.
 */
const VBAlert = (props) => {
  const { variant, content, children, className } = props;

  return (
    <>
      {(content || children) && (
        <Alert className={mergeClassNames(styles[variant], className)} variant={variant}>
          {children ?? content}
        </Alert>
      )}
    </>
  );
};

VBAlert.propTypes = propTypes;
VBAlert.defaultProps = defaultProps;

export default VBAlert;
