import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './PopupUploadFeaturedImage.module.scss';
import UploadPhotoSelector from '../../../UploadPhotoSelector/UploadPhotoSelector';
import useVbContext from '../../../../hooks/vb-context';
import APIConfig from '../../../../config/api';
import { useClosePopup } from '../../../../hooks/popup';
import VBButton from '../../../VBButton/VBButton';
import { updateItem, invalidateList } from '../../../../store/actions/apiActions';
import { ContentTypes } from '../../../../config/content-types';

const propTypes = {
  /** ID of the venue to update the featured image for */
  venueId: PropTypes.number.isRequired,
};

/**
 * Popup used by IconSetFeaturedImage to upload/set a new featured image for a venue.
 */
const PopupUploadFeaturedImage = ({ venueId }) => {
  // Is the upload/submit/get-image-size loading?
  const [isLoading, setIsLoading] = useState(false);

  // Base64 data for the image (once an image has been uploaded).
  const [imageSrc, setImageSrc] = useState();

  // ID of the media collection on the server (once an image has been uploaded).
  const [mediaCollectionId, setMediaCollectionId] = useState();

  // Image {width, height} (once an image has been uploaded).
  const [imageDim, setImageDim] = useState();

  const { errorToast, raiseToast, vbRequest } = useVbContext();
  const closePopup = useClosePopup();
  const dispatch = useDispatch();

  /**
   * Handle a successful image upload. At this point, the media collection will already exist on the server.
   */
  const handleUpload = useCallback(({ src: data, mediaCollectionId: mcId }) => {
    // Load into an Image object to get dimensions.
    const img = new Image();
    img.src = data;
    img.onload = () => {
      // Once the image object has loaded, update the state data to represent a successful upload.
      setIsLoading(false);
      setImageSrc(data);
      setMediaCollectionId(mcId);
      setImageDim({ width: img.width, height: img.height });
    };
  }, []);

  /**
   * Handle popup submission.
   */
  const handleSubmit = () => {
    setIsLoading(true);
    vbRequest(`${APIConfig.NAMESPACE.VENUE}/venues/${venueId}/featured-image`, {
      params: { media_collection_id: mediaCollectionId },
      method: 'POST',
    })
      .then(() => {
        // Live update. Could be more picky with what post lists we are invalidating, but this is an admin widget so who
        // cares?
        dispatch(updateItem(venueId, ContentTypes.venue.type, { featuredImage: imageSrc }));
        dispatch(invalidateList(ContentTypes.post.type, () => true));

        setIsLoading(false);
        closePopup();
        raiseToast('Success!', 'Update Featured Image');
      })
      .catch((err) => errorToast(err, 'Update Featured Image'));
  };

  return (
    <div className={styles.container}>
      <p className={styles.about}>
        This popup is only accessible to admin and contributors. Uploading an image using this popup will do two things.
        First, it will create a new dummy post with the image you select. This post will be attributed to a random dummy
        user. Next, that dummy post will be selected as the new featured image for the venue. The post that the original
        featured image was sourced from will not be deleted.
      </p>
      <UploadPhotoSelector
        src={imageSrc}
        onUploadStart={useCallback(() => setIsLoading(true), [])}
        onUpload={handleUpload}
        disabled={isLoading}
      />
      {imageDim ? (
        <div className={styles.imageDim}>
          <strong>Image Size:</strong>
          {`${imageDim.width}x${imageDim.height}`}
        </div>
      ) : null}
      <div className={styles.bottom}>
        {!isLoading && <VBButton type="full" size="med" content="Update Featured Image" onClick={handleSubmit} />}
      </div>
    </div>
  );
};
export default PopupUploadFeaturedImage;

PopupUploadFeaturedImage.propTypes = propTypes;
