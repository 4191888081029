import React from 'react';
import PropTypes from 'prop-types';
import VBButton from '../VBButton/VBButton';
import styles from './BubbleSelector.module.scss';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  /** currently selected value */
  value: PropTypes.any.isRequired,

  /** callback for when the value changes */
  onChange: PropTypes.func.isRequired,

  /** array of mutually exclusive options. Label will be the text on the buttons. Value is the internally used value
   * use in the value and onChange props.
   */
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.any.isRequired }))
    .isRequired,
};

/**
 * Use this for mobile filters on the explore page. This is a general purpose component, when the filter has mutually
 * exclusive values.
 */
const BubbleSelector = ({ value, onChange, options, fullWidth, analyticsLabel }) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const buttons = options.map((option) => (
    <VBButton
      content={option.label}
      onClick={() => {
        if (analyticsLabel) sendAnalyticsEvent(`vb_explore_filter_${analyticsLabel}_${option.value}`);
        onChange(option.value);
      }}
      size="med"
      type={value === option.value ? 'full' : 'border'}
      key={option.value}
      className={fullWidth ? styles.fullWidth : null}
    />
  ));

  return <div className={styles.container}>{buttons}</div>;
};

export default BubbleSelector;
BubbleSelector.propTypes = propTypes;
