import React from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import PropTypes from 'prop-types';
import styles from './AnimateFromBottom.module.scss';
import useVbContext from '../../hooks/vb-context';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../util/props';
import { MOBILE_FOOTER_HEIGHT } from '../../config/mobile-footer';
import { isUndefined } from '../../util/objects';

const propTypes = {
  /* distance from the bottom of the screen the component should display at when visible. Use a css string.
  ex. "CALC(2rem + 25px)" */
  bottomDistance: PropTypes.string,
  /** bottomDistance override for small (lteSm) resolutions */
  bottomDistanceMobile: PropTypes.string,
  /* time in seconds the animation should take to complete. */
  animationDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /* whether or not the component should be visible. */
  isVisible: PropTypes.bool,
  /* the children of the component that will be animated. */
  children: PropTypes.node.isRequired,
  /** dont center the content? */
  noCenter: PropTypes.bool,
  /** additional class names */
  className: PropTypes.string,
};
const defaultProps = {
  bottomDistance: '8rem',
  animationDuration: 0.5,
};

/**
 * AnimateFromBottom is a component that animates its children from the bottom of the screen.
 * See mobile Explore page for an example (Top 25 and Trending VBSwitch)
 */
function AnimateFromBottom({
  bottomDistance,
  bottomDistanceMobile,
  animationDuration,
  isVisible,
  children,
  noCenter,
  className,
}) {
  const { ref: switchContainerRef, height: containerHeight } = useResizeObserver();
  const { currentUser } = useVbContext();
  const { lteXs, lteSm } = useVBBreakpoint();

  const mobileFooterPadding = lteXs && currentUser ? MOBILE_FOOTER_HEIGHT : 0;

  const dist = lteSm && !isUndefined(bottomDistanceMobile) ? bottomDistanceMobile : bottomDistance;

  return (
    <div
      className={mergeClassNames(styles.sectionSelector, noCenter ? null : styles.center, className)}
      style={{
        bottom: `CALC(${dist} + ${mobileFooterPadding}px)`,
        transitionDuration: `${animationDuration}s`,
        transform: `translateY(${isVisible ? 0 : `CALC(${dist} + ${containerHeight + mobileFooterPadding}px)`})`,
      }}
      ref={switchContainerRef}
    >
      <div className={styles.component}>{children}</div>
    </div>
  );
}

AnimateFromBottom.propTypes = propTypes;
AnimateFromBottom.defaultProps = defaultProps;

export default AnimateFromBottom;
