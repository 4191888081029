import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import Replies from '../../../../../../shared-react/src/components/Comments/Comment/Replies/Replies';
import { updateItem } from '../../../../../../shared-react/src/store/actions/apiActions';
import { useContentFactory } from '../../../../../../shared-react/src/hooks/content-factory';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { vbRequest } from '../../../../util/api';
import { errorToast, pointsToast } from '../../../../util/toast';
import Report from '../../../../../../shared-react/src/components/Report/Report';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';

const propTypes = {
  /** ID of the comment to get replies for */
  commentId: PropTypes.number.isRequired,

  /** number of replies in total, from parent */
  replyCount: PropTypes.number.isRequired,

  /** max length of the reply field */
  maxLength: PropTypes.number,
};
const defaultProps = {
  maxLength: undefined,
};

/**
 * Used by TFComments, wrapper for @vb/vb-react/replies.
 *
 * @param {object} props
 */
const TFReplies = ({ commentId, maxLength, replyCount }) => {
  const dispatch = useDispatch();

  const { brandingConfig } = useVbContext();
  const {
    ICONS: { RIGHT_ARROW_FILLED: AddIcon },
  } = brandingConfig;

  // Content factory hook.
  const {
    loadMore,
    noMore,
    loading: replyQueryLoading,
    content,
    reload,
  } = useContentFactory({
    getQueryAddress: (offset) => `${APIConfig.NAMESPACE.COMMENT}/comments/${commentId}/replies?offset=${offset}`,
    context: `comment-replies:${commentId}`,
    type: ContentTypes.commentReply.type,
    getItems: ({ replies }) => replies.map((item) => ({ item })),
  });

  /**
   * Handle submit of a new reply.
   *
   * @param {string} replyContent
   */
  const handleSubmit = async (replyContent) => {
    const body = {
      content: replyContent,
    };

    try {
      const response = await vbRequest(`${APIConfig.NAMESPACE.COMMENT}/comments/${commentId}/reply`, {
        method: 'POST',
        body,
      });
      pointsToast(response.expert_points, 'replying');
      dispatch(updateItem(commentId, ContentTypes.comment.type, { replyCount: replyCount + 1 }));
      reload(1);
    } catch (err) {
      errorToast(err);
      throw err;
    }
  };

  /**
   * Handle report of a reply.
   */
  const handleReport = async () => {
    dispatch(updateItem(commentId, ContentTypes.comment.type, { replyCount: replyCount - 1 }));
  };

  return (
    <Replies
      replies={content.map(({ item }) => item)}
      loading={replyQueryLoading}
      noMore={noMore}
      maxLength={maxLength}
      onRequestSubmit={handleSubmit}
      report={(reply) => (
        <Report contentType={ContentTypes.commentReply.type} contentId={reply.id} onReport={handleReport} />
      )}
      onRequestLoadMore={loadMore}
      addIcon={<img src={AddIcon} alt="Add Reply" />}
    />
  );
};
export default TFReplies;

TFReplies.propTypes = propTypes;
TFReplies.defaultProps = defaultProps;
