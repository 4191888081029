import { ADD_PROGRESSIVE_IMAGE } from '../types';

/**
 * Reducer for progressive image cache.
 *
 * @param {object} state current state
 * @param {string} action.type the type of the action
 * @param {object} action.payload the src to add
 *
 * @returns {object} the new state
 */
const progressiveImageReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_PROGRESSIVE_IMAGE: {
      const src = action.payload;
      if (state.indexOf(src) >= 0) return state;
      return [...state, src];
    }
    default:
  }
  return state;
};

export default progressiveImageReducer;
