import { useVBBreakpoint } from './vb-breakpoint';

export const useTopImageMaxRatio = () => {
  const { lteSm } = useVBBreakpoint();

  if (lteSm) {
    return 1;
  }

  return 1.61803;
};
