import React from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import styles from './OnClickLink.module.scss';

const propTypes = {
  // the content of the button. If this is not provided
  content: PropTypes.node,
  children: PropTypes.node,
  // callback for when the link is clicked
  onClick: PropTypes.func,
  // whether or not to remove the default link styles
  noStyle: PropTypes.bool,
  // whether or not to remove the underline and color change on hover
  noHoverStyle: PropTypes.bool,
  // If the cursor should have pointer when hovering on link.
  cursorPointer: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
const defaultProps = {
  content: undefined,
  children: undefined,
  onClick: undefined,
  noStyle: false,
  noHoverStyle: false,
  className: undefined,
  cursorPointer: false,
  style: {},
};

/**
 * A link that is not really a link. Instead, it is something you can attach onClick listeners to.
 */
const OnClickLink = (props) => {
  const { content, children, onClick, noStyle, noHoverStyle, className, style, cursorPointer, ...other } = props;

  /**
   * Prevent the default link action, and call the callback.
   * @param {SyntheticEvent} e the event
   */
  const handleClick = (e) => {
    e.preventDefault();
    if (onClick) onClick(e);
    return false;
  };

  const addedStyle = {
    cursor: cursorPointer ? 'pointer' : undefined,
  };

  return (
    // eslint-disable-next-line
    <a
      href="#"
      onClick={handleClick}
      style={{ ...style, ...addedStyle }}
      className={mergeClassNames(className, noStyle ? styles.reset : null, noHoverStyle ? styles.noHoverStyle : null)}
      {...other}
    >
      {content ?? children}
    </a>
  );
};

OnClickLink.propTypes = propTypes;
OnClickLink.defaultProps = defaultProps;

export default OnClickLink;
