import { useCallback, useEffect, useState } from 'react';
import APIConfig from '../config/api';
import { ContentTypes } from '../config/content-types';
import useContentSingle from './content-single';

/**
 * Will grab the location details from a country, region and city slugs. Uses
 * Redux caching under the hood for optimization.
 */
export function useLocationDetails({ country, region, city }) {
  // Get location details.
  const getQueryAddress = useCallback(
    () => `${APIConfig.NAMESPACE.SEARCH}/location/${country}/${region}/${city}/details`,
    [country, region, city]
  );
  const findContent = useCallback(
    (x) => x.countrySlug === country && x.regionSlug === region && x.citySlug === city,
    [country, region, city]
  );
  const locationDetails = useContentSingle({
    type: ContentTypes.location.type,
    find: findContent,
    getQueryAddress,
  })?.content;
  return locationDetails;
}
