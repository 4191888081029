import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitialUserLocation, setCity, setCoords } from '../store/actions/userLocationActions';
import { USER_LOCATION_LOADING_FINISHED, USER_LOCATION_LOADING } from '../store/types';
import { checkGeolocationPermission, getClosestCity, requestBrowserPosition } from '../util/location';
import useVbContext from './vb-context';

/**
 * Gets the closest city to a user (as well as the user's coordinates via city.userCoords). This can be cached data.
 */
const useClosestCity = () => {
  const { vbRequest } = useVbContext();
  const dispatch = useDispatch();

  const { city, latitude, longitude, isLoadingCity, retriever } = useSelector((state) => state.userLocation);

  const shouldFetchUserLocation = !city && !isLoadingCity;

  if (shouldFetchUserLocation) {
    dispatch(fetchInitialUserLocation(vbRequest));
  }

  const closestCity = useMemo(() => {
    if (!city) {
      return null;
    }

    return { ...city, userCoords: { latitude, longitude } };
  }, [city, latitude, longitude]);

  const requestUserLocation = useCallback(async () => {
    const { state } = await checkGeolocationPermission();

    if (state === 'denied') {
      return null;
    }

    const shouldPromptLocation = retriever !== 'browser';

    if (!shouldPromptLocation) {
      return closestCity;
    }

    dispatch({
      type: USER_LOCATION_LOADING,
    });

    const position = await requestBrowserPosition();

    if (position) {
      const coords = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      dispatch(setCoords(vbRequest, coords.latitude, coords.longitude));

      const newClosestCity = await getClosestCity(vbRequest, coords.latitude, coords.longitude);

      dispatch(setCity(newClosestCity, 'browser'));

      return {
        ...newClosestCity,
        userCoords: coords,
      };
    }

    dispatch({
      type: USER_LOCATION_LOADING_FINISHED,
    });
  }, [closestCity, dispatch, retriever, vbRequest]);

  return useMemo(
    () => ({
      requestUserLocation,
      city: closestCity,
      isLoading: isLoadingCity,
    }),
    [closestCity, isLoadingCity, requestUserLocation]
  );
};

export default useClosestCity;
