import React, { useMemo, useState, useEffect, useCallback } from 'react';
import qs, { stringify } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, matchPath } from 'react-router';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import Explore from '../../../../../../shared-react/src/components/Explore/Explore';
import { useVBBreakpoint } from '../../../../../../shared-react/src/hooks/vb-breakpoint';
import {
  buildDistanceFilterString,
  getAgeFilterLabel,
  getPriceFilterLabel,
  getRadiusFilterLabel,
} from '../../../../../../shared-react/src/util/explore';
import CityGemsSplit from '../../../../../../shared-react/src/components/CityGemsSplit/CityGemsSplit';
import {
  COMMON_DISTANCE_RANGES,
  PLAYGROUNDS_DISTANCE_RANGES,
  EVENT_TIME_FRAMES,
  SIGNED_OUT_MAX_VENUE_ITEMS,
  AGE_GROUPS,
  ExploreSubsection,
} from '../../../../../../shared-react/src/config/explore';
import useQPState from '../../../../../../shared-react/src/hooks/qp-state';
import { parseBoolParam } from '../../../../../../shared-react/src/hooks/qp-state';
import { stringifyBoolParam } from '../../../../../../shared-react/src/hooks/qp-state';
import DropdownFilterActivities from '../../../../../../shared-react/src/components/Explore/DropdownFilterActivities/DropdownFilterActivities';
import DropdownFilterTrending from '../../../../../../shared-react/src/components/Explore/DropdownFilterTrending/DropdownFilterTrending';
import { linkEqualsCity } from '../../../../../../shared-react/src/util/location';
import MetaTags from '../../../../../../shared-react/src/components/MetaTags/MetaTags';
import trimSlashes, { uppercaseFirst } from '../../../../../../shared-react/src/util/string';
import { useContentFactory } from '../../../../../../shared-react/src/hooks/content-factory';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import { isKids } from '../../../../../../shared-react/src/util/sites';
import MaxWidthContainer from '../../../../../../shared-react/src/components/MaxWidthContainer/MaxWidthContainer';
import AgesSelector from '../../../../../../shared-react/src/components/AgesSelector/AgesSelector';
import VBButton from '../../../../../../shared-react/src/components/VBButton/VBButton';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { updateLocation } from '../../../../../../shared-react/src/store/actions/globalLocationActions';
import SplitContainer from '../../../../../../shared-react/src/components/SplitContainer/SplitContainer';
import {
  EventsFilterDefaults,
  FilterDefaults as ActivitiesFilterDefaults,
  SubcategoryLabels,
  FestivalsFilterDefaults,
  ItinerariesFilterDefaults,
} from '../../../../config/search-page';
import { FilterDefaults as TrendingFilterDefaults } from '../../../../config/trending';
import { openAuthPopup } from '../../../../store/actions/authPopupOpenActions';
import RenderedVenue from './RenderedVenue/RenderedVenue';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import useGotoCityFromSlugLocation from '../../../../../../shared-react/src/hooks/goto-city-from-slug-location';
import { getBadge } from '../../../../../../shared-react/src/config/badges';
import useQueryParams from '../../../../../../shared-react/src/hooks/query-params';
import { addParams, getLocationParams } from '../../../../../../shared-react/src/util/urls';
import RadiusBubbleSelector from '../../../../../../shared-react/src/components/RadiusBubbleSelector/RadiusBubbleSelector';
import FilterSelector from '../../../../../../shared-react/src/components/FilterSelector/FilterSelector';
import { isCrawler } from '../../../../../../shared-react/src/util/env';
import useSendAnalyticsEvent from '../../../../../../shared-react/src/hooks/send-analytics-event';
import { CONTENT_TYPE } from '../../../../../../shared-react/src/config/content-factory';
import SubcategorySelector from '../../SubcategorySelector/SubcategorySelector';
import usePushPreserveScrollPosition from '../../../../../../shared-react/src/hooks/push-preserve-scroll-position';
import DropdownFilterEvents from '../../../../../../shared-react/src/components/Explore/DropdownFilterEvents/DropdownFilterEvents';
import BubbleSelector from '../../../../../../shared-react/src/components/BubbleSelector/BubbleSelector';
import { buildRenderItemEvent, buildRenderItemVenue } from '../../../../../../shared-react/src/util/map';
import DropdownFilterFestivals from '../../../../../../shared-react/src/components/Explore/DropdownFilterFestivals/DropdownFilterFestivals';
import usePreloadSeed from '../../../../hooks/preload-seed';
import { clearPreloadSeed } from '../../../../store/actions/preloadSeed';
import useClosestCity from '../../../../../../shared-react/src/hooks/closest-city';
import BeenThere from './BeenThere/BeenThere';
import RenderedPostOrVideo from './RenderedPostOrVideo/RenderedPostOrVideo';
import RenderedEvent from './RenderedEvent/RenderedEvent';
import RenderedPost from './RenderedPost/RenderedPost';
import RenderedTrivia from './RenderedTrivia/RenderedTrivia';
import RenderedFestival from '../RenderedFestival/RenderedFestival';
import RenderedVideo from '../RenderedVideo/RenderedVideo';
import RenderedFAQ from '../RenderedFAQ/RenderedFAQ';
import RenderedNewsletter from '../RenderedNewsletter/RenderedNewsletter';
import RenderedVenueArticles from './RenderedVenueArticles/RenderedVenueArticles';
import RenderedItinerary from './RenderedItinerary/RenderedItinerary';
import RenderedArticlePost from './RenderedArticlePost/RenderedArticlePost';
import VBSpinner from '../../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import DropdownFilterItineraries, {
  BEST_TIME_TO_GO,
} from '../../../../../../shared-react/src/components/Explore/DropdownFilterItineraries/DropdownFilterItineraries';
import { ITINERARY_SEASONS, ItinerarySeasonLabel } from '../PopupItinerary/config';
import PageSummary from '../PageSummary/PageSummary';
import { getPageExploreSchema } from './schema';

import styles from './PageDetails.module.scss';

const DEFAULT_PLAYGROUND_RADIUS = JSON.stringify([0]);
const DEFAULT_ACTIVITIES_RADIUS_SMALL = JSON.stringify([0, 1]);
const DEFAULT_ACTIVITIES_RADIUS_LARGE = JSON.stringify([0, 1, 2]);

/**
 * Explore page on Zuzu.
 *
 * @param {object} props
 */
const PageDetails = ({ country, region, city, locationDetails, getEllipsisMenu }) => {
  const { lteXs: mobileStyle, lt1280: tabletStyle } = useVBBreakpoint();

  const { currentUser } = useVbContext();

  const dispatch = useDispatch();

  const history = useHistory();

  const { pathname } = history.location;

  // The closest city.
  const { city: closestCity, isLoading: isLoadingClosestCity, requestUserLocation } = useClosestCity();

  // A random city.
  const [randomCity, setRandomCity] = useState(null);

  // Filter properties.
  const [nearMe, setNearMe] = useQPState('n', ActivitiesFilterDefaults.nearMe, parseBoolParam, stringifyBoolParam);
  const [radius, setRadius] = useQPState('d', JSON.stringify(ActivitiesFilterDefaults.radius));
  const [lowRisk, setLowRisk] = useQPState('l', ActivitiesFilterDefaults.lowRisk, parseBoolParam, stringifyBoolParam);
  const [friendsOnly, setFriendsOnly] = useQPState(
    'f',
    TrendingFilterDefaults.friendsOnly,
    parseBoolParam,
    stringifyBoolParam
  );
  const [subcategory, setSubcategory] = useQPState('sc', ActivitiesFilterDefaults.subcategory);
  const [timePeriod, setTimePeriod] = useQPState('tp', EventsFilterDefaults.timePeriod);
  const [zzOnlyEvents, setZzOnlyEvents] = useQPState(
    'ez',
    EventsFilterDefaults.isOnlyZzEvents,
    parseBoolParam,
    stringifyBoolParam
  );
  const [isFree, setIsFree] = useQPState('fr', ActivitiesFilterDefaults.price, parseBoolParam, stringifyBoolParam);
  const [isRegionSelected, setIsRegionSelected] = useQPState('rs', false, parseBoolParam, stringifyBoolParam);
  const [sort, setSort] = useQPState('s', FestivalsFilterDefaults.sort);
  const [season, setSeason] = useQPState('ss', ItinerariesFilterDefaults.season, Number);

  const userCoords = closestCity?.userCoords;
  const userCoordsDep = nearMe ? userCoords : null;

  // Whether the page is in "most popular" view or "day by day" view
  // const [mostPopular, setMostPopular] = useQPState(
  //   'p',
  //   ActivitiesFilterDefaults.nearMe,
  //   parseBoolParam,
  //   stringifyBoolParam
  // );

  // Current section.
  const [section, setSection] = useState(null);

  // Current subsection.
  const [subsection, setSubsection] = useState(null);

  // Whether the search radius has been expanded due to venue badge counts
  const [expandedRadius, setExpandedRadius] = useState(null);

  // Whether the user has changed the radius filter at all. Used to determine when to scale
  // back down the expanded radius
  const [userHasChangedRadius, setUserHasChangedRadius] = useQPState('uc', false);

  const replaceKeepScroll = usePushPreserveScrollPosition();
  const location = useLocation();

  // Sets the radius and sets that the user has changed the radius
  const userSetRadius = useCallback(
    (r) => {
      // We can't use the set functions for radius and userHasChangedRadius
      // because the second one to be called will overwrite the first. Instead,
      // both values need to be set at once, like below
      replaceKeepScroll(
        addParams(location.pathname + location.search, { uc: true, d: r }) + location.hash,
        {},
        'replace'
      );

      // TODO: we might be able to use setters after the recent refactoring
      // setUserHasChangedRadius(true);
      // setRadius(r);
    },
    [location.hash, location.pathname, location.search, replaceKeepScroll]
  );

  // Kids age range filters.
  const [ages, setAges] = useQPState('a', ActivitiesFilterDefaults.ages, JSON.parse, JSON.stringify);

  // Distance ranges depending on the subsection
  const currentDistanceRanges = subsection === 'playgrounds' ? PLAYGROUNDS_DISTANCE_RANGES : COMMON_DISTANCE_RANGES;

  // The sections (these will be fixed to the bottom of the page)
  const sections = useMemo(
    () => [
      {
        value: 'top25',
        baseUrl: `/location/${country}/${region}/${city}`,
        name: 'Top 25',
      },
      {
        value: 'trending',
        baseUrl: `/trending/location/${country}/${region}/${city}`,
        name: 'Trending',
      },
    ],
    [country, region, city]
  );

  const ellipsis = useMemo(() => getEllipsisMenu(subsection), [getEllipsisMenu, subsection]);

  const basePath = sections.find(({ value }) => value === section)?.baseUrl ?? history.location.pathname;

  const locationId = locationDetails?.id;

  // Update the global location as needed.
  const globalLocation = useSelector((state) => state.globalLocation);
  useEffect(() => {
    if (globalLocation?.location?.id !== locationDetails?.id && locationDetails?.id) {
      dispatch(updateLocation(locationDetails));
    }
  }, [country, region, city, dispatch, locationDetails, globalLocation]);

  const limitResults = useMemo(
    () => (!currentUser && !isCrawler() ? SIGNED_OUT_MAX_VENUE_ITEMS + 1 : 0),
    [currentUser]
  );

  const cityGemSplit = (index, currentFilter, setFilterAfterSplit, isHiddenGems, noHiddenGems) => (
    <CityGemsSplit
      key={`split-city-${index}`}
      filter={currentFilter}
      setFilter={setFilterAfterSplit}
      randomCity={randomCity?.searchLink}
      closestCity={closestCity?.searchLink}
      isLoadingClosestCity={isLoadingClosestCity}
      requestUserLocation={requestUserLocation}
      index={index}
      refreshRandomCity={() => setRandomCity(null)}
      smallButtons={mobileStyle}
      isUserLoggedIn={!!currentUser}
      openAuthPopup={() => dispatch(openAuthPopup())}
      isHiddenGems={isHiddenGems}
      noHiddenGems={noHiddenGems}
    />
  );

  const distanceRanges = (index, currentFilter, setFilterAfterSplit) => (
    <SplitContainer
      key={`split-radius-${index}`}
      filter={currentFilter}
      setFilter={setFilterAfterSplit}
      property="radius"
      always={{ hiddenGems: false }}
      data={[[0, 1], [2], [3]].map((arr) => ({
        label: buildDistanceFilterString(arr, currentDistanceRanges),
        value: JSON.stringify(arr),
      }))}
      smallButtons={mobileStyle}
    />
  );

  const subsectionIsVenues = [
    ExploreSubsection.ACTIVITIES,
    ExploreSubsection.PLAYGROUNDS,
    ExploreSubsection.DAY_TRIPS,
  ].includes(subsection);

  // Activities content factory.
  const agesStr = JSON.stringify(ages);
  const activitiesCfFilter = useMemo(
    () => ({
      country,
      region,
      city,
      radius: subsection !== ExploreSubsection.DAY_TRIPS ? radius : '[2,3]',
      nearMe,
      userCoords: userCoordsDep,
      hiddenGems: false,
      category: subsection,
      ages: subsection === ExploreSubsection.ACTIVITIES ? agesStr : '[0,1,2,3]',
      subcategory: (subsection !== ExploreSubsection.PLAYGROUNDS ? subcategory : null) ?? 'all',
      isFree: subsection === ExploreSubsection.ACTIVITIES ? isFree : false,
      isRegionSelected,
    }),
    [city, country, nearMe, region, userCoordsDep, radius, agesStr, subsection, subcategory, isFree, isRegionSelected]
  );
  const activitiesCf = useContentFactory({
    context: `top25:${country}/${region}/${city}`,
    type: ContentTypes.venue.type,
    filter: activitiesCfFilter,
    disabled: !(section === 'top25' && subsectionIsVenues),
    getQueryAddress: (offset, f) => {
      const p = { offset };

      if (f.nearMe) {
        p.nearMe = true;
        p.userLatitude = f.userCoords?.latitude;
        p.userLongitude = f.userCoords?.longitude;
      }

      if (f.hiddenGems) {
        p.offset += 25;
      }

      p.ages = f.ages;

      if (f.category === 'daytrips') {
        p.category = 'activities';
      } else if (f.category) {
        p.category = f.category;
      }

      p.distanceRanges = f.radius;
      p.subcategory = f.subcategory;
      p.isFree = f.isFree;

      return `${APIConfig.NAMESPACE.SEARCH}/location/${f.country}/${f.region}/${
        f.isRegionSelected ? 'city' : f.city
      }/venues?${qs.stringify(p)}`;
    },
    getItems: (response) =>
      response.results.map((item) => ({
        item: item.venue,
        contextual: { distance: item.distance, direction: item.direction },
      })),
    splits: (index, currentFilter, isLastIndex, setFilterAfterSplit, total) => {
      const showFinalSplit = isLastIndex && index % 5 !== 0;

      if (index < 10 && !showFinalSplit) {
        return null;
      }

      const isPrimaryIndex = isKids() ? index % 10 === 0 : index % 5 === 0;
      const isAgeIndex = isKids() && index % 10 === 5;

      if (total > 0 && (isPrimaryIndex || (showFinalSplit && !isAgeIndex))) {
        // Page/distance buttons.
        return (
          <>
            {cityGemSplit(
              index,
              currentFilter,
              setFilterAfterSplit,
              currentFilter.hiddenGems || currentFilter.offset >= 25,
              subsection === ExploreSubsection.PLAYGROUNDS ? true : total <= 25,
              subsection === ExploreSubsection.PLAYGROUNDS ? true : total <= 0
            )}
            {distanceRanges(index, currentFilter, setFilterAfterSplit)}
          </>
        );
      }
      if (isAgeIndex) {
        // Ages
        return (
          <SplitContainer
            key={`split-ages-${index}`}
            filter={currentFilter}
            setFilter={setFilterAfterSplit}
            property="ages"
            always={{ hiddenGems: false }}
            data={AGE_GROUPS.map((label, value) => ({
              label,
              value,
            }))}
            smallButtons={mobileStyle}
          />
        );
      }
      return false;
    },
  });

  // Seed for random ordering of the posts
  const preloadSeed = usePreloadSeed();

  const seed = useMemo(
    () => (preloadSeed.hasSeed ? preloadSeed.seed : Math.floor(Math.random() * 1000)),
    [preloadSeed.hasSeed, preloadSeed.seed]
  );

  useEffect(() => {
    return () => {
      dispatch(clearPreloadSeed());
    };
  }, [dispatch]);

  // Posts content factory.
  const trendingCfFilter = useMemo(
    () => ({
      radius,
      nearMe,
      userCoords: userCoordsDep,
      country,
      region,
      city,
      friendsOnly,
      seed,
    }),
    [city, country, friendsOnly, nearMe, radius, region, seed, userCoordsDep]
  );
  const trendingCf = useContentFactory({
    getQueryAddress: (offset, f) => {
      const p = { offset, country: f.country, region: f.region, city: f.city, seed: f.seed };
      if (f.nearMe) {
        p.nearMe = true;
        p.userLatitude = f.userCoords?.latitude;
        p.userLongitude = f.userCoords?.longitude;
      }
      p.distanceRanges = f.radius;
      if (f.friendsOnly) p.friendsOnly = true;
      return `${APIConfig.NAMESPACE.SEARCH}/trending?${qs.stringify(p)}`;
    },
    getItems: (response) =>
      response.results.map((item) => ({
        item: { post: item.post, video: item.video, id: item.post?.id ?? item.video?.id },
        contextual: { distance: item.distance, direction: item.direction },
      })),
    filter: trendingCfFilter,
    splits: (index, currentFilter, isLastIndex, setFilterAfterSplit, total) => {
      if (total > 0 && ((index >= 10 && index % 5 === 0) || (isLastIndex && index % 5 !== 0))) {
        // Page buttons.
        return cityGemSplit(index, currentFilter, setFilterAfterSplit, false, false, total <= 0);
      }
      return false;
    },
    context: `trending:${country}/${region}/${city}`,
    type: ContentTypes.postOrVideo.type,
    disabled: section !== 'trending',
    // This is needed because otherwise the offset will add
    // 8 instead since it retrieves 4 posts and 4 videos.
    nextOffset: (oldOffset) => oldOffset + 4,
  });

  // Events content factory.
  // TODO: Because these results change with time, eventually we should send a timestamp here so we are getting the
  // results "at that time". Otherwise, there could be occasional pagination hiccups and failed caching.
  const eventsCfFilter = useMemo(
    () => ({
      radius,
      nearMe,
      userCoords,
      locationId,
      timePeriod,
      zzOnlyEvents,
      limitResults,
    }),
    [locationId, nearMe, radius, userCoords, timePeriod, zzOnlyEvents, limitResults]
  );
  const eventsCf = useContentFactory({
    getQueryAddress: (offset, f) => {
      const params = {
        locationId: f.locationId,
        offset,
        distanceRanges: JSON.parse(f.radius),
      };
      if (f.nearMe) params.nearMe = true;
      if (f.zzOnlyEvents) params.isOnlyZzEvents = true;
      params.limitResults = f.limitResults;
      const dayOfWeek = dayjs().day();
      switch (f.timePeriod) {
        case 'today':
          params.minTime = dayjs().unix();
          params.maxTime = dayjs().endOf('day').unix();
          break;

        case 'week':
          if (dayOfWeek > 0 && dayOfWeek < 6) {
            params.minTime = dayjs().unix();
            params.maxTime = dayjs().endOf('week').subtract(1, 'day').unix();
          } else {
            params.minTime = dayjs().add(2, 'days').startOf('week').add(1, 'day').unix();
            params.maxTime = dayjs().add(2, 'days').endOf('week').subtract(1, 'day').unix();
          }
          break;

        case 'weekend':
          if (dayOfWeek > 0 && dayOfWeek < 6) {
            params.minTime = dayjs().endOf('week').subtract(1, 'day').unix();
            params.maxTime = dayjs().endOf('week').add(1, 'day').unix();
          } else {
            params.minTime = dayjs().unix();
            params.maxTime = dayjs().add(2, 'days').startOf('week').add(1, 'day').unix();
          }
          break;

        case 'all':
          params.minTime = dayjs().unix();
          break;
        default:
      }
      params.userCoords = f.userCoords ? [f.userCoords.latitude, f.userCoords.longitude] : null;
      return `${APIConfig.NAMESPACE.EVENTS}/events?${qs.stringify(params, { arrayFormat: 'bracket' })}`;
    },
    getItems: ({ events }) => events.map(({ event, distance }) => ({ item: event, contextual: { distance } })),
    filter: eventsCfFilter,
    context: `events:${locationId}/${Math.floor(Date.now() / 1000 / 60 / 60)}`, // New context every hour, see above comment
    type: ContentTypes.event.type,
    disabled: section !== 'top25' || subsection !== ExploreSubsection.EVENTS || !userCoords,
  });

  const festivalsCfFilter = useMemo(
    () => ({
      sort,
      longitude: locationDetails?.longitude,
      latitude: locationDetails?.latitude,
    }),
    [locationDetails, sort]
  );

  const festivalsCf = useContentFactory({
    type: ContentTypes.festival.type,
    context: `festivals:${locationId}-includeBlogPost`,
    filter: festivalsCfFilter,
    disabled: section !== 'top25' || subsection !== ExploreSubsection.FESTIVALS || !locationDetails,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        sort,
        includeBlogPost: true,
        longitude: f.longitude,
        latitude: f.latitude,
      };

      return `${APIConfig.NAMESPACE.FESTIVAL}?${qs.stringify(params)}`;
    },
    getItems: ({ festivals }) => festivals.map((festival) => ({ item: festival })),
  });

  const articlePostMatch = matchPath(pathname.replace(basePath, ''), { path: '/articles/post/:slug', isExact: false });

  const postSlug = articlePostMatch?.params?.slug;

  const articlesCfFilter = useMemo(
    () => ({
      locationId: locationDetails?.id,
    }),
    [locationDetails]
  );

  const articlesCf = useContentFactory({
    type: ContentTypes.venueWithArticles.type,
    context: `venues-with-articles:${locationDetails?.id}`,
    filter: articlesCfFilter,
    disabled: Boolean(postSlug) || !locationDetails || section !== 'top25' || subsection !== ExploreSubsection.ARTICLES,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        size: 10,
      };

      return `${APIConfig.NAMESPACE.LOCATION}/${f.locationId}/article/venue?${stringify(params)}`;
    },
  });

  const articlePostCfFilter = useMemo(() => ({ postSlug }), [postSlug]);

  const articlePostCf = useContentFactory({
    type: ContentTypes.articlePost.type,
    context: `article-post:${postSlug}`,
    disabled: !postSlug || section !== 'top25' || subsection !== ExploreSubsection.ARTICLES,
    filter: articlePostCfFilter,
    getQueryAddress: (_, f) => {
      return `${APIConfig.NAMESPACE.ARTICLE}/post/${f.postSlug}`;
    },
  });

  const postsCfFilter = useMemo(
    () => ({
      countrySlug: locationDetails?.countrySlug,
      regionSlug: locationDetails?.regionSlug,
      citySlug: locationDetails?.citySlug,
    }),
    [locationDetails]
  );

  const postsCf = useContentFactory({
    type: ContentTypes.postOrVideo.type,
    context: `posts:${locationDetails?.countrySlug}-${locationDetails?.regionSlug}-${locationDetails?.citySlug}`,
    filter: postsCfFilter,
    disabled: !locationDetails || section !== 'top25' || subsection !== ExploreSubsection.CHAT,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        size: 10,
        contentType: 'photos',
        isFromVenue: true,
        country: f.countrySlug,
        region: f.regionSlug,
        city: f.citySlug,
      };

      return `${APIConfig.NAMESPACE.SEARCH}/trending?${stringify(params)}`;
    },
    getItems: ({ results }) => results.map(({ post, distance }) => ({ item: post, contextual: { distance } })),
  });

  const videosCfFilter = useMemo(
    () => ({
      longitude: locationDetails?.longitude,
      latitude: locationDetails?.latitude,
    }),
    [locationDetails]
  );

  const videosCf = useContentFactory({
    context: `location-videos:${locationDetails?.id}`,
    type: ContentTypes.locationVideo.type,
    disabled: !locationDetails || section !== 'top25' || subsection !== ExploreSubsection.VIDEOS,
    filter: videosCfFilter,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        size: 10,
        longitude: f.longitude,
        latitude: f.latitude,
      };

      return `${APIConfig.NAMESPACE.LOCATION}/video?${stringify(params)}`;
    },
    getItems: (response) => response.videos.map(({ video, distance }) => ({ item: video, contextual: { distance } })),
  });

  const triviaCfFilter = useMemo(
    () => ({
      longitude: locationDetails?.longitude,
      latitude: locationDetails?.latitude,
    }),
    [locationDetails]
  );

  const triviaCf = useContentFactory({
    type: ContentTypes.trivia.type,
    context: `travel-trivia:${locationDetails?.longitude}-${locationDetails?.latitude}`,
    disabled: !locationId || section !== 'top25' || subsection !== ExploreSubsection.TRIVIA,
    filter: triviaCfFilter,
    getQueryAddress: (_, f) => {
      const params = {
        longitude: f.longitude,
        latitude: f.latitude,
        size: 3,
      };

      return `${APIConfig.NAMESPACE.TRIVIA}/set?${stringify(params)}`;
    },
  });

  const faqCfFilter = useMemo(() => ({ locationId }), [locationId]);

  const faqCf = useContentFactory({
    type: ContentTypes.faq.type,
    context: `faq:location-${locationId}`,
    disabled: !locationId || section !== 'top25' || (mobileStyle && subsection !== ExploreSubsection.FAQ),
    filter: faqCfFilter,
    getQueryAddress: (offset, f) => {
      const params = { offset, locationId: f.locationId };

      return `${APIConfig.NAMESPACE.FAQ}?${stringify(params)}`;
    },
  });

  const newslettersCfFilter = useMemo(
    () => ({
      countrySlug: locationDetails?.countrySlug,
      regionSlug: locationDetails?.regionSlug,
      citySlug: isRegionSelected ? undefined : locationDetails?.citySlug,
    }),
    [isRegionSelected, locationDetails]
  );

  const newslettersCf = useContentFactory({
    type: ContentTypes.newsletter.type,
    context: `newsletters:${locationId}`,
    disabled: !locationId || section !== 'top25' || subsection !== ExploreSubsection.NEWSLETTERS,
    filter: newslettersCfFilter,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        countrySlug: f.countrySlug,
        regionSlug: f.regionSlug,
        citySlug: f.citySlug,
      };

      return `${APIConfig.NAMESPACE.NEWSLETTER}?${stringify(params)}`;
    },
  });

  const itinerariesCfFilter = useMemo(() => ({ locationId, season }), [locationId, season]);

  const itinerariesCf = useContentFactory({
    type: ContentTypes.itinerary.type,
    context: `itinerary:${locationId}-${season}-showClosestIfNotExist`,
    disabled:
      !locationId || section !== 'top25' || subsection !== ExploreSubsection.ITINERARIES || season === BEST_TIME_TO_GO,
    filter: itinerariesCfFilter,
    getQueryAddress: (_, f) => {
      const params = {
        season: f.season,
        locationId: f.locationId,
        showClosestIfNotExist: true,
      };

      return `${APIConfig.NAMESPACE.ITINERARY}?${stringify(params)}`;
    },
    getItems: ({ itineraries }) => {
      return itineraries.map((itinerary) => ({
        item: itinerary,
      }));
    },
  });

  const pageSchema = getPageExploreSchema(locationDetails, subsection, faqCf?.content);

  const gotoCity = useGotoCityFromSlugLocation(locationDetails);

  // When in the playgrounds subsection, there will be a near me toggle in the extra content area of the filter. When
  // the user uses this toggle, keep track of their initial location so it can be reset back to once the toggle is
  // flipped off.
  const [nearMeLocationReset, setNearMeLocationReset] = useState();

  // The next few items are the bubble filters for mobile/tablets.

  const playgroundsButton = useMemo(
    () => (
      <VBButton
        content="Playgrounds"
        onClick={() => history.push(`${basePath}/playgrounds${history.location.search}`)}
        size="med"
        type="border"
        key="playgrounds_filter_button"
        style={tabletStyle ? null : { width: '100%' }}
      />
    ),
    [basePath, history, tabletStyle]
  );

  // Filters for the activities tab on mobile/tablet
  const activitiesFilters = (
    isRegionSelected && subsection === ExploreSubsection.ACTIVITIES
      ? []
      : [
          {
            name: 'Radius',
            filterItem: (
              <RadiusBubbleSelector
                radius={radius}
                setRadius={userSetRadius}
                hideLabel
                isVertical
                fullWidth={!tabletStyle}
                ranges={currentDistanceRanges}
                analyticsLabel="activities_radius"
              />
            ),
            getValue: () => getRadiusFilterLabel(radius, currentDistanceRanges),
          },
        ]
  ).concat([
    // Filter for radius
    // Filter for outdoor venues only
    {
      name: 'Type',
      filterItem: (
        <SubcategorySelector
          subcategory={subcategory}
          setSubcategory={setSubcategory}
          setLowRisk={setLowRisk}
          lowRisk={lowRisk}
          extraButtons={[
            playgroundsButton,
            <VBButton
              content="Day Trips"
              onClick={() => history.push(`${basePath}/daytrips${history.location.search}`)}
              size="med"
              type="border"
              key="day_trips_button"
              style={tabletStyle ? null : { width: '100%' }}
            />,
          ]}
          fullWidth={!tabletStyle}
        />
      ),
      getValue: () => SubcategoryLabels[subcategory],
    },
    {
      name: 'Price',
      filterItem: (
        <BubbleSelector
          options={[
            { label: 'All', value: false },
            { label: 'Free', value: true },
          ]}
          value={isFree}
          onChange={setIsFree}
          fullWidth={!tabletStyle}
          analyticsLabel="activities_price"
        />
      ),
      getValue: () => getPriceFilterLabel(isFree),
    },
  ]);
  if (isKids())
    activitiesFilters.unshift(
      // Filter for activity ages - ONLY SHOWN ON KIDS
      {
        name: 'Ages',
        filterItem: (
          <AgesSelector
            ages={ages}
            setAges={setAges}
            hideLabel
            isVertical
            leftAlign
            fullWidth={!tabletStyle}
            analyticsLabel="activities_age"
          />
        ),
        getValue: () => getAgeFilterLabel(ages),
      }
    );

  const playgroundFilters = [activitiesFilters[1]];

  const dayTripsFilters = useMemo(
    () => [
      {
        name: 'Type',
        filterItem: (
          <SubcategorySelector
            subcategory={subcategory}
            setSubcategory={setSubcategory}
            extraButtons={[playgroundsButton]}
            fullWidth={!tabletStyle}
          />
        ),
        getValue: () => SubcategoryLabels[subcategory],
      },
    ],
    [playgroundsButton, setSubcategory, subcategory, tabletStyle]
  );

  // Filters for the explore trending page on mobile/tablet
  const trendingFilters = useMemo(
    () => [
      // Radius filter
      {
        name: 'Radius',
        filterItem: (
          <RadiusBubbleSelector
            radius={radius}
            setRadius={userSetRadius}
            hideLabel
            isVertical
            fullWidth={!tabletStyle}
            distance={currentDistanceRanges}
            analyticsLabel="trending_radius"
          />
        ),
        getValue: () => {
          const radiusArr = JSON.parse(radius);
          const lowerValue = radiusArr[0] === 0 ? 0 : currentDistanceRanges[radiusArr[0] - 1];
          return `${lowerValue}-${currentDistanceRanges[radiusArr[radiusArr.length - 1]]} mi`;
        },
      },
      // Filter for everyone or friends/following
      {
        name: 'View',
        filterItem: (
          <BubbleSelector
            value={friendsOnly}
            options={[{ label: 'Everyone', value: false }].concat(
              currentUser ? [{ label: 'Friends and Following', value: true }] : []
            )}
            onChange={setFriendsOnly}
            fullWidth={!tabletStyle}
            analyticsLabel="trending_view"
          />
        ),
        getValue: () => (friendsOnly ? 'Friends and Following' : 'Everyone'),
      },
    ],
    [currentDistanceRanges, currentUser, friendsOnly, radius, setFriendsOnly, tabletStyle, userSetRadius]
  );

  useEffect(() => {
    if (friendsOnly && !currentUser) setFriendsOnly(false);
  }, [currentUser, friendsOnly, setFriendsOnly]);

  const eventsFilters = useMemo(
    () => [
      {
        name: 'Radius',
        filterItem: (
          <RadiusBubbleSelector
            radius={radius}
            setRadius={userSetRadius}
            hideLabel
            isVertical
            fullWidth={!tabletStyle}
            ranges={currentDistanceRanges}
            analyticsLabel="events_radius"
          />
        ),
        getValue: () => {
          const radiusArr = JSON.parse(radius);
          const lowerValue = radiusArr[0] === 0 ? 0 : currentDistanceRanges[radiusArr[0] - 1];
          return `${lowerValue}-${currentDistanceRanges[radiusArr[radiusArr.length - 1]]} mi`;
        },
      },
      {
        name: 'Type',
        filterItem: (
          <BubbleSelector
            value={zzOnlyEvents}
            options={[
              { label: 'All', value: false },
              { label: 'Zuzu Only', value: true },
            ]}
            onChange={setZzOnlyEvents}
            fullWidth={!tabletStyle}
            analyticsLabel="event_type"
          />
        ),
        getValue: () => (zzOnlyEvents ? 'Zuzu Only' : 'All'),
      },
      {
        name: 'Time',
        filterItem: (
          <BubbleSelector
            value={timePeriod}
            options={EVENT_TIME_FRAMES}
            onChange={setTimePeriod}
            fullWidth={!tabletStyle}
            analyticsLabel="event_time"
          />
        ),
        getValue: () => EVENT_TIME_FRAMES.find(({ value }) => value === timePeriod)?.label ?? 'Unknown',
      },
    ],
    [
      currentDistanceRanges,
      radius,
      setTimePeriod,
      setZzOnlyEvents,
      tabletStyle,
      timePeriod,
      userSetRadius,
      zzOnlyEvents,
    ]
  );

  const festivalsFilters = useMemo(() => {
    const options = [
      { label: 'Date', value: 'date' },
      { label: 'Popular', value: 'popular' },
    ];

    return [
      {
        name: 'Sort',
        filterItem: (
          <BubbleSelector
            value={sort}
            options={options}
            onChange={setSort}
            fullWidth={!tabletStyle}
            analyticsLabel="festivals_sort"
          />
        ),
        getValue: () => options.find((option) => option.value === sort)?.label ?? 'Unknown',
      },
    ];
  }, [setSort, sort, tabletStyle]);

  const filters = useMemo(() => {
    if (!tabletStyle) {
      return null;
    }

    switch (section) {
      case 'top25': {
        switch (subsection) {
          case ExploreSubsection.ACTIVITIES: {
            // HERE
            return (
              <MaxWidthContainer padded={tabletStyle} className={styles.selectorWrapper}>
                <FilterSelector filters={activitiesFilters} tabName="activities" />
              </MaxWidthContainer>
            );
          }
          case ExploreSubsection.DAY_TRIPS: {
            return (
              <MaxWidthContainer padded={tabletStyle} className={styles.selectorWrapper}>
                <FilterSelector filters={dayTripsFilters} tabName="daytrips" />
              </MaxWidthContainer>
            );
          }
          case ExploreSubsection.PLAYGROUNDS: {
            return (
              <div className={styles.nearMeWideContainer}>
                <div className={!mobileStyle && styles.selectorWrapper}>
                  <RadiusBubbleSelector
                    radius={radius}
                    setRadius={userSetRadius}
                    ranges={currentDistanceRanges}
                    analyticsLabel="playgrounds_radius"
                  />
                </div>
              </div>
            );
          }
          case ExploreSubsection.EVENTS: {
            return (
              <MaxWidthContainer padded={tabletStyle} className={styles.selectorWrapper}>
                <FilterSelector filters={eventsFilters} tabName="events" />
              </MaxWidthContainer>
            );
          }
          case ExploreSubsection.FESTIVALS: {
            return (
              <MaxWidthContainer padded={tabletStyle} className={styles.selectorWrapper}>
                <FilterSelector filters={festivalsFilters} tabName={ExploreSubsection.FESTIVALS} />
              </MaxWidthContainer>
            );
          }
          default: {
            return null;
          }
        }
      }
      case 'trending': {
        return (
          <MaxWidthContainer padded={tabletStyle} className={styles.selectorWrapper}>
            <FilterSelector filters={trendingFilters} tabName="trending" />
          </MaxWidthContainer>
        );
      }
      default: {
        return null;
      }
    }
  }, [
    activitiesFilters,
    currentDistanceRanges,
    dayTripsFilters,
    eventsFilters,
    festivalsFilters,
    mobileStyle,
    radius,
    section,
    subsection,
    tabletStyle,
    trendingFilters,
    userSetRadius,
  ]);

  const locationSurrogate =
    trimSlashes(pathname).match(/^(?:[a-zA-Z-]+\/){3}([a-zA-Z-]+)\/([a-zA-Z-]+)/) ??
    pathname.match(/^.*\/([a-zA-Z-]+)\/([a-zA-Z-]+)/);

  const placeName = useMemo(() => {
    const onlyRegionInPlaceName =
      subsection === ExploreSubsection.ACTIVITIES && section === 'top25' && isRegionSelected;

    if (locationDetails?.regionSlug && locationDetails?.city) {
      return onlyRegionInPlaceName
        ? locationDetails?.region ?? locationDetails?.regionSlug.toUpperCase()
        : `${uppercaseFirst(locationDetails?.city)}, ${locationDetails?.regionSlug.toUpperCase()}`;
    }

    return onlyRegionInPlaceName
      ? locationSurrogate[1].toUpperCase()
      : `${uppercaseFirst(locationSurrogate[2])}, ${locationSurrogate[1].toUpperCase()}`;
  }, [isRegionSelected, locationDetails, locationSurrogate, section, subsection]);

  const gotoNearMe = () => {
    if (!nearMe) {
      // If not in near me, go to the closest city with near me enabled, and set the reset location.
      gotoCity(closestCity, { n: 1 });
      setNearMeLocationReset(locationDetails);
    } else if (!nearMeLocationReset) {
      // If near me is enabled and there is no reset location, just toggle near me.
      setNearMe(false);
    } else {
      // If near me was disabled and there is a reset location, go there with near me disabled.
      gotoCity(nearMeLocationReset, {
        n: 0,
      });
    }
  };

  const exploreLink = `${window.location.pathname}${window.location.search}`;

  const exploreLinkText = useMemo(() => {
    const locationName = locationDetails?.city ?? uppercaseFirst(locationSurrogate[2]);

    const buildTopFamilyString = (entity) => {
      return `< Top Family ${entity} in ${locationName}`;
    };

    const buildFamilyTravelString = (entity) => {
      return `< ${locationName} Family Travel ${entity}`;
    };

    switch (subsection) {
      case ExploreSubsection.NEWSLETTERS: {
        return `< What's Going On in ${locationName}`;
      }
      case ExploreSubsection.ACTIVITIES: {
        return buildTopFamilyString('Activities');
      }
      case ExploreSubsection.PLAYGROUNDS: {
        return buildTopFamilyString('Playgrounds');
      }
      case ExploreSubsection.FAQ: {
        return buildFamilyTravelString('FAQ');
      }
      case ExploreSubsection.FESTIVALS: {
        return buildFamilyTravelString('Festivals');
      }
      case ExploreSubsection.TRIVIA: {
        return buildFamilyTravelString('Trivia');
      }
      case ExploreSubsection.ITINERARIES: {
        return buildFamilyTravelString('Itineraries');
      }
      case ExploreSubsection.DAY_TRIPS: {
        return buildTopFamilyString('Day Trips');
      }
      case ExploreSubsection.ARTICLES: {
        return buildFamilyTravelString('Articles');
      }
      default: {
        return `< Fun Things to do in ${locationName}`;
      }
    }
  }, [locationDetails, locationSurrogate, subsection]);

  let venuesForMap;
  let cf;
  let cfFilter;
  let renderItemAs;
  let extraRenderProps;
  let header;
  let dropdown;
  let dropdownSide;
  let noItemsMessage;
  let wideContent;
  let pageTitle = 'Explore';
  let signUpItemNamePlural = 'Things to Do';
  let mapCardRenderFunction = buildRenderItemVenue;
  let bottomSwitch;
  let hideSeeMore;
  let centerScrollTest = true;
  let description = '';
  let hideBottomButtons;
  let contentOverride;
  let showLocationDetails = false;
  let contentMarginTop;
  let noSyncScroll = false;

  switch (section) {
    case 'top25':
      switch (subsection) {
        case ExploreSubsection.SUMMARY: {
          contentOverride = (
            <PageSummary
              locationDetails={locationDetails}
              getEllipsisMenu={getEllipsisMenu}
              placeName={placeName}
              exploreLink={exploreLink}
              exploreLinkText={exploreLinkText}
            />
          );

          header = `Fun Things to do near me with Kids in ${placeName} (%FILTER%)`;
          pageTitle = `Fun Things to do near me with Kids in ${placeName}`;
          description = `View the top things to do with kids in ${placeName}, including family activities, events, and playgrounds. See pictures and reviews on ZuzuForKids.com!`;

          wideContent = false;
          showLocationDetails = true;

          break;
        }
        case ExploreSubsection.DAY_TRIPS:
        case ExploreSubsection.ACTIVITIES:
        case ExploreSubsection.PLAYGROUNDS:
          {
            // Setup activities, tours and playground rendering.

            wideContent = false;

            cf = activitiesCf;
            cfFilter = activitiesCfFilter;
            venuesForMap = cf.content.filter(({ item }) => item);
            renderItemAs = RenderedVenue;

            extraRenderProps = {
              exploreLink: exploreLink,
              exploreLinkText: exploreLinkText,
              exploreSubsection: subsection,
              exploreFilter: cf.filter,
              exploreContext: cf.context,
              noVideo: true,
            };

            let subsectionTitle = 'Things To Do with Kids';
            if (placeName) {
              header = `Top 25 ${subsectionTitle} ${nearMe ? 'Near Me' : `in ` + placeName}${
                nearMe ? '' : ` (%FILTER%)`
              }`;
              pageTitle = `Top ${subsectionTitle}${nearMe ? ' Near Me' : ` in ${placeName}`}`;
              description = `View the top things to do with kids in ${placeName}, including family activities, events, and playgrounds. See pictures and reviews on ZuzuForKids.com!`;
              switch (subsection) {
                case ExploreSubsection.PLAYGROUNDS:
                  subsectionTitle = 'Playgrounds';
                  description = `View the top playgrounds in ${placeName}, with pictures and reviews. Find the perfect playground for your kids on ZuzuForKids.com!`;
                  header = !nearMe ? `25 Best Playgrounds in ${placeName}` : `25 Closest Playgrounds Near Me`;
                  break;
                case ExploreSubsection.DAY_TRIPS:
                  subsectionTitle = 'Kids Day Trips';
                  description = `Looking for a day trip from ${placeName}? Check out popular day trip ideas that your whole family will enjoy on ZuzuForKids.com!`;
                  header = `Top 25 Family Fun Day Trips from ${placeName}`;
                  break;
                default:
              }
            }

            centerScrollTest = false;

            const filterDropdownProps = {
              radius,
              setRadius: userSetRadius,
            };
            if (subsection !== ExploreSubsection.PLAYGROUNDS) {
              Object.assign(filterDropdownProps, {
                lowRisk,
                setLowRisk,
                isFree,
                setIsFree,
              });
            }
            dropdown = <DropdownFilterActivities {...filterDropdownProps} />;
            if (subsection === ExploreSubsection.DAY_TRIPS) {
              dropdownSide = <FilterSelector filters={dayTripsFilters} tabName="daytrips" isVertical />;
            } else if (subsection === ExploreSubsection.PLAYGROUNDS) {
              dropdownSide = <FilterSelector filters={playgroundFilters} tabName="playgrounds" isVertical />;
            } else {
              dropdownSide = <FilterSelector filters={activitiesFilters} tabName="activities" isVertical />;
            }
            noItemsMessage = `No ${uppercaseFirst(subsection)}.`;
          }
          break;

        case ExploreSubsection.EVENTS:
          // Setup events rendering.

          wideContent = false;
          cf = eventsCf;
          cfFilter = eventsCfFilter;
          renderItemAs = RenderedEvent;

          if (placeName) {
            header = `Top Kids Events ${nearMe ? 'Near Me ' : ''} in ${placeName} (%FILTER%)`;
            pageTitle = `Top Kids Events ${nearMe ? 'Near Me ' : ''} in ${placeName}`;
            description = `View upcoming kids' events in ${placeName} on our family friendly event calendar. View events going on today, this week, or this weekend on ZuzuForKids.com!`;
          }

          extraRenderProps = { exploreLink, exploreLinkText };

          venuesForMap = cf.content
            .filter(({ item }) => item)
            //Get the venue from either the post or video, whichever the item is.
            .map(({ item }) => ({ item }));
          mapCardRenderFunction = buildRenderItemEvent;

          dropdown = (
            <DropdownFilterEvents
              radius={radius}
              setRadius={userSetRadius}
              isOnlyZzEvents={zzOnlyEvents}
              setIsOnlyZzEvents={setZzOnlyEvents}
              timePeriod={timePeriod}
              setTimePeriod={setTimePeriod}
            />
          );
          dropdownSide = dropdownSide = <FilterSelector filters={eventsFilters} tabName="events" isVertical />;
          noItemsMessage = 'No Events.';
          signUpItemNamePlural = 'Events';
          break;
        case ExploreSubsection.FESTIVALS: {
          wideContent = false;
          cf = festivalsCf;
          cfFilter = festivalsCfFilter;
          renderItemAs = RenderedFestival;

          if (placeName) {
            header = `Top Family Festivals in ${placeName}`;
            pageTitle = `Top Family Festivals in ${placeName}`;
            description = `Top Family Festivals in ${placeName}`;
          }

          extraRenderProps = {
            locationDetails,
            fullDescription: true,
          };

          venuesForMap = null;

          dropdown = <DropdownFilterFestivals sort={sort} setSort={setSort} />;
          dropdownSide = <FilterSelector filters={festivalsFilters} tabName={ExploreSubsection.FESTIVALS} isVertical />;

          noItemsMessage = 'No Festivals.';
          signUpItemNamePlural = 'Festivals';

          break;
        }
        case ExploreSubsection.ARTICLES: {
          wideContent = false;
          noSyncScroll = Boolean(postSlug);

          cf = postSlug ? articlePostCf : articlesCf;
          cfFilter = postSlug ? articlePostCfFilter : articlesCfFilter;
          renderItemAs = postSlug ? RenderedArticlePost : RenderedVenueArticles;

          venuesForMap = cf.content.map(({ item }) => ({ item }));

          extraRenderProps = {
            basePath,
            exploreLink,
            exploreLinkText,
            locationDetails,
          };

          if (placeName) {
            header = `${placeName} Family Travel Articles`;
            pageTitle = `${placeName} Family Travel Articles`;
            description = `${placeName} Family Travel Articles`;
          }

          if (tabletStyle) {
            contentMarginTop = '1.5rem';
          }

          noItemsMessage = postSlug ? 'No Post' : 'No Articles.';
          signUpItemNamePlural = postSlug ? 'Posts' : 'Articles';

          break;
        }
        case ExploreSubsection.CHAT: {
          wideContent = false;
          cf = postsCf;
          cfFilter = postsCfFilter;
          venuesForMap = cf.content.map(({ item }) => ({ item: item.venue }));

          renderItemAs = RenderedPost;

          if (placeName) {
            header = `${placeName} Chat`;
            pageTitle = `${placeName} Chat`;
            description = `${placeName} Chat`;
          }

          noItemsMessage = 'No Posts.';
          signUpItemNamePlural = 'Posts';

          break;
        }
        case ExploreSubsection.VIDEOS: {
          wideContent = false;
          cf = videosCf;
          cfFilter = videosCfFilter;
          venuesForMap = cf.content.map(({ item }) => ({ item: item.venue }));

          renderItemAs = RenderedVideo;

          if (placeName) {
            header = `Videos of Things to do in ${placeName}`;
            pageTitle = `Videos of Things to do in ${placeName}`;
            description = `Videos of Things to do in ${placeName}`;
          }

          extraRenderProps = {
            disablePadding: mobileStyle,
          };

          noItemsMessage = 'No Videos.';
          signUpItemNamePlural = 'Videos';

          break;
        }
        case ExploreSubsection.TRIVIA: {
          wideContent = false;
          hideBottomButtons = true;

          const triviaSets = triviaCf.content.map((trivia) => trivia.item);

          cf = {
            ...triviaCf,
            content: triviaCf.content.length ? [{ key: 1, item: triviaSets }] : [],
            noMore: true,
          };

          cfFilter = triviaCfFilter;
          renderItemAs = RenderedTrivia;

          if (placeName) {
            header = `Travel Trivia - ${placeName}`;
            pageTitle = `Travel Trivia - ${placeName}`;
            description = `Travel Trivia - ${placeName}`;
          }

          if (tabletStyle) {
            contentMarginTop = '1.5rem';
          }

          extraRenderProps = {
            locationDetails,
            gotoCity,
          };

          venuesForMap = null;

          noItemsMessage = 'No Trivia.';
          signUpItemNamePlural = 'Trivia';

          break;
        }
        case ExploreSubsection.FAQ: {
          wideContent = false;
          cf = faqCf;
          cfFilter = faqCfFilter;
          renderItemAs = RenderedFAQ;

          extraRenderProps = {
            locationDetails,
          };

          if (placeName) {
            header = `FAQ - ${placeName}`;
            pageTitle = `FAQ - ${placeName}`;
            description = `FAQ - ${placeName}`;
          }

          if (tabletStyle) {
            contentMarginTop = '1.5rem';
          }

          venuesForMap = null;

          noItemsMessage = 'No FAQs.';
          signUpItemNamePlural = 'FAQs';

          break;
        }
        case ExploreSubsection.NEWSLETTERS: {
          wideContent = false;

          cf = newslettersCf;
          cfFilter = newslettersCfFilter;
          renderItemAs = RenderedNewsletter;

          if (placeName) {
            header = `What's Going On in ${placeName}`;
            pageTitle = `What's Going On in ${placeName}`;
            description = `What's Going On in ${placeName}`;
          }

          if (tabletStyle) {
            contentMarginTop = '1.5rem';
          }

          venuesForMap = null;
          noItemsMessage = 'No items.';

          break;
        }
        case ExploreSubsection.ITINERARIES: {
          wideContent = false;
          noSyncScroll = true;

          noItemsMessage = 'No Itineraries.';

          if (season === BEST_TIME_TO_GO) {
            contentOverride = locationDetails ? (
              <div className={styles.bestTimeToGo}>
                {locationDetails.bestTimeToGo ? (
                  <>
                    <h2 className={styles.heading}>Best Time to Go</h2>
                    <ReactMarkdown>{locationDetails.bestTimeToGo}</ReactMarkdown>
                  </>
                ) : (
                  <div className={styles.noItems}>{noItemsMessage}</div>
                )}
              </div>
            ) : (
              <VBSpinner center />
            );
          }

          cf = itinerariesCf;
          cfFilter = itinerariesCfFilter;
          renderItemAs = RenderedItinerary;

          if (placeName) {
            header = `7 Day Family Itinerary - ${placeName}`;
            pageTitle = `7 Day Family Itinerary - ${placeName}`;
            description = `7 Day Family Itinerary - ${placeName}`;
          }

          if (tabletStyle) {
            contentMarginTop = '1rem';
          }

          const matches = cf.content.flatMap(({ item }) => item.days.flatMap((day) => day.matches));

          venuesForMap = matches.map((match) => ({ item: match.venue }));

          dropdown = <DropdownFilterItineraries selectedSeason={season} setSeason={setSeason} />;

          break;
        }
        default:
      }
      break;
    case 'trending':
      // Setup post rendering.

      wideContent = false;
      cf = trendingCf;
      cfFilter = trendingCfFilter;
      renderItemAs = RenderedPostOrVideo;
      if (placeName) {
        header = `Trending Posts ${nearMe ? 'Near Me ' : 'in ' + locationDetails?.city} (%FILTER%)`;
        pageTitle = `Trending Posts ${nearMe ? 'Near Me ' : ''}in ${placeName}`;
        description = `View trending photos and videos in ${placeName} on ZuzuForKids.com!`;
      }

      venuesForMap = cf.content
        .filter(({ item }) => item)
        //Get the venue from either the post or video, whichever the item is.
        .map((entry) => ({ ...entry, item: entry.item.post?.venue ?? entry.item.video?.venue }));
      dropdown = (
        <DropdownFilterTrending
          radius={radius}
          setRadius={userSetRadius}
          friendsOnly={friendsOnly}
          setFriendsOnly={setFriendsOnly}
          openAuthPopup={() => dispatch(openAuthPopup())}
        />
      );
      dropdownSide = <FilterSelector filters={trendingFilters} tabName="trending" isVertical />;
      noItemsMessage = 'No Posts.';
      signUpItemNamePlural = 'Posts';
      hideSeeMore = true;
      break;
    default:
  }

  if (
    section === 'trending' ||
    subsection === ExploreSubsection.ACTIVITIES ||
    subsection === ExploreSubsection.NEWSLETTERS
  ) {
    bottomSwitch = {
      options: [
        { value: 'city', name: 'City' },
        { value: 'region', name: 'State' },
      ],
      selected: isRegionSelected ? 'region' : 'city',
      handleChange: (newValue) => setIsRegionSelected(newValue === 'region'),
    };
  } else if (section === 'top25') {
    if (subsection === ExploreSubsection.PLAYGROUNDS) {
      bottomSwitch = {
        options: [
          { value: 'popular', name: 'Top Rated' },
          { value: 'nearme', name: 'Near Me' },
        ],
        selected: nearMe ? 'nearme' : 'popular',
        handleChange: (newValue) => {
          if (newValue === 'nearme') {
            gotoNearMe();
          } else {
            setNearMe(false);
          }
        },
      };
    }
  }

  useEffect(() => {
    if (!cf?.content.length) return;
    if (cf?.loading && !cf?.refreshing) return;
    if (section === null) return;
    if (userHasChangedRadius) return;

    let newExpandedRadius = expandedRadius;

    // If there are <= 5 venues with badges, increase the filter radius to include 20-50 mi.
    if (section === 'top25' && subsection === ExploreSubsection.ACTIVITIES) {
      // If execution gets here, then the badge count has not yet been evaluated for this city.
      let badgeCount = 0;
      for (const contentItem of cf.content) {
        let rating = contentItem?.item?.overallRating;
        if (rating && getBadge(rating) !== null) badgeCount++;
      }
      if (badgeCount <= 5) {
        if (!expandedRadius) {
          newExpandedRadius = true;
        }
      } else if (expandedRadius === null) {
        newExpandedRadius = false;
      }
    } else {
      newExpandedRadius = false;
    }

    if (subsection === ExploreSubsection.PLAYGROUNDS) {
      if (radius !== DEFAULT_PLAYGROUND_RADIUS) setRadius(DEFAULT_PLAYGROUND_RADIUS);
    } else {
      const targetRadius = newExpandedRadius ? DEFAULT_ACTIVITIES_RADIUS_LARGE : DEFAULT_ACTIVITIES_RADIUS_SMALL;
      if (radius !== targetRadius) setRadius(targetRadius);
    }

    if (newExpandedRadius !== expandedRadius) setExpandedRadius(newExpandedRadius);
  }, [
    cf?.content,
    cf?.loading,
    cf?.refreshing,
    expandedRadius,
    section,
    setRadius,
    subsection,
    userHasChangedRadius,
    radius,
  ]);

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const subsections = useMemo(() => {
    if (section === 'trending') {
      return [];
    }

    const items = [
      {
        value: ExploreSubsection.SUMMARY,
        name: 'Summary',
      },
      {
        value: ExploreSubsection.NEWSLETTERS,
        name: "What's Going On",
        onClick: () => sendAnalyticsEvent('vb_explore_newsletters', 'vb_explore'),
      },
      {
        value: ExploreSubsection.ACTIVITIES,
        name: 'Activities',
        onClick: () => sendAnalyticsEvent('vb_explore_activities', 'vb_explore'),
      },
      isKids() && {
        value: ExploreSubsection.PLAYGROUNDS,
        name: 'Playgrounds',
        onClick: () => sendAnalyticsEvent('vb_explore_playgrounds', 'vb_explore'),
      },
      {
        value: ExploreSubsection.FAQ,
        name: 'FAQ',
        onClick: () => sendAnalyticsEvent('vb_explore_faq', 'vb_explore'),
      },
      {
        value: ExploreSubsection.FESTIVALS,
        name: 'Festivals',
        onClick: () => sendAnalyticsEvent('vb_explore_festivals', 'vb_explore'),
      },
      {
        value: ExploreSubsection.TRIVIA,
        name: 'Travel Trivia',
        onClick: () => sendAnalyticsEvent('vb_explore_travel_trivia', 'vb_explore'),
      },

      {
        value: ExploreSubsection.ITINERARIES,
        name: 'Itineraries',
        onClick: () => sendAnalyticsEvent('vb_explore_itineraries', 'vb_explore'),
      },
      {
        value: ExploreSubsection.DAY_TRIPS,
        name: 'Day Trips',
        onClick: () => sendAnalyticsEvent('vb_explore_day_trips', 'vb_explore'),
      },
      {
        value: ExploreSubsection.ARTICLES,
        name: 'Articles',
      },
      // {
      //   value: ExploreSubsection.EVENTS,
      //   name: 'Events',
      // },
      // {
      //   value: ExploreSubsection.CHAT,
      //   name: 'Chat',
      //   onClick: () => sendAnalyticsEvent('vb_explore_parent_feed', 'vb_explore'),
      // },
      // {
      //   value: ExploreSubsection.VIDEOS,
      //   name: 'Videos',
      //   onClick: () => sendAnalyticsEvent('vb_explore_videos', 'vb_explore'),
      // },
    ].filter(Boolean);

    return items;
  }, [section, sendAnalyticsEvent]);

  // The tabs (these will be fixed to the top of the page)
  const tabs = useMemo(() => {
    if (subsection === ExploreSubsection.ITINERARIES) {
      const items = [
        {
          name: '< Home',
          onClick: () => {
            history.push(`${basePath}/summary`);
          },
        },
      ];

      for (const item of ITINERARY_SEASONS) {
        items.push({
          name: `${ItinerarySeasonLabel[item]} Itinerary`,
          isActive: season === item,
          onClick: () => {
            setSeason(item);
          },
        });
      }

      items.push({
        name: 'Best Time to Go',
        isActive: season === BEST_TIME_TO_GO,
        onClick: () => {
          setSeason(BEST_TIME_TO_GO);
        },
      });

      return items;
    }

    return subsections.map((item) => ({
      ...item,
      isActive: item.value === subsection,
    }));
  }, [basePath, history, season, setSeason, subsection, subsections]);

  const distanceFilterString = buildDistanceFilterString(JSON.parse(radius), currentDistanceRanges);

  /**
   * Gets the text to render in a filter switch, given the filter.
   *
   * @param {object} filter the filter object being used
   *
   * @returns {string} stringified filter
   */
  const getSwitchText = (filter) => {
    if (section === 'top25') {
      let str = '';
      if (!filter.hiddenGems) str += 'Top 25 Activities ';
      if (nearMe) {
        str += 'Near Me';
        return str;
      }
      str += `within ${buildDistanceFilterString(
        JSON.parse(filter.radius),
        currentDistanceRanges
      )} of ${filter.city.toLocaleUpperCase()}`;
      return str;
    }

    if (section === 'trending') {
      let str = 'Trending Posts ';
      if (nearMe) {
        str += 'Near Me';
        return str;
      }
      str += `within ${buildDistanceFilterString(
        JSON.parse(filter.radius),
        currentDistanceRanges
      )} of ${filter.city.toLocaleUpperCase()}`;
      return str;
    }

    return JSON.stringify(filter);
  };

  // Parse query params, set the preselected activity if there is a match.
  const { presel: selectedActivityStr } = useQueryParams();
  const preselectedActivity = selectedActivityStr ? parseInt(selectedActivityStr, 10) : null;
  const [preselectedActivityIndex, setPreselectedActivityIndex] = useState();
  useEffect(() => {
    const content = (cf?.content ?? []).filter(({ item }) => item);
    if (preselectedActivity && content.length) {
      const idx =
        section === 'top25' && subsectionIsVenues
          ? content.findIndex(({ item }) => item.id === preselectedActivity)
          : -1;

      if (idx > -1) {
        setPreselectedActivityIndex(idx);
      }

      const p = getLocationParams(history.location);

      delete p.presel;
      let searchStr = '';
      if (Object.keys(p).length > 0) {
        searchStr = `?${qs.stringify(p)}`;
      }
      history.push(`${history.location.pathname}${searchStr}`);
    }
  }, [cf?.content, history, preselectedActivity, section, subsectionIsVenues]);

  const userBeenThere = useMemo(() => {
    if (
      !currentUser ||
      section !== 'top25' ||
      !cf?.content ||
      ![ExploreSubsection.ACTIVITIES, ExploreSubsection.DAY_TRIPS].includes(subsection)
    )
      return null;

    const items = cf.content
      .filter((x) => x.type === CONTENT_TYPE.ITEM)
      .map(({ item }) => item)
      .slice(0, 25);

    const total = items.filter(({ userReview }) => userReview).length;
    return (
      <BeenThere beenThereCount={total} totalCount={items.length} isLoading={!cf || (cf?.loading && !cf.refreshing)} />
    );
  }, [cf, currentUser, section, subsection]);

  return (
    <>
      <MetaTags title={pageTitle} description={description} schema={pageSchema} />
      <Explore
        sections={sections}
        subsections={subsections}
        tabs={tabs}
        onSectionChange={setSection}
        onSubsectionChange={setSubsection}
        header={header ?? 'TODO'}
        dropdown={dropdown}
        dropdownSide={dropdownSide}
        filterText={distanceFilterString}
        filterTextShort={distanceFilterString}
        locationDetails={locationDetails}
        content={cf?.content ?? []}
        contentLoading={
          (cf?.loading && !cf?.refreshing) ||
          (cf?.content.length &&
            expandedRadius === null &&
            !userHasChangedRadius &&
            section === 'top25' &&
            subsection === ExploreSubsection.ACTIVITIES)
        }
        contentRefreshing={cf?.refreshing}
        noMore={cf?.noMore}
        loadMore={cf?.loadMore}
        renderItemAs={renderItemAs}
        itemExtraProps={extraRenderProps}
        randomCity={randomCity}
        setRandomCity={setRandomCity}
        closestCity={closestCity}
        gotoCity={gotoCity}
        gotoClosestCity={() => gotoCity(closestCity)}
        hideStickiesAnimationDuration={0.5}
        ellipsis={ellipsis}
        faqs={faqCf?.content}
        getSwitchText={getSwitchText}
        isClosestCity={closestCity?.searchLink && linkEqualsCity(closestCity?.searchLink, country, region, city)}
        noItemsMessage={noItemsMessage}
        extraFilterContent={filters}
        wideContent={wideContent}
        venuesForMap={venuesForMap}
        preselected={preselectedActivityIndex}
        userBeenThere={userBeenThere}
        signUpItemNamePlural={signUpItemNamePlural}
        mapCardRenderFunction={mapCardRenderFunction}
        bottomSwitch={bottomSwitch}
        hideSeeMore={hideSeeMore}
        centerScrollTest={centerScrollTest}
        contentFilter={cfFilter}
        locationId={locationId}
        hideBottomButtons={hideBottomButtons}
        placeName={placeName}
        showLocationDetails={showLocationDetails}
        contentMarginTop={contentMarginTop}
        noSyncScroll={noSyncScroll}
      >
        {contentOverride}
      </Explore>
    </>
  );
};

export default PageDetails;
