import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  ITEMS_CLEAR,
  REMOVE_ITEM,
  ITEMS_INVALIDATE,
  REMOVE_FROM,
  ADD_TO,
  ITEMS_REFRESHING,
  ITEMS_REFRESHED,
  ITEMS_FAILED,
} from '../../types';
import contextReducer from './items/contextReducer';

/**
 * Reducer for contentFactoryData.items.
 *
 * @param {object} state the current items object
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx
 * @param {string} action.payload.context the context that the items are being added to. For example, a top 25 page or
 *                                        recommended venue section
 *
 * @returns {object} the new items object
 */
const itemsReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEMS_REQUESTED:
    case ITEMS_RECEIVED:
    case ITEMS_CLEAR:
    case ITEMS_REFRESHING:
    case ITEMS_REFRESHED:
    case ITEMS_FAILED: {
      const { context } = action.payload;
      return { ...state, [context]: contextReducer(state[context], action) };
    }
    case REMOVE_ITEM:
    case REMOVE_FROM:
    case ADD_TO:
    case ITEMS_INVALIDATE: {
      return Object.keys(state).reduce(
        (acc, cur) =>
          Object.assign(acc, {
            [cur]: contextReducer(state[cur], { ...action, payload: { ...action.payload, context: cur } }),
          }),
        {}
      );
    }
    default:
  }
  return state;
};

export default itemsReducer;
