import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './UserListPopup.module.scss';
import VBSpinner from '../VBSpinner/VBSpinner';
import UserList from '../UserList/UserList';
import { PropTypesVBContentFactoryReturn } from '../../util/types';
import { isKids } from '../../util/sites';
import { getProfileLocation } from '../../util/location';
const propTypes = {
  /** content factory to get users from */
  contentFactory: PropTypesVBContentFactoryReturn.isRequired,

  /** function to create a node from a user to put to the right of the listing. Takes the user as the argument and
   *  returns a node
   */
  buildActionArea: PropTypes.func.isRequired,

  /** sort function to use on users. See Array.prototype.sort */
  sort: PropTypes.func,
};
const defaultProps = {
  sort: undefined,
};

/**
 * A list of users which appears in a popup. This list has a scrollbar built in.
 *
 * @param {object} props
 */
const UserListPopup = (props) => {
  const { buildActionArea, contentFactory, sort, ...other } = props;

  // Refs used for infinite scroll.
  const bottomRef = useRef(null);
  const listRef = useRef(null);

  // Setup the content factory.
  const { content, loading, loadMore, noMore, refreshing } = contentFactory;

  /**
   * Handles scroll in the friends list.
   */
  const onScroll = () => {
    const bottomBox = bottomRef.current.getBoundingClientRect();
    const listBox = listRef.current.getBoundingClientRect();

    // Load more if the user has reached the bottom.
    if (!loading && !noMore && bottomBox.y < listBox.bottom + 64) {
      loadMore();
    }
  };

  // It is possible that the initial load will not include enough friends to have a scrollbar. Because the load more is
  // triggered by an onScroll, more friends will never load. So, load friends until there is a scrollbar.
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!loading && listRef.current.scrollHeight === listRef.current.clientHeight && !noMore) {
      loadMore();
    }
  }, [content, loading, loadMore, noMore]);

  // Create the bottom element.
  const bottom = (
    <div ref={bottomRef} style={{ textAlign: 'center' }}>
      {loading ? <VBSpinner /> : null}
    </div>
  );

  const buildSubtitle = (user) => (
    <div className={styles.subtitle}>
      <div>{getProfileLocation(user)}</div>
      <div>
        {typeof user.mutualFriends === 'number' ? `${user.mutualFriends} Mutual Friends` : null}
        {' | '}
        {typeof user.expertPoints === 'number'
          ? `${user.expertPoints} ${isKids() ? 'Parent Points' : 'Expert Points'}`
          : null}
      </div>
    </div>
  );

  const items = content.map(({ item }) => item);
  if (sort) {
    items.sort(sort);
  }

  return (
    <UserList
      ref={listRef}
      users={items}
      scroll={content.length}
      buildActionArea={buildActionArea}
      buildSubtitle={buildSubtitle}
      bottom={bottom}
      onScroll={onScroll}
      {...other}
    />
  );
};
UserListPopup.propTypes = propTypes;
UserListPopup.defaultProps = defaultProps;
export default UserListPopup;
