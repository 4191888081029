import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import { useVBBreakpoint } from '../../../../../shared-react/src/hooks/vb-breakpoint';
import MetaTags from '../../../../../shared-react/src/components/MetaTags/MetaTags';
import { isKids } from '../../../../../shared-react/src/util/sites';
import React from 'react';

import SearchBar from '../SearchBar/SearchBar';

import styles from './PageHome.module.scss';
import Branding from '../../../../../shared-react/src/config/branding';
import HomepageBottomZZ from './HomepageBottomZZ/HomepageBottomZZ';
import HomepageBottomTF from './HomepageBottomTF/HomepageBottomTF';
import VBCarousel from '../../../../../shared-react/src/components/CarouselWrapper/CarouselWrapper';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import HomeBubble from './HomeBubble/HomeBubble';
import IconPlayground from '../../../assets/images/icons/swing-set.svg';
import IconEvent from '../../../assets/images/icons/event-calendar-icon.svg';
import IconLocation from '../../../assets/images/icons/location.svg';
import IconCar from '../../../assets/images/icons/car.svg';
import IconGame from '../../../assets/images/icons/gamepad.svg';

/**
 * The homepage.
 */
const PageHome = () => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { lteMd: tabletStyle, lteXs: mobileStyle } = useVBBreakpoint();

  const mainBackground =
    Branding.HOMEPAGE_IMAGES.length > 1 ? (
      <VBCarousel
        autoPlay
        interval={10000}
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
      >
        {Branding.HOMEPAGE_IMAGES.map((image, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={styles.backgroundImage}
            style={{ backgroundImage: `url("${image}")`, height: '80vh' }}
          />
        ))}
      </VBCarousel>
    ) : (
      <div
        className={styles.backgroundImage}
        style={{ backgroundImage: `url("${Branding.HOMEPAGE_IMAGES[0]}")`, height: '80vh' }}
      />
    );

  const zuzuBubbles = isKids() ? (
    <div className={styles.zuzuBubbles}>
      <HomeBubble
        title="Things To Do"
        link="/activities"
        icon={IconLocation}
        alt="location icon"
        iconHeight="21"
        iconWidth="18"
      />
      <HomeBubble
        title="Playgrounds"
        link="/playgrounds"
        icon={IconPlayground}
        alt="swingset icon"
        iconHeight="21"
        iconWidth="31"
      />
      <HomeBubble
        title="Itineraries"
        link="/itineraries"
        icon={IconEvent}
        alt="event calendar icon"
        iconHeight="21"
        iconWidth="21"
      />
      <HomeBubble title="Day Trips" link="/daytrips" icon={IconCar} alt="car icon" iconHeight="21" iconWidth="21" />
    </div>
  ) : null;

  return (
    <>
      <MetaTags
        title={`${Branding.SITE_NAME}: ${Branding.TAGLINE}`}
        excludeTitlePostfix
        description={Branding.SITE_ABOUT}
      />
      <div
        className={mergeClassNames(
          styles.contentContainer,
          tabletStyle ? styles.tablet : null,
          mobileStyle ? styles.mobile : null
        )}
      >
        <div className={styles.mainBackground}>{mainBackground}</div>
        <div className={styles.splash}>
          <div className={mergeClassNames(styles.content)}>
            <h1 className={mergeClassNames(styles.tagline)}>{Branding.TAGLINE}</h1>
            <div className={mergeClassNames(styles.searchBar)}>
              <SearchBar
                include="e"
                onExploreClick={() => sendAnalyticsEvent('vb_homepage_explore', 'vb_homepage')}
                initToNearest
                cities
                venues
                border
                fullWidth={mobileStyle}
                height={60}
                maxWidth={500}
              />
            </div>
            {zuzuBubbles}
          </div>
        </div>
      </div>
      {isKids() ? <HomepageBottomZZ /> : <HomepageBottomTF />}
    </>
  );
};
PageHome.propTypes = {};

export default PageHome;
