import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageIcon from '../../../assets/images/icons/glyphs/covid-low-risk.svg';

const propTypes = {
  /** css string for the size of the icon */
  size: PropTypes.string,

  /** additional style for the icon */
  style: PropTypes.object,
};

const defaultProps = {
  size: '1.5rem',
  style: undefined,
};

/**
 * Covid low risk badge with tooltip.
 *
 * @param {object} props
 * @returns
 */
const CovidLowRisk = ({ size, style, ...other }) => {
  const trigger = useMemo(() => ['hover', 'focus'], []);

  return (
    <OverlayTrigger overlay={<Tooltip>Outdoor Venue</Tooltip>} trigger={trigger} role="tab" tabIndex="0">
      <img
        alt="Outdoor"
        src={ImageIcon}
        style={Object.assign(size ? { width: size, height: size, minHeight: size, minWidth: size } : {}, style)}
        {...other}
      />
    </OverlayTrigger>
  );
};

export default CovidLowRisk;

CovidLowRisk.propTypes = propTypes;
CovidLowRisk.defaultProps = defaultProps;
