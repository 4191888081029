import dayjs from 'dayjs';

/**
 * Format a date for event use.
 *
 * @param {number} start unix timestamp, s
 * @param {number} end unix timestamp, s
 * @returns {string}
 */
export const formatEventDate = (start, end) => {
  const FMT = 'MMM D, h:mm A';

  const startObj = dayjs(start * 1000);
  const endObj = end ? dayjs(end * 1000) : null;
  return `${startObj.format(FMT)}${endObj ? ` - ${endObj.format(FMT)}` : ''}`;
};
