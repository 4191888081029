import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VBScrollContainer from '../VBScrollContainer/VBScrollContainer';
import VBSpinner from '../VBSpinner/VBSpinner';
import VBTextArea from '../VBTextArea/VBTextArea';
import Header from './Header/Header';
import Comment from './Comment/Comment';
import styles from './Comments.module.scss';
import { PropTypesVBComment } from '../../util/types';
import useEmbeddedBottomWatcher from '../../hooks/embedded-bottom-watcher';
import NoContent from '../NoContent/NoContent';

const propTypes = {
  /** array of PropTypesVBComment to render */
  comments: PropTypes.arrayOf(PropTypesVBComment).isRequired,

  /** whether more comments are loading */
  loading: PropTypes.bool,

  /** whether there are no more comments to load */
  noMore: PropTypes.bool,

  /** the title of the popup */
  title: PropTypes.string,

  /** the max length of the comment input */
  maxLength: PropTypes.number,

  /** function to call to submit the comment. Takes the content of the new comment as the only argument. Should return
   *  a promise
   */
  onRequestSubmit: PropTypes.func,

  /** function to generate a report icon. Takes the comment as an argument, should return a node */
  report: PropTypes.func,

  /** function to call to like a comment. Takes the comment as the argument. Should return a promise */
  onRequestLike: PropTypes.func,

  /** function to call when more comments should be loaded. Should return a promise */
  onRequestLoadMore: PropTypes.func,

  /** function to call when the back button is clicked. If this is omitted, the back button will not be shown */
  onBackButtonClick: PropTypes.func,

  /** component to render for comment replies. The component should take two props, commentId and replyCount. If this is
   *  omitted, the replies toggle option will not be shown
   */
  replies: PropTypes.elementType,

  /** the ID of the current user */
  currentUserId: PropTypes.number,

  /** the add comment icon */
  addIcon: PropTypes.node,

  /** the go back icon */
  backIcon: PropTypes.node,

  author: PropTypes.node,
};

const defaultProps = {
  loading: false,
  noMore: false,
  title: undefined,
  maxLength: undefined,
  onRequestSubmit: undefined,
  report: undefined,
  onRequestLike: undefined,
  onRequestLoadMore: undefined,
  onBackButtonClick: undefined,
  replies: undefined,
  currentUserId: undefined,
  addIcon: undefined,
  backIcon: undefined,
  author: undefined,
};

/**
 * A general purpose comment container.
 *
 * @param {object} props
 */
const Comments = ({
  comments,
  loading: commentsLoading,
  noMore,
  title,
  maxLength,
  onRequestSubmit,
  report,
  onRequestLike,
  onRequestLoadMore: handleLoadMore,
  onBackButtonClick,
  replies,
  currentUserId,
  addIcon,
  backIcon,
  author,
}) => {
  // The current input value of the text box.
  const [commentInputValue, setCommentInputValue] = useState('');

  // Whether comment creation is loading.
  const [commentCreateLoading, setCommentCreateLoading] = useState(false);

  /**
   * Handle change in comments input.
   *
   * @param {SyntheticEvent} e the event
   */
  const commentInputChange = (e) => {
    const el = e.target;
    if (el.value === commentInputValue) {
      return;
    }
    setCommentInputValue(el.value);
  };

  /**
   * Create a new comment.
   */
  const submitComment = () => {
    if (commentCreateLoading || !commentInputValue) {
      return;
    }
    setCommentCreateLoading(true);
    onRequestSubmit(commentInputValue)
      .catch((err) => console.error(err))
      .finally(() => {
        setCommentCreateLoading(false);
      });
    setCommentInputValue('');
  };

  const addCommentContainer = onRequestSubmit ? (
    <span className={styles.addComment} role="button" tabIndex={0} onClick={submitComment}>
      {addIcon ?? '+'}
    </span>
  ) : null;

  const { bottomRef, listRef, onScroll } = useEmbeddedBottomWatcher(handleLoadMore, commentsLoading, noMore);

  return (
    <div className={styles.container}>
      <Header title={title} onBackButtonClick={onBackButtonClick} backIcon={backIcon} />
      <div className={styles.bottom}>
        <div className={styles.textArea}>
          <VBTextArea
            onChange={commentInputChange}
            className={styles.input}
            placeholder="Add Comment"
            value={commentInputValue}
            disabled={commentCreateLoading}
            maxLength={maxLength}
            interceptNewLine={submitComment}
            grow
          />
          {!commentCreateLoading ? (
            addCommentContainer
          ) : (
            <span className={styles.spinnerWrapper}>
              <VBSpinner />
            </span>
          )}
        </div>
        {Boolean(author) && <div className={styles.author}>Author: {author}</div>}
      </div>
      <VBScrollContainer className={styles.list} onScroll={onScroll} ref={listRef}>
        <div className={styles.inner}>
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              report={report}
              onRequestLike={onRequestLike}
              currentUserId={currentUserId}
              replies={replies}
            />
          ))}
          {commentsLoading && <VBSpinner center />}
          <div ref={bottomRef} />
          {comments.length <= 0 && !commentsLoading && <NoContent typePlural="comments" />}
        </div>
      </VBScrollContainer>
    </div>
  );
};

export default Comments;

Comments.propTypes = propTypes;

Comments.defaultProps = defaultProps;
