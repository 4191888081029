import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserListEntry.module.scss';
import { boolProp, mergeClassNames } from '../../../util/props';
import { PropTypesVBBool, PropTypesVBUser, PropTypesVBUserListingSize } from '../../../util/types';
import UserListing from '../../UserListing/UserListing';

/**
 * An entry in a UserList.
 *
 * @param {object} props.user the user to show
 * @param {boolean} props.link whether the entry should link to the user's profile
 * @param {node} props.actionArea the node to show in the entry's action area
 * @param {string[]} props.subtitle The user's subtitle
 * @param {string} props.nameClassName additional classNames for the name element
 * @param {string} props.listingSize size passed into UserListing
 */
const UserListEntry = (props) => {
  const { user, link, actionArea, subtitle, nameClassName, listingSize } = props;

  return (
    <div className={mergeClassNames(styles.container, styles[listingSize])}>
      <UserListing
        nameClassName={nameClassName}
        subtitle={subtitle}
        noLink={!boolProp(link)}
        user={user}
        className={styles.left}
        size={listingSize}
      />
      {actionArea ? <div className={styles.actionArea}>{actionArea}</div> : null}
    </div>
  );
};
export default UserListEntry;
UserListEntry.propTypes = {
  user: PropTypesVBUser.isRequired,
  link: PropTypesVBBool,
  actionArea: PropTypes.node,
  subtitle: PropTypes.node,
  nameClassName: PropTypes.string,
  listingSize: PropTypesVBUserListingSize,
};
UserListEntry.defaultProps = {
  link: false,
  actionArea: undefined,
  subtitle: null,
  nameClassName: '',
  listingSize: 'lg',
};
