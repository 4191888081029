import SITES from '../../config-json/sites.json';
import { SITE_INFO } from '../config/sites';
import Cookies from 'js-cookie';
import { isUndefined } from './objects';
import { ENV_DEV, ENV_LIVE, ENV_STAGING, ENV_TESTING, vbEnv } from './env';

export const isKids = () => {
  return getSite() === SITES.KIDS;
};

export const isViewbuff = () => {
  return getSite() === SITES.VIEWBUFF;
};

/**
 * All the site names in an ordered array.
 */
export const getSitesArray = () => {
  // Take the entries of VB_SITES, sort by value and returns the keys as strings.
  return Object.entries(SITES)
    .filter(([k, v]) => typeof v === 'number')
    .sort((a, b) => a[1] - b[1])
    .map(([key]) => key);
};

export const getSite = () => {
  const cookie = Cookies.get('vb-test-site-id');
  const testSite = isUndefined(cookie) ? cookie : parseInt(cookie);
  return testSite ?? VB_SITE;
};

/** Name of site in caps ex. VIEWBUFF */
export const getSiteName = () => {
  return getSitesArray()[getSite()];
};

/** The pretty name of the site IE. ViewBuff, etc. */
export const getSitePrettyName = (siteName) => {
  return SITE_INFO[siteName].PRETTY_NAME;
};

/**
 * Given the site name (VIEWBUFF, TRIPFOOL, KIDS) return the live site address.
 * @param {string} siteName
 * @returns {string}
 */
export const getLiveSiteUrl = (siteName = getSiteName()) => SITE_INFO[siteName].LIVE_SITE;
export const getStagingSiteUrl = (siteName = getSiteName()) => SITE_INFO[siteName].STAGING_SITE;
export const getDevSiteUrl = (siteName = getSiteName()) => SITE_INFO[siteName].DEV_SITE;

export const getSiteNames = () => {
  return getSitesArray();
};

/**
 * @returns {string[]} The names of the all sites except the current one in all caps.
 */
export const getOtherSiteNames = () => {
  const sites = getSitesArray();
  const siteIndex = sites.indexOf(getSiteName());
  return sites.slice(0, siteIndex).concat(sites.slice(siteIndex + 1));
};

/**
 * Get the URL for the site for the current environment you are in.
 * IE. If you are in a dev environment and want the ViewBuff URL, it will be
 * localhost:8080. In the staging environment it will be staging.viewbuff.com.
 */
export function getSiteUrlByCurrentEnv(siteName) {
  return getSiteUrlByEnv(siteName, vbEnv);
}
export function getSiteUrlByEnv(siteName = getSiteName(), env = vbEnv) {
  switch (env) {
    case ENV_DEV:
    case ENV_TESTING:
      return getDevSiteUrl(siteName);
    case ENV_STAGING:
      return getStagingSiteUrl(siteName);
    case ENV_LIVE:
      return getLiveSiteUrl(siteName);
    default:
      throw new Error('Unknown env: ' + env);
  }
}
