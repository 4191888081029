/* eslint-disable react/no-unused-prop-types */
import React, { useMemo, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import DynamicMeta from './DynamicMeta/DynamicMeta';

/**
 * This component helps generate meta and OpenGraph tags so that
 * the 4 required OpenGraph tags are included for all pages.
 * @param {string} title  The title of the page.
 * @param {object} schema Structured Data https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
 * @param {string} canonicalLink Canonical Link https://developers.google.com/search/docs/crawling-indexing/consolidate-duplicate-urls
 * @param {string} ... Any additional props will also become regular meta tags.
 * @param {bool} excludeTitlePostfix not meta, this just controls whether the site name is appended to the title
 */
const MetaTags = (initProps) => {
  // Don't directly modify props to avoid weird behavior.
  const { excludeTitlePostfix, ...props } = { ...initProps };

  const { brandingConfig } = useContext(VBReactContext);

  if (!props.title) {
    props.title = `${brandingConfig.SITE_NAME}: ${brandingConfig.TAGLINE}`;
  } else if (!excludeTitlePostfix) {
    props.title = props.title + (excludeTitlePostfix ? '' : ` - ${brandingConfig.SITE_NAME}`);
  }

  const tags = Object.keys(props).map((key) => {
    const value = props[key];

    if (!value) {
      return null;
    }

    if (key === 'title') {
      return <title key={key}>{value}</title>;
    }

    if (key === 'schema') {
      return (
        <script key={key} type="application/ld+json">
          {JSON.stringify(value)}
        </script>
      );
    }

    if (key === 'canonicalLink') {
      return <link key={key} rel="canonical" href={new URL(value, location.origin).href} />;
    }

    return <meta name={key} content={value} key={key} />;
  });

  return (
    <>
      <Helmet>{tags}</Helmet>
      <DynamicMeta />
    </>
  );
};

MetaTags.propTypes = {
  title: PropTypes.string,
  excludeTitlePostfix: PropTypes.bool,
};

MetaTags.defaultProps = {
  // Default is handled in code.
  title: null,
  excludeTitlePostfix: false,
};

export default MetaTags;
