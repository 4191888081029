export const getCroppedImg = (src, crop, scale) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      // New lines to be added
      canvas.width = crop.width / scale;
      canvas.height = crop.height / scale;
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x / scale,
        crop.y / scale,
        crop.width / scale,
        crop.height / scale,
        0,
        0,
        crop.width / scale,
        crop.height / scale
      );

      resolve(canvas.toDataURL());
    };
    image.src = src;
  });
};
