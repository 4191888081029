import { stringify } from 'query-string';
import APIConfig from '../config/api';
import { ContentTypes } from '../config/content-types';
import { useContentFactory } from './content-factory';

const useRandomCities = ({ exclude = [], size = 5, hasTrivia = false }) => {
  const context = `random-cities:${size}-[${exclude}]`;

  const { content } = useContentFactory({
    context,
    type: ContentTypes.randomCities.type,
    getQueryAddress: () => {
      const queryParams = { size, exclude, hasTrivia };

      return `${APIConfig.NAMESPACE.SEARCH}/random-cities?${stringify(queryParams, { arrayFormat: 'bracket' })}`;
    },
    getItems: ({ cities }) => cities.map((city) => ({ item: city })),
  });

  return content;
};

export default useRandomCities;
