import ReactGA from 'react-ga4';
import { GOOGLE_ADS, GOOGLE_ANALYTICS, HOTJAR } from '../../../tripfool/src/config/analytics';
import { isLive } from '../util/env';
import { getSite } from '../util/sites';
import { hotjar } from 'react-hotjar';
import { useCallback } from 'react';

/**
 * Send a Google Analytics event.
 *
 * @param {string} event
 * @param {string} category
 * @param  {object} other {value: number, nonInteraction: boolean}
 */
export const useSendAnalyticsEvent = () => {
  return useCallback((event, category = 'general', other = {}) => {
    if (!isLive()) return;

    const googleAnalytics = GOOGLE_ANALYTICS[getSite()];
    if (googleAnalytics) {
      ReactGA.event({
        category: category,
        action: event,
        value: other.nonInteraction,
        nonInteraction: other.nonInteraction,
      });
    }

    const hotjarConfig = HOTJAR[getSite()];
    if (hotjarConfig) hotjar.event(event);

    const googleAds = GOOGLE_ADS[getSite()];
    const conversion = googleAds?.conversions[event];
    if (googleAds && conversion && isLive()) {
      window.gtag('event', 'conversion', { send_to: `${googleAds.tag}/${conversion}` });
    }
  }, []);
};

export default useSendAnalyticsEvent;
