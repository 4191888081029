import { Suspense, lazy, useMemo } from 'react';
import { stringify } from 'query-string';
import VBSpinner from '../../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import { useContentFactory } from '../../../../../../shared-react/src/hooks/content-factory';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { ITINERARY_SEASONS } from './config';

const PopupController = lazy(() => import('./PopupController/PopupController'));

const PopupItinerary = ({ locationDetails }) => {
  const locationId = locationDetails?.id;

  const { content, loading, refreshing } = useContentFactory({
    type: ContentTypes.itinerary.type,
    context: `itinerary:${locationId}`,
    disabled: !locationId,
    filter: useMemo(() => ({ locationId }), [locationId]),
    getQueryAddress: (_, f) => {
      const params = {
        locationId: f.locationId,
      };

      return `${APIConfig.NAMESPACE.ITINERARY}?${stringify(params)}`;
    },
    getItems: ({ itineraries }) =>
      itineraries.map((itinerary) => ({
        item: {
          ...itinerary,
          season: itinerary.season,
        },
      })),
  });

  return (
    <Suspense fallback={<VBSpinner center />}>
      <PopupController
        itineraries={content}
        locationDetails={locationDetails}
        isLoading={loading}
        isRefreshing={refreshing}
      />
    </Suspense>
  );
};

export default PopupItinerary;
