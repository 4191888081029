import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import VBButton from '../../../../../shared-react/src/components/VBButton/VBButton';
import VBTextArea from '../../../../../shared-react/src/components/VBTextArea/VBTextArea';
import { uppercaseFirst } from '../../../../../shared-react/src/util/string';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import { useVBBreakpoint } from '../../../../../shared-react/src/hooks/vb-breakpoint';
import { useClosePopup } from '../../../../../shared-react/src/hooks/popup';
import { invalidateList, upsertItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import { vbRequest } from '../../../util/api';
import APIConfig from '../../../../../shared-react/src/config/api';
import Branding from '../../../../../shared-react/src/config/branding';
import { pointsToast } from '../../../util/toast';
import { PropTypesVBTrip } from '../../../util/types';
import MaxLengths from '../../../../../shared-react/config-json/content-lengths.json';
import { isUndefined } from '../../../../../shared-react/src/util/objects';
import SiteSelector from '../../../../../shared-react/src/components/SitesSelector/SitesSelector';
import SITES from '../../../../../shared-react/config-json/sites.json';
import EmojiSelector from '../../../../../shared-react/src/components/EmojiSelector/EmojiSelector';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import { getSite, isKids } from '../../../../../shared-react/src/util/sites';
import useSendAnalyticsEvent from '../../../../../shared-react/src/hooks/send-analytics-event';
import Author from './Author/Author';
import SearchBar from '../SearchBar/SearchBar';

import styles from './PopupMakePost.module.scss';

const propTypes = {
  /**
   * Pass in a trip here if this post is for a trip
   */
  trip: PropTypesVBTrip,
};

const defaultProps = {
  trip: null,
};

/**
 * Content of the popup for making a new post. If the user is on a venue
 * details page, then the venue information should autofill by default.
 * Otherwise, it is up to the user to select a venue.
 */
const PopupMakePost = ({ trip }) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const { currentUser, globalLocation } = useSelector((state) => ({
    currentUser: state.currentUser?.user,
    globalLocation: state.globalLocation?.location,
  }));

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [postCaption, setPostCaption] = useState('');
  const [author, setAuthor] = useState(currentUser);

  const [sites, setSites] = useState(0);

  const [isCropping, setIsCropping] = useState(false);
  const [cropParams, setCropParams] = useState(null);
  const [emojiRating, setEmojiRating] = useState(null);

  const closePopup = useClosePopup();
  const dispatch = useDispatch();

  const {
    brandingConfig: { RATING_EMOJIS },
    vbBlue,
  } = useVbContext();

  const { gteMd } = useVBBreakpoint();

  const globalLocationName = [globalLocation?.city, globalLocation?.regionShort].filter(Boolean).join(', ');
  const hasUserProductCapability = Boolean(currentUser.hasProductCapability);
  const selectedLocationSite = selectedLocation?.site;

  const canSubmit = Boolean(
    postCaption.trim() && !isCropping && selectedLocation && (selectedLocation.isVenue ? emojiRating : true)
  );

  const handlePhotoUpload = useCallback((files) => {
    // User uploaded the file
    const [file] = files;
    setPhotoFile(file);
  }, []);

  const postDetails = useMemo(() => {
    if (typeof selectedLocation === 'string') {
      // User entered custom text for venue
      return {
        postTitle: selectedLocation,
      };
    }

    // User selected a real venue
    return {
      venueOrLocationId: selectedLocation?.id,
      isVenue: selectedLocation?.isVenue,
      postTitle: selectedLocation?.name,
    };
  }, [selectedLocation]);

  const submitPostImage = useCallback(async () => {
    const formData = new FormData();

    formData.append('file', photoFile);
    formData.append('title', postDetails.postTitle);
    formData.append('caption', postCaption);
    formData.append('author_id', author.id);

    const hasCropParams = Boolean(cropParams);

    if (hasCropParams) {
      formData.append('x', cropParams.x);
      formData.append('y', cropParams.y);
      formData.append('w', cropParams.width);
      formData.append('h', cropParams.height);
    }

    try {
      const response = await vbRequest(`${APIConfig.NAMESPACE.MEDIA}/add`, {
        method: 'POST',
        contentType: false,
        body: formData,
      });

      return response;
    } catch (error) {
      console.error(error);
      setErrorMessage(`${uppercaseFirst(error?.message ?? 'unknown error.')}`);
    }
  }, [author.id, cropParams, photoFile, postCaption, postDetails.postTitle]);

  const submitPostDetails = useCallback(
    async (mediaCollectionId) => {
      const hasVenueOrCityId = Boolean(postDetails.venueOrLocationId);

      const endpoint = hasVenueOrCityId
        ? `${APIConfig.NAMESPACE.POST}/vb-or-location-id/${postDetails.venueOrLocationId}`
        : `${APIConfig.NAMESPACE.POST}/custom`;

      let queryParams = null;

      if (hasVenueOrCityId) {
        queryParams = {
          venue: Number(postDetails.isVenue),
        };
      }

      const requestBody = {
        content: postCaption,
        media_collection_id: mediaCollectionId,
        trip_id: trip?.id,
        custom_name: postDetails.postTitle,
        rating: emojiRating,
        author_id: author.id,
      };

      if (hasUserProductCapability) {
        Object.assign(requestBody, {
          sites: Object.entries(SITES)
            .filter(([k, v]) => typeof v === 'number' && (sites & (1 << v)) > 0)
            .map(([k, v]) => v),
        });
      }

      sendAnalyticsEvent('vb_post_create', 'vb_post');

      try {
        const response = await vbRequest(endpoint, {
          method: 'POST',
          body: requestBody,
          params: queryParams,
        });

        return response;
      } catch (error) {
        console.error(error);
        setErrorMessage(`${uppercaseFirst(error?.message ?? 'unknown error.')}`);
      }
    },
    [author, emojiRating, hasUserProductCapability, postCaption, postDetails, sendAnalyticsEvent, sites, trip]
  );

  const handleSubmit = useCallback(async () => {
    if (!canSubmit) {
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const hasPhotoFile = Boolean(photoFile);
    const postImageResponse = hasPhotoFile ? await submitPostImage() : null;
    const postDetailsResponse = await submitPostDetails(postImageResponse?.collection_id);

    setLoading(false);

    if (postDetailsResponse) {
      if (currentUser.id === author.id) {
        pointsToast(postDetailsResponse.expert_points, 'making a post');
      }

      // Done posting, successfully posted.
      setFinished(true);

      // Invalidate the necessary lists.
      if (trip) {
        // Invalidate trip posts list since post is a trip post
        dispatch(
          invalidateList(ContentTypes.tripPost.type, (context) => context.indexOf(`trip-posts:${trip.id}`) === 0)
        );

        dispatch(
          invalidateList(
            ContentTypes.tripPost.type,
            (context) => context === `trip-venue-photos:${selectedLocation.id}`
          )
        );
      } else {
        // Post isn't a trip post
        dispatch(invalidateList(ContentTypes.post.type, (context) => context.indexOf('trending:') === 0));

        if (currentUser) {
          dispatch(
            invalidateList(ContentTypes.post.type, (context) => context.indexOf(`posts:${currentUser.id}`) === 0)
          );
        }
      }

      Object.entries(postDetailsResponse.reviews).forEach(([site, review]) => {
        if (site === getSite().toString()) {
          dispatch(upsertItem(ContentTypes.review.type, review.id, review));
        }
      });

      dispatch(
        invalidateList(ContentTypes.reviewOrSnippet.type, (ctx) => ctx === `reviews-snippets:${currentUser.id}`)
      );
    }
  }, [author, canSubmit, currentUser, dispatch, photoFile, selectedLocation, submitPostDetails, submitPostImage, trip]);

  const handleCaptionChange = (e) => {
    e.preventDefault();
    setPostCaption(e.target.value);
  };

  const handleSelectEmoji = (rating) => {
    if (rating + 1 === emojiRating) {
      setEmojiRating(null);
      return;
    }
    setEmojiRating(rating + 1);
  };

  const handleSelectAutofillItem = useCallback((item) => {
    const locationDetails = item.isVenue
      ? {
          id: item.venue?.vbId,
          site: item.venue?.site,
          emojiRating: item.venue?.userReview?.emojiRating,
          isVenue: true,
        }
      : {
          id: item.location?.id,
          cityName: item.location?.city,
          isVenue: false,
        };

    setSelectedLocation({ searchId: item.id, name: item.name, ...locationDetails });
  }, []);

  const searchBar = useMemo(() => {
    const isTrip = Boolean(trip);

    if (isTrip) {
      return null;
    }

    const placeholder = {
      name: globalLocationName,
      location: globalLocation,
      isVenue: false,
    };

    return (
      <>
        <div className={styles.label}>Venue Name and Location</div>
        <div className={styles.searchWrapper}>
          <SearchBar
            disabled={loading}
            limit={3}
            resultBehavior="set_text"
            defaultPlaceholder={placeholder}
            onSelect={handleSelectAutofillItem}
            cities
            venues
            border
            shadow
          />
        </div>
      </>
    );
  }, [trip, globalLocationName, globalLocation, loading, handleSelectAutofillItem]);

  const authorSelector = useMemo(() => {
    if (!hasUserProductCapability) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.label}>Post Author</div>
        <Author author={author} onSelect={setAuthor} />
      </div>
    );
  }, [author, hasUserProductCapability]);

  const tripVenueSelector = null;

  // Selecting a venue from a trip
  // const getTripVenues = () => {
  //   if (!trip) return null;
  //   const venues = [];
  //   trip.itinerary.days.forEach((day) => {
  //     day.activities.forEach((activity) => venues.push(activity.venue));
  //   });
  //   return venues;
  // };
  // const tripVenues = getTripVenues();

  // const tripVenueSelector = trip && (
  //   <>
  //     <div className={Styles.label}>Select Venue</div>
  //     <div>
  //       <VBDropdownSearch
  //         caret
  //         label="Select a venue"
  //         options={tripVenues.map((v) => ({ label: v.name, value: v, key: v.id }))}
  //         onChange={(v) => handleSelectItem(v)}
  //         selected={selectedLocation}
  //         strict
  //         maxHeight="200px"
  //         allowCustom
  //         maxLength={MaxLengths.ITINERARY_DAY_CUSTOM_ACTIVITY_NAME}
  //       />
  //     </div>
  //   </>
  // );

  const siteSelector = useMemo(() => {
    if (!hasUserProductCapability) {
      return null;
    }

    return <SiteSelector value={sites} options={selectedLocationSite ?? 0} onChange={setSites} />;
  }, [hasUserProductCapability, selectedLocationSite, sites]);

  const photoUploadProps = useMemo(
    () => ({
      disabled: loading,
      allowCrop: true,
      onUpload: handlePhotoUpload,
      onCropParamsChange: setCropParams,
      onIsCroppingChange: setIsCropping,
    }),
    [handlePhotoUpload, loading]
  );

  // TODO: use something like this to display the post.
  // Some styling changes would have to be made to the post card to get this to work.
  // const successContent = (<>
  //     <div>
  //         {post ? <PostCard post={post} /> : null}
  //     </div>
  // </>);

  // Use global location as a default one
  useEffect(() => {
    if (globalLocation) {
      setSelectedLocation({
        id: globalLocation.id,
        searchId: globalLocation.id,
        name: globalLocationName,
        cityName: globalLocation.city,
        isVenue: false,
      });
    }
  }, [globalLocation, globalLocationName]);

  // Update emoji rating.
  useEffect(() => {
    setEmojiRating(selectedLocation?.userReview?.emojiRating ?? null);
  }, [selectedLocation]);

  useEffect(() => {
    if (!isUndefined(selectedLocationSite)) {
      setSites(selectedLocationSite);
    }
  }, [selectedLocationSite]);

  return (
    <div className={mergeClassNames(styles.container, gteMd && styles.gteMdBreakpoint)}>
      {finished ? (
        <div className={styles.successText}>
          <h2>Your post was submitted!</h2>
          <p>Thank you for sharing your favorite views with the {Branding.SITE_NAME_ABV} community!</p>
        </div>
      ) : (
        <>
          <div className={mergeClassNames(styles.part, styles.top)}>
            <div className={styles.headerTitle}>
              {['Hey', selectedLocation?.cityName, 'Parents!'].filter(Boolean).join(' ')}
            </div>
          </div>
          <div className={mergeClassNames(styles.part, styles.bottom)}>
            <div className={styles.column}>
              <div className={mergeClassNames(styles.section, styles.noMarginTop)}>
                {trip ? tripVenueSelector : searchBar}
              </div>
              {authorSelector}
              {Boolean(selectedLocation?.isVenue) && (
                <div className={styles.section}>
                  <div className={styles.label}>Rating</div>
                  <EmojiSelector
                    emojis={RATING_EMOJIS}
                    onSelect={handleSelectEmoji}
                    selected={emojiRating - 1}
                    color={isKids() ? null : vbBlue}
                  />
                </div>
              )}
              <div className={styles.section}>
                <div className={styles.label}>What's up?</div>
                <VBTextArea
                  containerClassName={styles.captionInput}
                  onChange={handleCaptionChange}
                  value={postCaption}
                  maxLength={MaxLengths.POST_CONTENT}
                  placeholder="In my opinion..."
                  showMaxLength
                  disabled={loading}
                  border="grey"
                  height="250px"
                  style={{ fontSize: '1rem' }}
                  photoUpload={photoUploadProps}
                />
              </div>
              {siteSelector}
              {errorMessage.length > 0 && <div className={styles.errMsg}>{errorMessage}</div>}
              <div className={styles.buttons}>
                <div className={styles.buttonContainer}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <VBButton
                      size="med"
                      type={canSubmit && !isCropping ? 'full' : 'empty'}
                      content="Post"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
                <div className={styles.buttonContainer}>
                  <VBButton size="med" type="border" content="Cancel" onClick={() => closePopup()} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PopupMakePost.WIDTH = 'med';
PopupMakePost.propTypes = propTypes;
PopupMakePost.defaultProps = defaultProps;

export default PopupMakePost;
