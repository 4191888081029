import React, { Fragment, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'query-string';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { generateTestAttributes, mergeClassNames, serializeProp, unserializeProp } from '../../util/props';
import styles from './Explore.module.scss';
import Header from './Header/Header';
import Tabs from './Tabs/Tabs';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import StickyColumn from '../StickyColumn/StickyColumn';
import About from './About/About';
import VBSpinner from '../VBSpinner/VBSpinner';
import useBottomWatcher from '../../hooks/bottom-watcher';
import VBButton from '../VBButton/VBButton';
import VBNWaySwitch from '../VBNWaySwitch/VBNWaySwitch';
import useVisibleOnUpscroll from '../../hooks/visible-on-upscroll';
import { trimSlashes } from '../../util/string';
import { ExploreEllipsisPropType } from '../../util/types';
import { CONTENT_TYPE } from '../../config/content-factory';
import { ABOUT_WIDTH, DEFAULT_EXPLORE_SUBSECTION, ExploreSubsection, FILTER_WIDTH } from '../../config/explore';
import SideNavigation from './SideNavigation/SideNavigation';
import useRandomCity from '../../hooks/random-city';
import AnimateFromBottom from '../AnimateFromBottom/AnimateFromBottom';
import useVbContext from '../../hooks/vb-context';
import { useHeaderHeight } from '../../util/size';
import useMapSyncedYScroll from '../../hooks/map-synced-y-scroll';
import useContentAutoScroll from '../../hooks/content-auto-scroll';
import { convertRemToPixels } from '../../util/dimensions';
import { buildRenderItemVenue } from '../../util/map';
import Map from '../MapWrapper/MapWrapper';
import { SIGNED_OUT_MAX_VENUE_ITEMS } from '../../config/explore';
import { isViewbuff } from '../../util/sites';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';
import { isCrawler } from '../../util/env';
import GoogleLoginBadge from '../GoogleLoginBadge/GoogleLoginBadge';
import APIConfig from '../../config/api';
import Cookies from 'js-cookie';
import useWindowScroll from '../../hooks/window-scroll';
import FAQ from '../../../../tripfool/src/components/Master/FAQ/FAQ';
import VBHeading from '../VBHeading/VBHeading';
import { ZuzuGoQueryParam } from '../../../../tripfool/src/components/Master/PageZuzuGo/PageZuzuGo';

const propTypes = {
  /** content to render at the top of the page. Include "%FILTER%" in the string where you want the dropdown to be
   *  placed. Include %HIDDEN_GEMS% to create a link which scrolls to hidden gems. */
  header: PropTypes.string.isRequired,

  /** text to show in the filter trigger in the tabs */
  filterText: PropTypes.string.isRequired,

  /** text to show in the filter trigger in the header */
  filterTextShort: PropTypes.string.isRequired,

  /** content in the filter dropdown */
  dropdown: PropTypes.node,

  /** override for dropdown for the content in the filter column */
  dropdownSide: PropTypes.node,

  /** sections, like Trending and Top 25. These will be included in a switch at the bottom of the page */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      /** value, used for matching and comparison */
      value: PropTypes.string.isRequired,

      /** URL of the section */
      baseUrl: PropTypes.string.isRequired,

      /** pretty name of the section */
      name: PropTypes.string,

      /** meta title */
      title: PropTypes.string,
    })
  ).isRequired,

  /** subsections, like Activities, Experts, and Itineraries. These will be included in tabs at the top of the page */
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      /** value, used for matching and comparison as well as routing. The first subsection will be matched to the
       *  section's baseUrl */
      value: PropTypes.string,

      /** pretty name of the subsection */
      name: PropTypes.string,

      /** meta title */
      title: PropTypes.string,

      /** url to link out to */
      outlink: PropTypes.string,
    })
  ).isRequired,

  tabs: PropTypes.array,

  /** callback for when section has changed */
  onSectionChange: PropTypes.func,

  /** callback for when subsection has changed */
  onSubsectionChange: PropTypes.func,

  /** location details */
  locationDetails: PropTypes.object,

  /** the content array from the content factory */
  content: PropTypes.array.isRequired,

  /** whether or not the content is loading */
  contentLoading: PropTypes.bool,

  /** whether or not the content is refreshing */
  contentRefreshing: PropTypes.bool,

  /** whether or not there are more items */
  noMore: PropTypes.bool,

  /** function to load more item */
  loadMore: PropTypes.func,

  /** component to render items with, passing in elements of content */
  renderItemAs: PropTypes.func,

  /** extra props to pass to each renderItemAs */
  itemExtraProps: PropTypes.object,

  /** the random city object */
  randomCity: PropTypes.object,

  /** setter for random city */
  setRandomCity: PropTypes.func.isRequired,

  /** the closest city object */
  closestCity: PropTypes.object,

  /** function to go to a city, takes the city object as the argument */
  gotoCity: PropTypes.func.isRequired,

  /** function to set the Y offset of the header. Used to make the header disappear and reappear */
  setHeaderYOffset: PropTypes.func,

  /** duration of the show/hide animation for sticky content in seconds */
  hideStickiesAnimationDuration: PropTypes.number,

  /** array of items to put in the ellipsis, or null/empty for no ellipsis. If the resolution is mobile and there are
   *  ellipsis items, showAboutSection must be true
   */
  ellipsis: ExploreEllipsisPropType,

  /** get text to render for switches in content (for example, when the user has selected a split option so the radius
   *  changes mid content) */
  getSwitchText: PropTypes.func,

  /** whether or not the current city is the closest city to the user */
  isClosestCity: PropTypes.bool,

  /** message to render if there are no items */
  noItemsMessage: PropTypes.string,

  /** the collections to render in the about blurb, see vb_search_query_collections. If the resolution is mobile and
   *  there are collections, showAboutSection must be true
   */
  collections: PropTypes.array,

  /** whether or not collections are being loaded */
  areCollectionsLoading: PropTypes.bool,

  /** if children are supplied, the content on the page will be overwritten. Used for city experts */
  children: PropTypes.node,

  /** whether or not show location details in about section */
  showLocationDetails: PropTypes.bool,

  /** node to render above the content */
  extraFilterContent: PropTypes.node,

  /** whether or not the about section should be hidden on the side on desktop */
  wideContent: PropTypes.bool,

  /** section to show in the bottom right of the about section */
  userBeenThere: PropTypes.node,

  /** venues to use for the map, or null for no map */
  venuesForMap: PropTypes.arrayOf(PropTypes.object),

  /** initial selection (index) */
  preselected: PropTypes.number,

  /** "sign up to see more X" */
  signUpItemNamePlural: PropTypes.string,

  /** function to use to render cards on the map */
  mapCardRenderFunction: PropTypes.func,

  bottomSwitch: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    selected: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
  }),

  hideSeeMore: PropTypes.bool,

  centerScrollTest: PropTypes.bool,

  contentFilter: PropTypes.any,

  hideBottomButtons: PropTypes.bool,

  contentMarginTop: PropTypes.string,

  faqs: PropTypes.array,

  isLoadingFaqs: PropTypes.bool,

  noSyncScroll: PropTypes.bool,
};
const defaultProps = {
  onSectionChange: undefined,
  onSubsectionChange: undefined,
  contentLoading: false,
  contentRefreshing: false,
  noMore: false,
  isLoadingFaqs: false,
  loadMore: undefined,
  renderItemAs: undefined,
  itemExtraProps: {},
  randomCity: undefined,
  closestCity: undefined,
  setHeaderYOffset: undefined,
  hideStickiesAnimationDuration: 0,
  ellipsis: [],
  faqs: [],
  dropdown: null,
  getSwitchText: undefined,
  isClosestCity: false,
  noItemsMessage: undefined,
  collections: undefined,
  areCollectionsLoading: false,
  children: undefined,
  extraFilterContent: undefined,
  wideContent: false,
  dropdownSide: undefined,
  userBeenThere: undefined,
  venuesForMap: undefined,
  mapCardRenderFunction: buildRenderItemVenue,
  bottomSwitch: undefined,
  hideSeeMore: false,
  centerScrollTest: false,
  contentFilter: undefined,
  hideBottomButtons: false,
  contentMarginTop: '0',
  noSyncScroll: false,
};

/**
 * Shared component for rendering the Explore page.
 *
 * @param {object} props
 */
const Explore = ({
  header: headerText,
  filterText,
  filterTextShort,
  dropdown: dropdownInner,
  dropdownSide,
  sections,
  subsections,
  tabs,
  onSectionChange,
  onSubsectionChange,
  locationDetails,
  content,
  contentLoading,
  contentRefreshing,
  noMore,
  loadMore,
  renderItemAs: RenderItemAs,
  itemExtraProps,
  randomCity,
  setRandomCity,
  closestCity,
  gotoCity,
  hideStickiesAnimationDuration,
  ellipsis,
  faqs,
  getSwitchText,
  isClosestCity,
  noItemsMessage,
  collections,
  areCollectionsLoading,
  children,
  showLocationDetails,
  extraFilterContent,
  wideContent,
  userBeenThere,
  venuesForMap,
  preselected,
  signUpItemNamePlural,
  mapCardRenderFunction,
  bottomSwitch,
  hideSeeMore,
  centerScrollTest,
  contentFilter,
  hideBottomButtons,
  placeName,
  contentMarginTop,
  noSyncScroll,
}) => {
  const { vbRequest, currentUser, openAuthPopup, errorToast } = useVbContext();
  const headerHeight = useHeaderHeight();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const [googleLoginURL, setGoogleLoginURL] = useState();
  const bottomRef = useRef();
  const featuredContentEndRef = useRef();
  const hiddenGemsScrollRef = useRef();
  const contentContainerRef = useRef();

  // const [userScrolled, setUserScrolled] = useState(false);
  // useWindowScroll(() => setUserScrolled(true));

  const items = useMemo(
    () => content.filter(({ type, item }) => type === CONTENT_TYPE.ITEM || item).map((entry) => entry?.item),
    [content]
  );

  const { lt1280: tabletStyle } = useVBBreakpoint();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const getAutoScrolledContentOffset = useCallback(
    (elementTop) => {
      const HEADER_HIDE_CUTOFF = 300;
      const TABS_HEIGHT = 40;

      if (!tabletStyle) {
        return headerHeight + convertRemToPixels(1);
      }

      if (elementTop <= HEADER_HIDE_CUTOFF) {
        return headerHeight + TABS_HEIGHT + convertRemToPixels(1);
      }

      return TABS_HEIGHT + convertRemToPixels(1);
    },
    [headerHeight, tabletStyle]
  );

  useContentAutoScroll(getAutoScrolledContentOffset, [content]);

  // Current section (top 25, trending, etc).
  const [section, setSection] = useState();

  // Current subsection (activities, itineraries, experts, etc).
  const [subsection, setSubsection] = useState();

  // Whether to limit the number of venues displayed to SIGNED_OUT_MAX_VENUE_ITEMS
  const limitExplore = !currentUser && !isCrawler() && !hideSeeMore;

  const placeholderMapVenues = useMemo(() => [], []);

  const { cardRefs, showMap, mapProps, setSelection, selection } = useMapSyncedYScroll({
    onlyShowIcon: tabletStyle,
    renderItem: useMemo(() => mapCardRenderFunction(), [mapCardRenderFunction]),
    content: limitExplore
      ? venuesForMap?.slice(0, SIGNED_OUT_MAX_VENUE_ITEMS) ?? []
      : venuesForMap || placeholderMapVenues,
    disabled: !venuesForMap,
    syncScroll: !tabletStyle && !noSyncScroll,
    centerScrollTest,
  });

  useWindowScroll(() => {
    if (!noMore && loadMore && items.length > 0 && items.length <= 25) loadMore();
  }, [noMore, loadMore, items.length]);

  const [hasAddedToQueue, setHasAddedToQueue] = useState(false);

  useEffect(() => {
    if (!locationDetails?.id || hasAddedToQueue || selection < 7 || !currentUser) {
      return;
    }

    vbRequest(`${APIConfig.NAMESPACE.MARKETING}/explore-queue`, {
      method: 'POST',
      body: {
        locationId: locationDetails?.id,
      },
    });
    setHasAddedToQueue(true);
  }, [hasAddedToQueue, locationDetails, selection, vbRequest, currentUser]);

  const [isPreselectionUsed, setIsPreselectionUsed] = useState(false);
  useEffect(() => {
    if (typeof preselected !== 'undefined' && !isPreselectionUsed) {
      setSelection(preselected, true);
      setIsPreselectionUsed(true);
    }
  }, [isPreselectionUsed, preselected, setSelection]);

  // Load/refresh the random city.
  // TODO: Avoid the current city.
  const [randomCityRefreshCount, setRandomCityRefreshCount] = useState(0);
  const { city: randomCityResult, isLoading: randomCityLoading } = useRandomCity(randomCityRefreshCount);
  useEffect(() => {
    if (randomCityResult) setRandomCity(randomCityResult);
    if (!randomCity && !randomCityLoading) {
      setRandomCityRefreshCount((x) => x + 1);
    }
  }, [randomCityResult, setRandomCity, randomCityLoading, randomCity]);

  useEffect(() => {
    vbRequest(`${APIConfig.NAMESPACE.SLOGIN}/redirect-url`, { params: { service: 'google' } })
      .then(({ result }) => setGoogleLoginURL(result))
      .catch((err) => {
        errorToast(err.message);
      });
  }, [vbRequest, errorToast]);

  const googleLogin = useMemo(
    () =>
      googleLoginURL
        ? () => {
            Cookies.set('vb-slogin-redirect', location.pathname + location.search);
            window.location = googleLoginURL;
          }
        : null,
    [googleLoginURL]
  );

  const stickiesVisible = useVisibleOnUpscroll({
    scrollContainer: window,
    offset: window.scrollY + featuredContentEndRef?.current?.getBoundingClientRect().y ?? Infinity,
  });

  // Get what the section and subsection should be based on the URL.
  let actualSection;
  let actualSubsection;

  const trimmedPathname = trimSlashes(pathname);

  sections.forEach((sect) => {
    const trimmedBaseUrl = trimSlashes(sect.baseUrl);

    if (subsections.length <= 0) {
      if (trimmedPathname === trimmedBaseUrl) {
        actualSection = sect;
      }
      return;
    }

    subsections.forEach((subSect) => {
      if ((actualSection && actualSubsection) || subSect.outlink) {
        return;
      }

      if (
        (trimmedPathname === trimmedBaseUrl && subSect.value === DEFAULT_EXPLORE_SUBSECTION) ||
        trimmedPathname.includes(`${trimmedBaseUrl}/${subSect.value}`)
      ) {
        actualSection = sect;
        actualSubsection = subSect;
      }
    });
  });

  // If the values in the actual section/subsection differ from the state, execute the callbacks

  const actualSectionValue = actualSection?.value ?? null;
  useEffect(() => {
    setSection(actualSectionValue);
    if (section !== actualSectionValue) {
      if (onSectionChange) onSectionChange(actualSectionValue);
    }
  }, [actualSectionValue, onSectionChange, section]);

  const actualSubsectionValue = actualSubsection?.value ?? null;

  useEffect(() => {
    setSubsection(actualSubsectionValue);
    if (subsection !== actualSubsectionValue) {
      if (onSubsectionChange) onSubsectionChange(actualSubsectionValue);
    }
  }, [actualSubsectionValue, onSubsectionChange, subsection]);

  // Load more items when the user is at the bottom of the page.
  const handleScrollBottom = useCallback(() => {
    if (!noMore && loadMore) loadMore();
  }, [loadMore, noMore]);
  useBottomWatcher(bottomRef, handleScrollBottom);

  const [localSectionSubsection, setLocalSectionSubsection] = useState(`${section}/${subsection}`);
  const [localContentFilter, setLocalContentFilter] = useState(serializeProp(contentFilter));

  // Update the scroll position on changes to the selected item.
  // When the selected item changes, set the scroll position.
  const cfSerialized = serializeProp(contentFilter);
  useEffect(() => {
    if (`${section}/${subsection}` !== localSectionSubsection || cfSerialized !== localContentFilter) {
      setLocalSectionSubsection(`${section}/${subsection}`);
      setLocalContentFilter(cfSerialized);
      setSelection(0);
    }
  }, [localSectionSubsection, section, setSelection, subsection, cfSerialized, localContentFilter]);

  // If item id is in the search string, set it as the selected item (which will scroll to it).
  useEffect(() => {
    // Wait until all images are loaded
    const params = QueryString.parse(search);
    if (params.item) {
      // eslint-disable-next-line eqeqeq
      const itemIndex = items.findIndex(({ id }) => id == params.item);
      if (itemIndex !== -1) {
        setSelection(itemIndex);
        // remove the item id from search
        history.replace(`${pathname}?${QueryString.stringify({ ...params, item: undefined })}`);
      }
    }
  }, [history, items, pathname, search, setSelection]);

  const mapFull = useMemo(
    () => (showMap ? <Map noPadding noShadow allowNullSelection {...mapProps} /> : null),
    [showMap, mapProps]
  );

  const mapPreview = useMemo(
    () => (showMap ? <Map {...mapProps} onlyShowIcon allowNullSelection /> : null),
    [showMap, mapProps]
  );

  const collectionsStr = serializeProp(collections);
  const isHiddenGems = selection > 25 - 1;
  const [isHiddenGemsNextVenueBuffered, setIsHiddenGemsNextVenueBuffered] = useState(false);

  // const handleHiddenGemsClick = useCallback(() => {
  //   if (!currentUser) {
  //     openAuthPopup();
  //   } else {
  //     setSelection(Math.min(items.length - 1, 25), true);
  //     if (items.length <= 25) setIsHiddenGemsNextVenueBuffered(true);
  //   }
  // }, [currentUser, openAuthPopup, setSelection, items.length]);

  useWindowScroll(() => {
    if (isHiddenGemsNextVenueBuffered && cardRefs.length > 25 && selection === 24) {
      setSelection(25, true);
      setIsHiddenGemsNextVenueBuffered(false);
    }
  }, [isHiddenGemsNextVenueBuffered, cardRefs.length, setSelection]);

  const basePath = actualSection?.baseUrl ?? '';

  const about = useMemo(() => {
    const showFaqs = Boolean(faqs.length && locationDetails && !tabletStyle && subsection !== ExploreSubsection.FAQ);

    return (
      <About
        name={`Things To Do with Kids in ${placeName}`}
        locationDetails={locationDetails}
        mapFull={mapFull}
        ellipsis={ellipsis}
        collections={unserializeProp(collectionsStr)}
        areCollectionsLoading={areCollectionsLoading}
        mapPreview={mapPreview}
        hideAbout={!showLocationDetails}
        bottomContent={
          showFaqs ? (
            <div className={styles.faqs}>
              <VBHeading size="xs" className={styles.faqsTitle}>
                Visit {locationDetails.city} FAQ
              </VBHeading>
              <div className={styles.faqsList}>
                {faqs.map((faq) => (
                  <FAQ key={faq.key} faq={faq.item} locationDetails={locationDetails} noPadding />
                ))}
              </div>
            </div>
          ) : null
        }
        content={
          <div className={styles.aboutActionsContainer}>
            <div className={styles.aboutActions}>
              <VBButton
                content="Zuzu Go"
                type="full"
                color="red"
                size="med"
                onClick={() => {
                  if (locationDetails?.id) {
                    history.push(`/zuzu-go?${ZuzuGoQueryParam.LOCATION_ID}=${locationDetails.id}`);
                  }
                }}
              />
              <VBButton
                content="Daily Contest"
                type="full"
                color="red"
                size="med"
                onClick={() => history.push('/daily-travel-contest')}
              />
              <VBButton content="Trips" type="full" color="red" size="med" onClick={() => history.push('/trips')} />
            </div>
          </div>
        }
      />
    );
  }, [
    faqs,
    locationDetails,
    tabletStyle,
    subsection,
    placeName,
    mapFull,
    ellipsis,
    collectionsStr,
    areCollectionsLoading,
    mapPreview,
    showLocationDetails,
    history,
  ]);

  const noItems = useMemo(() => <div className={styles.noItems}>{noItemsMessage}</div>, [noItemsMessage]);

  const gotoRandomCity = () => {
    gotoCity(randomCity, { n: 0 });
    if (!isViewbuff()) setRandomCity(null);
  };

  const seeMore = useMemo(
    () => (
      <div className={mergeClassNames(styles.last)}>
        <VBButton
          content="Sign Up For Free"
          color="red"
          size="lg"
          style={{ marginBottom: '10px' }}
          onClick={() => {
            sendAnalyticsEvent('vb_explore_sign_up_see_more', 'vb_explore');
            openAuthPopup();
          }}
        />
        <span className={styles.or}>- or -</span>
        <GoogleLoginBadge loginLink={googleLogin} />
        {Boolean(locationDetails) && (
          <p className={styles.signUp}>
            to See More {signUpItemNamePlural} in {locationDetails.city}! Plus:
          </p>
        )}
        <ul>
          <li>Save Kids Activities</li>
          <li>Save Your Trip</li>
          <li>Plan With Your Friends</li>
          <li>Follow Your Friends</li>
        </ul>
      </div>
    ),
    [googleLogin, locationDetails, signUpItemNamePlural, sendAnalyticsEvent, openAuthPopup]
  );

  const innerContent = useMemo(() => {
    let currentCardIdx = 0;

    return content.map(({ type, ...fields }, totalIndex) => {
      // Limit explore page to SIGNED_OUT_MAX_VENUE_ITEMS venues on Zuzu if user isn't logged in.
      let lastItem = false;
      if (currentCardIdx > SIGNED_OUT_MAX_VENUE_ITEMS && limitExplore) {
        // (SIGNED_OUT_MAX_VENUE_ITEMS + 1) venues have been passed, so just don't add anything else
        currentCardIdx++;
        return null;
      } else if (currentCardIdx === SIGNED_OUT_MAX_VENUE_ITEMS && limitExplore) {
        // We want to partially show the (SIGNED_OUT_MAX_VENUE_ITEMS + 1)th venue
        if (!(type === CONTENT_TYPE.ITEM || fields.item)) {
          // Don't bother rendering any splits before the (SIGNED_OUT_MAX_VENUE_ITEMS + 1)th venue
          return null;
        }
        lastItem = true;
      }
      let itemContent;
      let splitContent;
      let switchContent;
      if (type === CONTENT_TYPE.ITEM || fields.item) {
        const isFirstCard = currentCardIdx === 0;
        const isSelected = selection === currentCardIdx;

        itemContent = (
          <>
            {isFirstCard ? <div ref={featuredContentEndRef} /> : null}
            <div
              className={mergeClassNames(
                styles.cardContainer,
                contentRefreshing ? styles.refreshing : null,
                !tabletStyle && !noSyncScroll && venuesForMap && isSelected ? styles.selected : null,
                !tabletStyle && !noSyncScroll && venuesForMap && !isSelected ? styles.notSelected : null
              )}
              ref={cardRefs[currentCardIdx]}
              {...generateTestAttributes({
                vbExploreContentType: 'item',
              })}
            >
              {RenderItemAs ? (
                <RenderItemAs
                  {...fields}
                  {...itemExtraProps}
                  isFeatured={isFirstCard}
                  titleH3Seo={true}
                  isSelected={isSelected}
                />
              ) : null}
              {lastItem ? seeMore : null}
            </div>
            {currentCardIdx === 24 ? <div ref={hiddenGemsScrollRef} /> : null}
            {lastItem ? seeMore : null}
          </>
        );
        if (lastItem) {
          itemContent = <div className={styles.lastWrapper}>{itemContent}</div>;
        }
        currentCardIdx++;
      }
      if (type === CONTENT_TYPE.SPLIT || fields.split) {
        splitContent = (
          <div
            style={{ width: '100%' }}
            {...generateTestAttributes({
              vbExploreContentType: 'split',
            })}
          >
            {fields.content}
          </div>
        );
      }
      if (type === CONTENT_TYPE.SWITCH || fields.switched) {
        const { filter } = fields;

        switchContent = (
          <>
            <div
              className={styles.switchText}
              {...generateTestAttributes({
                vbExploreContentType: 'switch',
              })}
            >
              {getSwitchText ? getSwitchText(filter) : 'TODO'}
            </div>
            {totalIndex === content.length - 1 && !contentLoading ? noItems : null}
          </>
        );
      }
      return (
        <Fragment key={JSON.stringify({ x: fields.key ?? fields.filter, i: totalIndex })}>
          {itemContent}
          {splitContent}
          {switchContent}
        </Fragment>
      );
    });
  }, [
    content,
    limitExplore,
    selection,
    contentRefreshing,
    tabletStyle,
    noSyncScroll,
    venuesForMap,
    cardRefs,
    RenderItemAs,
    itemExtraProps,
    seeMore,
    getSwitchText,
    contentLoading,
    noItems,
  ]);

  const prebuiltContent = (
    <>
      {tabletStyle && extraFilterContent}
      {content.filter((x) => x.item).length || !noItemsMessage || contentLoading || contentRefreshing ? null : noItems}
      <div className={styles.innerContent}>{innerContent}</div>
      <div className={styles.bottom} ref={bottomRef}>
        {contentLoading ? <VBSpinner center /> : null}
      </div>
      {/* The random city button here also won't be shown for signed-out users because
      it looks kinda weird under the sign up prompt */}
      {!contentLoading && noMore && !tabletStyle && !limitExplore && !hideBottomButtons ? (
        <div className={styles.buttons}>
          {randomCity && <VBButton type="full" size="med" onClick={gotoRandomCity} content="Random City" />}
          {closestCity && !isClosestCity && (
            <VBButton type="full" size="med" onClick={() => gotoCity(closestCity)} content="Closest City" />
          )}
        </div>
      ) : null}
    </>
  );

  const contentContainerTestData = useMemo(() => ({ id: 'vb-explore-content' }), []);
  const filterContainerTestData = useMemo(() => ({ id: 'vb-explore-filter-desktop' }), []);
  const aboutContainerTestData = useMemo(() => ({ id: 'vb-explore-about' }), []);

  return (
    <div className={!tabletStyle ? styles.desktop : null}>
      <MaxWidthContainer padded style={{ position: 'relative' }}>
        <Header
          filterText={filterTextShort ?? filterText}
          dropdown={dropdownInner}
          header={headerText}
          hiddenGemsRef={hiddenGemsScrollRef}
          hasAboutColumn={!children && !wideContent}
          ellipsis={ellipsis}
          hasTabs={tabs.length}
        />
      </MaxWidthContainer>
      {/* Since there's no more filter dropdown, hide the tab row on 'trending' since there's no tabs there. */}
      {tabletStyle && section !== 'trending' && (
        <Tabs
          filterText={filterText}
          basePath={basePath}
          subsections={tabs}
          visible={stickiesVisible}
          hideStickiesAnimationDuration={hideStickiesAnimationDuration}
          ellipsis={ellipsis}
        />
      )}
      <MaxWidthContainer className={styles.columnContainer}>
        {tabletStyle && Boolean(about) && (
          <MaxWidthContainer padded className={styles.aboutMaxWidthContainer} testData={aboutContainerTestData}>
            {about}
          </MaxWidthContainer>
        )}
        {!tabletStyle && (
          <StickyColumn width={`${FILTER_WIDTH}px`} testData={filterContainerTestData}>
            <SideNavigation
              filterText={filterText}
              basePath={basePath}
              subsection={actualSubsectionValue}
              subsections={subsections}
              dropdown={dropdownSide ?? dropdownInner}
              visible={stickiesVisible}
              hideStickiesAnimationDuration={hideStickiesAnimationDuration}
              ellipsis={ellipsis}
              extraFilterContent={extraFilterContent}
            />
          </StickyColumn>
        )}
        <MaxWidthContainer
          className={styles.content}
          containerRef={contentContainerRef}
          testData={contentContainerTestData}
          style={{ marginTop: contentMarginTop }}
        >
          {children || prebuiltContent}
        </MaxWidthContainer>
        {!tabletStyle && !wideContent && Boolean(about) && (
          <StickyColumn width={`${ABOUT_WIDTH}px`} testData={aboutContainerTestData} isScrollable>
            {about}
          </StickyColumn>
        )}
      </MaxWidthContainer>
      {Boolean(bottomSwitch) && (
        <AnimateFromBottom
          bottomDistance="2rem"
          bottomDistanceMobile="1rem"
          isVisible={stickiesVisible}
          animationDuration={hideStickiesAnimationDuration}
        >
          <div className={styles.fromBottom}>
            <VBNWaySwitch
              options={bottomSwitch.options}
              selected={bottomSwitch.selected}
              onChange={(v) => {
                sendAnalyticsEvent(`vb_explore_switch_${subsection}_${v}`);
                bottomSwitch.handleChange(v);
              }}
              className={styles.switch}
            />
          </div>
        </AnimateFromBottom>
      )}
    </div>
  );
};
export default Explore;

Explore.propTypes = propTypes;
Explore.defaultProps = defaultProps;
