/**
 * Configuration related to the explore page.
 */

// Max values on distances ranges.
export const COMMON_DISTANCE_RANGES = [8, 20, 50, 120];
export const PLAYGROUNDS_DISTANCE_RANGES = [4, 8, 20, 50];

export const AGE_GROUPS = ['1-4 yrs', '4-8 yrs', '8-12 yrs', '12+ yrs'];

export const ABOUT_WIDTH = 427;
export const FILTER_WIDTH = 213;

export const SIGNED_OUT_MAX_VENUE_ITEMS = 8;

/**
 * Configuration related to the explore page on Tripfool.
 */

export const STICKIES_HIDE_ANIMATION_DURATION = 0.5;

export const EVENT_TIME_FRAMES = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'week',
    label: 'This Week',
  },
  {
    value: 'weekend',
    label: 'This Weekend',
  },
];

export const ExploreSubsection = {
  SUMMARY: 'summary',
  ACTIVITIES: 'activities',
  PLAYGROUNDS: 'playgrounds',
  NEWSLETTERS: 'newsletters',
  // EVENTS: 'events',
  ARTICLES: 'articles',
  FESTIVALS: 'festivals',
  // CHAT: 'chat',
  // VIDEOS: 'videos',
  TRIVIA: 'trivia',
  FAQ: 'faq',
  ITINERARIES: 'itineraries',
  DAY_TRIPS: 'daytrips',
};

export const DEFAULT_EXPLORE_SUBSECTION = ExploreSubsection.SUMMARY;
