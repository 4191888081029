import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { getLocationParams } from '../../../../../shared-react/src/util/urls';
import PopupAuth from '../../../../../shared-react/src/components/PopupAuth/PopupAuth';
import { usePopup } from '../../../../../shared-react/src/hooks/popup';
import PopupPost from '../PopupPost/PopupPost';
import PopupFAQ from '../PopupFAQ/PopupFAQ';
import PopupArticle from '../PopupArticle/PopupArticle';

/**
 * A general popup which can show different types of content
 * depending on url parameters.
 */
const PopupGeneral = () => {
  const urlLocation = useLocation();
  const history = useHistory();
  const locationDetails = useSelector((state) => state.globalLocation?.location);

  const params = getLocationParams(urlLocation);

  const contentType = params.show;
  const contentId = parseInt(params.id, 10);

  const innerContent = () => {
    switch (contentType) {
      case 'post':
        return <PopupPost id={contentId} />;
      case 'faq':
        return <PopupFAQ id={contentId} locationDetails={locationDetails} />;
      case 'article':
        return <PopupArticle id={contentId} locationDetails={locationDetails} />;
      // TODO: Eventually the register and login popup should be handled by openAuthPopup (since this will prevent
      // duplicate auth popups and track state in Redux). This should be fine for now, though.
      case 'register':
        return <PopupAuth page="signUp" />;
      case 'login':
        return <PopupAuth page="login" />;
      default:
        return null; // either no popup on current page or invalid type given.
    }
  };

  /**
   * When the popup closes, change the params.
   */
  const handleClose = () => {
    const newParams = { ...params };
    delete newParams.show;
    delete newParams.id;
    history.replace(
      urlLocation.pathname + (Object.keys(newParams).length ? `?${QueryString.stringify(newParams)}` : '')
    );
  };

  const contentPopup = usePopup({ child: innerContent(), onClose: handleClose });

  useEffect(() => {
    if (contentType && !contentPopup.isOpen) {
      contentPopup.open();
    }
    if (!contentType && contentPopup.isOpen) {
      contentPopup.close();
    }
  }, [contentType, contentId, contentPopup]);

  return contentPopup.modal;
};

export default PopupGeneral;
