import { useEffect, useState } from 'react';

/**
 * Returns false, until ref enters the viewport. After it has entered, will always return true (as long as the component
 * is not remounted).
 *
 * @param {React.Ref<HTMLElement>} ref
 *
 * @returns {bool}
 */
const useLazyLoad = (ref) => {
  const [shouldRender, setShouldRender] = useState(false);

  const current = ref?.current;
  useEffect(() => {
    if (!current) return null;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) setShouldRender(true);
    });
    // Sometimes, this is never called unless it is added onto the event loop.
    const timeout = setTimeout(() => observer.observe(current), 0);
    return () => {
      clearTimeout(timeout);
      observer.disconnect();
    };
  }, [current]);

  return shouldRender;
};

export default useLazyLoad;
