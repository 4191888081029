import itemsReducer from './api/itemsReducer';
import contentReducer from './api/contentReducer';
import {
  ITEMS_RECEIVED,
  ITEMS_REQUESTED,
  ITEMS_CLEAR,
  ITEMS_STORE,
  SINGLE_RECEIVED,
  SINGLE_REQUESTED,
  SINGLE_STORE,
  UPDATE_ITEM,
  ADD_ITEM,
  REMOVE_FROM,
  REMOVE_ITEM,
  ITEMS_INVALIDATE,
  ADD_TO,
  ITEMS_REFRESHED,
  ITEMS_REFRESHING,
  CLEAR_TOKEN,
  SET_TOKEN,
  ITEMS_FAILED,
  SINGLE_FAILED,
} from '../types';

const initialState = { items: undefined, content: undefined, singleErrorsUnknownId: {} };
if (window._pmgApiPreload) {
  Object.assign(initialState, window._pmgApiPreload);
}

/**
 * Reducer for content factory data.
 *
 * @param {object} state current state
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx.
 * @param {object} action.payload see itemsReducer.jsx and contentReducer.jsx.
 *
 * @returns {object} the new state
 */
const cfDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_REQUESTED:
    case ITEMS_RECEIVED:
    case ITEMS_CLEAR:
    case ITEMS_STORE:
    case SINGLE_RECEIVED:
    case SINGLE_STORE:
    case UPDATE_ITEM:
    case ADD_ITEM:
    case REMOVE_ITEM:
    case REMOVE_FROM:
    case ITEMS_INVALIDATE:
    case ADD_TO:
    case ITEMS_REFRESHING:
    case ITEMS_REFRESHED:
    case ITEMS_FAILED:
    case SINGLE_REQUESTED:
      return {
        ...state,
        items: itemsReducer(state.items, action),
        content: contentReducer(state.content, action),
      };
    case SINGLE_FAILED: {
      const { address, id, error } = action.payload;
      return {
        ...state,
        singleErrorsUnknownId: id ? state.singleErrorsUnknownId : { ...state.singleErrorsUnknownId, [address]: error },
        content: contentReducer(state.content, action),
        items: itemsReducer(state.items, action),
      };
    }
    case CLEAR_TOKEN:
    case SET_TOKEN:
      return initialState;
    default:
  }
  return state;
};

export default cfDataReducer;
