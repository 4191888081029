import PropTypes from 'prop-types';
import useVBBreakpoint from '../../../../../../../shared-react/src/hooks/vb-breakpoint';
import { addParams } from '../../../../../../../shared-react/src/util/urls';
import ExploreCard from '../../../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import VenueCard from '../../../../../../../shared-react/src/components/VenueCard/VenueCard';
import { mergeClassNames } from '../../../../../../../shared-react/src/util/props';

import styles from './RenderedVenueArticles.module.scss';

const propTypes = {
  item: PropTypes.object.isRequired,
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
  basePath: PropTypes.string,
};

const RenderedVenueArticles = ({ item, exploreLink, exploreLinkText, basePath }) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();

  return (
    <div className={mergeClassNames(styles.container, mobileStyle && styles.mobile)}>
      <VenueCard
        venue={item}
        titleSize="sm"
        exploreLink={exploreLink}
        exploreLinkText={exploreLinkText}
        disablePadding={mobileStyle}
        noBottomPadding
        showDescription
      />
      <ul className={styles.articles}>
        {item.articles.map((article) => {
          const permalink = addParams(`${basePath}/articles/post/${article.postSlug}#${article.slug}`, {
            exploreLink,
            exploreLinkText,
          });

          return (
            <li key={article.id}>
              <ExploreCard
                name={article.heading}
                by={article.author}
                permalink={permalink}
                description={article.content}
                descriptionLink={permalink}
                numberOfLines={4}
                hideReadMore
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RenderedVenueArticles.propTypes = propTypes;

export default RenderedVenueArticles;
