/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import ScrollResetHandler from '../ScrollResetHandler/ScrollResetHandler';

const propTypes = {
  children: PropTypes.node,
};

const VBRouter = ({ children, ...other }) => (
  <BrowserRouter {...other}>
    <ScrollResetHandler />
    {children}
  </BrowserRouter>
);
VBRouter.propTypes = propTypes;

export default VBRouter;
