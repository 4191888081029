import React from 'react';
import PropTypes from 'prop-types';
import SplitContainer from '../SplitContainer/SplitContainer';
import { linkEqualsCity, getSlugsFromLocationLink } from '../../util/location';
import VBButton from '../VBButton/VBButton';
import VBSpinnerButton from '../VBSpinnerButton/VBSpinnerButton';
import useVbContext from '../../hooks/vb-context';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  /** the filter object, can be any key-value pairs */
  filter: PropTypes.object.isRequired,

  /** function to set the offset and filter object */
  setFilter: PropTypes.func.isRequired,

  /** relative link to new city */
  randomCity: PropTypes.string,

  /** relative link to the closest city */
  closestCity: PropTypes.string,

  /** whether or not closest city is loading */
  isLoadingClosestCity: PropTypes.bool.isRequired,

  /** function to request user location */
  requestUserLocation: PropTypes.func.isRequired,

  /** the function to refresh the random city */
  refreshRandomCity: PropTypes.func.isRequired,

  /** whether or not the buttons should be small */
  smallButtons: PropTypes.bool,

  /** function to open the auth popup */
  openAuthPopup: PropTypes.func.isRequired,

  /** whether or not the buttons should be small */
  isHiddenGems: PropTypes.bool,

  /** whether there should be hidden gems */
  noHiddenGems: PropTypes.bool,
};

const defaultProps = {
  randomCity: undefined,
  closestCity: undefined,
  smallButtons: false,
  isHiddenGems: false,
  noHiddenGems: false,
};

/**
 * Represents the split the with new city, random city, and hidden gems options. Can't use SimpleSplitOption because
 * these are too complex.
 *
 * @param {object} props
 */
const CityGemsSplit = ({
  filter,
  setFilter,
  randomCity,
  closestCity,
  isLoadingClosestCity,
  requestUserLocation,
  refreshRandomCity,
  smallButtons,
  openAuthPopup,
  isHiddenGems,
  noHiddenGems,
}) => {
  const { currentUser } = useVbContext();

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const isClosestCity = closestCity ? linkEqualsCity(closestCity, filter.country, filter.region, filter.city) : null;

  const handleHiddenGemsClick = () => {
    if (isHiddenGems) return null;
    if (currentUser) return setFilter({ ...filter, hiddenGems: true });
    return openAuthPopup();
  };
  return (
    <SplitContainer>
      {randomCity ? (
        <VBButton
          size={smallButtons ? 'sm' : 'med'}
          type="border"
          onClick={() => {
            setFilter({ ...filter, ...getSlugsFromLocationLink(randomCity), nearMe: false, hiddenGems: false });
            // Get a new random city.
            refreshRandomCity();
            sendAnalyticsEvent(`vb_explore_splits_randomcity`);
          }}
        >
          New City
        </VBButton>
      ) : null}
      {closestCity ? (
        <VBSpinnerButton
          size={smallButtons ? 'sm' : 'med'}
          type={isClosestCity ? 'full' : 'border'}
          isSpinning={isLoadingClosestCity}
          onClick={
            isClosestCity
              ? null
              : async () => {
                  const closestCity = await requestUserLocation();

                  if (closestCity) {
                    sendAnalyticsEvent(`vb_explore_splits_closestcity`);

                    setFilter({
                      ...filter,
                      ...getSlugsFromLocationLink(closestCity.searchLink),
                      nearMe: true,
                      hiddenGems: false,
                    });
                  }
                }
          }
        >
          Near Me
        </VBSpinnerButton>
      ) : null}
      {noHiddenGems ? null : (
        <VBButton
          size={smallButtons ? 'sm' : 'med'}
          type={isHiddenGems ? 'full' : 'border'}
          onClick={() => {
            sendAnalyticsEvent(`vb_explore_splits_hiddengems`);
            handleHiddenGemsClick();
          }}
        >
          Hidden Gems
        </VBButton>
      )}
    </SplitContainer>
  );
};
export default CityGemsSplit;

CityGemsSplit.propTypes = propTypes;
CityGemsSplit.defaultProps = defaultProps;
