import {
  ITINERARY_SEASONS,
  ItinerarySeasonLabel,
} from '../../../../../tripfool/src/components/Master/PageExplore/PopupItinerary/config';
import { mergeClassNames } from '../../../util/props';

import styles from './DropdownFilterItineraries.module.scss';

export const BEST_TIME_TO_GO = 4;

const DropdownFilterItineraries = ({ selectedSeason, setSeason }) => {
  return (
    <ul className={styles.filter}>
      {ITINERARY_SEASONS.map((season) => {
        return (
          <li
            key={season}
            className={mergeClassNames(styles.option, season === selectedSeason ? styles.active : null)}
            onClick={() => setSeason(season)}
          >
            {ItinerarySeasonLabel[season]} Itinerary
          </li>
        );
      })}
      <li
        className={mergeClassNames(styles.option, selectedSeason === BEST_TIME_TO_GO ? styles.active : null)}
        onClick={() => setSeason(BEST_TIME_TO_GO)}
      >
        Best Time to Go
      </li>
    </ul>
  );
};

export default DropdownFilterItineraries;
