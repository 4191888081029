import PropTypes from 'prop-types';
import VBInputLabel from '../../VBInputLabel/VBInputLabel';
import VBRadioButton from '../../VBRadioGroup/VBRadioButton/VBRadioButton';
import VBRadioGroup from '../../VBRadioGroup/VBRadioGroup';
import styles from './DropdownFilterFestivals.module.scss';

const propTypes = {
  sort: PropTypes.oneOf(['date', 'popular']).isRequired,
  setSort: PropTypes.func.isRequired,
};

const DropdownFilterFestivals = ({ sort, setSort }) => {
  return (
    <div className={styles.dropdown}>
      <div className={styles.inputContainer}>
        <VBInputLabel content="Sort" noMargin />
        <VBRadioGroup selected={sort} onChange={setSort}>
          <VBRadioButton value="date" label="Date" />
          <VBRadioButton value="popular" label="Popular" />
        </VBRadioGroup>
      </div>
    </div>
  );
};

DropdownFilterFestivals.propTypes = propTypes;

export default DropdownFilterFestivals;
