import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-regular-svg-icons/faFlag';
import styles from './Report.module.scss';
import { useReport } from '../../hooks/report';
import { mergeClassNames } from '../../util/props';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import { usePopup } from '../../hooks/popup';
import PopupConfirm from '../PopupConfirm/PopupConfirm';

/**
 * Report button.
 * @param {string} props.contentType the type of the content to report
 * @param {number} props.contentId the ID of the content to report
 * @param {string} props.className additional class names
 * @param {(): void)} props.onReport callback for when the comment is reported
 */
const Report = ({ contentType, contentId, className, onReport }) => {
  const report = useReport();
  const { openAuthPopup, currentUser } = useContext(VBReactContext);
  const isLoggedIn = !!currentUser;

  /**
   * Called when the user closes the report post confirmation popup.
   * @param {bool} confirmed whether or not the user wants to report the post
   */
  const handleReportConfirm = (confirmed) => {
    // If the user did not confirm, don't handle reporting
    if (!confirmed) return;
    // If there is no user logged in, open the auth popup.
    if (!isLoggedIn) {
      openAuthPopup();
      return;
    }
    if (onReport) onReport();
    report(contentType, contentId);
  };

  // Nicely formatted content type name used in report confirmation popup message
  let niceContentType;
  switch (contentType) {
    case 'comment':
      niceContentType = 'comment';
      break;
    case 'commentReply':
    case 'reviewReply':
      niceContentType = 'reply';
      break;
    case 'snippet':
      niceContentType = 'snippet';
      break;
    case 'review':
      niceContentType = 'review';
      break;
    default:
      niceContentType = '';
  }

  // Popup to confirm that the user wants to report a post
  const { modal: reportContentModal, open: reportContentOpen } = usePopup({
    child: (
      <PopupConfirm
        onResolve={handleReportConfirm}
        prompt={
          niceContentType
            ? `Are you sure you want to report this ${niceContentType}?`
            : 'Are you sure you want to report this?'
        }
      />
    ),
  });

  return (
    <>
      <div>
        <FontAwesomeIcon
          icon={faFlag}
          onClick={reportContentOpen}
          className={mergeClassNames(styles.container, className)}
        />
      </div>
      {reportContentModal}
    </>
  );
};

Report.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.number.isRequired,
  className: PropTypes.string,
  onReport: PropTypes.func,
};

Report.defaultProps = {
  className: undefined,
  onReport: undefined,
};

export default Report;
