import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import { formatEventDate } from '../../../util/events';
import { usePopup } from '../../../../../shared-react/src/hooks/popup';
import PopupDeleteConfirm from '../../../../../shared-react/src/components/PopupDeleteConfirm/PopupDeleteConfirm';
import APIConfig from '../../../../../shared-react/src/config/api';
import { removeItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import ExploreCard from '../../../../../shared-react/src/components/ExploreCard/ExploreCard';

const propTypes = {
  /** the event object to render. This should be an object that has isThirdParty = true */
  event: PropTypes.object,

  /** distance in mi from the event */
  distance: PropTypes.number,

  hideImage: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  hideImage: false,
  isLoading: false,
};

/**
 * A 3rd party event. Used on the explore page.
 */
const EventExternal = ({ event, distance, hideImage, isLoading }) => {
  const { vbRequest, raiseToast } = useVbContext();
  const dispatch = useDispatch();

  const handlePopup = (confirmed) => {
    if (confirmed) {
      dispatch(removeItem(ContentTypes.event.type, event.id));
      vbRequest(`${APIConfig.NAMESPACE.EVENTS}/admin/events/${event.id}/delete`, { method: 'POST' })
        .then((response) => {
          if (response.success) {
            raiseToast('Successfully deleted event.', 'Deleted', 'info');
          } else {
            raiseToast('There was an error deleting this event.', 'Error', 'warning');
            console.error(response?.success);
          }
        })
        .catch((err) => {
          raiseToast('There was an error deleting this event.', 'Error', 'warning');
          console.error(err?.message);
        });
    }
  };

  const { open, modal } = usePopup({
    child: <PopupDeleteConfirm contentName="this event" onResolve={handlePopup} />,
  });

  return (
    <>
      <ExploreCard
        name={event.name}
        description={event.description}
        date={formatEventDate(event.startTime, event.endTime)}
        distance={distance}
        destination={{ latitude: event.latitude, longitude: event.longitude }}
        outlink={event.outlink}
        externalImageUrl={event.externalImageUrl}
        hideImage={hideImage}
        isLoading={isLoading}
        onDelete={open}
      />
      {modal}
    </>
  );
};

EventExternal.propTypes = propTypes;
EventExternal.defaultProps = defaultProps;

export default EventExternal;
