import { useCallback, useMemo, useState } from 'react';
import TriviaSet from './TriviaSet/TriviaSet';
import { getInitialFinishedSets, saveMasteredTriviaSet } from './utils';
import useRandomCities from '../../../../../../../shared-react/src/hooks/random-cities';

const RenderedTrivia = ({ item: triviaSets, locationDetails, gotoCity }) => {
  const [finishedSets, setFinishedSets] = useState(getInitialFinishedSets(triviaSets));

  const randomCities = useRandomCities({
    exclude: locationDetails ? [locationDetails.id] : [],
    size: 5,
    hasTrivia: true,
  });

  const availableSets = useMemo(
    () => triviaSets.filter((set) => !finishedSets.includes(set.id)),
    [finishedSets, triviaSets]
  );

  const [currentSet] = availableSets;

  const handleNextSet = useCallback(
    (id) =>
      setFinishedSets((finishedSets) =>
        availableSets.length === 1 ? getInitialFinishedSets(triviaSets) : [...finishedSets, id]
      ),
    [availableSets, triviaSets]
  );

  const handleSetMastered = useCallback((id) => {
    saveMasteredTriviaSet(id);
  }, []);

  return (
    <TriviaSet
      setId={currentSet.id}
      questions={currentSet.questions}
      currentLocation={locationDetails}
      randomCities={randomCities}
      onSelectRandomCity={gotoCity}
      onNextTriviaSet={handleNextSet}
      onSetMastered={handleSetMastered}
    />
  );
};

export default RenderedTrivia;
