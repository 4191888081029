import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ExploreCard, {
  AuthorPosition,
  ExploreCardSkeletonPart,
} from '../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import PopupDeleteConfirm from '../../../../../shared-react/src/components/PopupDeleteConfirm/PopupDeleteConfirm';
import { removeItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../shared-react/src/config/api';
import usePopup from '../../../hooks/open-auth-popup';
import SocialActions from '../SocialActions/SocialActions';
import PopupUpsertFAQ from '../PopupUpsertFAQ/PopupUpsertFAQ';

import styles from './FAQ.module.scss';

const propTypes = {
  faq: PropTypes.object.isRequired,
  locationDetails: PropTypes.object,
  noPadding: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const FAQ = ({ faq, locationDetails, noPadding, isLoading }) => {
  const dispatch = useDispatch();
  const { vbRequest, raiseToast, errorToast } = useVbContext();

  const handleDeleteFaq = useCallback(
    (confirmed) => {
      if (!confirmed) {
        return;
      }

      dispatch(removeItem(ContentTypes.faq.type, faq.id));

      vbRequest(`${APIConfig.NAMESPACE.FAQ}/${faq.id}`, {
        method: 'DELETE',
      })
        .then(() => raiseToast('Successfully deleted!', 'Success'))
        .catch((error) => errorToast(error.message, 'Error'));
    },
    [dispatch, errorToast, faq, raiseToast, vbRequest]
  );

  const { modal: editFaqModal, open: openEditFaqModal } = usePopup({
    child: <PopupUpsertFAQ faq={faq} locationDetails={locationDetails} />,
    size: 'lg',
  });

  const { modal: deleteConfirmModal, open: openDeleteConfirmModal } = usePopup({
    child: <PopupDeleteConfirm contentName="this FAQ" onResolve={handleDeleteFaq} />,
  });

  return (
    <div className={styles.faq}>
      <ExploreCard
        name={faq.question}
        description={faq.answer}
        toggleContent={faq.longAnswer}
        author={faq.askedBy?.username}
        authorPosition={AuthorPosition.DESCRIPTION}
        numberOfLines={0}
        isLoading={isLoading}
        noPadding={noPadding}
        skeletonParts={[
          ExploreCardSkeletonPart.TITLE,
          ExploreCardSkeletonPart.DESCRIPTION,
          ExploreCardSkeletonPart.AUTHOR,
        ]}
      />
      {!isLoading && (
        <SocialActions
          entityId={faq.id}
          entityType="FAQ"
          liked={faq.userLiked}
          commentsTotal={faq.comments}
          shareType="FAQ"
          username={faq.askedBy?.username}
          userLink={faq.askedBy?.permalink}
          className={noPadding ? null : styles.extraPadding}
          onEdit={openEditFaqModal}
          onDelete={openDeleteConfirmModal}
        />
      )}
      {editFaqModal}
      {deleteConfirmModal}
    </div>
  );
};

FAQ.propTypes = propTypes;

export default FAQ;
