import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIConfig from '../config/api';
import { hasUnreadNotification, readNotifications } from '../store/actions/notificationActions';
import useVbContext from './vb-context';

export const useHasUnreadActivity = () => {
  const hasUnreadActivity = useSelector(({ notifications }) => notifications.hasUnreadActivity);
  return hasUnreadActivity;
};

/**
 * Globally sets the unreadActivity state to false and sends POST to server to mark all notifications as read.
 *
 * @returns {Function} Call to read all notifications
 */
export const useReadActivityIfLoggedIn = () => {
  const { vbRequest, currentUser } = useVbContext();
  const dispatch = useDispatch();
  const readActivityFunc = useCallback(() => {
    dispatch(readNotifications());
    vbRequest(`${APIConfig.NAMESPACE.NOTIFICATIONS}/read`, { method: 'POST' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vbRequest, currentUser]);
  if (!currentUser) {
    return () => {};
  }
  return readActivityFunc;
};

export const useMarkActivityAsReadIfLoggedIn = () => {
  const markActivityAsRead = useReadActivityIfLoggedIn();
  useEffect(() => {
    markActivityAsRead();
  }, [markActivityAsRead]);
};

/**
 * Monitors the global unread activity state. Makes routine API calls to check for
 * new unread activity.
 */
export const useUnreadActivityMonitor = () => {
  const dispatch = useDispatch();
  const { currentUser, vbRequest } = useVbContext();

  // Check for any unreadActivity at an interval.
  useEffect(() => {
    const updateActivityUnread = () => {
      if (!currentUser) return;
      vbRequest(`${APIConfig.NAMESPACE.NOTIFICATIONS}/unread`).then((data) => {
        dispatch(hasUnreadNotification(data.unread));
      });
    };
    updateActivityUnread();
    const interval = setInterval(updateActivityUnread, 60000);
    return () => clearInterval(interval);
  }, [currentUser, dispatch, vbRequest]);
};
