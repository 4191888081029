import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styles from './MaxWidthContainer.module.scss';
import { boolProp, generateTestAttributes, mergeClassNames } from '../../util/props';
import { PropTypesVBBool, PropTypesRefProp } from '../../util/types';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { isUndefined } from '../../util/objects';
import useTestData from '../../hooks/test-data';

const propTypes = {
  /** className additional classes for the fluid container. */
  className: PropTypes.string,
  children: PropTypes.node,
  /** padded whether or not to pad the sides at all resolutions */
  padded: PropTypesVBBool,
  maxWidth: PropTypes.string,
  style: PropTypes.object,
  /** VIEWBUFF ONLY: there is a page container automatically set on each page.
   * It will put padding all around the page on larger resolutions. If you don't
   * want this behavior, set this to true.
   */
  removeViewBuffPagePadding: PropTypesVBBool,
  containerRef: PropTypesRefProp,
  testId: PropTypes.string,
  /** test data to add to the container */
  testData: PropTypes.object,
};

const defaultProps = {
  padded: false,
  className: '',
  style: {},
  maxWidth: undefined,
  removeViewBuffPagePadding: false,
  containerRef: undefined,
  children: null,
};

/**
 * Bootstrap fluid container with a max width of 1300px.
 * @param {string} maxWidth A custom maxwidth from the default of 1300px.
 */
const MaxWidthContainer = ({
  removeViewBuffPagePadding,
  className,
  children,
  padded,
  maxWidth,
  style,
  containerRef,
  testData,
  ...other
}) => {
  const { ref } = useTestData(testData, containerRef);
  const { lteSm } = useVBBreakpoint();

  // Remove ViewBuff page padding if requested.
  useEffect(() => {
    // Set the element with id "page-container" to have no padding.
    const pageContainer = document.getElementById('page-container');
    if (removeViewBuffPagePadding) {
      if (pageContainer) {
        pageContainer.style.padding = '0';
      }
    }
    return () => {
      if (pageContainer) {
        pageContainer.style.padding = '';
      }
    };
  }, [removeViewBuffPagePadding]);

  return (
    <Container
      className={mergeClassNames(
        styles.container,
        boolProp(padded) ? styles.padded : styles.notPadded,
        lteSm ? styles.sm : null,
        className
      )}
      style={{ ...style, maxWidth }}
      fluid
      ref={ref}
      {...other}
    >
      {children}
    </Container>
  );
};

MaxWidthContainer.propTypes = propTypes;
MaxWidthContainer.defaultProps = defaultProps;

export default MaxWidthContainer;
