import { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import VBSpinner from '../../../../../../shared-react/src/components/VBSpinner/VBSpinner';

const EditLocationForm = lazy(() => import('./EditLocationForm/EditLocationForm'));

const propTypes = {
  locationDetails: PropTypes.object,
};

const PopupEditLocation = ({ locationDetails }) => {
  return <Suspense fallback={<VBSpinner center />}>
    <EditLocationForm locationDetails={locationDetails} />
  </Suspense>;
};

PopupEditLocation.propTypes = propTypes;

export default PopupEditLocation;
