import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { VBRadioGroupProvider } from '../VBRadioGroup';
import styles from './VBRadioButton.module.scss';
import { mergeClassNames } from '../../../util/props';

const propTypes = {
  /** value for the radio button. This will be the value passed into the parent VBRadioGroup's onChange, or specified in
   *  the VBRadioGroup's selected prop
   */
  value: PropTypes.any.isRequired,

  /** the label on the radio button. This will also be clickable */
  label: PropTypes.node,

  /** ID of the input */
  id: PropTypes.string,

  /** additional class names */
  className: PropTypes.string,

  /** whether radio button is disabled or not */
  disabled: PropTypes.bool,
};

const defaultProps = {
  label: undefined,
  id: undefined,
  className: undefined,
  disabled: false,
};

/**
 * Stylized radio button. Must be used in VBRadioGroup, where state is managed.
 *
 * @param {object} props
 */
const VBRadioButton = ({ value, label, id, className, disabled, ...other }) => {
  const ctx = useContext(VBRadioGroupProvider);
  if (typeof ctx === 'undefined') {
    throw new Error('VBRadioButton must be rendered within a VBRadioGroup');
  }

  const { name, selected, onChange } = ctx;

  /**
   * Handle change in the checked status.
   *
   * @param {React.SyntheticEvent} e
   */
  const handleChange = (e) => {
    if (disabled) {
      e.preventDefault();
    }

    if (!disabled && e.target.checked) {
      onChange(value);
    }
  };

  // Use either the ID prop or a randomly generated ID.
  const [actualId, setActualId] = useState(id);
  useEffect(() => {
    if (typeof id === 'undefined') {
      setActualId(uuidv4());
    } else {
      setActualId(id);
    }
  }, [id]);

  return (
    <Form.Check
      id={actualId}
      type="radio"
      name={name}
      value={value}
      checked={selected === value}
      onChange={handleChange}
      label={label}
      className={mergeClassNames(className, styles.check, selected === value ? styles.active : null)}
      {...other}
    ></Form.Check>
  );
};

export default VBRadioButton;
VBRadioButton.propTypes = propTypes;
VBRadioButton.defaultProps = defaultProps;
