import React from 'react';
import PropTypes from 'prop-types';
import ColoredIcon from '../../../ColoredIcon/ColoredIcon';
import styles from './IconHamburger.module.scss';
import ImageHamburger from '../../../../../assets/images/icons/buttons/hamburger.svg';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};
const defaultProps = {};

function IconHamburger({ onClick, color }) {
  return (
    <div role="button" aria-label="hamburger" className={styles.hamburgerButton} onClick={onClick}>
      <ColoredIcon alt="hamburger" src={ImageHamburger} color={color} height={30} width={30} />
    </div>
  );
}

IconHamburger.propTypes = propTypes;
IconHamburger.defaultProps = defaultProps;

export default IconHamburger;
