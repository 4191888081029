import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer/polyfilled';
import styles from './StickyColumn.module.scss';
import { useHeaderHeight } from '../../util/size';
import { generateTestAttributes, mergeClassNames } from '../../util/props';
import { useCurrentScrollDirection } from '../../hooks/scroll-direction';
import useWindowResize from '../../hooks/window-resize';

const propTypes = {
  /** column content */
  children: PropTypes.node,

  /** width of the column as a CSS string. */
  width: PropTypes.string.isRequired,

  /** key-value test data */
  testData: PropTypes.object,

  /** whether the column takes up the full viewport height */
  isFullHeight: PropTypes.bool,

  /** addition offset to start sticky, used for tabs on Explore Summary page */
  offset: PropTypes.number,

  /** whether hide shadow or no */
  noShadow: PropTypes.bool,

  /**  whether hide border radius or no */
  noBorderRadius: PropTypes.bool,

  /** whether container is scrollable or no */
  isScrollable: PropTypes.bool,
};

const defaultProps = {
  children: undefined,
  offset: 0,
  noShadow: false,
  noBorderRadius: false,
  isScrollable: false,
};

export const STICKY_COLUMN_PADDING = 10;

/**
 * A div with the necessary style for a sticky column on the explore page.
 */
const StickyColumn = ({ width, children, testData, isFullHeight, offset, isScrollable }) => {
  const headerHeight = useHeaderHeight();
  const containerRef = useRef();
  const contentRef = useRef();

  const { height: contentHeight = 0 } = useResizeObserver({ ref: contentRef });

  const pageOffsetToStartSticky = headerHeight + offset + STICKY_COLUMN_PADDING;

  // Set full height based on the screen height.
  const [fullHeight, setFullHeight] = useState(0);
  useWindowResize(
    () => {
      setFullHeight(window.innerHeight - pageOffsetToStartSticky - STICKY_COLUMN_PADDING);
    },
    [pageOffsetToStartSticky],
    true
  );

  const curScrollDirection = useCurrentScrollDirection();

  const [topMargin, setTopMargin] = useState(1);
  const [contentStyle, setContentStyle] = useState(computeContentStyle('down', 0));

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const containerTop = containerRef.current.offsetTop;
    const contentTop = contentRef.current.offsetTop + containerTop;

    const contentFullyInViewport = scrollY > contentTop && scrollY + viewportHeight < contentTop + contentHeight;
    const contentSmallerThanViewport = contentHeight + pageOffsetToStartSticky < viewportHeight;

    if (contentSmallerThanViewport) {
      setTopMargin(0);
      setContentStyle({ top: pageOffsetToStartSticky });
    } else {
      if (!contentFullyInViewport) {
        let newTopMargin;
        if (curScrollDirection === 'down') {
          newTopMargin = scrollY - containerTop + pageOffsetToStartSticky;
        } else if (curScrollDirection === 'up') {
          newTopMargin = scrollY - containerTop + viewportHeight - contentHeight - STICKY_COLUMN_PADDING;
        }
        setTopMargin(Math.max(0, newTopMargin));
      }

      const top = -(contentHeight - viewportHeight) - STICKY_COLUMN_PADDING;
      const bottom = -(contentHeight - viewportHeight) - pageOffsetToStartSticky;
      const bottomOrTop = curScrollDirection === 'up' ? bottom : top;
      // Set top/bottom immediately after the margin updates.
      setTimeout(() => setContentStyle(computeContentStyle(curScrollDirection, bottomOrTop)), 0);
    }
  }, [contentHeight, curScrollDirection, pageOffsetToStartSticky]);

  const heightStyle = Object.assign({}, isFullHeight ? { minHeight: fullHeight + 'px' } : {});

  return (
    <div
      className={styles.container}
      ref={containerRef}
      style={{ width, minWidth: width, ...heightStyle }}
      {...generateTestAttributes(testData)}
    >
      <div className={styles.topMargin} style={{ marginTop: `${topMargin}px` }}></div>
      <div
        className={mergeClassNames(styles.content, styles.scrollable)}
        style={{ ...contentStyle, ...heightStyle, maxHeight: isScrollable ? fullHeight + 'px' : 'unset' }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default StickyColumn;

StickyColumn.propTypes = propTypes;
StickyColumn.defaultProps = defaultProps;

function computeContentStyle(curScrollDirection, contentTopOrBottom) {
  const contentStyle = {};
  if (curScrollDirection === 'up') {
    contentStyle.bottom = contentTopOrBottom;
  } else if (curScrollDirection === 'down') {
    contentStyle.top = contentTopOrBottom;
  } else {
    throw new Error('Scroll direction is not set');
  }
  return contentStyle;
}
