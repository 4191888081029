/**
 * Util functions dealing with the environment.
 */

import isBot from 'isbot';

// eslint-disable-next-line no-undef
export const vbEnv = VB_ENV;

export const ENV_DEV = 'development';
export const ENV_STAGING = 'staging';
export const ENV_LIVE = 'live';
export const ENV_TESTING = 'testing';

/**
 * Gets if this is a dev build. Checks VB_ENV.
 */
export const isDevelopment = () => vbEnv === ENV_DEV;

/**
 * Gets if this is a testing build. Checks VB_ENV.
 */
export const isTesting = () => vbEnv === ENV_TESTING;

/**
 * Gets if this is a live build. Checks VB_ENV.
 */
export const isLive = () => vbEnv === ENV_LIVE;

/**
 * Gets if this is a staging build. Checks VB_ENV.
 */
export const isStaging = () => vbEnv === ENV_STAGING;

/**
 * Is the user a web crawler.
 */
export const isCrawler = () => isBot(navigator.userAgent);
