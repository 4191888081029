import { useEffect, useState } from 'react';
import IconShare from '../../../../../../shared-react/src/components/IconShare/IconShare';
import IconMute from '../../../../../../shared-react/assets/images/icons/videos/mute.webp';
import ProgressiveImage from '../../../../../../shared-react/src/components/ProgressiveImage/ProgressiveImage';
import VBLink from '../../../../../../shared-react/src/components/VBLink/VBLink';
import { VENUE_BUTTONS_SHADOW_VALUE } from '../../../../../../shared-react/src/config/venue-buttons';
import useVBBreakpoint from '../../../../../../shared-react/src/hooks/vb-breakpoint';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import { mergeClassNames } from '../../../../../../shared-react/src/util/props';
import IconRepeat from '../../../../assets/images/icons/itineraries/repeat.svg';
import IconRepeatSolid from '../../../../assets/images/icons/itineraries/repeat-solid.svg';
import IconButton from '../../../../../../shared-react/src/components/IconButton/IconButton';
import ColoredIcon from '../../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { buildDistanceString } from '../../../../../../shared-react/src/util/distance';
import { ZuzuGoQueryParam } from '../PageZuzuGo';

import styles from './CarouselCard.module.scss';

const CarouselCard = ({
  venue,
  distance,
  showPopularity,
  positionLabel,
  dayLabel,
  isMuted,
  isItinerarySelected,
  isDoNextSelected,
  onlyIllustrationAndLink,
  showDifferentFacts,
  onClickVenueDetails,
  onClickItinerary,
  onClickDoNext,
  onUnmute,
}) => {
  const {
    components: { IconButtonSaveVenue, IconButtonBeenThere },
    vbBlack,
  } = useVbContext();

  const [interestingFact, setInterestingFact] = useState(venue.facts?.length ? venue.facts[0] : null);

  const { lteXs: mobileStyle } = useVBBreakpoint();

  const locationName = `${venue.city.city}, ${venue.city.regionShort}`;

  const handleClickVenueDetails = () => {
    onClickVenueDetails(venue);
  };

  const interceptPageNavigation = () => {
    window.history.replaceState(null, '', `/zuzu-go?${ZuzuGoQueryParam.VENUE_ID}=${venue.id}`);
  };

  useEffect(() => {
    if (showDifferentFacts && venue.facts?.length) {
      const interval = setInterval(() => {
        setInterestingFact((fact) => {
          const currentFactIdx = venue.facts.indexOf(fact);

          return venue.facts[currentFactIdx + 1] ?? venue.facts[0];
        });
      }, 8 * 1000);

      return () => clearInterval(interval);
    }
  }, [showDifferentFacts, venue]);

  const iconColor = mobileStyle ? 'white' : undefined;

  return (
    <div className={mergeClassNames(styles.container, mobileStyle ? styles.mobile : null)}>
      <div className={styles.carouselCard}>
        <ProgressiveImage
          containerClassName={styles.image}
          fullSrc={venue.featuredImage}
          smallSrc={venue.featuredImageSmall}
          alt={venue.imageAlt}
        />
        {onlyIllustrationAndLink ? (
          <VBLink to={venue.city.searchLink} className={styles.zuzuLink} noStyle>
            Go to Zuzu
          </VBLink>
        ) : null}
        {!onlyIllustrationAndLink ? (
          <div className={styles.venueDetails} style={{ textShadow: VENUE_BUTTONS_SHADOW_VALUE }}>
            <span onClick={handleClickVenueDetails}>{locationName}</span>
            {dayLabel ? <span>{dayLabel}</span> : null}
          </div>
        ) : null}
        {!onlyIllustrationAndLink && isMuted ? (
          <img className={styles.audioControl} src={IconMute} width="20" height="20" alt="Mute" onClick={onUnmute} />
        ) : null}
        {!onlyIllustrationAndLink ? (
          <div className={styles.actions}>
            <IconButton
              text="Itinerary"
              fontSize="1rem"
              ariaLabel="Itinerary"
              textShadow={mobileStyle ? VENUE_BUTTONS_SHADOW_VALUE : null}
              subtitleColor={iconColor}
              subtitleWeight={isItinerarySelected ? '600' : '500'}
              onClick={onClickItinerary}
            >
              <ColoredIcon
                src={isItinerarySelected ? IconRepeatSolid : IconRepeat}
                color={mobileStyle ? 'white' : vbBlack}
                additionalFilters={mobileStyle ? `drop-shadow(${VENUE_BUTTONS_SHADOW_VALUE})` : null}
                height="25"
                width="25"
                alt=""
              />
            </IconButton>
            <IconButton
              text="Do Next"
              fontSize="1rem"
              ariaLabel="Do Next"
              textShadow={mobileStyle ? VENUE_BUTTONS_SHADOW_VALUE : null}
              subtitleColor={iconColor}
              subtitleWeight={isDoNextSelected ? '600' : '500'}
              onClick={onClickDoNext}
              noBreakWords
            >
              <ColoredIcon
                src={isDoNextSelected ? IconRepeatSolid : IconRepeat}
                color={mobileStyle ? 'white' : vbBlack}
                additionalFilters={mobileStyle ? `drop-shadow(${VENUE_BUTTONS_SHADOW_VALUE})` : null}
                height="25"
                width="25"
                alt=""
              />
            </IconButton>
            <IconShare
              type="View"
              color={iconColor}
              permalink={window.location.host + venue.permalink}
              name={venue.name}
              venueId={venue.id}
              shadow={mobileStyle}
              bold
            />
            <IconButtonBeenThere color={iconColor} venue={venue} shadow={mobileStyle} bold />
            <IconButtonSaveVenue color={iconColor} venueId={venue.id} saved={venue.saved} shadow={mobileStyle} bold />
          </div>
        ) : null}
        {!onlyIllustrationAndLink ? (
          <div className={styles.meta}>
            <div className={styles.bubbles}>
              {positionLabel ? <div className={styles.bubble}>{positionLabel}</div> : null}
              <div className={styles.row}>
                {distance ? <div className={styles.bubble}>Distance: {buildDistanceString(distance)}</div> : null}
                {showPopularity ? (
                  <div className={styles.bubble}>Popularity: {Math.round(Math.sqrt(venue.kidsOverallRating))}</div>
                ) : null}
              </div>
            </div>
            <VBLink className={styles.name} to={venue.permalink} onClick={interceptPageNavigation} noStyle>
              {venue.name}
            </VBLink>
            <VBLink
              className={styles.locationName}
              to={venue.city.searchLink}
              onClick={interceptPageNavigation}
              noStyle
            >
              {locationName}
            </VBLink>
            {interestingFact ? <span className={styles.fact}>{interestingFact.fact}</span> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CarouselCard;
