import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';
import APIConfig from '../config/api';
import { ContentTypes } from '../config/content-types';
import { addItem, removeItem, updateItem } from '../store/actions/apiActions';

const useDeleteVideo = () => {
  const dispatch = useDispatch();
  const { vbRequest, raiseToast, errorToast } = useContext(VBReactContext);

  return async (videoId, venueId = null) => {
    try {
      const data = await vbRequest(`${APIConfig.NAMESPACE.VIDEO}/remove`, {
        params: {
          venueId: venueId,
          videoId: videoId,
        },
      });
      if (venueId) {
        if (data.venueVideoId) {
          dispatch(
            addItem(ContentTypes.venueVideo.type, {
              id: data.venueVideoId,
            })
          );
          dispatch(updateItem(venueId, ContentTypes.venue.type, { featuredVideo: data.venueVideoId }));
        } else {
          dispatch(updateItem(venueId, ContentTypes.venue.type, { featuredVideo: null }));
        }
      }
      dispatch(removeItem(ContentTypes.venueVideo.type, videoId));

      raiseToast('Successfully deleted video.', 'Deleted', 'info');
    } catch (err) {
      errorToast('There was an error deleting this video.', 'Error');
    }
  };
};

export default useDeleteVideo;
