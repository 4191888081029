/**
 * Util functions dealing with venues.
 */

import React from 'react';
import { badgeCompare, getBadge } from '../config/badges';
import { SITE_INFO } from '../config/sites';
import { getSiteName, getSitesArray } from '../util/sites';
import SITES from '../../config-json/sites.json';
import { buildAgesArray } from './kids';
import { AGE_GROUPS } from '../config/explore';
import VBLink from '../components/VBLink/VBLink';
import VBButton from '../components/VBButton/VBButton';

/**
 * Get the category to display for a venue. This will be the category, unless the category is activity in which case the
 * subcategory will be returned.
 * @param {object} venue the venue
 * @returns {string} the category string
 */
export const getDisplayedCategory = (venue) => {
  if (venue.category !== 'Activity' || !venue.subcategory) return venue.category;
  if (venue.subcategory === 'OSM Playground') return 'Playground';
  return venue.subcategory;
};

/**
 * Returns a list of all sites that this venue has superlatives ordered by highest superlative to lowest.
 * @returns {string[]} the list of sites in order
 */
export const getSitesWithSuperlatives = (venue) => {
  const sites = getSitesArray();
  const sitesWBadge = sites.map((site) => {
    const ratingField = SITE_INFO[site].RATING_FIELD;
    return [site, getBadge(venue[ratingField], site)];
  });
  const sortedSites = sitesWBadge.sort((a, b) => badgeCompare(a[1], b[1]));
  return sortedSites.filter((site) => site[1] !== null).map((site) => site[0]);
};

/**
 * If the venue has a superlative on another site, this will return the site with the highest superlative.
 * It will ignore the current site and will only test for superlative on the other sites.
 * @return {string} the cross site with the highest superlative
 */
export const getCrossLinkVenue = (venue) => {
  const curSite = getSiteName();
  const { kidsAgeGroups } = venue;
  const satisfiesKidsAgeGroups = buildAgesArray(kidsAgeGroups).some(
    (group) => group === AGE_GROUPS[0] || group === AGE_GROUPS[1]
  );
  const superlativeSites = getSitesWithSuperlatives(venue);
  // Remove current site (shouldn't cross link to it's own site).
  return (
    superlativeSites
      .filter((site) => site !== curSite)
      // Remove the kids site if venue age group doesn't have both 1-8 and 8-12.
      .filter((site) => (site === SITES[SITES.KIDS] ? satisfiesKidsAgeGroups : true))
  );
};

/**
 * Is a venue a hike. Requires that the property hikeDifficulty have been included in the server context.
 *
 * @returns {bool}
 */
export const isHike = (venue) => !!venue.hikeDifficulty;

/**
 * 1 -> easy
 * 2 -> intermediate
 * 3 -> advanced
 * @param {number} difficultyInt
 * @returns {string}
 */
export const getHikeDifficultyString = (difficultyInt) =>
  [undefined, 'easy', 'intermediate', 'advanced'][difficultyInt];

/**
 * Checks if venue image generated
 * 
 * @param {string} imageAlt 
 * @returns {bool}
 */

export const isImageGenerated = (imageAlt) => {
  return imageAlt?.startsWith('Colorful illustration of fun things to do');
}