import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { mergeClassNames } from '../../../util/props';
import NoContent from '../../NoContent/NoContent';
import MarkdownText from '../../MarkdownText/MarkdownText';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './styles.module.scss';

const propTypes = {
  description: PropTypes.string,
  descriptionLink: PropTypes.string,
  author: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.object),
  markdown: PropTypes.bool,
  hover: PropTypes.bool,
  numberOfLines: PropTypes.number,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

const CardDescription = ({
  description,
  descriptionLink,
  author,
  matches,
  markdown,
  hover,
  numberOfLines,
  isLoading,
  onClick,
}) => {
  const history = useHistory();
  const { lteXs: mobileStyle } = useVBBreakpoint();

  if (isLoading) {
    return <Skeleton count={numberOfLines || 2} />;
  }

  if (!description) {
    return <NoContent typePlural="description" noCenter />;
  }

  const clickHandler = descriptionLink
    ? () => {
        history.push(descriptionLink);
      }
    : onClick;

  return (
    <div
      className={mergeClassNames(
        styles.description,
        mobileStyle && styles.mobile,
        numberOfLines && styles.truncated,
        hover && styles.hover,
        clickHandler ? styles.pointer : null
      )}
      role={descriptionLink ? 'link' : undefined}
      tabIndex={descriptionLink ? 0 : undefined}
      style={{ '--numberOfLines': numberOfLines }}
      onClick={clickHandler}
    >
      {matches?.length || markdown ? (
        <MarkdownText text={description} matches={matches} />
      ) : (
        <span className={mergeClassNames(author ? styles.margin : null)}>{description}</span>
      )}
      {author ? <span className={styles.author}>{author}</span> : null}
    </div>
  );
};

CardDescription.propTypes = propTypes;

export default CardDescription;
