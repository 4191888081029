import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styles from './VBSpinner.module.scss';
import { mergeClassNames } from '../../util/props';

/**
 * A spinner.
 *
 * @param {boolean} show whether or not to show the spinner, defaults to true
 * @param {string} size size of the spinner as a CSS string
 * @param {boolean} center Will center the spinner for you.
 * @param {boolean} page Whether spinner should take full page of not
 */
const VBSpinner = (props) => {
  const { show, size, className, center, page, color, style, ...other } = props;

  const spinner = (
    <Spinner
      animation="border"
      role="status"
      className={mergeClassNames(styles.spinner, className)}
      style={{
        visibility: show ? 'visible' : 'hidden',
        width: size,
        height: size,
        color,
        ...style,
      }}
      {...other}
    />
  );

  if (center || page) {
    return <div className={mergeClassNames(styles.center, page ? styles.page : null)}>{spinner}</div>;
  }

  return spinner;
};

VBSpinner.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  center: PropTypes.bool,
};

VBSpinner.defaultProps = {
  show: true,
  className: '',
  size: '2rem',
  center: false,
};

export default VBSpinner;
