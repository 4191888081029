import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import VBLink from '../../../VBLink/VBLink';

import styles from './Header.module.scss';

/**
 * The header used on the Share popup.
 * @param {string} props.type The type of content being shared. Values: 'View', 'Post', 'Collection', 'Page', or 'FAQ'.
 * @param {string} props.name The name of the venue, collection, or page being shared if applicable.
 * @param {string} props.username The username of the relevant user if applicable.
 * @param {string} props.userLink The permalink to the user's profile if applicable.
 */

const Header = ({ type, name, username, userLink }) => {
  const nameByType = useMemo(() => {
    switch (type) {
      case 'View':
      case 'Collection':
      case 'Page':
      case 'Itinerary':
      case 'Infographic': {
        return name;
      }
      case 'Post':
      case 'FAQ':
      case 'Article': {
        return (
          <>
            <VBLink to={userLink} className={styles.link}>
              {username}
            </VBLink>{' '}
            of {name}
          </>
        );
      }
      default: {
        throw new Error('unhandled share type');
      }
    }
  }, [name, type, userLink, username]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>Share {type}</div>
        <div className={styles.name}>{nameByType}</div>
      </div>
    </>
  );
};

Header.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  username: PropTypes.string,
  userLink: PropTypes.string,
};

Header.defaultProps = {
  name: undefined,
  username: undefined,
  userLink: undefined,
};

export default Header;
