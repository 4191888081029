import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONTENT_TYPE } from '../config/content-factory';
import { requestMultiple, reloadContext, refreshContent } from '../store/actions/apiActions';
import { populateReferences } from '../util/content';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';

/**
 * @typedef {object} ContentFactoryReturn
 *
 * @property {any[]} content the array of items to be displayed
 * @property {() => Promise<void>} loadMore load more items for the current filter
 * @property {(delta: number) => Promise<void>} reload reload items for the current filter, and request items until
 *                                                        the number of items has reached previousItems + delta or
 *                                                        there are no more items. This will also clear the contents
 *                                                        of other filters
 * @property {boolean} loading whether or not the current filter is loading content (includes if it is being refreshed)
 * @property {boolean} refreshing whether or not the current filter is being refreshed
 * @property {boolean} noMore whether or not there are more items to load for the current filter
 * @property {number} lastSwitchIndex index of the last switch in content array
 * @property {(itemKey: any, newContextual): void} updateContextual update contextual data for an item
 * @property {string} context
 * @property {string} filter
 */

/**
 * Builds the args array for api actions from the args array passed into the content factory.
 *
 * @property {RequestItemsArgs} args the input args
 *
 * @returns {object}
 */
const buildRequestArgs = (args) => {
  const { method, headers, getQueryAddress, filterResponse, nextOffset, getItems } = args;
  return { method, headers, getQueryAddress, filterResponse, nextOffset, getItems };
};

/**
 * A hook that creates a list of content from an HTTP request. See the notifications page and the post comments
 * popup for examples of usage.
 *
 * @param {string} args.method the HTTP method to use for the request. Defaults to GET
 * @param {object} args.headers additional HTTP headers to use for the request. Defaults to empty object
 * @param {(offset: number, filter: any) => string} args.getQueryAddress the only mandatory property. Gets the address
 *                                                                        to query from the current filter and an
 *                                                                        offset value
 * @param {(oldOffset: number, items: any[], data: object)} args.nextOffset function to get the next offset
 * @param {(data: object) => any[]} args.getItems gets an array of items from the parsed body of the HTTP response
 * @param {(data: object) => object} args.filterResponse filter the parsed HTTP response body. To get the items from
 *                                                        this object, use getItems instead
 * @param {(index: number, currentFilter: object, currentOffset: number, setFilterAfterSplit: ({filter: object, offset: number}): void, total: number): Node | false} args.splits
 * This is the argument you can use to split the page up. See Activities for an example.
 * @param {any} args.filter the default filter
 * @param {(a, b) => number} args.sort a compareFunction used to sort content items
 * @param {bool} args.disabled whether or not the content factory should be disabled
 *
 * @returns {ContentFactoryReturn}
 *
 */
export const useContentFactory = (args) => {
  const { context, type, splits, sort, disabled } = args;
  const { vbRequest } = useContext(VBReactContext);
  const filter = useMemo(() => args.filter ?? {}, [args.filter]);
  const filterString = JSON.stringify(filter);

  // Top level filters as keys, array of ranges and filters as values.
  const [filtersUsed, setFiltersUsed] = useState([]);

  // Clean filters. This means filters that have been requested by the content factory since either it has been mounted
  // or since the context has last changed.
  const [cleanFilters, setCleanFilters] = useState([]);

  // Data to overwrite contextual data with.
  const [contextualOverride, setContextualOverride] = useState({});

  const dispatch = useDispatch();

  // Filter state and content from store.
  const contextFilters = useSelector((state) => state.api.items?.[context]?.filters);
  const allContent = useSelector((state) => state.api.content);
  const currentContent = allContent?.[type];

  const latestFilter = filtersUsed.slice(-1)[0]?.filter;
  const latestFilterData = contextFilters?.[latestFilter];
  const originalFilterData = contextFilters?.[filterString];

  const isLoading = (latestFilterData?.isLoading || latestFilterData?.isRefreshing) ?? false;
  const isRefreshing = latestFilterData?.isRefreshing ?? false;

  /**
   * Loads more content to the latest filter.
   */
  const offs = latestFilterData?.offset;
  const loadMore = useCallback(() => {
    if (isLoading || disabled) {
      return;
    }
    dispatch(requestMultiple(vbRequest, context, JSON.parse(latestFilter), type, buildRequestArgs(args), offs ?? 0));
  }, [isLoading, disabled, dispatch, vbRequest, context, latestFilter, type, args, offs]);

  const [reloaded, setReloaded] = useState(false);

  const itemsCount = originalFilterData?.items.length;
  /**
   * Reloads all content. This involves invalidating all other filters in the context, and reloading items up until the
   * last offset.
   */
  const reloadItems = useCallback(
    (delta) => {
      if (isLoading || disabled) {
        return;
      }
      setReloaded(true);
      dispatch(
        reloadContext(vbRequest, context, filter, type, buildRequestArgs(args), (itemsCount ?? 0) + (delta ?? 0))
      );
      setFiltersUsed([]);
    },
    [isLoading, disabled, dispatch, vbRequest, context, filter, type, args, itemsCount]
  );

  // Update filters, load more items if there is nothing.
  useEffect(() => {
    if (disabled) return;

    if (!filtersUsed.length || filterString !== filtersUsed[0].filter) {
      setFiltersUsed([{ filter: filterString, from: 0 }]);
    } else if (!latestFilterData && !reloaded) {
      setCleanFilters((prev) => [...prev, latestFilter]);
      loadMore();
    } else {
      filtersUsed.forEach((f) => {
        const storeData = contextFilters?.[f.filter];
        if (!storeData?.isLoading && !storeData?.isRefreshing && !cleanFilters.includes(f.filter)) {
          setCleanFilters((prev) => [...prev, f.filter]);
          dispatch(
            refreshContent(vbRequest, context, JSON.parse(f.filter), type, args, storeData?.items.length || undefined)
          );
        }
      });
    }
  }, [
    filterString,
    filtersUsed,
    latestFilterData,
    disabled,
    loadMore,
    reloaded,
    args,
    contextFilters,
    type,
    context,
    dispatch,
    cleanFilters,
    latestFilter,
    vbRequest,
  ]);

  // Clear clean filters when the context changes.
  useEffect(() => {
    setCleanFilters([]);
  }, [context]);

  /**
   * Builds a new function that hook users can use for setting the new filter after the given index.
   *
   * @param {number} splitIndex the index to split at
   *
   * @returns {(filter: object): void} returns a function that can be used to set the new filter
   */
  const buildSetNewFilterFunction = useCallback(
    (splitIndex) => (newFilter) => {
      // Get the index in filter used to split after.
      if (filtersUsed.length) {
        let filterIndex = filtersUsed.findIndex((cur, ind, arr) => arr[ind].from >= splitIndex);
        if (filterIndex < 0) {
          filterIndex = filtersUsed.length;
        }
        setFiltersUsed([
          ...filtersUsed.slice(0, filterIndex),
          {
            filter: JSON.stringify(newFilter),
            from: splitIndex,
          },
        ]);
      }
    },
    [filtersUsed]
  );

  /**
   * Build the content.
   */

  const contentWithSplits = useMemo(() => {
    const returnArray = [];
    let splitAdded = false;
    // CF content is composed of one or more filters. Iterate through all of the filters that are currently in use.
    filtersUsed.forEach((f, filterIndex) => {
      const filterStart = f.from;

      // Get the items in the current filter.
      let filterItems = contextFilters?.[f.filter]?.items;
      if (!filterItems) {
        return;
      }

      // Don't go over next.from - current.from.
      if (filterIndex < filtersUsed.length - 1) {
        filterItems = filterItems.slice(0, filtersUsed[filterIndex + 1].from - f.from);
      }

      // Parse the filter. Now this filter is a JSON object specified by the user.
      const parsedFilter = JSON.parse(f.filter);

      // If this is not the first filter, push a switch to the content.
      if (filterIndex > 0) {
        returnArray.push({
          type: CONTENT_TYPE.SWITCH,
          filter: parsedFilter,
          index: filterStart,
        });
        splitAdded = true;
      }

      for (let i = 0; i <= filterItems.length; i++) {
        // Get the split info at the current index.
        let split = false;
        if (splits) {
          // Determine if this is the last index.
          const isLastIndex = i === filterItems.length && filterIndex === filtersUsed.length - 1;

          // If there is a new filter right after, use that as the parsed filter.
          let filterPassedToSplits = parsedFilter;
          if (i === filterItems.length && !isLastIndex) {
            filterPassedToSplits = JSON.parse(filtersUsed[filterIndex + 1].filter);
          }

          // Get the split via the provided splits function.
          split = splits(
            filterStart + i, // Global index
            filterPassedToSplits,
            isLastIndex && contextFilters?.[f.filter]?.noMore,
            buildSetNewFilterFunction(filterStart + i),
            filterItems.length
          );
        }

        if (split !== false && !splitAdded) {
          // If there should be a split here, add it to the content.
          const entry = {
            type: CONTENT_TYPE.SPLIT,
            localIndex: i,
            content: split,
          };
          returnArray.push(entry);
          splitAdded = true;
          i -= 1;
        } else if (i < filterItems.length) {
          // Otherwise, add the item to the content.
          splitAdded = false;
          const inst = filterItems[i];
          const ctxOverride = contextualOverride[inst.item];
          returnArray.push({
            type: CONTENT_TYPE.ITEM,
            item: populateReferences(currentContent?.[inst.item].content, type, allContent),
            key: inst.item,
            index: i,
            contextual: typeof ctxOverride === 'undefined' ? inst.contextual : ctxOverride,
            filter: parsedFilter,
          });
        }
      }
    });
    // Handle sorting if sort function is defined
    if (sort) return returnArray.sort(sort);
    return returnArray;
  }, [
    filtersUsed,
    sort,
    contextFilters,
    splits,
    buildSetNewFilterFunction,
    contextualOverride,
    currentContent,
    type,
    allContent,
  ]);

  /**
   * Wrapper for load more that is passed out. This wrapper just makes it so latestFilterData must be defined before
   * more items can be loaded.
   */
  const loadMoreWrapper = useCallback(() => {
    if (!latestFilterData) {
      return null;
    }
    return loadMore();
  }, [latestFilterData, loadMore]);

  /**
   * Update contextual data for an item.
   *
   * @param {any} itemKey key of the item to update
   * @param {any} newContextual the new contextual data
   */
  const updateContextual = useCallback((itemKey, newContextual) => {
    setContextualOverride((prev) => ({ ...prev, [itemKey]: newContextual }));
  }, []);

  return {
    content: contentWithSplits,
    loadMore: loadMoreWrapper,
    reload: reloadItems,
    loading: isLoading || !latestFilterData,
    refreshing: isRefreshing,
    noMore: latestFilterData?.noMore ?? false,
    lastSwitchIndex: filtersUsed.slice(-1)[0]?.from,
    error: latestFilterData?.error ?? null,
    updateContextual,
    context,
    filter: latestFilter,
  };
};
