import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import MetaTags from '../../../../../shared-react/src/components/MetaTags/MetaTags';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import useContentSingle from '../../../../../shared-react/src/hooks/content-single';
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import APIConfig from '../../../../../shared-react/src/config/api';
import PostCard from '../PostCard/PostCard';
import Branding from '../../../../../shared-react/src/config/branding';
/**
 * Content of the popup for viewing a post.
 * @param {number} id   The id of the post
 */
const PopupPost = (props) => {
  const { id } = props;
  const [loading, setLoading] = useState(true);

  const getQueryAddress = useCallback(() => `${APIConfig.NAMESPACE.POST}/${id}`, [id]);
  const result = useContentSingle({
    type: ContentTypes.post.type,
    id,
    getQueryAddress,
    getTransient: useCallback(({ distance }) => ({ distance }), []),
  });
  const post = result?.content;
  const distance = result?.transient?.distance;

  useEffect(() => {
    if (post) {
      setLoading(false);
    }
  }, [post]);

  if (loading) {
    return (
      <center>
        <VBSpinner />
      </center>
    );
  }

  return (
    <>
      {post && (
        <MetaTags
          title={`${post.user?.username}'s post on ${post.venue?.name} in ${post.venue?.city?.city ?? ''}`.trim()}
          description={post?.content || `View this post on ${Branding.SITE_NAME}.`}
        />
      )}
      <PostCard post={post} distance={distance} hideBorder commentsReplaceContent />
    </>
  );
};
PopupPost.propTypes = {
  id: PropTypes.number.isRequired,
};

PopupPost.WIDTH = 'sm';
PopupPost.NO_PADDING_MOBILE = true;

export default PopupPost;
