import { useState } from 'react';
import QuestionsList from './QuestionsList/QuestionsList';
import AnswerForm from './AnswerForm/AnswerForm';

const PopupQuestions = ({ locationDetails }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  if (selectedQuestion) {
    return (
      <AnswerForm
        locationDetails={locationDetails}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
      />
    );
  }

  return <QuestionsList locationDetails={locationDetails} setSelectedQuestion={setSelectedQuestion} />;
};

export default PopupQuestions;
