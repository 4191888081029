import { Suspense, lazy } from 'react';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';

const UpsertFestivalForm = lazy(() => import('./UpsertFestivalForm/UpsertFestivalForm'));

const PopupUpsertFestival = ({ locationDetails, festivalId, defaultValues }) => {
  return (
    <Suspense fallback={<VBSpinner center />}>
      <UpsertFestivalForm locationDetails={locationDetails} festivalId={festivalId} defaultValues={defaultValues} />
    </Suspense>
  );
};

export default PopupUpsertFestival;
