import DummyUsersDropdownList from '../../../../../../shared-react/src/components/DummyUsersDropdownList/DummyUsersDropdownList';
import useDropdown from '../../../../../../shared-react/src/hooks/dropdown';

import styles from './Author.module.scss';

const Author = ({ author, onSelect }) => {
  const { dropdown } = useDropdown({
    dropdown: (
      <DummyUsersDropdownList
        elementWidth={200}
        containerClassName={styles.options}
        itemClassName={styles.option}
        onSelect={onSelect}
        displayBelow
        shadow
      />
    ),
    dropdownClassName: styles.author,
    trigger: <div>{author.username}</div>,
    triggerClassName: styles.trigger,
  });

  return dropdown;
};

export default Author;
