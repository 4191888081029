import Skeleton from 'react-loading-skeleton';
import VBRadioGroup from '../VBRadioGroup/VBRadioGroup';
import VBRadioButton from '../VBRadioGroup/VBRadioButton/VBRadioButton';
import { mergeClassNames } from '../../util/props';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './TriviaQuestion.module.scss';

const RANDOM_WIDTH_ARRAY = [195, 170, 200, 240];

const TriviaQuestion = ({ question, answer, disabled, isLoading, onOptionClick, onSelectAnswer }) => {
  const hasAnswer = answer !== undefined;

  const options = isLoading ? [...Array(4)] : question.options;

  return (
    <div className={styles.triviaQuestion}>
      <span className={styles.question}>{isLoading ? <Skeleton /> : question.question}</span>
      <ul className={styles.list}>
        <VBRadioGroup selected={answer} onChange={onSelectAnswer}>
          {options.map((option, idx) => {
            if (isLoading) {
              return <Skeleton key={idx} width={`${RANDOM_WIDTH_ARRAY[idx]}px`} />;
            }

            const isSelectedOption = answer === idx;
            const isOptionCorrect = question.correctOptionIdx === idx;

            return (
              <li
                key={idx}
                className={mergeClassNames(
                  styles.option,
                  hasAnswer && isOptionCorrect && styles.correct,
                  hasAnswer && isSelectedOption && !isOptionCorrect && styles.wrong
                )}
              >
                <VBRadioButton value={idx} label={option} disabled={disabled} onClick={onOptionClick} />
              </li>
            );
          })}
        </VBRadioGroup>
      </ul>
    </div>
  );
};

export default TriviaQuestion;
