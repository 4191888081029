import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import VBHeading from '../VBHeading/VBHeading';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import Share from '../Share/Share';

const propTypes = {
  /** the URL to share */
  permalink: PropTypes.string.isRequired,
};
const defaultProps = {};

/**
 * Popup to share a page.
 */
const PopupSharePage = ({ permalink }) => {
  const { brandingConfig } = useContext(VBReactContext);
  const getPermalink = useCallback(() => permalink, [permalink]);

  return (
    <div>
      <VBHeading>Share This Page</VBHeading>
      <Share
        hashtag={brandingConfig.HASHTAG}
        subject={brandingConfig.SHARE_SUBJECT}
        description={brandingConfig.SHARE_DESCRIPTION}
        includeLink
        getPermalink={getPermalink}
      />
    </div>
  );
};
export default PopupSharePage;
PopupSharePage.propTypes = propTypes;
PopupSharePage.defaultProps = defaultProps;
PopupSharePage.SIZE = 'sm';
