import VBLink from '../../../../../shared-react/src/components/VBLink/VBLink';
import useQueryParams from '../../../../../shared-react/src/hooks/query-params';
import { getLiveSiteUrl } from '../../../../../shared-react/src/util/sites';
import ZuzuBadge from '../../../assets/images/badges/zuzu.svg';
import styles from './PageEmbedBadge.module.scss';

const DIM = { WIDTH: 480, HEIGHT: 153 };
const DEFAULT_WIDTH = 200;

/**
 * Gets an iframe HTML code for the venue badge from a venue.
 *
 * @param {string} url
 * @returns {string} HTML code
 */
export const getIFrame = (url) =>
  `<iframe src="${getLiveSiteUrl()}/embed/badge?a=${encodeURIComponent(
    url
  )}" title="Zuzu Badge" width="${DEFAULT_WIDTH}" height="${Math.ceil((DEFAULT_WIDTH * DIM.HEIGHT) / DIM.WIDTH)}" />`;

/**
 * Venue badge used in iFrames. Takes query parameters w (width in px, optional) and a (link, required)
 */
const PageEmbedBadge = () => {
  const { w: targetWidthStr, a: href } = useQueryParams();
  const targetWidth = targetWidthStr ? parseInt(targetWidthStr, 10) : DEFAULT_WIDTH;

  if (!href) return null;

  return (
    <VBLink className={styles.container} noStyle to={href} newTab>
      <img src={ZuzuBadge} alt="Kids Award 2022" style={{ width: `${targetWidth}px` }} />
    </VBLink>
  );
};

export default PageEmbedBadge;
