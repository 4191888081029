import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import escapeRegExp from 'lodash.escaperegexp';
import VBLink from '../VBLink/VBLink';

const propTypes = {
  text: PropTypes.string.isRequired,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      match: PropTypes.string,
      permalink: PropTypes.string,
    })
  ),
  componentsOverride: PropTypes.object,
  html: PropTypes.bool,
};

export const getMarkdownLink = (text, link) => {
  return `[${text}](${link})`;
};

export const injectMatchesInText = (text, matches) => {
  if (!matches?.length) {
    return text;
  }

  return [...matches]
    .sort((a, b) => b.match.length - a.match.length)
    .reduce((result, match) => {
      try {
        const regExp = new RegExp(`(?<!\\[[^[\\]]*)\\b${escapeRegExp(match.match)}\\b(?![^[\\]]*\\])`, 'g');
        const markdownLink = getMarkdownLink(match.match, match.permalink);

        return result.replaceAll(regExp, markdownLink);
      } catch (error) {
        console.error(error);
        // do nothing: browser does not support negative lookahead (probably)
      }
      return result;
    }, text);
};

const MarkdownText = ({ text, matches, componentsOverride, html }) => {
  return (
    <ReactMarkdown
      components={{
        ...componentsOverride,
        a: ({ href, children }) => (
          <VBLink to={href} aria-label={children}>
            {children}
          </VBLink>
        ),
      }}
      rehypePlugins={html ? [rehypeRaw] : []}
    >
      {injectMatchesInText(text, matches)}
    </ReactMarkdown>
  );
};

MarkdownText.propTypes = propTypes;

export default MarkdownText;
