import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Header from './Header/Header';
import ShareList from '../../ShareList/ShareList';
import Branding from '../../../config/branding';
import useVbContext from '../../../hooks/vb-context';
import Share from '../../Share/Share';

import styles from './PopupShare.module.scss';

/**
 *
 * @param {string}  type            Should be one of these: View, Post, Collection, Page, FAQ or Article.
 *                                  What type of content is being shared?
 * @param {string}  permalink       Address for the copyable link that will be displayed to the user.
 *
 * Arguments that additionally required if type="View":
 * @param {string}  name            Name of the venue
 * @param {number}  venueId         ID of the venue
 *
 * Arguments that additionally required if type="Itinerary":
 * @param {string}  name            Name of the itinerary
 * @param {number}  itineraryId     ID of the itinerary

 * Arguments that additionally required if type="Post":
 * @param {string|int} postId       Id of the post
 * @param {string}  name            Name of the venue
 * @param {string}  username        Username of the post author
 * @param {string}  userLink        Permalink to user's page
 *
 * Arguments that additionally required if type="Collection":
 * @param {string}  name            Name of the collection
 *
 * Arguments that additionally required if type="Page":
 * @param {string} pageName         title of the page, e.g: "Top 25 Views: Chicago, IL"
 * 
 * Arguments that additionally required if type="FAQ"
 * @param {string} faqId             Id of the FAQ
 * 
 * Arguments that additionally required if type="article"
 * @param {string} articleId         Id of the article
 */

const PopupShare = ({ type, permalink, name, venueId, postId, itineraryId, faqId, articleId, username, userLink }) => {
  const { currentUser, brandingConfig } = useVbContext();

  const uniqueId = `invite-popup-${Math.floor(Math.random() * 10000000)}`;

  const permalinkByType = useMemo(() => {
    switch (type) {
      case 'Post': {
        return `${window.location.href.replace(/\?.*$/, '')}?show=post&id=${postId}`;
      }
      case 'FAQ': {
        return `${window.location.href.replace(/\?.*$/, '')}?show=faq&id=${faqId}`;
      }
      case 'Article': {
        return `${window.location.href.replace(/\?.*$/, '')}?show=article&id=${articleId}`;
      }
      default: {
        return permalink;
      }
    }
  }, [type, postId, faqId, articleId, permalink]);

  const shareList = useMemo(() => {
    if (!currentUser) {
      return null;
    }

    switch (type) {
      case 'View': {
        return <ShareList contentId={venueId} contentType="venue" />;
      }
      case 'Itinerary': {
        return <ShareList contentId={itineraryId} contentType="itinerary" />;
      }
      default: {
        return null;
      }
    }
  }, [currentUser, itineraryId, type, venueId]);

  const getPermalink = useCallback(() => permalinkByType, [permalinkByType]);

  return (
    <div id={uniqueId} className={styles.sharePopup}>
      <Header type={type} name={name} username={username} userLink={userLink} />
      <Share
        includeLink
        getPermalink={getPermalink}
        hashtag={Branding.HASHTAG}
        subject={brandingConfig.SHARE_SUBJECT}
        description={brandingConfig.SHARE_DESCRIPTION}
      />
      <div style={{ height: '2rem' }} />
      {shareList}
    </div>
  );
};

PopupShare.propTypes = {
  type: PropTypes.string.isRequired,
  permalink: PropTypes.string,
  venueName: PropTypes.string,
  venueId: PropTypes.number,
  postId: PropTypes.number,
  username: PropTypes.string,
  userLink: PropTypes.string,
  collectionName: PropTypes.string,
  pageName: PropTypes.string,
  itineraryId: PropTypes.number,
};

PopupShare.defaultProps = {
  permalink: undefined,
  venueName: undefined,
  venueId: undefined,
  postId: undefined,
  username: undefined,
  userLink: undefined,
  collectionName: undefined,
  pageName: undefined,
  itineraryId: undefined,
};

PopupShare.NO_OVERFLOW = true;

export default PopupShare;
