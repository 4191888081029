/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { buildDistanceString } from '../../util/distance';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './Distance.module.scss';

const propTypes = {
  /** either the coordinates of the location or the name of the location. For example, "32.7807, -79.7984" or: [32.7807,
   *  -79.7984]. Used for google maps link */
  destination: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),

  /** distance to the location */
  distance: PropTypes.number,

  /** direction to the location */
  direction: PropTypes.string,

  /** size to use for the font. Defaults to 1rem */
  fontSize: PropTypes.string,

  /** makes the link pull up directions rather than just a pin */
  directions: PropTypes.bool,

  /** display the distance with "From me" appended */
  nearMe: PropTypes.bool,

  /** whether to show skeleton or no */
  isLoading: PropTypes.bool,
};

const defaultProps = {
  nearMe: false,
  fontSize: '1rem',
  isLoading: false,
};

/**
 * Displays a distance and provides a link to view directions to the location.
 * @param {object} props
 */
const Distance = ({ destination, distance, directions, direction, fontSize, nearMe, isLoading }) => {
  // Set default values
  let showDirections = true;
  if (directions !== undefined) {
    showDirections = true;
  }

  let baseLink;
  if (showDirections) {
    baseLink = 'https://www.google.com/maps/dir/?api=1'; // Directions to location
  } else {
    baseLink = 'https://www.google.com/maps/search/?api=1'; // Pin on map
  }

  const googleMapsLink = `${baseLink}&destination=${encodeURIComponent(destination)}`;

  // Prepare text
  let el;
  if (typeof distance === 'string') {
    el = distance;
  } else if (distance) {
    let str = buildDistanceString(distance);
    if (direction && !nearMe) str += ` ${direction}`;
    if (nearMe) str += '  From me';
    el = <>{str}</>;
  } else {
    el = showDirections ? <>Location</> : <>map</>;
  }

  if (isLoading) {
    return <Skeleton width="115px" />;
  }

  return (
    <a className={styles.distance} style={{ fontSize }} target="_blank" href={googleMapsLink} rel="noreferrer">
      {el}
    </a>
  );
};

export default Distance;

Distance.propTypes = propTypes;
Distance.defaultProps = defaultProps;
