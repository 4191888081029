export const ItinerarySeason = {
  WINTER: 0,
  SPRING: 1,
  SUMMER: 2,
  FALL: 3,
};

export const ITINERARY_SEASONS = [
  ItinerarySeason.FALL,
  ItinerarySeason.WINTER,
  ItinerarySeason.SPRING,
  ItinerarySeason.SUMMER,
];

export const ItinerarySeasonLabel = {
  [ItinerarySeason.WINTER]: 'Winter',
  [ItinerarySeason.SPRING]: 'Spring',
  [ItinerarySeason.SUMMER]: 'Summer',
  [ItinerarySeason.FALL]: 'Fall',
};

export const DAYS_PER_ITINERARY = 7;

export const CURRENT_SEASON = [
  ItinerarySeason.WINTER,
  ItinerarySeason.SPRING,
  ItinerarySeason.SPRING,
  ItinerarySeason.SPRING,
  ItinerarySeason.SUMMER,
  ItinerarySeason.SUMMER,
  ItinerarySeason.SUMMER,
  ItinerarySeason.FALL,
  ItinerarySeason.FALL,
  ItinerarySeason.FALL,
  ItinerarySeason.WINTER,
  ItinerarySeason.WINTER,
][new Date().getUTCMonth()];
