import { CLEAR_TOKEN } from '../../../../shared-react/src/store/types';
import { CURRENT_USER_ERROR } from '../../../../shared-react/src/store/types';
import { CURRENT_USER_LOADING } from '../../../../shared-react/src/store/types';
import { CURRENT_USER_RECEIVED } from '../../../../shared-react/src/store/types';
import { SET_TOKEN } from '../../../../shared-react/src/store/types';

const initialState = window._pmgCurrentUser?.user
  ? window._pmgCurrentUser.user
  : { isLoading: false, user: null, error: false, initialized: false };

/**
 * Reducer for the current user field in the store.
 *
 * @param {object} state the object representing the current user
 * @param {string} action.type the type of the action. Currently only 'setCurrentUser' is valid
 * @param {object} action.payload.user the user listing object
 *
 * @returns {object} the new current user
 */
const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_USER_LOADING:
      return { ...state, initialized: true, isLoading: true };
    case CURRENT_USER_RECEIVED:
      return { ...state, isLoading: false, user: action.payload.user, initialized: true };
    case CURRENT_USER_ERROR:
      return { ...state, error: action.payload.error };
    case CLEAR_TOKEN:
    case SET_TOKEN:
      return initialState;
    default:
  }
  return state;
};

export default currentUserReducer;
