import PropTypes from 'prop-types';

/**
 * Prop type for a value parsed with boolProp.
 */
export const PropTypesVBBool = PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]);

/**
 * Proptype for the groups for a multi group dropdown.
 */
export const PropTypesVBMultiGroupDropdownGroups = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string,
    // Index of option that is selected
    selected: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }).isRequired
    ),
  }).isRequired
);

/**
 * User listing info
 */
export const PropTypesVBUser = PropTypes.shape({
  avatar: PropTypes.string,
  id: PropTypes.number,
  permalink: PropTypes.string,
  fullName: PropTypes.string,
  username: PropTypes.string,
});

/**
 * User listing info
 */
export const PropTypesVBUserListing = PropTypes.shape({
  avatar: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  permalink: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
});

/**
 * Prop types for bucket list status.
 */
export const PropTypesVBBucketStatus = PropTypes.shape({
  collections: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  list: PropTypes.string.isRequired,
});

/**
 * See VB_Venue_Description.
 */
export const PropTypesVBVenueDescription = PropTypes.shape({
  content: PropTypes.string,
  contentSource: PropTypes.string,
  isHelpfuled: PropTypes.bool,
  helpfuls: PropTypes.number,
  isUserSubmitted: PropTypes.bool,
  contentReviewId: PropTypes.number,
  userId: PropTypes.number,
  user: PropTypesVBUser,
  venueId: PropTypes.number,
});

/**
 * Prop type for a venue.
 */
export const PropTypesVBVenue = PropTypes.shape({
  bronzeBadges: PropTypes.number,
  bucketStatus: PropTypesVBBucketStatus,
  category: PropTypes.string,
  city: PropTypes.shape({
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
  }),
  description: PropTypesVBVenueDescription,
  featuredImage: PropTypes.string,
  goldBadges: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isVirusLowRisk: PropTypes.bool,
  latestUsers: PropTypes.shape({
    total: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypesVBUserListing.isRequired).isRequired,
  }),
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  permalink: PropTypes.string,
  silverBadges: PropTypes.number,
  subcategory: PropTypes.string,
  kidsAgeGroups: PropTypes.number,
});

/**
 * Nav info for a location
 */
export const PropTypesVBNavInfo = PropTypes.shape({
  id: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  searchLink: PropTypes.string.isRequired,
  trendingLink: PropTypes.string.isRequired,
  citySlug: PropTypes.string.isRequired,
  regionSlug: PropTypes.string.isRequired,
  countrySlug: PropTypes.string.isRequired,
  regionShort: PropTypes.string.isRequired,
  countryShort: PropTypes.string.isRequired,
}).isRequired;

/**
 * Prop type for place details
 */
export const PropTypesVBPlaceDetails = PropTypes.shape({
  city: PropTypes.string,
  citySlug: PropTypes.string,
  country: PropTypes.string,
  countrySlug: PropTypes.string,
  description: PropTypes.string,
  readMore: PropTypes.string,
  id: PropTypes.number.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  region: PropTypes.string,
  regionSlug: PropTypes.string,
  latestUsers: PropTypes.shape({
    total: PropTypes.number,
    users: PropTypes.arrayOf(PropTypesVBUserListing.isRequired),
  }),
  searchLink: PropTypes.string,
  trendingLink: PropTypes.string,
  regionShort: PropTypes.string,
  countryShort: PropTypes.string,
});

/**
 * Prop type for collection
 */
export const PropTypesVBCollection = PropTypes.shape({
  displayOrder: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
});

/**
 * Category
 */
export const PropTypesVBCategory = PropTypes.oneOf([null, 'activities', 'restaurants', 'hotels', 'bars', 'tours']);

export const PropTypesVBPost = PropTypes.shape({
  content: PropTypes.string,
  time: PropTypes.number,
  image: PropTypes.string,
  likes: PropTypes.number,
  userLiked: PropTypes.bool,
  comments: PropTypes.number,
  // In the future, we should have seperate proptypes for each context. Neither of our VB_Venue Proptypes here really
  // represent the venue field in here well
  // eslint-disable-next-line react/forbid-prop-types
  venue: PropTypes.object,
  user: PropTypesVBUserListing,
});

export const PropTypesVBInfographic = PropTypes.shape({
  user: PropTypesVBUserListing,
  time: PropTypes.number,
  // Image url
  image: PropTypes.string,
  location: PropTypesVBPlaceDetails,
  category: PropTypes.oneOf(['', 'activities', 'restaurants', 'hotels', 'bars', 'tours']),
  // Link to the user's infographics page with a popup of this infographic.
  permalink: PropTypes.string,
  likes: PropTypes.number,
  userLiked: PropTypes.bool,
  comments: PropTypes.number,
});

/**
 * A comment on a piece of content.
 */
export const PropTypesVBComment = PropTypes.shape({
  id: PropTypes.number,
  user: PropTypesVBUser,
  time: PropTypes.number,
  content: PropTypes.string,
  likeCount: PropTypes.number,
  replyCount: PropTypes.number,
  liked: PropTypes.bool,
});

/**
 * A reply to a comment
 */
export const PropTypesVBCommentReply = PropTypes.shape({
  id: PropTypes.number,
  user: PropTypesVBUser,
  time: PropTypes.number,
  content: PropTypes.string,
});

/**
 * Valid sized for UserListing.
 */
export const PropTypesVBUserListingSize = PropTypes.oneOf(['lg', 'sm']);

/**
 * The props that will always be returned in a content review type.
 * These are used in reviewing user submitted content in Content Review menu.
 */
const contentReviewTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  timeCreated: PropTypes.number,
  timeResolved: PropTypes.number,
  // -1 rejected, 0 unresolved, 1 accepted
  accepted: PropTypes.number,
  user: PropTypesVBUserListing,
};

export const PropTypesVBCRDescription = PropTypes.shape({
  ...contentReviewTypes,
  descriptionId: PropTypes.number,
  description: PropTypesVBVenueDescription,
  submittedFrom: PropTypes.oneOf(['venue_page', 'infographic']),
});

export const PropTypesRefProp = PropTypes.oneOfType([
  // Either a function
  PropTypes.func,
  // Or the instance of a DOM native element (see the note about SSR)
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const PropTypesTravelFeedChannel = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  timeAdded: PropTypes.string,
  rssUrl: PropTypes.string,
  siteUrl: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
});

export const PropTypesTravelFeedItem = PropTypes.shape({
  id: PropTypes.string,
  pubTime: PropTypes.string,
  timeRetrieved: PropTypes.string,
  thumbnail: PropTypes.string,
  clicks: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  channel: PropTypesTravelFeedChannel,
});

export const PropTypesShareLinks = PropTypes.shape({
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  pinterest: PropTypes.string,
  email: PropTypes.string,
  // Just through a copy paste of the url
  direct: PropTypes.string,
});

export const PopupAuthPage = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  FORGOT_PASSWORD: 'forgotPassword',
  CONFIRM_EMAIL: 'confirmEmail',
  SOCIAL_LOGIN: 'socialLogin',
};

export const PropTypesAuthPopupPages = PropTypes.oneOf([
  PopupAuthPage.SIGN_UP,
  PopupAuthPage.SIGN_IN,
  PopupAuthPage.FORGOT_PASSWORD,
  PopupAuthPage.CONFIRM_EMAIL,
  PopupAuthPage.SOCIAL_LOGIN,
  false,
]);

/**
 * Ellipsis items on the explore page.
 */
export const ExploreEllipsisPropType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    buttonType: PropTypes.string,
  }).isRequired
);

/**
 * Return type of content factory.
 */
export const PropTypesVBContentFactoryReturn = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      filter: PropTypes.any,
      index: PropTypes.number,
      localIndex: PropTypes.number,
      content: PropTypes.node,
      item: PropTypes.any,
      key: PropTypes.any,
      contextual: PropTypes.any,
    }).isRequired
  ).isRequired,
  loadMore: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  noMore: PropTypes.bool.isRequired,
  lastSwitchIndex: PropTypes.number,
});

export const BEEN_THERE_OPTIONS = ['beenThereBad', 'beenThereGood', 'beenThereGreat'];
export const LIST_OPTIONS = ['', 'bucketlist'].concat(BEEN_THERE_OPTIONS);
/**
 * For Bucketlist dropdown
 */
export const PropTypesVBListStatus = PropTypes.oneOf(LIST_OPTIONS);

export const PropTypesVBVenueVideo = PropTypes.shape({
  id: PropTypes.string,
  pubTime: PropTypes.string,
  timeRetrieved: PropTypes.string,
  thumbnail: PropTypes.string,
  clicks: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  venue: PropTypesVBVenue,
});

export const PropTypesVBReviewParentType = PropTypes.oneOf(['venue', 'itinerary']);
