/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesRefProp, PropTypesVBBool } from '../../util/types';
import styles from './VBButton.module.scss';
import { boolProp, mergeClassNames } from '../../util/props';

/**
 * A simple button component.
 * Can use the 'content' prop or provide children.
 * onClick functionality should be provided.
 *
 * @param {string} props.size size of the button
 * @param {string} props.type style of the button. Using type full-red is @DEPRECATED by props.color
 * @param {string} props.color The color of the button. Default is blue.
 * @param {string} props.content button content
 * @param {string} props.className additional classes
 * @param {boolean} props.submit whether the button is a submit button
 * @param {boolean} props.disabled Will disable the button from clicks.
 * @param {boolean} props.circle whether or not the button is a circle
 * @param {boolean} props.noFocusStyle whether to not change the style when the element is focused
 */
const VBButton = (props) => {
  const { children, size, type, content, className, submit, color, circle, buttonRef, noFocusStyle, ...other } = props;

  let coloredType = type;
  if (color) {
    coloredType = `${type}-${color}`;
  }

  const buttonType = { type: 'button' };
  if (boolProp(submit)) {
    buttonType.type = 'submit';
  }

  let render;
  if (content) render = content;
  if (children)
    render = (
      <>
        {render}
        {children}
      </>
    );
  if (!render) render = <>&nbsp;</>;

  return (
    <button
      {...other}
      {...buttonType}
      className={mergeClassNames(
        className,
        styles[coloredType],
        styles[size],
        circle ? styles.circle : null,
        noFocusStyle ? null : styles.focus
      )}
      ref={buttonRef}
    >
      {render}
    </button>
  );
};
VBButton.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['tny', 'xs', 'sm', 'med', 'lg']),
  type: PropTypes.oneOf(['full', 'empty', 'border', 'badgeFull', 'cardBadge', 'collection', 'full-red', 'border-red']),
  content: PropTypes.node,
  className: PropTypes.string,
  submit: PropTypesVBBool,
  color: PropTypes.oneOf(['blue', 'red', 'green', 'yellow', '']),
  circle: PropTypes.bool,
  buttonRef: PropTypesRefProp,
  noFocusStyle: PropTypes.bool,
};
VBButton.defaultProps = {
  type: 'full',
  size: 'med',
  className: undefined,
  color: '',
  circle: false,
  buttonRef: undefined,
};

export default VBButton;
