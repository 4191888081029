import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import VBButton from '../VBButton/VBButton';
import { COMMON_DISTANCE_RANGES } from '../../config/explore';
import styles from './RadiusBubbleSelector.module.scss';
import { mergeClassNames } from '../../util/props';
import useVBBreakpoint from '../../hooks/vb-breakpoint';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  /** json array of non repeating indices in DISTANCE_RANGES */
  radius: PropTypes.string.isRequired,

  /** setter for radius */
  setRadius: PropTypes.func.isRequired,

  /** whether or not to hide the label. Pretty much only use this if the
   * selector is always displayed and needs to be labeled (i.e. not in a FilterSelector) */
  hideLabel: PropTypes.bool,

  /** whether the radius buttons should be displayed vertically or horizontally. */
  isVertical: PropTypes.bool,

  /** whether or not the filters should take up the full width of the container */
  fullWidth: PropTypes.bool,

  /** distance ranges  */
  ranges: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const defaultProps = {
  hideLabel: false,
  isVertical: false,
  fullWidth: false,
  ranges: COMMON_DISTANCE_RANGES,
};

/**
 * Select radius in DISTANCE_RANGES. Used on kids site.
 *
 * @param {object} props
 */
const RadiusBubbleSelector = ({ radius, setRadius, hideLabel, isVertical, fullWidth, analyticsLabel, ranges }) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const radiusArr = useMemo(() => (typeof radius === 'string' ? JSON.parse(radius) : radius), [radius]);
  const { lteXs: mobileStyle } = useVBBreakpoint();

  /**
   * Toggles whether or not a radius is selected.
   *
   * @param {number} rad the radius group index, see DISTANCE_RANGES
   */
  const toggleRadiusGroup = (rad) => {
    const idx = radiusArr.indexOf(rad);
    const newRadius = [...radiusArr];

    const getRadiusValue = (newRadius) => {
      return typeof radius === 'string' ? JSON.stringify(newRadius) : newRadius;
    };

    if (idx < 0) {
      newRadius.push(rad);
      newRadius.sort();
      setRadius(getRadiusValue(newRadius));
    } else if (radiusArr.length > 1) {
      newRadius.splice(idx, 1);
      newRadius.sort();
      setRadius(getRadiusValue(newRadius));
    }
  };

  let label = null;
  if (!hideLabel) {
    label = <span className={styles.label}>Radius: </span>;
  }

  let buttons = ranges.map((max, idx) => (
    <VBButton
      content={` ${ranges[idx - 1] ?? 0} - ${max} mi`}
      onClick={() => {
        if (analyticsLabel)
          sendAnalyticsEvent(`vb_explore_filter_${analyticsLabel}_${idx}_${radius.includes(idx) ? 0 : 1}`);
        toggleRadiusGroup(idx);
      }}
      size="med"
      type={radius.includes(idx) ? 'full' : 'border'}
      key={max}
      className={fullWidth ? styles.fullWidth : null}
    />
  ));

  if (!mobileStyle || hideLabel) {
    // Either the RadiusSelector is non-scrolling (b/c not on mobile size) or the label is not shown
    return (
      <div
        className={mergeClassNames(
          styles.radiusContainer,
          mobileStyle && !isVertical ? styles.scroll : null, // Only allow this to take effect for mobile horizontal
          isVertical ? styles.vertical : null
        )}
      >
        {label}
        {buttons}
      </div>
    );
  } else {
    return (
      // The label is shown AND the screen size is mobile
      <div className={styles.scrollWithLabel}>
        {label}
        <div className={mergeClassNames(styles.radiusContainer, styles.scroll)}>{buttons}</div>
      </div>
    );
  }
};

export default RadiusBubbleSelector;
RadiusBubbleSelector.propTypes = propTypes;
RadiusBubbleSelector.defaultProps = defaultProps;
