import React, { useState, useEffect, useContext, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import APIConfig from '../../config/api';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import VBSpinner from '../VBSpinner/VBSpinner';
import VBTextArea from '../VBTextArea/VBTextArea';
import { getIFrame } from '../../../../tripfool/src/components/Master/PageEmbedBadge/PageEmbedBadge';
import { getSite } from '../../util/sites';
import SITES from '../../../config-json/sites.json';
import OnClickLink from '../OnClickLink/OnClickLink';
import { ContentTypes } from '../../config/content-types';
import { addItem, updateItem } from '../../store/actions/apiActions';
import useDeleteVideo from '../../hooks/delete-video';

const EditVenueForm = lazy(() => import('./EditVenueForm/EditVenueForm'));

const propTypes = {
  venueId: PropTypes.number,
  SearchBar: PropTypes.elementType.isRequired,
};
const defaultProps = {
  venueId: undefined,
};

/**
 * Popup used for editing a venue.
 */
function PopupAdminEditVenue({ venueId, SearchBar }) {
  const [venue, setVenue] = useState();

  const { vbRequest } = useContext(VBReactContext);

  // Get current data for venue.
  useEffect(() => {
    if (venueId) {
      vbRequest(`${APIConfig.NAMESPACE.VENUE}/${venueId}/details`).then((data) => {
        setVenue(data.venue);
      });
    } else {
      vbRequest(`${APIConfig.NAMESPACE.SEARCH}/location-cookie`).then((res) => {
        setVenue({
          id: null,
          activityItineraryTime: 0,
          activityKidsRatio: 0,
          activityMaxTime: 0,
          activityMinTime: 0,
          activityOverallRating: 0,
          address: 0,
          allCategories: [{ id: 33, name: 'Activity' }],
          bronzeBadges: 0,
          category: 'Activity',
          categoryId: 33,
          city: res,
          description: '',
          descriptionSource: '',
          descriptionMeta: '',
          featuredImage: '',
          goldBadges: 0,
          hikeDifficulty: 0,
          hikeDistance: 0,
          hikePaved: false,
          hikeTime: 0,
          isTour: false,
          isVirusLowRisk: false,
          kidsAgeGroups: 0,
          kidsOverallRating: 0,
          latitude: 0,
          longitude: 0,
          name: '',
          overallRating: null,
          permalink: '',
          season: 3,
          silverBadges: 0,
          site: 7,
          subcategory: '',
          subcategoryId: 0,
          timeOfDay: 3,
          viewbuffRating: 0,
          twitterUser: '',
          ticketPrice: null,
          ticketLink: null,
          website: '',
        });
      });
    }
  }, [vbRequest, venueId]);

  const deleteVideo = useDeleteVideo();

  const featuredVideoId = venue?.featuredVideo?.id;
  const handleDeleteFeaturedVideo = () => deleteVideo(featuredVideoId, venueId);

  if (!venue) {
    return <VBSpinner />;
  }

  return (
    <Suspense fallback={<VBSpinner center />}>
      {featuredVideoId ? <OnClickLink onClick={handleDeleteFeaturedVideo}>Delete Video</OnClickLink> : null}
      <EditVenueForm venue={venue} SearchBar={SearchBar} />
      {getSite() === SITES.KIDS ? <VBTextArea value={getIFrame(venue.permalink)} /> : null}
    </Suspense>
  );
}

PopupAdminEditVenue.propTypes = propTypes;
PopupAdminEditVenue.defaultProps = defaultProps;

export default PopupAdminEditVenue;
