/**
 * Utility functions for working with objects.
 */

/**
 * Get a key from an object. The function looks for id/key fields.
 *
 * @param {object} x the input object
 * @param {boolean} warn whether or not to warn if no key could be found. Defaults to true
 */
export const getKey = (x, warn = true) => {
  // Add a null value at index 0 to make the || work out.
  const keys = Object.keys(x);
  const keysLower = keys.map((k) => k.toLowerCase());
  let index;

  index = keysLower.indexOf('id');
  if (index >= 0) {
    return x[keys[index]];
  }
  index = keysLower.indexOf('key');
  if (index >= 0) {
    return x[keys[index]];
  }
  const idRegex = /[a-z]I[dD]/g;
  index = keys.findIndex((k) => idRegex.test(k));
  if (index >= 0) {
    return x[keys[index]];
  }
  const keyRegex = /[a-z]K[eE][yY]/g;
  index = keys.findIndex((k) => keyRegex.test(k));
  if (index >= 0) {
    return x[keys[index]];
  }

  // If all else fails, stringify.
  if (warn) console.warn('failed to find a valid key. Returning stringified object');
  return JSON.stringify(x);
};

/**
 * Deep clone a serializable object.
 * @param {object} obj the object to clone
 * @returns {object} the cloned object
 */
export const deepCloneSerializable = (obj) => {
  if (isUndefined(obj)) return obj;
  return JSON.parse(JSON.stringify(obj)); // TODO: this is a hack
};

/**
 * Gets a JSON object from storage.
 *
 * @param {string} key key to use in getItem
 * @param {object} storage sessionStorage, localStorage or any object with a similar interface
 * @param {any} defaultVal
 * @returns
 */
export const getJsonFromStorage = (key, storage = localStorage, defaultVal = null) => {
  const str = storage.getItem(key);
  if (!str) return defaultVal;
  try {
    const { exp, val } = JSON.parse(str);
    if (!exp || Date.now() < exp) return val;
    return defaultVal;
  } catch (err) {
    console.error(`failed to parse value for '${key}'`);
    return defaultVal;
  }
};

/**
 * Saves JSON data in storage.
 *
 * @param {string} key key to use in setItem
 * @param {any} val
 * @param {object} storage sessionStorage, localStorage or any object with a similar interface
 * @param {number} exp unix timestamp for expiry in ms. Expiration will not remove the item, it will just be ignored
 *                     by getJsonFromStorage
 */
export const saveJsonInStorage = (key, val, storage = localStorage, exp = null) => {
  storage.setItem(key, JSON.stringify({ val, exp }));
};

/**
 * Returns whether or not a value has typeof 'undefined'
 * @param {any} x
 * @returns {boolean}
 */
export const isUndefined = (x) => typeof x === 'undefined';
