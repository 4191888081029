import { getSite } from '../../shared-react/src/util/sites';
import { isDevelopment, isLive } from '../../shared-react/src/util/env';
/**
 * Index of the CRA project. This is where the Master container is rendered into the root div. This is where you should
 * include providers that wrap the application.
 */

import './index.scss';

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store/store';
import Master from './components/Master/Master';
import { GOOGLE_ADS, GOOGLE_ANALYTICS, HOTJAR } from './config/analytics';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

let googleAnalyticsTag = null;
let googleAdsTag = null;
let hotjarConfig = null;

if (isLive()) {
  googleAnalyticsTag = GOOGLE_ANALYTICS[getSite()];
  googleAdsTag = GOOGLE_ADS[getSite()]?.tag;
  hotjarConfig = HOTJAR[getSite()];
}

// Initialize Google Ads.
if (googleAdsTag) {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag('js', new Date());
  gtag('config', googleAdsTag);
}

// Initialize Google analytics.
if (googleAnalyticsTag) {
  ReactGA.initialize(googleAnalyticsTag);
}

// Initialize HotJar.
if (hotjarConfig) {
  hotjar.initialize(hotjarConfig.hjid, hotjarConfig.hjsv);
}

// Initialize service worker.
if (!isDevelopment() && 'serviceWorker' in navigator) {
  // window.addEventListener('load', () => {
  //   const registerSw = () => {
  //     navigator.serviceWorker.register('/service-worker.js').then(
  //       () => {
  //         // Success: do nothing
  //       },
  //       (err) => {
  //         // Error
  //         console.error(err);
  //       }
  //     );
  //   };

  //   const lastSiteVersion = localStorage.getItem('siteVersionSW');
  //   if (lastSiteVersion !== VB_VERSION) {
  //     navigator.serviceWorker
  //       .getRegistrations()
  //       .then((registrations) => Promise.all(registrations.map((registration) => registration.unregister())))
  //       .then(() => {
  //         registerSw();
  //         localStorage.setItem('siteVersionSW', VB_VERSION);
  //       });
  //   } else {
  //     registerSw();
  //   }
  // });

  // For now, deregister service workers.
  window.addEventListener('load', () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  });
}

ReactDOM.render(
  <StrictMode>
    {/* React's Strict Mode: https://reactjs.org/docs/strict-mode.html */}
    <ReduxProvider store={store.store}>
      {/* Allows children to access the store */}
      <PersistGate loading={null} persistor={store.persistor}>
        {/* Wait until persistent store is loaded */}
        <Master />
      </PersistGate>
    </ReduxProvider>
  </StrictMode>,
  document.getElementById('root')
);
