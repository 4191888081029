import IconButton from '../../../../../../shared-react/src/components/IconButton/IconButton';
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../../shared-react/assets/images/icons/search/search-search.svg';
/**
 * Icon button for jumping to random city
 * @param props.onClick   The function to run on click
 */
const IconSearch = (props) => {
  return (
    <>
      <IconButton onClick={props.onClick} width="25px" height="40px">
        <img src={Image} height="16" width="16" alt="" />
      </IconButton>
    </>
  );
};

IconSearch.propTypes = {
  onClick: PropTypes.func,
};

IconSearch.defaultProps = {
  onClick: undefined,
};

export default IconSearch;
