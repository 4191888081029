import React from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.module.scss';
import VBLink from '../VBLink/VBLink';

/**
 * User avatar icon.
 *
 * @param {string} props.src         the source of the image
 * @param {number} props.userId      the user ID. This is only used to add additional classes
 * @param {number|string} props.size the size of the avatar in px or a css string
 * @param {string} props.className   other classes to add
 * @param {string} props.href        link to wrap the avatar in, or no value for no link
 */
const Avatar = (props) => {
  const { src, userId, size, className, href, ...other } = props;

  const classes = className ? className.split(' ') : [];
  classes.push(styles.avatar);
  if (userId) {
    classes.push(`user-${userId}-avatar`);
  }

  const isSizeString = typeof size === 'string';
  const isSizeNumber = typeof size === 'number' && !isNaN(size);
  let dim = {};
  if (isSizeString || isSizeNumber) {
    if (isSizeNumber) {
      classes.push(`avatar-${size}`);
      dim = {
        width: `${size}px`,
        height: `${size}px`,
      };
    } else {
      dim = {
        width: size,
        height: size,
      };
    }
  }
  classes.push('photo');

  const img = <img src={src} className={classes.join(' ')} style={dim} {...other} alt="" />;
  if (href) {
    return (
      <VBLink to={href} className="avatar-link">
        {img}
      </VBLink>
    );
  }
  return img;
};

Avatar.propTypes = {
  src: PropTypes.string,
  userId: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  href: PropTypes.string,
};

export default Avatar;
