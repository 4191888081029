import { toastr } from 'react-redux-toastr';
import { uppercaseFirst } from './string';
import React, { Component } from 'react';
import { isKids } from './sites';
/**
 * Raises a toast using react-redux-toastr
 * @param {object} branding The branding object unique to the website.
 * @param {string} message The message for the toast
 * @param {string} title The title for the toast (optional)
 * @param {image} icon The icon to display in the toast
 * @param {function} onToastrClick The function to call when the toast is clicked
 * @param {Component} comp A react component to display in the toast.
 */
export const raiseToast = (branding, message, title, icon, onToastrClick, comp) => {
  toastr.light(title || branding.SITE_NAME, message, {
    icon: icon || 'success',
    status: 'info',
    onToastrClick: onToastrClick,
    component: comp,
  });
};

/**
 * Raises a toast for gaining expert points.
 * @param {object} branding The branding object unique to the website.
 * @param {number} delta The amount of points gained or lost
 * @param {string} reason What was the reason for the change in points? Should be
 *                        phrased as a present participle, e.g: "liking a post"
 * @param {function} onToastClick the function to run when the toast is clicked
 */
export const pointsToast = (branding, delta, reason, onToastClick, comp) => {
  if (!delta || delta <= 0) {
    // Don't show toast if user isn't gaining points
    return;
  }
  const reasonMsg = reason ? `for ${reason}` : '';
  raiseToast(
    branding,
    reasonMsg,
    `${delta > 0 ? '+' : ''}${delta} ${isKids() ? 'parent points' : 'expert points'}`,
    undefined,
    onToastClick,
    comp
  );
};

/**
 * Raises a toast for an error
 * @param {object} branding The branding object unique to the website.
 * @param {Error|string} err the error
 * @param {string} title the title
 */
export const errorToast = (branding, err, title) => {
  // Capitalize first letter to make it look better to user
  const errString = uppercaseFirst(err instanceof Error ? err.message : `${err}`);
  toastr.error(title || branding.SITE_NAME, errString);
};
