/**
 * Util functions dealing with URLs
 */

import qs from 'query-string';

/**
 * Adds rank and exploreLink parameters to a venue permalink.
 *
 * @param {string} permalink the venue link
 * @param {number} rank the rank to assign
 * @param {string} exploreLink the explore page link
 *
 * @returns the new string
 */
export const addRankAndExploreLinkToPermalink = (
  permalink,
  rank,
  exploreLink,
  exploreLinkText,
  exploreContext,
  exploreFilter,
  exploreSubsection
) => {
  return addParams(
    permalink,
    Object.assign(
      {},
      rank ? { rank } : null,
      exploreLink ? { exploreLink } : null,
      exploreLinkText ? { exploreLinkText } : null,
      exploreContext ? { exploreContext } : null,
      exploreFilter ? { exploreFilter } : null,
      exploreSubsection ? { exploreSubsection } : null
    )
  );
};

/**
 * Builds a google map directions link
 * @param {number} destLat the destination latitude
 * @param {number} destLon the destination longitude
 * @param {number} originLat optional, the origin latitude
 * @param {number} originLon optional, the origin longitude
 */
export const buildGoogleMapsLink = (destLat, destLon, originLat, originLon) => {
  if (typeof originLat === 'undefined' || typeof originLon === 'undefined') {
    return `https://www.google.com/maps/dir/?api=1&destination=${destLat},${destLon}`;
  }
  return `https://www.google.com/maps/dir/?api=1&origin=${originLat},+${originLon}&destination=${destLat},${destLon}`;
};

/**
 * This will build a link for googling the venue. This is useful for Admin review stuff
 * when we want the admin to quickly fact check whatever information about a venue that they
 * are reviewing
 * @param {PropTypesVBVenue} venue
 */
export const buildGoogleSearchLink = (venue) => {
  if (!venue.city) return `https://google.com/search?q=${encodeURIComponent(venue.name)}`;

  return `https://google.com/search?q=${encodeURIComponent(
    [`"${venue.name}"`, venue.city.city, venue.city.region].join(' ')
  )}`;
};

/**
 * Add query parameters to a URL. If a parameter already exists in a URL it will be replaced.
 *
 * @param {string} url the URL to add query params to, which may already have query params
 * @param {object} params the params to add to the URL
 *
 * @returns {string} the new URL
 */
export const addParams = (url, params) => {
  if (!params || !Object.keys(params).length) return url;

  const qmInd = url.indexOf('?');

  // If there are existing params, combine and return
  if (qmInd > -1 && qmInd < url.length - 1) {
    const existingParams = qs.parse(url.substr(qmInd + 1));
    const paramsStr = qs.stringify({ ...existingParams, ...params }, { arrayFormat: 'bracket' });
    return `${url.substr(0, qmInd)}?${paramsStr}`;
  }

  const paramsStr = qs.stringify(params, { arrayFormat: 'bracket' });
  if (qmInd === url.length - 1) {
    return `${url}${paramsStr}`;
  }
  return `${url}?${paramsStr}`;
};

export const removeParams = (url, params) => {
  if (!params.length) return url;

  const qmInd = url.indexOf('?');

  // If there are existing params, combine and return
  if (qmInd > -1 && qmInd < url.length - 1) {
    const existingParams = qs.parse(url.substr(qmInd + 1));
    params.forEach((p) => {
      delete existingParams[p];
    });
    return `${url.substr(0, qmInd)}?${qs.stringify(existingParams)}`;
  }

  return url;
};

/**
 * Get query params from a react router location object.
 *
 * @param {object} location
 *
 * @returns {object}
 */
export const getLocationParams = (location) => {
  if (location.search) {
    return qs.parse(location.search.substr(1));
  }

  const [_hash, search] = location.hash.split('?');

  return qs.parse(search);
};

export const addReferrals = (url) => {
  return url
    ? addParams(url, {
        utm_source: 'zuzuforkids.com',
        utm_medium: 'referral',
        utm_campaign: 'zuzuforkids',
        utm_id: 'zuzuforkids',
      })
    : null;
};
