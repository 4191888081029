import React from 'react';
import VBButton from '../components/VBButton/VBButton';
import OnClickLink from '../components/OnClickLink/OnClickLink';
import VBLink from '../components/VBLink/VBLink';
import { isKids } from '../util/sites';
import { isUndefined } from './objects';
import { uppercaseFirst } from './string';
import { AGE_GROUPS } from '../config/explore';

/**
 * Utility functions for the explore page.
 */

/**
 * Builds a string such as "0mi-60mi" from a distances array.
 *
 * @param {array} selected array of distance range indices selected, ie [1, 2]
 * @param {array} distances the distance limits themselves in mi, ie [8, 20, 60]
 *
 * @returns {string} a string representing the distance
 */
export const buildDistanceFilterString = (selected, distances) => {
  if (!selected.length) return '> 0 mi';

  let start;
  let end;
  distances.forEach((max, idx) => {
    const isSelected = selected.includes(idx);
    if (isSelected) {
      if (typeof start === 'undefined') {
        start = idx;
      }
      if (typeof start !== 'undefined' && idx === distances.length - 1) {
        end = idx;
      }
    } else if (typeof start !== 'undefined' && typeof end === 'undefined') {
      end = idx - 1;
    }
  });

  const plus = selected.length - (end - start) - 1;
  return `${distances[start - 1] ?? 0} - ${distances[end]} mi${plus > 0 ? ` (+${plus})` : ''}`;
};

/**
 * Maps an array of {text, href?, onClick} to VBButton, OnClickLink, or OnClick elements for the explore page.
 *
 * @param {object[]} array of objects described above
 * @param {bool} buttons whether to create buttons or links
 * @param {object} history useHistory return
 *
 * @returns {node[]}
 */
export const mapEllipsisArray = (ellipsis, buttons = false, history = undefined) => {
  if (buttons && !history) throw new Error('if building ellipsis options with buttons, history must be defined');

  const defaultButtonType = 'full';
  // const defaultButtonType = 'border';

  if (buttons)
    return ellipsis.map(({ text, href, onClick, buttonType, htmlLink }) => {
      if (onClick)
        return (
          <VBButton key={text} onClick={onClick} type={defaultButtonType || buttonType}>
            {text}
          </VBButton>
        );

      const onLinkClick = () => {
        if (href.charAt(0) === '/') {
          if (!htmlLink) {
            history.push(href);
          } else {
            window.location = href;
          }
        } else {
          window.open(href, '_blank');
        }
      };
      return (
        <VBButton key={text} type={defaultButtonType || buttonType} onClick={onLinkClick}>
          {text}
        </VBButton>
      );
    });

  return ellipsis.map(({ text, href, onClick, htmlLink }) => {
    if (onClick)
      return (
        <OnClickLink key={text} onClick={onClick}>
          {text}
        </OnClickLink>
      );
    return (
      <VBLink key={text} to={href} newTab={href.charAt(0) !== '/'} forceHtmlLink={htmlLink}>
        {text}
      </VBLink>
    );
  });
};

export const getEventsLink = (city, region) => {
  let q = `events ${city}, ${region} today`;
  if (isKids()) q = `kids ${q}`;
  return `https://www.google.com/search?q=${encodeURIComponent(
    q.toLocaleLowerCase()
  )}&ibp=htl;events#fpstate=tldetail&htichips=date:today&htivrt=events`;
};

export const getInfographicsLink = (country, region, city, category) =>
  `/infographic-creator/create/${country}/${region}/${city}/${category && category !== 'all' ? category : ''}`;

/**
 * Limit a total number of venues.
 *
 * @param {number} total the total, which can be undefined (coming from useExploreTotalVenues usually)
 * @param {number} limit venue total limit, defaults to 50
 * @returns {number} total or limit
 */
export const limitExploreVenueTotal = (total, limit = 50) => (isUndefined(total) || total <= limit ? total : limit);

// export const replaceLocationInLink = (href, location) => {
//   const preSlug = '/location/';
//   const preSlugStart = href.indexOf(preSlug);
//   if (preSlugStart < 0) {
//     throw new Error(`invalid link: does not contain '${preSlug}'`);
//   }

//   const start = preSlugStart + preSlug.length;
//   let cur = start;
//   for (let i = 0; i < 3; i++) {
//     cur = href.indexOf('/', cur + 1);
//     if (i !== 2 && cur < 0)
//       throw new Error(`invalid link: link does not contain at least 2 forward slashes after '${preSlug}'`);
//   }
//   const end = cur < 0 ? href.length : cur;

//   return `${href.slice(0, start) + location.countrySlug}/${location.regionSlug}/${location.citySlug}${href.slice(end)}`;
// };

/**
 * Given a place object, construct a pretty name for the place: "City, StateAbv" (ie "Charlotte, NC").
 *
 * @param {object} location
 * @returns {string}
 */
export const getPlaceName = (location) => {
  if (location?.regionSlug && location?.city)
    return `${uppercaseFirst(location?.city)}, ${location?.regionSlug.toLocaleUpperCase()}`;
  return '';
};

/**
 * Whether or not to show the hidden gems link in the header.
 *
 * @param {bool} isLoggedIn
 * @param {object} contentFactory
 * @returns {boolean}
 */
export const showHiddenGemsLink = (isLoggedIn, contentFactory) => {
  return isLoggedIn && contentFactory.content.filter(({ item }) => item).length >= 25 && !contentFactory.noMore;
};

/**
 * Returns the string to display on the mobile age filter given the currently selected age ranges.
 * @param {int[]} ages array of currently selected ages
 * @return {string} age filter string
 */
export const getAgeFilterLabel = (ages) => {
  if (ages.length === 1) return AGE_GROUPS[ages[0]];
  if (ages.toString() === '0,1') return '1-8 yrs';
  if (ages.toString() === '0,1,2') return '1-12 yrs';
  if (ages.toString() === '1,2') return '4-12 yrs';
  if (ages.toString() === '1,3' || ages.toString() === '1,2,3') return '4+ yrs';
  if (ages.toString() === '2,3') return '8+ yrs';
  return '1+ yrs';
};

export const getRadiusFilterLabel = (radius, ranges) => {
  const radiusArr = typeof radius === 'string' ? JSON.parse(radius) : radius;
  const lowerValue = radiusArr[0] === 0 ? 0 : ranges[radiusArr[0] - 1];

  return `${lowerValue}-${ranges[radiusArr[radiusArr.length - 1]]} mi`;
};

export const getPriceFilterLabel = (isFree) => {
  return isFree ? 'Free' : 'All';
};
