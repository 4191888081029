import PropTypes from 'prop-types';
import { ReactComponent as GoogleIcon } from '../../../assets/images/icons/brands/google.svg';
import styles from './GoogleLoginBadge.module.scss';

const propTypes = {
  /** link that the google sign in button should have */
  loginLink: PropTypes.func,
};

/**
 * Login with Google prompt.
 */
const GoogleLoginBadge = ({ loginLink }) => {
  return (
    <div className={styles.googleLoginBadge} alt="Sign In with Google." onClick={loginLink}>
      <GoogleIcon />
      Sign In with Google
    </div>
  );
};

GoogleLoginBadge.propTypes = propTypes;

export default GoogleLoginBadge;
