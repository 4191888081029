import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../../util/props';
import { timeAgo } from '../../../util/time';
import Avatar from '../../Avatar/Avatar';
import VBButton from '../../VBButton/VBButton';
import OnClickLink from '../../OnClickLink/OnClickLink';
import ColoredIcon from '../../ColoredIcon/ColoredIcon';

import Heart from '../../../../assets/images/icons/hearts/heart-outline.svg';
import HeartLiked from '../../../../assets/images/icons/hearts/heart-filled.svg';

import styles from './Comment.module.scss';
import { PropTypesVBComment } from '../../../util/types';
import { VBReactContext } from '../../VBReactProvider/VBReactProvider';

const propTypes = {
  /** the PropTypesVBComment to render */
  comment: PropTypesVBComment.isRequired,

  /** function to generate a report icon. Takes the comment as an argument, should return a node */
  report: PropTypes.func,

  /** function to call to like a comment. Takes the comment as the argument. Should return a promise */
  onRequestLike: PropTypes.func,

  /** the ID of the current user */
  currentUserId: PropTypes.number,

  /** component to render for comment replies. The component should take two props, commentId and replyCount. If this is
   *  ommited, the replies toggle option will not be shown
   */
  replies: PropTypes.elementType,
};

const defaultProps = {
  report: undefined,
  onRequestLike: undefined,
  currentUserId: undefined,
  replies: undefined,
};

/**
 * Individual comment to be rendered by Comments.
 *
 * @param {object} props
 */
const Comment = ({ comment, report, onRequestLike, currentUserId, replies: Replies }) => {
  // Whether or not the replies are showing.
  const [repliesHidden, setRepliesHidden] = useState(true);

  const { cssVariables } = useContext(VBReactContext);

  // Whether the like is loading.
  const [toggleLikeLoading, setToggleLikeLoading] = useState(false);

  // Whether or not the initial render of the replies has taken place.
  const [repliesRendered, setRepliesRendered] = useState(false);

  const toggleRepliesHidden = () => {
    if (repliesHidden) setRepliesRendered(true);
    setRepliesHidden(!repliesHidden);
  };

  // Like button
  const userLiked = comment.liked;
  const isUsers = currentUserId === comment.user.id;

  /**
   * Toggles whether or not the user has liked the comment.
   */
  const toggleLike = () => {
    if (toggleLikeLoading || isUsers) {
      return;
    }
    setToggleLikeLoading(true);
    onRequestLike(comment)
      .catch((err) => console.error(err))
      .finally(() => setToggleLikeLoading(false));
  };

  const likeContainer = (
    <div
      className={mergeClassNames(
        styles.likesContainer,
        userLiked ? styles.userLiked : undefined,
        isUsers ? styles.isUsers : undefined
      )}
    >
      <span className={styles.likes}>{comment.likeCount}</span>
      {(() => {
        return (
          <OnClickLink onClick={toggleLike}>
            {userLiked ? (
              <ColoredIcon
                className={styles.likeButton}
                src={HeartLiked}
                color={cssVariables.color.blue[0]}
                alt="Like comment"
              />
            ) : (
              <ColoredIcon
                className={styles.likeButton}
                src={Heart}
                color={cssVariables.color.black}
                alt="Like comment"
              />
            )}
          </OnClickLink>
        );
      })()}
    </div>
  );

  const replyButton = (
    <VBButton
      size="tny"
      type={repliesHidden ? 'border' : 'full'}
      onClick={toggleRepliesHidden}
      content={comment.replyCount === 1 ? '1 Reply' : `${comment.replyCount} Replies`}
    />
  );

  return (
    <div className={styles.single}>
      <div>
        <a href={comment.user.permalink}>
          <Avatar src={comment.user.avatar} />
        </a>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.name}>
          <a href={comment.user.permalink}>{comment.user.fullName}</a>
        </div>
        <div className={styles.content}>{comment.content}</div>
        <div className={styles.contentBottom}>
          <div className={styles.meta}>
            {report ? <div className={styles.reportContainer}>{report(comment)}</div> : null}
            <span className={styles.timePosted}>{timeAgo(comment.time)}</span>
          </div>
          {Replies ? replyButton : null}
          {repliesRendered ? (
            <div className={mergeClassNames(styles.replies, repliesHidden ? styles.hidden : null)}>
              <Replies commentId={comment.id} replyCount={comment.replyCount} />
            </div>
          ) : null}
        </div>
      </div>
      {onRequestLike ? likeContainer : null}
    </div>
  );
};

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default Comment;
