import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { mergeClassNames } from '../../util/props';
import VBTextArea from '../VBTextArea/VBTextArea';
import VBButton from '../VBButton/VBButton';
import VBFeedbackText from '../VBFeedbackText/VBFeedbackText';
import MaxLengths from '../../../../shared-react/config-json/content-lengths.json';
import useVbContext from '../../hooks/vb-context';
import APIConfig from '../../config/api';
import { errorToast, raiseToast } from '../../util/toast';
import styles from './ReviewEdit.module.scss';
import { updateItem } from '../../store/actions/apiActions';
import { ContentTypes } from '../../config/content-types';
import EmojiSelector from '../EmojiSelector/EmojiSelector';
import { isKids } from '../../util/sites';

const propTypes = {
  /** ID of the review to edit */
  reviewId: PropTypes.number.isRequired,

  /** initial review content */
  content: PropTypes.string,

  /** called when the edit is saved or canceled */
  onClose: PropTypes.func.isRequired,

  /** initial review emoji ID */
  emoji: PropTypes.number.isRequired,

  /** additional class names */
  className: PropTypes.string,

  /** ID of the parent venue or itinerary */
  parentId: PropTypes.number.isRequired,
};

const defaultProps = {
  className: undefined,
};

/**
 * Edit review form. Includes the ability to change the rating and content of a review. Edit can be saved or canceled.
 * @returns
 */
const ReviewEdit = ({
  reviewId,
  content: parentContent,
  emoji: parentEmoji,
  className,
  onClose: handleClose,
  parentId,
}) => {
  const [value, setValue] = useState(parentContent ?? '');
  const [emoji, setEmoji] = useState(parentEmoji - 1);

  const { vbRequest, brandingConfig, vbBlue } = useVbContext();

  const dispatch = useDispatch();

  let invalidMessage = '';
  if (value.length > MaxLengths.REVIEW) {
    invalidMessage = `Review cannot be greater than ${MaxLengths.REVIEW} characters in length.`;
  }

  const submit = () => {
    if (invalidMessage) return;

    // Update on server
    vbRequest(`${APIConfig.NAMESPACE.REVIEW}/reviews/${reviewId}/edit`, {
      method: 'POST',
      body: {
        content: value,
        emoji,
      },
    })
      .then((data) => {
        raiseToast(brandingConfig, 'Success!', 'Edit Review', 'info');
      })
      .catch((err) => {
        errorToast(brandingConfig, err, 'Edit Review');
      });

    // Update in Redux.
    dispatch(
      updateItem(reviewId, ContentTypes.review.type, {
        content: value,
        emojiRating: emoji + 1,
      })
    );

    handleClose();
  };

  return (
    <div className={mergeClassNames(className)}>
      <EmojiSelector
        selected={emoji}
        onSelect={setEmoji}
        className={styles.emojiSelector}
        color={isKids() ? undefined : vbBlue}
      />
      <VBTextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        grow
        className={styles.input}
        placeholder="Review..."
      />
      {invalidMessage ? <VBFeedbackText type="invalid" message={invalidMessage} className={styles.feedback} /> : null}
      <div className={styles.buttons}>
        <VBButton
          type={invalidMessage ? 'empty' : 'full'}
          size="med"
          onClick={submit}
          content="Save"
          className={styles.button}
        />
        <VBButton type="border" size="med" onClick={handleClose} content="Cancel" className={styles.button} />
      </div>
    </div>
  );
};

export default ReviewEdit;
ReviewEdit.propTypes = propTypes;
ReviewEdit.defaultProps = defaultProps;
