import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../Sidebar/Sidebar';
import LegalAgreement from '../LegalAgreement/LegalAgreement';
import styles from './ConfirmEmail.module.scss';
import VBButton from '../../../VBButton/VBButton';
import { VBReactContext } from '../../../VBReactProvider/VBReactProvider';
import { useVBBreakpoint } from '../../../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../../../util/props';

const propTypes = {
  sendActivationEmail: PropTypes.func.isRequired,
  setNotice: PropTypes.func.isRequired,
};
const defaultProps = {};

/**
 * The last page of the Sign Up popup that sends a confirmation email.
 *
 * @param {function} props.sendActivationEmail resends the activation email using the sign up field information
 */
const ConfirmEmail = ({ setNotice, sendActivationEmail }) => {
  const { gteXl } = useVBBreakpoint();
  const { brandingConfig } = useContext(VBReactContext);

  /**
   * Handle click on the submit button. This will resend the confirmation email.
   *
   * @param {SyntheticEvent} e the event
   */
  const handleResend = (e) => {
    sendActivationEmail();
    return false;
  };

  return (
    <>
      <div className={mergeClassNames('d-flex', gteXl ? 'flex-row' : 'flex-column-reverse')}>
        <div style={gteXl ? { flexShrink: '0', width: '42%' } : undefined}>
          <h1>Confirm Email</h1>
          <div className={styles.thankYouMessage}>
            Thank you for joining the {brandingConfig.SITE_NAME} travel community! Please check your email and select
            the confirmation link.
          </div>
          <VBButton className={styles.button} size="med" type="border" onClick={handleResend}>
            Re-send Email
          </VBButton>
          <br />
          <LegalAgreement />
        </div>
        <Sidebar />
      </div>
    </>
  );
};

ConfirmEmail.propTypes = propTypes;
ConfirmEmail.defaultProps = defaultProps;

export default ConfirmEmail;
