import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesVBPost } from '../../../../../../../shared-react/src/util/types';
import { useVBBreakpoint } from '../../../../../../../shared-react/src/hooks/vb-breakpoint';
import PostCard from '../../../PostCard/PostCard';
import styles from './RenderedPost.module.scss';

const propTypes = {
  /** item to render */
  item: PropTypesVBPost.isRequired,

  /** contextual data unique to each content factory */
  contextual: PropTypes.any,

  /** Whether to use an h3 for the itinerary title (for SEO purposes, does not affect style) */
  titleH3Seo: PropTypes.bool,
};

const defaultProps = {
  contextual: undefined,
  titleH3Seo: false,
};

/**
 * Post rendered by PageExplore.
 *
 * @param {object} props
 */
const RenderedPost = ({ item, contextual, titleH3Seo }) => {
  const { lteMd: tabletStyle } = useVBBreakpoint();

  return (
    <div className={tabletStyle ? styles.mobileCardContainer : styles.desktopCardContainer}>
      <PostCard
        post={item}
        distance={contextual.distance}
        direction={contextual.direction}
        titleH3Seo={titleH3Seo}
        hideBorder
      />
    </div>
  );
};

export default RenderedPost;
RenderedPost.propTypes = propTypes;
RenderedPost.defaultProps = defaultProps;
