import { CLOSE_POPUP } from '../../../../shared-react/src/store/types';
import { OPEN_POPUP } from '../../../../shared-react/src/store/types';
/**
 * Reducer for the auth popup.
 *
 * @param {string} state the current auth token
 * @param {PopupAuthPage} action.payload
 *
 * @returns {string} the new state
 */
const authPopupOpenReducer = (state = false, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return action.payload;
    case CLOSE_POPUP:
      return false;
    default:
  }
  return state;
};

export default authPopupOpenReducer;
