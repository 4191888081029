import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../../../../../shared-react/src/util/props';
import OnClickLink from '../../../../../../shared-react/src/components/OnClickLink/OnClickLink';
import { vbRequest } from '../../../../util/api';
import ImageSearchCity from '../../../../assets/images/icons/search-city.svg';
import ImageSearchVenue from '../../../../assets/images/icons/search-venue.svg';
import APIConfig from '../../../../../../shared-react/src/config/api';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import DropdownList from '../../../../../../shared-react/src/components/DropdownList/DropdownList';

import Styles from './Autofill.module.scss';

/**
 * This dropdown is used for displaying autofilled results on the search bar.
 *
 * Props
 * @param {string}      searchText      The current value of the search bar. Needed in order to find
 *                                          autofill results
 * @param {number}      elementWidth    The width that the autofill should be to match the search bar.
 * @param {number}      limit           The amount of results to limit showing at once.
 * @param {bool}        cities          Whether or not to include cities in search
 * @param {bool}        venues          Whether or not to include venues in search
 * @param {bool}        displayBelow   If true, the autofill will display below the search bar instead of behind it.
 * @param {bool}        resultBehavior Specifies what autofill results should do when clicked.
 *                                      Possible values:
 *                                          "set_text": Clicking a result just sets the search bar text and returns the item in a callback
 *                                          (default): Leave undefined to link to page instead by default.
 * @param {func}        onSelect        A function that runs when an item is selected from the dropdown. The selected item is passed.
 * @param {bool}        border          Whether the dropdown should have a border behind it
 * @param {bool}        condensed       Makes the list of results more compact and removes the venue/city icons.
 * @param {func}        getTopResult    Callback that returns the top result
 * @param {bool}        shadow          Whether or not to include a shadow
 * @param {bool}        fullWidth      Whether or not the results should take up the full screen width (this is based on
 *                                     the left position of the search bar on the screen, if left is 20px, 20px will be
 *                                     left on the right as well).
 */
const Autofill = (props) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { brandingConfig } = useVbContext();

  const {
    ICONS: { RIGHT_ARROW_FILLED: ImageArrowRight },
  } = brandingConfig;

  const {
    searchText,
    elementWidth,
    limit,
    cities,
    venues,
    displayBelow,
    resultBehavior,
    onSelect,
    border,
    condensed,
    getTopResult,
    shadow,
    fullWidth,
    inputHeight,
  } = props;

  const dropdownListProps = {
    elementWidth,
    inputHeight,
    fullWidth,
    displayBelow,
    shadow,
    border,
  };

  // Wait until user is done typing
  useEffect(() => {
    let stale = false;

    const handleSearch = () => {
      if (!searchText) return;
      // Build the URL based on the args.
      const queryParams = {
        cities: cities ? 1 : 0,
        venues: venues ? 1 : 0,
        query: searchText.toLowerCase(),
      };

      setIsLoading(true);

      vbRequest(`${APIConfig.NAMESPACE.SEARCH}/autofill`, { params: queryParams })
        .then((data) => {
          // Ensure that the component has not already rerendered.
          if (stale) return;

          const { results } = data;

          // Limit results if needed
          if (limit !== undefined && results.length > limit) {
            results.splice(limit, results.length - limit);
          }

          setSearchResults(results);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    };

    const waitTypingDone = setTimeout(() => {
      handleSearch();
    }, 150); // Assume user is done typing when they haven't pressed a key for 150 milliseconds.

    return () => {
      stale = true;
      clearTimeout(waitTypingDone);
    };
  }, [cities, limit, searchText, venues]);

  const handleClickResult = useCallback(
    (item) => {
      if (onSelect) {
        onSelect(item);
      }
    },
    [onSelect]
  );

  const ItemRenderer = useCallback(
    (item) => {
      const { name, link, id } = item;

      const itemImage = item.isVenue ? (
        <img src={ImageSearchVenue} style={{ minWidth: '24px', maxWidth: '24px' }} alt="Venue" />
      ) : (
        <img src={ImageSearchCity} style={{ minWidth: '24px', maxWidth: '24px' }} alt="City" />
      );

      const displayName = item.isVenue ? name + (condensed ? `, ${link.split('/')[3].toUpperCase()}` : '') : name;

      if (resultBehavior === 'set_text') {
        // Set text of the search bar
        return (
          <OnClickLink
            className={mergeClassNames(Styles.item, condensed && Styles.condensed)}
            onClick={() => handleClickResult(item)}
          >
            {condensed ? null : <div className={Styles.icon}>{itemImage}</div>}
            <div className={Styles.name}>{displayName}</div>
          </OnClickLink>
        );
      }
      // Default to a link to the page
      return (
        <OnClickLink
          className={mergeClassNames(Styles.item, condensed && Styles.condensed)}
          onClick={() => handleClickResult(item)}
        >
          {condensed ? null : <div className={Styles.icon}>{itemImage}</div>}
          <div className={Styles.name}>{displayName}</div>
          {condensed ? null : <img className={Styles.arrow} src={ImageArrowRight} alt="" />}
        </OnClickLink>
      );
    },
    [ImageArrowRight, condensed, handleClickResult, resultBehavior]
  );

  useEffect(() => {
    // Pass top search result to search bar (so user can press enter to go to it)
    getTopResult(searchResults[0]);
  }, [getTopResult, searchResults]);

  return (
    <DropdownList options={searchResults} ItemRenderer={ItemRenderer} isLoading={isLoading} {...dropdownListProps} />
  );
};

Autofill.propTypes = {
  searchText: PropTypes.string,
  elementWidth: PropTypes.number.isRequired,
  limit: PropTypes.number,
  cities: PropTypes.bool,
  venues: PropTypes.bool,
  displayBelow: PropTypes.bool,
  resultBehavior: PropTypes.string,
  onSelect: PropTypes.func,
  border: PropTypes.bool,
  condensed: PropTypes.bool,
  getTopResult: PropTypes.func,
  shadow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputHeight: PropTypes.string.isRequired,
};

Autofill.defaultProps = {
  searchText: null,
  limit: 5,
  cities: false,
  venues: false,
  displayBelow: false,
  resultBehavior: undefined,
  onSelect: null,
  border: false,
  condensed: false,
  getTopResult: null,
  shadow: false,
};

export default Autofill;
