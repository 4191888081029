import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesVBVenue } from '../../../../../../../shared-react/src/util/types';
import VenueCard from '../../../../../../../shared-react/src/components/VenueCard/VenueCard';
import useVBBreakpoint from '../../../../../../../shared-react/src/hooks/vb-breakpoint';

const propTypes = {
  /** item to render */
  item: PropTypesVBVenue.isRequired,

  /** index of the item within the content */
  index: PropTypes.number.isRequired,

  /** the filter that was used to query the item */
  filter: PropTypes.object,

  /** contextual data unique to each content factory */
  contextual: PropTypes.any,

  /** The specific explore page link that the user navigated from, if any */
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,

  /** Whether to use an h3 for the itinerary title (for SEO purposes, does not affect style) */
  titleH3Seo: PropTypes.bool,

  isSelected: PropTypes.bool,

  noBottomPadding: PropTypes.bool,

  isLoading: PropTypes.bool,
};

const defaultProps = {
  contextual: undefined,
  filter: undefined,
  isFeatured: false,
  exploreLink: undefined,
  exploreLinkText: undefined,
  titleH3Seo: false,
  noBottomPadding: false,
  isLoading: false,
};

/**
 * Venue rendered by PageExplore.
 *
 * @param {object} props
 */
const RenderedVenue = ({
  item,
  index,
  filter,
  contextual,
  exploreLink,
  exploreLinkText,
  exploreSubsection,
  titleH3Seo,
  isSelected,
  noVideo,
  exploreFilter,
  exploreContext,
  noBottomPadding,
  isLoading,
}) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();

  return (
    <VenueCard
      venue={item}
      rank={index >= 25 || filter?.hiddenGems ? undefined : index + 1}
      isHiddenGem={index >= 25 || filter?.hiddenGems}
      distance={contextual?.distance}
      direction={contextual?.direction}
      nearMe={filter?.nearMe}
      titleSize="sm"
      exploreLink={exploreLink}
      exploreLinkText={exploreLinkText}
      exploreSubsection={exploreSubsection}
      exploreFilter={exploreFilter}
      exploreContext={exploreContext}
      titleH3Seo={titleH3Seo}
      showVideoIfAvailable={!noVideo}
      isSelected={isSelected}
      noBottomPadding={noBottomPadding}
      disablePadding={mobileStyle}
      isLoading={isLoading}
      extraBottomPadding
      showDescription
      withArticles
    />
  );
};

export default RenderedVenue;

RenderedVenue.propTypes = propTypes;
RenderedVenue.defaultProps = defaultProps;
