import { HOTJAR } from '../../../tripfool/src/config/analytics';
import { isLive } from '../util/env';
import { getSite } from '../util/sites';
import { hotjar } from 'react-hotjar';
import { useCallback } from 'react';

/**
 * Send a Google Analytics event.
 *
 * @param {number} userId
 */
export const useAnalyticsSetUser = () => {
  return useCallback((userId) => {
    if (!isLive()) return;

    // TODO: GA

    const hotjarConfig = HOTJAR[getSite()];
    if (hotjarConfig) hotjar.identify(userId ? userId.toString() : null);
  }, []);
};

export default useAnalyticsSetUser;
