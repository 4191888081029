import IconButton from '../../../../../../shared-react/src/components/IconButton/IconButton';
import ColoredIcon from '../../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import { usePopup } from '../../../../../../shared-react/src/hooks/popup';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import { updateItem } from '../../../../../../shared-react/src/store/actions/apiActions';
import { invalidateList } from '../../../../../../shared-react/src/store/actions/apiActions';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ImageReplace from '../../../../assets/images/admin/images.svg';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';
import PopupConfirm from '../../../../../../shared-react/src/components/PopupConfirm/PopupConfirm';
import { vbRequest } from '../../../../util/api';

const propTypes = {
  /** ID of the venue to set featured image for */
  venueId: PropTypes.number.isRequired,

  /** name of the venue referred to by venueId */
  venueName: PropTypes.string.isRequired,

  /** ID of the post to use when setting the featured image */
  postId: PropTypes.number.isRequired,

  /** src of the image */
  postSrc: PropTypes.string.isRequired,
};

/**
 * Icon button for admin uploading a new featured image for a venue.
 */
const IconSetFeaturedImage = ({ venueId, venueName, postId, postSrc }) => {
  const { vbBlack, raiseToast, errorToast } = useVbContext();
  const dispatch = useDispatch();

  /**
   * When the replacement is confirmed, do the HTTP POST to replace the image and live update.
   */
  const handleConfirm = useCallback(
    (isConfirm) => {
      if (!isConfirm) return;

      vbRequest(`${APIConfig.NAMESPACE.VENUE}/venues/${venueId}/featured-image`, {
        params: { post_id: postId },
        method: 'POST',
      })
        .then(() => {
          dispatch(updateItem(venueId, ContentTypes.venue.type, { featuredImage: postSrc }));
          raiseToast('Success!', 'Update Featured Image');
        })
        .catch((err) => errorToast(err, 'Update Featured Image'));
    },
    [dispatch, postId, postSrc, venueId, raiseToast, errorToast]
  );

  const { modal, open: openConfirm } = usePopup({
    child: (
      <PopupConfirm
        onResolve={handleConfirm}
        prompt={`Are you sure you want to replace the featured image for ${venueName} with this post?`}
      />
    ),
  });

  return (
    <>
      <IconButton onClick={openConfirm} text="[Set Feat.]" fontSize="1rem">
        <ColoredIcon src={ImageReplace} color={vbBlack} />
      </IconButton>
      {modal}
    </>
  );
};

IconSetFeaturedImage.propTypes = propTypes;

export default IconSetFeaturedImage;
