/**
 * Util functions dealing with content types.
 */

import { ContentTypes } from '../config/content-types';
import { deepCloneSerializable, getKey as objectGetKey } from './objects';

/**
 * Content objects in redux store will have content IDs stored instead of references to other objects. This will return
 * an object that contains the object references instead of IDs.
 *
 * @param {object} item the input item
 * @param {string} type the type of the input item
 * @param {object} content the redux api.content object
 *
 * @returns {object} the object with the references filled in
 */
export const populateReferences = (item, type, content, itemCache = {}) => {
  const { references } = ContentTypes[type];
  const returnContent = deepCloneSerializable(item);

  let cacheKey;
  const id = item?.id;
  if (typeof id !== 'undefined') {
    cacheKey = `${type}//${id}`;
    if (itemCache[cacheKey]) return itemCache[cacheKey];
    itemCache[cacheKey] = returnContent;
  }

  if (references) {
    references.forEach(({ path, arrayOf, type: refContentType }) => {
      let ind = 0;
      let cur = returnContent;
      let prev = null;
      while (cur !== null && cur !== undefined && ind < path.length) {
        prev = cur;
        cur = cur[path[ind++]];
      }
      if (cur) {
        if (arrayOf) {
          prev[path[ind - 1]] = cur.map((refId) =>
            populateReferences(content?.[refContentType][refId]?.content, refContentType, content, itemCache)
          );
        } else {
          prev[path[ind - 1]] = populateReferences(
            content?.[refContentType][cur]?.content,
            refContentType,
            content,
            itemCache
          );
        }
      }
    });
  }
  return returnContent;
};

/**
 * Gets a key from an object.
 * @param {object} item the item
 * @param {string} type the type of the item
 * @returns {any} the key
 */
export const getKey = (item, type) => {
  if (ContentTypes[type].getKey) return ContentTypes[type].getKey(item);
  return objectGetKey(item);
};

/**
 * Merge two items.
 * @param {object} oldItem the older of the two items
 * @param {object} newItem the newer of the two items
 * @param {string} type the type of the item
 * @returns {object} the key
 */
export const merge = (oldItem, newItem, type) => {
  if (ContentTypes[type].merge) return ContentTypes[type].merge(oldItem, newItem);
  return { ...oldItem, ...newItem };
};
