import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import VBButton from '../../../../../../../shared-react/src/components/VBButton/VBButton';
import VBInputLabel from '../../../../../../../shared-react/src/components/VBInputLabel/VBInputLabel';
import VBTextInput from '../../../../../../../shared-react/src/components/VBTextInput/VBTextInput';
import { useCloseDropdown } from '../../../../../../../shared-react/src/hooks/dropdown';
import { prettyErrorString } from '../../../../../../../shared-react/src/util/string';
import useEventToggleRsvp from '../../../../../hooks/event-toggle-rsvp';
import styles from './DropdownRSVP.module.scss';

// TODO: share with server
export const RSVP_MAX_GUESTS = 5;

const propTypes = {
  /** event object to RSVP for */
  event: PropTypes.object,

  redirectOnRsvp: PropTypes.string,
};

/**
 * Dropdown where the user can specify the number of guests.
 */
const DropdownRSVP = ({ event, redirectOnRsvp }) => {
  const { isLoading, toggleRsvp } = useEventToggleRsvp();
  const closeDropdown = useCloseDropdown();

  const [inputValue, setInputValue] = useState(1);
  const [error, setError] = useState('');

  const history = useHistory();

  const handleRsvp = () => {
    if (isLoading) return;
    const parsedInputValue = parseInt(inputValue, 10);
    if (isNaN(parsedInputValue) || !parsedInputValue || parsedInputValue < 1 || parsedInputValue > RSVP_MAX_GUESTS) {
      setError('invalid guests');
      return;
    }
    if (event.signUpCount + parsedInputValue > event.capacity) {
      setError('guest number exceeds capacity');
      return;
    }

    setError('');
    toggleRsvp(event, parsedInputValue);
    closeDropdown();
    if (redirectOnRsvp) history.push(redirectOnRsvp);
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        RSVP for <span className={styles.eventName}>{event.name}</span>:
      </div>
      <div className={styles.inputContainer}>
        <VBInputLabel>Guests (including self, max {RSVP_MAX_GUESTS})</VBInputLabel>
        <VBTextInput
          className={styles.input}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Guests"
        />
      </div>
      <div className={styles.error}>{error ? prettyErrorString(error) : null}</div>
      <div className={styles.bottom}>
        <VBButton content="RSVP" size="med" type="full" onClick={handleRsvp} />
      </div>
    </div>
  );
};

export default DropdownRSVP;
DropdownRSVP.propTypes = propTypes;
