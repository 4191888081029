/**
 * Action dispatchers dealing with global location information.
 */

import APIConfig from '../../config/api';
import { SET_LOCATION, START_LOADING } from '../types';

/**
 * Action to refresh the location with a random city.
 */
export const refreshRandom = (vbRequest) => {
  return (dispatch) => {
    dispatch({
      type: START_LOADING,
    });
    vbRequest(`${APIConfig.NAMESPACE.SEARCH}/random-city`).then(({ result }) => {
      dispatch({
        type: SET_LOCATION,
        payload: { location: result },
      });
    });
  };
};

/**
 * Action to set the location.
 */
export const updateLocation = (location) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCATION,
      payload: { location: { ...location } },
    });
  };
};
