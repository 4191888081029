import { useRef } from 'react';

const useEmbeddedBottomWatcher = (loadMore, isLoading, noMore, bottomRefArg, listRefArg) => {
  const bottomRefLocal = useRef();
  const listRefLocal = useRef();
  const bottomRef = bottomRefArg ?? bottomRefLocal;
  const listRef = listRefArg ?? listRefLocal;

  /**
   * Handles scroll in the friends list.
   */
  const onScroll = () => {
    const bottomBox = bottomRef.current.getBoundingClientRect();
    const listBox = listRef.current.getBoundingClientRect();

    // Load more if the user has reached the bottom.
    if (!isLoading && !noMore && bottomBox.y < listBox.bottom + 64) {
      loadMore();
    }
  };

  return {
    bottomRef,
    listRef,
    onScroll,
  };
};

export default useEmbeddedBottomWatcher;
