import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import RenderedArticle from '../../../../tripfool/src/components/Master/PageExplore/PageSummary/RenderedArticle/RenderedArticle';
import RenderedFestival from '../../../../tripfool/src/components/Master/PageExplore/RenderedFestival/RenderedFestival';
import RenderedTriviaQuestion from '../../../../tripfool/src/components/Master/PageExplore/PageSummary/RenderedTriviaQuestion/RenderedTriviaQuestion';
import RenderedFAQ from '../../../../tripfool/src/components/Master/PageExplore/RenderedFAQ/RenderedFAQ';
import RenderedItineraryDay from '../../../../tripfool/src/components/Master/PageExplore/PageSummary/RenderedItinerary/RenderedItineraryDay';
import RenderedVenue from '../../../../tripfool/src/components/Master/PageExplore/PageDetails/RenderedVenue/RenderedVenue';
import RenderedNewsletter from '../../../../tripfool/src/components/Master/PageExplore/RenderedNewsletter/RenderedNewsletter';
import { ExploreSubsection } from '../../config/explore';
import Section from './Section/Section';
import AskQuestion from './AskQuestion/AskQuestion';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import trimSlashes, { uppercaseFirst } from '../../util/string';
import useVBBreakpoint from '../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../util/props';
import useMapSyncedYScroll from '../../hooks/map-synced-y-scroll';
import ExploreButtons from '../ExploreButtons/ExploreButtons';
import { PageExploreSummarySection } from './config';

import styles from './ExploreSummary.module.scss';

const ExploreSummary = ({
  locationDetails,
  ellipsis,
  newslettersContentFactory,
  articlesContentFactory,
  eventsContentFactory,
  festivalsContentFactory,
  postsContentFactory,
  videosContentFactory,
  triviaContentFactory,
  faqContentFactory,
  itineraryDays,
  activitiesFactory,
  dayTripsContentFactory,
  playgroundsContentFactory,
  placeName,
  randomCities,
  exploreLink,
  exploreLinkText,
}) => {
  // const featuredContentEndRef = useRef();
  const { lt1280: tabletStyle, lteXs: mobileStyle } = useVBBreakpoint();

  const location = useLocation();

  const basePath = useMemo(() => {
    const [route, country, state, city] = trimSlashes(location.pathname).split('/');

    return `${route}/${country}/${state}/${city}`;
  }, [location.pathname]);

  const { isVisible: isHeaderVisible } = useSelector((state) => state.header);

  // const stickiesVisible = useVisibleOnUpscroll({
  //   scrollContainer: window,
  //   offset: window.scrollY + featuredContentEndRef?.current?.getBoundingClientRect().y ?? Infinity,
  // });

  // const { cardRefs: videoCardRefs, selection: videoSelection } = useMapSyncedYScroll({
  //   content: videosContentFactory.data ?? [],
  //   centerScrollTest: false,
  //   allowNullSelection: true,
  // });

  const { cardRefs: itineraryCardRefs, selection: itineraryDaySelection } = useMapSyncedYScroll({
    content: itineraryDays.data?.slice(0, 2) ?? [],
    centerScrollTest: false,
    allowNullSelection: true,
  });

  const { cardRefs: activityCardRefs, selection: activitySelection } = useMapSyncedYScroll({
    content: activitiesFactory.data ?? [],
    centerScrollTest: false,
    allowNullSelection: true,
  });

  const { cardRefs: daytripsCardRefs, selection: daytripsSelection } = useMapSyncedYScroll({
    content: dayTripsContentFactory.data ?? [],
    centerScrollTest: false,
    allowNullSelection: true,
  });

  // const summaryTabs = useMemo(
  //   () =>
  //     getExploreSummaryTabs({
  //       articlesContentFactory,
  //       festivalsContentFactory,
  //       eventsContentFactory,
  //       postsContentFactory,
  //       videosContentFactory,
  //       triviaContentFactory,
  //       itineraryDays,
  //       activitiesFactory,
  //       dayTripsContentFactory,
  //       playgroundsContentFactory,
  //       qaContentFactory,
  //       tabletStyle,
  //       mobileStyle,
  //     }),
  //   [
  //     articlesContentFactory,
  //     festivalsContentFactory,
  //     eventsContentFactory,
  //     postsContentFactory,
  //     videosContentFactory,
  //     triviaContentFactory,
  //     itineraryDays,
  //     activitiesFactory,
  //     dayTripsContentFactory,
  //     playgroundsContentFactory,
  //     qaContentFactory,
  //     tabletStyle,
  //     mobileStyle,
  //   ]
  // );

  const venuesExtraProps = {
    noBottomPadding: true,
    noVideo: true,
    exploreSubsection: ExploreSubsection.SUMMARY,
    exploreLink: exploreLink,
    exploreLinkText: exploreLinkText,
  };

  return (
    <MaxWidthContainer className={mergeClassNames(styles.sections, tabletStyle && styles.tablet)}>
      <Section
        id={PageExploreSummarySection.NEWSLETTERS}
        title={`What's Going On in ${placeName}`}
        contentFactory={newslettersContentFactory}
        RenderItem={RenderedNewsletter}
        extraProps={{
          noMargin: true,
          fullPageLink: `/${basePath}/newsletters`,
        }}
        link={`/${basePath}/newsletters`}
        size={1}
        showMoreText="More…"
      />
      <Section
        id={PageExploreSummarySection.ACTIVITIES}
        title={`Top Kids Activities in ${placeName}`}
        contentFactory={activitiesFactory}
        cardRefs={activityCardRefs}
        RenderItem={RenderedVenue}
        extraProps={(item) => ({
          ...venuesExtraProps,
          exploreFilter: activitiesFactory.filter,
          exploreContext: activitiesFactory.context,
          isSelected: item.index === activitySelection,
        })}
        link={`/${basePath}/activities`}
        size={3}
        gap="2rem"
        showMoreText="More Activities"
      />
      <Section
        id={PageExploreSummarySection.PLAYGROUNDS}
        title={`Top Playgrounds in ${placeName}`}
        contentFactory={playgroundsContentFactory}
        RenderItem={RenderedVenue}
        extraProps={{
          ...venuesExtraProps,
          exploreFilter: playgroundsContentFactory.filter,
          exploreContext: playgroundsContentFactory.context,
          noVideo: true,
        }}
        link={`/${basePath}/playgrounds`}
        size={3}
        gap="2rem"
        showMoreText="More Playgrounds"
      />
      {mobileStyle && (
        <Section
          id={PageExploreSummarySection.FAQ}
          title={`FAQ - ${placeName}`}
          contentFactory={faqContentFactory}
          RenderItem={RenderedFAQ}
          extraProps={{
            locationDetails,
            noMargin: true,
          }}
          link={`/${basePath}/faq`}
          size={3}
          beforeListContent={<AskQuestion locationDetails={locationDetails} />}
          showMoreText="More FAQs"
        />
      )}
      <Section
        id={PageExploreSummarySection.FESTIVALS}
        title={`${placeName} Major Festivals`}
        contentFactory={festivalsContentFactory}
        RenderItem={RenderedFestival}
        extraProps={({ item }) => {
          return {
            locationDetails,
            noMargin: true,
            permalink: `/${basePath}/festivals#${item.slug}`,
          };
        }}
        link={`/${basePath}/festivals`}
        size={4}
        showMoreText="More Festivals"
      />
      <Section
        id={PageExploreSummarySection.TRIVIA}
        title={`Travel Trivia - ${placeName}`}
        contentFactory={triviaContentFactory}
        RenderItem={RenderedTriviaQuestion}
        extraProps={{ basePath }}
        link={`/${basePath}/trivia`}
        size={1}
        showMoreText="More Trivia"
      />
      <Section
        id={PageExploreSummarySection.ITINERARIES}
        title={`7 Day Family Itinerary - ${placeName}`}
        contentFactory={itineraryDays}
        cardRefs={itineraryCardRefs}
        RenderItem={RenderedItineraryDay}
        extraProps={(item) => ({ isSelected: item.index === itineraryDaySelection })}
        link={`/${basePath}/itineraries`}
        size={2}
        forceShowMore
        showMoreText="More Itineraries"
      />
      <Section
        id={PageExploreSummarySection.DAY_TRIPS}
        title={`Top Kids Daytrips in ${placeName}`}
        contentFactory={dayTripsContentFactory}
        cardRefs={daytripsCardRefs}
        RenderItem={RenderedVenue}
        extraProps={(item) => ({
          ...venuesExtraProps,
          exploreFilter: dayTripsContentFactory.filter,
          exploreContext: dayTripsContentFactory.context,
          isSelected: item.index === daytripsSelection,
        })}
        link={`/${basePath}/daytrips`}
        size={3}
        gap="2rem"
        showMoreText="More Day Trips"
      />
      <Section
        id={PageExploreSummarySection.ARTICLES}
        title={`${placeName} Family Travel Articles`}
        contentFactory={articlesContentFactory}
        RenderItem={RenderedArticle}
        extraProps={{ exploreLinkText }}
        link={`/${basePath}/articles`}
        size={5}
        showMoreText="More Articles"
      />
      {/* <Section
        id={PageExploreSummarySection.EVENTS}
        title={`Top Kids Events in ${placeName}`}
        contentFactory={eventsContentFactory}
        RenderItem={RenderedEvent}
        extraProps={{ hideImage: tabletStyle, noMargin: true }}
        link={`/${basePath}/events`}
        size={4}
        showMoreText="More Events"
      /> */}
      {/* <Section
        id={PageExploreSummarySection.CHAT}
        title={`${placeName} Chat`}
        contentFactory={postsContentFactory}
        RenderItem={RenderedPost}
        extraProps={{
          descriptionLink: `/${basePath}/chat`,
        }}
        link={`/${basePath}/chat`}
        size={3}
      /> */}
      {/* <Section
        id={PageExploreSummarySection.VIDEOS}
        title={`Videos of Things to do in ${placeName}`}
        contentFactory={videosContentFactory}
        cardRefs={videoCardRefs}
        RenderItem={RenderedVideo}
        extraProps={(item) => ({
          isSelected: item.index === videoSelection,
          noBottomPadding: true,
          disablePadding: mobileStyle,
        })}
        link={`/${basePath}/videos`}
        size={2}
        disablePadding={mobileStyle}
        showMoreText="More Videos"
      /> */}
      {locationDetails && randomCities && (
        <ExploreButtons locationDetails={locationDetails} cities={randomCities} className={styles.bottomButtons} />
      )}
    </MaxWidthContainer>
  );
};

export default ExploreSummary;
