import VBPopup from '../../../../../shared-react/src/components/VBPopup/VBPopup';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'query-string';
import { closeAuthPopup } from '../../../store/actions/authPopupOpenActions';
import PopupAuthWrap from '../PopupAuthWrap/PopupAuthWrap';

/**
 * Container for the auth popup used in the Master component.
 */
const AuthPopupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const params = QueryString.parse(search.slice(1));

  const authPopupOpen = useSelector((state) => state.authPopupOpen);

  return (
    <VBPopup
      component={<PopupAuthWrap page={authPopupOpen} />}
      isOpen={!!authPopupOpen}
      onRequestClose={() => {
        if (params.slogin && params.token) {
          const newParams = { ...params };
          delete newParams.slogin;
          delete newParams.token;
          history.replace(pathname + (Object.keys(newParams).length ? `?${QueryString.stringify(newParams)}` : ''));
        }
        dispatch(closeAuthPopup());
      }}
      size="xl"
    />
  );
};
export default AuthPopupContainer;
