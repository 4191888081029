/**
 * Configuration for the map.
 */

/** map center for no locations. This is the US */
export const mapDefaultCenter = { latitude: 37.0902, longitude: -95.7129 };

/** zoom factor for no locations. This seems to be a good value to fit the whole US */
export const mapDefaultZoom = 3.4;

/** zoom factor for a single location */
export const mapSingleZoom = 14;

/** how much padding there should be around the items on the map when auto fitting the zoom */
export const mapZoomFitPadding = 75;
