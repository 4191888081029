import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  ITEMS_CLEAR,
  REMOVE_ITEM,
  ITEMS_INVALIDATE,
  REMOVE_FROM,
  ADD_TO,
  ITEMS_REFRESHING,
  ITEMS_REFRESHED,
  ITEMS_FAILED,
} from '../../../types';
import filtersReducer from './context/filtersReducer';

/**
 * Reducer for contentFactoryData.items[context].
 *
 * @param {object} state the current context object
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx
 * @param {string} action.payload.context the context that the items are being added to. For example, a top 25 page or
 *                                        recommended venue section
 * @param {string} action.payload.type the type of the content. See content-types.jsx
 *
 * @returns {object} the new context object
 */
const contextReducer = (state = { type: undefined, filters: {} }, action) => {
  switch (action.type) {
    case ITEMS_CLEAR:
      return { ...state, filters: {} };
    case REMOVE_ITEM:
    case REMOVE_FROM:
    case ADD_TO:
    case ITEMS_INVALIDATE: {
      const { type: itemType } = action.payload;
      if (itemType !== state.type) {
        return state;
      }
      return { ...state, filters: filtersReducer(state.filters, action) };
    }
    case ITEMS_REQUESTED:
    case ITEMS_REFRESHING:
    case ITEMS_FAILED:
      return { ...state, filters: filtersReducer(state.filters, action) };
    case ITEMS_RECEIVED:
    case ITEMS_REFRESHED: {
      const { type, context } = action.payload;

      // Create initial state based on the type of item.
      const stateCopy = { ...state };
      if (!stateCopy.type) {
        stateCopy.type = type;
      } else if (type !== stateCopy.type) {
        console.error(
          `provided content type '${type}' does not match existing content type '${stateCopy.type}' for context ${context}`
        );
      }
      stateCopy.filters = filtersReducer(stateCopy.filters, action);
      return stateCopy;
    }
    default:
  }
  return state;
};

export default contextReducer;
