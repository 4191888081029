import React from 'react';
import PropTypes from 'prop-types';
import VBInputLabel from '../../VBInputLabel/VBInputLabel';
import styles from './DropdownFilterEvents.module.scss';
import RadiusSelector from '../RadiusSelector/RadiusSelector';
import VBRadioButton from '../../VBRadioGroup/VBRadioButton/VBRadioButton';
import VBRadioGroup from '../../VBRadioGroup/VBRadioGroup';
import VBButton from '../../VBButton/VBButton';
import { EVENT_TIME_FRAMES } from '../../../config/explore';

const propTypes = {
  /** stringified array of radiuses, ie '[0, 1]', '[2]'. See DISTANCE_RANGES in config/explore.jsx */
  radius: PropTypes.string.isRequired,

  /** setter for radius */
  setRadius: PropTypes.func.isRequired,

  /** whether or not to filter by internal event */
  isOnlyZzEvents: PropTypes.bool.isRequired,

  /** setter for isOnlyZzEvents */
  setIsOnlyZzEvents: PropTypes.func.isRequired,

  /** time period option for filtering events */
  timePeriod: PropTypes.string.isRequired,

  /** setter for timePeriod */
  setTimePeriod: PropTypes.func.isRequired,
};

const defaultProps = {
  noPadding: false,
};

/**
 * Dropdown to use to filter events.
 *
 * @param {object} props
 */
const DropdownFilterEvents = ({ radius, setRadius, isOnlyZzEvents, setIsOnlyZzEvents, timePeriod, setTimePeriod }) => (
  <div className={styles.dropdown}>
    <div className={styles.inputContainer}>
      <VBInputLabel content="Radius" noMargin />
      <RadiusSelector radius={radius} setRadius={setRadius} />
    </div>
    <div className={styles.inputContainer}>
      <VBInputLabel content="Type" noMargin />
      <VBRadioGroup selected={isOnlyZzEvents} onChange={setIsOnlyZzEvents}>
        <VBRadioButton value={false} label="All" />
        <VBRadioButton value={true} label="Zuzu Events" />
      </VBRadioGroup>
    </div>
    <div>
      <div className={styles.paddedLabel}>
        <VBInputLabel content="Time" noMargin />
      </div>
      {EVENT_TIME_FRAMES.map(({ label, value }) => (
        <VBButton
          key={value}
          type={timePeriod === value ? 'full' : 'border'}
          content={label}
          onClick={() => setTimePeriod(value)}
          className={styles.button}
        />
      ))}
    </div>
  </div>
);
export default DropdownFilterEvents;

DropdownFilterEvents.propTypes = propTypes;
DropdownFilterEvents.defaultProps = defaultProps;
