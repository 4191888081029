import React from 'react';
import PropTypes from 'prop-types';
import VBInputLabel from '../../VBInputLabel/VBInputLabel';
import VBRadioGroup from '../../VBRadioGroup/VBRadioGroup';
import VBRadioButton from '../../VBRadioGroup/VBRadioButton/VBRadioButton';
import styles from './DropdownFilterTrending.module.scss';
import RadiusSelector from '../RadiusSelector/RadiusSelector';
import useVbContext from '../../../hooks/vb-context';
import { mergeClassNames } from '../../../util/props';

const propTypes = {
  /** stringified array of radiuses, ie '[0, 1]', '[2]'. See DISTANCE_RANGES in config/explore.jsx */
  radius: PropTypes.string.isRequired,

  /** setter for radius */
  setRadius: PropTypes.func.isRequired,

  /** whether to filter by friends and following */
  friendsOnly: PropTypes.bool.isRequired,

  /** setter for friendsOnly */
  setFriendsOnly: PropTypes.func.isRequired,

  /** if the user is not logged in, this will be called if the user tries to filter by friends and following */
  openAuthPopup: PropTypes.func.isRequired,

  /** whether or not there should be horizontal padding on the outer div */
  noPadding: PropTypes.bool,
};

const defaultProps = {
  noPadding: false,
};

/**
 * Dropdown to use to filter posts. Includes radius and friends only filters.
 *
 * @param {object} props
 */
const DropdownFilterTrending = ({ radius, setRadius, friendsOnly, setFriendsOnly, openAuthPopup, noPadding }) => {
  const { currentUser } = useVbContext();

  return (
    <div className={mergeClassNames(styles.dropdown, !noPadding ? styles.padded : null)}>
      <div className={styles.inputContainer}>
        <VBInputLabel content="Radius" noMargin />
        <RadiusSelector radius={radius} setRadius={setRadius} />
      </div>
      <div className={styles.inputContainer}>
        <VBInputLabel content="View" noMargin />
        <VBRadioGroup selected={friendsOnly} onChange={currentUser ? setFriendsOnly : openAuthPopup}>
          <VBRadioButton value={false} label="Everyone" />
          {/* eslint-disable-next-line react/jsx-boolean-value */}
          <VBRadioButton value={true} label="Friends and Following" />
        </VBRadioGroup>
      </div>
    </div>
  );
};
export default DropdownFilterTrending;

DropdownFilterTrending.propTypes = propTypes;
DropdownFilterTrending.defaultProps = defaultProps;
