import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer/polyfilled';
import ImageHiddenGem from '../../../assets/images/top-25/hidden-gem.svg';
import { getBadge } from '../../config/badges';
import GemDropdown from '../GemDropdown/GemDropdown';
import VenueMeta from '../VenueMeta/VenueMeta';
import { getDisplayedCategory, isImageGenerated } from '../../util/venue';
import { addRankAndExploreLinkToPermalink } from '../../util/urls';
import IconShare from '../IconShare/IconShare';
import VBLink from '../VBLink/VBLink';
import VBButton from '../VBButton/VBButton';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import useVbContext from '../../hooks/vb-context';
import { boolProp } from '../../util/props';
import { usePopup } from '../../hooks/popup';
import Card from '../Card/Card';
import { PropTypesVBBool, PropTypesVBVenue } from '../../util/types';
import PopupAdminEditVenue from '../PopupAdminEditVenue/PopupAdminEditVenue';
import CheckMark from '../../../assets/images/icons/forms/checkbox-mark.svg';
import IconUploadFeaturedImage from './IconUploadFeaturedImage/IconUploadFeaturedImage';
import { HORIZONTAL_ACTION_BUTTONS_CUTOFF } from '../Meta/Meta';
import { isStaging } from '../../util/env';
import useSaveIconButtons from '../../hooks/save-icon-buttons';
import PopupUserAddEditVenue from '../PopupUserAddEditVenue/PopupUserAddEditVenue';
import useContentSingle from '../../hooks/content-single';
import { useTopImageMaxRatio } from '../../hooks/top-image-max-ratio';
import { ContentTypes } from '../../config/content-types';
import APIConfig from '../../config/api';
import PriceBadge from '../PriceBadge/PriceBadge';
import YoutubeEmbedVideo from '../VideoCard/YoutubeEmbedVideo/YoutubeEmbedVideo';
import RatingSummary from './RatingSummary/RatingSummary';
import { buildDistanceString } from '../../util/distance';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './VenueCard.module.scss';

const propTypes = {
  /** the venue, see PropTypesVBVenue */
  venue: PropTypesVBVenue.isRequired,
  /** the height of the image for the card. Defaults to 400px. */
  imgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** whether padding should be disabled on the card.
   *  Optionally can be a string representing a breakpoint e.g. 'lteSm' */
  disablePadding: PropTypesVBBool,
  /** Hides the action buttons (ie. Save venue, share, upload featured image) */
  disableActionButtons: PropTypesVBBool,
  /** shows only the save and share action buttons */
  lessActionButtons: PropTypes.bool,
  /** additional class names */
  className: PropTypes.string,
  /** whether extra padding should be added to the card */
  extraPadding: PropTypesVBBool,
  /** whether extra bottom padding should be added to the card */
  extraBottomPadding: PropTypes.bool,
  /** whether the venue's description should be shown */
  showDescription: PropTypesVBBool,
  /** hides the regular venue meta */
  hideMeta: PropTypesVBBool,
  /** extra meta to display below the normal venue meta */
  extraMeta: PropTypes.node,
  /** the distance to show */
  distance: PropTypes.number,
  /** the direction from the user or city to the place */
  direction: PropTypes.string,
  /** To display the distance with "From me" appended. */
  nearMe: PropTypes.bool,
  /** 'sm', 'med' or 'lg' */
  titleSize: PropTypes.oneOf(['sm', 'med', 'lg']),
  /** The rank to display on the card */
  rank: PropTypes.number,
  /** Whether or not to display a badge that shows that the venue was reviewed (used on profile page) */
  isReviewed: PropTypes.bool,
  /** Number of posts that a user has made for this venue (used on profile page) */
  numPosts: PropTypes.number,
  /** If it should show "Hidden gem" overlay. Defaults to venue.isHiddenGem and then defaults to false */
  isHiddenGem: PropTypes.bool,
  centerMeta: PropTypes.bool,
  /** What trip (if any) associated with the venue (used on trip view (group favs)) */
  selectedTrip: PropTypes.object,
  /** whether or not to disable rendering the image */
  hideImage: PropTypes.bool,
  /** extra style to put on the card Meta (not VenueMeta) */
  cardMetaStyle: PropTypes.object,
  /** Link back to the explore page that the user was on */
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
  exploreContext: PropTypes.string,
  exploreFilter: PropTypes.string,
  showVideoIfAvailable: PropTypes.bool,
  isSelected: PropTypes.bool,
  withRatingSummary: PropTypes.bool,
  withDistance: PropTypes.bool,
  withPopularity: PropTypes.bool,
  withArticles: PropTypes.bool,
  isLoading: PropTypes.bool,
  fullDescription: PropTypes.bool,
};
const defaultProps = {
  disablePadding: false,
  extraPadding: false,
  extraBottomPadding: false,
  className: undefined,
  extraMeta: undefined,
  imgHeight: 'auto',
  showDescription: false,
  fullDescription: false,
  distance: undefined,
  nearMe: false,
  direction: undefined,
  titleSize: 'sm',
  isReviewed: false,
  numPosts: null,
  centerMeta: false,
  rank: undefined,
  isHiddenGem: undefined,
  selectedTrip: undefined,
  exploreLink: undefined,
  exploreLinkText: undefined,
  exploreContext: undefined,
  exploreFilter: undefined,
  showVideoIfAvailable: false,
  isSelected: undefined,
  withRatingSummary: false,
  withDistance: false,
  withPopularity: false,
  withArticles: false,
  isLoading: false,
};

/**
 * A venue card.
 */
const VenueCard = (props) => {
  const {
    venue,
    imgHeight,
    distance,
    className,
    direction,
    titleSize,
    disablePadding: propDisablePadding,
    extraBottomPadding,
    rank,
    isReviewed,
    numPosts,
    centerMeta,
    isHiddenGem: isHiddenGemProp,
    disableActionButtons,
    lessActionButtons,
    selectedTrip,
    hideImage,
    cardMetaStyle,
    exploreLink,
    exploreLinkText,
    exploreSubsection,
    exploreContext,
    exploreFilter,
    showVideoIfAvailable,
    isSelected,
    withRatingSummary,
    withDistance,
    withPopularity,
    withArticles,
    isLoading,
    fullDescription,
    ...other
  } = props;

  const {
    currentUser,
    components: { SearchBar, IconButtonBeenThere },
  } = useVbContext();

  const getQueryAddress = useCallback(() => `${APIConfig.NAMESPACE.VENUE}/user-data/${venue.id}`, [venue.id]);
  useContentSingle({
    type: ContentTypes.venue.type,
    getQueryAddress,
    id: venue.id,
    disabled: !currentUser,
    shouldFetchContent: (existingContent) => {
      // TODO: this is not working
      return typeof existingContent?.content?.latestUsers === 'undefined';
    },
  });

  // Icon buttons will be horizontal or vertical depending on the size of the card. Need to keep track of this to set
  // the correct value of noBreakWords in IconButtonBucket.
  const metaRef = useRef();
  const [verticalButtons, setVerticalButtons] = useState(false);
  useResizeObserver({
    ref: metaRef,
    onResize: ({ width }) => {
      // not mounted
      if (!width) return;

      // compare width to HORIZONTAL_ACTION_BUTTONS_CUTOFF, optionally update state
      if (width < HORIZONTAL_ACTION_BUTTONS_CUTOFF) {
        setVerticalButtons(true);
      } else {
        setVerticalButtons(false);
      }
    },
  });

  const isHiddenGem = isHiddenGemProp ?? venue.isHiddenGem ?? false;
  const vbBreakpoints = useVBBreakpoint();
  let disablePadding = propDisablePadding;
  if (typeof disablePadding === 'string') {
    disablePadding = vbBreakpoints[disablePadding];
  }
  const extraPadding = boolProp(props.extraPadding);
  const showDescription = boolProp(props.showDescription);

  const venueRank = rank ?? venue.rank;

  const venueId = venue.id;

  const permalink = venue.permalink
    ? addRankAndExploreLinkToPermalink(
        venue.permalink,
        venueRank,
        exploreLink,
        exploreLinkText,
        exploreContext,
        exploreFilter,
        exploreSubsection
      )
    : undefined;
  let actionButtons = useSaveIconButtons(venue, selectedTrip, verticalButtons);
  if (!disableActionButtons) {
    if (!lessActionButtons || !IconButtonBeenThere) {
      actionButtons.push(
        <IconShare type="View" permalink={window.location.host + permalink} name={venue.name} venueId={venueId} />
      );
    }
    if (!lessActionButtons && currentUser?.hasProductCapability && !isStaging())
      actionButtons.push(
        <IconUploadFeaturedImage venueId={venueId} venueName={venue.name} cityName={venue?.city?.city ?? ''} />
      );
  } else {
    actionButtons = null;
  }

  const leftOverlay = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (isHiddenGem) {
      // Overlay if it is a hidden gem.
      return (
        <div className={styles.hiddenGem}>
          <img src={ImageHiddenGem} alt="Hidden Gem" />
          <span>Hidden Gem</span>
        </div>
      );
    }

    if (withRatingSummary && venue.reviewsCount) {
      return <RatingSummary venueId={venue.id} reviewsCount={venue.reviewsCount} />;
    }

    if (withDistance) {
      return (
        <div className={styles.distance}>
          <div className={styles.content}>Distance: {buildDistanceString(distance)}</div>
        </div>
      );
    }

    return null;
  }, [distance, isHiddenGem, isLoading, venue, withDistance, withRatingSummary]);

  const rightOverlay = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (isReviewed || numPosts) {
      return (
        <div className={styles.pillContainer}>
          {isReviewed && (
            <VBLink noStyle to={permalink}>
              <VBButton size="xs" className={styles.badgeLabel}>
                <span className={styles.checkmark}>
                  <ColoredIcon src={CheckMark} color="white" />
                </span>
                Reviewed
              </VBButton>
            </VBLink>
          )}
          {Boolean(numPosts) && (
            <VBLink noStyle to={permalink}>
              <VBButton size="xs" className={styles.badgeLabel}>
                {numPosts} {numPosts === 1 ? 'Post' : 'Posts'}
              </VBButton>
            </VBLink>
          )}
        </div>
      );
    }

    if (withPopularity) {
      return (
        <div className={styles.popularity}>
          <div className={styles.content}>Popularity: {Math.round(Math.sqrt(venue.kidsOverallRating))}</div>
        </div>
      );
    }

    return null;
  }, [isLoading, isReviewed, numPosts, permalink, venue, withPopularity]);

  const topImageMaxRatio = useTopImageMaxRatio();

  const badge = getBadge(venue.overallRating);

  // Popup for editing a venue.
  const { modal, open: openEditPopup } = usePopup({
    child: <PopupAdminEditVenue venueId={venueId} SearchBar={SearchBar} />,
    size: 'lg',
    initOpen: false,
  });

  // Non-admin popup for submitting a venue edit to the content review system.
  const { modal: userEditVenueModal, open: userOpenVenueEdit } = usePopup({
    child: <PopupUserAddEditVenue venueId={venueId} />,
    size: 'lg',
    initOpen: false,
  });

  const testData = useMemo(() => ({ vbVenueCardId: venueId }), [venueId]);

  let badgeNode = null;
  if (badge) {
    badgeNode = (
      <>
        {badgeNode}
        <GemDropdown gemBadge={badge} />
      </>
    );
  }
  badgeNode = (
    <>
      {badgeNode}
      <PriceBadge price={venue.ticketPrice} />
    </>
  );

  const featuredContent = useMemo(
    () =>
      showVideoIfAvailable && venue.featuredVideo ? (
        <div className={styles.videoContainer}>
          <YoutubeEmbedVideo
            videoId={venue.featuredVideo.id}
            image={venue.featuredVideo.thumbnail}
            isSelected={isSelected}
            permalink={permalink}
            permalinkAriaLabel={`Go to ${venue.name} details page`}
            isLoading={isLoading}
          />
        </div>
      ) : (
        venue.featuredImage
      ),
    [showVideoIfAvailable, venue.featuredVideo, venue.name, venue.featuredImage, isSelected, permalink, isLoading]
  );

  const imageDimensions = {
    width: topImageMaxRatio * 100,
    height: 100,
  };

  return (
    <>
      <Card
        className={className}
        image={featuredContent}
        imageSmall={venue.featuredImageSmall}
        imageDimensions={imageDimensions}
        imageAspectRatio={isImageGenerated(venue?.imageAlt) ? topImageMaxRatio : undefined}
        includeImageBorder={!venue.hasFeaturedImage}
        ranking={isHiddenGem ? undefined : venueRank}
        title={venue.name}
        imageAlt={
          venue.imageAlt ?? (venue.city ? `${venue.name} in ${venue.city.city}, ${venue.city.regionShort}` : venue.name)
        }
        permalink={permalink}
        disableImgLink={Boolean(showVideoIfAvailable && venue.featuredVideo)}
        imageOverlay={
          leftOverlay || rightOverlay ? (
            <div className={styles.overlay}>
              {leftOverlay}
              {rightOverlay}
            </div>
          ) : null
        }
        disablePadding={disablePadding}
        extraPadding={extraPadding}
        extraBottomPadding={extraBottomPadding}
        squareBorders={disablePadding}
        actionButtons={actionButtons}
        badge={badgeNode}
        onEdit={
          currentUser?.hasProductCapability && !isStaging()
            ? openEditPopup
            : !isStaging() && currentUser
            ? userOpenVenueEdit
            : undefined
        }
        hideImage={hideImage}
        cardMetaStyle={cardMetaStyle}
        meta={
          <>
            {!props.hideMeta ? (
              <>
                <VenueMeta
                  venue={venue}
                  distance={distance}
                  direction={direction}
                  exploreLink={exploreLink}
                  exploreLinkText={exploreLinkText}
                  desc={showDescription ? venue.description?.content : null}
                  coords={[venue.latitude, venue.longitude]}
                  users={venue.latestUsers ? venue.latestUsers.users : null}
                  descLines={fullDescription ? null : undefined}
                  padSides={!disablePadding}
                  category={getDisplayedCategory(venue)}
                  nearMe={props.nearMe}
                  overallRating={venue.overallRating}
                  showArticles={withArticles}
                  isLoading={isLoading}
                />
              </>
            ) : null}
            {props.extraMeta}
          </>
        }
        titleSize={titleSize}
        covidLowRisk={venue.isVirusLowRisk}
        isMetaAlignedTop={!centerMeta}
        metaRef={metaRef}
        testData={testData}
        isLoading={isLoading}
        // https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
        noReferrerImage={venue.isGoogleImage}
        {...other}
      />
      {modal}
      {userEditVenueModal}
    </>
  );
};

VenueCard.propTypes = propTypes;
VenueCard.defaultProps = defaultProps;

export default VenueCard;
