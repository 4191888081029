import { get as getColorFromString } from 'color-string';
import seedrandom from 'seedrandom';
import { Color as FilterSolverColor, Solver as FilterSolver } from './3rd-party/color-to-filter';

// Map colors to filters.
const filterCache = {};

/**
 * Builds a CSS string that can be used as a 'filter' value to render a black image in a given color. Does this using
 * linear algebra, optimization and dark magic. I did not write this.
 *
 * @see https://codepen.io/sosuke/pen/Pjoqqp?editors=1111
 * @see FilterSolverColor
 * @see FilterSolver
 *
 * @param {string} c the color as a CSS string
 * @returns {string} the filter string to use to get that color from a black icon
 */
export const getCSSFilters = (c) => {
  // Check the cache first.
  if (filterCache[c]) {
    return filterCache[c];
  }

  // Find a filter with a loss less than or equal to 5, trying a max of 16 different filters.
  let filter;
  let tries = 0;
  while ((!filter || filter.loss > 5) && tries++ < 16) {
    const values = getColorFromString.rgb(c);
    const solverColor = new FilterSolverColor(values[0], values[1], values[2]);
    const solver = new FilterSolver(solverColor);
    const newFilter = solver.solve();
    if (!filter || newFilter.loss < filter.loss) {
      filter = newFilter;
    }
  }

  // Only cache if the loss is good. Otherwise, we can try to get a better version later.
  if (filter.loss <= 5) {
    filterCache[c] = filter.filter;
  }

  return filter.filter;
};

/**
 * @DEPRECATED I don't think we should ever need to use this with cssVariables being passed down
 * in a context, but it's here if some day we do.
 *
 * This will grab the colors from the css variables on root. Type in the latter part
 * of the color name (after "--vb-color-"). Ex. if you wanted to get --vb-color-grey-mid,
 * you'd call this function with the argument "grey-mid".
 */
// export const getVBColor = (color) => {
//   const styles = window.getComputedStyle(document.documentElement);
//   return styles.getPropertyValue(`--vb-color-${color}`).trim();
// }

/**
 * Helper function for getting colors from CSS variables.
 *
 * @param {object} cssVariables
 * @param {string} color 'red', 'blue', etc
 * @param {bool} highlight whether or not to use the highlight version
 *
 * @return {string} the CSS color
 */
export const getThemeColor = (cssVariables, color, highlight = false) => {
  const colorValue = cssVariables.color[color];
  if (highlight) return colorValue[1].highlight;
  if (Array.isArray(colorValue)) return colorValue[0];
  return colorValue;
};

/**
 * Helpful shortcuts for getting site colors.
 * @param {object} cssVariables the css variables object
 * @return {object} an object with color names as keys and color values as values
 */
export const makeStyleShortcuts = (cssVariables) => {
  return {
    /** ex. getThemColor("blue", true) would get the highlighted version of blue. */
    getThemeColor: (color, highlight) => getThemeColor(cssVariables, color, highlight),
    vbBlue: getThemeColor(cssVariables, 'blue', false),
    vbBlueHighlight: getThemeColor(cssVariables, 'blue', true),
    vbRed: getThemeColor(cssVariables, 'red', false),
    vbRedHighlight: getThemeColor(cssVariables, 'red', true),
    vbYellow: getThemeColor(cssVariables, 'yellow', false),
    vbYellowHighlight: getThemeColor(cssVariables, 'yellow', true),
    vbGreen: getThemeColor(cssVariables, 'green', false),
    vbBlack: getThemeColor(cssVariables, 'black', false),
    vbWhite: getThemeColor(cssVariables, 'white', false),
    vbGreyLight: getThemeColor(cssVariables, 'grey-light', false),
    vbGreyMid: getThemeColor(cssVariables, 'grey-mid', false),
    vbGreyDark: getThemeColor(cssVariables, 'grey-dark', false),
    vbGrayLight: getThemeColor(cssVariables, 'grey-light', false),
    vbGrayMid: getThemeColor(cssVariables, 'grey-mid', false),
    vbGrayDark: getThemeColor(cssVariables, 'grey-dark', false),
    vbMaxWidth: cssVariables['max-width'],
  };
};

/**
 * Sets the global CSS variables.
 * @param {Object} obj Object of the schema found in ViewBuff and Itineraries
 */
export const setGlobalCSSVars = (obj, nameSoFar = 'vb') => {
  if (obj.toString() === '[object Object]') {
    Object.keys(obj).forEach((key) => {
      setGlobalCSSVars(obj[key], `${nameSoFar}-${key}`);
    });
  } else if (Array.isArray(obj)) {
    obj.forEach((el) => setGlobalCSSVars(el, nameSoFar));
  } else if (typeof obj === 'string') {
    document.documentElement.style.setProperty(`--${nameSoFar}`, obj);
  } else {
    throw Error(`invalid value for '${nameSoFar}'`);
  }
};

/**
 * Get a random CSS color, given a seed.
 *
 * @param {any} seed
 * @param {number} opacity the opacity of the CSS color
 * @param {number} lightness
 * @param {number} saturation
 */
export const seededRandomColor = (seed = 0, opacity = 1, lightness = 50, saturation = 100) => {
  const rng = seedrandom(seed);
  const h = Math.floor(rng() * 256);
  return `hsla(${h}, ${saturation}%, ${lightness}%, ${opacity})`;
};
