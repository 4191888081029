import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../../shared-react/src/components/Card/Card';
import { PropTypesVBBool } from '../../../../../shared-react/src/util/types';
import { boolProp } from '../../../../../shared-react/src/util/props';
import { useVBBreakpoint } from '../../../../../shared-react/src/hooks/vb-breakpoint';
import { timeAgo } from '../../../../../shared-react/src/util/time';
import { usePopup } from '../../../../../shared-react/src/hooks/popup';
import { getPlaceholderName } from '../../../../../shared-react/src/util/location';
import { PropTypesVBPost } from '../../../util/types';
import IconShare from '../../../../../shared-react/src/components/IconShare/IconShare';
import IconSaveVenue from '../IconSaveVenue/IconSaveVenue';
import IconPostEllipsis from '../IconPostEllipsis/IconPostEllipsis';
import LikeButton from './LikeButton/LikeButton';
import PostMeta from './PostMeta/PostMeta';
import PostComments from './PopupPostComments/PostComments/PostComments';
import IconSetFeaturedImage from './IconSetFeaturedImage/IconSetFeaturedImage';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import IconBeenThere from '../IconBeenThere/IconBeenThere';
import APIConfig from '../../../../../shared-react/src/config/api';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import useContentSingle from '../../../../../shared-react/src/hooks/content-single';
import PostCommentsWrapper from '../PostCommentsWrapper/PostCommentsWrapper';
import styles from './PostCard.module.scss';

/**
 * A user post card. Only handles rendering, loading and state handled by Post.
 *
 * @param {object}      post                   the post object described above. See vb_post_query
 * @param {string}      content                caption the user attaches to the image they upload
 * @param {number}      distance               the distance between the user and the place
 * @param {string}      direction              the direction from the user/city to the place
 * @param {bool}        hideBorder             whether or not the card should be bordered
 * @param {bool}        commentsReplaceContent whether or not the comments replace the post contents,
 *                                                   rather than appearing in a separate popup
 * @param {(): void}   handleReport           function called when the content is reported
 * @param {bool}        nearMe                 display the distance with "From me" appended
 */
const PostCard = ({
  post,
  distance,
  direction,
  handleReport,
  hideBorder,
  commentsReplaceContent,
  nearMe,
  ...other
}) => {
  const [page, setPage] = useState('post');

  const { lteXs } = useVBBreakpoint();
  const { currentUser } = useVbContext();

  const bHideBorder = boolProp(hideBorder);
  const bCommentsReplaceContent = boolProp(commentsReplaceContent);

  const isRealVenue = Boolean(post.venue);

  const postContent = post?.content ?? '';
  const totalComments = post?.comments ?? 0;

  useContentSingle({
    type: ContentTypes.venue.type,
    id: post.id,
    disabled: !currentUser,
    getQueryAddress: () => {
      return `${APIConfig.NAMESPACE.POST}/user-data/${post.id}`;
    },
    shouldFetchContent: (existingContent) => {
      return typeof existingContent?.content?.venue?.latestUsers === 'undefined';
    },
  });

  const commentsPopup = usePopup({
    child: <PostCommentsWrapper totalComments={totalComments} postId={post.id} />,
    size: 'lg',
    initOpen: false,
  });

  /**
   * Function to either set the page to comments or open the comments popup.
   */
  const handleOpenComments = () => {
    if (bCommentsReplaceContent) {
      setPage('comments');
    } else {
      commentsPopup.open();
    }
  };

  const postTitle = useMemo(() => {
    if (isRealVenue) {
      return post?.venue?.name;
    }

    return [post?.location?.city, post?.location?.regionShort].filter(Boolean).join(', ');
  }, [isRealVenue, post]);

  const permalink = useMemo(() => {
    if (isRealVenue) {
      return post.venue?.permalink;
    }

    return post.location?.searchLink;
  }, [isRealVenue, post]);

  const actionsButtons = useMemo(() => {
    const buttons = [
      isRealVenue && <IconSaveVenue saved={post.venue.saved} venueId={post.venue.id} />,
      isRealVenue && <IconBeenThere venue={post.venue} />,
      <IconShare
        type="Post"
        name={postTitle}
        username={post.user.username}
        userLink={post.user.permalink}
        postId={post.id}
      />,
      <IconPostEllipsis postId={post.id} user={post.user} />,
      isRealVenue && currentUser?.hasProductCapability && (
        <IconSetFeaturedImage
          venueId={post.venue.id}
          venueName={post.venue.name}
          postId={post.id}
          postSrc={post.image}
        />
      ),
    ];

    return buttons.filter(Boolean);
  }, [currentUser, isRealVenue, post, postTitle]);

  const distanceValue = useMemo(() => {
    const hasDistance = Boolean(distance);

    if (hasDistance) {
      return distance;
    }

    if (isRealVenue) {
      return `(${getPlaceholderName(post.venue?.city, true)})`;
    }

    return null;
  }, [distance, isRealVenue, post.venue?.city]);

  if (!post) {
    return null;
  }

  return page === 'post' ? (
    <>
      {commentsPopup.modal}
      <Card
        className={styles.postCard}
        image={post.image}
        imageSmall={post.imageSmall}
        title={postTitle}
        permalink={permalink}
        distance={distanceValue}
        coords={isRealVenue ? [post.venue.latitude, post.venue.longitude] : null}
        direction={direction}
        meta={
          <PostMeta
            postId={post.id}
            content={postContent}
            venue={post.venue}
            user={post.user}
            comments={totalComments}
            ago={timeAgo(post.time, true)}
            isFollowing={post.user.followingStatus === 'following'}
            handleOpenComments={handleOpenComments}
            nearMe={nearMe}
            firstComment={post.firstComment}
            hideComments={other.hideComments}
            showVenueMetaInsteadUser={isRealVenue && other.showVenueMetaInsteadUser}
          />
        }
        covidLowRisk={isRealVenue && post.venue.isVirusLowRisk}
        imageOverlay={<LikeButton entityId={post.id} entityType="post" liked={post.userLiked} />}
        actionButtons={actionsButtons}
        bordered={!lteXs && !bHideBorder}
        disablePadding={lteXs || bHideBorder}
        squareBorders={lteXs}
        isMetaAlignedTop
        forceVerticalButtons="true"
        // https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
        noReferrerImage={post.isGoogleImage}
        {...other}
      />
    </>
  ) : (
    <PostComments includeTitle postId={post.id} commentsTotal={totalComments} setPage={setPage} />
  );
};

PostCard.propTypes = {
  post: PropTypesVBPost.isRequired,
  content: PropTypes.string,
  direction: PropTypes.string,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleReport: PropTypes.func,
  hideBorder: PropTypesVBBool,
  commentsReplaceContent: PropTypesVBBool,
  nearMe: PropTypes.bool,
};

PostCard.defaultProps = {
  content: '',
  distance: null,
  handleReport: undefined,
  hideBorder: false,
  commentsReplaceContent: false,
  nearMe: false,
  direction: undefined,
};

export default PostCard;
