import { ContentTypes } from '../../../../../../../shared-react/src/config/content-types';
import React from 'react';
import PropTypes from 'prop-types';
import TFComments from '../../../TFComments/TFComments';
import MaxLengths from '../../../../../../../shared-react/config-json/content-lengths.json';
/**
 * Component representing a list of post comments.
 *
 * @param {number}              props.postId            the ID of the post to get comments for
 * @param {number}              props.commentsTotal     the total number of comments, or 0 if this has not been loaded
 * @param {Function(string)}    props.setPage           function to change the page in the PostRoot, or null
 * @param {bool} props.hideTitle If it should include the title of "X Comments" at the top.
 */
const PostComments = ({ postId, commentsTotal, setPage, hideTitle }) => {
  return (
    <TFComments
      contentType={ContentTypes.post.type}
      contentId={postId}
      commentCountField="comments"
      commentsTotal={commentsTotal}
      queryRoute="posts"
      onBackButtonClick={setPage ? () => setPage('post') : null}
      maxLength={MaxLengths.POST_COMMENT}
      maxReplyLength={MaxLengths.POST_COMMENT_REPLY}
      hideTitle={hideTitle}
    />
  );
};

PostComments.propTypes = {
  postId: PropTypes.number.isRequired,
  commentsTotal: PropTypes.number,
  setPage: PropTypes.func,
  hideTitle: PropTypes.bool,
};

PostComments.defaultProps = {
  commentsTotal: 0,
  setPage: null,
  hideTitle: false,
};

export default PostComments;
