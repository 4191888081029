import { useLocation } from 'react-router';
import useQueryParams from './query-params';
import usePushPreserveScrollPosition from './push-preserve-scroll-position';

/**
 * Similar to useState, but stores the state in the query parameters.
 *
 * @param {string} queryParameter the name of the query parameter
 * @param {any} initialValue initial state value
 * @param {(x: string): any} parse optional function to parse a string into a value
 * @param {(x: any): string} stringify optional function to stringify a value
 *
 * @returns {array} same return type as useState
 */
const useQPState = (queryParameter, initialValue, parse, stringify) => {
  const location = useLocation();
  const params = useQueryParams();

  const valueString = params[queryParameter];
  let value = initialValue;
  if (typeof valueString !== 'undefined') value = parse ? parse(valueString) : valueString;

  const replaceKeepScroll = usePushPreserveScrollPosition();

  const setValue = (v) => {
    const newStr = stringify ? stringify(v) : v;
    const oldStr = stringify ? stringify(value) : value;
    if (newStr !== oldStr) {
      replaceKeepScroll(location.pathname + location.search, {}, 'replace', {
        [queryParameter]: stringify ? stringify(v) : v,
      });
    }
  };

  return [value, setValue];
};
export default useQPState;

/**
 * Parses a bool parameter. Turns '0', '1' to false, true.
 *
 * @param {string} x
 * @returns {bool}
 */
export const parseBoolParam = (x) => parseInt(x, 10) > 0;

/**
 * Turns a bool into a string for a param value. Turns false, true to '0', '1'.
 * @param {bool} x
 * @returns {string}
 */
export const stringifyBoolParam = (x) => (x ? '1' : '0');
