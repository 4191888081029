import VBSpinner from '../../../../shared-react/src/components/VBSpinner/VBSpinner';
import { useVBBreakpoint } from '../../../../shared-react/src/hooks/vb-breakpoint';
import VBReactProvider from '../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import React, { useEffect, Suspense, lazy, useState } from 'react';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { requestCurrentUser } from '../../store/actions/currentUserActions';
import styles from './Master.module.scss';
import Footer from './Footer/Footer';
import useCurrentUser from '../../hooks/current-user';
import Branding from '../../../../shared-react/src/config/branding';
import AuthPopupContainer from './AuthPopupContainer/AuthPopupContainer';
import SearchBar from './SearchBar/SearchBar';
import ReferralHandler from './ReferralHandler/ReferralHandler';
// import PageItineraryDetails from './PageItineraryDetails/PageItineraryDetails';
// import PageTripDetails from './PageTripDetails/PageTripDetails';
import VBToaster from '../../../../shared-react/src/components/VBToaster/VBToaster';
import ReportError from '../../../../shared-react/src/components/ReportError/ReportError';
import { setAuthToken, vbRequest } from '../../util/api';
import { useOpenAuthPopup } from '../../hooks/open-auth-popup';
import RouteWithGlobalLocation from './RouteWithGlobalLocation/RouteWithGlobalLocation';
import IconButtonSaveVenue from './IconSaveVenue/IconSaveVenue';
import { usePopup } from '../../../../shared-react/src/hooks/popup';
import VBRouter from '../../../../shared-react/src/components/VBRouter/VBRouter';
// import Experts from './Experts/Experts';
// import VenueDetailsItineraries from './VenueDetailsItineraries/VenueDetailsItineraries';
import PostCard from './PostCard/PostCard';
import PopupMakePost from './PopupMakePost/PopupMakePost';
import { refreshRandom } from '../../../../shared-react/src/store/actions/globalLocationActions';
import Header from '../../../../shared-react/src/components/Header/Header';
import GlobalMonitors from '../../../../shared-react/src/components/GlobalMonitors/GlobalMonitors';
import InstallPWA from '../../../../shared-react/src/components/InstallPWA/InstallPWA';
import AnalyticsHandler from '../AnalyticsHandler/AnalyticsHandler';
import IconBeenThere from './IconBeenThere/IconBeenThere';
import { getLocationTelize } from '../../../../shared-react/src/util/location';
import { isCrawler, isLive } from '../../../../shared-react/src/util/env';
import { clearToken } from '../../store/actions/authTokenActions';
import MobileFooter from './MobileFooter/MobileFooter';
import SocialLogin from './SocialLogin/SocialLogin';
import PopupGeneral from './PopupGeneral/PopupGeneral';
import GameHangmanGeneralRedirect from './GameHangmanGeneralRedirect/GameHangmanGeneralRedirect';
import PageHome from './PageHome/PageHome';
import PageExplore from './PageExplore/PageExplore';
import PageEmbedBadge from './PageEmbedBadge/PageEmbedBadge';
import PageZuzuGo from './PageZuzuGo/PageZuzuGo';

const PageTermsOfUse = lazy(() => import('./PageTermsOfUse/PageTermsOfUse'));
const PagePrivacyPolicy = lazy(() => import('./PagePrivacyPolicy/PagePrivacyPolicy'));
const PageNotifications = lazy(() => import('./PageNotifications/PageNotifications'));
const PageUser = lazy(() => import('./PageUser/PageUser'));
const PageHallOfFame = lazy(() => import('./PageHallOfFame/PageHallOfFame'));
const PageAbout = lazy(() => import('./PageAbout/PageAbout'));
const PageFriends = lazy(() => import('./PageFriends/PageFriends'));
const PageContact = lazy(() => import('./PageContact/PageContact'));
const PageEditUser = lazy(() => import('./PageEditUser/PageEditUser'));
const PageActivateEmail = lazy(() => import('./PageActivateEmail/PageActivateEmail'));
const PageResetPassword = lazy(() => import('./PageResetPassword/PageResetPassword'));
const PageBlogPost = lazy(() => import('./PageBlogPost/PageBlogPost'));
const PageBlog = lazy(() => import('./PageBlog/PageBlog'));
const PageVenueDetails = lazy(() =>
  import('../../../../shared-react/src/components/PageVenueDetails/PageVenueDetails')
);
const PageComingSoon = lazy(() => import('./PageComingSoon/PageComingSoon'));
const PageAdvertisers = lazy(() => import('./PageAdvertisers/PageAdvertisers'));
const PageNotAvailable = lazy(() => import('./PageNotAvailable/PageNotAvailable'));
const PageEvent = lazy(() => import('./PageEvent/PageEvent'));
const PageGameHangman = lazy(() => import('./PageGameHangman/PageGameHangman'));
const PageDailyContest = lazy(() => import('./PageDailyContest/PageDailyContest'));
const PageTrips = lazy(() => import('./PageTrips/PageTrips'));

/**
 * The master component. Handles top level routing.
 */
const Master = () => {
  const authToken = useSelector((state) => state.authToken);
  const dispatch = useDispatch();
  const { lteMd } = useVBBreakpoint();

  const { isLoading: isUserLoading, user: currentUser, isInitialized: isUserInitialized } = useCurrentUser();

  // Determine the user's location. Display a not available page if not in the US/Canada.
  const [canSeeSite, setCanSeeSite] = useState(true);

  useEffect(() => {
    // TODO: reset this once YouTube's team can access the site.
    const shouldCheckLocation = isLive() && !isCrawler();
    // const shouldCheckLocation = false;

    if (shouldCheckLocation) {
      getLocationTelize().then((response) => {
        const allowedCountries = ['United States', 'Canada'];

        if (!allowedCountries.includes(response.country)) {
          setCanSeeSite(false);
        }
      });
    }
  }, []);

  // Load the user's info when the auth token changes.
  const [lastAuthToken, setLastAuthToken] = useState(authToken);
  useEffect(() => {
    if (!isUserInitialized || authToken !== lastAuthToken) {
      dispatch(requestCurrentUser());
      setLastAuthToken(authToken);
    }
  }, [dispatch, authToken, isUserInitialized, lastAuthToken]);

  // Initial get global location.
  const { location, loading } = useSelector((state) => state.globalLocation);
  useEffect(() => {
    if (!location && !loading) {
      dispatch(refreshRandom(vbRequest));
    }
  }, [location, loading, dispatch]);

  // // Initialize sentry.
  // useEffect(() => {
  //   Sentry.init({
  //     dsn: SENTRY_DSN,
  //     integrations: [new Integrations.BrowserTracing()],

  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for performance monitoring.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0,

  //     release: VB_VERSION,
  //   });
  // }, []);

  // // Set sentry user
  // useEffect(() => {
  //   Sentry.setUser(
  //     currentUser
  //       ? {
  //           email: currentUser.email,
  //           username: currentUser.username,
  //           id: currentUser.id,
  //         }
  //       : null
  //   );
  // }, [currentUser]);

  const openAuthPopup = useOpenAuthPopup();

  const { modal: makePostPopupModal, open: openMakePostPopup } = usePopup({
    child: <PopupMakePost />,
    size: 'lg',
  });

  const logout = () => {
    // TODO: would like to use useClearToken in here
    dispatch(clearToken());
    setAuthToken(null);
  };

  return (
    <VBReactProvider
      routerLinks
      cssVariables={Branding.CSS_VARS}
      brandingConfig={Branding}
      vbRequest={vbRequest}
      openAuthPopup={openAuthPopup}
      logout={logout}
      currentUser={currentUser}
      components={{
        IconButtonSaveVenue,
        SearchBar,
        // VenueDetailsItineraries,
        // VenueDetailsExperts: Experts,
        IconButtonBeenThere: IconBeenThere,
        PostCard,
      }}
      openMakePostPopup={openMakePostPopup}
    >
      <HelmetProvider>
        <VBRouter>
          <Suspense fallback={<VBSpinner page />}>
            {canSeeSite ? (
              <AnalyticsHandler>
                <Header isUserLoading={isUserLoading} />
                <div className={styles.container}>
                  {/* Don't mount or render anything until we have figured out the current user. */}
                  <AuthPopupContainer />
                  <Switch>
                    <Route path="/location">
                      <RouteWithGlobalLocation component={PageExplore} />
                    </Route>
                    <Route path="/trending">
                      <RouteWithGlobalLocation component={PageExplore} afterPath="/location" />
                    </Route>
                    <Route path="/places/:country/:region/:city/:name" component={PageVenueDetails} />
                    <Route path="/notifications" component={PageNotifications} />
                    <Route path="/user" component={PageUser} />
                    <Route path="/edit/user" component={PageEditUser} />
                    <Route path="/friends" component={PageFriends} />
                    <Route path="/hall-of-fame" component={PageHallOfFame} />
                    <Route path="/about" component={PageAbout} />
                    <Route path="/terms-and-conditions" component={PageTermsOfUse} />
                    <Route path="/contact" component={PageContact} />
                    <Route path="/privacy-policy" component={PagePrivacyPolicy} />
                    <Route path="/social-login">
                      <div className={styles.spinnerContainer}>
                        <VBSpinner />
                      </div>
                    </Route>
                    <Route path="/activate-email/:code" component={PageActivateEmail} />
                    <Route path="/reset-password" component={PageResetPassword} />
                    {/* <Route path="/itineraries/:slug" component={PageItineraryDetails} />
                    <Route path="/trips/:id" component={PageTripDetails} /> */}
                    <Route path="/blog/posts/:slug" component={PageBlogPost} />
                    <Route path="/blog" component={PageBlog} />
                    <Route path="/embed/badge" component={PageEmbedBadge} />
                    <Route path="/coming-soon" component={PageComingSoon} />
                    <Route path="/advertisers" component={PageAdvertisers} />
                    <Route path="/events/:slug" component={PageEvent} />
                    <Route path="/games/hangman/:locationId" component={PageGameHangman} />
                    <Route path="/games/hangman" component={GameHangmanGeneralRedirect} />
                    <Route path="/daily-travel-contest" component={PageDailyContest} />
                    <Route path="/zuzu-go" component={PageZuzuGo} />
                    <Route path="/trips" component={PageTrips} />
                    <Route path="/" component={PageHome} />
                  </Switch>
                  {lteMd ? <MobileFooter /> : <Footer />}
                  <PopupGeneral />
                  <SocialLogin />
                  {/* <ReportError /> */}
                  <ReferralHandler />
                  <GlobalMonitors />
                  <InstallPWA />
                  {makePostPopupModal}
                </div>
              </AnalyticsHandler>
            ) : (
              <PageNotAvailable />
            )}
          </Suspense>
          <VBToaster />
        </VBRouter>
      </HelmetProvider>
    </VBReactProvider>
  );
};
Master.propTypes = {};

export default Master;
