import ColoredIcon from '../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import useDropdown from '../../../../../shared-react/src/hooks/dropdown';
import DropdownItem from '../../../../../shared-react/src/components/VBDropdownItem/VBDropdownItem';
import IconButton from '../../../../../shared-react/src/components/IconButton/IconButton';
import { VBReactContext } from '../../../../../shared-react/src/components/VBReactProvider/VBReactProvider';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import { updateItem } from '../../../../../shared-react/src/store/actions/apiActions';
import OnClickLink from '../../../../../shared-react/src/components/OnClickLink/OnClickLink';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Image from '../../../assets/images/icons/buttons/ellipsis.svg';
import { useReport } from '../../../../../shared-react/src/hooks/report';
import { useDeletePost } from '../../../../../shared-react/src/hooks/delete-post';
import { requestFollow, requestUnfollow, requestWithdraw } from '../../../util/relationships';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import { usePopup } from '../../../../../shared-react/src/hooks/popup';
import PopupConfirm from '../../../../../shared-react/src/components/PopupConfirm/PopupConfirm';

/**
 * The ellipsis on the post card.
 *
 * @param {number}  props.postId                the ID of the post
 * @param {string}  props.user.followingStatus  the following status on the user who made the post
 * @param {number}  props.user.id               the id of the user who made the post
 * @param {bool}    props.user.isPublic         whether or not the user who made the post is a public account
 */
const IconPostEllipsis = (props) => {
  const { postId, user } = props;

  const { currentUser, openAuthPopup } = useVbContext();
  const dispatch = useDispatch();

  const { vbBlack } = useContext(VBReactContext);

  // The relationship status.
  const [followingStatus, setFollowingStatus] = useState(user.followingStatus);

  // Whether or not the user is hovering over the component.
  const [hovering, setHovering] = useState(false);

  const expectedOnClick = {
    public: 'following',
    // I don't know why this happens, but some users have {... isPublic: true, followingStatus: "private"}
    private: user.isPublic ? 'following' : 'follow_pending',
    following: user.isPublic ? 'public' : 'private',
  };

  /**
   * Gets the text for the follow prompt based on the following status.
   */
  const getText = () => {
    if (followingStatus === 'public' || followingStatus === 'private' || followingStatus === 'self') {
      return 'Follow User';
    }
    if (followingStatus === 'follow_pending') {
      return 'Follow Requested';
    }
    if (followingStatus === 'following') {
      return 'Following User';
    }
    if (followingStatus === 'loading') {
      return 'Loading';
    }
    return 'Follow User';
  };

  /**
   * Gets the text when hovering for the follow prompt based on the following status.
   */
  const getHoverText = () => {
    if (followingStatus === 'follow_pending') {
      return 'Cancel Request';
    }
    if (followingStatus === 'following') {
      return 'Unfollow User';
    }
    return getText();
  };

  let closeDropdown = null;

  /**
   * Handle click of the follow button.
   */
  const handleClickFollow = (e) => {
    e.preventDefault();
    if (followingStatus === 'loading' || followingStatus === 'self') {
      return false;
    }

    const predictedStatus = expectedOnClick[followingStatus];

    setFollowingStatus(predictedStatus);
    dispatch(updateItem(user.id, ContentTypes.user.type, { predictedStatus }));

    if (followingStatus === 'following') {
      // Unfollow.
      requestUnfollow(user.id)
        .then((response) => {
          if (!response.success) throw new Error(`invalid status: '${response.success}'`);
        })
        .catch(console.error);
    } else if (followingStatus === 'follow_pending') {
      // Withdraw request.
      requestWithdraw(user.id)
        .then((response) => {
          if (!response.success) throw new Error(`invalid status: '${response.success}'`);
        })
        .catch(console.error);
    } else {
      // Follow.
      requestFollow(user.id)
        .then((response) => {
          if (!response.success) throw new Error(`invalid status: '${response.success}'`);
        })
        .catch(console.error);
    }

    return false;
  };

  const report = useReport();

  /**
   * Called when the user closes the report post confirmation popup.
   * @param {bool} confirmed whether or not the user wants to report the post
   */
  const handleReportConfirm = (confirmed) => {
    if (!confirmed) return;
    report(ContentTypes.post.type, postId);
    closeDropdown();
  };

  // Popup to confirm that the user wants to report a post
  const { modal: reportPostModal, open: reportPostOpen } = usePopup({
    child: <PopupConfirm onResolve={handleReportConfirm} prompt="Are you sure you want to report this post?" />,
  });

  const del = useDeletePost();
  const handleClickDelete = () => {
    del(ContentTypes.post.type, postId, props.user.id);
    closeDropdown();
  };

  const dropdownTrigger = (
    <>
      <IconButton height="30px">
        <ColoredIcon src={Image} color={vbBlack} alt="" />
      </IconButton>
    </>
  );

  const dropdownContent = (
    <>
      <div>
        <DropdownItem
          big
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={handleClickFollow}
        >
          {hovering ? getHoverText() : getText()}
        </DropdownItem>
        <DropdownItem big red onClick={reportPostOpen}>
          Report Post
        </DropdownItem>
        {followingStatus === 'self' && (
          <DropdownItem big red onClick={handleClickDelete}>
            Delete Post
          </DropdownItem>
        )}
      </div>
      {reportPostModal}
    </>
  );

  const dropdown = useDropdown({
    dropdown: dropdownContent,
    dropdownClassName: null,
    trigger: dropdownTrigger,
    triggerClassName: null,
  });

  closeDropdown = () => {
    dropdown.close();
  };

  return currentUser ? (
    <>{dropdown.dropdown}</>
  ) : (
    <OnClickLink noStyle onClick={() => openAuthPopup()}>
      {dropdownTrigger}
    </OnClickLink>
  );
};

IconPostEllipsis.propTypes = {
  postId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    followingStatus: PropTypes.string,
    isPublic: PropTypes.bool,
  }).isRequired,
};

export default IconPostEllipsis;
