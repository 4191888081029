import { useCallback } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useLocationDetails } from '../../../../../shared-react/src/hooks/location';
import usePopup, { useOpenAuthPopup } from '../../../hooks/open-auth-popup';
import PopupAdminEditVenue from '../../../../../shared-react/src/components/PopupAdminEditVenue/PopupAdminEditVenue';
import SearchBar from '../SearchBar/SearchBar';
import PopupEditLocation from './PopupEditLocation/PopupEditLocation';
import PopupUserAddEditVenue from '../../../../../shared-react/src/components/PopupUserAddEditVenue/PopupUserAddEditVenue';
import { useIsLoggedIn } from '../../../hooks/auth';
import useVbContext from '../../../../../shared-react/src/hooks/vb-context';
import PopupSharePage from '../../../../../shared-react/src/components/PopupSharePage/PopupSharePage';
import PopupUpsertFestival from '../PopupUpsertFestival/PopupUpsertFestival';
import PopupUpsertFAQ from '../PopupUpsertFAQ/PopupUpsertFAQ';
import { ExploreSubsection } from '../../../../../shared-react/src/config/explore';
import PopupQuestions from './PopupQuestions/PopupQuestions';
import PopupItinerary from './PopupItinerary/PopupItinerary';
import PageDetails from './PageDetails/PageDetails';

const propTypes = {
  /** match passed down by React Router */
  match: ReactRouterPropTypes.match.isRequired,
};

/**
 * Explore page on Zuzu.
 *
 * @param {object} props
 */
const PageExplore = ({ match }) => {
  const { country, region, city } = match.params;

  const loggedIn = useIsLoggedIn();
  const openAuth = useOpenAuthPopup();
  const locationDetails = useLocationDetails(match.params);
  const { currentUser } = useVbContext();

  const { modal: sharePopup, open: openSharePopup } = usePopup({
    child: <PopupSharePage permalink={window.location.href} />,
  });

  const { modal: createVenueModal, open: openVenueCreate } = usePopup({
    child: <PopupAdminEditVenue SearchBar={SearchBar} />,
    size: 'lg',
    initOpen: false,
  });

  const { modal: editLocationDetails, open: openEditLocationDetails } = usePopup({
    child: <PopupEditLocation locationDetails={locationDetails} />,
    size: 'lg',
    initOpen: false,
  });

  // Non-admin popup for submitting a new venue to the content review system.
  const { modal: userCreateVenueModal, open: userOpenVenueCreate } = usePopup({
    child: <PopupUserAddEditVenue />,
    size: 'lg',
    initOpen: false,
  });

  const { modal: addFestivalModal, open: openAddFestival } = usePopup({
    child: <PopupUpsertFestival locationDetails={locationDetails} />,
    size: 'lg',
    initOpen: false,
  });

  // Note: The popup is no longer valid. We now use trivia questions instead of trivia groups

  // const { modal: travelTriviaModal, open: openTravelTrivia } = usePopup({
  //   child: <PopupTrivia locationDetails={locationDetails} />,
  //   size: 'lg',
  //   initOpen: false,
  // });

  const { modal: questionsModal, open: openQuestions } = usePopup({
    child: <PopupQuestions locationDetails={locationDetails} />,
    size: 'lg',
    initOpen: false,
  });

  const { modal: upsertFAQModal, open: openUpsertFAQ } = usePopup({
    child: <PopupUpsertFAQ locationDetails={locationDetails} />,
    size: 'lg',
    initOpen: false,
  });

  const { modal: itineraryModal, open: openItinerarySeasons } = usePopup({
    child: <PopupItinerary locationDetails={locationDetails} />,
    size: 'lg',
    initOpen: false,
  });

  const getEllipsisMenu = useCallback(
    (subsection) => {
      const items = [
        {
          text: 'Share This Page',
          onClick: openSharePopup,
        },
        {
          text: 'Add New Place',
          onClick: currentUser?.hasProductCapability ? openVenueCreate : loggedIn ? userOpenVenueCreate : openAuth,
        },
      ];

      if (currentUser?.hasProductCapability) {
        items.push({
          text: 'Edit This Location',
          onClick: openEditLocationDetails,
        });

        if (!subsection || subsection === ExploreSubsection.FESTIVALS) {
          items.push({
            text: 'Add Festival',
            onClick: openAddFestival,
          });
        }

        // if (!subsection || subsection === ExploreSubsection.TRIVIA) {
        //   items.push({
        //     text: 'Travel Trivia',
        //     onClick: openTravelTrivia,
        //   });
        // }

        if (!subsection || subsection === ExploreSubsection.FAQ) {
          items.push(
            {
              text: 'List of Questions',
              onClick: openQuestions,
            },
            { text: 'Add New FAQ', onClick: openUpsertFAQ }
          );
        }

        if (!subsection) {
          items.push({
            text: 'Itineraries',
            onClick: openItinerarySeasons,
          });
        }
      }

      return items;
    },
    [
      openSharePopup,
      currentUser?.hasProductCapability,
      openVenueCreate,
      loggedIn,
      userOpenVenueCreate,
      openAuth,
      openEditLocationDetails,
      openAddFestival,
      openQuestions,
      openUpsertFAQ,
      openItinerarySeasons,
    ]
  );

  return (
    <>
      <PageDetails
        country={country}
        region={region}
        city={city}
        locationDetails={locationDetails}
        getEllipsisMenu={getEllipsisMenu}
      />
      {sharePopup}
      {createVenueModal}
      {editLocationDetails}
      {userCreateVenueModal}
      {addFestivalModal}
      {/* {travelTriviaModal} */}
      {questionsModal}
      {upsertFAQModal}
      {itineraryModal}
    </>
  );
};

export default PageExplore;

PageExplore.propTypes = propTypes;
