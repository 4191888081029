import QueryString from 'query-string';

/**
 * @typedef {object} RequestItemsArgs
 * @property {string} args.method the HTTP method to use for the request. Defaults to GET
 * @property {object} args.headers additional HTTP headers to use for the request. Defaults to empty object
 * @property {(offset: number, filter: any) => string} args.getQueryAddress the only mandatory property. Gets the
 *                                                                          address to query from the current filter and
 *                                                                          an offset value
 * @property {(oldOffset: number, items: any[], data: object)} args.nextOffset function to get the next offset
 * @property {(data: object) => any[]} args.getItems gets an array of items from the parsed body of the HTTP response
 * @property {(data: object) => object} args.filterResponse filter the parsed HTTP response body. To get the items from
 *                                                          this object, use getItems instead
 */

/**
 * Recursively requests items from an endpoint. Mostly used in apiActions.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {number} offset the start offset
 * @param {object} filter the filter. This can be any object defined by the user
 * @param {number} remaining the remaining items, optional. If this is not provided, only one request will be made
 * @param {RequestItemsArgs} args the arguments to use when requesting items
 * @param {object[]} lastItems the items received from the last call. Used when called recursively
 */
export const requestItems = (vbRequest, offset, filter, remaining, args, lastItems = []) =>
  new Promise((resolve, reject) => {
    const method = args.method || 'GET';
    const headers = args.headers || {};
    const { getQueryAddress } = args;

    /**
     * Filter the parsed HTTP response body.
     *
     * @param {object} data the HTTP response data
     *
     * @returns {object} the filtered data
     */
    const filterResponse = (data) => {
      if (args.filterResponse) {
        return args.filterResponse(data);
      }
      return data;
    };

    /**
     * Get the next offset from a response.
     *
     * @param {number} oldOffset the last offset
     * @param {object[]} items the items array
     * @param {object} data the response object
     *
     * @returns {number} the next offset
     */
    const nextOffset = (oldOffset, items, data) => {
      if (args.nextOffset) {
        return args.nextOffset(oldOffset, items, data);
      }
      return oldOffset + items.length;
    };

    /**
     * Gets an array of items from the parsed body of the HTTP response
     *
     * @param {object} response the parsed body of the HTTP response
     *
     * @returns {any[]} the array of items
     */
    const getItems = (response) => {
      if (args.getItems) {
        return args.getItems(response);
      }
      if (!response || Array.isArray(response) || !Object.keys(response).length || typeof response !== 'object') {
        throw new Error('invalid response for add items');
      }
      if (response.results) {
        return response.results.map((item) => ({ item }));
      }
      const arrKey = Object.keys(response).find((k) => Array.isArray(response[k]));
      if (typeof arrKey === 'undefined') {
        throw new Error('failed to find items in response object');
      }
      return response[arrKey].map((item) => ({ item }));
    };

    // Go to the API.
    const url = getQueryAddress(offset, filter);
    const qmInd = url.indexOf('?');
    let path = url;
    let params;
    if (qmInd > -1) {
      path = url.slice(0, qmInd);
      params = QueryString.parse(url.slice(qmInd + 1), { arrayFormat: 'bracket' });
    }
    vbRequest(path, { headers, method, params })
      .then((data) => {
        // Get the items from the data.
        const itemsFull = getItems(filterResponse(data));

        // Don't go over the amount specified in remaining.
        let items = itemsFull;
        const wouldRunOver = typeof remaining !== 'undefined' && items.length > remaining;
        if (wouldRunOver) items = itemsFull.slice(0, remaining);

        let noMore = false;
        if (!items.length || data.no_more || data.noMore) {
          noMore = true;
        }

        // If we are done, resolve. Dont means there are no more, or 'remining' has not been set, or 'remaining' has
        // been satisfied. Otherwise, request more items.
        const nextCallOffset = nextOffset(offset, items, data);
        const nextRemaining = remaining - items.length;
        const nextItems = lastItems.concat(items);
        if (noMore || typeof remaining === 'undefined' || (typeof remaining !== 'undefined' && nextRemaining <= 0)) {
          return resolve({
            offset: nextCallOffset,
            noMore: noMore && !wouldRunOver,
            items: nextItems,
          });
        }
        return requestItems(vbRequest, nextCallOffset, filter, nextRemaining, args, nextItems);
      })
      .then(resolve)
      .catch(reject);
  });

/**
 * Gets a single piece of content.
 *
 * @typedef {object} RequestOneArgs
 * @property {string} args.method the HTTP method to use for the request. Defaults to GET
 * @property {object} args.headers additional HTTP headers to use for the request. Defaults to empty object
 * @property {(id: any) => string} args.getQueryAddress address to use to query the content
 * @property {(data: object) => any[]} args.getContent gets an array of items from the parsed body of the HTTP response
 * @property {(data: object) => object} args.filterResponse filter the parsed HTTP response body. To get the items from
 *                                                          this object, use getItems instead
 * @property {any} args.id the ID to pass into get query address
 */

/**
 * Request a single piece of content.
 *
 * @param {function} vbRequest the vbRequest function to use for the request
 * @param {number} type the content type
 * @param {RequestOneArgs} args the arguments to use when requesting items
 */
export const requestOne = (vbRequest, type, args) =>
  new Promise((resolve, reject) => {
    const method = args.method || 'GET';
    const headers = args.headers || {};
    const { getQueryAddress } = args;

    /**
     * Filter the parsed HTTP response body.
     *
     * @param {object} data the HTTP response data
     *
     * @returns {object} the filtered data
     */
    const filterResponse = (data) => {
      if (args.filterResponse) {
        return args.filterResponse(data);
      }
      return data;
    };

    /**
     * Gets an array of items from the parsed body of the HTTP response
     *
     * @param {object} response the parsed body of the HTTP response
     *
     * @returns {any[]} the array of items
     */
    const getContent = (response) => {
      if (args.getContent) {
        return args.getContent(response);
      }
      if (!response || Array.isArray(response) || !Object.keys(response).length || typeof response !== 'object') {
        throw new Error('invalid response for get content');
      }
      if (response.result) {
        return response.result;
      }
      const objKey = Object.keys(response).find((k) => response[k].toString() === '[object Object]');
      if (typeof objKey === 'undefined') {
        throw new Error('failed to find content in response object');
      }
      return response[objKey];
    };

    // Go to the API.
    const url = getQueryAddress(args.id);
    const qmInd = url.indexOf('?');
    let path = url;
    let params;
    if (qmInd > -1) {
      path = url.slice(0, qmInd);
      params = QueryString.parse(url.slice(qmInd + 1));
    }
    vbRequest(path, { headers, method, params })
      .then((data) => {
        if (args.putTransient) args.putTransient(data);
        resolve(getContent(filterResponse(data)));
      })
      .catch(reject);
  });
