import VenueReviewCreate from '../VenueReviewCreate/VenueReviewCreate';
import ReviewEdit from '../ReviewEdit/ReviewEdit';
import { PropTypesVBVenue } from '../../util/types';
import { useClosePopup } from '../../hooks/popup';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../VBHeading/VBHeading';

const propTypes = {
  venue: PropTypesVBVenue,
};

const PopupLeaveReview = ({ venue }) => {
  const close = useClosePopup();

  return venue.userReview ? (
    <MaxWidthContainer>
      <VBHeading size="sm">Edit a Review</VBHeading>
      <ReviewEdit
        parentId={venue.id}
        reviewId={venue.userReview.id}
        emoji={venue.userReview.emojiRating}
        content={venue.userReview.content}
        onClose={close}
      />
    </MaxWidthContainer>
  ) : (
    <VenueReviewCreate venue={venue} onReviewCreated={close} popupView />
  );
};

PopupLeaveReview.propTypes = propTypes;

export default PopupLeaveReview;
