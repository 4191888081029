import PropTypes from 'prop-types';
import ExploreCard, { ExploreCardSkeletonPart } from '../ExploreCard/ExploreCard';
import { addParams } from '../../util/urls';

const propTypes = {
  item: PropTypes.object.isRequired,
  exploreLinkText: PropTypes.string,
  isLoading: PropTypes.bool,
};

const Article = ({ item, isLoading, exploreLinkText, ...rest }) => {
  const permalink = item.permalink
    ? addParams(item.permalink, {
        exploreLink: `${window.location.pathname}${window.location.search}`,
        exploreLinkText,
      })
    : undefined;

  return (
    <ExploreCard
      name={item.heading}
      description={item.content}
      isLoading={isLoading}
      skeletonParts={[ExploreCardSkeletonPart.TITLE, ExploreCardSkeletonPart.DESCRIPTION]}
      permalink={permalink}
      descriptionLink={permalink}
      {...rest}
    />
  );
};

Article.propTypes = propTypes;

export default Article;
