import React from 'react';
import PropTypes from 'prop-types';
import VBButton from '../VBButton/VBButton';
import VBSpinner from '../VBSpinner/VBSpinner';
import styles from './VBSpinnerButton.module.scss';

/**
 * @param {bool} isSpinning If true, shows spinner, if false, shows the button.
 */
const VBSpinnerButton = (props) => {
  const { isSpinning, children, ...rest } = props;

  const buttonStyle = rest.style ? { ...rest.style } : {};
  if (isSpinning) {
    Object.assign(buttonStyle, { visibility: 'hidden' });
  }

  const spinnerStyle = { position: 'absolute' };
  if (!isSpinning) {
    Object.assign(spinnerStyle, { visibility: 'hidden' });
  }

  const button = (
    <VBButton {...rest} style={buttonStyle}>
      {children}
    </VBButton>
  );
  return (
    <div className={styles.container}>
      <VBSpinner style={spinnerStyle} />
      {button}
    </div>
  );
};

VBSpinnerButton.propTypes = {
  isSpinning: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

VBSpinnerButton.defaultProps = {
  children: null,
};

export default VBSpinnerButton;
