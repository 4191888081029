import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import VBButton from '../../../../../shared-react/src/components/VBButton/VBButton';
import { SubcategoryLabels } from '../../../config/search-page';
import styles from './SubcategorySelector.module.scss';

const propTypes = {
  /** The currently selected subcategory */
  subcategory: PropTypes.string.isRequired,

  /** The function to set the subcategory */
  setSubcategory: PropTypes.func.isRequired,

  /** The function which sets the lowRisk value */
  setLowRisk: PropTypes.func,

  /** Whether only low risk (outdoor) venues should be displayed */
  lowRisk: PropTypes.bool,

  /** Any extra buttons to include (such as links to other tabs, there's an example in PageExplore) */
  extraButtons: PropTypes.arrayOf(PropTypes.node),

  /** Whether to make the buttons take up the full width of their container */
  fullWidth: PropTypes.bool,
};

const defaultProps = {
  extraButtons: [],
  fullWidth: false,
};

const SubcategorySelector = ({ subcategory, setSubcategory, setLowRisk, lowRisk, extraButtons, fullWidth }) => {
  useEffect(() => {
    if (!setLowRisk) return;

    if (subcategory === 'outdoor') {
      setLowRisk(true);
    } else {
      setLowRisk(false);
    }
  }, [subcategory, setLowRisk]);

  const buttons = [
    ...Object.entries(SubcategoryLabels)
      .filter(([cat_value]) => cat_value !== 'outdoor' || setLowRisk)
      .map(([cat_value, cat_label]) => {
        return (
          <VBButton
            content={cat_label}
            onClick={() => {
              setSubcategory(cat_value);
            }}
            size="med"
            type={subcategory === cat_value ? (cat_value === 'all' ? (lowRisk ? 'border' : 'full') : 'full') : 'border'}
            key={cat_value + '_filter_button'}
            className={fullWidth ? styles.fullWidth : null}
          />
        );
      }),
    ...extraButtons,
  ];

  return <div className={styles.mobileFilter}>{buttons}</div>;
};

export default SubcategorySelector;
SubcategorySelector.propTypes = propTypes;
SubcategorySelector.defaultProps = defaultProps;
