import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import EllipsisCircle from '../../../assets/images/icons/ellipsis-circle.svg';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import VBDropdownItem from '../VBDropdownItem/VBDropdownItem';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import { useDropdown } from '../../hooks/dropdown';

const propTypes = {
  /** The items you want in the dropdown. Will be wrapped in a VBDropdownItem.
   * Most often you will want each item to be a VBLink.
   * NOTE: Make sure to put a key prop on each element in this array.  */
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** CSS string of the color of the ellipsis in hex. */
  color: PropTypes.string,
  /** See hooks/dropdown.jsx for the extra args that can be passed to useDropdown */
  dropdownArgs: PropTypes.object,
  /** whether or not to close the dropdown when an item is clicked */
  closeOnSelect: PropTypes.bool,
  /** whether the dropdown should be closed. This will override the previous state */
  forceClosed: PropTypes.bool,
  /** A size to give the ellipsis icon */
  size: PropTypes.oneOf(['tny', 'xs', 'sm', 'med', 'lg']),
  /** ellipsis style */
  style: PropTypes.object,
};
const defaultProps = {
  // Will actually default to mid grey in code.
  color: null,
  dropdownArgs: {},
  closeOnSelect: false,
  forceClosed: false,
  size: null,
  style: undefined,
};

/**
 * A common dropdown we have throughout the site is the ellipsis circle dropdown which will contain
 * various list items. This is the component to use for that. Just a standard dropdown with the
 * ellipsis circle icon as it's trigger.
 */
function EllipsisDropdown({
  items,
  color: c,
  dropdownArgs,
  closeOnSelect,
  forceClosed,
  size,
  style: triggerStyle,
  ...other
}) {
  const { cssVariables } = useContext(VBReactContext);
  let color = c;
  if (!c) {
    color = cssVariables.color.grey.mid;
  }
  const ellipsisContent = items.map(
    (item, ind) =>
      item && (
        <VBDropdownItem key={item.key ?? ind} closeOnSelect={closeOnSelect} onClick={item.props?.onClick}>
          {item}
        </VBDropdownItem>
      )
  );
  const ellipsisTrigger = (
    <ColoredIcon
      style={{ cursor: 'pointer', ...triggerStyle }}
      src={EllipsisCircle}
      color={color}
      size={size}
      {...other}
    />
  );
  const { dropdown, isOpen, close } = useDropdown({
    dropdown: ellipsisContent,
    trigger: ellipsisTrigger,
    ...dropdownArgs,
  });

  useEffect(() => {
    if (forceClosed && isOpen) close();
  }, [isOpen, forceClosed, close]);

  return dropdown;
}

EllipsisDropdown.propTypes = propTypes;
EllipsisDropdown.defaultProps = defaultProps;

export default EllipsisDropdown;
