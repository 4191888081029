import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Context used to pass name, selected index, and onChange function to children.
export const VBRadioGroupProvider = createContext();

const propTypes = {
  /** the value that is currently selected. Should be a value provided in the value prop to a child VBRadioButton. If no
   *  valid value has been provided, no radio button will be selected by default
   */
  selected: PropTypes.any,

  /**
   * name of the group
   */
  name: PropTypes.string,

  /**
   * callback for when the value changes. Takes the new value as the only argument
   */
  onChange: PropTypes.func,

  children: PropTypes.node,
};

const defaultProps = {
  selected: undefined,
  name: undefined,
  onChange: undefined,
  children: undefined,
};

/**
 * A group of radio buttons. This will not actually render any radio buttons, you will need to add instances of the
 * VBRadioButton component as children (although these do not need to be direct children). This component gives you a
 * single point to manage state to/from (rather than dealing with all of the children individually).
 *
 * @param {object} props
 */
const VBRadioGroup = ({ selected: parentSelected, name, onChange: parentOnChange, children }) => {
  // Use either the name prop or a randomly generated ID.
  const [actualName, setActualName] = useState(name);
  useEffect(() => {
    if (typeof name === 'undefined') {
      setActualName(uuidv4());
    } else {
      setActualName(name);
    }
  }, [name]);

  // Update selected, but keep in state so input can be uncontrolled
  const [selected, setSelected] = useState(parentSelected);
  useEffect(() => {
    setSelected(parentSelected);
  }, [parentSelected]);

  // onChange wrapper so input can be uncontrolled.
  const onChange = (val) => {
    if (parentOnChange) {
      parentOnChange(val);
    } else {
      setSelected(val);
    }
  };

  return (
    <VBRadioGroupProvider.Provider value={{ name: actualName, selected, onChange }}>
      {children}
    </VBRadioGroupProvider.Provider>
  );
};
export default VBRadioGroup;
VBRadioGroup.propTypes = propTypes;
VBRadioGroup.defaultProps = defaultProps;
