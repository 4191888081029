import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';

const propTypes = {};
const defaultProps = {};

/**
 * Our toaster component that should be included at the root of the app.
 */
function VBToaster() {
  const { lteXs } = useVBBreakpoint();
  return (
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position={lteXs ? 'bottom-center' : 'bottom-right'}
      getState={(state) => state.toastr}
      transitionIn="bounceIn"
      transitionOut="bounceOut"
      progressBar={false}
      closeOnToastrClick
    />
  );
}

VBToaster.propTypes = propTypes;
VBToaster.defaultProps = defaultProps;

export default VBToaster;
