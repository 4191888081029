import { useMemo, useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContentTypes } from '../config/content-types';
import { requestSingle } from '../store/actions/apiActions';
import { populateReferences } from '../util/content';
import { getKey, isUndefined } from '../util/objects';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';

/**
 * Gets a single piece of content.
 *
 * @param {number} args.type the content type
 * @param {string} args.method the HTTP method to use for the request. Defaults to GET
 * @param {object} args.headers additional HTTP headers to use for the request. Defaults to empty object
 * @param {(id: any) => string} args.getQueryAddress address to use to query the content
 * @param {(data: object) => any} args.getContent gets an item from the parsed body of the HTTP response
 * @param {(data: object) => any[]} args.getTransient gets an array of items from the parsed body of the HTTP response
 * @param {(data: object) => object} args.filterResponse filter the parsed HTTP response body. To get the items from
 *                                                          this object, use getItems instead
 * @param {any} args.id the ID to request. Optional.
 * @param {(content: any) => bool} args.find if you did not provide an ID, but you suspect you may still be able to
 *                                              find the content in redux store, use this function to filter out the
 *                                              piece of content you want
 * @param {bool} args.disabled whether or not to disable the query. If the content can already be found in cache via
 *                             id or find, it will still be returned via content even if disabled is true
 * @param {(existingContent: any) => bool} args.shouldFetchContent whether or not content should be fetched. If the item
 *                                                               already exists in the cache, this can be used to
 *                                                               prevent an additional network request
 */
const useContentSingle = ({
  type,
  id,
  find,
  method,
  headers,
  getQueryAddress,
  getContent,
  filterResponse,
  getTransient,
  disabled,
  shouldFetchContent,
}) => {
  const { vbRequest } = useContext(VBReactContext);
  const dispatch = useDispatch();

  const [appliedId, setAppliedId] = useState(undefined);
  const [transient, setTransient] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(true);

  const requestArgs = useMemo(() => {
    let putTransient;
    if (getTransient) {
      putTransient = (data) => setTransient(getTransient(data));
    }
    return { method, headers, getQueryAddress, getContent, filterResponse, putTransient };
  }, [method, headers, getQueryAddress, getContent, filterResponse, getTransient]);

  const allContent = useSelector((state) => state.api.content);
  const allContentOfType = allContent?.[type];

  let content = allContentOfType?.[appliedId];

  // If no ID was provided,
  let requestId = appliedId;
  if (typeof appliedId === 'undefined' && find) {
    if (allContentOfType) {
      content =
        allContentOfType[
          Object.keys(allContentOfType).find((x) =>
            allContentOfType[x].content ? find(allContentOfType[x].content) : false
          )
        ];
      if (content?.content) {
        requestId = ContentTypes[type].getKey?.(content.content) ?? getKey(content.content);
      }
    }
  }

  useEffect(() => {
    if (typeof id !== 'undefined' && appliedId !== id) {
      setAppliedId(undefined);
    }
  }, [id, appliedId]);

  useEffect(() => {
    setAppliedId(undefined);
    setIsDirty(true);
  }, [find]);

  useEffect(() => {
    const item = content?.content;
    if (item && typeof appliedId === 'undefined') {
      setAppliedId(ContentTypes[type].getKey?.(item) ?? getKey(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.content, appliedId, type]);

  useEffect(() => {
    const appliedIdDefined = typeof appliedId !== 'undefined';
    const contentDefined = typeof content !== 'undefined';
    if (!isDirty) {
      if (appliedIdDefined && contentDefined && loading) {
        setLoading(false);
        return;
      }
      if ((contentDefined && appliedIdDefined) || loading) {
        return;
      }
    } else {
      setIsDirty(false);
    }

    const canFindContent = (allContentOfType && find) || !isUndefined(id);
    if (
      !disabled &&
      (!shouldFetchContent || (isUndefined(appliedId) && canFindContent && shouldFetchContent(content))) &&
      requestArgs.getQueryAddress
    ) {
      setLoading(true);
      dispatch(requestSingle(vbRequest, type, { id: requestId, ...requestArgs }));
    }

    if (typeof id !== 'undefined' && appliedId !== id) {
      setAppliedId(id);
    }
  }, [
    dispatch,
    requestArgs,
    type,
    appliedId,
    requestId,
    id,
    content,
    loading,
    isDirty,
    vbRequest,
    disabled,
    shouldFetchContent,
    allContentOfType,
    find,
  ]);

  // Fill with references.
  const newContent = useMemo(() => {
    if (!content?.content) {
      return null;
    }
    return populateReferences(content.content, type, allContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContent, content?.content, type]);

  let err = content?.error;

  const { singleErrorsUnknownId } = useSelector((state) => state.api);
  if (getQueryAddress) {
    const errorUnknownId = singleErrorsUnknownId[getQueryAddress(id)];

    if (!err && !content?.content && errorUnknownId) {
      err = errorUnknownId;
    }
  }

  return { content: newContent, loading: content?.loading ?? true, transient, error: err };
};
export default useContentSingle;
