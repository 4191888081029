import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Reply from './Reply/Reply';
import VBSpinner from '../../../VBSpinner/VBSpinner';
import VBButton from '../../../VBButton/VBButton';
import VBTextArea from '../../../VBTextArea/VBTextArea';

import styles from './Replies.module.scss';
import { PropTypesVBCommentReply } from '../../../../util/types';
import NoContent from '../../../NoContent/NoContent';

const propTypes = {
  /** array of PropTypesVBCommentReply to render */
  replies: PropTypes.arrayOf(PropTypesVBCommentReply).isRequired,

  /** whether replies are loading */
  loading: PropTypes.bool,

  /** whether there are more replies to render */
  noMore: PropTypes.bool,

  /** max length of the reply */
  maxLength: PropTypes.number,

  /** function to call to submit a reply. The content of the new reply is passed in as the only argument. Should return
   *  a promise */
  onRequestSubmit: PropTypes.func,

  /** function to generate a report icon. Takes the reply as an argument, should return a node */
  report: PropTypes.func,

  /** function to call when more replies should be loaded. Should return a promise */
  onRequestLoadMore: PropTypes.func,

  /** the add reply icon */
  addIcon: PropTypes.node,
};

const defaultProps = {
  loading: false,
  noMore: false,
  maxLength: undefined,
  onRequestSubmit: undefined,
  report: undefined,
  onRequestLoadMore: undefined,
  addIcon: undefined,
};

/**
 * Renders a list of comment replies. Intended for use in conjuncation with the Comments component.
 *
 * @param {object} props
 */
const Replies = ({
  replies,
  loading: repliesLoading,
  noMore,
  onRequestSubmit,
  report,
  onRequestLoadMore,
  maxLength,
  addIcon,
}) => {
  // The value in the reply input.
  const [replyInputValue, setReplyInputValue] = useState('');

  // Whether reply creation is loading.
  const [replyCreateLoading, setReplyCreateLoading] = useState(false);

  /**
   * Handle change in reply input.
   *
   * @param {SyntheticEvent} e the event
   */
  const replyInputChange = (e) => {
    const el = e.target;
    if (el.value === replyInputValue) {
      return;
    }
    setReplyInputValue(el.value);
  };

  /**
   * Create a new reply.
   */
  const submitReply = () => {
    if (replyCreateLoading || !replyInputValue) {
      return;
    }
    setReplyCreateLoading(true);
    onRequestSubmit(replyInputValue)
      .catch((err) => console.error(err))
      .finally(() => setReplyCreateLoading(false));
    setReplyInputValue('');
  };

  let loadMoreContent = null;
  if (!noMore) {
    if (!repliesLoading) {
      if (!noMore && onRequestLoadMore) {
        loadMoreContent = <VBButton size="sm" type="full" onClick={onRequestLoadMore} content="Load More" />;
      }
    } else {
      loadMoreContent = (
        <span className={styles.spinnerWrapper}>
          <VBSpinner />
        </span>
      );
    }
  }

  let bottom = null;
  if (!replyCreateLoading) {
    if (onRequestSubmit) {
      bottom = (
        <span className={styles.addReply} role="button" tabIndex={0} onClick={submitReply}>
          {addIcon ?? '+'}
        </span>
      );
    }
  } else {
    bottom = (
      <span className={styles.spinnerWrapper}>
        <VBSpinner />
      </span>
    );
  }

  return (
    <div className={styles.replies}>
      <div className={styles.bottom}>
        {onRequestSubmit ? (
          <VBTextArea
            onChange={replyInputChange}
            containerClassName={styles.input}
            placeholder="Add Reply"
            value={replyInputValue}
            disabled={replyCreateLoading}
            maxLength={maxLength}
            interceptNewLine={submitReply}
            grow
          />
        ) : null}
        {bottom}
      </div>
      <div className={styles.list}>
        {replies.map((reply) => (
          <Reply key={reply.id} reply={reply} report={report} />
        ))}
      </div>
      <div className={styles.loadMoreContainer}>{loadMoreContent}</div>
      {replies.length <= 0 && <NoContent typePlural="replies" />}
    </div>
  );
};

Replies.propTypes = propTypes;

Replies.defaultProps = defaultProps;

export default Replies;
