import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalMonitors } from '../../hooks/global-monitors';
import { useDispatch } from 'react-redux';

const propTypes = {};
const defaultProps = {};

/**
 * Global monitors are hooks that generally are timer based.
 * Ex. an activity hook that checks for notifications every 60 seconds.
 */
function GlobalMonitors({ children }) {
  useGlobalMonitors();
  return children ?? null;
}

GlobalMonitors.propTypes = propTypes;
GlobalMonitors.defaultProps = defaultProps;

export default GlobalMonitors;
