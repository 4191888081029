import React, { useContext } from 'react';

import styles from './LegalAgreement.module.scss';
import { VBReactContext } from '../../../VBReactProvider/VBReactProvider';
import VBLink from '../../../VBLink/VBLink';

/**
 * The legal agreement statement in the Sign Up popup.
 * Contains links to the terms of use and privacy policy pages.
 */
const LegalAgreement = (props) => {
  const { brandingConfig } = useContext(VBReactContext);
  return (
    <>
      <div className={styles.legalAgreement}>
        By joining {brandingConfig.SITE_NAME}, you agree to the{' '}
        <VBLink className={styles.link} to="/terms-and-conditions">
          Terms of Use
        </VBLink>
        {', '}
        <VBLink className={styles.link} to="/privacy-policy#cookies-policy">
          Cookies Policy
        </VBLink>
        {' and '}
        <VBLink className={styles.link} to="/privacy-policy">
          Privacy Policy
        </VBLink>
      </div>
    </>
  );
};

export default LegalAgreement;
