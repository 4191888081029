import { CLEAR_PAGE_ITEM_ID, SET_PAGE_ITEM_ID } from '../types';

/**
 * Reducer for page id.
 *
 * @param {object} state current state
 * @param {string} action.type the type of the action. See pageItemIdActions.jsx
 * @param {object} action.payload the page ids object
 *
 * @returns {object} the new state
 */
const pageItemIdReducer = (state = null, action) => {
  const { payload, type: actionType } = action;
  switch (actionType) {
    case SET_PAGE_ITEM_ID:
      return !state ? payload : { ...state, ...payload };
    case CLEAR_PAGE_ITEM_ID:
      return null;
    default:
  }
  return state;
};

export default pageItemIdReducer;
