import { SITE_INFO } from './sites';
import { getSiteName } from '../util/sites';

/**
 * Gets the badge ("gold", "silver", "bronze", null) for a venue based on the rating.
 *
 * @param {float} rating
 * @param {string} site The site name to use. Defaults to current site.
 *
 * @returns "gold", "silver", "bronze", or null for no badge
 */
export const getBadge = (rating, site = null) => {
  const s = site ?? getSiteName();
  const thresholds = SITE_INFO[s].THRESHOLDS;

  if (rating >= thresholds[0]) return 'gold';
  if (rating >= thresholds[1]) return 'silver';
  if (rating >= thresholds[2]) return 'bronze';
  return null;
};

const BADGE_VALUES = {
  gold: 2,
  silver: 1,
  bronze: 0,
};
/**
 * Compares two badges.
 */
export const badgeCompare = (a, b) => {
  if (a === b) return 0;
  if (a === null) return 1;
  if (b === null) return -1;
  return BADGE_VALUES[a] > BADGE_VALUES[b] ? -1 : 1;
};
