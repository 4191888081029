import useDropdown from '../../../hooks/dropdown';
import useVbContext from '../../../hooks/vb-context';
import { mergeClassNames } from '../../../util/props';
import Avatar from '../../Avatar/Avatar';
import DummyUsersDropdownList from '../../DummyUsersDropdownList/DummyUsersDropdownList';

import styles from './Author.module.scss';

const Author = ({ author, onSelect }) => {
  const { currentUser } = useVbContext();

  const { dropdown } = useDropdown({
    dropdown: (
      <DummyUsersDropdownList
        containerClassName={styles.dropdown}
        onSelect={onSelect}
        fullWidth
        displayBelow
        shadow
      />
    ),
    trigger: (
      <>
        <Avatar src={author.avatar} userId={author.id} className={styles.avatar} />
        <strong>{author.username}</strong>
      </>
    ),
    dropdownClassName: styles.dropdownWrapper,
  });

  return (
    <div className={mergeClassNames(styles.author, currentUser?.hasProductCapability && styles.clickable)}>
      {dropdown}
    </div>
  );
};

export default Author;
