import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './VBTextArea.module.scss';
import { boolProp, mergeClassNames } from '../../util/props';
import VBCharacterLimit from '../VBCharacterLimit/VBCharacterLimit';
import VBInputLabel from '../VBInputLabel/VBInputLabel';
import VBPhotoUpload from '../VBPhotoUpload/VBPhotoUpload';
import { PropTypesRefProp } from '../../util/types';

const propTypes = {
  /** additional classNames */
  className: PropTypes.string,

  /** height of the input as a CSS string */
  height: PropTypes.string,

  /** additional style */
  style: PropTypes.object,

  /** ref */
  inputRef: PropTypesRefProp,

  /** the border to use:
   *    "shadow"  = Drop shadow (default)
   *    "grey"    = Solid grey line
   *    "blue"    = Solid blue line
   */
  border: PropTypes.oneOf(['shadow', 'grey', 'blue']),

  /** whether the input should grow in height instead of scroll */
  grow: PropTypes.bool,

  /** whether the text area should be styled as inline text */
  asText: PropTypes.bool,

  /** max length of the input */
  maxLength: PropTypes.number,

  /** whether or not to show the max length */
  showMaxLength: PropTypes.bool,

  /** A label to show above the text area */
  label: PropTypes.string,

  /** Whether or not this input is required */
  required: PropTypes.bool,

  /** value of the input */
  value: PropTypes.string,

  /** change callback passed down to the input component */
  onChange: PropTypes.func,

  /** input classNames for the input container */
  containerClassName: PropTypes.string,

  /** callback for when a new line appears in the input. The value will be updated to not include the new line. If not
   *  provided, new lines are allowed in the input
   */
  interceptNewLine: PropTypes.func,

  /** id of the input */
  id: PropTypes.string,

  /** Photo upload props */
  photoUpload: PropTypes.object,
};

const defaultProps = {
  className: undefined,
  height: undefined,
  style: {},
  border: 'shadow',
  grow: false,
  asText: false,
  inputRef: undefined,
  maxLength: undefined,
  showMaxLength: false,
  label: undefined,
  required: false,
  value: '',
  onChange: undefined,
  containerClassName: undefined,
  interceptNewLine: undefined,
  id: undefined,
  photoUpload: undefined,
};

/**
 * A stylized text area. For more props, see https://react-bootstrap.github.io/components/forms/#form-control-props
 *
 * @param {object} props
 */
const VBTextArea = ({
  className,
  height,
  style,
  inputRef,
  border,
  grow,
  asText,
  maxLength,
  showMaxLength,
  value: parentValue,
  onChange,
  label,
  required,
  containerClassName,
  interceptNewLine,
  id,
  photoUpload,
  ...other
}) => {
  if (showMaxLength && typeof maxLength === 'undefined')
    throw new Error('maxLength is required in VBTextArea when showMaxLength is enabled');

  // Maintain value locally, bind it one way to the value prop.
  const [value, setValue] = useState(parentValue);

  const hasLabel = Boolean(label);
  const hasPhotoUploadProps = Boolean(photoUpload);

  const [newLineIntercepted, setNewLineIntercepted] = useState(false);

  /**
   * Sets the value in state and calls the parent callback if it is defined.
   *
   * @param {React.SyntheticEvent} e
   */
  const onChangeWrapper = useCallback(
    (e) => {
      const v = e.target.value;

      if (interceptNewLine && v.indexOf('\n') >= 0) {
        const filteredValue = v.replaceAll('\n', '');

        setValue(filteredValue);
        onChange?.({ ...e, target: { ...e.target, value: filteredValue } });
        setNewLineIntercepted(true);
      } else {
        setValue(e.target.value);
        onChange?.(e);
      }
    },
    [interceptNewLine, onChange]
  );

  const borderClass = useMemo(() => {
    switch (border) {
      case 'shadow': {
        return styles.borderShadow;
      }
      case 'grey':
      case 'gray': {
        return styles.borderGrey;
      }
      case 'blue': {
        return styles.borderBlue;
      }
      default: {
        return null;
      }
    }
  }, [border]);

  const bGrow = boolProp(grow);

  const inputProps = useMemo(
    () => ({
      style: { height, ...style },
      className: mergeClassNames(styles.input, className, bGrow && styles.grow, asText && styles.asText),
      ref: inputRef,
      ...other,
    }),
    [asText, bGrow, className, height, inputRef, other, style]
  );

  const textareaContent = useMemo(() => {
    if (bGrow) {
      return (
        <TextareaAutosize
          id={id}
          maxLength={maxLength}
          value={value}
          onChange={onChangeWrapper}
          data-hj-allow
          {...inputProps}
        />
      );
    }

    return (
      <Form.Control
        as="textarea"
        id={id}
        maxLength={maxLength}
        value={value}
        onChange={onChangeWrapper}
        {...inputProps}
      />
    );
  }, [bGrow, id, inputProps, maxLength, onChangeWrapper, value]);

  useEffect(() => setValue(parentValue), [parentValue]);

  // Intercept new lines
  useEffect(() => {
    if (newLineIntercepted) {
      interceptNewLine();
      setNewLineIntercepted(false);
    }
  }, [interceptNewLine, newLineIntercepted]);

  return (
    <div>
      {hasLabel && <VBInputLabel labelFor={id} content={label} required={required} />}
      <div className={mergeClassNames(styles.container, borderClass, containerClassName)}>
        {textareaContent}
        {hasPhotoUploadProps && (
          <VBPhotoUpload theme="textarea" containerClassName={styles.photoUpload} {...photoUpload} />
        )}
        {showMaxLength && <VBCharacterLimit remaining={maxLength - value.length} />}
      </div>
    </div>
  );
};

export default VBTextArea;

VBTextArea.propTypes = propTypes;
VBTextArea.defaultProps = defaultProps;
