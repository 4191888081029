import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './IconButton.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** Children inside this component (the actual icon) */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /** The text to display under the button (omit to exclude this) */
  text: PropTypes.string,
  /** Position of the text */
  textPosition: PropTypes.oneOf(['right', 'bottom']),
  /** A class to give to the text label */
  textClass: PropTypes.string,
  /** Whether or not this icon-button should be filled */
  filled: PropTypes.bool,
  /** The function to run when the component is clicked */
  onClick: PropTypes.func,
  /** The width to use for this icon (Defaults to '25px') */
  width: PropTypes.string,
  /** The height to use for this icon (Defaults to '25px') */
  height: PropTypes.string,
  /** The size to use for the font of this icon (Defaults to '10px') */
  fontSize: PropTypes.string,
  /** An extra className to give the icon button */
  className: PropTypes.string,
  /** whether or not the subtitle should be multiple lines */
  noBreakWords: PropTypes.bool,
  ariaLabel: PropTypes.string,
  subtitleColor: PropTypes.string,
  subtitleWeight: PropTypes.string,
  textShadow: PropTypes.string,
};

const defaultProps = {
  children: null,
  text: null,
  textPosition: 'bottom',
  filled: false,
  onClick: null,
  width: null,
  height: null,
  fontSize: null,
  className: null,
  textClass: null,
  noBreakWords: false,
};

const IconButton = (props) => {
  const {
    onClick,
    children,
    text,
    textPosition,
    filled,
    className,
    width,
    height,
    fontSize,
    textClass,
    noBreakWords,
    ariaLabel,
    subtitleColor,
    subtitleWeight,
    textShadow,
    ...other
  } = props;

  const iconStyles = {};

  if (width) {
    iconStyles.minWidth = width;
    iconStyles.maxWidth = width;
  }

  if (fontSize) {
    iconStyles.fontSize = fontSize;
  }

  if (height) {
    iconStyles.height = height;
  }

  const subtitleStyles = {
    color: subtitleColor,
    fontWeight: subtitleWeight,
  };

  return (
    <>
      <div className={className} {...other}>
        <div
          onClick={onClick}
          className={mergeClassNames(
            styles.iconButton,
            filled ? styles.filled : null,
            textPosition === 'bottom' ? 'flex-column' : styles.textRight,
            'd-flex align-items-center'
          )}
          style={iconStyles}
          role="button"
          aria-label={ariaLabel}
          tabIndex={0}
        >
          {children}
          {text !== null && (
            <div
              className={mergeClassNames(styles.subtitle, textClass, noBreakWords ? null : styles.stacked)}
              style={subtitleStyles}
            >
              <span style={textShadow ? { textShadow } : null}>{text}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

IconButton.propTypes = propTypes;

IconButton.defaultProps = defaultProps;

export default IconButton;
