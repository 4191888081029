import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import VBLink from '../../VBLink/VBLink';
import VBHeading from '../../VBHeading/VBHeading';
import EllipsisDropdown from '../../EllipsisDropdown/EllipsisDropdown';
import { mergeClassNames } from '../../../util/props';
import { ExploreEllipsisPropType } from '../../../util/types';
import { mapEllipsisArray } from '../../../util/explore';
import { addReferrals } from '../../../util/urls';
import Description from './Description/Description';
import VBSpinner from '../../VBSpinner/VBSpinner';
// import RecentPost from './RecentPost/RecentPost';

import styles from './About.module.scss';

const propTypes = {
  /** name of the city */
  name: PropTypes.string,

  /** the collections to render in the blurb, see vb_search_query_collections */
  collections: PropTypes.array,

  /** whether or not collections are loading */
  areCollectionsLoading: PropTypes.bool,

  /** map icon */
  mapPreview: PropTypes.node,

  /** full map ro render on desktop */
  mapFull: PropTypes.node,

  /** array of nodes to put in the ellipsis */
  ellipsis: ExploreEllipsisPropType,

  /** minimum container height as a CSS string */
  minHeight: PropTypes.string,

  /** location details */
  locationDetails: PropTypes.object,

  /** section to show in the bottom right of the about section */
  userBeenThere: PropTypes.node,

  /** whether to hide map or not */
  hideMap: PropTypes.bool,

  /** Additional content */
  content: PropTypes.node,

  /** Bottom content */
  bottomContent: PropTypes.node,

  noBottomMargin: PropTypes.bool,

  hideAbout: PropTypes.bool,
};

const defaultProps = {
  name: undefined,
  description: undefined,
  link: undefined,
  collections: undefined,
  mapPreview: undefined,
  mapFull: undefined,
  ellipsis: undefined,
  minHeight: undefined,
  areCollectionsLoading: false,
  hideMap: false,
  content: undefined,
  bottomContent: undefined,
  noBottomMargin: false,
  hideAbout: false,
};

/**
 * The about blurb on the explore page.
 *
 * @param {object} props
 */
const About = ({
  name,
  link,
  mapPreview,
  mapFull,
  ellipsis,
  minHeight,
  locationDetails,
  hideMap,
  content,
  bottomContent,
  noBottomMargin,
  hideAbout,
}) => {
  const { lt1280: tabletStyle, lteXs: mobileStyle } = useVBBreakpoint();

  const history = useHistory();

  const titleContent = useMemo(
    () => (
      <VBHeading color="white" className={styles.heading}>
        {name}
      </VBHeading>
    ),
    [name]
  );

  const description = locationDetails?.intro ?? locationDetails?.description;

  return (
    <div
      className={mergeClassNames(styles.outer, mobileStyle && styles.mobile, tabletStyle && styles.tablet)}
      style={{ minHeight }}
    >
      {!hideAbout && (
        <div
          className={mergeClassNames(
            styles.aboutContainer,
            !tabletStyle && styles.noTopMargin,
            noBottomMargin && styles.noBottomMargin
          )}
        >
          <div className={styles.top}>
            {link ? (
              <VBLink to={addReferrals(link)} className={styles.topLink} noStyle newTab>
                {titleContent}
              </VBLink>
            ) : (
              <span className={styles.topLink}>{titleContent}</span>
            )}
            {tabletStyle && mapPreview}
            {mobileStyle && Boolean(ellipsis?.length) && (
              <EllipsisDropdown items={mapEllipsisArray(ellipsis)} color="white" closeOnSelect size="med" />
            )}
          </div>
          {!locationDetails ? (
            <div className={styles.spinner}>
              <VBSpinner color="white" center />
            </div>
          ) : description ? (
            <Description description={description} matches={locationDetails.matches} />
          ) : null}
          {content}
        </div>
      )}
      {!tabletStyle && !hideMap && Boolean(mapFull) && (
        <div className={mergeClassNames(styles.mapContainer, hideAbout && styles.hideAbout)}>{mapFull}</div>
      )}
      {!tabletStyle && Boolean(ellipsis?.length) && (
        <div className={styles.buttonContainer}>{mapEllipsisArray(ellipsis, true, history)}</div>
      )}
      {bottomContent}
    </div>
  );
};

About.propTypes = propTypes;

About.defaultProps = defaultProps;

export default About;
