import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import styles from './VBReactProvider.module.scss';
import { PropTypesVBBool, PropTypesVBUser } from '../../util/types';
import { makeStyleShortcuts, setGlobalCSSVars } from '../../util/colors';
import {
  errorToast as sharedErrorToast,
  pointsToast as sharedPointsToast,
  raiseToast as sharedRaiseToast,
} from '../../util/toast';

export const VBReactContext = React.createContext();

const propTypes = {
  cssVariables: PropTypes.object,
  routerLinks: PropTypesVBBool,
  useRootStyles: PropTypesVBBool,
  children: PropTypes.any,
  /** See NewBuff branding config file for example. */
  brandingConfig: PropTypes.object,
  vbRequest: PropTypes.func,
  openAuthPopup: PropTypes.func,
  logout: PropTypes.func,
  currentUser: PropTypesVBUser,
  components: PropTypes.shape({
    IconButtonSaveVenue: PropTypes.elementType,
    IconButtonBucket: PropTypes.elementType,
    SearchBar: PropTypes.elementType,
    IconButtonBeenThere: PropTypes.elementType,
  }),
  useHeaderHeight: PropTypes.func,
  headerHeightSelector: PropTypes.string,
  openMakePostPopup: PropTypes.func,
};

const defaultProps = {
  cssVariables: null,
  useRootStyles: true,
  children: null,
  brandingConfig: null,
  routerLinks: false,
  vbRequest: undefined,
  currentUser: null,
  openAuthPopup: undefined,
  logout: undefined,
  useHeaderHeight: undefined,
  headerHeightSelector: undefined,
  components: undefined,
};

const VBReactProvider = ({
  children,
  cssVariables,
  routerLinks,
  useRootStyles,
  brandingConfig,
  vbRequest,
  openAuthPopup,
  logout,
  currentUser,
  components,
  useHeaderHeight,
  headerHeightSelector,
  openMakePostPopup,
}) => {
  // Create CSS variables from props.
  let cssVars;
  if (cssVariables) {
    cssVars = JSON.stringify(cssVariables);
  }
  useEffect(() => {
    if (cssVars) {
      setGlobalCSSVars(JSON.parse(cssVars));
    }
  }, [cssVars]);

  // Assign class to root element.
  const { lteMd, lteSm } = useVBBreakpoint();
  useEffect(() => {
    if (useRootStyles) {
      const classes = document.documentElement.classList;
      classes.add(styles.root);
      classes.remove(styles.sm, styles.md);
      if (lteSm) {
        classes.add(styles.sm);
      } else if (lteMd) {
        classes.add(styles.md);
      }
    }
  }, [lteMd, lteSm, useRootStyles]);

  let styleShortcuts = {};
  // Using if statements so that older code in VB that doesn't use cssVariables argument yet
  // doesn't break.
  if (cssVariables) {
    styleShortcuts = makeStyleShortcuts(cssVariables);
  }

  const toastFunctions = {
    raiseToast: (...args) => sharedRaiseToast(brandingConfig, ...args),
    pointsToast: (...args) => sharedPointsToast(brandingConfig, ...args),
    errorToast: (...args) => sharedErrorToast(brandingConfig, ...args),
  };

  return (
    <VBReactContext.Provider
      value={{
        ...styleShortcuts,
        routerLinks,
        cssVariables,
        brandingConfig,
        vbRequest,
        openAuthPopup,
        logout,
        currentUser,
        components,
        useHeaderHeight,
        headerHeightSelector,
        openMakePostPopup,
        ...toastFunctions,
      }}
    >
      <HelmetProvider context={{}}>{children}</HelmetProvider>
    </VBReactContext.Provider>
  );
};

VBReactProvider.propTypes = propTypes;

VBReactProvider.defaultProps = defaultProps;

export default VBReactProvider;
