import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styles from './VBInputLabel.module.scss';
import { mergeClassNames } from '../../util/props';

const propTypes = {
  /** same as children, both will be rendered if both are provided */
  content: PropTypes.node,

  /** additional class names */
  className: PropTypes.string,

  /** whether or not to show that the field is required */
  required: PropTypes.bool,

  children: PropTypes.node,

  /** whether to disable the default margins */
  noMargin: PropTypes.bool,

  /** The for on the label element. */
  labelFor: PropTypes.string,
};

const defaultProps = {
  content: undefined,
  className: undefined,
  children: undefined,
  required: undefined,
  noMargin: false,
  labelFor: undefined,
};

/**
 * Label to use above inputs.
 * @param {object} props
 */
const VBInputLabel = ({ content, children, className, required, noMargin, labelFor }) => {
  return (
    <Form.Label
      htmlFor={labelFor}
      className={mergeClassNames(styles.label, noMargin ? styles.noMargin : null, className)}
    >
      {content}
      {children}
      {required ? <span className={styles.required}>(Required)</span> : null}
    </Form.Label>
  );
};
export default VBInputLabel;

VBInputLabel.propTypes = propTypes;
VBInputLabel.defaultProps = defaultProps;
