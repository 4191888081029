import React from 'react';
import PropTypes from 'prop-types';
import styles from './VBDropdownItem.module.scss';
import { mergeClassNames } from '../../util/props';
import { useCloseDropdown } from '../../hooks/dropdown';

/**
 * An item that can be listed in a dropdown.
 *
 * @param {element} children Content to display in the item
 * @param {func} onClick A function to run when this item is clicked
 * @param {bool} big     Makes this dropdown item bigger than normal
 * @param {bool} red     Makes this dropdown item red
 * @param {string} className additional class names
 * @param {bool} closeOnSelect whether or not to close the dropdown when an item is clicked
 */
const VBDropdownItem = (props) => {
  const { onClick, big, red, className, closeOnSelect, ...other } = props;

  const close = useCloseDropdown();

  const onClickWrapper = (e) => {
    if (closeOnSelect) close();
    if (onClick) onClick(e);
  };

  return (
    <div
      className={mergeClassNames(styles.item, big && styles.big, red && styles.red, className)}
      onClick={onClickWrapper}
      {...other}
      role="button"
      tabIndex="0"
    >
      {props.children}
    </div>
  );
};

VBDropdownItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  big: PropTypes.bool,
  red: PropTypes.bool,
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool,
};

VBDropdownItem.defaultProps = {
  children: undefined,
  onClick: undefined,
  big: false,
  red: false,
  className: undefined,
  closeOnSelect: false,
};

export default VBDropdownItem;
