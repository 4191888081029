import React from 'react';
import PropTypes from 'prop-types';
import styles from './HikeMeta.module.scss';
import { uppercaseFirst } from '../../../util/string';
import { getHikeDifficultyString } from '../../../util/venue';
import Dot from '../../Dot/Dot';
import { secondsToTimeObject } from '../../../util/time';
import { formatHours } from '../../../util/itineraries';

const propTypes = {
  /** hike distance in miles */
  distance: PropTypes.number.isRequired,

  /** hike difficulty, 1 - 3 */
  difficulty: PropTypes.oneOf([1, 2, 3]).isRequired,

  /** hike time in s */
  time: PropTypes.number.isRequired,

  /** whether or not the hike is paved */
  paved: PropTypes.bool.isRequired,
};

/**
 * Shows hike metadata. Used in VenueMeta.
 */
const HikeMeta = ({ distance, difficulty, time, paved }) => {
  return (
    <div className={styles.container}>
      <span>{uppercaseFirst(getHikeDifficultyString(difficulty))} Hike</span>
      <Dot />
      <span>{`${distance}mi`}</span>
      <Dot />
      <span>{`${formatHours(time / 60 / 60)}h`}</span>
      {!!paved && (
        <>
          <Dot />
          <span>Paved</span>
        </>
      )}
    </div>
  );
};

export default HikeMeta;

HikeMeta.propTypes = propTypes;
