import React from 'react';
import PropTypes from 'prop-types';
import PopupConfirm from '../PopupConfirm/PopupConfirm';

const propTypes = {
  /** callback for when the deletion is either deleted or cancelled (takes the resolution is the argument) */
  onResolve: PropTypes.func.isRequired,

  /** name of the content to delete */
  contentName: PropTypes.string.isRequired,

  /** whether to leave the popup open if the deletion is cancelled */
  leaveOpenOnCancel: PropTypes.bool,
};

const defaultProps = {
  leaveOpenOnCancel: false,
};

/**
 * General purpose popup to confirm deletion of a resource.
 *
 * @param {object} props
 */
const PopupDeleteConfirm = ({ contentName, ...other }) => {
  return <PopupConfirm prompt={`Are you sure you want to delete ${contentName}?`} isDeletion {...other} />;
};
export default PopupDeleteConfirm;

PopupDeleteConfirm.propTypes = propTypes;
PopupDeleteConfirm.defaultProps = defaultProps;
