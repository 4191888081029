/* eslint-disable react/require-default-props */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './VBDropdownTrigger.module.scss';
import { mergeClassNames } from '../../util/props';

/**
 * Component representing a dropdown trigger. Used by useDropdown.
 *
 * @param {string} props.className additional classNames for the trigger
 * @param {(SyntheticEvent: e): void} props.onClick callback for when the trigger is clicked
 */
const VBDropdownTrigger = forwardRef((props, ref) => {
  const { children, onClick, className, ...other } = props;
  return (
    <span
      ref={ref}
      onClick={(e) => {
        onClick(e);
      }}
      {...other}
      className={mergeClassNames(styles.trigger, className)}
    >
      {children}
    </span>
  );
});
VBDropdownTrigger.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default VBDropdownTrigger;
