import React, { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer/polyfilled';
import Distance from '../Distance/Distance';
import { boolProp, mergeClassNames } from '../../util/props';
import { PropTypesVBBool, PropTypesRefProp } from '../../util/types';
import VBLink from '../VBLink/VBLink';
import ItineraryTripInfo from '../ItineraryTripInfo/ItineraryTripInfo';
import CovidLowRisk from '../CovidLowRisk/CovidLowRisk';
import VBHeading from '../VBHeading/VBHeading';
import useVBBreakpoint from '../../hooks/vb-breakpoint';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './Meta.module.scss';

/** how many pixels must the meta shrink to before the buttons go vertical */
export const HORIZONTAL_ACTION_BUTTONS_CUTOFF = 450;

/**
 * Meta information. Used in cards and on venue details pages.
 *
 * @param {array} actionButtons The buttons to display to the right side.
 * @param {string} title        The title to use on the card (displayed under the image)
 * @param {string} permalink    Link that is used for title and image
 * @param {number} distance     The distance to display next to the title
 * @param {string} direction    The direction to display next to the title
 * @param {number} days         The number of days of the trip
 * @param {number} tripscore    The tripscore of the trip
 * @param {string|array} coords The coordinates of the location. For example, "32.7807, -79.7984" or: [32.7807, -79.7984]. Used for google maps link
 * @param {jsx} meta            Another layer of meta information that can be appended to the bottom. (For example, VenueMeta).
 * @param {string} titleSize    'sm', 'med' or 'lg'
 * @param {string} className    Addition class names
 * @param {node} badge          the badge to show next to the title
 *
 * --- Boolean Settings: ---          Default values:
 * @param {boolean} padded               = False adds padding around the meta component
 * @param {boolean} covidLowRisk         = False whether to show the covid low risk logo
 * @param {boolean} forceVerticalButtons = False force vertical action buttons
 * @param {boolean} nearMe               = False display the distance with "From me" appended
 * @param {boolean} allowMultiLineTitle  = False allows title to overflow onto multiple lines
 * @param {boolean} isMetaAlignedTop     = False center the action buttons vertically or align the to the start
 * @param {boolean} overridePositioning  = False whether or not to override the action buttons with absolute positioning
 * @param {boolean} titleH3Seo           = False whether or not to put the title in an h3 (for SEO purposes, has no effect on style)
 * @param {boolean} hideTitle            = False whether or not to hide the title
 * @param {boolean} hideActionButtons    = False whether or not to hide the action buttons
 * @param {boolean} isLoading            = False whether to show skeleton view or not
 */
const Meta = (props) => {
  const covidLowRisk = boolProp(props.covidLowRisk);
  const forceVerticalButtons = boolProp(props.forceVerticalButtons);
  const {
    titleSize,
    days,
    tripscore,
    permalink,
    actionButtons,
    title,
    distance,
    direction,
    nearMe,
    coords,
    className,
    padded,
    meta,
    badge,
    ranking,
    metaMaxWidthContainer,
    isMetaAlignedTop,
    metaRef: parentMetaRef,
    actionButtonsAfterTitle,
    style,
    overridePositioning,
    isVideo,
    titleH3Seo,
    isDetailsStyle,
    hideTitle,
    hideActionButtons,
    isLoading,
  } = props;
  const hasDays = typeof days !== 'undefined';
  const hasTripscore = typeof tripscore !== 'undefined';

  const [xxsStyling, setXxsStyling] = useState(forceVerticalButtons);
  const generatedMetaRef = useRef();
  const metaRef = parentMetaRef ?? generatedMetaRef;

  useResizeObserver({
    ref: metaRef,
    onResize: ({ width }) => {
      // not mounted
      if (!width) return;

      if (forceVerticalButtons) return;

      // compare width to HORIZONTAL_ACTION_BUTTONS_CUTOFF, optionally update state
      if (width < HORIZONTAL_ACTION_BUTTONS_CUTOFF) {
        setXxsStyling(true);
      } else {
        setXxsStyling(false);
      }
    },
  });

  const actionButtonsEl = useRef(null);
  let buttons = [];
  // If action buttons were provided
  if (actionButtons) {
    buttons = actionButtons.map((element, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className={mergeClassNames(xxsStyling.button_class, styles.actionButton)} key={key}>
        {element}
      </div>
    ));
  }

  // If no image is shown, add the edit button to the action buttons
  if (props.editButton) {
    buttons.push(
      <div className={mergeClassNames(xxsStyling.button_class, styles.actionButton)} key={buttons.length}>
        {props.editButton}
      </div>
    );
  }

  const medTitle = (
    <VBHeading
      size="md"
      as="h1"
      className={mergeClassNames(styles.title, styles.titleBig, isVideo ? styles.titleVideo : null)}
    >
      {title}
    </VBHeading>
  );

  const { lteMd } = useVBBreakpoint();

  let smTitle = title;
  if (isDetailsStyle) {
    smTitle = (
      <VBHeading size="sm" as="h1" className={mergeClassNames(styles.smallDetailsStyle, lteMd ? styles.block : null)}>
        {title}
      </VBHeading>
    );
  } else if (permalink) {
    smTitle = (
      <VBLink to={permalink} aria-label={title}>
        {title}
      </VBLink>
    );
  }

  const titleContainer =
    titleSize === 'med' ? (
      permalink ? (
        <VBLink to={permalink} aria-label={title}>
          {medTitle}
        </VBLink>
      ) : (
        medTitle
      )
    ) : (
      <span className={mergeClassNames(styles.title, styles.titleSmall, isVideo ? styles.titleVideo : null)}>
        {titleH3Seo ? (
          <h3 className={mergeClassNames(styles.noStyleHeader, isVideo ? styles.video : null)}>{smTitle}</h3>
        ) : (
          smTitle
        )}
        {covidLowRisk ? <CovidLowRisk className={styles.lowRisk} /> : null}
        {isDetailsStyle ? null : <span style={{ marginLeft: '0.6rem' }} />}
        {badge}
      </span>
    );

  const hasButtons = Boolean(buttons?.length);

  const actionButtonsContainerSmall = hasButtons && !hideActionButtons && (
    <div
      className={mergeClassNames(
        'd-flex',
        'flex-column',
        'justify-start',
        'align-items-center',
        'position-relative',
        styles.actionButtons,
        styles.small,
        actionButtonsAfterTitle ? styles.afterTitle : null,
        overridePositioning ? styles.overridePositioning : null
      )}
      ref={actionButtonsEl}
    >
      {buttons}
    </div>
  );

  const actionButtonsContainerBig = hasButtons && !hideActionButtons && (
    <div
      className={mergeClassNames(
        'd-flex',
        'flex-row',
        'justify-start',
        styles.actionButtons,
        styles.spaceButtons,
        actionButtonsAfterTitle ? styles.afterTitle : null,
        overridePositioning ? styles.overridePositioning : null
      )}
      ref={actionButtonsEl}
    >
      {buttons}
    </div>
  );

  const actionButtonsWidth = actionButtonsEl?.current
    ? Math.round(actionButtonsEl.current.getBoundingClientRect().width)
    : 0;
  const details = !hideTitle && (
    <div className={styles.titleContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.topContainer}>
          {typeof ranking === 'number' && metaMaxWidthContainer ? (
            <div className={styles.ranking}>{ranking}</div>
          ) : null}
          {isVideo ? null : isLoading ? <Skeleton width="120px" /> : titleContainer}
          {titleSize === 'med' ? (
            <>
              {covidLowRisk ? <CovidLowRisk className={styles.lowRisk} /> : null}
              <span style={{ marginLeft: '0.6rem' }} />
              {badge}
            </>
          ) : null}
          {distance !== undefined && (
            <>
              <span style={{ paddingLeft: '0.6rem' }} />
              <Distance
                destination={coords || title}
                distance={distance}
                direction={direction}
                fontSize="0.86rem"
                nearMe={nearMe}
              />
            </>
          )}
        </div>
        {!xxsStyling && !actionButtonsAfterTitle ? (
          <div
            style={{
              width: `${actionButtonsWidth}px`,
              minWidth: `${actionButtonsWidth}px`,
            }}
          />
        ) : null}
        {!isLoading && !!actionButtonsAfterTitle && actionButtonsContainerBig}
      </div>
      {hasDays || hasTripscore ? (
        <div>
          <ItineraryTripInfo days={days} tripscore={tripscore} />
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <div
        className={mergeClassNames(
          className,
          styles.metaContainer,
          boolProp(padded) ? styles.metaContainerPadded : null
        )}
        ref={metaRef}
        style={style}
      >
        {xxsStyling ? (
          <div
            className={mergeClassNames(
              'd-flex',
              'flex-row',
              isMetaAlignedTop ? 'align-items-start' : 'align-items-center'
            )}
          >
            <div className="d-flex flex-column" style={!actionButtonsAfterTitle ? { flexGrow: '1' } : null}>
              <div>{details}</div>
              {meta ?? null}
            </div>
            {!isLoading && !actionButtonsAfterTitle && actionButtonsContainerSmall}
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex flex-row position-relative">
              <div style={{ flexGrow: 1 }}>{details}</div>
              {!isLoading && !actionButtonsAfterTitle && actionButtonsContainerBig}
            </div>
            {meta ?? null}
          </div>
        )}
      </div>
    </>
  );
};

/* eslint-disable react/require-default-props */
Meta.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.string,
  permalink: PropTypes.string,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  coords: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  meta: PropTypes.element,
  padded: PropTypesVBBool,
  titleSize: PropTypes.oneOf(['sm', 'med']),
  covidLowRisk: PropTypesVBBool,
  className: PropTypes.string,
  forceVerticalButtons: PropTypesVBBool,
  nearMe: PropTypes.bool,
  days: PropTypes.number,
  tripscore: PropTypes.number,
  direction: PropTypes.string,
  badge: PropTypes.node,
  ranking: PropTypes.number,
  metaMaxWidthContainer: PropTypes.bool,
  isMetaAlignedTop: PropTypes.bool,
  /** optional ref for the meta container */
  metaRef: PropTypesRefProp,

  /** whether or not the action buttons should be directly after the title */
  actionButtonsAfterTitle: PropTypes.bool,

  style: PropTypes.object,
  /** Edit button for if an image is not shown */
  editButton: PropTypes.element,

  isDetailsStyle: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
};

Meta.defaultProps = {
  forceVerticalButtons: false,
  nearMe: false,
  isMetaAlignedTop: false,
  titleSize: 'sm',
  hideTitle: false,
  hideActionButtons: false,
  isLoading: false,
};

export default Meta;
