import { useCallback } from 'react';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import useContentSingle from '../../../../../shared-react/src/hooks/content-single';
import APIConfig from '../../../../../shared-react/src/config/api';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import Article from '../Article/Article';

const PopupArticle = ({ id, locationDetails }) => {
  const { content, loading } = useContentSingle({
    id,
    type: ContentTypes.article.type,
    getQueryAddress: useCallback(() => `${APIConfig.NAMESPACE.ARTICLE}/${id}`, [id]),
  });

  if (loading) {
    return (
      <center>
        <VBSpinner />
      </center>
    );
  }

  return <Article article={content} locationDetails={locationDetails} />;
};

export default PopupArticle;
