/**
 * Util functions for making requests to the server detailing with relationships between users.
 */

import APIConfig from '../../../shared-react/src/config/api';
import { vbRequest } from './api';

/**
 * Submit a request to the server to follow a user.
 * @param {number} userId the ID of the user to follow
 * @returns {Promise} the request
 */
export const requestFollow = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/follow/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to unfollow a user.
 * @param {number} userId the ID of the user to unfollow
 * @returns {Promise} the request
 */
export const requestUnfollow = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/unfollow/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to block a user.
 * @param {number} userId the ID of the user to block
 * @returns {Promise} the request
 */
export const requestBlock = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/block/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to unblock a user.
 * @param {number} userId the ID of the user to unblock
 * @returns {Promise} the request
 */
export const requestUnblock = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/unblock/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to withdraw a follow request from a user.
 * @param {number} userId the ID of the user to withdraw a follow request from
 * @returns {Promise} the request
 */
export const requestWithdraw = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/request/withdraw/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to accept a user's follow request.
 * @param {number} userId the ID of the user to accept a user's follow request
 * @returns {Promise} the request
 */
export const requestAccept = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/request/accept/${userId}`, { method: 'POST' });
};

/**
 * Submit a request to the server to reject a user's follow request.
 * @param {number} userId the ID of the user to reject a user's follow request
 * @returns {Promise} the request
 */
export const requestReject = (userId) => {
  return vbRequest(`${APIConfig.NAMESPACE.SOCIAL}/relationships/reject/${userId}`, { method: 'POST' });
};
