import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ExploreCard from '../../../../../shared-react/src/components/ExploreCard/ExploreCard';
import PopupDeleteConfirm from '../../../../../shared-react/src/components/PopupDeleteConfirm/PopupDeleteConfirm';
import PopupUpdateArticle from './PopupUpdateArticle/PopupUpdateArticle';
import { removeItem } from '../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../shared-react/src/config/api';
import usePopup from '../../../hooks/open-auth-popup';
import { errorToast, raiseToast } from '../../../util/toast';
import { vbRequest } from '../../../util/api';
import SocialActions from '../SocialActions/SocialActions';

import styles from './Article.module.scss';

const Article = ({ article, locationDetails }) => {
  const dispatch = useDispatch();

  const deleteArticle = useCallback(
    (confirmed) => {
      if (!confirmed) {
        return;
      }

      dispatch(removeItem(ContentTypes.article.type, article.id));

      vbRequest(`${APIConfig.NAMESPACE.ARTICLE}/${article.id}`, { method: 'DELETE' })
        .then(() => raiseToast('Successfully deleted!', 'Success'))
        .catch((error) => errorToast(error.message, 'Error'));
    },
    [article, dispatch]
  );

  const { modal: editArticleModal, open: openEditArticleModal } = usePopup({
    child: <PopupUpdateArticle article={article} locationDetails={locationDetails} />,
    size: 'lg',
  });

  const { modal: deleteConfirmModal, open: openDeleteConfirmModal } = usePopup({
    child: <PopupDeleteConfirm contentName="this article" onResolve={deleteArticle} />,
  });

  return (
    <div id={article.slug}>
      <ExploreCard
        name={article.heading}
        by={article.author}
        description={article.content}
        titleClassName={styles.title}
        numberOfLines={null}
        markdown
      />
      <SocialActions
        entityId={article.id}
        entityType="article"
        liked={article.userLiked}
        commentsTotal={article.comments}
        shareType="Article"
        username={article.author.username}
        userLink={article.author.permalink}
        onEdit={openEditArticleModal}
        onDelete={openDeleteConfirmModal}
      />
      {deleteConfirmModal}
      {editArticleModal}
    </div>
  );
};

export default Article;
