import { useEffect, useRef, useState } from 'react';

/**
 * Returns the current scroll direction. 'up' or 'down'
 */
export function useCurrentScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState('down');
  const lastScrollY = useRef(window.scrollY);

  useEffect(() => {
    const onWindowScroll = () => {
      const curScrollY = window.scrollY;
      const delta = curScrollY - lastScrollY.current;
      lastScrollY.current = curScrollY;

      if (delta > 0) {
        setScrollDirection('down');
      } else if (delta < 0) {
        setScrollDirection('up');
      }
    };
    onWindowScroll();
    window.addEventListener('scroll', onWindowScroll);
    return () => window.removeEventListener('scroll', onWindowScroll);
  }, []);

  return scrollDirection;
}
