import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { boolProp, mergeClassNames } from '../../util/props';
import Avatar from '../Avatar/Avatar';
import { PropTypesVBBool, PropTypesVBUser, PropTypesVBUserListingSize } from '../../util/types';
import VBLink from '../VBLink/VBLink';
import ImageAnonAvatar from '../../../assets/images/icons/users/anon-avatar.webp';

import styles from './UserListing.module.scss';

import 'react-loading-skeleton/dist/skeleton.css';

/**
 * Listing information on a user. Shows their name, avatar, and an optional subtitle.
 *
 * @param {object} props.user the user object
 * @param {node} props.subtitle the subtitle to include under the name
 * @param {boolean} props.showUsername whether to use the username instead of the the full name
 * @param {boolean} props.noLink whether or not to have the entries link to user profiles
 * @param {string} props.avatarSize the size of the avatar as a CSS string
 * @param {boolean} props.hideAvatar whether or not to hide the avatar
 * @param {string} props.nameClassName additional classNames for the name element
 * @param {string} props.size the size of the listing, sm or lg
 * @param {boolean} props.isLoading skeleton state
 */
const UserListing = (props) => {
  const {
    user,
    subtitle,
    className,
    showUsername,
    noLink,
    size,
    hideAvatar,
    nameClassName,
    avatarSize,
    subtitleClassName,
    isLoading,
  } = props;

  const bNoLink = boolProp(noLink);
  const bShowUsername = boolProp(showUsername);
  const bHideAvatar = boolProp(hideAvatar);

  let nameText = 'Anonymous User';

  if (bShowUsername) {
    if (user?.username) {
      nameText = user.username;
    }
  } else if (user?.fullName) {
    nameText = user.fullName;
  }

  const name = <span className={mergeClassNames(nameClassName, styles.name)}>{nameText}</span>;

  return (
    <div className={mergeClassNames(className, styles.container, styles[size])}>
      {!bHideAvatar ? (
        <div className={styles.left}>
          {isLoading ? (
            <div className={styles.avatar} style={{ borderRadius: '50%', overflow: 'hidden' }}>
              <Skeleton containerClassName={styles.avatarSkeleton} height="100%" />
            </div>
          ) : (
            <Avatar
              src={user?.avatar ?? ImageAnonAvatar}
              href={bNoLink || !user?.permalink ? null : user.permalink}
              userId={user?.id}
              className={styles.avatar}
              size={avatarSize}
            />
          )}
        </div>
      ) : null}
      <div className={styles.right}>
        {isLoading ? (
          <Skeleton width="115px" />
        ) : bNoLink || !user?.permalink ? (
          name
        ) : (
          <div>
            <VBLink className={styles.link} to={user.permalink}>
              {name}
            </VBLink>
          </div>
        )}
        {subtitle ? <div className={mergeClassNames(styles.subtitle, subtitleClassName)}>{subtitle}</div> : null}
      </div>
    </div>
  );
};
export default UserListing;

UserListing.propTypes = {
  user: PropTypesVBUser,
  subtitle: PropTypes.node,
  subtitleClassName: PropTypes.string,
  className: PropTypes.string,
  showUsername: PropTypesVBBool,
  noLink: PropTypesVBBool,
  size: PropTypesVBUserListingSize.isRequired,
  hideAvatar: PropTypesVBBool,
  nameClassName: PropTypes.string,
  avatarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
};

UserListing.defaultProps = {
  user: undefined,
  subtitle: undefined,
  subtitleClassName: null,
  className: undefined,
  showUsername: false,
  noLink: false,
  hideAvatar: false,
  nameClassName: undefined,
  avatarSize: undefined,
  isLoading: false,
};
