import QueryString from 'query-string';
import { isMobile } from './platforms';

/**
 * Gets a share link given a platform and some information on what is being shared.
 *
 * @param {string} platform the platform, see vb/v1/social/invite-link/(?P<platform>[\S]+)
 * @param {string} link link to put in the share
 * @param {string} subject subject of the share (currently only used for email)
 * @param {string} hashtag hashtag (without the pound sign) to use for the share
 * @param {string} description text content of the share
 *
 * @returns {string} the share link
 */
export const getShareLink = (platform, link, subject, hashtag, description) => {
  switch (platform) {
    case 'twitter':
      // https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
      return `https://twitter.com/intent/tweet?${QueryString.stringify({
        text: description,
        url: link,
        hashtags: hashtag,
      })}`;
    case 'pinterest':
      // https://stackoverflow.com/questions/11312923/custom-pinterest-button-for-custom-url-text-link-image-or-both
      return `https://www.pinterest.com/pin/create/button?${QueryString.stringify({
        media: 'https://viewbuff.com/wp-content/themes/VB_theme/assets/images/og-default.jpeg',
        url: link,
      })}`;
    case 'facebook':
      // https://developers.facebook.com/docs/sharing/reference/share-dialog
      return `https://www.facebook.com/dialog/share?${QueryString.stringify({
        app_id: 221895199772769,
        display: 'popup',
        href: link,
        hashtag,
        quote: description,
        redirect_url: window.location.origin,
      })}`;
    case 'facebook-messenger':
      // https://developers.facebook.com/docs/sharing/reference/share-dialog
      if (isMobile())
        return `fb-messenger://share/?${QueryString.stringify({
          app_id: 221895199772769,
          link,
          redirect_url: window.location.origin,
        })}`;
      return `http://www.facebook.com/dialog/send?${QueryString.stringify({
        app_id: 221895199772769,
        display: 'popup',
        link,
        redirect_url: window.location.origin,
      })}`;
    case 'email':
      return `mailto:?${QueryString.stringify({
        body: `${description}\n\n${link}`,
        subject,
      })}`;
    case 'sms':
      return `sms:?&${QueryString.stringify({
        body: `${description} ${link}`,
      })}`;
    default:
  }
  throw new Error(`invalid platform: ${platform}`);
};
