/* eslint-disable default-case */
import SITES from '../../../config-json/sites.json';
import { VB_SITE_ITINERARIES, VB_SITE_KIDS, VB_SITE_VIEWBUFF } from '../../config/sites';
import TripfoolHeaderBuilder from './site-specific/tripfool-header-builder';
import ViewBuffHeaderBuilder from './site-specific/viewbuff-header-builder';
import ZuzuHeaderBuilder from './site-specific/zuzu-header-builder';

export function getHeaderBuilderClass(siteId = VB_SITE) {
  switch (SITES[siteId]) {
    case VB_SITE_ITINERARIES:
      return TripfoolHeaderBuilder;
    case VB_SITE_VIEWBUFF:
      return ViewBuffHeaderBuilder;
    case VB_SITE_KIDS:
      return ZuzuHeaderBuilder;
  }
}

/** I originally had this export the class directly. The problem arises when trying to change the Header
 * class used in the Jest environment. Jest will hard set the HeaderBuilder class at compile time and it won't be
 * changeable at runtime. This is fine though, because theoretically I think this file will only be imported in the
 * Header component, so it's one extra line of code.
 */
export default getHeaderBuilderClass;
