import { useEffect } from 'react';

/**
 * This is a hook which will watch a component and will notify when the user
 * is about to be put on the screen. (Really you can configure the value to do whatever,
 * you can make it trigger when 50px are already on the screen.)
 * @param {React.Ref} ref A React ref to the element you want to watch the location of getting close to.
 * @param {function} nearRefHandler When the user scrolls near the ref this is the function that will get triggered.
 * @param {number} pixelTrigger How many pixels below the screen the ref should
 * be within for the handler to trigger. Negative values would result in how
 * many pixels of the element need to be on the screen for it to trigger.
 */
const useBottomWatcher = (ref, nearRefHandler, pixelTrigger = 1600, scrollContainer = window) => {
  useEffect(() => {
    const onScroll = () => {
      if (!ref?.current) return;

      if (ref.current.getBoundingClientRect().y < window.innerHeight + pixelTrigger) {
        nearRefHandler();
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll);

      return () => scrollContainer.removeEventListener('scroll', onScroll);
    }
  }, [nearRefHandler, pixelTrigger, ref, scrollContainer]);
};

export default useBottomWatcher;
