const getOffsetTop = (element) => {
  return element ? element.offsetTop + getOffsetTop(element.offsetParent) : 0;
};

export const scrollVertically = (element, offset = 0, behavior = 'smooth') => {
  const elementOffset = getOffsetTop(element);
  const actualOffset = typeof offset === 'function' ? offset(elementOffset) : offset;

  window.scrollTo({ top: elementOffset - actualOffset, behavior });
};
