import React from 'react';
import PropTypes from 'prop-types';
import styles from './EventInternal.module.scss';
import NoContent from '../../../../../shared-react/src/components/NoContent/NoContent';
import useVBBreakpoint from '../../../../../shared-react/src/hooks/vb-breakpoint';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import { formatEventDate } from '../../../util/events';
import Distance from '../../../../../shared-react/src/components/Distance/Distance';
import ProgressiveImage from '../../../../../shared-react/src/components/ProgressiveImage/ProgressiveImage';
import ImageZZEvent from '../../../assets/images/events/zz-event.svg';
import VBLink from '../../../../../shared-react/src/components/VBLink/VBLink';
import RSVPButton from './RSVPButton/RSVPButton';
import { addParams } from '../../../../../shared-react/src/util/urls';

const propTypes = {
  /** event to render */
  event: PropTypes.object.isRequired,

  /** distance the user is from the event */
  distance: PropTypes.number,

  /** whether or not details page style should be used */
  isDetailsPage: PropTypes.bool,

  /** the component to use for the heading */
  heading: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
};

const defaultProps = {
  heading: 'span',
};

/**
 * Renders a ZZ event. Used on the explore page and event details page.
 */
const EventInternal = ({ event, distance, isDetailsPage, heading: Heading, exploreLink, exploreLinkText }) => {
  const { lteXs: mobileStyle, lt1280: tabletStyle } = useVBBreakpoint();

  /**
   * Render a node wrapped in the permalink, if isDetailsPage is false.
   */
  const withPermalink = (node) => {
    if (isDetailsPage) return node;
    return (
      <VBLink noStyle to={addParams(event.permalink, { exploreLink, exploreLinkText })}>
        {node}
      </VBLink>
    );
  };

  return (
    <div
      className={mergeClassNames(
        styles.container,
        isDetailsPage ? styles.detailsPage : null,
        tabletStyle && styles.tablet,
        mobileStyle && styles.mobile
      )}
    >
      {withPermalink(
        <div className={styles.imageContainer}>
          <ProgressiveImage
            fullSrc={event.featuredImageUrlFull}
            smallSrc={event.featuredImageUrlSmall}
            imageClassName={styles.image}
            alt={event.name}
          />
          <img src={ImageZZEvent} className={styles.badge} alt="Zuzu Event" />
        </div>
      )}
      <div className={styles.bottom}>
        <div className={styles.metaUpper}>
          <div className={styles.nameAndDate}>
            {withPermalink(
              <>
                <div className={styles.nameContainer}>
                  <Heading className={styles.name}>{event.name}</Heading>
                </div>
                <span className={styles.date}>{formatEventDate(event.startTime, event.endTime)}</span>
              </>
            )}
          </div>
          <RSVPButton event={event} redirectOnRsvp={isDetailsPage ? exploreLink : undefined} />
        </div>
        {withPermalink(
          <>
            <div className={styles.description}>
              {event.description?.length ? event.description : <NoContent typePlural="event description" noCenter />}
            </div>
            <div className={styles.address}>{event.address}</div>
          </>
        )}
      </div>
      <div className={styles.distance}>
        <Distance destination={[event.latitude, event.longitude]} distance={distance} nearMe />
      </div>
    </div>
  );
};

export default EventInternal;
EventInternal.propTypes = propTypes;
EventInternal.defaultProps = defaultProps;
