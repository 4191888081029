import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import APIConfig from '../config/api';
import { uppercaseFirst } from '../util/string';
import { removeItem } from '../store/actions/apiActions';
import { VBReactContext } from '../components/VBReactProvider/VBReactProvider';
import { ContentTypes } from '../config/content-types';

/**
 * Hook to report content
 *
 * @returns {(type: string, id: int): void} function to report an item
 */
export const useReport = () => {
  const { vbRequest, raiseToast } = useContext(VBReactContext);
  const dispatch = useDispatch();
  const report = useCallback(
    (type, id) => {
      // TODO: It would be a good idea in the future to automatically remove any items that reference the deleted item.
      // However, this is really the only common case on the site currently.
      if (type === ContentTypes.review.type) {
        dispatch(removeItem, ContentTypes.reviewOrSnippet, `R-${id}`);
      } else if (type === ContentTypes.snippet.type) {
        dispatch(removeItem, ContentTypes.reviewOrSnippet, `S-${id}`);
      }
      dispatch(removeItem(type, id));
      return new Promise(() => {
        vbRequest(`${APIConfig.NAMESPACE.REPORT}/${type}/${id}`, { method: 'POST' })
          .then((response) => {
            if (response.success) {
              raiseToast(`${uppercaseFirst(type)} successfully reported.`, 'Reported', 'info');
            } else {
              raiseToast(`There was an error reporting this ${type}.`, 'Error', 'warning');
              console.error(response?.success);
            }
          })
          .catch((err) => {
            raiseToast(`There was an error reporting this ${type}.`, 'Error', 'warning');
            console.error(err?.message);
          });
      });
    },
    [dispatch, raiseToast, vbRequest]
  );

  return report;
};
export default useReport;
