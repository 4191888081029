import React, { lazy, Suspense } from 'react';
import VBSpinner from '../VBSpinner/VBSpinner';

const VBCarousel = lazy(() => import('../VBCarousel/VBCarousel'));

const CarouselWrapper = (carouselProps) => (
  <Suspense fallback={<VBSpinner center />}>
    <VBCarousel {...carouselProps} />
  </Suspense>
);

export default CarouselWrapper;
