import React from 'react';
import PropTypes from 'prop-types';
import styles from './PopupConfirm.module.scss';
import { useClosePopup } from '../../hooks/popup';
import VBButton from '../VBButton/VBButton';
import VBHeading from '../VBHeading/VBHeading';

const propTypes = {
  /** callback for when the deletion is either confirmed or cancelled (takes the resolution is the argument) */
  onResolve: PropTypes.func.isRequired,

  /** text in the popup */
  prompt: PropTypes.string.isRequired,

  /** whether to leave the popup open if the confirmation is cancelled */
  leaveOpenOnCancel: PropTypes.bool,

  /** whether to use deletion style */
  isDeletion: PropTypes.bool,
};

const defaultProps = {
  leaveOpenOnCancel: false,
  isDeletion: false,
};

/**
 * General purpose popup to confirm something.
 *
 * @param {object} props
 */
const PopupConfirm = ({ onResolve, prompt, leaveOpenOnCancel, isDeletion }) => {
  const close = useClosePopup();

  /**
   * Handle a deletion or cancellation.
   *
   * @param {bool} deleted whether it is a deletion or a cancellation
   */
  const handleResolution = (confirmed) => {
    onResolve(confirmed);
    if (confirmed || !leaveOpenOnCancel) close();
  };

  return (
    <div className={styles.container}>
      <VBHeading size="sm">Confirm</VBHeading>
      <div className={styles.content}>{prompt}</div>
      <div className={styles.buttons}>
        <VBButton
          type="full"
          color={isDeletion ? 'red' : null}
          size="med"
          content={isDeletion ? 'Delete' : 'Confirm'}
          onClick={() => handleResolution(true)}
        />
        <VBButton type="border" size="med" content="Cancel" onClick={() => handleResolution(false)} />
      </div>
    </div>
  );
};
export default PopupConfirm;

PopupConfirm.propTypes = propTypes;
PopupConfirm.defaultProps = defaultProps;
