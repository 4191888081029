import { useMemo } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useVBBreakpoint from '../../../hooks/vb-breakpoint';
import MaxWidthContainer from '../../MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../../VBHeading/VBHeading';
import VBLink from '../../VBLink/VBLink';
import { mergeClassNames } from '../../../util/props';

import styles from './Section.module.scss';

const propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  contentFactory: PropTypes.object,
  cardRefs: PropTypes.array,
  RenderItem: PropTypes.func.isRequired,
  extraProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  link: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  gap: PropTypes.string,
  disablePadding: PropTypes.bool,
  beforeListContent: PropTypes.node,
  forceShowMore: PropTypes.bool,
  showMoreText: PropTypes.string,
};

const getContentPlaceholder = (size) =>
  [...Array(size)].map((_, idx) => ({
    index: idx,
    key: idx,
    item: {},
    contextual: {},
    filter: {},
  }));

const Section = ({
  id,
  title,
  contentFactory,
  cardRefs,
  RenderItem,
  extraProps,
  link,
  size,
  gap = '1.5rem',
  disablePadding,
  beforeListContent,
  forceShowMore,
  showMoreText,
}) => {
  const { data, isLoading, noMore } = contentFactory;

  const history = useHistory();
  const { lt1280: tabletStyle } = useVBBreakpoint();
  const { isVisible: isHeaderVisible } = useSelector((state) => state.header);

  const items = useMemo(() => {
    if (isLoading) {
      return getContentPlaceholder(size);
    }

    return data?.slice(0, size);
  }, [data, isLoading, size]);

  if (!data?.length && !isLoading && !beforeListContent) {
    return null;
  }

  return (
    <div id={id} className={mergeClassNames(styles.section, isHeaderVisible && styles.headerVisible)}>
      <div className={mergeClassNames(styles.titleContainer, !tabletStyle && styles.desktop)} padded>
        <VBHeading size="xs" className={styles.title}>
          <span
            onClick={() => {
              if (link) {
                history.push(link);
              }
            }}
          >
            {title}
          </span>
        </VBHeading>
      </div>
      {Boolean(beforeListContent) && beforeListContent}
      <div style={{ gap }} className={styles.list} padded={!disablePadding}>
        {items.map((item) => {
          const itemProps = typeof extraProps === 'function' ? extraProps(item) : extraProps;

          return (
            <div key={item.key} ref={cardRefs?.[item.index]}>
              <RenderItem
                item={item.item}
                index={item.index}
                contextual={item.contextual}
                filter={item.filter}
                isLoading={isLoading}
                {...itemProps}
              />
            </div>
          );
        })}
      </div>
      {(forceShowMore || (!noMore && !isLoading)) && (
        <div className={styles.showMore} padded>
          <VBLink to={link}>{showMoreText ?? 'Show More'}</VBLink>
        </div>
      )}
    </div>
  );
};

Section.propTypes = propTypes;

export default Section;
