import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import MaxWidthContainer from '../../../../../../../shared-react/src/components/MaxWidthContainer/MaxWidthContainer';
import VBHeading from '../../../../../../../shared-react/src/components/VBHeading/VBHeading';
import VBSpinner from '../../../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import { ContentTypes } from '../../../../../../../shared-react/src/config/content-types';
import { useContentFactory } from '../../../../../../../shared-react/src/hooks/content-factory';
import APIConfig from '../../../../../../../shared-react/src/config/api';
import { removeItem } from '../../../../../../../shared-react/src/store/actions/apiActions';
import useVbContext from '../../../../../../../shared-react/src/hooks/vb-context';
import useEmbeddedBottomWatcher from '../../../../../../../shared-react/src/hooks/embedded-bottom-watcher';
import VBScrollContainer from '../../../../../../../shared-react/src/components/VBScrollContainer/VBScrollContainer';
import Question from '../Question/Question';

import styles from './QuestionsList.module.scss';

const propTypes = {
  locationDetails: PropTypes.object,
  setSelectedQuestion: PropTypes.func,
};

const QuestionsList = ({ locationDetails, setSelectedQuestion }) => {
  const dispatch = useDispatch();
  const { vbRequest, raiseToast, errorToast } = useVbContext();

  const locationId = locationDetails?.id;

  const { content, loading, refreshing, noMore, loadMore } = useContentFactory({
    type: ContentTypes.locationQuestion.type,
    context: `location-question:${locationId}`,
    disabled: !locationId,
    filter: useMemo(
      () => ({
        locationId,
      }),
      [locationId]
    ),
    getQueryAddress: (offset, f) => {
      const queryParams = {
        offset,
        size: 10,
      };

      return `${APIConfig.NAMESPACE.LOCATION}/${f.locationId}/question?${stringify(queryParams)}`;
    },
    getItems: ({ questions }) => questions.map((item) => ({ item })),
  });

  const createDeleteQuestionCallback = useCallback(
    (question) => () => {
      const confirmed = confirm('Are you sure you want to delete this question?');

      if (confirmed) {
        dispatch(removeItem(ContentTypes.locationQuestion.type, question.id));

        vbRequest(`${APIConfig.NAMESPACE.LOCATION}/question/${question.id}`, {
          method: 'DELETE',
        })
          .then(() => raiseToast('Successfully deleted!', 'Success'))
          .catch((error) => errorToast(error.message, 'Error'));
      }
    },
    [dispatch, errorToast, raiseToast, vbRequest]
  );

  const createAnswerQuestionCallback = useCallback(
    (question) => () => {
      setSelectedQuestion(question);
    },
    [setSelectedQuestion]
  );

  const { bottomRef, listRef, onScroll } = useEmbeddedBottomWatcher(loadMore, loading, noMore);

  return (
    <MaxWidthContainer className={styles.container}>
      <VBHeading size="sm" className={styles.heading}>
        List of Question
      </VBHeading>
      <VBScrollContainer className={styles.list} onScroll={onScroll} ref={listRef}>
        {content.map(({ item, key }) => (
          <Question
            key={key}
            question={item}
            onDelete={createDeleteQuestionCallback(item)}
            onAnswer={createAnswerQuestionCallback(item)}
          />
        ))}
        {loading && (
          <div className={styles.spinner}>
            <VBSpinner center />
          </div>
        )}
        {!loading && !content.length && <div className={styles.noQuestions}>No Questions.</div>}
        <div ref={bottomRef} />
      </VBScrollContainer>
    </MaxWidthContainer>
  );
};

QuestionsList.propTypes = propTypes;

export default QuestionsList;
