import { ExploreSubsection } from '../../../../../../shared-react/src/config/explore';

export const getPageExploreSchema = (location, exploreSubsection, faqs) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
  };

  const origin = window.location.origin;

  if (location) {
    schema.breadcrumb = {
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Explore',
          'item': `${origin}/location`,
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': `${location.city}, ${location.regionShort}`,
          'item': `${origin}${location.searchLink}`,
        },
      ],
    };

    if (exploreSubsection === ExploreSubsection.FAQ && faqs?.length) {
      schema['@type'] = 'FAQPage';

      schema['mainEntity'] = faqs.map((faq) => ({
        '@type': 'Question',
        'name': faq.item.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': faq.item.answer,
        },
      }));
    }
  }

  return schema;
};
