import { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import Distance from '../Distance/Distance';
import VBLink from '../VBLink/VBLink';
import CardImage from './CardImage/CardImage';
import ImageOutlink from '../../../assets/images/icons/venue-details/summary-source.svg';
import useVBBreakpoint from '../../hooks/vb-breakpoint';
import useVbContext from '../../hooks/vb-context';
import { mergeClassNames } from '../../util/props';
import CardTitle from './CardTitle/CardTitle';
import CardDescription from './CardDescription/CardDescription';
import MarkdownText from '../MarkdownText/MarkdownText';
import UserListing from '../UserListing/UserListing';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './ExploreCard.module.scss';

const propTypes = {
  elementId: PropTypes.string,
  name: PropTypes.string,
  by: PropTypes.object,
  fullImage: PropTypes.object,
  description: PropTypes.string,
  toggleContent: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.object),
  descriptionLink: PropTypes.string,
  avatar: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  distance: PropTypes.number,
  destination: PropTypes.object,
  outlink: PropTypes.string,
  permalink: PropTypes.string,
  externalImageUrl: PropTypes.string,
  hideImage: PropTypes.bool,
  isLoading: PropTypes.bool,
  noPadding: PropTypes.bool,
  hideReadMore: PropTypes.bool,
  numberOfLines: PropTypes.number,
  additionalActions: PropTypes.node,
  skeletonParts: PropTypes.arrayOf(PropTypes.string),
  authorPosition: PropTypes.string,
  markdown: PropTypes.bool,
  titleClassName: PropTypes.string,
  doNotHighlightLink: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export const ExploreCardSkeletonPart = {
  IMAGE: 'image',
  FULL_IMAGE: 'fullImage',
  TITLE: 'title',
  AUTHOR: 'author',
  DATE: 'date',
  DESCRIPTION: 'description',
  DISTANCE: 'distance',
  READ_MORE: 'readMore',
};

export const AuthorPosition = {
  META: 'meta',
  DESCRIPTION: 'description',
};

const defaultProps = {
  hideImage: false,
  isLoading: false,
  noPadding: false,
  markdown: false,
  numberOfLines: 3,
  skeletonParts: [
    ExploreCardSkeletonPart.IMAGE,
    ExploreCardSkeletonPart.TITLE,
    ExploreCardSkeletonPart.AUTHOR,
    ExploreCardSkeletonPart.DATE,
    ExploreCardSkeletonPart.DESCRIPTION,
    ExploreCardSkeletonPart.DISTANCE,
    ExploreCardSkeletonPart.READ_MORE,
  ],
  authorPosition: AuthorPosition.META,
  additionalActions: null,
};

const ExploreCard = ({
  elementId,
  name,
  by,
  fullImage,
  description,
  toggleContent,
  matches,
  markdown,
  descriptionLink,
  avatar,
  author,
  date,
  distance,
  destination,
  outlink,
  permalink,
  externalImageUrl,
  hideImage,
  isLoading,
  noPadding,
  hideReadMore,
  numberOfLines,
  additionalActions,
  skeletonParts,
  authorPosition,
  titleClassName,
  doNotHighlightLink,
  onClick,
  onDelete,
  onEdit,
}) => {
  const { vbBlue, currentUser } = useVbContext();
  const { lteXs: mobileStyle, lt1280: tabletStyle } = useVBBreakpoint();

  const [isToggleContentShown, setIsToggleContentShown] = useState(false);

  const link = outlink || permalink;

  const isTitleLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.TITLE);
  const isAuthorLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.AUTHOR);
  const isDateLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.DATE);
  const isDescriptionLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.DESCRIPTION);
  const isDistanceLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.DISTANCE);
  const isReadMoreLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.READ_MORE);
  const isImageLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.IMAGE);
  const isFullImageLoading = isLoading && skeletonParts.includes(ExploreCardSkeletonPart.FULL_IMAGE);

  const showMeta = Boolean(author || by || isAuthorLoading || date || isDateLoading);
  const showLeftImage = !hideImage && Boolean(avatar || externalImageUrl || isImageLoading);
  const showBottomImage = !hideImage && Boolean(fullImage || isFullImageLoading);

  const hasToggleContent = Boolean(toggleContent);

  const handleDescriptionClick =
    onClick || hasToggleContent
      ? () => {
          if (onClick) {
            return onClick();
          }

          if (hasToggleContent) {
            setIsToggleContentShown((value) => !value);
          }
        }
      : undefined;

  return (
    <div
      id={elementId}
      className={mergeClassNames(styles.container, tabletStyle && styles.tablet, mobileStyle && styles.mobile)}
    >
      <div className={mergeClassNames(styles.inner, !showLeftImage && styles.hideImage)}>
        {showLeftImage && (
          <div className={mergeClassNames(styles.left, avatar && styles.withAvatar, noPadding && styles.noPadding)}>
            <CardImage
              src={avatar ?? externalImageUrl}
              alt={name}
              link={link}
              isOutlink={Boolean(outlink)}
              isLoading={isImageLoading}
              isAvatar={Boolean(avatar)}
              onClick={onClick}
            />
          </div>
        )}
        <div className={mergeClassNames(styles.right, avatar && styles.withAvatar)}>
          <div className={mergeClassNames(styles.title, noPadding && styles.noPadding)}>
            {Boolean(isTitleLoading || name) && (
              <CardTitle
                title={name}
                link={link}
                isOutlink={Boolean(outlink)}
                matches={matches}
                isLoading={isLoading}
                doNotHighlightLink={doNotHighlightLink}
                className={titleClassName}
              />
            )}
            {showMeta && (
              <span className={styles.meta}>
                {by ? (
                  <div className={styles.by}>
                    by <UserListing user={by} showUsername />
                  </div>
                ) : null}
                {authorPosition === AuthorPosition.META ? (
                  isAuthorLoading ? (
                    <Skeleton width="35px" />
                  ) : author ? (
                    <div className={styles.author}>{author}</div>
                  ) : null
                ) : null}
                {isDateLoading ? <Skeleton width="100px" /> : date ? <div>{date}</div> : null}
              </span>
            )}
          </div>
          <div className={styles.content}>
            {Boolean(isDescriptionLoading || description) && (
              <div className={mergeClassNames(styles.description, noPadding && styles.noPadding)}>
                <CardDescription
                  description={description}
                  matches={matches}
                  markdown={markdown}
                  hover={hasToggleContent}
                  descriptionLink={descriptionLink}
                  numberOfLines={numberOfLines}
                  author={authorPosition === AuthorPosition.DESCRIPTION ? author : null}
                  isLoading={isDescriptionLoading}
                  onClick={handleDescriptionClick}
                />
              </div>
            )}
            {hasToggleContent && (
              <div
                style={{ display: isToggleContentShown ? 'block' : 'none' }}
                className={mergeClassNames(styles.toggleContent, noPadding && styles.noPadding)}
              >
                <MarkdownText text={toggleContent} componentsOverride={{ strong: 'span' }} />
              </div>
            )}
            {showBottomImage && (
              <div className={mergeClassNames(styles.fullWidth, mobileStyle && styles.noPadding)}>
                <CardImage
                  src={fullImage?.src}
                  alt={fullImage?.alt}
                  title={fullImage?.title}
                  width={fullImage?.width}
                  height={fullImage?.height}
                  isLoading={isFullImageLoading}
                  onClick={onClick}
                  fullWidth
                />
              </div>
            )}
            <div className={mergeClassNames(styles.bottom, noPadding && styles.noPadding)}>
              {Boolean(isDistanceLoading || distance) && (
                <Distance
                  destination={destination ? [destination.latitude, destination.longitude] : null}
                  distance={distance}
                  isLoading={isDistanceLoading}
                  nearMe
                />
              )}
              <div className={styles.actions}>
                {Boolean(!isLoading && currentUser?.hasProductCapability && onDelete) && (
                  <div className={styles.delete} onClick={onDelete}>
                    Delete
                  </div>
                )}
                {Boolean(!isLoading && currentUser?.hasProductCapability && onEdit && hideImage) && (
                  <div className={styles.delete} onClick={onEdit}>
                    Edit
                  </div>
                )}
                {isReadMoreLoading ? (
                  <Skeleton width="92px" />
                ) : link && !hideReadMore ? (
                  <VBLink to={link} aria-label={`Read more about ${name}`} newTab={Boolean(outlink)}>
                    Read More{' '}
                    <ColoredIcon src={ImageOutlink} color={vbBlue} className={styles.icon} height="18" width="17" />
                  </VBLink>
                ) : null}
                {additionalActions}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExploreCard.propTypes = propTypes;
ExploreCard.defaultProps = defaultProps;

export default ExploreCard;
