import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { mergeClassNames } from '../../../../../../shared-react/src/util/props';
import { updateItem } from '../../../../../../shared-react/src/store/actions/apiActions';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import useSendAnalyticsEvent from '../../../../../../shared-react/src/hooks/send-analytics-event';
import useCurrentUser from '../../../../hooks/current-user';
import { useOpenAuthPopup } from '../../../../hooks/open-auth-popup';
import { pointsToast } from '../../../../util/toast';
import { vbRequest } from '../../../../util/api';

import styles from './LikeButton.module.scss';

/**
 * The like button on a post.
 *
 * @param {boolean} liked              whether the user has already liked the post
 * @param {number} entityId            the ID of the entity
 * @param {string} entityType          type of the entity (either 'post' or 'FAQ')
 * @param {string} size                icon size
 * @param {string} withLabel           whether to show label or not
 * @param {string} containerClassName  container class name
 * @param {string} iconClassName       icon class name
 */

const propTypes = {
  liked: PropTypes.bool,
  entityId: PropTypes.number,
  entityType: PropTypes.oneOf(['post', 'FAQ', 'article']),
  size: PropTypes.string,
  withLabel: PropTypes.bool,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

const defaultProps = {
  liked: false,
  size: '2x',
  withLabel: false,
};

const LikeButton = ({
  entityId,
  entityType,
  liked: defaultLiked,
  size,
  withLabel,
  containerClassName,
  iconClassName,
}) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useCurrentUser();

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const [liked, setLiked] = useState(defaultLiked);

  const openAuthPopup = useOpenAuthPopup();

  const handleLike = useCallback(() => {
    if (!currentUser) {
      return openAuthPopup();
    }

    const contentType =
      entityType === 'post'
        ? ContentTypes.post.type
        : entityType === 'article'
        ? ContentTypes.article.type
        : ContentTypes.faq.type;

    dispatch(updateItem(entityId, contentType, { liked: !liked }));
    setLiked(!liked);

    if (!liked && entityType === 'post') {
      sendAnalyticsEvent('vb_post_like', 'vb_post');
    }

    const requestUrl =
      entityType === 'post'
        ? `${APIConfig.NAMESPACE.POST}/${entityId}/like`
        : entityType === 'article'
        ? `${APIConfig.NAMESPACE.ARTICLE}/${entityId}/like`
        : `${APIConfig.NAMESPACE.FAQ}/${entityId}/like`;

    vbRequest(requestUrl, {
      body: { like: liked ? 0 : 1 },
      method: 'POST',
    })
      .then((response) => {
        if (!response.success) {
          throw new Error(`invalid status code: '${response.status}'`);
        }

        if (response.expert_points) {
          pointsToast(response.expert_points, 'liking a post');
        }
      })
      .catch((error) => {
        console.error(error.message ?? error);
        setLiked(!liked); // revert
      });
  }, [currentUser, dispatch, entityId, entityType, liked, openAuthPopup, sendAnalyticsEvent]);

  return (
    <>
      <div className={mergeClassNames(styles.container, containerClassName)}>
        <FontAwesomeIcon
          icon={liked ? faHeart : farHeart}
          size={size}
          onClick={handleLike}
          className={mergeClassNames(styles.likeButton, iconClassName)}
        />
        {withLabel && (
          <span className={styles.label} onClick={handleLike}>
            Like
          </span>
        )}
      </div>
    </>
  );
};

LikeButton.propTypes = propTypes;
LikeButton.defaultProps = defaultProps;

export default LikeButton;
