import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'query-string';
import Cookies from 'js-cookie';
import { useOpenAuthPopup } from '../../../hooks/open-auth-popup';
import { vbRequest } from '../../../util/api';
import APIConfig from '../../../../../shared-react/src/config/api';
import { PopupAuthPage } from '../../../../../shared-react/src/util/types';
import { addParams } from '../../../../../shared-react/src/util/urls';
import useSetToken from '../../../hooks/set-token';
/**
 * Component directly in master responsible for handing social login flow.
 */
const SocialLogin = () => {
  const location = useLocation();
  const history = useHistory();
  const params = QueryString.parse(location.search.slice(1));
  const isRedirectPage = location.pathname.indexOf('/social-login') === 0;
  const openAuthPopup = useOpenAuthPopup();
  const isAuthPopupOpen = useSelector(({ authPopupOpen }) => authPopupOpen);
  const setToken = useSetToken();

  // Whether or not the login is loading.
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  let service;
  let code;
  if (isRedirectPage) {
    service = params.service;
    switch (service) {
      case 'google':
      default:
        code = params.code;
    }
  }

  useEffect(() => {
    if (!isRedirectPage || isLoginLoading) {
      return;
    }
    setIsLoginLoading(true);

    const redirect = Cookies.get('vb-slogin-redirect');
    vbRequest(`${APIConfig.NAMESPACE.SLOGIN}/login`, { method: 'POST', params: { service, code } })
      .then(({ loginToken, socialToken }) => {
        if (loginToken) {
          setToken(loginToken.token);
          history.replace(redirect);
        } else if (socialToken) {
          history.replace(
            addParams(redirect ?? '/', {
              slogin: service,
              token: socialToken,
            })
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoginLoading(false));
  }, [isRedirectPage, service, code, history, isLoginLoading, params.service, setToken]);

  useEffect(() => {
    if (!isRedirectPage && !isAuthPopupOpen && params.slogin) {
      openAuthPopup(PopupAuthPage.SOCIAL_LOGIN);
    }
  }, [isAuthPopupOpen, isRedirectPage, openAuthPopup, params.slogin]);

  return null;
};
export default SocialLogin;
