import React from 'react';
import styles from './headerBuilder.module.scss';
import ColoredIcon from '../../components/ColoredIcon/ColoredIcon';
import Branding from '../../config/branding';
import Pages from '../pages';
import { HeaderMenuItem } from '../../config/header';
import {
  PostTab,
  ExploreTab,
  ActivityTab,
  LoginTab,
  SignUpTab,
  NotificationsTab,
  MyProfileTab,
  HomeTab,
  BlogTab,
  GameHangmanTab,
} from './tabs';
import { AdminSettings, LogoutLink, OtherSiteLink, SettingsLink } from './links';
import { getSiteNames } from '../../util/sites';

/**
 * This class should be extended to make a HeaderBuilder (probably will only want one HeaderBuilder per site).
 */
export default class AbstractHeaderBuilder {
  static TABS = {
    [HeaderMenuItem.POST]: PostTab,
    [HeaderMenuItem.EXPLORE]: ExploreTab,
    [HeaderMenuItem.ACTIVITY]: ActivityTab,
    [HeaderMenuItem.LOGIN]: LoginTab,
    [HeaderMenuItem.SIGN_UP]: SignUpTab,
    [HeaderMenuItem.NOTIFICATIONS]: NotificationsTab,
    [HeaderMenuItem.MY_PROFILE]: MyProfileTab,
    [HeaderMenuItem.BLOG]: BlogTab,
    [HeaderMenuItem.GAME_HANGMAN]: GameHangmanTab,
  };

  // Tabs are typically usable as links.
  static LINKS = {
    [HeaderMenuItem.HOME]: HomeTab,
    [HeaderMenuItem.EXPLORE]: ExploreTab,
    [HeaderMenuItem.ACTIVITY]: ActivityTab,
    [HeaderMenuItem.NOTIFICATIONS]: NotificationsTab,
    [HeaderMenuItem.SETTINGS]: SettingsLink,
    [HeaderMenuItem.MY_PROFILE]: MyProfileTab,
    [HeaderMenuItem.LOGIN]: LoginTab,
    [HeaderMenuItem.LOGOUT]: LogoutLink,
    [HeaderMenuItem.SIGN_UP]: SignUpTab,
    [HeaderMenuItem.ADMIN_SETTINGS]: AdminSettings,
    [HeaderMenuItem.BLOG]: BlogTab,
    [HeaderMenuItem.GAME_HANGMAN]: GameHangmanTab,
    ...AbstractHeaderBuilder.getAllSiteLinks(),
  };

  /**
   * @param {Object} currentUser The currentUser object
   * @param {string} pathname The URL pathname to build the header for. (Could build header differently on Homepage vs other pages)
   * @param {func} getThemeColor See src/util/colors getThemeColor function
   * @param {func} SearchBarComponentType SearchBar component
   * @param {History} history React-router history object
   */
  constructor(currentUser, pathname, getThemeColor, SearchBarComponentType, history) {
    this.currentUser = currentUser;
    this.loggedIn = !!currentUser;
    this.getThemeColor = getThemeColor;
    this.SearchBarComponentType = SearchBarComponentType;
    this.pathname = pathname;
    this.history = history;
  }

  getTabs() {
    const tabs = this.loggedIn
      ? [HeaderMenuItem.POST, HeaderMenuItem.EXPLORE, HeaderMenuItem.NOTIFICATIONS, HeaderMenuItem.MY_PROFILE]
      : [
          HeaderMenuItem.EXPLORE,
          HeaderMenuItem.BLOG,
          HeaderMenuItem.GAME_HANGMAN,
          HeaderMenuItem.LOGIN,
          HeaderMenuItem.SIGN_UP,
        ];

    return AbstractHeaderBuilder.createTabsFromNames(tabs);
  }

  getDropdownLinks() {
    const links = [
      HeaderMenuItem.MY_PROFILE,
      HeaderMenuItem.BLOG,
      HeaderMenuItem.GAME_HANGMAN,
      HeaderMenuItem.SETTINGS,
      HeaderMenuItem.LOGOUT,
    ];

    if (this.currentUser?.hasProductCapability) {
      links.unshift(HeaderMenuItem.ADMIN_SETTINGS);
    }

    return AbstractHeaderBuilder.createLinksFromNames(links);
  }

  getHamburgerLinksAndDividers() {
    let links;
    let afterLinkDividers = [];

    if (this.loggedIn) {
      links = [
        HeaderMenuItem.HOME,
        HeaderMenuItem.EXPLORE,
        // HeaderMenuItem.ACTIVITY,
        HeaderMenuItem.BLOG,
        HeaderMenuItem.GAME_HANGMAN,
        HeaderMenuItem.NOTIFICATIONS,
        HeaderMenuItem.MY_PROFILE,
        HeaderMenuItem.LOGOUT,
      ];

      afterLinkDividers.push('hallOfFame');

      if (this.currentUser.hasProductCapability) {
        links.unshift(HeaderMenuItem.ADMIN_SETTINGS);
      }
    } else {
      links = [
        HeaderMenuItem.HOME,
        HeaderMenuItem.EXPLORE,
        // HeaderMenuItem.ACTIVITY,
        HeaderMenuItem.BLOG,
        HeaderMenuItem.GAME_HANGMAN,
        HeaderMenuItem.LOGIN,
        HeaderMenuItem.SIGN_UP,
      ];
    }

    return { links: AbstractHeaderBuilder.createLinksFromNames(links), dividers: afterLinkDividers };
  }

  getSearchBar(sendAnalyticsEvent) {
    if (Pages.isOnAnyOfPages(Pages.PAGES_WITH_SEARCH_BAR, this.pathname)) {
      return this.getSearchBarComponent(sendAnalyticsEvent);
    }
    return null;
  }

  getSearchBarComponent(sendAnalyticsEvent, exploreText) {
    throw new Error('getSearchBarComponent must be implemented by subclass');
  }

  calculateBackgroundColor(scrollY, windowHeight) {
    if (this.onHomePage()) {
      return this.getThemeColor('black');
    }
    return 'rgba(255,255,255,0.9)';
  }

  getBackgroundBlur() {
    if (this.onHomePage()) {
      return true;
    }
    return false;
  }

  getIconLogo() {
    return (
      <ColoredIcon
        src={Branding.LOGO_ICON}
        color={this.getLogoColor()}
        className={styles.gem}
        height="40"
        width="38"
        alt={`${Branding.SITE_NAME_ABV}Gem`}
      />
    );
  }

  getTextLogo() {
    return (
      <ColoredIcon
        src={Branding.LOGO_TEXT}
        color={this.getLogoColor()}
        className={styles.logoText}
        alt={`${Branding.SITE_NAME} logo`}
      />
    );
  }

  getLogoColor() {
    if (this.onHomePage()) {
      return 'white';
    }
    return this.getThemeColor('blue');
  }

  getHamburgerIconColor() {
    return this.getThemeColor('grey-dark');
  }

  hasFixedPositioning() {
    return this.onHomePage();
  }

  /**
   * This is for both tab links on desktop and links in the mobile hamburger menu.
   */
  hasWhiteLinks() {
    if (this.onHomePage()) {
      return true;
    }
    return false;
  }

  hasBottomBorder() {
    return !this.onHomePage();
  }

  onHomePage() {
    return Pages.isOnPage(Pages.HOME, this.pathname);
  }

  onGameHangmanPage() {
    return Pages.isOnPage(Pages.GAME_HANGMAN, this.pathname);
  }

  hasVenuesInSearchResult() {
    return !Pages.isOnPage(Pages.ACTIVITY, this.pathname) && !this.onGameHangmanPage();
  }

  handleSearchResultOnHangmanPage(result) {
    if (this.onGameHangmanPage()) {
      this.history.push(`/games/hangman/${result.id}`);
    }
  }

  getSearchBarSelectItemHandler() {
    return (item) => {
      this.handleSearchResultOnHangmanPage(item);
    };
  }

  getSearchBarRandomClickHandler(sendAnalyticsEvent) {
    return (item) => {
      sendAnalyticsEvent('vb_header_random', 'vb_header');
      this.handleSearchResultOnHangmanPage(item);
    };
  }

  getSearchBarNearMeClickHandler(sendAnalyticsEvent) {
    return (item) => {
      sendAnalyticsEvent('vb_header_near_me', 'vb_header');
      this.handleSearchResultOnHangmanPage(item);
    };
  }

  getSearchBarExploreClickHandler() {
    return (item) => {
      this.handleSearchResultOnHangmanPage(item);
    };
  }

  getSearchBarResultBehavior() {
    return this.onGameHangmanPage() ? 'set_text' : 'link';
  }

  /** Uses Tab element types defined in this class to build into nodes from the name of the tab */
  static createTabsFromNames(tabNames) {
    return AbstractHeaderBuilder.createFromNames(tabNames, AbstractHeaderBuilder.TABS);
  }

  /** Uses Link element types defined in this class to build into nodes from the name of the link */
  static createLinksFromNames(linkNames) {
    return AbstractHeaderBuilder.createFromNames(linkNames, AbstractHeaderBuilder.LINKS, { isHamburger: true });
  }

  static createFromNames(names, componentMap, props = {}) {
    return names.map((name) => {
      if (!componentMap[name]) {
        throw new Error(`No component found for name: ${name}`);
      }
      const C = componentMap[name];
      return {
        name,
        node: <C key={name} {...props} />,
      };
    });
  }

  static getAllSiteLinks() {
    const siteNames = getSiteNames();
    const otherSites = {};
    siteNames.forEach((name) => {
      otherSites[name] = () => <OtherSiteLink name={name} key={name} />;
    });
    return otherSites;
  }
}
