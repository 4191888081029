import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './UserList.module.scss';
import { boolProp, mergeClassNames } from '../../util/props';
import { PropTypesVBUser, PropTypesVBBool } from '../../util/types';
import UserListEntry from './UserListEntry/UserListEntry';
import VBScrollContainer from '../VBScrollContainer/VBScrollContainer';
import NoContent from '../NoContent/NoContent';

/**
 * A list of users.
 *
 * @param {object[]} props.users the users to show
 * @param {(user: object): node} props.buildActionArea a function to create a node to show on the right
 * @param {(user: object): node | string[]]} props.buildSubtitle a function to create the subtitle area under each user.
 *        This function should return an array of strings or a custom React node. If using array of strings,
 *        each string will get it's own row in the subtitle.
 * @param {node} props.bottom node to show at the bottom of the list
 * @param {string} props.className additional classes for the list
 * @param {string} props.subtitlesClassName className to wrap around all subtitle divs.
 * @param {string} props.nameClassName className to put on the name of the user.
 * @param {boolean} props.noLink whether entries should be links to profiles or not
 * @param {scroll} props.scroll whether the list should have a scroll or not
 * @param {string} props.listingSize size passed into UserListing
 */
const UserList = forwardRef((props, ref) => {
  const {
    users,
    buildActionArea,
    buildSubtitle,
    bottom,
    className,
    noLink,
    scroll,
    subtitlesClassName,
    nameClassName,
    listingSize,
    ...other
  } = props;

  const makeSubtitle = (user) => {
    let subtitle = null;
    const builtSubtitle = buildSubtitle(user);
    if (Array.isArray(builtSubtitle)) {
      // Using strings
      subtitle = (
        <div className={subtitlesClassName}>
          {builtSubtitle.map((content, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>{content}</div>
          ))}
        </div>
      );
    } else {
      // Probably a custom React component
      subtitle = builtSubtitle;
    }
    return subtitle;
  };

  return (
    <VBScrollContainer
      className={mergeClassNames(className, styles.container, boolProp(scroll) ? styles.scroll : null)}
      ref={ref}
      {...other}
    >
      <div className={styles.list}>
        {users.length > 0 ? (
          users.map((user) => (
            <UserListEntry
              key={user.id}
              user={user}
              actionArea={buildActionArea ? buildActionArea(user) : null}
              subtitle={makeSubtitle(user)}
              subtitlesClassName={subtitlesClassName}
              nameClassName={nameClassName}
              link={!boolProp(noLink)}
              listingSize={listingSize}
            />
          ))
        ) : (
          <NoContent typePlural="users" />
        )}
      </div>
      {bottom ?? null}
    </VBScrollContainer>
  );
});
UserList.displayName = 'UserList';
export default UserList;
UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypesVBUser).isRequired,
  buildActionArea: PropTypes.func,
  bottom: PropTypes.node,
  className: PropTypes.string,
  noLink: PropTypesVBBool,
  buildSubtitle: PropTypes.func,
  subtitlesClassName: PropTypes.string,
  nameClassName: PropTypes.string,
  scroll: PropTypesVBBool,
  listingSize: PropTypes.string,
};
UserList.defaultProps = {
  buildActionArea: undefined,
  bottom: undefined,
  className: undefined,
  noLink: false,
  buildSubtitle: () => [],
  subtitlesClassName: '',
  nameClassName: '',
  scroll: false,
  listingSize: undefined,
};
