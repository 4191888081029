/**
 * Action to add a full image src to the progressive images cache.
 */

import { ADD_PROGRESSIVE_IMAGE } from '../types';

/**
 * Call this when an image has been loaded. Any ProgressiveImage that uses the src as the source image will switch to
 * using the full image.
 *
 * @param {string} src source of the full image
 */
export const progressiveImageLoaded = (src) => {
  return (dispatch) =>
    dispatch({
      type: ADD_PROGRESSIVE_IMAGE,
      payload: src,
    });
};
