import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { boolProp, mergeClassNames } from '../../util/props';
import { PropTypesVBBool, PropTypesVBVenue } from '../../util/types';
import Dot from '../Dot/Dot';
import Distance from '../Distance/Distance';
import { isHike } from '../../util/venue';
import StackedAvatars from '../StackedAvatars/StackedAvatars';
import HikeMeta from './HikeMeta/HikeMeta';
import VBLink from '../VBLink/VBLink';
import { addParams } from '../../util/urls';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './VenueMeta.module.scss';

const propTypes = {
  /** (Optional) The description of the venue to show */
  desc: PropTypes.string,
  /** (Optional) The number of lines to display for the description (defaults to 4) */
  descLines: PropTypes.number,
  /** The coordinates of the location. For example, "32.7807, -79.7984" or: [32.7807, -79.7984]. Used for google maps link */
  coords: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  /** The title to base the google maps destination off of if no coordinates are provided. */
  title: PropTypes.string,
  /** A distance to display in the directions link, such as "14 mi". */
  distance: PropTypes.number,
  /** A list of users with ids, avatars, and links. Used for StackedAvatars */
  users: PropTypes.arrayOf(PropTypes.object),
  /** Adds padding around the meta component */
  padded: PropTypesVBBool,
  /** Link back to the explore page that the user was on */
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
  /** Category which is displayed */
  category: PropTypes.string,
  /** Display the distance with "From me" appended */
  nearMe: PropTypes.bool,
  /** A direction to display in the directions link, such as "NW". */
  direction: PropTypes.string,
  /** venue the metadata is for. Used for getting cross superlative data. */
  venue: PropTypesVBVenue.isRequired,
  /** whether to show skeleton or no */
  isLoading: PropTypes.bool,
  /** whether or not show articles */
  showArticles: PropTypes.bool,
};

const defaultProps = {
  nearMe: false,
  desc: undefined,
  descLines: 4,
  coords: undefined,
  title: undefined,
  distance: undefined,
  users: undefined,
  padded: false,
  category: undefined,
  direction: undefined,
  isLoading: false,
  showArticles: false,
};

/**
 * Displays the meta information that is specific to venues:
 * Gem badges, distance, profile bubbles, and description.
 */
const VenueMeta = ({
  venue,
  descLines,
  padded,
  exploreLink,
  exploreLinkText,
  isLoading,
  category,
  coords,
  title,
  distance,
  direction,
  nearMe,
  users,
  desc,
  showArticles,
}) => {
  const containerStyle = { flexWrap: 'wrap' };
  if (boolProp(padded)) {
    containerStyle.padding = '10px 0px';
  }

  return (
    <>
      <div style={containerStyle} className="d-flex align-items-center">
        {isLoading ? (
          <>
            <Skeleton width="50px" />
            <Dot />
          </>
        ) : Boolean(category) ? (
          <>
            {isLoading ? <Skeleton width="50px" /> : <span className={styles.category}>{category}</span>}
            <Dot />
          </>
        ) : null}

        <Distance
          destination={coords ?? title}
          distance={distance}
          direction={direction}
          nearMe={nearMe}
          isLoading={isLoading}
          fontSize="0.86rem"
        />
        {!isLoading && users?.length ? (
          <>
            <Dot />
            <StackedAvatars users={users} showExtra={false} maxUsers={5} />
          </>
        ) : null}
      </div>
      {isHike(venue) && (
        <HikeMeta
          distance={venue.hikeDistance < 1 ? venue.hikeDistance.toFixed(1) : venue.hikeDistance}
          difficulty={venue.hikeDifficulty}
          time={venue.hikeTime}
          paved={venue.hikePaved}
        />
      )}
      <div
        className={mergeClassNames(styles.description, descLines ? styles.truncated : null)}
        style={descLines ? { '--numberOfLines': descLines } : null}
      >
        {isLoading ? (
          <Skeleton count={3} />
        ) : (
          <VBLink
            to={venue.permalink}
            className={styles.descriptionText}
            aria-label={`Go to ${venue.name} details page`}
          >
            {desc}
          </VBLink>
        )}
      </div>
      {Boolean(showArticles && venue.articles?.length) && (
        <ul className={styles.articles}>
          {venue.articles.slice(0, 3).map((article) => (
            <li key={article.id}>
              <VBLink to={addParams(article.permalink, { exploreLink, exploreLinkText })}>{article.heading}</VBLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

VenueMeta.propTypes = propTypes;
VenueMeta.defaultProps = defaultProps;

export default VenueMeta;
