import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SocialShareButton.module.scss';
import OnClickLink from '../../OnClickLink/OnClickLink';
import VBSpinner from '../../VBSpinner/VBSpinner';
import { getShareLink } from '../../../util/share';
import ColoredIcon from '../../ColoredIcon/ColoredIcon';

const propTypes = {
  /** the platform to share on, see vb/v1/social/invite-link/(?P<platform>[\S]+) */
  platform: PropTypes.string.isRequired,

  /** font awesome icon to render */
  icon: PropTypes.string.isRequired,

  /** text to render under the icon */
  displayText: PropTypes.string.isRequired,

  /** flex basis for the button */
  flexBasis: PropTypes.string.isRequired,

  /** given the platform as an argument, return either the share link or a promise which resolved the share link */
  getPermalink: PropTypes.func.isRequired,

  /** hashtag (without the pound sign) to use for the share */
  hashtag: PropTypes.string.isRequired,

  /** subject of the share (currently only used for email) */
  subject: PropTypes.string.isRequired,

  /** text content of the share */
  description: PropTypes.string.isRequired,
};

/**
 * A single platform share button.
 *
 * @param {object} props
 */
const SocialShareButton = ({ platform, icon, displayText, hashtag, subject, description, getPermalink, flexBasis }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleShare = () => {
    setIsLoading(true);
    Promise.resolve(getPermalink(platform))
      .then((link) => {
        window.open(getShareLink(platform, link, subject, hashtag, description), '_blank');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <OnClickLink className={styles.shareButton} onClick={handleShare} style={{ flexBasis }} noStyle>
      <div className={styles.logoContainer}>
        {isLoading ? <VBSpinner color="white" /> : <ColoredIcon src={icon} color="#ffffff" />}
      </div>
      <p>{displayText}</p>
    </OnClickLink>
  );
};

SocialShareButton.propTypes = propTypes;

export default SocialShareButton;
