import { useCallback } from 'react';
import { useHistory } from 'react-router';
import TriviaQuestion from '../../../../../../../shared-react/src/components/TriviaQuestion/TriviaQuestion';

const RenderedTriviaQuestion = ({ item, basePath, isLoading }) => {
  const history = useHistory();

  const handleOptionClick = useCallback(() => {
    history.push(`/${basePath}/trivia`);
  }, [basePath, history]);

  return <TriviaQuestion question={item} onOptionClick={handleOptionClick} isLoading={isLoading} disabled />;
};

export default RenderedTriviaQuestion;
