import React from 'react';
import useVbContext from './vb-context';

/**
 * Returns an array of react nodes for saving venues. The exact content depends on the site and the venue.
 *
 * @param {PropTypesVBVenue} venue
 * @param {PropTypesVBTrip} trip
 * @param {bool} verticalButtons whether or not the action buttons will be displayed vertically
 */
const useSaveIconButtons = (venue, trip = undefined, verticalButtons = false) => {
  const {
    components: { IconButtonBucket, IconButtonSaveVenue, IconButtonBeenThere },
  } = useVbContext();

  const buttons = [];

  if (IconButtonBucket) {
    buttons.push(<IconButtonBucket key="bucket" venueId={venue.id} noBreakWords={verticalButtons} />);
  }

  if (IconButtonSaveVenue) {
    buttons.push(<IconButtonSaveVenue key="saveVenue" venueId={venue.id} saved={venue.saved} />);
  }

  if (IconButtonBeenThere) {
    buttons.push(<IconButtonBeenThere key="addVenue" venue={venue} />);
  }

  return buttons;
};

export default useSaveIconButtons;
