import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import ImageCancel from '../../../assets/images/icons/popups/cancel-circle.svg';
import { PropTypesRefProp } from '../../util/types';
import OnClickLink from '../OnClickLink/OnClickLink';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './VBPopup.module.scss';

/**
 * A popup component, used by the usePopup hook.
 *
 * @param {element} props.component the component to be used in the body of the popup. Can also just nest component.
 * @param {string} props.isOpen whether the popup should be shown
 * @param {(): void} props.onRequestClose callback for when the popup closes
 */
const VBPopup = (props) => {
  const {
    component,
    children,
    isOpen,
    onRequestClose,
    contentClassName,
    onExited,
    popupRef,
    hideFakeScrollbar,
    ...other
  } = props;

  const { gteXl, gteMd, lteXs } = useVBBreakpoint();
  const onExitedHandler = () => {
    onExited();
  };

  // Arguments from the child component.
  const noOverflow = component?.type?.NO_OVERFLOW;
  const width = component?.type?.WIDTH;

  // Watch the height of the child container.
  const { height: childContainerHeight, ref: childContainerRef } = useResizeObserver();

  const { height: popupHeight } = useResizeObserver({ ref: popupRef });

  // Whether the content should have height: 100%.
  const [fullHeight, setFullHeight] = useState(false);

  // Change the full height state based on overflow, scroll, child height and whether the popup is shown.
  useEffect(() => {
    if (!popupRef?.current) return;

    if (!noOverflow) {
      if (fullHeight) setFullHeight(false);
    } else if (popupRef.current.scrollHeight > popupRef.current.clientHeight) {
      if (!fullHeight) setFullHeight(true);
    }
  }, [childContainerHeight, noOverflow, popupRef, fullHeight, isOpen, popupHeight]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onRequestClose}
        dialogClassName={mergeClassNames(
          width ? styles[width] : null,
          gteXl ? styles.innerXl : null,
          lteXs ? styles.innerSm : null
        )}
        contentClassName={mergeClassNames(contentClassName, styles.content, fullHeight ? styles.fullHeight : null)}
        centered
        onExited={onExitedHandler}
        {...other}
        scrollable
        // https://github.com/react-bootstrap/react-bootstrap/issues/3518
        // Removing this because onEntering and onExiting don't trigger if animation is off.
        // animation={!isDevelopment()}
      >
        <Modal.Body
          className={mergeClassNames(
            styles.body,
            component?.type?.NO_PADDING_MOBILE && styles.noPaddingMobile,
            gteMd ? styles.bodyMd : null
          )}
          ref={popupRef}
        >
          <div className={styles.childContainer} ref={childContainerRef}>
            {component || children}
          </div>
        </Modal.Body>
        <OnClickLink onClick={onRequestClose} noStyle>
          <img src={ImageCancel} className={styles.cancel} alt="Close" />
        </OnClickLink>
      </Modal>
    </>
  );
};

VBPopup.propTypes = {
  component: PropTypes.node,
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  contentClassName: PropTypes.string,
  onExited: PropTypes.func,
  hideFakeScrollbar: PropTypes.bool,
  popupRef: PropTypesRefProp,
};

VBPopup.defaultProps = {
  onRequestClose: () => {},
  contentClassName: '',
  onExited: () => {},
  hideFakeScrollbar: false,
  popupRef: undefined,
};

export default VBPopup;
