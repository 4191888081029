import {
  ADD_TO,
  ITEMS_RECEIVED,
  ITEMS_REFRESHED,
  ITEMS_STORE,
  REMOVE_ITEM,
  SINGLE_FAILED,
  SINGLE_RECEIVED,
  SINGLE_REQUESTED,
  SINGLE_STORE,
  UPDATE_ITEM,
  ADD_ITEM,
} from '../../types';
import typeReducer from './content/typeReducer';

/**
 * Reducer for contentFactoryData.content.
 *
 * @param {object} state the current items object
 * @param {string} action.type the type of the action. See contentFactoryDataActions.jsx
 * @param {string} action.payload.type the type of the content. See content-types.jsx
 *
 * @returns {object} the new items object
 */
const contentReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_REQUESTED:
    case SINGLE_RECEIVED:
    case SINGLE_STORE:
    case ITEMS_STORE:
    case ITEMS_RECEIVED:
    case ADD_TO:
    case UPDATE_ITEM:
    case ADD_ITEM:
    case SINGLE_FAILED:
    case REMOVE_ITEM:
    case ITEMS_REFRESHED: {
      const { type } = action.payload;

      const newState = { ...state };
      // TODO: This is an antipattern. We should find and add references at the action dispatch level.
      const addItems = (referenceType, referenceItems) => {
        newState[referenceType] = typeReducer(newState[referenceType], {
          type: ITEMS_STORE,
          payload: {
            type: referenceType,
            items: referenceItems,
            addItems,
          },
        });
      };
      newState[type] = typeReducer(newState[type], { ...action, payload: { ...action.payload, addItems } });
      return newState;
    }
    default:
  }
  return state;
};

export default contentReducer;
