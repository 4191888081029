import React from 'react';
import styles from './HomeBubble.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ColoredIcon from '../../../../../../shared-react/src/components/ColoredIcon/ColoredIcon';
import useVbContext from '../../../../../../shared-react/src/hooks/vb-context';

const HomeBubble = ({ title, link, icon, alt, iconHeight, iconWidth, absolute: isAbsolute }) => {
  const exploreLink = useSelector((state) => state.globalLocation.location?.searchLink);
  const history = useHistory();
  const { vbBlack, vbWhite } = useVbContext();

  return (
    <div className={styles.bubble} onClick={() => history.push(isAbsolute ? link : exploreLink + link)}>
      <p>{title}</p>
      {icon ? (
        <ColoredIcon
          color={vbWhite}
          src={icon}
          className={styles.iconWhite}
          alt={alt}
          height={iconHeight}
          width={iconWidth}
        />
      ) : null}
      {icon ? (
        <ColoredIcon
          color={vbBlack}
          src={icon}
          className={styles.iconBlack}
          alt={alt}
          height={iconHeight}
          width={iconWidth}
        />
      ) : null}
    </div>
  );
};

export default HomeBubble;
