import { useSelector } from 'react-redux';
import useCurrentUser from './current-user';

/**
 * Gets the auth token from redux store.
 * @returns {string} the auth token.
 */
export const useAuthToken = () => {
  return useSelector((state) => state.authToken);
};

/**
 * Gets whether or not the user is logged in.
 * @returns {boolean} whether or not the user is logged in
 */
export const useIsLoggedIn = () => {
  return !!useCurrentUser().user;
};
