import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getSite } from '../../../../shared-react/src/util/sites';
import { isLive } from '../../../../shared-react/src/util/env';
import useVbContext from '../../../../shared-react/src/hooks/vb-context';
import useAnalyticsSetUser from '../../../../shared-react/src/hooks/analytics-set-user';
import { HOTJAR } from '../../config/analytics';
import { hotjar } from 'react-hotjar';

const hotjarConfig = isLive() ? HOTJAR[getSite()] : null;

const AnalyticsHandler = ({ children }) => {
  const history = useHistory();

  const { currentUser } = useVbContext();

  // Initial page view, last path name.
  const [lastPathname, setLastPathname] = useState(null);

  const analyticsSetUser = useAnalyticsSetUser();
  useEffect(() => {
    analyticsSetUser(currentUser?.id ?? null);
  }, [analyticsSetUser, currentUser]);

  // Record page view every time the location changes.
  useEffect(() => {
    if (!hotjarConfig) return null;

    // Don't think we have to do this. Initial record path.
    // if (!lastPathname) {
    //   hotjar.stateChange(history.location.pathname);
    //   setLastPathname(history.location.pathname);
    // }

    const removeListener = history.listen((location) => {
      if (location.pathname !== lastPathname) {
        hotjar.stateChange(location.pathname);
        setLastPathname(location.pathname);
      }
    });

    return () => {
      removeListener();
    };
  }, [history, lastPathname]);

  return children;
};

export default AnalyticsHandler;
