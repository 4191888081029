import { useEffect, useMemo, useRef, useState } from 'react';
import { stringify } from 'query-string';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import APIConfig from '../../../../../shared-react/src/config/api';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import { useContentFactory } from '../../../../../shared-react/src/hooks/content-factory';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import useBottomWatcher from '../../../../../shared-react/src/hooks/bottom-watcher';
import { mergeClassNames } from '../../../../../shared-react/src/util/props';
import MetaTags from '../../../../../shared-react/src/components/MetaTags/MetaTags';
import useQPState, { parseBoolParam, stringifyBoolParam } from '../../../../../shared-react/src/hooks/qp-state';
import usePushPreserveScrollPosition from '../../../../../shared-react/src/hooks/push-preserve-scroll-position';
import { convertRemToPixels } from '../../../../../shared-react/src/util/dimensions';
import useVBBreakpoint from '../../../../../shared-react/src/hooks/vb-breakpoint';
import { FilterDefaults } from '../../../config/search-page';
import CarouselCard from './CarouselCard/CarouselCard';
import CityFilter from './CityFilter/CityFilter';

import styles from './PageZuzuGo.module.scss';

const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

export const ZuzuGoQueryParam = {
  LOCATION_ID: 'l',
  RECOMMENDED: 're',
  ITINERARY: 'i',
  VENUE_ID: 'iv',
  RANDOM_VENUE: 'rv',
  RANDOM_CITY: 'rc',
  RANDOM_STATE: 'rs',
  AGES: 'a',
  RADIUS: 'd',
  SUBCATEGORY: 'sc',
  LOW_RISK: 'lr',
  IS_FREE: 'fr',
};

const PageZuzuGo = () => {
  const location = useLocation();

  const { lteXs: mobileStyle } = useVBBreakpoint();

  const { globalLocation } = useSelector((state) => ({
    globalLocation: state.globalLocation?.location,
  }));

  const [selectedVenue, setSelectedVenue] = useState(null);
  const [locationDetails, setLocationDetails] = useState(globalLocation);
  const [currentCardIdx, setCurrentCardIdx] = useState(0);
  const [isMuted, setIsMuted] = useState(true);

  const [locationId] = useQPState(ZuzuGoQueryParam.LOCATION_ID, locationDetails?.id, Number);
  const [venueId] = useQPState(ZuzuGoQueryParam.VENUE_ID, undefined, Number);
  const [recommended] = useQPState(ZuzuGoQueryParam.RECOMMENDED, 0, parseBoolParam, stringifyBoolParam);
  const [itinerary] = useQPState(ZuzuGoQueryParam.ITINERARY, 0, parseBoolParam, stringifyBoolParam);
  const [randomVenue] = useQPState(ZuzuGoQueryParam.RANDOM_VENUE, 1, parseBoolParam, stringifyBoolParam);
  const [randomCity] = useQPState(ZuzuGoQueryParam.RANDOM_CITY, 1, parseBoolParam, stringifyBoolParam);
  const [randomState] = useQPState(ZuzuGoQueryParam.RANDOM_STATE, 1, parseBoolParam, stringifyBoolParam);
  const [ages] = useQPState(ZuzuGoQueryParam.AGES, FilterDefaults.ages, JSON.parse, JSON.stringify);
  const [radius] = useQPState(ZuzuGoQueryParam.RADIUS, FilterDefaults.radius, JSON.parse, JSON.stringify);
  const [subcategory] = useQPState(ZuzuGoQueryParam.SUBCATEGORY, FilterDefaults.subcategory);
  const [lowRisk] = useQPState(ZuzuGoQueryParam.LOW_RISK, FilterDefaults.lowRisk, parseBoolParam, stringifyBoolParam);
  const [isFree] = useQPState(ZuzuGoQueryParam.IS_FREE, FilterDefaults.price, parseBoolParam, stringifyBoolParam);

  const scrollContainerRef = useRef(null);
  const bottomRef = useRef(null);
  const audio = useRef(new Audio());

  const replaceKeepScroll = usePushPreserveScrollPosition();

  const { content, loading, noMore, loadMore } = useContentFactory({
    context: `zuzu-go`,
    type: ContentTypes.venue.type,
    filter: useMemo(() => {
      return {
        locationId,
        randomVenue,
        randomState,
        randomCity,
        ages,
        radius,
        subcategory,
        isFree,
        venueId,
        recommended,
        itinerary,
      };
    }, [
      locationId,
      randomVenue,
      randomState,
      randomCity,
      ages,
      radius,
      subcategory,
      isFree,
      venueId,
      recommended,
      itinerary,
    ]),
    getItems: (response) => {
      return response.items.map((item) => ({ item: item.venue, contextual: { distance: item.distance } }));
    },
    getQueryAddress: (offset, f) => {
      const queryParams = {
        size: 25,
        randomVenue: f.randomVenue,
        randomState: f.randomState,
        randomCity: f.randomCity,
        ages: f.ages,
        distanceRanges: f.radius,
        subcategory: f.subcategory,
        isFree: f.isFree,
        venueId: f.venueId,
        recommended: f.recommended,
        itinerary: f.itinerary,
        locationId: f.locationId,
        offset: offset,
      };

      return `${APIConfig.NAMESPACE.VENUE}/zuzu-go?${stringify(queryParams, { arrayFormat: 'bracket' })}`;
    },
  });

  const isInitialLoading = loading && !content.length;
  const noItems = !loading && !content.length && noMore;

  const isItineraryMode = Boolean(itinerary && locationId);
  const isDoNextMode = Boolean(recommended && venueId);

  const doNextMainVenue = isDoNextMode ? content.find(({ item }) => item.id === venueId) : null;

  const handleLoadMore = () => {
    if (!noMore && !loading) {
      loadMore();
    }
  };

  const hideFilter = () => {
    setSelectedVenue(null);
  };

  const saveFilterValues = (values) => {
    const params = {};

    const hasLocationDetails = 'locationDetails' in values;
    const hasRandomVenue = 'randomVenue' in values;
    const hasRandomCity = 'randomCity' in values;
    const hasRandomState = 'randomState' in values;

    const shouldResetNonFilterValues = hasLocationDetails || hasRandomVenue || hasRandomCity || hasRandomState;

    if (shouldResetNonFilterValues) {
      params[ZuzuGoQueryParam.VENUE_ID] = undefined;
      params[ZuzuGoQueryParam.LOCATION_ID] = undefined;
      params[ZuzuGoQueryParam.ITINERARY] = 0;
      params[ZuzuGoQueryParam.RECOMMENDED] = 0;
    }

    if (hasLocationDetails) {
      params[ZuzuGoQueryParam.LOCATION_ID] = values.locationDetails.id;
      setLocationDetails(values.locationDetails);
    }

    if (hasRandomVenue) {
      params[ZuzuGoQueryParam.RANDOM_VENUE] = stringifyBoolParam(values.randomVenue);
    }

    if (hasRandomCity) {
      params[ZuzuGoQueryParam.RANDOM_CITY] = stringifyBoolParam(values.randomCity);
    }

    if (hasRandomState) {
      params[ZuzuGoQueryParam.RANDOM_STATE] = stringifyBoolParam(values.randomState);
    }

    if ('ages' in values) {
      params[ZuzuGoQueryParam.AGES] = JSON.stringify(values.ages);
    }

    if ('radius' in values) {
      params[ZuzuGoQueryParam.RADIUS] = JSON.stringify(values.radius);
    }

    if ('subcategory' in values) {
      params[ZuzuGoQueryParam.SUBCATEGORY] = values.subcategory;
    }

    if ('lowRisk' in values) {
      params[ZuzuGoQueryParam.LOW_RISK] = stringifyBoolParam(values.lowRisk);
    }

    if ('isFree' in values) {
      params[ZuzuGoQueryParam.IS_FREE] = stringifyBoolParam(values.isFree);
    }

    replaceKeepScroll(location.pathname + location.search, {}, 'replace', params);
    hideFilter();
  };

  useBottomWatcher(bottomRef, handleLoadMore, 1600, scrollContainerRef.current);

  useEffect(() => {
    const scrollContainerElement = scrollContainerRef.current;

    if (scrollContainerElement) {
      const handleScroll = debounce(() => {
        const { scrollTop, clientHeight } = scrollContainerElement;

        const cardHeight = mobileStyle ? clientHeight : clientHeight * 0.94 - convertRemToPixels(2);
        const cardIdx = Math.floor(scrollTop / cardHeight);

        const venue = content[cardIdx]?.item;

        // Handle itinerary mode infinite scroll
        const TOTAL_ITINERARY_CARDS = 14;

        const isLastCard = cardIdx === content.length - 1;

        if (isLastCard && isItineraryMode) {
          const items = content.slice(0, TOTAL_ITINERARY_CARDS);

          for (const item of items) {
            content.push({ ...item, key: `${item.key}-${Math.random()}` });
          }
        }

        if (venue) {
          setCurrentCardIdx(cardIdx);
        }
      }, 200);

      handleScroll();

      scrollContainerElement.addEventListener('scroll', handleScroll);

      return () => scrollContainerElement.removeEventListener('scroll', handleScroll);
    }
  }, [content, isItineraryMode, isMuted, mobileStyle]);

  useEffect(() => {
    const audioPlayer = audio.current;
    const venue = content[currentCardIdx]?.item;
    const canPlayAudio = venue?.audioUrl && venue.audioUrl !== audioPlayer.src && !isMuted;

    if (canPlayAudio) {
      audioPlayer.src = venue.audioUrl;
      audioPlayer.currentTime = 0;
      audioPlayer.autoplay = true;
      audioPlayer.loop = true;

      audioPlayer.play();
    }
  }, [content, currentCardIdx, isMuted]);

  useEffect(() => {
    const audioPlayer = audio.current;

    return () => {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    };
  }, []);

  return (
    <>
      <MetaTags title="Zuzu Go" />
      <div
        className={mergeClassNames(
          styles.carousel,
          selectedVenue || noItems ? styles.disabled : null,
          mobileStyle ? styles.mobile : null
        )}
        ref={scrollContainerRef}
      >
        {noItems ? <div className={styles.noItems}>No items found.</div> : null}
        {content.length
          ? content.map(({ item, key, contextual, index }) => {
              const isRecommendedVenue = isDoNextMode && item.id !== venueId;

              return (
                <CarouselCard
                  key={key}
                  venue={item}
                  distance={isRecommendedVenue || isItineraryMode ? contextual.distance : null}
                  showPopularity={isRecommendedVenue || isItineraryMode}
                  positionLabel={
                    isRecommendedVenue && doNextMainVenue?.item ? `After ${doNextMainVenue.item.name} #${index}` : null
                  }
                  dayLabel={
                    isItineraryMode ? `Day ${Math.floor(index / 2) + 1} ${index % 2 === 0 ? 'AM' : 'PM'}` : null
                  }
                  onlyIllustrationAndLink={Boolean(selectedVenue)}
                  showDifferentFacts={currentCardIdx === index}
                  isItinerarySelected={isItineraryMode}
                  isDoNextSelected={isDoNextMode && venueId === item.id}
                  onClickVenueDetails={setSelectedVenue}
                  onClickItinerary={() => {
                    const params = {
                      [ZuzuGoQueryParam.LOCATION_ID]: isItineraryMode ? undefined : item.city.id,
                      [ZuzuGoQueryParam.ITINERARY]: stringifyBoolParam(!isItineraryMode),
                    };

                    if (!isItineraryMode) {
                      params[ZuzuGoQueryParam.VENUE_ID] = 0;
                      params[ZuzuGoQueryParam.RECOMMENDED] = undefined;
                    }

                    replaceKeepScroll(location.pathname + location.search, {}, 'push', params);
                  }}
                  onClickDoNext={() => {
                    const isDoNextVenue = isDoNextMode && venueId === item.id;

                    const params = {
                      [ZuzuGoQueryParam.VENUE_ID]: isDoNextVenue ? undefined : item.id,
                      [ZuzuGoQueryParam.RECOMMENDED]: stringifyBoolParam(!isDoNextVenue),
                    };

                    if (!isDoNextMode) {
                      params[ZuzuGoQueryParam.ITINERARY] = 0;
                      params[ZuzuGoQueryParam.LOCATION_ID] = undefined;
                    }

                    replaceKeepScroll(location.pathname + location.search, {}, 'push', params);
                  }}
                  isMuted={isMuted}
                  onUnmute={() => setIsMuted(false)}
                />
              );
            })
          : null}
        <div ref={bottomRef} />
        {isInitialLoading ? <VBSpinner page /> : null}
        <CityFilter
          defaultValues={{
            locationId,
            randomVenue,
            randomCity,
            randomState,
            ages,
            radius,
            subcategory,
            lowRisk,
            isFree,
          }}
          venue={selectedVenue}
          location={locationDetails}
          fullPage={noItems && mobileStyle}
          isShown={Boolean(selectedVenue || noItems)}
          hideCancel={noItems && !mobileStyle}
          onCancel={hideFilter}
          onSave={saveFilterValues}
        />
      </div>
    </>
  );
};

export default PageZuzuGo;
