import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
// import { useSelector } from 'react-redux';
import ExploreSummary from '../../../../../../shared-react/src/components/ExploreSummary/ExploreSummary';
import APIConfig from '../../../../../../shared-react/src/config/api';
import { ContentTypes } from '../../../../../../shared-react/src/config/content-types';
import useRandomCities from '../../../../../../shared-react/src/hooks/random-cities';
import useExploreContent from './useExploreContent/useExploreContent';
import { getVenuesItems, getVenuesQueryAddress } from './utils';

const propTypes = {
  locationDetails: PropTypes.object,
  getEllipsisMenu: PropTypes.func,
  placeName: PropTypes.string.isRequired,
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
};

const PageSummary = ({ locationDetails, getEllipsisMenu, placeName, exploreLink, exploreLinkText }) => {
  const ellipsis = useMemo(() => getEllipsisMenu(), [getEllipsisMenu]);

  // const { userLocation } = useSelector((state) => state);

  const randomCities = useRandomCities({ exclude: locationDetails ? [locationDetails?.id] : [], size: 5 });

  // const userCoords = useMemo(
  //   () => ({
  //     latitude: userLocation?.latitude,
  //     longitude: userLocation?.longitude,
  //   }),
  //   [userLocation?.latitude, userLocation?.longitude]
  // );

  const newslettersContentFactory = useExploreContent({
    type: ContentTypes.newsletter.type,
    context: `explore-newsletters:${locationDetails?.id}`,
    disabled: !locationDetails,
    filter: useMemo(
      () => ({
        countrySlug: locationDetails?.countrySlug,
        regionSlug: locationDetails?.regionSlug,
        citySlug: locationDetails?.citySlug,
      }),
      [locationDetails]
    ),
    getQueryAddress: (_, f) => {
      const params = {
        countrySlug: f.countrySlug,
        regionSlug: f.regionSlug,
        citySlug: f.citySlug,
        size: 1,
      };

      return `${APIConfig.NAMESPACE.NEWSLETTER}?${stringify(params)}`;
    },
  });

  const articlesContentFactory = useExploreContent({
    type: ContentTypes.exploreArticles.type,
    context: `explore-summary-articles:${locationDetails?.id}`,
    disabled: !locationDetails,
    filter: useMemo(() => ({ locationId: locationDetails?.id }), [locationDetails]),
    getQueryAddress: (_, f) => {
      const params = {
        size: 5,
      };

      return `${APIConfig.NAMESPACE.LOCATION}/${f.locationId}/article?${stringify(params)}`;
    },
  });

  // const eventsContentFactory = useExploreContent({
  //   context: `summary-events:${locationDetails?.id}`,
  //   type: ContentTypes.event.type,
  //   filter: useMemo(() => ({ userCoords, locationId: locationDetails?.id }), [locationDetails?.id, userCoords]),
  //   disabled: !locationDetails || !userLocation,
  //   getQueryAddress: (offset, f) => {
  //     const params = {
  //       offset,
  //       locationId: f.locationId,
  //       size: 4,
  //       timePeriod: 'all',
  //       distanceRanges: [0, 1],
  //       userCoords: f.userCoords ? [f.userCoords.latitude, f.userCoords.longitude] : null,
  //     };

  //     return `${APIConfig.NAMESPACE.EVENTS}/events?${stringify(params, { arrayFormat: 'bracket' })}`;
  //   },
  //   getItems: ({ events }) => events.map(({ event, distance }) => ({ item: event, contextual: { distance } })),
  // });

  const festivalsContentFactory = useExploreContent({
    context: `summary-festivals:${locationDetails?.id}`,
    type: ContentTypes.festival.type,
    filter: useMemo(
      () => ({ longitude: locationDetails?.longitude, latitude: locationDetails?.latitude }),
      [locationDetails]
    ),
    disabled: !locationDetails,
    getQueryAddress: (offset, f) => {
      const params = {
        offset,
        size: 4,
        longitude: f.longitude,
        latitude: f.latitude,
      };

      return `${APIConfig.NAMESPACE.FESTIVAL}?${stringify(params)}`;
    },
    getItems: ({ festivals }) => festivals.map((festival) => ({ item: festival })),
  });

  // const postsContentFactory = useExploreContent({
  //   context: `summary-posts:${locationDetails?.countrySlug}-${locationDetails?.regionSlug}-${locationDetails?.citySlug}`,
  //   type: ContentTypes.postOrVideo.type,
  //   disabled: !locationDetails,
  //   filter: useMemo(
  //     () => ({
  //       countrySlug: locationDetails?.countrySlug,
  //       regionSlug: locationDetails?.regionSlug,
  //       citySlug: locationDetails?.citySlug,
  //     }),
  //     [locationDetails]
  //   ),
  //   getQueryAddress: (offset, f) => {
  //     const params = {
  //       offset,
  //       size: 3,
  //       contentType: 'photos',
  //       isFromVenue: true,
  //       seed: f.seed,
  //       country: f.countrySlug,
  //       region: f.regionSlug,
  //       city: f.citySlug,
  //     };

  //     return `${APIConfig.NAMESPACE.SEARCH}/trending?${stringify(params)}`;
  //   },
  //   getItems: ({ results }) => results.map((item) => ({ item: item.post, contextual: { distance: item.distance } })),
  // });

  // const videosContentFactory = useExploreContent({
  //   context: `summary-location-videos:${locationDetails?.id}`,
  //   type: ContentTypes.locationVideo.type,
  //   disabled: !locationDetails,
  //   filter: useMemo(
  //     () => ({ longitude: locationDetails?.longitude, latitude: locationDetails?.latitude }),
  //     [locationDetails]
  //   ),
  //   getQueryAddress: (offset, f) => {
  //     const params = {
  //       offset,
  //       size: 2,
  //       longitude: f.longitude,
  //       latitude: f.latitude,
  //     };

  //     return `${APIConfig.NAMESPACE.LOCATION}/video?${stringify(params)}`;
  //   },
  //   getItems: (response) => response.videos.map(({ video, distance }) => ({ item: video, contextual: { distance } })),
  // });

  const triviaContentFactory = useExploreContent({
    type: ContentTypes.trivia.type,
    context: `summary-travel-trivia:${locationDetails?.longitude}-${locationDetails?.latitude}`,
    disabled: !locationDetails,
    filter: useMemo(
      () => ({ longitude: locationDetails?.longitude, latitude: locationDetails?.latitude }),
      [locationDetails]
    ),
    getQueryAddress: (_, f) => {
      const params = {
        longitude: f.longitude,
        latitude: f.latitude,
        size: 1,
      };

      return `${APIConfig.NAMESPACE.TRIVIA}?${stringify(params)}`;
    },
  });

  const faqContentFactory = useExploreContent({
    type: ContentTypes.faq.type,
    context: `summary-faq:${locationDetails?.id}`,
    disabled: !locationDetails,
    filter: useMemo(() => ({ locationId: locationDetails?.id }), [locationDetails]),
    getQueryAddress: (_, f) => {
      const params = {
        size: 3,
        locationId: f.locationId,
      };

      return `${APIConfig.NAMESPACE.FAQ}?${stringify(params)}`;
    },
  });

  const itineraryDays = useExploreContent({
    type: ContentTypes.itinerary.type,
    context: `summary-itinerary:${locationDetails?.id}`,
    disabled: !locationDetails,
    filter: useMemo(() => ({ locationId: locationDetails?.id }), [locationDetails]),
    getQueryAddress: (_, f) => {
      const params = {
        size: 1,
        locationId: f.locationId,
        showClosestIfNotExist: true,
      };

      return `${APIConfig.NAMESPACE.ITINERARY}?${stringify(params)}`;
    },
    getItems: ({ itineraries }) => {
      const [itinerary] = itineraries;
      return itinerary.days.map((day) => ({ item: day }));
    },
  });

  const commonVenuesFilter = useMemo(
    () => ({
      country: locationDetails?.countrySlug,
      region: locationDetails?.regionSlug,
      city: locationDetails?.citySlug,
      hiddenGems: false,
      nearMe: false,
      isFree: false,
      isRegionSelected: false,
    }),
    [locationDetails]
  );

  const activitiesFactory = useExploreContent({
    type: ContentTypes.venue.type,
    context: `summary-activities:${locationDetails?.country}/${locationDetails?.region}/${locationDetails?.city}`,
    disabled: !locationDetails,
    filter: useMemo(
      () => ({
        ...commonVenuesFilter,
        radius: '[0,1]',
        category: 'activities',
        ages: '[0,1,2]',
        subcategory: 'all',
      }),
      [commonVenuesFilter]
    ),
    getQueryAddress: getVenuesQueryAddress,
    getItems: getVenuesItems,
  });

  const dayTripsContentFactory = useExploreContent({
    type: ContentTypes.venue.type,
    context: `summary-daytrips:${locationDetails?.country}/${locationDetails?.region}/${locationDetails?.city}`,
    disabled: !locationDetails,
    filter: useMemo(
      () => ({
        ...commonVenuesFilter,
        radius: '[2,3]',
        category: 'activities',
        ages: '[0,1,2,3]',
        subcategory: 'daytrips',
      }),
      [commonVenuesFilter]
    ),
    getQueryAddress: getVenuesQueryAddress,
    getItems: getVenuesItems,
  });

  const playgroundsContentFactory = useExploreContent({
    type: ContentTypes.venue.type,
    context: `summary-playgrounds:${locationDetails?.country}/${locationDetails?.region}/${locationDetails?.city}`,
    disabled: !locationDetails,
    filter: useMemo(
      () => ({
        ...commonVenuesFilter,
        radius: '[0]',
        category: 'playgrounds',
        ages: '[0,1,2,3]',
        subcategory: 'all',
      }),
      [commonVenuesFilter]
    ),
    getQueryAddress: getVenuesQueryAddress,
    getItems: getVenuesItems,
  });

  return (
    <ExploreSummary
      locationDetails={locationDetails}
      ellipsis={ellipsis}
      articlesContentFactory={articlesContentFactory}
      newslettersContentFactory={newslettersContentFactory}
      // eventsContentFactory={eventsContentFactory}
      festivalsContentFactory={festivalsContentFactory}
      // postsContentFactory={postsContentFactory}
      // videosContentFactory={videosContentFactory}
      triviaContentFactory={triviaContentFactory}
      faqContentFactory={faqContentFactory}
      itineraryDays={itineraryDays}
      activitiesFactory={activitiesFactory}
      dayTripsContentFactory={dayTripsContentFactory}
      playgroundsContentFactory={playgroundsContentFactory}
      placeName={placeName}
      randomCities={randomCities}
      exploreLink={exploreLink}
      exploreLinkText={exploreLinkText}
    />
  );
};

PageSummary.propTypes = propTypes;

export default PageSummary;
