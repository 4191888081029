import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import LegalAgreement from './LegalAgreement/LegalAgreement';
import styles from './SignUp.module.scss';
import { mergeClassNames } from '../../../util/props';
import { PopupAuthPage } from '../../../util/types';
import VBButton from '../../VBButton/VBButton';
import VBTextInput from '../../VBTextInput/VBTextInput';
import OnClickLink from '../../OnClickLink/OnClickLink';
import Dot from '../../Dot/Dot';
import { useVBBreakpoint } from '../../../hooks/vb-breakpoint';
import ContentLengths from '../../../../../shared-react/config-json/content-lengths.json';
import GoogleLoginBadge from '../../GoogleLoginBadge/GoogleLoginBadge';
import RecaptchWrapper from '../../RecaptchaWrapper/RecaptchaWrapper';
import { RECAPTCHA_PUBLIC_KEY } from '../../../config/security';
import { isTesting } from '../../../util/env';

/**
 * The page of the popup prompting the user to sign up.
 *
 * @param {function} props.setCurrentPage                   changes the current page of the PopupAuth
 * @param {function} props.googleLogin                      redirects to google social login
 * @param {object}   props.fields                           input fields and their setters
 * @param {string}   props.fields.usernameInput             value of the Username field
 * @param {string}   props.fields.emailInput                value of the Email field
 * @param {string}   props.fields.passwordInput             value of the Password field
 * @param {boolean}  props.fields.registerCaptchaResult     captcha value
 * @param {function} props.fields.setUsernameInput          sets the Username field
 * @param {function} props.fields.setEmailInput             sets the Email field
 * @param {function} props.fields.setPasswordInput          sets the Password field
 * @param {function} props.fields.setRegisterCaptchaResult  sets registerCaptchaResult
 * @param {array}    props.tempEmails                       list of temporary email domains to not accept
 * @param {function} props.setNotice                        sets the error/success notice
 * @param {function} props.registerUser                     registers the user using the sign up field information
 * @param {ref}      props.registerCaptchaRef               ref to use for the captcha
 */

const SignUp = ({ setCurrentPage, googleLogin, fields, tempEmails, setNotice, registerUser, registerCaptchaRef }) => {
  const {
    usernameInput,
    emailInput,
    passwordInput,
    registerCaptchaResult,
    setUsernameInput,
    setEmailInput,
    setPasswordInput,
    setRegisterCaptchaResult,
  } = fields;

  const { lteLg } = useVBBreakpoint();

  const hasGoogleLoginHandler = Boolean(googleLogin);

  const getValidationError = React.useCallback(() => {
    const [, enteredEmailDomain] = emailInput.split('@');

    if (tempEmails?.includes(enteredEmailDomain)) {
      return { message: 'Temporary emails cannot be used for signup.', variant: 'danger' };
    }

    if (!registerCaptchaResult && !isTesting()) {
      return { message: 'Invalid CAPTCHA.', variant: 'danger' };
    }

    return null;
  }, [emailInput, registerCaptchaResult, tempEmails]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      const validationError = getValidationError();

      if (validationError) {
        setNotice(validationError);
      } else {
        registerUser();
      }

      return false;
    },
    [getValidationError, registerUser, setNotice]
  );

  return (
    <>
      <div className={mergeClassNames('d-flex', lteLg ? 'flex-column-reverse' : 'flex-row')}>
        <div className={styles.content}>
          {!lteLg && <h1>Sign Up</h1>}
          <Form className={styles.form} onSubmit={handleSubmit}>
            <Form.Group controlId="signUpUsername">
              <VBTextInput
                type="text"
                placeholder="Username"
                maxLength={ContentLengths.PROFILE_USERNAME}
                value={usernameInput}
                onChange={(e) => setUsernameInput(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="signUpEmail">
              <VBTextInput
                type="email"
                placeholder="Email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="signUpPassword">
              <VBTextInput
                type="password"
                placeholder="Password"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
                required
                minLength={ContentLengths.PROFILE_PASSWORD_MIN}
                maxLength={ContentLengths.PROFILE_PASSWORD}
              />
            </Form.Group>
            <div className={styles.captchaContainer}>
              <RecaptchWrapper
                sitekey={RECAPTCHA_PUBLIC_KEY}
                onChange={(captchaResult) => {
                  setRegisterCaptchaResult(captchaResult);
                }}
                ref={registerCaptchaRef}
              />
            </div>
            <VBButton className="my-2" size="med" submit>
              Sign Up For Free
            </VBButton>
          </Form>
          <div className={mergeClassNames('d-flex', 'align-items-center', 'text-nowrap')}>
            <OnClickLink content="Already Have an Account?" onClick={() => setCurrentPage(PopupAuthPage.SIGN_IN)} />
            {hasGoogleLoginHandler && (
              <>
                <Dot />
                <GoogleLoginBadge loginLink={googleLogin} />
              </>
            )}
          </div>
          <br />
          <LegalAgreement />
        </div>
        <Sidebar imageType="benefits" />
      </div>
    </>
  );
};

SignUp.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  fields: PropTypes.exact({
    usernameInput: PropTypes.string.isRequired,
    emailInput: PropTypes.string.isRequired,
    passwordInput: PropTypes.string.isRequired,
    registerCaptchaResult: PropTypes.string,
    setUsernameInput: PropTypes.func.isRequired,
    setEmailInput: PropTypes.func.isRequired,
    setPasswordInput: PropTypes.func.isRequired,
    setRegisterCaptchaResult: PropTypes.func.isRequired,
  }).isRequired,
  googleLogin: PropTypes.func,
  tempEmails: PropTypes.array.isRequired,
  setNotice: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  registerCaptchaRef: PropTypes.object.isRequired,
};

export default SignUp;
