/**
 * Actions to deal with resetting the scroll on location change.
 */

import { isDesktop } from 'react-device-detect';
import APIConfig from '../../config/api';
import {
  requestBrowserPosition,
  getLocationTelize,
  getClosestCity,
  checkGeolocationPermission,
  getUserCoords,
} from '../../util/location';
import { USER_LOCATION_LOADING, SET_USER_COORDS, SET_USER_CITY } from '../types';

export const setCity = (city, retriever) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_CITY,
      payload: { city, retriever },
    });
  };
};

export const setCoords = (vbRequest, latitude, longitude) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    dispatch({
      type: SET_USER_COORDS,
      payload: { latitude, longitude },
    });

    if (currentUser.user) {
      vbRequest(`${APIConfig.NAMESPACE.MARKETING}/location`, {
        method: 'POST',
        body: {
          latitude,
          longitude,
          type: isDesktop ? 'desktop' : 'mobile',
        },
      });
    }
  };
};

/**
 * Action to fetch the user location and store it in Redux.
 */
export const fetchInitialUserLocation = (vbRequest) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOCATION_LOADING,
    });

    const { latitude, longitude, retriever } = await getUserCoords();

    dispatch(setCoords(vbRequest, latitude, longitude));

    const closestCity = await getClosestCity(vbRequest, latitude, longitude);

    dispatch(setCity(closestCity, retriever));
  };
};
