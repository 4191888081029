import { useSelector } from 'react-redux';

/**
 * Gets the height of the header, using either the useHeaderHeight or headerHeightSelector field in VBReactContext's
 * value.
 *
 * @returns {number} in px
 */

export const useHeaderHeight = () => {
  const { height } = useSelector((state) => state.header);

  return height;
};
