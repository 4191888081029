import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isIOS, isSafari } from 'react-device-detect';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';
import usePopup from '../../hooks/popup';
import { isPwa } from '../../util/platforms';
import { hours, seconds } from '../../util/time';
import PWAPopup from './PWAPopup/PWAPopup';

const FIRST_OPEN_PROMPT_TIME = seconds(120);
const TIME_TO_REPROMPT = hours(24);

// Local storage key for when the user should be reprompted
export const LOCAL_STORAGE_REPROMPT_TIME_KEY = 'vbPwaRepromptTime';
export const NEVER_REPROMPT_VALUE = 'never';

/**
 * Will prompt the user to install the PWA
 */
const InstallPWA = () => {
  const location = useLocation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const showPromptWithDelay = useCallback(
    (openPopup, repromptTimeStr = localStorage.getItem(LOCAL_STORAGE_REPROMPT_TIME_KEY)) => {
      const repromptTime = repromptTimeStr ? Number(repromptTimeStr) : null;
      const showPromptImmediately = !repromptTime || Date.now() > repromptTime;
      const timeoutDelay = showPromptImmediately ? FIRST_OPEN_PROMPT_TIME : repromptTime - Date.now();

      return setTimeout(() => {
        sendAnalyticsEvent('vb_pwa_open_prompt_popup', 'vb_pwa');
        openPopup();
      }, timeoutDelay);
    },
    [sendAnalyticsEvent]
  );

  const handleClose = useCallback(
    (openPopup) => {
      sendAnalyticsEvent('vb_pwa_close_prompt_popup', 'vb_pwa');

      const currentRepromptTime = localStorage.getItem(LOCAL_STORAGE_REPROMPT_TIME_KEY);

      if (currentRepromptTime !== NEVER_REPROMPT_VALUE) {
        const repromptDate = Date.now() + TIME_TO_REPROMPT;

        localStorage.setItem(LOCAL_STORAGE_REPROMPT_TIME_KEY, repromptDate);
        showPromptWithDelay(openPopup, repromptDate);
      }
    },
    [sendAnalyticsEvent, showPromptWithDelay]
  );

  const { open, modal } = usePopup({
    child: <PWAPopup deferredPrompt={deferredPrompt} />,
    onClose: () => handleClose(open),
  });

  useEffect(() => {
    const repromptTime = localStorage.getItem(LOCAL_STORAGE_REPROMPT_TIME_KEY);

    // Don't show the prompt if:
    // 1) We are already in the PWA
    // 2) Browser did not give us a deferredPrompt to work with, and we need one (on iOS safari we don't need one)
    // 3) Reprompt time value is NEVER_REPROMPT_VALUE
    // 4) Current page is /daily-travel-contest
    // 5) Current page is /trips
    const canPromptBeShown =
      !isPwa() &&
      (deferredPrompt || (isIOS && isSafari)) &&
      repromptTime !== NEVER_REPROMPT_VALUE &&
      location.pathname.indexOf('/daily-travel-contest') === -1 &&
      location.pathname.indexOf('/zuzu-go') === -1 &&
      location.pathname.indexOf('/trips') === -1;

    if (canPromptBeShown) {
      const timeout = showPromptWithDelay(open);
      return () => clearTimeout(timeout);
    }
  }, [deferredPrompt, location, open, showPromptWithDelay]);

  useEffect(() => {
    // This being called at all is totally up to the browser. There are a lot of factors that go into this. All we
    // can really do is register the listener and hope the browser gives us an API to prompt with.
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  return modal;
};

export default InstallPWA;
