import React from 'react';
import PropTypes from 'prop-types';
import OnClickLink from '../../OnClickLink/OnClickLink';
import styles from './Header.module.scss';

const propTypes = {
  /** content of the header */
  title: PropTypes.string,

  /** callback for when the back button is clicked. If this is not provided, the back button will not be rendered */
  onBackButtonClick: PropTypes.func,

  /** the go back icon */
  backIcon: PropTypes.node,
};

const defaultProps = {
  title: undefined,
  onBackButtonClick: undefined,
  backIcon: undefined,
};

/**
 * Header rendered by the Comments component.
 *
 * @param {object} props
 */
const Header = ({ title, onBackButtonClick, backIcon }) => (
  <div className={styles.commentsHeader}>
    {onBackButtonClick ? (
      <OnClickLink className={styles.backButton} onClick={onBackButtonClick}>
        {backIcon ?? '<'}
      </OnClickLink>
    ) : null}
    {title ? <span className={styles.xComments}>{title}</span> : null}
  </div>
);
Header.propTypes = propTypes;

Header.defaultProps = defaultProps;

export default Header;
