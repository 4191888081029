import { CLEAR_PRELOAD_SEED } from '../../../../shared-react/src/store/types';

const preloadSeedReducer = (state = window._pmgPreloadSeed ?? null, action) => {
  switch (action.type) {
    case CLEAR_PRELOAD_SEED:
      return null;
    default:
  }
  return state;
};

export default preloadSeedReducer;
