import { useEffect, useRef } from 'react';
import { isUndefined } from '../util/objects';
import { generateTestAttributes } from '../util/props';

/**
 * Put test data on an element's attributes using generateTestAttributes. This is very useful for nodes that you want
 * to have testData, but are 3rd party components that don't take this prop. But if they have a ref prop, you can use
 * this hook and provide a ref.
 *
 * @param {object} testData key value test data
 * @param {React.Ref} incomingRef existing ref to apply attributes to, optional
 *
 * @returns {object} { ref } - attach this ref to the node you want the test data on
 */
const useTestData = (testData, incomingRef = null) => {
  let ref = useRef();
  if (incomingRef) ref = incomingRef;

  useEffect(() => {
    if (!ref?.current) return null;

    if (!isUndefined(testData)) {
      const el = ref.current;
      const attrs = generateTestAttributes(testData);
      Object.entries(attrs).forEach(([k, v]) => el.setAttribute(k, v));
      return () => Object.entries(attrs).forEach(([k]) => el.removeAttribute(k));
    }
    return null;
  }, [testData, ref]);

  return {
    ref,
  };
};
export default useTestData;
