import { useCallback, useEffect, useState } from 'react';
import useContentSingle from '../../../../../shared-react/src/hooks/content-single';
import { ContentTypes } from '../../../../../shared-react/src/config/content-types';
import APIConfig from '../../../../../shared-react/src/config/api';
import VBSpinner from '../../../../../shared-react/src/components/VBSpinner/VBSpinner';
import FAQ from '../FAQ/FAQ';

const PopupFAQ = ({ id, locationDetails }) => {
  const [loading, setLoading] = useState(true);

  const result = useContentSingle({
    id,
    type: ContentTypes.faq.type,
    getQueryAddress: useCallback(() => `${APIConfig.NAMESPACE.FAQ}/${id}`, [id]),
  });

  const faq = result?.content;

  useEffect(() => {
    if (faq) {
      setLoading(false);
    }
  }, [faq]);

  if (loading) {
    return (
      <center>
        <VBSpinner />
      </center>
    );
  }

  return <FAQ faq={faq} locationDetails={locationDetails} />;
};

export default PopupFAQ;
