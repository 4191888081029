import React from 'react';
import styles from '../headerBuilder.module.scss';
import ColoredIcon from '../../../components/ColoredIcon/ColoredIcon';
import Branding from '../../../config/branding';
import AbstractHeaderBuilder from '../abstract-header-builder';

export default class TripfoolHeaderBuilder extends AbstractHeaderBuilder {
  getTextLogo() {
    return (
      <ColoredIcon
        src={Branding.LOGO_TEXT}
        color={this.getLogoColor()}
        className={styles.logoText}
        style={{ marginTop: '3px' }}
        alt={`${Branding.SITE_NAME} logo`}
      />
    );
  }

  calculateBackgroundColor(scrollY, windowHeight) {
    if (this.onHomePage()) {
      return 'rgba(50, 50, 50, 0.7)';
    }
    return 'rgba(255,255,255,0.9)';
  }

  getSearchBarComponent(sendAnalyticsEvent) {
    const lteXs = false;
    const SearchBar = this.SearchBarComponentType;
    return (
      <SearchBar
        include="rn"
        onRandomClick={() => sendAnalyticsEvent('vb_header_random', 'vb_header')}
        onNearMeClick={() => sendAnalyticsEvent('vb_header_near_me', 'vb_header')}
        cities
        venues
        border
        separateButtons
        randomPerformsAction
        fullWidth={lteXs}
      />
    );
  }
}
