import { arrayToPrettyList } from './string';
import { AGE_GROUPS } from '../config/explore';

/**
 * Builds an array of age groups into an array of strings.
 *
 * @param {int} agesInt
 * @returns {string[]}
 */
export const buildAgesArray = (agesInt) => {
  const ages = [];
  for (let i = 0; i < AGE_GROUPS.length; i++) {
    // eslint-disable-next-line no-bitwise
    if ((agesInt & (1 << i)) > 0) ages.push(AGE_GROUPS[i]);
  }
  return ages;
};

/**
 * Given a binary age group integer, return a string with the list of ages.
 *
 * @param {int} agesInt
 * @returns {string}
 */
export const buildAgesString = (agesInt) => {
  const ages = buildAgesArray(agesInt);
  return arrayToPrettyList(ages);
};
