import React from 'react';
import PropTypes from 'prop-types';
import VBCheckbox from '../../VBCheckbox/VBCheckbox';
import { COMMON_DISTANCE_RANGES } from '../../../config/explore';

const propTypes = {
  /** stringified array of radiuses, ie '[0, 1]', '[2]'. See DISTANCE_RANGES in config/explore.jsx */
  radius: PropTypes.string.isRequired,

  /** setter for radius */
  setRadius: PropTypes.func.isRequired,

  /** distance ranges */
  ranges: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const defaultProps = {
  ranges: COMMON_DISTANCE_RANGES,
};

/**
 * Checkboxes used in filters on the explore page to select a radius.
 *
 * @param {object} props
 */
const RadiusSelector = ({ radius, setRadius, ranges }) => {
  return ranges.map((max, idx) => (
    <div style={{ marginBottom: '5px' }} key={max}>
      <VBCheckbox
        checked={JSON.parse(radius).includes(idx)}
        onChange={(checked) => {
          if (checked) {
            setRadius(JSON.stringify([...JSON.parse(radius), idx]));
          } else {
            setRadius(JSON.stringify(JSON.parse(radius).filter((x) => x !== idx)));
          }
        }}
        label={<>{` ${ranges[idx - 1] ?? 0} - ${max} mi`}</>}
      />
    </div>
  ));
};

RadiusSelector.propTypes = propTypes;
RadiusSelector.defaultProps = defaultProps;

export default RadiusSelector;
