import { CLEAR_TOKEN } from '../../../../shared-react/src/store/types';
import { SET_TOKEN } from '../../../../shared-react/src/store/types';
/**
 * Action dispatchers dealing with the auth token field of the store.
 */

import LocalStore from 'store';
import { LOCAL_AUTH_TOKEN_KEY } from '../../config/auth';
/**
 * Action dispatcher to set the auth token
 *
 * @param {string} token the auth token
 *
 * @returns the action dispatcher for setting the auth token
 */
export const setToken = (token) => {
  const action = {
    type: SET_TOKEN,
    payload: { token },
  };
  return (dispatch) => {
    LocalStore.set(LOCAL_AUTH_TOKEN_KEY, token);
    return dispatch(action);
  };
};

/**
 * Action dispatcher to clear the auth token. Use this when the user logs out.
 *
 * @returns the action dispatcher for clearing the auth token
 */
export const clearToken = () => (dispatch) => {
  LocalStore.remove(LOCAL_AUTH_TOKEN_KEY);
  return dispatch({ type: CLEAR_TOKEN });
};
