import React, { Fragment } from 'react';

/**
 * Util functions dealing with strings.
 */

/**
 * Trims forward slashes from the start and end of a string
 * @param {string} str the string to trim slashes from
 */
export const trimSlashes = (str) => {
  const startsWithSlash = str[0] === '/' ? 1 : 0;
  const endsWithSlash = str[str.length - 1] === '/' ? 1 : 0;
  return str.slice(startsWithSlash, str.length - endsWithSlash);
};
export default trimSlashes;

/**
 * Uppercase the first character of a string.
 *
 * @param {string} str the input string
 *
 * @returns {string} the string with its first character capitalized
 */
export const uppercaseFirst = (str) => {
  if (!str || typeof str !== 'string') {
    return str;
  }
  if (str.length <= 1) {
    return str.toLocaleUpperCase();
  }
  return str.charAt(0).toLocaleUpperCase() + str.substr(1);
};

/**
 * Remove hyphens and uppercase the first character of each word in a string.
 *
 * @param {string} str the input string
 *
 * @returns {string} the string with each first character capitalized
 */
export const titleCase = (str) => {
  if (!str || typeof str !== 'string') {
    return str;
  }
  if (str.length <= 1) {
    return str.toLocaleUpperCase();
  }

  return str
    .split('-')
    .map((e) => uppercaseFirst(e))
    .join(' ');
};

/**
 * Checks if a string is a valid email address.
 *
 * @param {string} str
 * @returns {bool}
 */
export const isEmailAddress = (str) => {
  // https://melvingeorge.me/blog/check-if-string-is-valid-email-address-javascript
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(
    str
  );
};

/**
 * Given an error message, return a pretty version of it. This involves making sure the first relevant character is
 * capitalized, and that the message ends in punctuation.
 *
 * @param {string} message
 * @returns {string}
 */
export const prettyErrorString = (message) => {
  const trimmed = message.trim();
  const punctuated = ['?', '.', '!'].includes(trimmed.charAt(trimmed.length - 1)) ? trimmed : `${trimmed}.`;
  const capIdx = punctuated.match(/[\w\d]/)?.index;
  if (typeof capIdx === 'undefined') return punctuated;
  return punctuated.substr(0, capIdx) + punctuated.charAt(capIdx).toLocaleUpperCase() + punctuated.substr(capIdx + 1);
};

/**
 * Given a string, this will return a truncated version that is shorter than the maxLength and ends
 * at the last period.
 * @param {string} str String you want to truncate.
 * @param {number} maxLength Maximum length of the string.
 */
export const truncateToLastPeriod = (str, maxLength) => {
  if (!str) return str;
  if (str.length <= maxLength) {
    return str;
  }
  // Truncate the string to max length
  const truncated = str.slice(0, maxLength);
  const periodIdx = truncated.lastIndexOf('.');
  if (periodIdx === -1) {
    return `${truncated.slice(0, -3)}...`;
  }
  return `${str.slice(0, periodIdx)}.`;
};

/**
 * Given an array of strings, return a comma separated list with "and"s where appropriate.
 *
 * @param {string[]} arr array of strings
 * @param {bool} oxfordComma include oxford comma
 *
 * @returns {string}
 */
export const arrayToPrettyList = (arr, oxfordComma = false) => {
  if (!arr.length) return '';
  if (arr.length === 1) return arr[0];

  return `${arr.slice(0, arr.length - 1).join(', ')}${oxfordComma ? ',' : ''} and ${arr[arr.length - 1]}`;
};

/**
 * Replaces matched text in a string with a component.
 * Can perform multiple different replacements at the same time
 *
 * @param {string} text The text to perform the search/replace on
 * @param {object[]} replacements Array of objects containing replacement info
 *                                [{find: String, replace: Component}, {...}, etc]
 *
 * @returns {node} Text with components injected and can be rendered by React
 */
export const injectComponent = (text, replacements) => {
  let str = text;
  const inserts = []; // Contains info where to insert each component

  // Find where components should be inserted
  replacements.forEach((item) => {
    let focusStr = str;
    // Remove all other search strings besides current "focused" one.
    // This is important because the indexes of matches will change once other matches are removed from the string
    replacements.forEach((e) => {
      if (e.find !== item.find) {
        focusStr = focusStr.replaceAll(e.find, '');
      }
    });
    const parts = focusStr.split(item.find);
    let charIdx = 0;
    parts.forEach((e, i) => {
      // Log matches to prepare to insert components
      if (i > 0) inserts.push({ idx: charIdx, component: item.replace });
      charIdx += e.length;
    });
    str = str.replaceAll(item.find, ''); // remove found matches original str
  });

  // Sort inserts by index
  inserts.sort((a, b) => a.idx - b.idx);

  // Add last part of str without component. This algorithm inserts a component after each substring
  // of original str. So it would lose the last bit of the if it weren't for this.
  inserts.push({ idx: str.length, component: null });

  let lastIdx = 0;
  // Return the str with components injected
  return inserts.map((e) => {
    const textPart = str.substring(lastIdx, e.idx);
    const returnVal = (
      <Fragment key={e.idx}>
        {textPart}
        {e.component}
      </Fragment>
    );
    lastIdx = e.idx; // Update lastIdx after using it
    return returnVal;
  });
};

/**
 * Checks that users are equal based on slugs.
 *
 * @param {User} user
 * @param {string} slug
 *
 * @returns {bool}
 */
export const userSlugsEqual = (user, slug) => user?.slug?.toLocaleLowerCase() === slug?.toLocaleLowerCase();
