import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as RateGood } from '../../../../assets/images/icons/rate/good.svg';
import { ReactComponent as RateBad } from '../../../../assets/images/icons/rate/bad.svg';
import { PropTypesVBVenueVideo } from '../../../util/types';
import useVbContext from '../../../hooks/vb-context';
import IconButton from '../../IconButton/IconButton';
import { mergeClassNames } from '../../../util/props';
import useRateVideo from './useRateVideo';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './RateVideoMeta.module.scss';

const propTypes = {
  video: PropTypesVBVenueVideo.isRequired,
  isLoading: PropTypes.bool,
};

const VideoRating = {
  BAD: 0,
  GOOD: 1,
  GREAT: 2,
};

const defaultProps = { isLoading: false };

const RateVideoMeta = ({ video, isLoading }) => {
  const { currentUser, openAuthPopup } = useVbContext();
  const rateVideo = useRateVideo();

  const createRateVideoCallback = (rating) => () => {
    if (currentUser) {
      rateVideo(video.id, rating);
    } else {
      openAuthPopup();
    }
  };

  const isActiveRating = (rating) => {
    return video.rating === rating;
  };

  return (
    <div className={styles.rateVideoMeta}>
      <div className={styles.title}>{isLoading ? <Skeleton width="110px" /> : 'Video Helpful?'}</div>
      {!isLoading && (
        <div className={styles.actions}>
          <IconButton text="Bad" onClick={createRateVideoCallback(VideoRating.BAD)}>
            <RateBad className={mergeClassNames(styles.icon, isActiveRating(VideoRating.BAD) && styles.active)} />
          </IconButton>
          <IconButton text="Good" onClick={createRateVideoCallback(VideoRating.GOOD)}>
            <RateGood className={mergeClassNames(styles.icon, isActiveRating(VideoRating.GOOD) && styles.active)} />
          </IconButton>
          <IconButton text="Great" className={styles.great} onClick={createRateVideoCallback(VideoRating.GREAT)}>
            <div className={styles.iconsWrapper}>
              <RateGood className={mergeClassNames(styles.icon, isActiveRating(VideoRating.GREAT) && styles.active)} />
              <RateGood className={mergeClassNames(styles.icon, isActiveRating(VideoRating.GREAT) && styles.active)} />
            </div>
          </IconButton>
        </div>
      )}
    </div>
  );
};

RateVideoMeta.propTypes = propTypes;
RateVideoMeta.defaultProps = defaultProps;

export default RateVideoMeta;
