import QueryString from 'query-string';
import { useLocation } from 'react-router';

/**
 * Hook to get query params.
 *
 * @returns {object} the query params
 */
const useQueryParams = () => {
  const { search } = useLocation();
  return QueryString.parse(search.slice(1));
};
export default useQueryParams;
