import React, { lazy, Suspense } from 'react';
import VBSpinner from '../VBSpinner/VBSpinner';

const Map = lazy(() => import('../Map/Map'));

const MapWrapper = (mapProps) => (
  <Suspense fallback={<VBSpinner center />}>
    <Map {...mapProps} />
  </Suspense>
);

export default MapWrapper;
