import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setHamburgerTransitioning } from '../../../../store/actions/headerActions';
import MaxWidthContainer from '../../../MaxWidthContainer/MaxWidthContainer';
import { mergeClassNames } from '../../../../util/props';
import styles from './HamburgerMenu.module.scss';

const propTypes = {
  whiteLinks: PropTypes.bool,
  onContentClick: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      node: PropTypes.node,
    })
  ).isRequired,
  linksWithDividerUnder: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {};

function HamburgerMenu({ whiteLinks, onContentClick, links, linksWithDividerUnder }) {
  const hamburgerRef = useRef(null);

  const dispatch = useDispatch();

  const { isHamburgerOpen } = useSelector((state) => state.header);

  const hamburgerContent = useMemo(
    () =>
      links.map(({ name, node }) => (
        <React.Fragment key={name}>
          {node}
          {linksWithDividerUnder.includes(name) && <hr className={styles.hamburgerDivider} />}
        </React.Fragment>
      )),
    [links, linksWithDividerUnder]
  );

  useEffect(() => {
    const hamburgerNode = hamburgerRef.current;

    const handleTransitionStart = () => {
      dispatch(setHamburgerTransitioning(true));
    };

    const handleTransitionEnd = () => {
      dispatch(setHamburgerTransitioning(false));
    };

    hamburgerNode?.addEventListener('transitionstart', handleTransitionStart);
    hamburgerNode?.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      hamburgerNode?.removeEventListener('transitionstart', handleTransitionStart);
      hamburgerNode?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [dispatch]);

  return (
    <MaxWidthContainer>
      <div
        className={mergeClassNames(
          styles.hamburgerDropdown,
          isHamburgerOpen && styles.open,
          whiteLinks && styles.white
        )}
        onClick={onContentClick}
        data-testid="hamburger-content"
        ref={hamburgerRef}
      >
        {hamburgerContent}
      </div>
    </MaxWidthContainer>
  );
}

HamburgerMenu.propTypes = propTypes;
HamburgerMenu.defaultProps = defaultProps;

export default HamburgerMenu;
