import React, { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import YtButton from './YtButton/YtButton';
import useHover from '../../../../hooks/hover';
import VBSpinner from '../../../VBSpinner/VBSpinner';

import styles from './YtThumbnail.module.scss';

const propTypes = {
  /* the thumbnail to show for the Youtube video */
  image: PropTypes.string.isRequired,

  /** click callback */
  onClick: PropTypes.func,

  /** whether or not to display a spinner instead of the YouTube logo */
  isLoading: PropTypes.bool,
};

/**
 * Displays a youtube video thumbnail with a video icon in the corner.
 */
const YtThumbnail = ({ image, onClick, isLoading }) => {
  const [thumbnailContainerRef, isHovering] = useHover();

  // If a click handler is passed in, fill an object with that and a11y attributes.
  const clickProps = useMemo(
    () =>
      !onClick
        ? null
        : {
            onClick,
            tabIndex: 0,
            role: 'button',
          },
    [onClick]
  );

  return (
    <div className={styles.container} ref={thumbnailContainerRef} {...clickProps}>
      <LazyLoadImage className={styles.image} src={image} alt="Youtube video thumbnail" width="320" height="180" />
      {isLoading ? (
        <VBSpinner className={styles.ytButton} />
      ) : (
        <YtButton className={styles.ytButton} isRed={isHovering} />
      )}
    </div>
  );
};

YtThumbnail.propTypes = propTypes;

export default YtThumbnail;
