import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { mergeClassNames } from '../../util/props';
import styles from './VBDropdown.module.scss';

/**
 * Component representing a dropdown, with VB style on it. This is basically just the children wrapped by a
 * Bootstrap Dropdown Menu component with some style. Used by useDropdown.
 *
 * @param {string} props.className additional classNames for the dropdown
 */
const VBDropdown = (props) => {
  const { children, className, ...other } = props;
  return (
    <Dropdown.Menu {...other} className={mergeClassNames(styles.dropdown, className)}>
      {children}
    </Dropdown.Menu>
  );
};
VBDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default VBDropdown;
