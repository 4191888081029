import React from 'react';
import PropTypes from 'prop-types';
import SimpleSplitOption from './SimpleSplitOption/SimpleSplitOption';
import styles from './SplitContainer.module.scss';

const propTypes = {
  /** the current filter object, can be any key value pairs */
  filter: PropTypes.object,

  /** function to set the filter and offset */
  setFilter: PropTypes.func,

  /** the field in the filter that is being changed. If the property represents an array in the filter, multiple items
   *  can be selected. See the age filter on PageExplore in Itineraries.
   */
  property: PropTypes.string,

  /** data object, for example: [{label: "All", value: null}, {label: "Hotels", value: "hotels"}, ...] */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),

  /** if data was not provided, this will be in the split container */
  children: PropTypes.node,

  /** whether or not the buttons should be small */
  smallButtons: PropTypes.bool,

  /** filter attributes to always include in the new filter, ie {hiddenGems: false} */
  always: PropTypes.object,
};

const defaultProps = {
  filter: undefined,
  setFilter: undefined,
  property: undefined,
  data: undefined,
  children: 'Splits',
  smallButtons: false,
  always: undefined,
};

/**
 * A container for simple split buttons. You can either add options using props, or you can add your own options
 * using children.
 *
 * @param {object} filter
 */
const SplitContainer = (props) => {
  const { filter, setFilter, data, property, children, always, smallButtons } = props;
  return (
    <div className={styles.container}>
      {data
        ? data.map((option) => (
            <SimpleSplitOption
              key={option.value}
              filter={filter}
              setFilter={setFilter}
              property={property}
              small={smallButtons}
              always={always}
              {...option}
            />
          ))
        : children}
    </div>
  );
};
export default SplitContainer;

SplitContainer.propTypes = propTypes;
SplitContainer.defaultProps = defaultProps;
