import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import LegalAgreement from '../LegalAgreement/LegalAgreement';
import styles from './SocialLoginFinish.module.scss';
import { useVBBreakpoint } from '../../../../hooks/vb-breakpoint';
import VBSpinner from '../../../VBSpinner/VBSpinner';
import VBTextInput from '../../../VBTextInput/VBTextInput';
import VBButton from '../../../VBButton/VBButton';
import { mergeClassNames } from '../../../../util/props';
import { RECAPTCHA_PUBLIC_KEY } from '../../../../config/security';
import { isTesting } from '../../../../util/env';
import ContentLengths from '../../../../../../shared-react/config-json/content-lengths.json';
import RecaptchWrapper from '../../../RecaptchaWrapper/RecaptchaWrapper';

/**
 * A page of the Sign Up popup asking for the user's username.
 *
 * @param {function} props.registerUser            registers the user using the sign up field information
 * @param {boolean}  props.loading                 whether or not the loading spinner should be shown
 * @param {object}   props.fields                  input fields and their setters
 * @param {string}   props.fields.usernameInput    value of the Username field
 * @param {function} props.fields.setUsernameInput sets the Username field
 * @param {boolean}  props.fields.registerCaptchaResult     captcha value
 * @param {function} props.fields.setRegisterCaptchaResult  sets registerCaptchaResult
 * @param {function} props.setNotice  parent setNotice
 * @param {ref}      props.registerCaptchaRef ref to use for the captcha
 */
const SocialLoginFinish = ({ registerUser, loading, fields, setNotice, registerCaptchaRef }) => {
  const { usernameInput, setUsernameInput, registerCaptchaResult, setRegisterCaptchaResult } = fields;

  const { gteXl } = useVBBreakpoint();

  const getValidationError = React.useCallback(() => {
    if (!registerCaptchaResult && !isTesting()) {
      return { message: 'Invalid CAPTCHA.', variant: 'danger' };
    }

    return null;
  }, [registerCaptchaResult]);

  /**
   * Handle click on the submit button.
   * This will send the activation email and move to the next page in the Sign Up process.
   *
   * @param {SyntheticEvent} e the event
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = getValidationError();

    if (validationError) {
      setNotice(validationError);
    } else {
      registerUser();
    }

    return false;
  };

  return (
    <>
      <div className={mergeClassNames('d-flex', gteXl ? 'flex-row' : 'flex-column-reverse')}>
        <div className={styles.content}>
          <h1>Finish Sign Up</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="signUpUsername">
              <VBTextInput
                type="text"
                placeholder="Username"
                maxLength={ContentLengths.PROFILE_USERNAME}
                value={usernameInput}
                onChange={(e) => setUsernameInput(e.target.value)}
                required
              />
            </Form.Group>
            <div className={styles.captchaContainer}>
              <RecaptchWrapper
                sitekey={RECAPTCHA_PUBLIC_KEY}
                onChange={(x) => setRegisterCaptchaResult(x)}
                ref={registerCaptchaRef}
              />
            </div>
            {loading ? (
              <div
                className={mergeClassNames(
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  styles.spinnerContainer
                )}
              >
                <VBSpinner />
              </div>
            ) : (
              <VBButton className="my-2" size="med" submit>
                Get My Travel On!
              </VBButton>
            )}
          </Form>
          <br />
          <LegalAgreement />
        </div>
        <Sidebar imageType="benefits" />
      </div>
    </>
  );
};
SocialLoginFinish.propTypes = {
  registerUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.exact({
    usernameInput: PropTypes.string.isRequired,
    setUsernameInput: PropTypes.func.isRequired,
    registerCaptchaResult: PropTypes.string.isRequired,
    setRegisterCaptchaResult: PropTypes.func.isRequired,
  }).isRequired,
  setNotice: PropTypes.func.isRequired,
  registerCaptchaRef: PropTypes.object.isRequired,
};

export default SocialLoginFinish;
