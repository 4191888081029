import React from 'react';
import { isSafari, isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

/**
 * Contains meta tags for anything other than search engine crawlers and opengraph.
 */
const DynamicMeta = () => {
  // This tag disables automatic zoom for textboxes on IOS Safari. According to this, it should
  // still allow the user to pinch zoom: https://stackoverflow.com/a/46254706
  const safariZoomFix = isSafari && isMobile && (
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  );

  return <Helmet>{safariZoomFix}</Helmet>;
};

export default DynamicMeta;
