import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoContent.module.scss';
import { mergeClassNames } from '../../util/props';
import VBButton from '../VBButton/VBButton';

const propTypes = {
  /** posts, venues, infographics, etc */
  typePlural: PropTypes.string.isRequired,

  /** additional class names */
  className: PropTypes.string,

  /** whether or not to center */
  noCenter: PropTypes.bool,

  /** Text to display on a button to create a type of content */
  createPrompt: PropTypes.string,

  /** Callback function for when the button is clicked */
  createCallback: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  noCenter: false,
  createPrompt: undefined,
  createCallback: undefined,
};

/**
 * Shows a message that there are no instances of a certain type of content.
 */
const NoContent = ({ typePlural, className, noCenter, createPrompt, createCallback }) => (
  <div className={mergeClassNames(styles.container, noCenter ? styles.noCenter : null, className)}>
    No {typePlural}.
    {createPrompt && createCallback && (
      <VBButton content={createPrompt} onClick={createCallback} className={styles.button} />
    )}
  </div>
);

export default NoContent;
NoContent.propTypes = propTypes;
NoContent.defaultProps = defaultProps;
