export const SET_PAGE_ITEM_ID = 'vb/pageItemId/SET_PAGE_ITEM_ID';
export const CLEAR_PAGE_ITEM_ID = 'vb/pageItemId/CLEAR_PAGE_ITEM_ID';

export const ITEMS_REQUESTED = 'vb/api/ITEMS_REQUESTED';
export const ITEMS_RECEIVED = 'vb/api/ITEMS_RECEIVED';
export const ITEMS_FAILED = 'vb/api/ITEMS_FAILED';
export const ITEMS_CLEAR = 'vb/api/ITEMS_CLEAR';
export const SINGLE_REQUESTED = 'vb/api/SINGLE_REQUESTED';
export const SINGLE_RECEIVED = 'vb/api/SINGLE_RECEIVED';
export const SINGLE_FAILED = 'vb/api/SINGLE_FAILED';
export const SINGLE_STORE = 'vb/api/SINGLE_STORE';
export const ITEMS_STORE = 'vb/api/ITEMS_STORE';
export const UPDATE_ITEM = 'vb/api/UPDATE_ITEM';
export const ADD_ITEM = 'vb/api/ADD_ITEM';
export const REMOVE_ITEM = 'vb/api/REMOVE_ITEM';
export const REMOVE_FROM = 'vb/api/REMOVE_FROM';
export const ITEMS_INVALIDATE = 'vb/api/ITEMS_INVALIDATE';
export const ADD_TO = 'vb/api/ADD_TO';
export const ITEMS_REFRESHING = 'vb/api/ITEMS_REFRESHING';
export const ITEMS_REFRESHED = 'vb/api/ITEMS_REFRESHED';

export const SET_TOKEN = 'vb/authToken/SET_TOKEN';
export const CLEAR_TOKEN = 'vb/authToken/CLEAR_TOKEN';

export const CURRENT_USER_RECEIVED = 'vb/currentUser/CURRENT_USER_RECEIVED';
export const CURRENT_USER_LOADING = 'vb/currentUser/CURRENT_USER_LOADING';
export const CURRENT_USER_ERROR = 'vb/currentUser/CURRENT_USER_ERROR';

// Global location
export const START_LOADING = 'vb/globalLocation/START_LOADING';
export const SET_LOCATION = 'vb/globalLocation/SET_LOCATION';

// Auth popup
export const CLOSE_POPUP = 'vb/authPopupOpen/CLOSE_POPUP';
export const OPEN_POPUP = 'vb/authPopupOpen/OPEN_POPUP';

// Header
export const SET_HEADER_HEIGHT = 'vb/header/SET_HEADER_HEIGHT';
export const SET_HEADER_VISIBILITY = 'vb/header/SET_HEADER_VISIBILITY';
export const SET_HEADER_VISIBILITY_MODE = 'vb/header/SET_HEADER_VISIBILITY_MODE';
export const SET_HEADER_HAMBURGER_VISIBILITY = 'vb/header/SET_HEADER_HAMBURGER_VISIBILITY';
export const TOGGLE_HEADER_HAMBURGER_VISIBILITY = 'vb/header/TOGGLE_HEADER_HAMBURGER_VISIBILITY';
export const SET_HEADER_HAMBURGER_TRANSITIONING = 'vb/header/SET_HEADER_HAMBURGER_TRANSITIONING';

// Progressively loaded image cache
export const ADD_PROGRESSIVE_IMAGE = 'vb/progressiveImages/ADD';

// Preserving scroll position.
export const SHOULD_PRESERVE_SCROLL_POSITION = 'vb/scrollPositionPreserve/SHOULD_PRESERVE';
export const CLEAR_PRESERVE_SCROLL_POSITION = 'vb/scrollPositionPreserve/CLEAR';

// Get the user location
export const USER_LOCATION_LOADING = 'vb/userLocation/LOADING';
export const USER_LOCATION_LOADING_FINISHED = 'vb/userLocation/LOADING_FINISHED';
export const SET_USER_COORDS = 'vb/userLocation/SET_COORDS';
export const SET_USER_CITY = 'vb/userLocation/SET_CITY';

// Notifications
export const READ_NOTIFICATIONS = 'vb/notifications/READ';
export const HAS_UNREAD_NOTIFICATION = 'vb/notifications/UNREAD';

// Preload seed.
export const CLEAR_PRELOAD_SEED = 'vb/preloadSeed/CLEAR';
