import { PropTypesVBVenue } from '../../../../../../shared-react/src/util/types';
import { useVBBreakpoint } from '../../../../../../shared-react/src/hooks/vb-breakpoint';
import React from 'react';
import PropTypes from 'prop-types';
import VenueCard from '../../../../../../shared-react/src/components/VenueCard/VenueCard';

const propTypes = {
  /** item to render */
  item: PropTypesVBVenue.isRequired,

  /** index of the item within the content */
  index: PropTypes.number.isRequired,

  /** the filter that was used to query the item */
  filter: PropTypes.object,

  /** contextual data unique to each content factory */
  contextual: PropTypes.any,
};

const defaultProps = {
  contextual: undefined,
  filter: undefined,
};

/**
 * Venue rendered by PageExplore.
 *
 * @param {object} props
 */
const RenderedVenueHomepage = ({ item, index, filter, contextual }) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();

  return (
    <VenueCard
      venue={item}
      rank={index >= 25 || filter.hiddenGems ? undefined : index + 1}
      isHiddenGem={index >= 25 || filter.hiddenGems}
      distance={contextual.distance}
      direction={contextual.direction}
      disablePadding={mobileStyle}
      nearMe={filter.nearMe}
      titleSize="sm"
      showDescription
    />
  );
};

export default RenderedVenueHomepage;
RenderedVenueHomepage.propTypes = propTypes;
RenderedVenueHomepage.defaultProps = defaultProps;
