import PropTypes from 'prop-types';
import VenueCard from '../../../../../../../shared-react/src/components/VenueCard/VenueCard';
import useVBBreakpoint from '../../../../../../../shared-react/src/hooks/vb-breakpoint';
import UserListing from '../../../../../../../shared-react/src/components/UserListing/UserListing';
import { getDateString } from '../../../../../../../shared-react/src/util/time';
import { mergeClassNames } from '../../../../../../../shared-react/src/util/props';
import Article from '../../../Article/Article';

import styles from './RenderedArticlePost.module.scss';

const propTypes = {
  item: PropTypes.object.isRequired,
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
  locationDetails: PropTypes.object.isRequired,
};

const RenderedArticlePost = ({ item, exploreLink, exploreLinkText, locationDetails }) => {
  const { lteXs: mobileStyle } = useVBBreakpoint();

  return (
    <div>
      <VenueCard
        venue={item}
        titleSize="sm"
        exploreLink={exploreLink}
        exploreLinkText={exploreLinkText}
        disablePadding={mobileStyle}
        noBottomPadding
        showDescription
        fullDescription
      />
      <div>
        <h2 className={mergeClassNames(styles.postTitle, styles.title)}>{item.post.title}</h2>
        <div className={styles.meta}>
          <div className={styles.sectionEditor}>
            Section Editor: <UserListing user={item.post.author} showUsername />
          </div>
          <div>{getDateString(item.post.date)}</div>
        </div>
        <ul className={styles.articles}>
          {item.articles.filter(Boolean).map((article) => (
            <li key={article.id}>
              <Article article={article} locationDetails={locationDetails} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

RenderedArticlePost.propTypes = propTypes;

export default RenderedArticlePost;
