import SITES from '../../../shared-react/config-json/sites.json';

export const GOOGLE_ANALYTICS = {
  [SITES.ITINERARIES]: 'G-V29E1WJ10E',
  [SITES.KIDS]: 'G-KC1SYL66HR',
};

export const GOOGLE_ADS = {
  [SITES.KIDS]: {
    tag: 'AW-10869610198',
    conversions: {
      vb_auth_register: 'P9EOCOOBl74DENathL8o',
    },
  },
};

export const HOTJAR = {
  [SITES.KIDS]: {
    hjid: 3042166,
    hjsv: 6,
  },
};
