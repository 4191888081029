import * as React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import QueryString from 'query-string';
import SignUp from './SignUp/SignUp';
import ConfirmEmail from './SignUp/ConfirmEmail/ConfirmEmail';
import SignIn from './SignIn/SignIn';
import ForgotPassword from './SignIn/ForgotPassword/ForgotPassword';
import styles from './PopupAuth.module.scss';
import SocialLoginFinish from './SignUp/SocialLoginFinish/SocialLoginFinish';
import APIConfig from '../../config/api';
import { PropTypesAuthPopupPages, PopupAuthPage } from '../../util/types';
import { VBReactContext } from '../VBReactProvider/VBReactProvider';
import VBAlert from '../VBAlert/VBAlert';
import { useVBBreakpoint } from '../../hooks/vb-breakpoint';
import { mergeClassNames } from '../../util/props';
import { isTesting } from '../../util/env';
import { TEST_RECAPTCHA_TOKEN } from '../../config/security';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';

const propTypes = {
  page: PropTypesAuthPopupPages,
  /**
   * What to do after a social register.
   * (res) => void
   */
  handleSocialRegister: PropTypes.func.isRequired,
  /** ({username, password}, {captcha}) => Promise */
  handleLogin: PropTypes.func.isRequired,
};
const defaultProps = {
  page: PopupAuthPage.SIGN_IN,
};

const emptyNotice = { message: '', variant: null };

const TEMP_EMAIL_LIST_URL =
  'https://gist.githubusercontent.com/michenriksen/8710649/raw/e09ee253960ec1ff0add4f92b62616ebbe24ab87/disposable-email-provider-domains';

/**
 * The popup prompting the user to log in or sign up.
 */
const PopupAuth = ({ page, handleSocialRegister, handleLogin }) => {
  const registerCaptchaRef = React.useRef();

  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const [currentPage, setCurrentPageState] = React.useState(page);
  // URL to the google consent page.
  const [googleLoginURL, setGoogleLoginURL] = React.useState();

  const [notice, setNotice] = React.useState(emptyNotice);
  const [loading, setLoading] = React.useState(false);

  // Input control
  const [emailInput, setEmailInput] = React.useState('');
  const [passwordInput, setPasswordInput] = React.useState('');
  const [usernameInput, setUsernameInput] = React.useState('');

  // Registration captcha state
  const [registerCaptchaResult, setRegisterCaptchaResult] = React.useState();

  const [signupId, setSignupId] = React.useState();

  // Temporary emails
  const [tempEmails, setTempEmails] = React.useState([]);

  const { vbRequest, errorToast, raiseToast } = React.useContext(VBReactContext);

  const { lteLg, lteSm } = useVBBreakpoint();

  const { location } = window;

  // Remove the notice if the user goes to a different page.
  const setCurrentPage = React.useCallback(
    (page) => {
      setNotice(emptyNotice);
      setCurrentPageState(page);
    },
    [setCurrentPageState]
  );

  const [isPopupOpenEventSent, setIsPopupOpenEventSent] = React.useState(false);

  const displayNotice = React.useCallback(
    (notice) => {
      setNotice(notice);
      if (notice.message && lteSm) {
        if (notice.variant === 'success') {
          raiseToast(notice.message, 'Success');
        } else if (notice.variant === 'danger') {
          errorToast(notice.message, 'Error');
        }
      }
    },
    [errorToast, raiseToast, setNotice, lteSm]
  );

  const googleLogin = React.useMemo(
    () =>
      googleLoginURL
        ? () => {
            Cookies.set('vb-slogin-redirect', location.pathname + location.search);
            window.location = googleLoginURL;
          }
        : null,
    [googleLoginURL, location]
  );

  /**
   * Sends an activation email.
   */
  const sendActivationEmail = React.useCallback(
    (usernameOrEmail = null) => {
      // Should always succeed so just say it did.
      displayNotice({ message: 'Activation email sent', variant: 'success' });

      return vbRequest('vb/v1/login/resend-activation', {
        method: 'POST',
        params: { signupId, usernameOrEmail },
      }).catch((err) => {
        // Just in case it doesn't work, display the error.
        displayNotice({ message: err.message, variant: 'danger' });
      });
    },
    [displayNotice, signupId, vbRequest]
  );

  /**
   * Registers the user.
   */
  const registerUser = React.useCallback(() => {
    setLoading(true);

    const requestBody = {
      username: usernameInput,
      email: emailInput,
      password: passwordInput,
      refertoken: Cookies.get('vb-refer-token'),
      captcha: isTesting() ? TEST_RECAPTCHA_TOKEN : registerCaptchaResult,
      page: location.pathname + location.search,
    };

    vbRequest('vb/v1/login/sign-up', { method: 'POST', body: requestBody })
      .then(({ message, code, signupId: responseSignupId }) => {
        if (code) {
          setCurrentPage(PopupAuthPage.CONFIRM_EMAIL);
          sendAnalyticsEvent('vb_auth_register', 'vb_auth');
        } else {
          displayNotice({ message, variant: 'danger' });
          registerCaptchaRef.current.reset();
        }

        if (responseSignupId) {
          setSignupId(responseSignupId);
        }
      })
      .catch((err) => {
        displayNotice({ message: err.message || err, variant: 'danger' });
      })
      .finally(() => setLoading(false));
  }, [
    displayNotice,
    emailInput,
    location,
    passwordInput,
    registerCaptchaResult,
    sendAnalyticsEvent,
    setCurrentPage,
    usernameInput,
    vbRequest,
  ]);

  /**
   * Registers the user.
   * Calls sendActivationEmail if the information is valid.
   */
  const registerUserSlogin = React.useCallback(() => {
    setLoading(true);

    const params = QueryString.parse(location.search);

    const requestBody = {
      username: usernameInput,
      service: params.slogin,
      token: params.token,
      refertoken: Cookies.get('vb-refer-token'),
      captcha: isTesting() ? TEST_RECAPTCHA_TOKEN : registerCaptchaResult,
      page: location.pathname + location.search,
    };

    vbRequest(`${APIConfig.NAMESPACE.SLOGIN}/register`, { method: 'POST', body: requestBody })
      .then((res) => {
        // Either has token (itineraries) or userId (ViewBuff).
        handleSocialRegister(res);
      })
      .catch((err) => {
        displayNotice({ message: err.message || err, variant: 'danger' });
      })
      .finally(() => setLoading(false));
  }, [displayNotice, handleSocialRegister, location, registerCaptchaResult, vbRequest, usernameInput]);

  const authContent = React.useMemo(() => {
    switch (currentPage) {
      case PopupAuthPage.SIGN_UP: {
        return (
          <SignUp
            setCurrentPage={setCurrentPage}
            fields={{
              usernameInput,
              emailInput,
              passwordInput,
              registerCaptchaResult,
              setUsernameInput,
              setEmailInput,
              setPasswordInput,
              setRegisterCaptchaResult,
            }}
            tempEmails={tempEmails}
            googleLogin={googleLogin}
            setNotice={displayNotice}
            registerUser={registerUser}
            registerCaptchaRef={registerCaptchaRef}
          />
        );
      }
      case PopupAuthPage.CONFIRM_EMAIL: {
        return <ConfirmEmail setNotice={displayNotice} sendActivationEmail={sendActivationEmail} />;
      }
      case PopupAuthPage.FORGOT_PASSWORD: {
        return <ForgotPassword setNotice={displayNotice} />;
      }
      case PopupAuthPage.SOCIAL_LOGIN: {
        return (
          <SocialLoginFinish
            registerUser={registerUserSlogin}
            loading={loading}
            fields={{
              usernameInput,
              setUsernameInput,
              registerCaptchaResult,
              setRegisterCaptchaResult,
            }}
          />
        );
      }
      default: {
        return (
          <SignIn
            setNotice={displayNotice}
            setCurrentPage={setCurrentPage}
            googleLogin={googleLogin}
            handleLogin={handleLogin}
            sendActivationEmail={sendActivationEmail}
          />
        );
      }
    }
  }, [
    currentPage,
    displayNotice,
    emailInput,
    googleLogin,
    handleLogin,
    loading,
    passwordInput,
    registerCaptchaResult,
    registerUser,
    registerUserSlogin,
    sendActivationEmail,
    setCurrentPage,
    tempEmails,
    usernameInput,
  ]);

  // Get the google login url to the consent page.
  React.useEffect(() => {
    vbRequest(`${APIConfig.NAMESPACE.SLOGIN}/redirect-url`, { params: { service: 'google' } })
      .then(({ result }) => setGoogleLoginURL(result))
      .catch((err) => {
        // errorToast(err.message);
      });
  }, [vbRequest]);

  // Get the list of temp emails
  React.useEffect(() => {
    fetch(TEMP_EMAIL_LIST_URL)
      .then((response) => {
        return response.text();
      })
      .then((data) => setTempEmails(data.split('\n')))
      .catch((err) => {
        console.error(err);
      });
  }, [vbRequest]);

  React.useEffect(() => {
    if (currentPage !== PopupAuthPage.SIGN_UP || isPopupOpenEventSent) {
      return;
    }

    sendAnalyticsEvent('vb_auth_open_register', 'vb_auth');
    setIsPopupOpenEventSent(true);
  }, [currentPage, isPopupOpenEventSent, sendAnalyticsEvent]);

  React.useEffect(() => {
    setRegisterCaptchaResult(null);
  }, [currentPage]);

  // const alertMessage = useMemo(() => {
  //   if (!notice.message) return null;
  //   // Replace <RESEND_LINK> with a react element.
  //   return message;
  // }, [notice.message, sendActivationEmail])

  return (
    <>
      {lteSm ? null : <VBAlert variant={notice.variant} content={notice.message} />}
      <div className={mergeClassNames(styles.auth, lteLg && styles.lteLgBreakpoint)}>{authContent}</div>
    </>
  );
};

PopupAuth.WIDTH = 'lg';
PopupAuth.propTypes = propTypes;
PopupAuth.defaultProps = defaultProps;

export default PopupAuth;
