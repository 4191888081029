import React from 'react';
import { decode as decodeHtmlEntities } from 'html-entities';
import PropTypes from 'prop-types';

const propTypes = {
  /** value to decode */
  decode: PropTypes.string,

  /** prop name to put value on in the component */
  decodeName: PropTypes.string,

  /** component to render */
  component: PropTypes.func,
};

/**
 * Renders a component with a prop decoded with html-entities. Used in the venue video card. Recommend lazy loading
 * this.
 */
const WithHtmlDecodedProp = ({ decode, decodeName, component: C, ...other }) => (
  <C {...{ [decodeName]: decodeHtmlEntities(decode) }} {...other} />
);

export default WithHtmlDecodedProp;
WithHtmlDecodedProp.propTypes = propTypes;
