import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useHeaderHeight } from '../util/size';
import useWindowScroll from './window-scroll';

/**
 * @typedef UseMapSyncedYScrollArgs
 * @param {bool} onlyShowIcon whether to only show the map icon, rather than the full map
 * @param {object[]} content content array from a useContentFactory
 * @param {(entry: object,
 *          index: number,
 *          handleClickNext: (): void,
 *          handleClickPrevious: (): void,
 *          isFullscreen: bool
 *         ): React.Node} renderItem function to render the card
 * @param {bool} disabled
 */

/**
 * @typedef UseMapSyncedYScrollReturn
 * @param {React.Ref[]} cardRefs the refs to give the cards
 * @param {object} mapProps props to put on a Map component
 * @param {number} selection selection index
 * @param {(selection: number): void} setSelection set the selection index
 * @param {bool} showMap whether the map should be rendered
 * @param {bool} syncScroll whether or not to sync the scroll with the map
 */

/**
 *
 * @param {UseMapSyncedYScrollArgs} args
 * @returns {UseMapSyncedYScrollReturn}
 */
const useMapSyncedYScroll = ({
  onlyShowIcon,
  content,
  renderItem,
  disabled,
  syncScroll,
  centerScrollTest,
  // Note: this should remain the same through the life of the component for stuff to work properly
  allowNullSelection,
}) => {
  const [mapSelection, setMapSelection] = useState(null);
  const [scrollSelection, setScrollSelection] = useState(!allowNullSelection ? 0 : null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [latestSelectionChange, setLatestSelectionChange] = useState('parent');
  const [autoScrollingInterval, setAutoScrollingInterval] = useState();
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);

  const headerHeight = useHeaderHeight();

  const mapLocations = useMemo(() => {
    const items = [];

    content.forEach(({ item }, idx) => {
      if (!item) {
        return;
      }

      const entry = items.find(({ id }) => id === item.id);

      if (entry) {
        entry.indices.push(idx);
      } else {
        items.push({ id: item.id, indices: [idx] });
      }
    });

    return items.map(({ indices }) => {
      const entry = content[indices[0]];
      const { item } = entry;

      return {
        latitude: item.latitude,
        longitude: item.longitude,
        name: item.name,
        indices,
        renderCard: (handleClickNext, handleClickPrevious, isFullscreen) =>
          renderItem(entry, indices[0], handleClickNext, handleClickPrevious, isFullscreen),
      };
    });
  }, [content, renderItem]);

  const cardRefs = useMemo(() => new Array(content.length).fill(0).map(() => createRef()), [content]);

  /**
   * Given a selection index, get the ideal Y scroll.
   */
  const getScrollY = useCallback(
    (newSelection) => {
      if (!cardRefs.length) return 0;
      const firstCardDim = cardRefs[0].current.getBoundingClientRect();
      const lastCardDim = cardRefs[cardRefs.length - 1].current.getBoundingClientRect();
      const selectionCardDim = cardRefs[newSelection].current.getBoundingClientRect();
      const documentLastCardDimBottom = lastCardDim.bottom + window.scrollY;
      const documentFirstCardDimTop = firstCardDim.top + window.scrollY;
      const documentSelectionCardDimTop = selectionCardDim.top + window.scrollY;
      const documentBboxTop = window.scrollY;

      // This is the equation in useWindowScroll solved for the window scrollY.
      // See shared-react/doc/reverse-algo-work.txt
      if (!centerScrollTest)
        return (
          (2 * headerHeight ** 2 +
            headerHeight *
              (firstCardDim.height -
                selectionCardDim.height -
                2 * (window.innerHeight - documentLastCardDimBottom + documentSelectionCardDimTop)) +
            firstCardDim.height * (documentLastCardDimBottom - window.innerHeight) +
            lastCardDim.height * documentFirstCardDimTop +
            selectionCardDim.height * window.innerHeight +
            selectionCardDim.height * documentFirstCardDimTop -
            selectionCardDim.height * documentLastCardDimBottom -
            2 * window.innerHeight * documentFirstCardDimTop +
            2 * window.innerHeight * documentSelectionCardDimTop +
            2 * documentFirstCardDimTop * documentSelectionCardDimTop -
            2 * documentLastCardDimBottom * documentSelectionCardDimTop) /
          (firstCardDim.height + lastCardDim.height + 2 * documentFirstCardDimTop - 2 * documentLastCardDimBottom)
        );

      return 2 * (headerHeight + (window.innerWidth - headerHeight) / 2 - (documentBboxTop - window.scrollY));
    },
    [cardRefs, headerHeight, centerScrollTest]
  );

  /**
   * Does not stop the auto-scroll. Rather, cancels the interval to reset state and resets the auto-scroll state
   * immediately.
   */
  const cancelAutoScroll = useCallback(
    (interval) => {
      const iv = interval || autoScrollingInterval;
      setIsAutoScrolling(false);
      if (iv) clearInterval(iv);
      setAutoScrollingInterval(null);
    },
    [autoScrollingInterval]
  );

  /**
   * Scroll to a Y position, updating the relevant state along the way.
   */
  const scrollTo = useCallback(
    (scrollY, smooth = false) => {
      if (!smooth) {
        cancelAutoScroll();
        // On Safari, smooth scroll is not specified by behaviour but instead of which "variation" of the scrollTo
        // function you use. The 2 arg version (absolute position) will not smooth scroll, while the 1 arg version
        // (relative) will.
        window.scrollTo(0, window.scrollY - scrollY);
        return;
      }

      setIsAutoScrolling(true);

      window.scrollTo({
        top: scrollY,
        behavior: 'smooth',
      });

      let windowPos;
      const interval = setInterval(() => {
        if (windowPos === window.scrollY) {
          cancelAutoScroll(interval);
        }
        windowPos = window.scrollY;
      }, 100);
      setAutoScrollingInterval(interval);
    },
    [cancelAutoScroll]
  );

  // Update the scroll selection if the map selection changes, update the map selection if the scroll selection changes.
  useEffect(() => {
    if (disabled) {
      return;
    }

    if (mapLocations.length <= mapSelection) {
      return;
    }

    const selectedLocation = mapLocations[mapSelection];

    if (!selectedLocation?.indices.includes(scrollSelection)) {
      switch (latestSelectionChange) {
        case 'map': {
          // Set the scroll select to the closest location.
          const newScrollSelection = mapLocations[mapSelection].indices.sort(
            (a, b) => Math.abs(a - scrollSelection) - Math.abs(b - scrollSelection)
          )[0];

          setScrollSelection(newScrollSelection);

          if (syncScroll) {
            scrollTo(getScrollY(newScrollSelection), !isMapFullscreen);
          }

          break;
        }
        case 'scroll': {
          if (syncScroll) {
            const newMapSelection = mapLocations.findIndex(({ indices }) => indices.includes(scrollSelection));
            setMapSelection(newMapSelection);
          }

          break;
        }
        default:
      }
    }
  }, [
    content,
    getScrollY,
    latestSelectionChange,
    mapLocations,
    mapSelection,
    isMapFullscreen,
    scrollSelection,
    scrollTo,
    syncScroll,
    disabled,
  ]);

  const refreshScrollPos = useCallback(() => {
    if (disabled) return;
    if (!cardRefs.length || !cardRefs[0].current || !cardRefs[cardRefs.length - 1].current) return;
    if (isAutoScrolling) return;
    if (!allowNullSelection && scrollSelection === 0 && (window.pageYOffset || document.documentElement.scrollTop) < 1)
      return;

    // The idea here is to find a reasonable spot on the viewport to check for cards (the higher the user is in the
    // list, the higher this position will be up to half the height of the first card).

    // Note: If you change the algorithm for deriving viewportCheck, you will need to update the reverse of the
    // algorithm.
    // See shared-react/doc/reverse-algo-work.txt

    let viewportCheck = headerHeight + (window.innerHeight - headerHeight) / 2;
    if (!centerScrollTest) {
      // Beginning and end of card bboxes.
      const firstCardDim = cardRefs[0].current.getBoundingClientRect();
      const lastCardDim = cardRefs[cardRefs.length - 1].current.getBoundingClientRect();

      // Viewport Y to check to determine the current card will depend on how far the user has scrolled. This defines a
      // start and end to this range.
      const startViewportCheck = headerHeight + firstCardDim.height / 2;
      const endViewportCheck = window.innerHeight - lastCardDim.height / 2;

      // Scroll Y and inner height with the header height taken into account.
      const contentScrollY = window.scrollY - headerHeight;
      const contentHeight = window.innerHeight - headerHeight;

      // Start and end of content relative to document.
      const documentContentStart = firstCardDim.top + contentScrollY;
      const documentContentEnd = lastCardDim.bottom + contentScrollY;

      // How far the user has scrolled through the cards (0 - 1).
      const scrollProgress = Math.max(
        Math.min(
          (contentScrollY - documentContentStart) / (documentContentEnd - documentContentStart - contentHeight),
          1
        ),
        0
      );

      // Put it all together to get the Y in the viewport to check.
      viewportCheck = startViewportCheck + scrollProgress * (endViewportCheck - startViewportCheck);
    }

    // Set scroll position based on which card is at viewportCheck.
    let brk = false;
    cardRefs.forEach((cardRef, idx) => {
      if (brk || !cardRef.current) return;
      const { bottom, top } = cardRef.current.getBoundingClientRect();
      if (idx === 0 && viewportCheck < top && allowNullSelection) {
        setLatestSelectionChange('scroll');
        setScrollSelection(null);
        brk = true;
      } else if (bottom > viewportCheck) {
        setLatestSelectionChange('scroll');
        setScrollSelection(idx);
        brk = true;
      } else if (idx === cardRefs.length - 1 && viewportCheck > bottom && allowNullSelection) {
        setLatestSelectionChange('scroll');
        setScrollSelection(null);
        brk = true;
      }
    });
  }, [cardRefs, centerScrollTest, disabled, headerHeight, isAutoScrolling, scrollSelection, allowNullSelection]);

  // Update the scroll selection as the user scrolls.
  useWindowScroll(() => {
    refreshScrollPos();
  }, [refreshScrollPos]);

  // Props to put on a map to make it function.
  const mapProps = useMemo(
    () => ({
      locations: mapLocations,
      requireSelection: true,
      selection: mapSelection,
      onSelect: (ind) => {
        if (isAutoScrolling || ind === null) return;

        setLatestSelectionChange('map');
        setMapSelection(ind);
      },
      noClickSelect: isAutoScrolling,
      onlyShowIcon,
      onChangeFullscreen: setIsMapFullscreen,
    }),
    [isAutoScrolling, mapLocations, mapSelection, onlyShowIcon]
  );

  /**
   * Parent setSelection.
   */
  const parentSetSelection = useCallback(
    (newSelection, smooth = false) => {
      if (newSelection === scrollSelection) return;

      setLatestSelectionChange('parent');
      setScrollSelection(newSelection);

      if (syncScroll) scrollTo(getScrollY(newSelection), smooth);
    },
    [getScrollY, scrollSelection, scrollTo, syncScroll]
  );

  return useMemo(
    () => ({
      cardRefs,
      mapProps,
      selection: scrollSelection,
      setSelection: parentSetSelection,
      showMap: !disabled,
    }),
    [cardRefs, mapProps, scrollSelection, parentSetSelection, disabled]
  );
};

export default useMapSyncedYScroll;
