import React from 'react';
import PropTypes from 'prop-types';
import PopupShare from './PopupShare/PopupShare';
import ImageShare from '../../../assets/images/icons/buttons/share.svg';
import ImageShareBold from '../../../assets/images/icons/buttons/share-bold.svg';
import { usePopup } from '../../hooks/popup';
import useVbContext from '../../hooks/vb-context';
import IconButton from '../IconButton/IconButton';
import ColoredIcon from '../ColoredIcon/ColoredIcon';
import useSendAnalyticsEvent from '../../hooks/send-analytics-event';
import { VENUE_BUTTONS_SHADOW_VALUE } from '../../config/venue-buttons';

/**
 * Icon button for sharing something
 * @param props.shareParams Props that will be passed to the IconButton.
 * @param props.hideText whether or not to hide the text at the bottom
 * @param props.color the color to give to the icon
 * @param props.shadow whether or not icon has shadow
 * @param props.bold whether or not icon is bold
 */

const IconShare = (props) => {
  const { shareParams, hideText, color, shadow, bold, ...other } = props;

  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { vbBlack } = useVbContext();

  const sharePopup = usePopup({ child: <PopupShare {...other} /> });

  const openSharePopup = () => {
    if (other.venueId) {
      sendAnalyticsEvent('vb_icon_button_share_venue_click', 'vb_icon_button');
    }

    sharePopup.open();
  };

  const { text: iconText, ...iconOtherParams } = shareParams ?? {};

  return (
    <>
      <IconButton
        onClick={openSharePopup}
        text={!hideText ? iconText ?? 'Share' : null}
        fontSize="1rem"
        ariaLabel="Share"
        textShadow={shadow ? VENUE_BUTTONS_SHADOW_VALUE : null}
        subtitleColor={color}
        {...iconOtherParams}
      >
        <ColoredIcon
          additionalFilters={shadow ? `drop-shadow(${VENUE_BUTTONS_SHADOW_VALUE})` : null}
          src={bold ? ImageShareBold : ImageShare}
          color={color || vbBlack}
          height="25"
          width="25"
          alt=""
        />
      </IconButton>
      {sharePopup.modal}
    </>
  );
};

IconShare.propTypes = {
  shareParams: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    fontSize: PropTypes.string,
  }),
  hideText: PropTypes.bool,
  color: PropTypes.string,
};

IconShare.defaultProps = {
  shareParams: undefined,
  hideText: false,
  color: null,
};

export default IconShare;
