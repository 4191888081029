import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import VBButton from '../VBButton/VBButton';
import { mergeClassNames } from '../../util/props';
import { EXPLORE_SECTIONS, ExploreSectionLabel } from './config';

import styles from './ExploreButtons.module.scss';

const propTypes = {
  locationDetails: PropTypes.object,
  title: PropTypes.string,
  cities: PropTypes.array,
  hideSections: PropTypes.bool,
  className: PropTypes.string,
  getCityButtonContent: PropTypes.func,
  onClickCityButton: PropTypes.func,
};

const ExploreButtons = ({
  locationDetails,
  title,
  cities,
  hideSections,
  className,
  getCityButtonContent,
  onClickCityButton,
}) => {
  const history = useHistory();

  const showSectionsGroup = Boolean(!hideSections && locationDetails);
  const showCitiesGroup = Boolean(cities.length);

  return (
    <MaxWidthContainer className={mergeClassNames(styles.buttonsWrapper, className)}>
      {title}
      <div className={styles.buttons}>
        {showSectionsGroup && (
          <div className={styles.group}>
            {EXPLORE_SECTIONS.map((section, idx) => (
              <VBButton
                key={idx}
                type="border"
                size="med"
                content={`${locationDetails.city} ${ExploreSectionLabel[section]}`}
                onClick={() =>
                  history.push(
                    `/location/${locationDetails.countrySlug}/${locationDetails.regionSlug}/${locationDetails.citySlug}/${section}`
                  )
                }
                noFocusStyle
              />
            ))}
          </div>
        )}
        {showCitiesGroup && (
          <div className={styles.group}>
            {cities.map(({ item, key }) => (
              <VBButton
                key={key}
                type="border"
                color="red"
                size="med"
                content={getCityButtonContent?.(item) ?? `Explore ${item.city}, ${item.regionSlug.toUpperCase()}`}
                onClick={
                  onClickCityButton
                    ? () => onClickCityButton(item)
                    : () => history.push(`/location/${item.countrySlug}/${item.regionSlug}/${item.citySlug}`)
                }
                noFocusStyle
              />
            ))}
          </div>
        )}
      </div>
    </MaxWidthContainer>
  );
};

ExploreButtons.propTypes = propTypes;

export default ExploreButtons;
